import useFormatMessage from 'hooks/useFormatMessage';
import { ErrorConfused } from 'pages/Error/ErrorConfused';
import { ErrorOnFire } from 'pages/Error/ErrorOnFire';
import { ErrorWithTable } from 'pages/Error/ErrorWithTable';
import { errorKeys } from 'utils/constants/errorKeys';
import { MissingFieldsErrorTable } from './MissingFieldsErrorTable';
import { categorizeErrorsByDetail } from '../helpers/categorizeErrorsByDetail';

export function ImportTaskError({ importTask }) {
  const { messages } = importTask;
  const t = useFormatMessage();

  const { missingDetailsErrors, otherErrors } =
    categorizeErrorsByDetail(messages);

  const errorMessages = otherErrors.map((message) => {
    const { errorCategoryName } = message;

    if (errorCategoryName === errorKeys.EXCEL_TOO_MANY_USERS) {
      return t('errors.category.tooManyUsers');
    }

    return t('global.error.message');
  });

  if (missingDetailsErrors.length === 0) {
    return (
      <ErrorOnFire
        centerHorizontally={false}
        isSubtitle
        message={errorMessages}
        showBackLink={false}
        showMessagesAsList
      />
    );
  }

  const errorWithImage =
    errorMessages.length > 0 ? (
      <ErrorOnFire
        centerHorizontally={false}
        isSubtitle
        message={errorMessages}
        showBackLink={false}
        showMessagesAsList
      />
    ) : (
      () => (
        <ErrorConfused
          centerHorizontally={false}
          isSubtitle
          message={t('errors.category.missing.fields.no.other.errors')}
          showBackLink={false}
        />
      )
    );

  return (
    <ErrorWithTable
      errorWithImage={errorWithImage}
      table={
        <MissingFieldsErrorTable
          importTask={importTask}
          missingDetailsErrors={missingDetailsErrors}
        />
      }
    />
  );
}
