import { gql } from 'graphql-request';

export const SCHOOLADMIN_CLASSGROUPS_BY_USER_ID = gql`
  query schooladminClassGroupsByUserId($userId: String!) {
    schooladminClassGroupsByUserId(userId: $userId) {
      id
      name
      archived
    }
  }
`;
