import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { ADMIN_CREATE_PERIOD } from 'api/plannerService/admin/mutations/adminCreatePeriod';

export default function useAdminCreatePeriod(
  organizationId,
  onSuccess = () => {},
) {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ input }) =>
      plannerServiceQueryClient.request(ADMIN_CREATE_PERIOD, {
        organizationId,
        input,
      }),
    {
      mutationKey: 'adminCreatePeriod',
      onSuccess: () => {
        onSuccess();
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.period'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('global.period'),
        });
      },
    },
  );
}
