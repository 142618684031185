import { gql } from 'graphql-request';

export const SCHOOLADMIN_USERS = gql`
  query schooladminUsers(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    schooladminUsers(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        id
        classGroups {
          id
          name
        }
        displayName
        enabled
        endDate
        firstName
        idp
        lastName
        roles
        userName
        avatar
      }
    }
  }
`;
