/* The below disable is because if we make the props explicit ...
 * than the dropdownMenu, where the dropdown buttons are cloned,
 * doesn't work anymore.
 */
/* eslint-disable react/jsx-props-no-spreading */
import DropdownIcon from '../DropdownIcon';

import { DefaultButton } from './DefaultButton';

export function DropdownButton({
  children = null,
  className = '',
  isOpen = false,
  ...props
}) {
  return (
    <DefaultButton aria-expanded={!!isOpen} className={className} {...props}>
      <div className="flex items-center w-full justify-between">
        <div className="mr-2">{children}</div>
        <DropdownIcon className="w-3" isOpen={isOpen} />
      </div>
    </DefaultButton>
  );
}
