import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_DELETE_PROGRAM } from 'api/plannerService/admin/mutations/adminDeleteProgram';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminDeleteProgram() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    (id) =>
      plannerServiceQueryClient.request(ADMIN_DELETE_PROGRAM, {
        id,
      }),
    {
      mutationKey: 'adminDeleteProgram',
      onSuccess: () => {
        toast(TOASTTYPES.DELETESUCCESS, {
          str: t('global.PROGRAM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.DELETEERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );

  return mutation;
}
