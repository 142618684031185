import { CREATE_SCRATCH_JUDGE } from 'api/portfolioService/mutations/createScratchJudge';
import { SCRATCH_JUDGE_RESULTS_BY_SESSIONID } from 'api/portfolioService/queries/scratchJudgeResultsBySessionId';
import { useState } from 'react';
import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export function useTestExercise({
  exerciseId,
  language,
  testPlanId,
  versionId,
}) {
  const [testResults, setTestResults] = useState([]);
  const [isLoadingTests, setLoadingTests] = useState(false);

  const test = ({ enableTestButton, exerciseBlob }) => {
    setLoadingTests(true);

    // Request Judge
    portfolioServiceQueryClient
      .request(CREATE_SCRATCH_JUDGE, {
        exerciseVersionId: versionId,
        exerciseId,
        testPlanId,
        language,
        file: exerciseBlob,
      })
      .then((response) => {
        // When judge is requested, start polling for result every 1s

        // Get JudgeId from response
        const judgeId = response.createScratchJudge.id;

        // Push requested judge into results array
        setTestResults([
          {
            id: judgeId,
            time: new Date(),
            status: 'loading',
          },
          ...testResults,
        ]);

        // Track time of when we started polling so we can stop after 60s
        const startOfQuery = Date.now();

        // Save interval so we can cancel it
        const queryResultsEverySec = setInterval(() => {
          portfolioServiceQueryClient
            .request(SCRATCH_JUDGE_RESULTS_BY_SESSIONID, {
              sessionId: judgeId,
            })
            .then(({ scratchJudgeResultsBySessionId }) => {
              // When result is found update
              if (scratchJudgeResultsBySessionId?.length > 0) {
                // Stop polling
                clearInterval(queryResultsEverySec);

                // Stop loading animations
                setLoadingTests(false);

                // Update result in existing results array (state)
                setTestResults((oldResults) => {
                  // clone current testResults for immutabillity
                  const newResults = [...oldResults];

                  // Its an object so we can just update it, it will stay linked
                  const currentJudge = newResults.find(
                    (result) => result.id === judgeId,
                  );
                  if (currentJudge) {
                    currentJudge.result = JSON.parse(
                      scratchJudgeResultsBySessionId[0].result,
                    );
                    currentJudge.status = 'done';
                  }

                  return newResults;
                });

                // Re-enable test button so user can click it
                enableTestButton();
              }
            })
            .catch(() => {
              // if server throws error stop checking and re-enable button
              clearInterval(queryResultsEverySec);
              setLoadingTests(false);

              // Update result in existing results array (state)
              setTestResults((oldResults) => {
                // clone current testResults for immutabillity
                const newResults = [...oldResults];

                // Its an object so we can just update it, it will stay linked
                const currentJudge = newResults.find(
                  (result) => result.id === judgeId,
                );
                if (currentJudge) {
                  currentJudge.status = 'failed';
                }

                return newResults;
              });

              // Re-enable test button so user can click it
              enableTestButton();
            });

          // If judge takes more then 60s something must have gone wrong,
          // stop checking and re-enable button
          if (Date.now() - startOfQuery > 60000) {
            clearInterval(queryResultsEverySec);
            setLoadingTests(false);

            // Update result in existing results array (state)
            setTestResults((oldResults) => {
              // clone current testResults for immutabillity
              const newResults = [...oldResults];

              // Its an object so we can just update it, it will stay linked
              const currentJudge = newResults.find(
                (result) => result.id === judgeId,
              );
              if (currentJudge) {
                currentJudge.status = 'failed';
              }

              return newResults;
            });

            // Re-enable test button so user can click it
            enableTestButton();
          }
        }, 1000);
      });
  };

  return { results: testResults, isLoading: isLoadingTests, test };
}
