import { Tooltip as ReachTooltip } from '@reach/tooltip';

/*
 * This component is just a wrapper around Reach-Tooltip.
 * The reason we use it is to avoid the "missing required attribute" warnings each time we use it.
 * Now it's just something that we need to suppress only once here.
 * It also means we only need this dependency in tailwind-components.
 */
export function Tooltip({ children, label }) {
  // noinspection RequiredAttributes
  return <ReachTooltip label={label}>{children}</ReachTooltip>;
}
