export function sortByStartDateThenByEndDate(a, b) {
  // Compare start dates first
  if (a.startDate < b.startDate) {
    return -1;
  }
  if (a.startDate > b.startDate) {
    return 1;
  }

  // Start dates are equal, compare end dates
  if (a.endDate < b.endDate) {
    return -1;
  }
  if (a.endDate > b.endDate) {
    return 1;
  }

  // End dates are also equal
  return 0;
}
