import { gql } from 'graphql-request';

export const UPSERT_LESSON_GROUP = gql`
  mutation teacherUpsertLessonGroup($input: LessonGroupInput!) {
    teacherUpsertLessonGroup(input: $input) {
      lessonSessionId
      userGroups {
        steamsGroupId
        name
        userRoles {
          userId
          roleId
          roleName
        }
      }
    }
  }
`;
