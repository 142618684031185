import { createContext, createRef, useEffect, useMemo } from 'react';
import { toast as reactToast } from 'react-toastify';

import NotificationDisplay from 'components/Notification/NotificationToast';

import useNotifications from 'hooks/api/plannerService/common/queries/useNotifications';

export const NotificationContext = createContext({});

export default function NotificationProvider({ children }) {
  const { data: newNotifications } = useNotifications({
    showAll: false,
    page: 0,
    size: 500,
  });

  useEffect(() => {
    newNotifications?.content
      // for each pending notification, create a custom toast
      .forEach((notification) => {
        const toastId = createRef(null);

        toastId.current = reactToast(
          <NotificationDisplay
            notification={notification}
            onDismiss={() => {
              // reactToast on dismiss is used to close the toast instead of default behaviour (close onclick)
              reactToast.dismiss(toastId.current);
            }}
          />,
          {
            toastId: notification.id,
            autoClose: false,
            style: { padding: '0px' },
            closeButton: false,
            closeOnClick: false,
            bodyStyle: { padding: '0px' },
          },
        );
      });
  }, [newNotifications?.content]);

  const context = useMemo(() => ({ newNotifications }), [newNotifications]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}
