import { gql } from 'graphql-request';

export const SCHOOLADMIN_TEAMS_CLASSGROUPS = gql`
  query schooladminTeamsClassGroups {
    schooladminTeamsClassGroups {
      name
      externalId
      lastSyncedOn
    }
  }
`;
