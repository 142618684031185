import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_ARCHIVE_CLASS_GROUP } from 'api/plannerService/schooladmin/mutations/schooladminArchiveClassGroup';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminArchiveClassGroup() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    (id) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_ARCHIVE_CLASS_GROUP, {
        id,
      }),
    {
      mutationKey: 'schooladminArchiveClassGroup',
      onSuccess: () => {
        toast(TOASTTYPES.ARCHIVESUCCESS, {
          str: t('global.CLASS.singular'),
        });
      },
    },
  );
  return mutation;
}
