import { useEffect, useState } from 'react';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';
import { SearchBar } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { BadgeSelect } from 'components/BadgeSelect';
import { ChaptersList } from './ChaptersList';
import { filterByTags, filteredByName, getUniqueSortedTags } from './helpers';

export default function ChapterOverview({ chapters, currentProgramId }) {
  const t = useFormatMessage();
  const [filteredChapters, setFilteredChapters] = useState([...chapters]);
  const [filter, setFilter] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    let filteredChapters = [...chapters];

    if (filter) {
      filteredChapters = filteredByName(filteredChapters, filter);
    }

    if (selectedTags.length > 0) {
      const tags = selectedTags.map((tag) => tag.value);

      filteredChapters = filterByTags(filteredChapters, tags);
    }

    setFilteredChapters(filteredChapters);
  }, [chapters, filter, selectedTags]);

  const tagOptions = getUniqueSortedTags(chapters).filter(
    (tag) => tag !== undefined,
  );

  return (
    <>
      <div className="flex gap-2 flex-col md:flex-row">
        <div className="md:w-1/2">
          <SearchBar
            id="filterChapterByName"
            onChange={setFilter}
            placeholder={t('chapter-overview.search.placeholder')}
            test="filterChapterByName"
          />
        </div>

        {Boolean(tagOptions?.length) && (
          <div className="md:w-1/2">
            <BadgeSelect
              id="filterChapterByTags"
              options={tagOptions.map((tag) => ({
                value: tag?.name,
                label: tag?.name,
                color: tag?.color,
              }))}
              placeholder={t('chapter-overview.select.placeholder')}
              selected={selectedTags}
              setSelected={setSelectedTags}
            />
          </div>
        )}
      </div>

      <ChaptersList
        chapters={filteredChapters?.sort(sortByWeightThenByName)}
        programId={currentProgramId}
      />
    </>
  );
}
