import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { dataTestIds } from 'utils/dataTestIds';
import useFormatMessage from 'hooks/useFormatMessage';

export function PeriodsDropdown({ id, inModal, period, periods, setPeriod }) {
  const t = useFormatMessage();
  return (
    <div className={inModal ? 'mb-8' : 'min-w-1/2'}>
      <Label className={!inModal && 'sr-only'} htmlFor={id}>
        {inModal
          ? t('program-overview.column.seatPeriod')
          : t('edit-period-for-program.seatPeriod.choose')}
      </Label>
      <Dropdown
        data-test={dataTestIds.modal.editSeatsFromProgram.input.period}
        defaultValue={period}
        inputId={id}
        isMulti={false}
        onBlur={(e) => {
          if (e.id) setPeriod(e);
        }}
        onChange={(e) => {
          if (e.id) setPeriod(e);
        }}
        options={periods}
        placeholder={t('edit-period-for-program.seatPeriod.choose')}
        required
        returnMethod="object"
        value={period}
      />
    </div>
  );
}
