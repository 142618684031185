import { generatePath } from 'react-router-dom';

import { getItemIndex } from 'components/Pager/getItemIndex';
import { Pager } from 'components/Pager/Pager';

import URLS from 'utils/constants/urls';

export function ProgramPager({
  currentProgram,
  programs,
  setProgramId = () => {},
}) {
  return (
    <div className="w-full flex justify-center mt-2 items-center">
      <Pager
        currentItem={currentProgram}
        getItemIndex={getItemIndex}
        getNewLocation={(value) =>
          generatePath(URLS.MANAGE_PROGRAM_DETAILS, {
            programId: value?.id,
          })
        }
        items={programs}
        nameAttribute="name"
        setItemId={setProgramId}
      />
    </div>
  );
}
