export const viewModes = {
  PROJECTION: 'PROJECTION',
  CLASSICAL: 'CLASSICAL',
  SELFSTUDY: 'SELFSTUDY',
};

export const viewModesWithTranslations = {
  PROJECTION: {
    value: viewModes.PROJECTION,
    label: 'view-modes.projection',
  },
  CLASSICAL: {
    value: viewModes.CLASSICAL,
    label: 'view-modes.classical',
  },
  SELFSTUDY: {
    value: viewModes.SELFSTUDY,
    label: 'view-modes.selfstudy',
  },
};

viewModes.DEFAULT = viewModes.CLASSICAL;

export default viewModes;
