import { XMarkIcon } from '@heroicons/react/24/outline';

export function ChosenInCorrect({ answer }) {
  return (
    <li>
      <div className="rounded bg-red-100 text-red-700 p-8 flex max-h-fit justify-between">
        <div>
          <div
            className="font-semibold"
            dangerouslySetInnerHTML={{ __html: answer.value }}
          />
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: answer.explanation }}
          />
        </div>
        <XMarkIcon
          className="stroke-2 ml-4 min-w-[2rem] w-8 min-h-12 h-12"
          size="2rem"
        />
      </div>
    </li>
  );
}
