import useFormatMessage from 'hooks/useFormatMessage';

export function SkinColorPicker({ id, map, selected, updateProp }) {
  const t = useFormatMessage();

  return (
    <div key={id}>
      <fieldset className="flex items-center mt-4 border-0">
        <legend className="font-semibold w-1/3">
          {t(`avatar.prop.${id}`)}
        </legend>
        <div className="w-2/3 ml-[33%] -mt-6 bg-white focus-within:outline-none focus-within:border-purple-500 flex flex-wrap gap-2">
          {Object.keys(map).map((value) => (
            <div key={value}>
              <label className="sr-only" htmlFor={value}>
                {t(`avatar.value.${id}.${value}`)}
              </label>
              <input
                checked={selected === value ? 'checked' : null}
                className="rounded-full w-6 h-6 border-0"
                id={value}
                name="skinTone"
                onChange={updateProp}
                style={{
                  backgroundColor: map?.[value]?.base,
                  border: `3px solid ${map?.[value]?.shadow}`,
                }}
                type="radio"
              />
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
