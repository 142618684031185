import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_GROUP_COMPOSITION_BY_ACTIVITY } from 'api/plannerService/teacher/queries/teacherGroupCompositionByActivity';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherGroupCompositionByActivity({ activity }) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    [`teacherGroupCompositionByActivity`, activity],
    () =>
      plannerServiceQueryClient.request(TEACHER_GROUP_COMPOSITION_BY_ACTIVITY, {
        activity,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.CLASS.plural'),
        });
      },
    },
  );

  return { ...response, data: response?.data?.groupComposition };
}
