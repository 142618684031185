import { useState } from 'react';
import URLS from 'utils/constants/urls';

import {
  Actions,
  Loader,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import { EmptyState } from 'components/EmptyState';
import ProgressBar from 'components/ProgressBar';
import useTeacherResultsByUserId from 'hooks/api/plannerService/teacher/queries/useTeacherResultsByUserId';
import useFormatMessage from 'hooks/useFormatMessage';
import { calculateProgress } from 'utils/calculateProgress';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import { EyeIcon } from '@heroicons/react/24/outline';
import { generatePath, useOutletContext } from 'react-router-dom';
import { dateFormat } from 'utils/timeHelpers';

export default function UserResults() {
  const t = useFormatMessage();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [{ user }] = useOutletContext();
  const { id: userId } = user;

  const { data: resultsData, isLoading } = useTeacherResultsByUserId({
    userId,
    page: page - 1,
    size: itemsPerPage,
  });

  const totalItems = resultsData?.content;
  const totalPages = resultsData?.pages;
  const results = resultsData?.content || [];

  if (isLoading) {
    return (
      <div className="w-5">
        <Loader />
      </div>
    );
  }

  if (!results?.length) {
    return <EmptyState textKey="global.no_results" />;
  }

  return (
    <div className="">
      <ResponsiveTable
        headers={[
          {
            key: 'lesson',
            label: t('global.LESSON.singular'),
            orderable: true,
          },
          {
            className: 'text-center',
            key: 'score',
            label: t('results-overview.column.score'),
            orderable: true,
          },
          {
            className: 'text-center',
            key: 'progress',
            label: t('results-overview.column.progress'),
            orderable: false,
          },
          {
            className: 'text-center',
            key: 'grading',
            label: t('results-overview.column.grading'),
            orderable: false,
          },
          {
            className: 'text-center',
            key: 'viewmode',
            label: t('global.viewMode'),
            orderable: false,
          },
          {
            key: 'actions',
            label: t('global.actions'),
            orderable: false,
            className: 'max-w-fit sr-only',
          },
        ]}
      >
        {results.map((result, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ResponsiveTableRow key={`results-${Date.now()}${i}`}>
            <ResponsiveTableCol>
              {result.calendarItem.lessonContentTitle}
              <small className="block text-slate-500">
                {dateFormat(result.calendarItem.startTime, true)}
              </small>
            </ResponsiveTableCol>
            <ResponsiveTableCol className="text-center">
              {result.questionScoresEarned > 0
                ? result.questionScoresEarned
                : 0}
              /{result.questionScoresMax}
            </ResponsiveTableCol>
            <ResponsiveTableCol className="text-center">
              <ProgressBar
                label={`${result.answeredQuestions}/${
                  result.amountOfQuestions
                } ${t('results-overview.column.progress_suffix')}`}
                percent={calculateProgress(
                  result.answeredQuestions,
                  result.amountOfQuestions,
                )}
              />
            </ResponsiveTableCol>

            <ResponsiveTableCol className="text-center px-4">
              <ProgressBar
                label={`${
                  result.amountOfOpenQuestions - result.questionsToGrade
                }/${result.amountOfOpenQuestions} ${t(
                  'results-overview.column.graded_suffix',
                )}`}
                percent={calculateProgress(
                  result.amountOfOpenQuestions - result.questionsToGrade,
                  result.amountOfOpenQuestions,
                )}
              />
            </ResponsiveTableCol>

            <ResponsiveTableCol className="text-center px-4">
              {t(`view-modes.${result.viewMode.toLowerCase()}`)}
            </ResponsiveTableCol>

            <ResponsiveTableCol
              className="px-6 py-2 whitespace-nowrap flex gap-2 justify-end"
              whitespaceNoWrapOnMobile={false}
            >
              <Actions
                items={[
                  {
                    icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
                    label: t('global.RESULT.view'),
                    href: generatePath(
                      URLS.STUDENT_RESULT_PAGE_FOR_LESSON_PROGRAM,
                      {
                        calendarItemId: result.calendarItem.id,
                        programId: nonNumericProgramIds.CLASS,
                        sessionId: result.calendarItem.sessionId,
                        userId,
                      },
                    ),
                  },
                ]}
              />
            </ResponsiveTableCol>
          </ResponsiveTableRow>
        ))}
      </ResponsiveTable>

      {Boolean(results && results.length) && (
        <Pagination
          className="mt-12"
          currentPage={page}
          itemsPerPage={itemsPerPage}
          setCurrentPage={(page) => setPage(page)}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems.length}
          totalPages={totalPages}
        />
      )}
    </div>
  );
}
