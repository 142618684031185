import { Tooltip } from '@ftrprf/tailwind-components';
import { reverse, sortBy } from 'lodash-es';
import c from 'utils/c';

const DEFAULT_THRESHOLDS = [
  { color: 'bg-red-500', threshold: 0 },
  { color: 'bg-amber-500', threshold: 25 },
  { color: 'bg-green-500', threshold: 75 },
  { color: 'bg-purple-500', threshold: 101 },
];

export const IMPORT_TASK_THRESHOLDS = [
  { color: 'bg-red-500', threshold: 0 },
  { color: 'bg-amber-500', threshold: 50 },
  { color: 'bg-green-500', threshold: 100 },
];

/**
 * Returns the color based on the given percent and thresholds.
 *
 * @param {number} percent - The percentage value to determine the color.
 * @param {Array} [thresholds=DEFAULT_THRESHOLDS] - An array of objects containing threshold and color.
 * @returns {string} The color class name based on the thresholds.
 *
 * @example
 * const thresholds = [
 *   { color: 'bg-red-500', threshold: 0 },
 *   { color: 'bg-amber-500', threshold: 25 },
 *   { color: 'bg-green-500', threshold: 75 },
 * ];
 *
 * getColorByThresholds(50, thresholds); // Returns 'bg-amber-500'
 * getColorByThresholds(90, thresholds); // Returns 'bg-green-500'
 * getColorByThresholds(120, thresholds); // Returns 'bg-green-500'
 */
function getColorByThresholds(percent, thresholds = DEFAULT_THRESHOLDS) {
  const sortedThresholds = sortBy(thresholds, [(o) => o.threshold]);
  const reverseSortedThresholds = reverse(sortedThresholds);

  for (const { color, threshold } of reverseSortedThresholds) {
    if (percent >= threshold) return color;
  }

  return thresholds[thresholds.length - 1].color;
}

/**
 * ProgressBar component that displays a progress bar with a label and customizable thresholds.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class names for the component.
 * @param {string} props.label - The label to display on the progress bar.
 * @param {number} props.percent - The percentage value to determine the progress bar color.
 * @param {boolean} [props.hasVisibleLabel=false] - Whether the label should be visible or hidden.
 * @param {Array} [props.thresholds=DEFAULT_THRESHOLDS] - An array of objects containing threshold and color.
 *
 * @returns {JSX.Element} - The ProgressBar component.
 *
 * @example
 * <ProgressBar
 *   className="my-4"
 *   label="Progress"
 *   percent={75}
 *   hasVisibleLabel
 *   thresHolds={[
 *     { color: 'bg-red-500', threshold: 0 },
 *     { color: 'bg-amber-500', threshold: 25 },
 *     { color: 'bg-green-500', threshold: 75 },
 *   ]}
 * />
 */
export default function ProgressBar({
  className,
  hasVisibleLabel = false,
  label,
  percent,
  thresholds,
}) {
  const color = getColorByThresholds(percent, thresholds);

  return (
    <Tooltip label={label}>
      <div className={c('w-full', className)}>
        <div className="bg-slate-200 w-full rounded">
          <div
            className={`${color} h-2 rounded min-w-[5px]`}
            style={{ width: `${Math.min(percent, 100)}%` }}
          />
        </div>
        {hasVisibleLabel && (
          <span
            className={c('text-xs block', !hasVisibleLabel && 'sr-only')}
            style={{
              marginLeft: `max(calc(${Math.min(percent, 100)}% - ${Math.ceil(
                label.length / 2,
              )}em), 0px)`,
            }}
          >
            {label}
          </span>
        )}
      </div>
    </Tooltip>
  );
}
