import { RadioButtonList } from '@ftrprf/tailwind-components';
import { useEffect } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { EmptyState } from 'components/EmptyState';
import { usePopupContext } from 'providers/PopupProvider';
import { SelectClass } from './SelectClass';

export function SelectTarget() {
  const t = useFormatMessage();

  const { classGroupsData, formOptions, setClasses, setTarget, target } =
    usePopupContext();

  const targets = [
    {
      description: t('smartschool.popup.target.all.description'),
      id: 0,
      name: t('smartschool.popup.target.all.name'),
    },
    {
      description: t('smartschool.popup.target.specific.description'),
      extra:
        target?.id === 1 ? (
          <SelectClass classes={classGroupsData?.content} />
        ) : null,
      id: 1,
      name: t('smartschool.popup.target.specific.name'),
    },
  ];

  const onTargetClick = (newTarget) => {
    if (target?.id === newTarget?.id) return;

    if (newTarget?.id === 0) {
      setClasses(classGroupsData?.content);
    } else {
      const { classGroups } = formOptions.watch();
      setClasses(classGroups);
    }

    setTarget(newTarget);
  };

  useEffect(() => {
    if (!target) onTargetClick(targets[0]);
    // disable below is because we only need to set a defaultValue on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasClassGroupContent = Boolean(classGroupsData?.content?.length);

  return hasClassGroupContent ? (
    <RadioButtonList
      groupName={t('smartschool.popup.step1')}
      onClick={onTargetClick}
      selected={target}
      settings={targets}
    />
  ) : (
    <EmptyState imageClass="w-32" textKey="smartschool.popup.noClasses" />
  );
}
