import { Navigate, useParams } from 'react-router-dom';
import { studentResultTab } from 'utils/constants/urls';
import { generateStudentResultPageTabPath } from 'utils/generateUrlPaths';

export default function StudentResultsForLessonIndex() {
  const { calendarItemId, programId, sessionId, userId } = useParams();

  return (
    <Navigate
      replace
      to={generateStudentResultPageTabPath(
        calendarItemId,
        programId,
        sessionId,
        studentResultTab.DEFAULT,
        userId,
      )}
    />
  );
}
