import { useState } from 'react';

import { Dropdown } from '@ftrprf/tailwind-components';

import useTeacherUpdateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherUpdateCalendarItem';
import useFormatMessage from 'hooks/useFormatMessage';

import { viewModesWithTranslations } from 'utils/constants/viewModes';

export default function ViewModeDropdown({ chapterItemId, classGroup }) {
  const t = useFormatMessage();
  const { mutate: updateCalendarItem } = useTeacherUpdateCalendarItem();
  const [selectedViewMode, setSelectedViewMode] = useState();

  const viewModesOptions = [
    // first option is default value
    viewModesWithTranslations.CLASSICAL,
    viewModesWithTranslations.SELFSTUDY,
  ].map((viewMode) => ({
    value: viewMode.value,
    label: t(`${viewMode.label}`),
  }));

  const updateCalendarItems = (viewMode) => {
    classGroup.calendarItems.forEach(async (calendarItem) => {
      const input = {
        startTime: calendarItem.startTime,
        endTime: calendarItem.endTime,
        chapterItemId,
        closedAfterEndTime: calendarItem?.closedAfterEndtime || false,
        classGroupIds: calendarItem.classGroups.map(
          (classGroup) => classGroup.id,
        ),
        viewMode: viewMode.value,
        studioId: calendarItem.studioId,
        hidden: calendarItem.hidden,
      };

      await updateCalendarItem({
        id: calendarItem.id,
        input,
      });
      setSelectedViewMode(viewMode.value);
    });
  };

  return (
    <Dropdown
      className="text-sm font-normal w-36"
      defaultValue={
        selectedViewMode ||
        viewModesWithTranslations[classGroup.calendarItems?.[0].viewMode]
          .value ||
        viewModesOptions[0].value
      }
      inputId="selectViewMode"
      onChange={updateCalendarItems}
      options={viewModesOptions}
      returnMethod="object"
      value={
        selectedViewMode ||
        viewModesWithTranslations[classGroup.calendarItems?.[0].viewMode]
          .value ||
        viewModesOptions[0].value
      }
    />
  );
}
