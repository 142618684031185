import { useExercises } from 'hooks/api/portfolioService/queries/useExercises';

import { ExercisesOverview } from './ExercisesOverview';

export function ExercisesOverviewStudentContainer() {
  const { data, isError, isLoading } = useExercises();

  return (
    <ExercisesOverview
      exercises={data}
      hasCreateExercise={false}
      isError={isError}
      isLoading={isLoading}
    />
  );
}
