import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import ClassesOverview from './ClassesOverview';

export default function ClassesOverviewContainer() {
  // Final items
  const MAX_ITEMS = 10;

  // State
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ name: sortOperation.ASC });

  const debouncedFilter = useDebounce(filter, 300);

  const {
    data: classData,
    isLoading,
    refetch,
  } = useTeacherClassGroups({
    filter: [
      { key: 'name', value: debouncedFilter, operation: filterOperation.LIKE },
    ],
    page: page - 1,
    size: MAX_ITEMS,
    sort,
  });

  const { content: classes, pages, total: totalItems } = classData || {};
  const itemsPerPage = MAX_ITEMS;

  useEffect(() => {
    refetch();
  }, [sort, debouncedFilter, page, refetch]);

  return (
    <ClassesOverview
      classes={classes}
      currentPage={page}
      isLoading={isLoading}
      itemsPerPage={itemsPerPage}
      maxItems={MAX_ITEMS}
      pages={pages}
      setFilter={(value) => {
        setPage(1);
        setFilter(value);
      }}
      setPage={setPage}
      setSort={setSort}
      sort={sort}
      totalItems={totalItems}
    />
  );
}
