function getWeightIfDraggedHigherAndToStart(
  destination,
  items,
  isSortedDescending,
) {
  const itemBelowWeight = items[destination.index].weight;

  if (isSortedDescending) {
    return itemBelowWeight + 1;
  }

  return itemBelowWeight === 0 ? itemBelowWeight : itemBelowWeight - 1;
}

function getWeightIfDraggedLowerAndToEnd(
  destination,
  items,
  isSortedDescending,
) {
  const itemAboveWeight = items[destination.index].weight;

  if (isSortedDescending) {
    return itemAboveWeight === 0 ? itemAboveWeight : itemAboveWeight - 1;
  }

  return itemAboveWeight + 1;
}

function getWeightIfDraggedLowerAndNotToEnd(
  destination,
  items,
  isSortedDescending,
) {
  const itemAboveWeight = items[destination.index].weight;
  const itemBelowWeight = items[destination.index + 1].weight;
  const weightDiffersByMoreThanOne =
    Math.abs(itemAboveWeight - itemBelowWeight) > 1;

  if (weightDiffersByMoreThanOne && isSortedDescending) {
    return itemAboveWeight - 1;
  }

  if (weightDiffersByMoreThanOne) {
    return itemAboveWeight + 1;
  }

  if (isSortedDescending) {
    return itemAboveWeight;
  }

  return itemBelowWeight;
}

function getWeightIfDraggedHigherAndNotToStart(
  destination,
  items,
  isSortedDescending,
) {
  const itemAboveWeight = items[destination.index - 1].weight;
  const itemBelowWeight = items[destination.index].weight;
  const weightDiffersByMoreThanOne =
    Math.abs(itemAboveWeight - itemBelowWeight) > 1;

  if (weightDiffersByMoreThanOne && isSortedDescending) {
    return itemBelowWeight + 1;
  }

  if (weightDiffersByMoreThanOne) {
    return itemBelowWeight - 1;
  }

  if (isSortedDescending) {
    return itemAboveWeight;
  }

  return itemAboveWeight;
}

export function droppedOutsideTheDroppable(destination) {
  return !destination;
}

export function hasADifferentPosition(destination, source) {
  return source.index !== destination.index;
}

function lockStylesOnChildNodes(childElements) {
  Array.from(childElements).forEach((element) => {
    element.setAttribute('style', window.getComputedStyle(element).cssText);
    if (element.children.length > 0) lockStylesOnChildNodes(element.children);
  });
}

function lockRowStyles(rows) {
  Array.from(rows).forEach((rij, index) => {
    const row = document.querySelector(`.draggable-item:nth-of-type(${index})`);
    if (row) {
      row.setAttribute(
        'style',
        `width:${row.offsetWidth}px;height:${row.offsetHeight}px;`,
      );
      const cells = row.querySelectorAll('td');

      cells.forEach((cell, index) => {
        const isLastCell = index === cells.length - 1;

        cell.setAttribute(
          'style',
          `width:${cell.offsetWidth - 1}px;height:${cell.offsetHeight}px;`,
        );

        if (isLastCell) {
          lockStylesOnChildNodes(cell.children);
        }
      });
    }
  });
}

export function eraseStylesOnChildNodes(childElements) {
  Array.from(childElements).forEach((element) => {
    element.setAttribute('style', '');
    if (element.children.length > 0) lockStylesOnChildNodes(element.children);
  });
}

export function lockTableStyles() {
  const rows = document.querySelectorAll('.draggable-item');
  lockRowStyles(rows);
  const theadRow = document.querySelector('thead > tr');
  lockRowStyles([theadRow]);
}

function unlockRowStyles(rows) {
  Array.from(rows).forEach((rij, index) => {
    const row = document.querySelector(`.draggable-item:nth-of-type(${index})`);
    if (row) {
      row.removeAttribute('style');
      const cells = row.querySelectorAll('td');

      cells.forEach((cell, index) => {
        cell.removeAttribute('style');

        if (index === cells.length - 1) {
          eraseStylesOnChildNodes(cell.children);
        }
      });
    }
  });
}

export function unlockTableStyles() {
  const rows = document.querySelectorAll('.draggable-item');
  unlockRowStyles(rows);
  const theadRow = document.querySelector('thead > tr');
  unlockRowStyles([theadRow]);
}
export function setItemWeight(items, destination, source) {
  const isSortedDescending =
    items[0].weight - items[items.length - 1].weight > 0;
  const draggedItem = items[source.index];
  const wasDraggedLower = source.index < destination.index;
  const wasDraggedHigher = source.index > destination.index;
  const draggedToEnd = destination.index === items.length - 1;
  const draggedToStart = destination.index === 0;

  if (wasDraggedLower && draggedToEnd) {
    draggedItem.weight = getWeightIfDraggedLowerAndToEnd(
      destination,
      items,
      isSortedDescending,
    );
    return;
  }

  if (wasDraggedLower && !draggedToEnd) {
    draggedItem.weight = getWeightIfDraggedLowerAndNotToEnd(
      destination,
      items,
      isSortedDescending,
    );
    return;
  }

  if (wasDraggedHigher && draggedToStart) {
    draggedItem.weight = getWeightIfDraggedHigherAndToStart(
      destination,
      items,
      isSortedDescending,
    );
    return;
  }

  if (wasDraggedHigher && !draggedToStart) {
    draggedItem.weight = getWeightIfDraggedHigherAndNotToStart(
      destination,
      items,
      isSortedDescending,
    );
  }
}
