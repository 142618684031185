// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Dropdown,
  Input,
  Label,
  SubjectCard,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import colors from 'utils/constants/colors';
import programTypes from 'utils/constants/programTypes';

export default function UpdateProgramDialog({
  isOpen,
  onConfirm: updateProgram,
  onDismiss: dismiss,
  programData,
}) {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState('');
  const [color, setColor] = useState('#000000');
  const [name, setName] = useState('');

  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const resetState = useCallback(
    ({ blobUri, color, name }) => {
      setFiles([]);
      setImage(blobUri);
      setColor(color);
      setName(name);
      reset();
    },
    [reset, setColor, setFiles, setName, setImage],
  );

  useEffect(() => {
    if (programData) resetState(programData);
  }, [programData, resetState]);

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ErrorCode.FileInvalidType) {
            setError(`Error: ${err.message}`);
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      return acceptedFiles;
    },
  });

  const onDismiss = useCallback(() => {
    dismiss(false);
    resetState(programData);
  }, [dismiss, resetState, programData]);

  const onSubmit = useCallback(
    (data) => {
      // eslint-disable-next-line no-param-reassign
      delete data.file;
      updateProgram(data, files[0] ? acceptedFiles[0] : undefined);
      resetState(programData);
      dismiss(false);
    },
    [updateProgram, resetState, dismiss, acceptedFiles, files, programData],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('update-program-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="updateProgramDialog-name">
                {t('update-program-dialog.name')}
              </Label>
              <Input
                defaultValue={name}
                hasErrors={Boolean(errors.name)}
                id="updateProgramDialog-name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                {...register('name', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.name && (
                <span className="text-red-500 text-xs">
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="updateProgramDialog_type">
                {t('update-program-dialog.type')}
              </Label>
              <Controller
                control={control}
                defaultValue={programData?.type}
                name="type"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Dropdown
                    hasErrors={Boolean(errors.type)}
                    inputId="updateProgramDialog_type"
                    isMulti={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    options={Object.values(programTypes)}
                    value={value}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                }}
                type="select"
              />
              {errors.type && (
                <span className="text-red-500 text-xs">
                  {errors.type.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="updateProgramDialog-weight">
                {t('global.weight')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('program-dialog.weight.description')}
              </p>
              <Input
                defaultValue={programData?.weight || '0'}
                id="updateProgramDialog-weight"
                min={0}
                type="number"
                {...register('weight', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <Label htmlFor="updateProgramDialog-document">
                {t('program-dialog.document')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('program-dialog.document.description')}
              </p>
              <Input
                defaultValue={programData?.document}
                hasErrors={Boolean(errors.document)}
                id="updateProgramDialog-document"
                type="text"
                {...register('document', {
                  required: {
                    value: false,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.document && (
                <span className="text-red-500 text-xs">
                  {errors.document.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('update-program-dialog.color')}</Label>
              <p className="text-gray-500 text-sm my-1">
                {t('program-dialog.color.description')}
              </p>
              <Controller
                control={control}
                defaultValue={color}
                name="color"
                render={({ field: { onChange } }) => (
                  <TwitterPicker
                    className="rounded border border-gray-300"
                    color="#000000"
                    colors={colors.map((item) => item.color)}
                    onChange={(item) => {
                      setColor(item.hex);
                      onChange(item.hex);
                    }}
                    styles={{
                      default: { card: { boxShadow: '0', border: '' } },
                    }}
                    triangle="hide"
                    // this width prop resets the default width that's added by the twitterpicker
                    width=""
                  />
                )}
              />
              <div
                className="mt-4 rounded h-2"
                style={{ backgroundColor: color }}
              />
            </div>

            <div className="flex flex-col">
              <Label>{t('global.image.update')}</Label>
              {files[0] || image ? (
                <div
                  className="grid grid-cols-1 md:grid-cols-2 w-full p-2 justify-center items-center rounded cursor-pointer border border-gray-300"
                  onClick={() => {
                    setFiles([]);
                    setImage('');
                  }}
                >
                  <div className="p-2 flex flex-row justify-start items-center">
                    <img
                      alt=""
                      className="h-7 w-7 mr-2"
                      src={files[0]?.preview || image}
                    />

                    {files[0]?.name ||
                      programData?.blobUri?.split('/').reverse()[0]}
                  </div>
                  <div className="p-2 flex flex-row justify-end items-center">
                    <ActionStyledAsButton danger variant="text">
                      <XCircleIcon className="h-3 w-3 mr-2" />
                      {t('global.image.delete')}
                    </ActionStyledAsButton>
                  </div>
                </div>
              ) : (
                <div
                  {...getRootProps()}
                  className="flex flex-row gap-x-3 justify-center items-center p-7 rounded border border-gray-300"
                >
                  <Input
                    htmlFor="updateProgramDialog-file"
                    type="file"
                    {...getInputProps()}
                  />
                  <div className="w-full flex flex-row justify-center items-center">
                    <ArrowUpTrayIcon
                      className="h-3 w-3 mr-2"
                      id="updateProgramDialog-file"
                    />
                    {t('global.drag_and_drop_or_browse.image')}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('global.example')}</Label>
              <SubjectCard
                color={color}
                image={files[0]?.preview || image}
                title={name}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton onClick={onDismiss} variant="text">
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton type="submit">
            {t('global.update')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
