import { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import ClassDetail from 'pages/ClassDetail/ClassDetail';
import SchooladminClassProfile from 'pages/ClassProfile/SchooladminClassProfile';
import { Error403 } from 'pages/Error/Error403';
import { Error404 } from 'pages/Error/Error404';
import { ExerciseContainer } from 'pages/Exercise/student/ExerciseContainer';
import OpenExercise from 'pages/Exercise/teacher/OpenExercise';
import { ExercisesOverviewStudentContainer } from 'pages/ExercisesOverview/ExercisesOverviewStudentContainer';
import { HackRoomContainer } from 'pages/HackRoom/HackRoomContainer';
import { HelpRedirect, HELPTYPES } from 'pages/HelpVideos/HelpRedirect';
import StudentContainerHome from 'pages/Home/StudentContainer';
import TeacherContainerHome from 'pages/Home/TeacherContainer';
import SchooladminImport from 'pages/Import/SchooladminImport';
import Logout from 'pages/Logout/Logout';
import ManageAdmin from 'pages/ManageAdmin/Manage';
import ChapterContainer from 'pages/ManageAdmin/ManagePrograms/Chapter/ChapterContainer';
import ChapterContainerSchoolAdmin from 'pages/ManageAdmin/ManagePrograms/Chapter/ChapterContainerSchoolAdmin';
import ManageSchooladmin from 'pages/ManageSchoolAdmin/ManageSchooladmin';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import Profile from 'pages/Profile/Profile';
import { CalendarItemResultsOverviewContainer } from 'pages/Learn/CalendarItemResults/CalendarItemResultsOverviewContainer';
import LessonResultsOverviewContainer from 'pages/Learn/ChapterItemOverview/LessonResultsOverview/LessonResultsOverviewContainer';
import StudentOwnContentOverviewContainer from 'pages/Learn/ChapterItemOverview/StudentOwnContentOverviewContainer';
import TeacherOwnLessonsOverviewContainer from 'pages/Learn/ChapterItemOverview/TeacherOwnLessonsOverviewContainer';
import LearnContainer from 'pages/Learn/LearnContainer';
import { HackRoomLessonViewerContainer } from 'pages/SlideViewer/HackRoomLessonViewerContainer';
import SlideViewerContainer from 'pages/SlideViewer/SlideViewerContainer';
import { StudentResultsForLesson } from 'pages/StudentResults/StudentResultsForLesson';
import UserProfileSchooladminContainer from 'pages/UserProfile/UserProfileSchooladminContainer';
import UserProfileTeacherContainer from 'pages/UserProfile/UserProfileTeacherContainer';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import URLS, {
  adminTabs,
  chapterItemTabs,
  classDetailTabs,
  organizationTabs,
  profileTab,
  schooladminTabs,
  smartschoolSyncTabs,
  studentResultTab,
  teamsSyncTabs,
  userProfileTabs,
} from 'utils/constants/urls';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl-BE';
import fr from 'date-fns/locale/fr';
import Tools from 'pages/Tools/Tools';
import MoveCopyLesson from 'pages/Tools/MoveLesson/MoveCopyLesson';
import { TOOLS_TYPES } from 'utils/constants/toolsTypes';
import { Impersonate } from 'pages/Impersonate/Impersonate';
import ManageProgramUsageContainerSchoolAdmin from 'pages/ManageAdmin/ManagePrograms/ManageProgramUsage/ManageProgramUsageContainerSchoolAdmin';
import { themeColors } from '@ftrprf/tailwind-components';
import { IDPS } from 'utils/constants/idp';
import CodeFeverLanding from 'pages/CodeFever/CodeFeverLanding';
import ManageOrganizationsContainer from 'pages/ManageAdmin/ManageOrganizations/ManageOrganizationsContainer';
import ManageTags from 'pages/ManageAdmin/ManageTags/ManageTags';
import { ManageReports } from 'pages/ManageAdmin/ManageReports/ManageReports';
import ManageProgramsContainer from 'pages/ManageAdmin/ManagePrograms/ManageProgramsContainer';
import { ManagePeriodsContainer } from 'pages/ManageAdmin/ManagePeriods/ManagePeriodsContainer';
import OrganizationProfile from 'pages/OrganizationProfile/OrganizationProfile';
import ManageOrganizationPrograms from 'pages/OrganizationProfile/partials/ManageOrganizationPrograms';
import ManageUsersContainer from 'pages/ManageSchoolAdmin/ManageUsers/ManageUsersContainer';
import ManageClassesContainer from 'pages/ManageSchoolAdmin/ManageClasses/ManageClassesContainer';
import ManageProgramsContainerSchooladmin from 'pages/ManageAdmin/ManagePrograms/ManageProgramsContainerSchooladmin';
import LessonsOverviewContainer from 'pages/ClassDetail/LessonsOverview/LessonsOverviewContainer';
import StudentsOverviewContainer from 'pages/ClassDetail/StudentsOverview/StudentsOverviewContainer';
import ResponsesTeacher from 'pages/StudentResults/partials/ResponsesTeacher';
import ScratchExercisesTeacher from 'pages/StudentResults/partials/ScratchExercisesTeacher';
import ScratchExercisesStudent from 'pages/StudentResults/partials/ScratchExercisesStudent';
import ResponsesStudent from 'pages/StudentResults/partials/ResponsesStudent';
import UserInformation from 'pages/UserProfile/partials/UserInformation';
import { UserExercises } from 'pages/UserProfile/partials/UserExercises';
import UserResults from 'pages/UserProfile/partials/UserResults';
import ProfileInfo from 'pages/Profile/sections/ProfileInfo/ProfileInfo';
import { Notifications } from 'pages/Profile/sections/Notifications/Notifications';
import { Preferences } from 'pages/Profile/sections/Preferences/Preferences';
import ClassesOverviewContainer from 'pages/ClassesOverview/ClassesOverviewContainer';
import SmartschoolPopup from 'pages/UseInSmartschool/SmartschoolPopup';
import { MultiScreenMode } from 'pages/SlideViewer/MultiScreenMode';
import ProfileAvatar from 'pages/Profile/sections/Avatar/ProfileAvatar';
import { ChapterItemReportContainer } from 'pages/Learn/ChapterItemOverview/ChapterReport/ChapterItemReportContainer';
import { TeacherChapterItemOverviewWrapper } from 'pages/Learn/ChapterItemOverview/TeacherChapterItemOverviewWrapper';
import { ErrorBoundaryWrapper } from 'components/ErrorBoundaryWrapper';
import { TeamsSync } from 'pages/ManageSchoolAdmin/TeamsSync/TeamsSync';
import { TeamsPreferences } from 'pages/Profile/sections/Teams/TeamsPreferences';
import { Error401 } from 'pages/Error/Error401';
import CodeFeverLogout from 'pages/CodeFever/CodeFeverLogout';
import ErrorLogin from 'pages/Error/ErrorLogin';
import { SynchronizeTeams } from '../pages/ManageSchoolAdmin/TeamsSync/partials/SynchronizeTeams';
import { TeamsStatus } from '../pages/ManageSchoolAdmin/TeamsSync/partials/TeamsStatus';
import ManageOrganizationUsers from '../pages/OrganizationProfile/partials/ManageOrganizationUsers';
import FullPageLayout from './layouts/FullPageLayout';
import GeneralLayout from './layouts/GeneralLayout';
import LoggedOutLayout from './layouts/LoggedOutLayout';
import SlideviewerLayout from './layouts/SlideviewerLayout';

import { handleRedirectAndLocalStorage } from './partials/handleRedirectAndLocalStorage';
import { setNavigationClient } from './partials/setNavigationClient';
import ManageAdminIndex from './indexNavigates/ManageAdminIndex';
import ManageOrganizationIndex from './indexNavigates/ManageOrganizationIndex';
import ManageSchooladminIndex from './indexNavigates/ManageSchooladminIndex';
import StudentResultsForLessonIndex from './indexNavigates/StudentResultsForLessonIndex';
import ClassgroupStudentProfileIndex from './indexNavigates/ClassgroupStudentProfileIndex';
import ProfileIndex from './indexNavigates/ProfileIndex';
import ChapterItemIndex from './indexNavigates/ChapterItemIndex';
import TeamsSyncIndex from './indexNavigates/TeamsSyncIndex';
import { SmartschoolSync } from '../pages/ManageSchoolAdmin/SmartschoolSync/SmartschoolSync';
import SmartschoolSyncIndex from './indexNavigates/SmartschoolSyncIndex';
import { SynchronizeSmartschool } from '../pages/ManageSchoolAdmin/SmartschoolSync/partials/SynchronizeSmartschool';
import { SmartschoolStatus } from '../pages/ManageSchoolAdmin/SmartschoolSync/partials/SmartschoolStatus';

export default function App() {
  const navigate = useNavigate();
  const { redirect } = useParams();

  const { inProgress, instance } = useMsal();

  setNavigationClient(instance, navigate);
  const account = instance.getActiveAccount();

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    handleRedirectAndLocalStorage(account, inProgress, instance);
  }, [account, inProgress, instance]);

  if (window.location.pathname.startsWith(URLS.CALLBACK)) {
    window.location = redirect;
  }

  const t = useFormatMessage();

  const user = useContext(UserContext);
  const {
    hasRoles,
    initialToken,
    isAdmin,
    isRefetchingUserInfo,
    isSchoolAdmin,
    isSmartschoolUser,
    isStudent,
    isTeacher,
    language,
    organization,
  } = user;
  const {
    id: organizationId,
    idp,
    isCodefever,
    isSmartschool,
    isTeams,
    showCalendar,
  } = organization;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('G-0XZMQ7JR66');
    }

    registerLocale('nl', nl);
    registerLocale('fr', fr);
  }, []);

  useEffect(() => {
    setDefaultLocale(language);
  }, [language]);

  useEffect(() => {
    // Set CSS variable to ::root to the brand colors for the selected IDP
    const currentColors = themeColors[idp] ?? themeColors[IDPS.FTRPRF];

    Object.keys(currentColors).forEach((key) => {
      document.documentElement.style.setProperty(
        `--accent-color-${key}`,
        currentColors[key],
      );
    });
  }, [idp]);

  return (
    <ErrorBoundaryWrapper>
      <div className="antialiased w-full h-full flex flex-col flex-grow items-center relative">
        <Routes>
          <Route
            element={
              <FullPageLayout>
                <Logout />
              </FullPageLayout>
            }
            path={URLS.LOGOUT}
          />

          <Route
            element={
              <FullPageLayout>
                <ErrorLogin />
              </FullPageLayout>
            }
            path={URLS.LOGIN_FAILED}
          />

          {!account && (
            <>
              <Route
                element={
                  <LoggedOutLayout>
                    <PrivacyPolicy />
                  </LoggedOutLayout>
                }
                path={URLS.TERMS_OF_SERVICE}
              />
              <Route
                element={
                  <LoggedOutLayout>
                    <PrivacyPolicy />
                  </LoggedOutLayout>
                }
                path={URLS.PRIVACY_POLICY}
              />
            </>
          )}
          {/*
            Wait for organisation ID so we're sure organisationInfo is loaded,
            so we know the isCodeFever for example. If we don't the 404 is the only known page
            for a fraction of a second and flashes.
           */}
          {account && initialToken && (organizationId || isAdmin) && (
            <>
              <Route
                element={
                  <GeneralLayout>
                    <CodeFeverLanding />
                  </GeneralLayout>
                }
                path={URLS.CODEFEVER}
              />

              <Route
                element={
                  <GeneralLayout>
                    <CodeFeverLogout />
                  </GeneralLayout>
                }
                path={URLS.CODEFEVER_LOGOUT}
              />
              {isCodefever && (
                <Route
                  element={
                    <GeneralLayout>
                      <Error403 />
                    </GeneralLayout>
                  }
                  path="/"
                />
              )}
              {(isTeacher || isStudent || isSchoolAdmin) && !isCodefever && (
                <Route
                  element={
                    <GeneralLayout>
                      <HackRoomContainer />
                    </GeneralLayout>
                  }
                  path={URLS.HACKROOM}
                />
              )}

              {isStudent && !isCodefever && (
                <>
                  {showCalendar && (
                    <Route
                      element={
                        <GeneralLayout>
                          <StudentContainerHome />
                        </GeneralLayout>
                      }
                      path={URLS.HOME}
                    />
                  )}
                  <Route
                    element={
                      <GeneralLayout>
                        <ExercisesOverviewStudentContainer />
                      </GeneralLayout>
                    }
                    path={URLS.EXERCISE_OVERVIEW}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <StudentOwnContentOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.OWN_LESSONS_OVERVIEW}
                  />
                  <Route
                    element={<StudentResultsForLesson />}
                    path={URLS.STUDENT_RESULT_PAGE_FOR_LESSON_PROGRAM}
                  >
                    <Route element={<StudentResultsForLessonIndex />} index />
                    <Route
                      element={<ResponsesStudent />}
                      path={studentResultTab.RESPONSES}
                    />
                    <Route
                      element={<ScratchExercisesStudent />}
                      path={studentResultTab.SCRATCH_EXERCISES}
                    />
                  </Route>
                </>
              )}

              <Route
                element={
                  <GeneralLayout>
                    <Impersonate />
                  </GeneralLayout>
                }
                path={URLS.IMPERSONATE}
              />

              {!isCodefever && (
                <Route
                  element={
                    <SlideviewerLayout>
                      <HackRoomLessonViewerContainer />
                    </SlideviewerLayout>
                  }
                  path={URLS.SLIDEVIEWER_HACKROOM_LESSON}
                />
              )}

              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW_SLIDE}
              />
              {isTeacher && (
                <>
                  <Route
                    element={<MultiScreenMode />}
                    path={URLS.MULTI_SCREEN}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <LearnContainer />
                      </GeneralLayout>
                    }
                    path={URLS.LEARN_TAB_PROGRAM_CHAPTER}
                  >
                    <Route element={<ChapterItemIndex />} index />
                    <Route
                      element={<TeacherChapterItemOverviewWrapper />}
                      path={chapterItemTabs.LESSONS}
                    />
                    <Route
                      element={<ChapterItemReportContainer />}
                      path={chapterItemTabs.PROGRESS}
                    />
                  </Route>
                </>
              )}
              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID}
              />
              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID_SLIDEID}
              />
              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW}
              />
              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE}
              />
              <Route
                element={
                  <SlideviewerLayout>
                    <SlideViewerContainer />
                  </SlideviewerLayout>
                }
                path={URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID}
              />
              <Route
                element={<Navigate replace to={URLS.LEARN} />}
                path={URLS.SLIDEVIEWER}
              />

              {!isStudent && !isCodefever && (
                <>
                  <Route
                    element={<HelpRedirect type={HELPTYPES.CATEGORY} />}
                    path={URLS.HELP_CATEGORY}
                  />
                  <Route
                    element={<HelpRedirect type={HELPTYPES.VIDEO} />}
                    path={URLS.HELP_VIDEO}
                  />
                  <Route element={<HelpRedirect />} path={URLS.HELP} />
                </>
              )}

              {isTeacher && !isCodefever && (
                <>
                  <Route
                    element={
                      <GeneralLayout>
                        <UserProfileTeacherContainer />
                      </GeneralLayout>
                    }
                    path={URLS.CLASSGROUP_STUDENT_PROFILE}
                  >
                    <Route element={<ClassgroupStudentProfileIndex />} index />
                    <Route
                      element={<UserInformation />}
                      path={userProfileTabs.INFO}
                    />
                    <Route
                      element={<UserResults />}
                      path={userProfileTabs.RESULTS}
                    />

                    <Route
                      element={<UserExercises />}
                      path={userProfileTabs.EXERCISES}
                    />
                  </Route>

                  <Route
                    element={<ClassDetail />}
                    path={URLS.CLASSGROUP_DETAIL}
                  >
                    <Route element={<ManageSchooladminIndex />} index />
                    <Route
                      element={<StudentsOverviewContainer />}
                      path={classDetailTabs.STUDENTS}
                    />
                    <Route
                      element={<LessonsOverviewContainer />}
                      path={classDetailTabs.LESSONS}
                    />
                  </Route>

                  <Route
                    element={
                      <GeneralLayout>
                        <ClassesOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.CLASSGROUP}
                  />
                  <Route
                    element={<SmartschoolPopup />}
                    path={URLS.USE_IN_SMARTSCHOOL}
                  />
                  {showCalendar && (
                    <Route
                      element={
                        <GeneralLayout>
                          <TeacherContainerHome />
                        </GeneralLayout>
                      }
                      path={URLS.HOME}
                    />
                  )}
                  <Route
                    element={
                      <GeneralLayout>
                        <CalendarItemResultsOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.CALENDAR_ITEM_RESULTS}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <TeacherOwnLessonsOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.OWN_LESSONS_OVERVIEW}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <LessonResultsOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.OWN_LESSONS_RESULTS}
                  />

                  <Route
                    element={<StudentResultsForLesson />}
                    path={URLS.STUDENT_RESULT_PAGE_FOR_LESSON_PROGRAM}
                  >
                    <Route element={<StudentResultsForLessonIndex />} index />
                    <Route
                      element={<ResponsesTeacher />}
                      path={studentResultTab.RESPONSES}
                    />
                    <Route
                      element={<ScratchExercisesTeacher />}
                      path={studentResultTab.SCRATCH_EXERCISES}
                    />
                  </Route>
                </>
              )}

              {isCodefever ? (
                <Route element={<Profile />} path={URLS.PROFILE}>
                  <Route
                    element={<Preferences />}
                    path={profileTab.PREFERENCES}
                  />
                </Route>
              ) : (
                <>
                  <Route element={<Profile />} path={URLS.PROFILE}>
                    <Route element={<ProfileIndex />} index />
                    <Route
                      element={<ProfileInfo />}
                      path={profileTab.ACCOUNT}
                    />
                    <Route
                      element={<Preferences />}
                      path={profileTab.PREFERENCES}
                    />

                    {(isTeacher || isStudent) && (
                      <Route
                        element={<Notifications />}
                        path={profileTab.NOTIFICATIONS}
                      />
                    )}

                    {!isAdmin && (
                      <Route
                        element={<ProfileAvatar />}
                        path={profileTab.AVATAR}
                      />
                    )}

                    {isTeams && isTeacher && (
                      <Route
                        element={<TeamsPreferences />}
                        path={profileTab.TEAMS}
                      />
                    )}
                  </Route>

                  <Route
                    element={
                      <FullPageLayout>
                        <ExerciseContainer />
                      </FullPageLayout>
                    }
                    path={URLS.EXERCISE_NEW}
                  />
                </>
              )}

              <Route
                element={
                  <FullPageLayout>
                    <ExerciseContainer />
                  </FullPageLayout>
                }
                path={URLS.EXERCISE_DETAIL}
              />
              <Route
                element={
                  <FullPageLayout>
                    <ExerciseContainer />
                  </FullPageLayout>
                }
                path={URLS.EXERCISE_START}
              />
              <Route
                element={
                  <FullPageLayout>
                    <ExerciseContainer />
                  </FullPageLayout>
                }
                path={URLS.EXERCISE_START_EXERCISEVERSIONID}
              />
              <Route
                element={
                  <FullPageLayout>
                    <ExerciseContainer />
                  </FullPageLayout>
                }
                path={URLS.EXERCISE_START_EXERCISEVERSIONID_LESSONSESSIONID}
              />

              {isSchoolAdmin && !isCodefever && (
                <>
                  <Route element={<ManageSchooladmin />} path={URLS.MANAGE}>
                    <Route element={<ManageSchooladminIndex />} index />
                    <Route
                      element={<ManageUsersContainer />}
                      path={schooladminTabs.USERS}
                    />
                    {isSmartschool && (
                      <Route
                        element={<SmartschoolSync />}
                        path={schooladminTabs.SMARTSCHOOL}
                      >
                        <Route element={<SmartschoolSyncIndex />} index />
                        <Route
                          element={<SynchronizeSmartschool />}
                          path={smartschoolSyncTabs.SYNC}
                        />
                        <Route
                          element={<SmartschoolStatus />}
                          path={smartschoolSyncTabs.STATUS}
                        />
                      </Route>
                    )}
                    {isTeams && (
                      <Route
                        element={<TeamsSync />}
                        path={schooladminTabs.TEAMS}
                      >
                        <Route element={<TeamsSyncIndex />} index />
                        <Route
                          element={<SynchronizeTeams />}
                          path={teamsSyncTabs.SYNC}
                        />
                        <Route
                          element={<TeamsStatus />}
                          path={teamsSyncTabs.STATUS}
                        />
                      </Route>
                    )}
                    <Route
                      element={<ManageClassesContainer />}
                      path={schooladminTabs.CLASSES}
                    />
                    <Route
                      element={<ManageProgramsContainerSchooladmin />}
                      path={schooladminTabs.PROGRAMS}
                    />
                  </Route>

                  <Route
                    element={
                      <GeneralLayout>
                        <SchooladminImport />
                      </GeneralLayout>
                    }
                    path={URLS.IMPORT}
                  />

                  <Route
                    element={
                      <GeneralLayout>
                        <UserProfileSchooladminContainer />
                      </GeneralLayout>
                    }
                    path={URLS.USER_PROFILE}
                  >
                    <Route element={<UserInformation />} index />
                  </Route>

                  <Route
                    element={
                      <GeneralLayout>
                        <UserProfileSchooladminContainer />
                      </GeneralLayout>
                    }
                    path={URLS.USER_PROFILE_VIA_CLASS}
                  >
                    <Route element={<UserInformation />} index />
                  </Route>

                  <Route
                    element={
                      <GeneralLayout>
                        <SchooladminClassProfile />
                      </GeneralLayout>
                    }
                    path={URLS.MANAGE_CLASS_DETAILS}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <SchooladminClassProfile showArchived />
                      </GeneralLayout>
                    }
                    path={URLS.MANAGE_CLASS_DETAILS_ALL}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <ManageProgramUsageContainerSchoolAdmin />
                      </GeneralLayout>
                    }
                    path={URLS.MANAGE_PROGRAM_USAGE}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <ChapterContainerSchoolAdmin />
                      </GeneralLayout>
                    }
                    path={URLS.MANAGE_PROGRAM_DETAILS}
                  />
                </>
              )}
              {isAdmin && (
                <>
                  <Route
                    element={
                      <GeneralLayout>
                        <ChapterContainer />
                      </GeneralLayout>
                    }
                    path={URLS.MANAGE_PROGRAM_DETAILS}
                  />

                  <Route
                    element={<OrganizationProfile />}
                    path={URLS.MANAGE_ORGANIZATION}
                  >
                    <Route element={<ManageOrganizationIndex />} index />
                    <Route
                      element={<ManagePeriodsContainer />}
                      path={organizationTabs.PERIODS}
                    />
                    <Route
                      element={<ManageReports />}
                      path={organizationTabs.REPORTS}
                    />
                    <Route
                      element={<ManageOrganizationUsers />}
                      path={organizationTabs.USERS}
                    />
                    <Route
                      element={<ManageOrganizationPrograms />}
                      path={organizationTabs.PROGRAMS}
                    />
                  </Route>

                  <Route element={<ManageAdmin />} path={URLS.MANAGE}>
                    <Route element={<ManageAdminIndex />} index />
                    <Route
                      element={<ManageOrganizationsContainer />}
                      path={adminTabs.ORGANIZATIONS}
                    />
                    <Route
                      element={<ManageProgramsContainer />}
                      path={adminTabs.PROGRAMS}
                    />
                    <Route
                      element={<ManageReports />}
                      path={adminTabs.REPORTS}
                    />
                    <Route element={<ManageTags />} path={adminTabs.TAGS} />
                  </Route>

                  <Route element={<Tools />} path={URLS.TOOLS}>
                    <Route
                      element={<Navigate replace to={URLS.TOOLS_MOVE_LESSON} />}
                      index
                    />
                    <Route
                      element={<MoveCopyLesson method={TOOLS_TYPES.MOVE} />}
                      path={URLS.TOOLS_MOVE_LESSON}
                    />
                    <Route
                      element={<MoveCopyLesson method={TOOLS_TYPES.COPY} />}
                      path={URLS.TOOLS_COPY_LESSON}
                    />
                  </Route>
                </>
              )}

              {(isStudent || isTeacher) && !isCodefever && (
                <>
                  <Route
                    element={
                      <GeneralLayout>
                        <LearnContainer />
                      </GeneralLayout>
                    }
                    path={URLS.LEARN}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <LearnContainer />
                      </GeneralLayout>
                    }
                    path={URLS.LEARN_PROGRAM}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <LearnContainer />
                      </GeneralLayout>
                    }
                    path={URLS.LEARN_PROGRAM_CHAPTER}
                  />
                  <Route
                    element={
                      <GeneralLayout>
                        <LessonResultsOverviewContainer />
                      </GeneralLayout>
                    }
                    path={URLS.LESSON_RESULTS}
                  />

                  <Route
                    element={
                      <FullPageLayout>
                        <OpenExercise />
                      </FullPageLayout>
                    }
                    path={URLS.EXERCISE_OPEN}
                  />
                </>
              )}

              {(isAdmin || isSchoolAdmin) && !isCodefever && (
                <>
                  <Route
                    element={<Navigate replace to={URLS.MANAGE} />}
                    path={URLS.HOME}
                  />
                  <Route
                    element={<Navigate replace to={URLS.MANAGE} />}
                    path={URLS.ROOT}
                  />
                </>
              )}
              {organization.showCalendar === false && !isCodefever && (
                <Route
                  element={<Navigate replace to={URLS.LEARN} />}
                  path={URLS.ROOT}
                />
              )}
              {organization.showCalendar === true && !isCodefever && (
                <Route
                  element={<Navigate replace to={URLS.HOME} />}
                  path={URLS.ROOT}
                />
              )}

              <Route element={null} path={URLS.ROOT} />

              {/* smartschool user without roles => newly created smartschooluser */}
              {!hasRoles && isSmartschoolUser && (
                <Route
                  element={
                    <GeneralLayout>
                      <Error403 message={t('errors.message.smartschool')} />
                    </GeneralLayout>
                  }
                  path="*"
                />
              )}

              {isCodefever && (
                <Route
                  element={
                    <GeneralLayout>
                      <Error403 />
                    </GeneralLayout>
                  }
                  path="*"
                />
              )}

              <Route
                element={
                  <GeneralLayout>
                    <PrivacyPolicy />
                  </GeneralLayout>
                }
                path={URLS.TERMS_OF_SERVICE}
              />
              <Route
                element={
                  <GeneralLayout>
                    <PrivacyPolicy />
                  </GeneralLayout>
                }
                path={URLS.PRIVACY_POLICY}
              />

              <Route
                element={
                  <GeneralLayout>
                    <Error404 />
                  </GeneralLayout>
                }
                path="*"
              />
            </>
          )}

          {Boolean(initialToken && !hasRoles && !isRefetchingUserInfo) && (
            <Route
              element={
                <LoggedOutLayout>
                  <Error401 />
                </LoggedOutLayout>
              }
              path="*"
            />
          )}
        </Routes>
      </div>
    </ErrorBoundaryWrapper>
  );
}
