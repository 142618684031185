import { useContext } from 'react';
import classNames from 'classnames';

import c from '../../utils/c';

import { ResponsiveTableContext } from './ResponsiveTableContext';

export function ResponsiveTableCol({
  children = null,
  className = '',
  extendClassNames = '',
  header = null,
  index = null,
  turnHeaders = false,
  whitespaceNoWrapOnMobile = true,
  wrapRow = false,
}) {
  const { headers, isMobile, mobileHeaderWidth } = useContext(
    ResponsiveTableContext,
  );

  if (isMobile) {
    return (
      <tr className={classNames(['divide-x', className])}>
        <th
          className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50"
          scope="row"
          style={{ width: mobileHeaderWidth }}
        >
          {header || (headers[index]?.label ?? '')}
        </th>
        <td
          className={c(
            'px-4 py-2',
            whitespaceNoWrapOnMobile && 'whitespace-nowrap',
            extendClassNames,
          )}
        >
          {children}
        </td>
      </tr>
    );
  }

  return (
    <td
      className={
        className ||
        c(
          'px-6 py-4',
          !wrapRow && 'whitespace-nowrap',
          extendClassNames,
          turnHeaders ? 'w-10 align-middle text-center' : 'w-auto',
        )
      }
    >
      {children}
    </td>
  );
}
