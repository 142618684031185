import { useContext, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useTeacherChapterItemsByChapterId from 'hooks/api/plannerService/teacher/queries/useTeacherChapterItemsByChapterId';
import useLessons from 'hooks/api/plannerService/shared/useLessons';
import usePrograms from 'hooks/api/plannerService/shared/usePrograms';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import URLS from 'utils/constants/urls';
import LessonResultsOverview from './LessonResultsOverview';

export default function LessonResultsOverviewContainer() {
  const { chapterId, chapterItemId, programId, studioId } = useParams();
  const t = useFormatMessage();
  const { isTeacher } = useContext(UserContext);

  const [filterLessons, setFilterLessons] = useState('');
  const [sortLessons, setSortLessons] = useState({
    startTime: sortOperation.ASC,
  });
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [pageTitle, setPageTitle] = useState();
  const isOwnLesson = !!studioId;

  const debouncedFilterLessons = useDebounce(filterLessons, 200);

  const { data, isLoadingTeacherChapterItems } =
    useTeacherChapterItemsByChapterId({
      enabled: !isOwnLesson && isTeacher,
      chapterId,
      filter: [
        {
          key: 'name',
          value: debouncedFilterLessons,
          operation: filterOperation.LIKE,
        },
      ],
      page: 0,
      size: 10,
    });

  const { data: lessonsData, isLoading: isLoadingLessonOverviews } = useLessons(
    {
      filter: [
        chapterItemId && {
          key: 'chapterItemId',
          value: chapterItemId,
          operation: filterOperation.EQUAL,
        },
        studioId && {
          key: 'studioId',
          value: studioId,
          operation: filterOperation.EQUAL,
        },
        {
          key: 'classGroupName',
          value: debouncedFilterLessons,
          operation: filterOperation.LIKE,
        },
      ].filter((filterItem) => !!filterItem),
      sort: sortLessons,
      page: page - 1,
      size: itemsPerPage,
    },
  );

  const { data: programsData } = usePrograms();

  const program = programsData?.find(
    (item) => String(item.id) === String(programId),
  );
  const chapterItem = program?.chapters?.find(
    (item) => String(item.id) === String(chapterId),
  );

  const breadcrumbsLinks = isOwnLesson
    ? [
        { href: URLS.LEARN, name: t('curricula.overview') },
        { href: URLS.OWN_LESSONS_OVERVIEW, name: t('curricula.lessons.own') },
        { name: pageTitle || '' },
      ]
    : [
        { href: URLS.LEARN, name: t('curricula.overview') },
        {
          href: generatePath(URLS.LEARN_PROGRAM, {
            programId,
          }),
          name: program?.name || t('global.PROGRAM.singular'),
        },
        {
          href: generatePath(URLS.LEARN_PROGRAM_CHAPTER, {
            programId,
            chapterId,
          }),
          name: chapterItem?.name,
        },
        {
          name: pageTitle || '',
        },
      ];

  useEffect(() => {
    if (pageTitle?.length) return;

    if (isOwnLesson && lessonsData?.content.length) {
      setPageTitle(lessonsData?.content[0]?.lessonContentTitle);
      return;
    }

    if (!isOwnLesson && data?.content.length) {
      setPageTitle(
        data?.content.find((item) => String(item.id) === String(chapterItemId))
          ?.name ??
          chapterItem?.items?.find(
            (item) => String(item.id) === String(chapterItemId)?.name,
          ),
      );
    }
  }, [
    chapterItem?.items,
    chapterItemId,
    data,
    isOwnLesson,
    lessonsData,
    pageTitle,
  ]);

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        subtitle={t('results-overview.title.results')}
        title={
          isOwnLesson
            ? pageTitle
            : `${t('global.CHAPTER.singular')}${
                pageTitle ? ` - ${pageTitle}` : ''
              }`
        }
      />
      <LessonResultsOverview
        currentPage={page}
        isLoading={isLoadingLessonOverviews || isLoadingTeacherChapterItems}
        itemsPerPage={itemsPerPage}
        lessonsData={lessonsData}
        setFilter={(value) => {
          setPage(1);
          setFilterLessons(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        setSort={setSortLessons}
        sort={sortLessons}
      />
    </>
  );
}
