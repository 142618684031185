import { gql } from 'graphql-request';

export const TEACHER_CHAPTER_REPORT = gql`
  query teacherChapterReport(
    $chapterId: Int!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherChapterReport(
      chapterId: $chapterId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        userName
        firstName
        lastName
        fullName
        avatar
        classGroups {
          id
          name
        }
        chapterItems {
          id
          name
          weight
          calendarItems {
            id
            startTime
            endTime
            viewMode
            reportOverallScores {
              amountOfQuestions
              answeredQuestions
              calendarItem {
                id
                sessionId
              }
              questionScoresEarned
              questionScoresMax
              questionsToGrade
              userId
              viewMode
            }
          }
        }
      }
    }
  }
`;
