import { useContext, useEffect, useState } from 'react';

import { Toggle } from '@ftrprf/tailwind-components';

import Divider from 'components/Divider';

import { UserContext } from 'providers/UserProvider';

import useUpdateOwnUserUiSettings from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserUiSettings';
import useFormatMessage from 'hooks/useFormatMessage';
import { cloneDeep } from 'lodash-es';

export default function Calendar() {
  const { refetchSettings, settings } = useContext(UserContext);

  const t = useFormatMessage();
  const [fullWeek, setFullWeek] = useState(settings.calendar?.fullWeek);
  const [extendedHours, setExtendedHours] = useState(
    settings?.calendar?.extendedHours,
  );
  const { mutateAsync: updateUiSettings } = useUpdateOwnUserUiSettings();

  useEffect(() => {
    const changedSettings = cloneDeep(settings);
    changedSettings.calendar = {
      extendedHours,
      fullWeek,
    };

    const aChangeWasMade = settings.calendar
      ? settings.calendar.extendedHours !== extendedHours ||
        settings.calendar.fullWeek !== fullWeek
      : extendedHours || fullWeek;

    if (aChangeWasMade) {
      updateUiSettings(changedSettings).then(() => {
        refetchSettings();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedHours, fullWeek]); // don't include all dependencies, to
  // prevent double updates/refetches

  return (
    <div>
      <Divider text={t('global.calendar')} />

      <div className="mt-4 flex flex-col lg:flex-row">
        <div className="flex-grow space-y-4">
          <Toggle
            label={t('calendar.modal.week')}
            onChange={setFullWeek}
            test="week"
            value={fullWeek}
            visibleLabel
          />
          <Toggle
            label={t('calendar.modal.normal')}
            onChange={setExtendedHours}
            test="hours"
            value={extendedHours}
            visibleLabel
          />
        </div>
      </div>
    </div>
  );
}
