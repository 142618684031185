export const french = {
  // Global
  'dropdown.no_results': "No options found",
  'global.account.avatar': 'Avatar',
  'global.account.avatar.save': "Enregistrer l'avatar",
  'global.account.info': 'Information de compte',
  'global.actions': 'Actions',
  'global.activate': 'Activer',
  'global.all': 'tout',
  'global.archive': 'Archiver',
  'global.archived': 'Archivé',
  'global.at': 'à',
  'global.buypackages': "Acheter plus de paquets",
  'global.cancel': 'Annulé',
  'global.calendar': 'Calendrier',
  'global.class': 'Class group',
  'global.close': 'Fermer',
  "global.color": "Couleur",
  'global.configure_users': 'Configure les utilisateurs',
  'global.confirm': "Créer",
  'global.content': 'Contenu',
  'global.copyToClipboard': 'Copier le texte',
  'global.deactivate': 'Désactuver',
  'global.default.via.profile.part1': 'Vous pouvez définir la valeur par défaut dans ',
  'global.default.via.profile.part2': 'votre profil.',
  'global.delete': 'Supprimer',
  'global.download': 'Télécharger',
  'global.drag_and_drop_or_browse.excel': "Glisser/déplacer un fichier excel, ou chercher dans les fichiers",
  'global.drag_and_drop_or_browse.image': "Glisser/déplacer une image, ou chercher dans les fichiers.",
  'global.edit': 'Editer',
  'global.edit_user': 'Editer un utilisateur',
  'global.edit_password': 'Editer un le mot de passe',
  'global.email': 'E-mail',
  'global.empty': "Il semble que ce soit vide ici...",
  'global.empty.adjust_filter': 'Ajuster le filtre pour voir plus de résultats.',
  'global.empty-lesson': 'Leçon vide',
  'global.error': "Oops! Quelque chose s'est mal passé",
  'global.error.message': "Nous ne savons pas exactement ce qui n'a pas fonctionné. S'il vous plait, réessayez dans quelques minutes. Si les choses continuent à mal se passer, veuillez contacter support@ftrprf.be.",
  'global.example': "Exemple",
  'global.export': 'Exporter',
  'global.file.delete': "Supprimer le fichier",
  'global.general.info': 'Informations générales',
  'global.given_by': 'Donné par',
  'global.go_back': 'Revenir en arrière',
  'global.hack_room': 'Hack Room',
  'global.hide': 'Cache',
  'global.hourAbbreviation': 'h',
  'global.idp': 'Connexion',
  'global.image.add': "Ajouter une image",
  'global.image.delete': "Supprimer une image",
  'global.image.update': "Mettre à jour une image",
  'global.impersonate': 'Imiter',
  'global.import': 'Importer',
  'global.indicator': 'indicateur',
  'global.items.per.page': 'Objets par page',
  'global.link.classgroups': "Ajouter un class group",
  'global.link.tags': "Ajouter une balise",
  'global.link.programs': 'Ajouter un programme',
  'global.loading': 'Loading...',
  'global.login.with.microsoft': 'Connectez-vous avec Microsoft',
  'global.manual': 'Manuel',
  'global.name': "Nom",
  'global.next': "Suivant",
  'global.none': 'Aucun',
  'global.no_results': 'Pas de résultats trouvés',
  'global.not.planned': 'Pas encore programmé',
  'global.on': 'on',
  'global.only_word_characters': "Un préfixe de domaine ne peut contenir que des lettres, des chiffres et un trait d'union.",
  'global.oops': 'Oops',
  'global.open': 'Ouvrir',
  'global.planned': 'Programmé',
  'global.presentation': 'Presentation',
  'global.previous': 'Précédent',
  'global.program': 'Programme',
  'global.redirecting': 'Redirection...',
  'global.reload': 'Recharger',
  'global.remove': 'Enlever',
  'global.remove.classgroup': 'Enlever le class group',
  'global.role': 'Rôle',
  'global.save.changes': 'Sauvegarder les modifications',
  'global.search.by.name': 'Chercher par nom',
  'global.selected': 'Choisi',
  'global.show': 'Montre',
  'global.showErrors': 'Afficher les erreurs',
  'global.showMore': 'Plus',
  'global.smartschool': 'Smartschool',
  'global.start': 'Démarrer',
  'global.status': 'Status',
  'global.teams': 'Teams',
  'global.to': 'Á',
  'global.today': "Aujourd'hui",
  'global.tomorrow': 'Demain',
  'global.total-amount': 'nombre total',
  'global.unimpersonate': 'Fin de imiter',
  'global.unknown': 'inconnu',
  'global.usage': 'Usage',
  'global.userId': 'Identifiant utilisateur',
  "global.issuer": "Emetteur",
  'global.viewMode': "mode d'affichage",
  'global.weight': "Poids / Importance",
  'global.yesterday': 'Hier',

  // calendar
  'calendar.show': "Montrer le calendrier",

  // validation
  'global.invalid-values': 'Valeurs invalides',
  'global.max-length-255': 'Ce champ ne peut pas contenir plus de 255 caractères.',
  'global.min-length-8': 'Ce champ doit contenir au moins 8 caractères.',
  'global.min-length-12': 'Ce champ doit contenir au moins 12 caractères.',
  'global.password': 'Mot de passe',
  'global.password-pattern': 'Le mot de passe doit être composé, au minimum, de 8 caractères, une lettre minuscule, une lettre majuscule et une chiffre.',
  'global.present': 'Présent',
  'global.progress': 'Progrès',
  'global.required-field': 'Champ requis',
  'global.required-number': 'Ce champ doit être un nombre.',
  'global.reset-password': 'Reset du mot de passe',
  'global.reset-password.confirm': 'Reset',
  'global.save': 'Sauver',
  'global.save-changes': 'Sauver les changements',
  'global.select.all': 'Tout sélectionner',
  'global.select.none': 'Tout dé-sélectionner',
  'global.snooze': 'Snooze',
  'global.taskInfoStatus.all': 'Tous',
  'global.taskInfoStatus.completed': 'Terminé',
  'global.taskInfoStatus.failed': 'Échec',
  'global.taskInfoStatus.in_progress': 'En cours',
  'global.try-again': 'Essaie encore',
  'global.uuid-length': 'Un identifiant doit contenir exactement 36 caractères',
  'global.uuid-valid': "Ceci est un identifiant invalide. Un identifiant ne peut contenir que des chiffres, des lettres et des traits d'union.",
  'global.unlink.classgroup': 'Supprimer la classe',
  'global.unlink.program': 'Supprimer le programme',
  'global.unlink.tags': 'Dissocier une balise',
  'global.update': 'Mettre à jour',
  'global.username': 'Username',
  'global.view': 'Voir',
  'global.work_in_progress': 'Travail en cours',

  // accessibility-related stuff
  'global.keyboard.skiplink': 'Passer vers {destination}',
  'global.sr.about': 'A propos',

  // language
  'global.LANGUAGE.singular': 'Langage',
  'global.LANGUAGE.select': 'Selectionner langage',

  // success
  'global.activate.success': '{what} activé avec succes',
  'global.add.success': '{what} ajouté avec succes',
  'global.archive.success': '{what} archivé avec succes',
  'global.create.success': '{what} créé avec succes',
  'global.deactivate.success': '{what} désctivaté avec succes',
  'global.delete.success': '{what} supprimé avec succes',
  'global.disable.success': '{quoi} désactivé avec succès',
  'global.enable.success': '{quoi} activé avec succès',
  'global.move.success': '{what} deplacé avec succes',
  'global.post.success': 'the {what} envoyé avec succes',
  'global.remove.success': '{what} enlevé avec succes',
  'global.update.success': '{what} mis à jour avec succes',


  // error
  'global.activate.error': "Quelque chose s'est mal passé durant l'activation de {what}",
  'global.add.error': "Quelque chose s'est mal passé durant l'ajout de {what}",
  'global.archive.error': "Quelque chose s'est mal passé durant l'archivage de {what}",
  'global.create.error': "Quelque chose s'est mal passé durant la création de {what}",
  'global.deactivate.error': "Quelque chose s'est mal passé durant la désactivation de {what}",
  'global.delete.error': "Quelque chose s'est mal passé durant la suppression de {what}",
  'global.disable.error': "Quelque chose s'est mal passé durant la désactivation de {what}",
  'global.enable.error': "Quelque chose s'est mal passé durant la activation de {what}",
  'global.get.error': "Quelque chose s'est mal passé durant la récupération de {what}",
  'global.move.error': "Quelque chose s'est mal passé durant l'deplacement de {what}",
  'global.not_found.error': "{what} est introuvable",
  'global.update.error': "Quelque chose s'est mal passé durant la modification de {what}",
  'global.post.error': "Quelque chose s'est mal passé durant l'envoi de {what}",
  'global.remove.error': "Quelque chose s'est mal passé durant la suppresion de {what}",

  // generic messages
  "global.add.what": "Ajouter {what}",
  "global.create.what": "Créer {what}",
  "global.delete.what": "Supprimer {what}",
  'global.delete.what.confirm': "Êtes-vous sûr de vouloir supprimer ce {what}?",
  'global.empty.search': "Aucun {what} correspondant à ce critère de recherche n'a été trouvé.",
  "global.update.what": "Mettre à jour {what}",
  "global.link.what": "associer {what}",
  "global.unlink.what": "dissocier {what}",
  "global.manage.what": "Gérer {what}",
  'global.empty.link': "Actuellement, aucun {what} n'est lié à {thing}.",

  // warning
  'global.no.users.warning': "Pas d'utilisateur disponibles pour les tests personnalisés.",

  // global articles
  'global.THE': 'le',
  'global.THEH': 'le',
  'global.THE.plural': 'les',

  // global student
  'global.LESSON.plural': 'Leçons',
  'global.LESSON.singular': 'Leçon',
  'global.LESSON.view': 'Voir leçon',
  'global.lesson.new': 'Créer nouvelle leçon',

  // global classgroup
  'global.CLASSGROUP.id': 'ID de classe',
  'global.CLASSGROUP.name': 'Nom de la classe',
  'global.CLASSGROUP.plural': 'Groupes de classe',
  'global.CLASSGROUP.singular': 'groupe de classe',

  // global exam
  'global.EXAM.plural': 'Examens',
  'global.EXAM.singular': 'Examen',
  'global.EXAM.view': 'Voir examen',

  // global role
  'global.FTRPRF_SCHOOLSTUDENT.singular': 'Elèves',
  'global.FTRPRF_SCHOOLTEACHER.singular': 'Professeur',
  'global.FTRPRF_SCHOOLADMIN.singular': 'Directeur/admin',
  'global.FTRPRF_ADMIN.singular': 'Admin',
  'global.SCHOOLADMIN.singular': "Directeur/admin",
  'global.SCHOOLADMIN.plural': "Directeurs/Admins",

  // global enabled
  'global.enabled.true': 'Utilisateur activé',
  'global.enabled': 'Activé',
  'global.disabled': 'Désactivé',
  'global.enabled.false': 'Utilisateur désactivé',

  // global student
  'global.STUDENT.plural': 'Elèves',
  'global.STUDENT.singular': 'Elève',
  'global.STUDENT.view': 'Voir élève',

  // global class
  'global.CLASS.plural': 'Groupes de classe',
  'global.CLASS.singular': 'Groupe de classe',
  'global.CLASS.view': 'Voir le groupe de classe',

  // global result
  'global.RESULT.plural': 'Résultats',
  'global.RESULT.singular': 'Résultat',
  'global.RESULT.view': 'Voir résultat',

  // global teacher
  'global.TEACHER.plural': 'Professeurs',
  'global.TEACHER.singular': 'Professeur',
  'global.TEACHER.view': 'Voir professeur',

  // global user
  'global.USER.plural': 'Utilisateurs',
  'global.USER.singular': 'Utilisateur',
  'global.USER.view': 'Voir utilisateur',

  // global school
  'global.SCHOOL.plural': 'Ecoles',
  'global.SCHOOL.singular': 'Ecole',
  'global.SCHOOL.view': 'Voir école',

  // global program
  'global.PROGRAM.plural': 'Programmes',
  'global.PROGRAM.singular': 'Programme',
  'global.PROGRAM.view': 'Voir programme',
  'global.PROGRAM.add': 'Ajouter programme',

  // global exercise
  'global.SCRATCH_EXERCISE.plural': 'Scratch-exercices',
  'global.SCRATCH_EXERCISE.singular': 'Scratch-exercice',
  'global.SCRATCH_EXERCISE.view': "Voir l'exercice Scratch",

  // global exercise
  'global.EXERCISE.plural': 'Exercices',
  'global.EXERCISE.singular': 'Exercice',
  'global.EXERCISE.view': 'Voir exercice',

  // global test
  'global.TEST.plural': 'Tests',
  'global.TEST.singular': 'Test',
  'global.TEST.view': 'Voir le test',

  // global antwoord
  'global.RESPONSE.plural': 'Réponses',
  'global.RESPONSE.singular': 'Réponse',
  'global.RESPONSE.view': 'Voir réponses',

  // global organization
  'global.ORGANIZATION.plural': 'Organisations',
  'global.ORGANIZATION.singular': 'Organisation',
  'global.ORGANIZATION.view': 'Voir organisation',

  // global organization
  'global.FILE.plural': 'Fichiers',
  'global.FILE.singular': 'Fichier',
  'global.FILE.view': 'Voir fichier',

  // global calendaritems
  'global.CALENDARITEM.plural': 'éléments de calendrier',
  'global.CALENDARITEM.singular': 'élément de calendrier',
  'global.CALENDARITEM.view': 'Voir élément de calendrier',

  // global chapter
  'global.CHAPTER.plural': 'Chapitres',
  'global.CHAPTER.singular': 'Chapitre',
  'global.CHAPTER.view': 'Voir chapitre',

  // global chapteritem
  'global.CHAPTERITEM.plural': 'Eléments de chapitre',
  'global.CHAPTERITEM.singular': 'Elément de chapitre',
  'global.CHAPTERITEM.view': 'Voir élément de chapitre',

  // global slide
  'global.SLIDE.plural': 'Slides',
  'global.SLIDE.singular': 'Slide',
  'global.SLIDE.view': 'Voir slide',

  // global answer
  'global.ANSWER.plural': 'Réponses',
  'global.ANSWER.singular': 'Réponse',
  'global.ANSWER.view': 'Voir réponse',

  // global slide
  'global.CONTENT.singular': 'Contenu',
  'global.CONTENT.view': 'Voir contenu',

  // global notification
  "global.NOTIFICATION.plural": "Notifications",
  "global.NOTIFICATION.singular": "Notification",
  "global.NOTIFICATION.view": "Voir notification",

  // global feedback
  "global.FEEDBACK": "Feedback",
  "global.FEEDBACK.view": "Voir feedback",

  // global score
  "global.SCORE.plural": "Scores",
  "global.SCORE.singular": "Score",

  // global learn
  'global.LEARN': 'Apprendre',

  // global personality
  'global.personality.test.plural': "Test de personalité",

  // global validation
  "global.validation.unique.what": "{what} doit être unique.",

  // column headers
  'global.table-header.errorMessages': "Messages d'erreur",
  'global.table-header.imported.classes': 'Classes importées',
  'global.table-header.imported.users': 'Utilisateurs importées',
  'global.table-header.started_on': 'Démarré à',
  'global.table-header.status': 'Status',

  // assessment status
  "assessment.status.created": "Demandé",
  "assessment.status.finished": "Complété",
  "assessment.status.started": "Démarré",
  "assessment.status.not_send": "Non envoyé",

  // ChapterItem Report Table
  'chapterItemReport.dialog.title.questions': 'Nombre de questions répondues par élément de calendrier',
  'chapterItemReport.dialog.title.score': 'Scores par élément de calendrier',
  'chapterItemReport.filter.score': 'Afficher les scores',
  'chapterItemReport.filter.questions': 'Afficher le nombre de questions répondues',
  'chapterItemReport.tooltip.multiple_questions': "Il y a plusieurs éléments de calendrier, cliquez pour plus d'informations",
  'chapterItemReport.no-results-found': "Aucun résultat n'a été trouvé pour cette leçon",
  'chapterItemReport.tooltip.multiple_no_grade_classical': 'Salle de classe le {startTime}',
  'chapterItemReport.tooltip.multiple_no_grade_selfstudy': 'Tutoriel sur {startTime}',
  'chapterItemReport.tooltip.to_grade': '{amountToGrade} pour améliorer les questions ouvertes.',

  // import dialog
  "import.dialog.submission.error": "Il y a eu des problèmes avec l'import.",
  'import.dialog.description': "Seuls des fichiers excel sont acceptés. (avec l'extension: .xls and .xlsx)",
  'import.dialog.file.invalid': 'Fichier invalide',
  'import.dialog.file.unsupported': 'Type de fichier non supporté',
  'import.dialog.file.valid': 'Fichier valide',
  'import.dialog.title': 'Importer élèves et classes',
  'import.excel': 'Importer Excel',
  'import.excel.description': "Ici, vous pouvez facilement importer des élèves et/ou des professeurs.(qui sont assignés à une certaine classe) depuis un fichier excel, après avoir télécharger le template et l'avoir rempli. Après avoir rempli le template, envoyez le fichier en cliquant sur le bouton ci-dessous.",
  'import.excel.documentation': 'Voir la documentation du template',
  'import.excel.download': 'Télécharger le template',
  'import.excel.max': 'Remarque : il y a un maximum de 2000 utilisateurs/import.',
  'import.task': 'des informations',

  // viewModes
  'view-modes.projection': 'Projection',
  'view-modes.selfstudy': 'Home',
  'view-modes.classical': 'Salle de classe',

  // Header
  'header.hello': 'Hello, ',
  'header.help': 'How-to',
  'header.help_videos': 'How-to videos',
  'header.help_videos.tag': 'How-to videos par catégorie {category}',
  'header.logout': 'Log out',
  'header.manage_admin': 'Gérer directeur/admin',
  'header.manage_school': 'Gérer école',
  'header.navigation.classes': 'Groupes de classe',
  'header.navigation.exams': 'Examens',
  'header.navigation.home': 'Home',
  'header.navigation.manage': 'Gérer',
  'header.navigation.profile': 'Profil',
  'header.navigation.students': 'Pupils',
  'header.navigation.studio': 'Studio',
  'header.navigation.tools': 'Outils',
  'header.no.active.period.schooladmin': "Il n'y a actuellement aucune période active. Veuillez contacter support@ftrprf.be.",
  'header.no.active.period.studentAndTeacher': "Il n'y a actuellement aucune période active. Veuillez contacter l'administration de votre école.",
  'header.old_manage_school': 'Ancien tableau de contrôle',
  'header.profile': 'Profil',
  'header.uiSettings': 'Préférences utilisateur',

  // Calendar
  'calendar.item.view_results': 'Voir resultats',
  'calendar.modal.closeAfterEndTime': "Fermer après l'heure de fun",
  'calendar.modal.dateEvent': 'Date de la leçon',
  'calendar.modal.dateNowValidate': 'Une leçon ne peut être donnée dans le passé',
  'calendar.modal.editLessonContent': 'Voulez-vous changer la leçon?',
  'calendar.modal.endHour': 'Heure de fin',
  'calendar.modal.endMinute': 'Minute de fin',
  'calendar.modal.endTime': 'Heure de fin',
  'calendar.modal.extended': 'Voir journée',
  'calendar.modal.lesson': 'Sélectionner la version',
  'calendar.modal.normal': 'Voir journée complète',
  'calendar.modal.selectChapter': 'Sélectionne le chapitre',
  'calendar.modal.selectChapterItem': "Sélectionner l'élément de chapitre",
  'calendar.modal.selectClass': "Sélectionner groupe de classe",
  'calendar.modal.selectProgram': 'Selectionner programme',
  'calendar.modal.selectViewMode': 'Sélectionner le mode vue',
  'calendar.modal.startHour': 'Heure de démarrage',
  'calendar.modal.startMinute': 'Minute de démarrage',
  'calendar.modal.startTime': 'Heure de démarrage',
  'calendar.modal.startTime<endTime': "La date de fin ne pet pas être plus tôt que l'heure de départ.",
  'calendar.modal.teams.assignmentDescription': 'Quelle description souhaitez-vous pour cette mission ? (facultatif)',
  'calendar.modal.teams.assignmentTitle': 'Quel devrait être le titre de cette mission ?',
  'calendar.modal.teams.dateInThePast': 'La date de début que vous avez choisie pour cet élément de calendrier est passée. Malheureusement, Teams ne permet pas de créer des éléments dans le passé. Vous verrez donc votre élément dans FTRPF, mais pas dans Teams.',
  'calendar.modal.teams.resourceDisplayName': 'Quel est le ResourceDisplayName',
  'calendar.modal.title': 'Planifier une leçon',
  'calendar.modal.titleAddTittle': 'Planifier une leçon',
  'calendar.modal.week': 'Voir semaine complète',
  'calendar.modal.workweek': 'Voir semaine',
  'calendar.no_classes': 'Pas de classes',
  'calendar.no_teachers': 'Pas de professeurs',
  'calendar.title': 'Calendrier',
  'calendar.welcome': 'Welcome',

  // Quickplan Modal for Calendar
  'calendar.quickplan.close.all': 'Ferme pour tous les classes',
  'calendar.quickplan.open': 'Ouvre',
  'calendar.quickplan.open.all': 'Ouvert à toutes les classes',
  'calendar.quickplan.plan': 'Planifier dans le calendrier',
  'calendar.quickplan.remove': 'Supprimer du calendrier',

  // Side calendar classes
  'side-calendar-classes.no-classes': 'Pas de groupes de classe sélectionnés',

  // Content
  'content.settings': 'Paramètres',
  'content.end_goals': 'Objectifs finaux',
  'content.what_are_you_looking_for': 'Après quoi cherchez-vous ?',
  'content.close.dialog': "Fermer",
  'content.results': 'Resultats',
  'content.info': 'Info',
  'content.infoLessons': 'Info sur la leçon',
  'content.archive': 'Archiver',
  'content.tags': 'Tags',
  'content.copyAndEdit': 'Copie et ouverture dans Studio',
  'content.edit': 'Modifier',
  'content.unarchive': 'Désarchiver',

  // Content overview
  'content-details.status': 'Status',
  'content-details.download': 'Documents',

  // Results overview
  'results-overview.average': 'Moyenne',
  'results-overview.breadcrumbs.results.for.class': 'Résultats pour {name}',
  'results-overview.calendar_item.breadcrumb.classes': 'Résultats de groupes de classe ',
  'results-overview.calendar_item.breadcrumb.student': 'Résultats élève ',
  'results-overview.calendar_item.last_opened_at': 'Dernière ouverture à',
  'results-overview.calendar_item.no_results_scratch_no_judge': 'pas de co-enseignant',
  'results-overview.calendar_item.no-students-found': "Aucun étudient n'a été trouvé pour ce groupe de classe.",
  'results-overview.calendar_item.times_opened': "Nb d'ouverture",
  'results-overview.class': 'groupe de classe',
  'results-overview.column.graded_suffix': 'graded open questions',
  'results-overview.column.grading': 'Classification',
  'results-overview.column.name': 'Nom',
  'results-overview.column.progress': 'Progrès',
  'results-overview.column.progress_suffix': 'questions answered by the student',
  'results-overview.column.results': 'Résultats',
  'results-overview.column.score': 'Score',
  'results-overview.column.submitted_at': 'Envoyé à',
  'results-overview.export_excel': 'Excel Spreadsheet',
  'results-overview.export_failed': "L'export a échoué",
  'results-overview.export_smartschool': 'Bulletin',
  'results-overview.export_successful': 'Export complété avec succès',
  'results-overview.filter.classes': 'Filtré par les classes suivantes: ',
  'results-overview.home': 'home',
  'results-overview.made_in_version': 'Made in version',
  'results-overview.no_complete_average': "Ceci est une moyenne temporaire car tous les points n'ont pas été envoyés.",
  'results-overview.no-results': "Aucun réponse n'a été envoyée.",
  'results-overview.not_all_questions_answered_warning': 'pas répondu à toutes les questions.',
  'results-overview.not_all_questions_have_scores_warning': "des questions qui n'ont pas été quotées.",
  'results-overview.not_fully_graded': "Tout les réponses n'ont pas été quotées.",
  'results-overview.not_fully_submitted': "L'élève n'a pas encore postée toutes ses réponses.",
  'results-overview.publish_results': 'Publier les résultats',
  'results-overview.publish_results_warnings': 'Attention',
  'results-overview.student_has': "L'élève a",
  'results-overview.students_have': 'les élèves ont',
  'results-overview.title.class': 'Classe',
  'results-overview.title.results': 'Résultats',
  'results-overview.unanswered_question': 'question non répondue',
  'results-overview.unanswered_questions': 'questions non répondues',
  'results-overview.unpublish_results': 'Cacher les résultats',
  'results-overview.version': 'Version',
  'results-overview.view': 'voir',
  'results_overview.calendar_item.loading': "Chargement des données pour cet élève, ceci peut prendre quelques secondes.",
  'results_overview.calendar_item.no_results': "Cet élève n'a pas encore donné des réponses.",
  'results_overview.calendar_item.no_results_scratch': "Cet élève n'a pas encore fait des Scratch-exercices.",
  'results_overview.calendar_item.no_results_scratch_student': "Tu n'as pas encore fait des Scratch-exercices.",
  'results_overview.calendar_item.no_results_student': "Tu n'as pas encore donné des réponses.",
  'results_overview.get.student_results': "Information sur l'élève",
  'results_overview.questions.feedback': 'Feedback',
  'results_overview.results.for': "résultats pour",
  'results_overview.results.for.student': "résultat pour",
  'results_overview.score.between': 'Ce score doit être entre {min} et {max}.',
  'results_overview.student.result': "Résultat de l'élève",
  'results_overview.student.results': "Résultats de l'élève",

  'student-answers.title.results': 'Résultats',
  'student-answers.title.total': 'Total',
  'student-answers.question_label': 'question',
  'student-answers.no_questions': "Il n'y a pas de question dans ce mode de vue.",
  'student-answers.no_results_published': "Il n'y as pas encore de résultats publiés.",
  'student-answers.given_answer': 'Réponse donnée',
  'student-answers.example_solution': 'Exemple de solution',
  'student-answers.click_to_show_question': 'Montrer la question',
  'student-answers.click_to_hide_question': 'Cacher la question',
  'student-answers.no_content': 'Rien à montrer ici',
  'student-answers.correct_answer': 'La bonne réponse est :',
  'student-answers.no_answer': 'Pas de réponses-exemple fournie',

  'question-result.no-result': "Il n'y a pas de réponses pour cette question.",

  // StudentSwitcher
  'student-switcher.next': 'suivant',
  'student-switcher.previous': 'précédent',

  // Errors
  'errors.401.header': "Non autorisé",
  'errors.401.message.conclusion': "Si vous pensez voir cette page par erreur, n'hésitez pas à nous contacter à {email}.",
  'errors.401.message.cta': 'demandez une démo',
  'errors.401.message.description': "Nous ne reconnaissons pas votre compte, cela peut être dû à {amountOfReasons} raisons:",
  'errors.401.message.intro': 'Bienvenue chez FTRPRF!',
  'errors.401.message.reason.no_contract_with_ftrprf': "Votre école n'a pas signé d'accord avec nous. Pour plus d'informations, rendez-vous sur notre site web {website} ou {cta} dès maintenant!",
  'errors.401.message.reason.not_synchronized_with_smartschool': "Le responsable de votre école n'a pas encore synchronisé avec SmartSchool, veuillez contacter la personne responsable.",
  'errors.403.header': "Accès interdit",
  'errors.403.message': "Vous n'avez pas accès à cette page. Si vous pensez que vous devriez avoir accès à cette page, veuillez contacter l'administrateur de votre école.",
  'errors.404.header': 'Page non trouvée',
  'errors.404.message.main': "Vous êtes en eaux inconnues : cette page n'existe pas.",
  'errors.404.message.sub': "La page a peut-être été supprimée ou son URL a été modifiée.",
  'errors.action.home': 'Retour à la page d\'accueil',
  'errors.back': 'Retour',
  'errors.category.missing.fields.no.other.errors.clarification': "Vous trouverez ci-dessous quels utilisateurs sont impliqués et quels champs sont manquants. Nous montrons autant d'informations que possible pour faciliter la résolution de l'erreur.",
  'errors.category.missing.fields.no.other.errors.title': 'Il y avait des utilisateurs avec des champs invalides.',
  'errors.category.tooManyUsers': "Il peut y avoir un maximum de 2 000 utilisateurs dans Excel. Divisez l'Excel pour qu'il y ait un maximum de 2000 utilisateurs/fichier.",
  'errors.category.userGenerationFailed': 'Échec de la création de {utilisateur}.',
  'errors.change_eula': 'Ne parvient pas à mettre à jour EULA',
  'errors.change_language': 'Ne parvient pas à mettre à jour la langue',
  'errors.delete_chapter_item': `Il y a un ou plusieurs éléments de calendrier pour cela. Vous devez supprimer les éléments du calendrier pour supprimer cette leçon.`,
  'errors.message.no-page': "Aucune page n'a été trouvée pour cette URL.",
  'errors.message.smartschool': "Votre compte utilisateur a été créé aujorud'hui et sera actif  à partir de dmain. Vous serez ensuite capable d'explorer la plateforme d'apprentissage FTRPRF. Si vous recevez ce message pendant plusieurs jours de suite, veuillez contacter votre directeur/admin",
  'errors.no-classgroups': 'Vous n\'avez pas été associé à un groupe de classe. Veuillez contacter votre directeur/admin.',
  'errors.set_publish_status': `Ne parviens pas à mettre à jour le statut de publication.`,
  'errors.set_results_published': 'Ne parviens pas à mettre à jour les résultat comme publiés',
  'errors.unknown': "Nous ne savons pas ce qui n'a pas fonctionné. Si cela continue, veuillez contacter notre support.",
  'errors.update_score': `Ne parviens pas à mettre à jour le score.`,

  // Info Impersonator Among Us
  'impersonator.joke': "Attention, il y a un sosie!",
  'impersonator.alt': "parmi nous",
  "impersonator.header": "N'oubliez pas d'agir comme un coéquipier normal",

  // impersonate
  "impersonate.header": "Nous embarquons, veuillez rester assis...",
  "impersonate.header.failed": "Nous avons essayé de vous embarquer",
  "impersonate.description": "Malheureusement, vous ne pouvez pas encore commencer votre aventure. Veuillez réessayer dans un instant!",

  // publishStatuses
  'publish-statuses.PUBLISHED': 'Publié',
  'publish-statuses.OPEN_FOR_HOME': 'Ouvert pour auto-apprentissage',
  'publish-statuses.OPEN_FOR_CLASSGROUP': 'Ouvert pour le groupe de classe',
  'publish-statuses.CLOSED': 'Fermé',
  'publish-statuses.explanation.OPEN_FOR_HOME': 'Les élèves voient la version home.',
  'publish-statuses.explanation.OPEN_FOR_CLASSGROUP': 'Les élèves foir la version classroom.',
  'publish-statuses.explanation.CLOSED': "Les élèves ne peuvent pas voir cette leçon.",

  // curricula
  'curricula.overview': "Matériel d'apprentissage",
  'curricula.overview.button': 'Aperçu',
  'curricula.overview.plan': 'Planifier',
  'curricula.overview.plan.quick': 'Planifiez rapidement',
  'curricula.programs.empty': "Il n'y a actuellement aucun contenu associé à votre compte. Veuillez contacter l'administrateur de l'école pour lier le contenu.",
  'curricula.LESSON.content-overview.TEACHER.empty': 'Aucune leçon n\'a encore été publiée.',
  'curricula.LESSON.content-overview.STUDENT.empty': 'Aucune leçon n\'a été ouverte.',
  'curricula.lessons.own': 'Mes leçons',
  'curricula.lessons.school': "Cours à l'école",
  'curricula.lessons.own.none': 'Aucune leçon de ce type n\'a été publiée' +
    ' to date.',
  'curricula.lessons.own.create': 'Commencez par créer votre/vos élève(s)!',

  'curricula.EXAM.title': "Apercu de l'examen",
  'curricula.EXAM.content-overview.TEACHER.empty': 'Aucun examen n\'a encore été publié.',
  'curricula.EXAM.content-overview.STUDENT.empty': 'Aucun examen n\'a été ouvert.',

  // Class Group
  'class-group.name': 'Nom',
  'class-group.details': 'Détails',
  'class-group.linked': 'Attaché à',
  'class-group.errors.something_went_wrong': "Ces détails de groupe de classe ne peuvent être affichés pour le moment.",
  'class-group.same-name.error': 'Il y a déjà un groupe de classe avec ce nom.',
  'class-group.add-user-to-class-group': 'Ajouter un utilisateur à ce groupe de classe',
  'class-group.add-user': 'Ajouter utilisateurs',

  // Profile
  'profile.calendar.showFullday': 'Montrer les jours complets plutot que les heures de bureau (8 - 20:00)',
  'profile.calendar.showWeekends': 'Montrer les week-ends dans le calendrier',
  'profile.change_password': 'Changer le mot de passe',
  'profile.codeblocks': 'Thème',
  'profile.codeblocks.settings': 'Paramètres des blocs de code',
  'profile.codeblocks.showLineNumbers': 'Afficher les numéros de ligne',
  'profile.codeblocks.showMatchingBraces': 'Afficher les accolades correspondantes',
  'profile.codeblocks.showRainbowBrackets': 'Afficher les parenthèses arc-en-ciel',
  'profile.confirm_new_password': 'Confirmer le mot de passe',
  'profile.current_password': 'Mot de passe actuel',
  'profile.default.password': 'Mot de passe par défaut',
  'profile.default.password.description': "Au moins 8 caractères. L'utilisateur devra changer son mot de passe au premier login.",
  'profile.default.password.error': 'Le mot de passe est trop facile',
  'profile.default.password.placeholder': 'Mot de passe',
  'global.display_name': 'Nom affiché',
  'profile.endDate': 'Date de fin',
  'profile.errors.change_password': `Impossible de changer le mot de passe`,
  'profile.errors.firstname.length': "Le prénom doit contenir au moins 1 caractère et au maximum 64 caractères",
  'profile.errors.lastname.length': "Le nom de famille doit contenir au moins 1 caractère et au maximum 64 caractères",
  'profile.errors.password.capital': 'Le mot de passe doit contenir au moins une majuscule ET une minuscule',
  'profile.errors.password.length': 'Le mot de passe doit contenir au minimum 8 caractères',
  'profile.errors.password.number': 'Le mot de passe doit contenir au moins un chiffre',
  'profile.errors.passwords_not_matching': 'Les nouveaux mots de passe ne correspondent pas',
  'profile.errors.passwords_validation': 'Le mot de passe doit contenir au moins une majuscule ET une minuscule ET un caractère numérique.',
  'profile.errors.something_went_wrong': "Les détails utilisateur ne peuvent pas être montrés pour le moment",
  'profile.errors.username.length': "Le nom d'utilisateur doit contenir au moins 1 caractère et au maximum 64 caractères",
  'profile.errors.username.pattern': "Le nom d'utilisateur ne peut contenir que des lettres et des chiffres",
  'profile.errors.wrong_current_password': "Votre mot de passe actuel n'est pas correct",
  'profile.eula_accept': "J'accepte  la license utilisateur",
  'profile.eula_accepted': 'License utilisateur acceptée',
  'profile.first_name': 'Prénom',
  'profile.language': 'Langue',
  'profile.language.choose': 'Choissisez votre langue préféré',
  'profile.last_name': 'Nom de famille',
  'profile.mail': 'E-mail',
  'profile.name': 'Nom',
  'profile.navLabel': 'Profil-info',
  'profile.new_password': 'Nouveau mot de passe',
  'profile.no_mail': "Pas d'e-Mail fourni",
  'profile.notifications': 'liste de notifications',
  'profile.notifications.empty': "Il n'y a pas de notification pour le moment",
  'profile.preferences': 'Préférences',
  'profile.role': 'Role',
  'profile.settings_being_retrieved': 'Vos paramètres sont en cours de chargement, veuillez patienter.',
  'profile.slideviewer': 'Diapositives',
  'profile.slideviewer.viewMode': "Mode d'affichage par défaut",
  'profile.success.change_password': 'Mot de passe mis à jour avec succès',
  'profile.teams.assignmentAddToCalendarActions': "Qui voulez-vous que cela apparaisse dans l'agenda?",
  'profile.teams.assignmentAddToCalendarActions.NONE': "Personne",
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_AND_TEAM_OWNERS': "Étudiants et enseignants",
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_ONLY': 'Étudiants',
  'profile.teams.sendTo': 'Envoyer au teams',
  'profile.title': 'Profil',
  'profile.username': 'Nom d\'utilisateur',

  // HelpVideos
  'help_videos.categories': 'Catégories',
  'help_videos.categories.sr.link': 'Regardez les vidéos dans la catégorie {category}',
  'help_videos.featured': 'En vedette',
  'help_videos.title': 'How-to vidéos',
  'help_videos.videos': 'Vidéos',
  'help_videos.short.description.title': 'En savoir plus ',

  // password strength
  'password-strength.very_weak': 'mot de passe très faible',
  'password-strength.weak': 'Mot de passe faible',
  'password-strength.almost_there': 'Vous y êtes presque !',
  'password-strength.good': 'Bon mot de passe',
  'password-strength.excellent': 'Excellent mot de passe',
  'password.dialog.generate-password': 'Générer un mot de passe',

  // FeedbackPopover
  "feedback-popover.feedback": "Feedback",
  "feedback-popover.placeholder": "Ajouter votre feedback ici.",

  // ContentSlideViewer
  'slideviewer.error': "Il n'y a pas de slides pour cette leçon.",
  'slide_viewer.scroll_up': 'Défiler vers le haut',
  'slide_viewer.scroll_down': 'Défiler vers le bas',
  'content-slide-viewer.no-slides': 'Pas de slides',
  'content-slide-viewer.no-slides.description': 'Il semble ne pas avoir de slide pour ce mode de lecture. Veuillez en essayer un autre :',
  'content-slide-viewer.no-slides.description-student': 'Cette leçon ne semble pas contenir de slides.',
  'content-slide-viewer.forgot-to-save': "Avez-vous ouvblié d'envoyer?",
  'content-slide-viewer.forgot-to-save-description': 'La réponse à cette question n\'a pas encore été sauvée. Voulez-vous la soumettre ?',

  // ContentSlideViewer ExamSlide
  'content-slide-viewer.hard-submit-slide.title': 'Envoyer',
  'content-slide-viewer.hard-submit-slide.description': "Quand vous envoyez votre examen, il ne sera plus possible de le changer par la suite. Etes-vous sûr de vouloir l'envoyer?",
  'content-slide-viewer.hard-submit-slide.submit': 'Envoyer',
  'content-slide-viewer.hard-submit-slide.submitted': 'Envoyé',
  'content-slide-viewer.hard-submit-slide.success': "L'examen a été envoyé avec succès",
  'content-slide-viewer.hard-submit-slide.error': 'Oops! Quelque chose s\'est mal déroulé en envoyant le test. Veuillez réessayer',

  // Scratch
  'scratch.header.new_project': 'Nouveau projet',
  'scratch.header.start_over': 'Démarrer',
  'scratch.header.upload-exercise': "Envoyer l'exercice",
  'scratch.header.turbo': 'Turbo',
  'scratch.header.by': 'Par',
  'scratch.scratch_error': "Votre exercice Scratch n'a pas pu être chargé...",
  'scratch.confirmation.content': 'Etes-vous sur de vouloir réinitialiser votre exercice ?',

  // Coach Codi
  'codi.messages.tests_loading': 'Nous testons votre exercice. Cela peut prendre un certain temps.',
  'codi.messages.tests_more_than_half_correct': 'Plus de la moitié de mes tests fonctionnent déjà ! Bon travail !',
  'codi.messages.tests_more_all_correct': 'Tous les tests sont passés ! Vous avez terminé !',
  'codi.messages.test_ready': "Un test vous attend. Cliquez dessus pour plus d'informations.",
  'codi.messages.welcome': 'Bonjour, je suis le Coach Codi ! Je vais tester votre code. Appuyez sur "vérifier le code" pour commencer.',


  // Program usage overview
  'program-usage-overview.chapter': 'Leçon',
  'program-usage-overview.classes': 'Classes',
  'program-usage-overview.classes.filter': 'Filtrer par classe',
  'program-usage-overview.empty': "Actuellement, aucun étudiant n'utilise ce programme.",
  'program-usage-overview.empty.search': "Aucun étudiant correspondant à ces critères de recherche n'a été trouvé.",
  'program-usage-overview.name': 'Étudiant(e)',
  'program-usage-overview.no-access': "Vous n'avez pas accès à ce programme pour cette période. Veuillez contacter un administrateur si vous pensez qu'il s'agit d'une erreur.",
  'program-usage-overview.start': 'Heure de début',
  'program-usage-overview.teacher': 'Professeur(e)',


  // Student overview
  'student-overview.classes': 'Groupes de classe',
  'student-overview.courses': 'Cours',
  'student-overview.users': 'Utilisateurs',
  'student-overview.title': 'Elèves',
  'student-overview.column.name': 'Nom',
  'student-overview.column.endDate': 'Date de fin',
  'student-overview.column.username': "Nom d'utilisateur",
  'student-overview.column.class': 'Groupe de classe',
  'student-overview.column.status': 'Statut',
  'student-overview.column.reset-password.header': 'Réinitialiser le mot de passe',
  'student-overview.column.reset-password.info': 'Entrez le nouveau mot de passe',
  'student-overview.column.reset-password.repeat': 'Répétez le noveau mot de passe',
  'student-overview.column.reset-password.label': 'Nouveau mot de passe',
  'student-overview.column.reset-password.label.repeat-password': 'Répétez le nouveau mot de passe',
  'student-overview.column.reset-password.confirm': 'Réinitialiser',
  'student-overview.column.reset-password.error': 'Erreur pendant la réinitialisation du mot de passe',
  'student-overview.column.delete-student.success': "Utilisateur supprimé avec succès",
  'student-overview.column.delete-student.error': "Erreur pendant la suppression de l'élève",
  'student-overview.column.edit-student.success': "Utilisateur édité avec succès",
  'student-overview.column.edit-student.error': "Erreur pendant l'édition de l'élève",
  'student-overview.column.reset-password.confirmation': 'Le nouveau mot de passe a été copié dans le presse-papier',
  'student-overview.new-student': "Nouvel élève",
  'student-overview.add-student': "Ajouter un élève",
  'student-overview.no-students': "Ce group de classe ne contient pas d'élève",
  'student-overview.column.create-student.success': "Utilisateur créé avec succès. Mot de passe copié dans le presse-papier.",
  'student-overview.column.create-student.error': "Erreur durant la création de l'élève.",
  'student-overview.are-you-sure': "Etes-vous sûr de vouloir supprimer cet utilisateur?",

  // Teacher overview
  'teacher-overview.classes': 'Groupes de classe',

  // classgroups overview
  'classgroup-overview.column.name': 'Nom',
  'classgroup-overview.column.archived': 'Archivé',
  'classgroup-overview.archived.true': 'Archivé',
  'classgroup-overview.archived.false': 'Non archivé',
  'classgroup-overview.not-archived': 'Montrer les groupes de classe archivés',


  // Organization overview
  'organization-overview.button.add-organization': 'Ajouter une organisation',
  'organization-overview.column.issuer': 'Emetteur',
  'organization-overview.column.language': 'Langue',
  'organization-overview.column.name': 'Nom',
  'organization-overview.column.usernamePrefix': 'Préfixe',
  'organization-overview.empty': "Aucune organisation correspondant à ces critères de recherche n'a été trouvée",
  'organization-overview.error': "Un erreur est survenue pendant le chargement de l'organisations",
  'organization-overview.calendar.legend': 'Statut du calendrier',
  'organization-overview.calendar.off': 'off',
  'organization-overview.calendar.on': 'on',
  'organization-overview.not-active': 'Organisations inactives',
  'organization-overview.title': 'Organisations',

  // Users overview
  'users-overview.confirmation-text': "Voulez-vous vraiment supprimer cet utilisateur?",
  'users-overview.disable': "Désactiver l'utilisateur",
  'users-overview.enable': "Activer l'utilisateur",
  'users-overview.error': "Un erreur est survenue pendant le chargement des utilisateurs",
  'users-overview.firstName': "Chercher par prénom",
  'users-overview.lastName': "Chercher par nom de famille",
  'users-overview.use-disable-text': "Nous recommandons fortement de désactiver l'utilisateur. La suppression d'utilisateurs est permanente et peut entraîner une corruption des données (par exemple des liens rompus), mais la désactivation peut toujours être annulée.",
  'users-overview.username': "Chercher par nom d'utilisateur",

  // Program overview
  'program-overview.column.name': "Nom",
  'program-overview.column.seatCount': "Nombre de places",
  'program-overview.column.seatPeriod': "Periode de places",
  'program-overview.column.type': "Type de programme",
  'program-overview.empty': "Il n'y a pas de programmes liés à cette période pour cette organisation.",
  'program-overview.empty.search': "Aucun programme correspondant à ce critère de recherche n'a été trouvé.",
  'program-overview.error': "Une erreur est survenue pendant le chargement des programmes.",
  'program-overview.new-program': "Ajouter un programme",
  'program-overview.title': "Programmes",

  // Chapter overview
  'chapter-overview.search.placeholder': 'Cherchez par nom',
  'chapter-overview.select.placeholder': 'Filtrer par balise',

  // ChapterItem overview
  'chapterItem-overview.search.placeholder': 'Cherchez par nom',
  'chapterItem-overview.select.placeholder': 'Filtrer par balise',

  // Classes overview
  "classes-overview.view.students": "Elèves",
  "classes-overview.view.lessons": "Leçons",
  "classes-overview.view.exercises": "Exercices",
  "classes-overview.view.tests": "Tests",
  "classes-overview.column.classname": "Nom du groupe de classe",
  "classes-overview.column.students": "Elèves",
  'classes-overview.error': "Une erreur est survenue pendant le chargement du groupe de classe.",

  // Class detail
  "class-detail.pageTitle": "Détails de la classe",

  // Students overview
  "students-overview.column.email": "E-mail",
  "students-overview.column.name": "Nom",
  "students-overview.column.profiletest": "Profil test",
  "students-overview.column.username": "Nom d'utilisateur",
  'students-overview.error': "Une erreur est survenue pendant le chargement des élèves",
  'students-overview.search.email': "Chercher l'e-mail",
  'students-overview.search.username': "Chercher l'utlisateur",
  'students-overview.exportResults.COMPLETED': 'La publication des résultats vers {where} a réussi le {dateTime}.',
  'students-overview.exportResults.export': 'Publier vers {where}',
  'students-overview.exportResults.FAILED': 'La publication des résultats a échoué le {dateTime}.',
  'students-overview.exportResults.isLoading': 'Aucune note n\'a encore été envoyée à {where}.',

  // Lesson overview
  'lessons-overview.error': 'Une erreur est survenue pendant le chargement des leçons',
  'lessons-overview.column.startDate': 'Date de début',
  'lessons-overview.column.date': 'Date',
  'lessons-overview.column.endDate': 'Date de fin',
  'lessons-overview.column.author': 'Auteur',
  'lessons-overview.column.type': 'Type',
  'lessons-overview.column.moment': 'Date',
  'lessons-overview.column.chapterItemName': 'Nom de la leçon',
  'lessons-overview.search': "Chercher par nom d'étudiant",
  'lessons-overview.filterTeacher': 'Chercher par nom du professeur',
  'lessons-overview.range': 'Range',
  'lessons-overview.planned_start': 'Planifié depuis:',
  'lessons-overview.planned_end': 'Jusque:',
  'lessons-overview.at': "à",
  'lessons-overview.from': "depuis",
  'lessons-overview.until': "jusqu'à",
  'lessons-overview.title': 'Résultats de classe',

  // Lesson results overview
  'lesson-results-overview-klasgemiddelde': 'Moyenne du groupe de classe',
  'lesson-results-overview-search': 'Chercher par groupe de classe',
  'lesson-results-class-not-found': 'Groupe de classe non trouvé',

  // exercise overview
  'exercises-overview.column.endDate': 'Date de fin',
  'exercises-overview.column.startDate': 'Date de début',
  'exercises-overview.column.title': 'Titre',
  'exercises-overview.search': 'Chercher par titre',
  'exercises-overview.range': 'Range',
  'exercises-overview.planned_start': 'Planifié depuis:',
  'exercises-overview.planned_end': 'Jusque:',
  'exercises-overview.at': "à",

  // Manage program details
  'manage-program-details.name': "Nom",
  'manage-program-details.type': "Type de programme",
  'manage-program-details.edit': "Editer le programme",
  'manage-program-details.chapter-item': "Elément",
  'manage-program-details.chapter-items': "Eléments",
  'manage-program-details.no-chapters': "Pas de chapitres",
  'manage-program-details.no-chapter-items': "Pas de leçons",
  'manage-program-details.add-chapter': "Ajouter un chapitre",
  'manage-program-details.edit-chapter': "Editer un chapitre",
  'manage-program-details.delete-chapter': "Supprimer un chapitre",
  'manage-program-details.add-chapter-item': "Ajouter une leçon",
  'manage-program-details.delete-chapter-item': "Supprimer une leçon",
  'manage-program-details.edit-chapter-item': "Editer une leçon",
  'manage-program-details.error': "Une erreur est survenue pendant le chargement du programme.",

  // program dialog
  'program-dialog.color.description': "va être utilisé pour afficher les éléments du calendrier.",
  'program-dialog.document': 'Lier le document',
  'program-dialog.document.description': 'Documents relatifs à ce programme.',
  'program-dialog.weight.description': "va être utilisé pour trier les programmes.",

  // Add program dialog
  'add-program-dialog.header': "Créer un nouveau programme",
  'add-program-dialog.error': "Une erreur est survenue pendant la création du programme.",
  'add-program-dialog.name': "Nom du programme",
  'add-program-dialog.type': "Type de programme",
  'add-program-dialog.add': "Ajouter un programme",
  'add-program-dialog.color': "Ajouter une couleur",
  'add-program-dialog.file': "Ajouter un fichier",

  // Update chapter
  "update-chapter-dialog.header": "Mise à jour du chapitre",

  // Link programs
  'link-programs-dialog.header': "Lier le programme au professeur",
  'link-programs-dialog.confirm': "Confirmer",
  'link-programs-dialog.label': "Programmes",
  'link-program-dialog.all-programs-linked': 'Tous les programmes sont liés',

  // Unlink programs
  'unlink-programs-dialog.header': "Déconnecter le professeur du programme",
  'unlink-programs-dialog.confirm': "Confirmer",
  'unlink-programs-dialog.label': "Programmes",
  'unlink-program-dialog.all-programs-unlinked': "Tous les programmes sont déconnectés",

  // Add classgroups dialog
  "add-classgroups-dialog.header": "Ajouter un utilisateur à un groupe de classe",
  "add-classgroups-dialog.label": "Groupes de classe",
  "add-classgroups-dialog.description": "L'intégralité des groupes de classe n'est pas affichée. Veuillez chercher le groupe de classe que vous souhaitez ajouter avant de se faire.",
  "add-classgroups-dialog.confirm": "Confirmer",
  "add-classgroups-dialog.placeholder": "Chercher par nom",
  "link-classgroup-dialog.all-classgroups-linked": "Tous les groupes de classe sont liés",

  // date range dialog
  "date-range-dialog.header": "Selectionner le range",
  "date-range-dialog.apply": "Appliquer",

  // Add or update organization dialog
  'aou-org.add': "Ajouter une organisation",
  'aou-org.domainName': "Préfixe du domaine",
  'aou-org.domainName.description': 'Si vous possédez un domaine : "https://domain prefix.smartschool.be", vous devez' +
    ' préciser le préfixe du domaine',
  'aou-org.import.classes': "Importer des classes",
  "aou-org.import.clientId": "ID client",
  "aou-org.import.clientSecret": "Client-secret",
  "aou-org.import.membershipOfStudentsToClasses": "Importer l'adhésion étudiante aux cours",
  "aou-org.import.membershipOfTeachersToClasses": "Importer l'adhésion des enseignants aux classes",
  "aou-org.import.one-roster.title": "Données d'une liste",
  'aou-org.import.students': "Importer des étudiants",
  'aou-org.import.teachers': "Importer des enseignants",
  'aou-org.issuer': "Plateforme d'abonnement",
  'aou-org.language': "Choisissez la langue de l'organisation",
  'aou-org.name': "Nom de l'organisation",
  'aou-org.prefix': "Préfixe de l'organisation",
  'aou-org.prefix-error': "Ce préfixe existe déjà",
  'aou-org.submit': "Créer une organisation et ajouter une période",
  'aou-org.tenantId': "Identifiant du locataire",
  'aou-org.update': "Modifier l'organisation",

  // Activate program dialog
  'activate-organization-dialog.header': "Activer l'organisation",
  'activate-organization-dialog.error': "Une erreur est survenue pendant l'activation de l'organisation.",
  'activate-organization-dialog.confirm': "Activer",
  'activate-organization-dialog.confirmation-text': `Etes-vous sûr de vouloir activer l'organisation "{organisationName}"?`,

  // Deactivate program dialog
  'deactivate-organization-dialog.header': "Désactivater l' organisation",
  'deactivate-organization-dialog.error': "Une erreur est survenue pendant la désactivation de l'organisation.",
  'deactivate-organization-dialog.confirm': "Désactivater",
  'deactivate-organization-dialog.confirmation-text': `Etes-vous sûr de vouloir désactiver l'organisation "{organisationName}"?`,

  // Deactivate user dialog
  'deactivate-user-dialog.header': "Désactiver l'utilisateur",
  'deactivate-user-dialog.confirm': "Désactiver",
  'deactivate-user-dialog.confirmation-text': "Voulez-vous vraiment désactiver {user}?",

  // Add class group dialog
  'add-class.group-dialog.header': "Créer un nouveau groupe de classe",
  'add-class.group-dialog.error': "Une erreur est survenue pendant la création du groupe de classe.",
  'add-class.group-dialog.confirm': "Créer",
  'add-class.group-dialog.name': "Nom de groupe de classe",
  'add-class.group-dialog.add': "Ajouter un groupe de classe",

  // Delete class group dialog
  'delete-class.group-dialog.header': 'Archiver:',
  'delete-class.group-dialog.content': 'Etes-vous sûr de vouloir archiver ?',

  // Delete program dialog
  'delete-program-dialog.header': "Supprimer le programme",
  'delete-program-dialog.error': "Une erreur est survenue pendant la suppression du programme.",
  'delete-program-dialog.confirm': "Supprimer",
  'delete-program-dialog.confirmation-text': "Etes-vous sûr de vouloir supprimer ce programme ?",

  // Delete user dialog
  'delete-user-dialog.header': "Supprimer l'utilisateur",
  'delete-user-dialog.error': "Une erreur est survenue pendant la suppression de l'utilisateur.",
  'delete-user-dialog.confirm': "Supprimer",
  'delete-user-dialog.confirmation-text': "Etes-vous sûr de vouloir supprimer cet utilisateur ?",

  // Link user to class dialog
  'link-user.to.class-dialog.header': "Lier l'utilisateur au groupe de classe",
  'link-user.to.class-dialog.add': "Lier au groupe de classe",
  'link-user.to.class-dialog.confirm': "Mise à jour",

  // Update program dialog
  'update-program-dialog.color': "Mise à jour de la couleur",
  'update-program-dialog.confirm': "Ajuster l'organisation",
  'update-program-dialog.error': "Une erreur est survenue pendant la mise à jour du programme",
  'update-program-dialog.header': "Editer le programme",
  'update-program-dialog.name': "Nom du programme",
  'update-program-dialog.type': "Type de programme",

  // Update class group
  'update-class.group-dialog.confirm': "Mise à jour du groupe de classe",

  // chapter dialog
  'chapter-dialog.document': 'Lier le document',
  'chapter-dialog.document.description': 'Documents relatifs à ce chapitre.',
  'chapter-dialog.name': "Nom du chapitre",
  'chapter-dialog.weight.description': "va être utilier pour tier les chapitres.",

  // Add chapter dialog
  'add-chapter-dialog.header': "Créer un nouveau chapitre",
  'add-chapter-dialog.error': "Une erreur est survenue pendant la création du nouveau chapitre.",
  'add-chapter-dialog.add': "Ajouter un chapitre",

  // Delete chapter dialog
  'delete-chapter-dialog.header': "Supprimer le chapitre",
  'delete-chapter-dialog.error': "Une erreur est survenue pendant la suppression du chapitre.",
  'delete-chapter-dialog.confirm': "Supprimer",
  'delete-chapter-dialog.confirmation-text': "Etes-vous sûr de vouloir supprimer ce chapitre ?",

  // chapter item dialog
  'chapter-item-dialog.document': 'Lier le document',
  'chapter-item-dialog.document.description': 'Documents relatifs à cette leçon.',
  'chapter-item-dialog.weight.description': "Va être utilisé pour trier les leçons.",


  // Add chapter item dialog
  'add-chapter-item-dialog.header': "Ajouter une leçon",
  'add-chapter-item-dialog.error': "Une erreur est survenue pendant l'ajout de la leçon",
  'add-chapter-item-dialog.name': "Nom de la leçon",
  'add-chapter-item-dialog.lesson-content-id': "Lesson content ID",

  // Delete chapter dialog
  'delete-chapter-item-dialog.header': "Supprimer une leçon",
  'delete-chapter-item-dialog.error': "Une erreur est survenue pendant la suppression de la leçon.",
  'delete-chapter-item-dialog.confirm': "Supprimer",
  'delete-chapter-item-dialog.confirmation-text': "Etes-vous sûr de vouloir supprimer la leçon ?",

  // Update chapter item dialog
  'update-chapter-item-dialog.header': "Editer la leçon",
  'update-chapter-item-dialog.error': "Une erreur est survenue pendant la mise à jour de la leçon.",
  'update-chapter-item-dialog.name': "Nom de la leçon",
  'update-chapter-item-dialog.lesson-content-id': "Lesson content ID",

  // Add program to organization
  'add-program-dialog.add-to-organization': "Ajouter le programme à l'organisation",
  'add-program-dialog.close': "Fermer",
  'add-program-dialog.what_are_you_looking_for': 'Vous cherchez après quoi?',
  'add-program-dialog.seat-count': "Nombre de places",
  'add-program-dialog.enter.seat-count': "Veuillez entrer un nombre de places.",

  // Edit seats for program dialog
  'edit-seats-for-program.change': "Modifier le nombre de places pour ",
  'edit-seats-for-program.submit': "Personnalisez les lieux",
  'edit-seats-for-program.edit': "Personnalisez le nombre des lieux",

  // Edit period for program dialog
  'edit-period-for-program.change': "Change periode pour ",
  'edit-period-for-program.submit': "Ajuster la période",
  'edit-period-for-program.edit': "Ajuster la période",
  'edit-period-for-program.seatPeriod.choose': "Choisissez une période de réservation",

  // Schooladmin overview
  'global.first_and_last_name': 'Prénom et nom',
  'global.first_name': 'Prénom',
  'global.last_name': 'Nom',
  'schooladmin-overview.new-user': 'Nouveau {what}',
  'schooladmin-overview.new-class-group': 'Nouveau groupe de classe',
  'schooladmin-overview.new-schooladmin': 'Ajouter un directeur/admin',
  'schooladmin-overview.edit-schooladmin': 'Editer un directeur/admin',

  // Schooladmin dialog
  'delete-schooladmin-header': 'Supprimer directeur/admin',
  'email.validate-error': "Format d'e-Mail invalide",

  // Schooladmin notifications
  'schooladmin.error': 'Oops, une erreur est survenue.',
  'schooladmin.success.add': 'Directeur/admin ajouté avec succès.',
  'schooladmin.success.delete': 'Directeur/admin supprimé avec succès.',
  'schooladmin.success.edit': 'Directeur/admin édité avec succès.',

  // userProfileSchooladmin
  'schooladmin.classes.empty': 'Ce(t) {what} n\'a pas été lié à aucun groupe de classe.',
  'schooladmin.programs.empty': 'Ce {what} n\'a pas été lié à aucun programme.',

  // Program detail schooladmin
  'schooladmin.program.detail.empty': "Il n'y a actuellement aucun chapitre lié à ce programme. Demandez à un administrateur de le faire.",

  // Program usage schooladmin
  'schooladmin.program.usage.empty': "Actuellement, il n'y a pas d'utilisation de ce programme.",

  // Program detail admin
  'admin.program.detail.empty': "Il n'y a actuellement aucun chapitre lié à ce programme.",

  // Add student dialog
  'add-user-dialog.header': "Créer nouveau {what}",
  'add-user-dialog.success': "Création de nouvel élève réussie",
  'add-user-dialog.error': "Une erreur est survenue pendant la création de l'élève..",
  'add-user-dialog.confirm': "Créer",
  'add-user-dialog.linked_classgroups': "Lier aux groupes de classe",
  "add-user-dialog.user-type": "Quel type d'utilisateur?",

  // Add teacher dialog
  'add-teacher-dialog.header': "Créer un nouveau professeur",
  'add-teacher-dialog.success': "Création d'un nouveau professeur réussie.",
  'add-teacher-dialog.error': "Une erreur est survenue pendant la création du professeur.",
  'add-teacher-dialog.confirm': "Créer",
  'add-teacher-dialog.linked_classgroups': "Lié aux groupes de classe",

  // hack room
  'hack-room.featured': 'En vedette',
  'hack-room.more_lessons': 'Plus de leçons',
  'hack-room.what_are_you_looking_for': 'Que cherchez-vous ?',
  'hack-room.select': 'Filtrer par balise',

  // Exercises
  'exercises-overview.title': 'Exercices de',
  'exercises-overview.pretitle': 'Exercices',
  'exercises-overview.my-exercises.title': 'Mes exercices',
  'exercises-overview.no_exercises': 'Pas d\'exercices disponibles',
  'exercises-overview.create': 'Créer un nouvel exercice',
  'exercises-overview.error': 'Une erreur est survenue pendant la création du nouvel exercice.',
  'exercises-overview.view_exercises': 'Voir les exercices',
  'exercises-overview.delete_success': "Suppression de l'exercice réussie.",
  'exercises-overview.delete_fail': "Une erreur est survenue pendant la suppression de l'exercice",
  'exercises-overview.toggle_filter': 'Filtre',
  'exercises-overview.filter_placeholder': 'Titre',
  'exercises-overview.label.filter': 'Filtrer sur le titre',

  // scratch gui
  'gui.ftrprf.testresult.click_to_test': 'Teste ton code!',
  'gui.ftrprf.testresult.failed': 'Le test a échoué',
  'gui.ftrprf.testresult.hide_subtests': 'Cacher tous les checks.',
  'gui.ftrprf.testresult.loading': 'Chargement',
  'gui.ftrprf.testresult.of': 'Test de: ',
  'gui.ftrprf.testresult.show_subtests': 'Montrer tous les checks.',
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Tests',
  'gui.ftrprf.test-tab': 'Coach Codi',
  'gui.ftrprf.test-tab.latest_test': 'Test final',
  'gui.ftrprf.test-tab.loading': 'Vérification de ton code en cours...',
  'gui.ftrprf.test-tab.old_tests': 'Anciens tests',
  'gui.ftrprf.contextMenu.hide_blocks': 'Cacher les blocs',
  'gui.ftrprf.contextMenu.display_blocks': 'Afficher les blocs',

  // excercise versions
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Solution',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Autres',

  // Codi
  'assistant.name': "Entraîneur {name}",
  'assistant.always-here-to-help': "Toujours là pour vous aider.",
  'assistant.chat.need-help': "Besoin d'aide ? Cliquez ici pour obtenir un indice.",
  'assistant.getToKnow.title': 'Je suis entraîneur Codi!',
  'assistant.getToKnow.body': "Bonjour, je suis Coach CoDi, votre coach de codage. Je peux vous dire si vos exercices ont réussi et vous donner des conseils sur ce que vous pouvez faire mieux. Vous pouvez me trouver en cliquant sur l'onglet \"Coach CoDi\" dans le menu du haut.",
  'assistant.getToKnow.screenshot': "L'onglet Codi dans Scratch est le dernier onglet à droite de l'onglet Sons.",

  // Manage
  'manage.title': "Gérer",
  'manage.edit-school': "Editer école",
  'manage.courses': "Cours",

  // personality
  'personality.test.send': "Envoyer des tests de personnalité",
  'personality.test.form.send': "Envoyer les tests",
  'personality.test.adults': "Adultes",
  'personality.test.children': "Enfants",

  // groups
  'groups.group': "Groupe",
  'groups.members': "Membres",
  'groups.compose.divide': "Diviser les groupes",
  'groups.compose.type': "Quel type de composition souhaitez-vous?",
  'groups.compose.divider.amount': "Nombre de groupes",
  'groups.compose.divider.create': "Créer des groupes",
  'groups.compose.option.random': "Composition aléatoire",
  'groups.compose.option.steams': "STEAMS composition",
  'groups.compose.randomize.members': "Randomiser les groupes",
  'groups.compose.randomize.roles': "Randomiser les rôles",
  'groups.compose.steams.activity.created': 'activité STEAMS',
  'groups.compose.steams.activity.error': "l'activité STEAMS",
  'groups.compose.steams.loading': "Chargement des données depuis STEAMS, Ceci peut prendre" +
    " quelques secondes",
  'groups.compose.steams.noiframe': "Votre navigateur internet ne supporte pas cette fonctionalité",
  'groups.compose.save': "Sauver les groupes",
  'groups.compose.start': "Démarer la composition du groupe",
  'groups.compose.memberrow.roles': "Kies een rol voor deze student",
  'groups.save.lesson.success': 'Groupes',
  'groups.save.lesson.error': 'groupes',

  // Results
  'sendResults.success': "Résultats envoyés avec succès à {what}",
  'sendResults.error': "Une erreur s'est produite lors de l'envoi des résultats à {what}",

  // eventpopup
  'eventpopup.divide_groups': 'Diviser les groupes',
  'eventpopup.delete.past_event': "Impossible de supprimer un événement du passé",

  // filter
  'filter.clear': 'Supprimer les filtres',

  // Logout
  'logout.title': 'Déconnexion en cours.',
  'logout.extra': 'Veuillez patienter pendant que nous vous déconnections de nos plateformes...',

  // Reports
  'global.REPORT.singular': 'Rapport',
  'global.REPORT.plural': 'Rapports',
  'reports.SEAT_UPDATE_FREQUENCY': "mises à jour toutes les heures",
  'reports.SEAT_UPDATE_FREQUENCY_EXPLANATION': "Il ne s'agit pas de données en direct: le nombre de sièges utilisés est recalculé toutes les heures.",
  'reports.SEAT_USAGE': 'Utilisation du siège',
  'reports.SEAT_USAGE.extra': 'Rapport dat voor per organisatie, per programma het aantal gebruikte seat en het toegelaten seats weergeeft',

  'reports.CALENDAR_ITEM_USAGE': "Utilisation de l'élément de calendrier",
  'reports.CALENDAR_ITEM_USAGE.extra': 'Geeft per organisatie weer hoeveel kalender items er zijn per les/module/pakket',

  'reports.PROGRAM_USAGE': 'Utilisation des programmes',
  'reports.PROGRAM_USAGE.extra': "Geeft per organisatie, per programma weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items en het maximum aantal seats.",

  'reports.COMBINED_PROGRAMS_USAGE': 'Utilisation des forfaits',
  'reports.COMBINED_PROGRAMS_USAGE.extra': 'Geeft per organisatie voor de Pakketten IT&ME en WIJS (1ste en 2de graad) weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items',

  'reports.STUDENTS_OVERVIEW_ITANDME': 'Présentation des étudiants IT&ME',
  'reports.STUDENTS_OVERVIEW_ITANDME.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van IT&ME (1ste en 2de graad)',

  'reports.STUDENTS_OVERVIEW_WIJS': 'Présentation des étudiants WIJS',
  'reports.STUDENTS_OVERVIEW_WIJS.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van WIJS (1ste en 2de graad)',

  'reports.STUDENTS_PER_LESSON': 'Présentation des étudiants par lesson',
  'reports.STUDENTS_PER_LESSON.extra': 'Overzicht van welke lessen er zijn opgengezet voor welke klassen, hoeveel leerlingen daarin zitten en welke leerkracht dit heeft opengezet',

  'reports.SLIDES_OPENED': 'Leçons ouvertes par utilisateur',
  'reports.SLIDES_OPENED.extra': 'Uitgebreide lijst van elke keer een gebruiker een les heeft open gedaan, inclusief timestamp',

  'reports.LOGGED_IN': 'Connexions par utilisateur',
  'reports.LOGGED_IN.extra': 'Uitgebreide lijst van elke keer dat een gebruiker ingelogd is op het platform',

  'reports.TEACHERS_EXPORT': 'Teachers export',
  'reports.TEACHERS_EXPORT.extra': 'Lijst van alle Leerkrachten en schooladmins, inclusief email en newsletter toestemming',

  // Tags
  'global.TAG.singular': 'Balise',
  'global.TAG.plural': 'Balises',

  // Periods
  'global.period': 'Période',
  'global.periods': 'Périodes',
  'periods.active': 'Actif',
  'periods.delete-confirmation': 'Êtes-vous sûr de vouloir supprimer cette période ?',
  'periods.description': 'Description',
  'periods.endDate': 'Date de fin',
  'periods.errors.dates-overlap': "La période ne doit pas se chevaucher avec une période existante.",
  'periods.errors.startdate-before-enddate': 'La date de début doit être antérieure à la date de fin.',
  'periods.new': 'Nouvelle periode',
  'periods.new.for': 'Nouvelle periode pour',
  'periods.selector.select-period': "Sélectionner une période",
  'periods.startDate': 'Date de début',
  'periods.submit': 'Créer période',

  // Admin move lesson
  'tools-move-lesson.description': `Sur cette page, vous pouvez déplacer des leçons d'une organisation à une autre. Le déplacement présente l'avantage supplémentaire que les slideIDs restent les mêmes et que les slidelinks continuent donc de fonctionner.`,
  'tools-copy-lesson.description': `Sur cette page, vous pouvez copier des leçons d'une organisation à une autre.`,
  'move-lesson.method': 'Méthode',
  'move-lesson.method.move': 'Déplacer',
  'move-lesson.method.copy': 'Copier',

  // Link teachers to programs
  'link-teachers-programs.button': 'Relier tous les enseignants à tous les programmes',
  'link-teachers-programs.confirmation': `Cette action relie tous les enseignants actuels à tous les programmes disponibles. Vous pouvez effectuer cette action chaque fois que vous avez de nouveaux enseignants et/ou programmes. Notez que s'il y a des exceptions, vous devrez les dissocier manuellement via le profil de l'enseignant.`,
  'link-teachers-programs.success': 'Tous les enseignants actifs sont reliés avec succès à tous les programmes',

  // Multi-screen mode
  'multi-screen-mode.open': 'Ouvrir le mode présentateur',
  'multi-screen-mode.current-slide': 'Diapositive actuelle',
  'multi-screen-mode.no-manual': 'Aucune note pour cette diapositive.',
  'multi-screen-mode.next-slide': 'Diapositive suivante',
  'multi-screen-mode.previous-slide': 'Diapositive précédente',
  'multi-screen-mode.end': 'Cette leçon est terminée, mais votre apprentissage se poursuivra.',
  'multi-screen-mode.progress': '{currentIndex} de {length}',
  'multi-screen-mode.make-main-display': "Afficher l'écran principal",

  // Smartschool
  'smartschool.popup.lessonsLoading': 'Les leçons sont chargées...',
  'smartschool.popup.noClasses': "Aucun cours n'a été trouvé pour vous. Demandez à l'administrateur de votre école de lier les classes.",
  'smartschool.popup.noPrograms': "Aucun cours n'a été trouvé pour vous. Demandez à l'administrateur de votre école de lier des leçons ou de créer vous-même une leçon.",
  'smartschool.popup.next': "Aller à l'étape {step}",
  'smartschool.popup.selectViewMode.exam': "Vous avez choisi un examen. Celui-ci doit toujours être affiché dans la vue classique.",
  'smartschool.popup.step1': 'Groupe cible',
  'smartschool.popup.step2': 'Leçon',
  'smartschool.popup.step3': 'Afficher',
  'smartschool.popup.target.all.description': "Ce lien sera accessible à toutes les classes, il n'y a aucune restriction d'accès.",
  'smartschool.popup.target.all.name': 'Toutes les classes',
  'smartschool.popup.target.specific.description': 'Sélectionnez 1 ou plusieurs classes qui devraient avoir accès à la leçon via ce lien.',
  'smartschool.popup.target.specific.name': 'Classe(s) spécifique(s)',
  'smartschool.popup.use': 'Utiliser en Smartschool',

  // Synchroniseer
  'synchronize.checkAll': 'Sélectionner toutes les classes',
  'synchronize.clearAll': 'Effacer toutes les classes',
  'synchronize.dailySync': 'Synchroniser quotidiennement',
  'synchronize.lastSynchronized': 'Dernière synchronisation',
  'synchronize.navLabel': 'Navigation de synchronisation',
  'synchronize.noneFound': 'Aucun groupe-classe n’a été trouvé.',
  'synchronize.notSyncedYet': "La synchronisation n'a pas encore eu lieu.",
  "synchronize.percentage": "Nombre d'utilisateurs traités, environ {percentage}% du total",
  'synchronize.selected': 'Synchroniser les cours sélectionnés',
  'synchronize.smartschool': 'Synchroniser avec Smartschool',
  'synchronize.status.warning': "Seules les {amount} dernières mises à jour de statut sont affichées.",
  'synchronize.synchronize': 'Synchroniser',
  'synchronize.teams': 'Synchroniser avec Teams',

  // teams login dialog
  "teams.login.title": "Connectez d'abord votre compte Microsoft",
  "teams.login.explanation": "Pour utiliser cette fonctionnalité, vous devez d'abord lier votre compte Microsoft. Après vous être connecté, vous serez redirigé vers cette page et la fonctionnalité sera prête à être utilisée!",

  // teams error
  "teams.error.synchronize.title": "Impossible de synchroniser avec Teams.",
};
