import { useEffect, useState } from 'react';
import uniqBy from 'lodash-es/uniqBy';

import useNotifications from 'hooks/api/plannerService/common/queries/useNotifications';
import useFormatMessage from 'hooks/useFormatMessage';

import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import { NoNotifications } from './NoNotifications';
import { NotificationListItem } from './NotificationListItem';

const MAX_ITEMS = 10;

export function Notifications() {
  const [maxItems, setMaxItems] = useState(MAX_ITEMS);

  const [notifications, setNotifications] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const t = useFormatMessage();

  const { data: notificationsData } = useNotifications({
    size: maxItems,
    showAll: false, // only retrieve notifications that aren't cancelled
  });

  useEffect(() => {
    setShowMore(notificationsData?.pages > 1);

    if (notificationsData?.content) {
      const newNotifications = uniqBy(
        [...notifications, ...notificationsData.content],
        (notification) => notification.id,
      );

      setNotifications(newNotifications);
      setHasNotifications(newNotifications?.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData?.content, notificationsData?.pages]);

  return (
    <div className="h-full w-full border border-gray-300 rounded">
      <div className="flow-root">
        <ul className="divide-y divide-gray-200">
          {hasNotifications &&
            notifications.map((notification) => (
              <NotificationListItem
                key={notification.id}
                notification={notification}
              />
            ))}
          {!hasNotifications && <NoNotifications />}
        </ul>
      </div>
      {showMore && (
        <div className="my-6 flex items-center justify-center">
          <ActionStyledAsButton
            className="rounded-md border px-8 py-2 text-sm shadow-sm"
            onClick={() => setMaxItems(Math.floor(maxItems * 1.5))}
          >
            {t('global.showMore')}
          </ActionStyledAsButton>
        </div>
      )}
    </div>
  );
}
