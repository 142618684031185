import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';
import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_SMARTSCHOOL_CLASSGROUPS } from 'api/plannerService/schooladmin/queries/schooladminSmartschoolClassGroups';

export default function useSchooladminSmartSchoolClassGroups() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminSmartSchoolClassGroups'],
    () =>
      plannerServiceQueryClient.request(SCHOOLADMIN_SMARTSCHOOL_CLASSGROUPS),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.CLASS.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminSmartSchoolClassGroups,
  };
}
