import { gql } from 'graphql-request';

export const OPEN_LESSON_SLIDES_WITH_CORRECT = gql`
  query openLessonSlides(
    $studioId: Int!
    $viewMode: ViewMode!
    $lessonSessionId: String
  ) {
    openLessonSlides(
      studioId: $studioId
      viewMode: $viewMode
      lessonSessionId: $lessonSessionId
    ) {
      id
      title
      sequence
      content
      part
      method
      execution
      module
      time
      info
      motivation
      viewModes
      background
      activityType {
        id
        key
        icon
      }
      groupRoles {
        id
        role
      }
      questions {
        id
        type
        ... on QuestionMultipleChoice {
          value
          scorePerCorrectAnswer
          subtractPerWrongAnswer
          maxSelectableAnswers
          shouldFindAllCorrect
          shuffleAnswers
          questionAnswersMultipleChoice {
            correct
            id
            numbering
            value
          }
        }
        ... on QuestionOpen {
          value
          scorePerCorrectAnswer
          placeholder
          solution
          showModelAnswer
          questionAnswerOpen {
            id
            optional
          }
        }
      }
      hints {
        id
        title
        content
      }
    }
  }
`;
