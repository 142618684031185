import { gql } from 'graphql-request';

export const SCRATCH_JUDGE_RESULTS_BY_SESSIONID = gql`
  query scratchJudgeResultsBySessionId($sessionId: String!) {
    scratchJudgeResultsBySessionId(sessionId: $sessionId) {
      id
      result
    }
  }
`;
