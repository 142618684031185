function transformGroupToUserRoles(group) {
  const userRoles = [];

  group.forEach((student) => {
    student.roles.forEach((role) => {
      userRoles.push({
        userId: student.id,
        roleId: role.id,
        roleName: role.role,
      });
    });

    if (!student.roles.length) {
      userRoles.push({
        userId: student.id,
      });
    }
  });

  return userRoles;
}

export const transformGroupsToLessonGroup = (groups, lessonSessionId) => ({
  lessonSessionId,
  userGroups: groups.map((group, index) => ({
    name: `group ${index + 1}`,
    userRoles: transformGroupToUserRoles(group),
  })),
});
