import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const LocationHistoryContext = createContext({});

export default function LocationHistoryProvider({ children }) {
  const [locationHistoryProvider, setLocationHistoryProvider] = useState(
    new Set(),
  );
  const [hasLocationHistory, setHasLocationHistory] = useState(new Set());

  const location = useLocation();

  useEffect(() => {
    // if pathname has changed, add it to the history
    setLocationHistoryProvider((prev) => new Set([location.pathname, ...prev]));
    setHasLocationHistory(
      (prev) => new Set([location.pathname.match(/^\/([^/])*/)[0], ...prev]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location]);

  const context = useMemo(
    () => ({
      hasHistory: hasLocationHistory.size > 1,
      locationHistory: Array.from(locationHistoryProvider.values()),
    }),
    [hasLocationHistory.size, locationHistoryProvider],
  );

  return (
    <LocationHistoryContext.Provider value={context}>
      {children}
    </LocationHistoryContext.Provider>
  );
}
