export const dataTestIds = {
  component: {
    actions: {
      button: {
        // when you change this value -> you will have to change it as well in the tailwindcomponents library (they aren't linked)
        // or even better yet, make sure to add the datatestids in tailwindcomponents too!!!!
        showMore: 'table-button-show-more',
      },
    },

    confirmationDialog: {
      button: {
        cancel: 'confirmation-dialog-button-cancel',
        submit: 'confirmation-dialog-button-submit',
      },
      id: 'confirmation-dialog',
    },

    loader: 'page-loading',

    pagination: {
      button: {
        next: 'pagination-button-next',
        number: 'pagination-button-number',
        previous: 'pagination-button-previous',
      },
      text: {
        endItemCurrentSelection: 'pagination-text-end-item-current-selection',
        startItemCurrentSelection:
          'pagination-text-start-item-current-selection',
        totalItems: 'pagination-text-total-items',
      },
    },

    search: {
      clear: 'clear-searchbar',
    },
  },

  header: {
    classgroups: 'header-classgroups',
    exercise: 'header-exercise',
    hackroom: 'header-hackroom',
    help: 'header-help',
    home: 'header-home',
    learn: 'header-learn',
    manage: 'header-manage',
    studio: 'header-studio',

    userMenu: {
      id: 'user-menu',
      links: {
        help: 'user-menu-help',
        logout: 'user-menu-logout',
        profile: 'user-menu-profile',
      },
    },
  },

  // when changing these values => make sure to change it in the corresponding component
  // or even better yet, make sure to add the datatestids in tailwindcomponents too!!!!
  modal: {
    activateOrganisation: {
      button: {
        cancel: 'activate-organisation-button-cancel',
        submit: 'activate-organisation-button-submit',
      },
    },

    addProgramToOrganization: {
      button: {
        close: 'add-program-to-organization-button-close',
      },
      table: {
        button: {
          add: 'add-program-to-organization-table-button-add',
        },
      },
    },

    calendarItem: {
      button: {
        cancel: 'calendar-item-button-cancel',
        submit: 'calendar-item-button-submit',
      },
      self: 'calendar-item',
    },

    createClassgroup: {
      button: {
        cancel: 'create-classgroup-button-cancel',
        submit: 'create-classgroup-button-submit',
      },
      input: {
        name: 'create-classgroup-input-name',
      },
    },

    createUser: {
      button: {
        cancel: 'create-user-button-cancel',
        submit: 'create-user-button-submit',
      },
      dropdown: {
        language: 'create-user-dropdown-password',
      },
      input: {
        email: 'create-user-input-email',
        firstname: 'create-user-input-firstname',
        lastname: 'create-user-input-lastname',
        password: 'create-user-input-password',
        username: 'create-user-input-username',
      },
      radio: {
        student: 'create-user-radio-student',
        teacher: 'create-user-radio-teacher',
      },
    },

    deactivateOrganisation: {
      button: {
        cancel: 'deactivate-organisation-button-cancel',
        submit: 'deactivate-organisation-button-submit',
      },
    },

    deleteProgramFromOrganization: {
      button: {
        cancel: 'delete-program-from-organization-button-cancel',
        submit: 'delete-program-from-organization-button-submit',
      },
    },

    editPeriod: {
      button: {
        cancel: 'edit-period-button-cancel',
        submit: 'edit-period-button-submit',
      },
      input: {
        description: 'edit-period-input-description',
        endDate: 'edit-period-input-end-date',
        name: 'edit-period-input-name',
        startDate: 'edit-period-input-start-date',
      },
    },

    editSeatsFromProgram: {
      button: {
        cancel: 'edit-seats-from-program-button-cancel',
        submit: 'edit-seats-from-program-button-submit',
      },
      input: {
        period: 'edit-period-for-organization-program',
        seats: 'edit-seats-for-organization-program',
      },
    },

    editTag: {
      button: {
        cancel: 'edit-tag-button-cancel',
        submit: 'edit-tag-button-submit',
      },
      input: {
        color: 'edit-tag-input-color',
        name: 'edit-tag-input-name',
      },
    },

    editUser: {
      button: {
        cancel: 'edit-user-button-cancel',
        submit: 'edit-user-button-submit',
      },
      input: {
        email: 'edit-user-input-email',
        firstName: 'edit-user-input-first-name',
        lastName: 'edit-user-input-last-name',
      },
    },

    importExcel: {
      button: {
        cancel: 'import-excel-button-cancel',
        startImport: 'import-excel-button-start-import',
      },
      input: {
        import: 'import-excel-button-import',
      },
      text: {
        error: 'import-excel-text-error',
      },
    },

    manageTagOnItem: {
      button: {
        close: 'manage-tag-on-item-close',
        link: 'manage-tag-on-item-link',
        unlink: 'manage-tag-on-item-unlink',
      },
      input: {
        tagName: 'manage-tag-on-item-input-tag-name',
      },
    },

    organization: {
      button: {
        calendar: 'aou-org-button-calendar',
        cancel: 'aou-org-button-cancel',
        submit: 'aou-org-button-submit',
      },
      dropdown: {
        issuer: 'aou-org-dropdown-issuer',
        language: 'auo-org-dropdown-language',
      },
      input: {
        clientId: 'aou-org-input-clientId',
        clientSecret: 'aou-org-input-clientSecret',
        domainName: 'aou-org-input-domainName',
        name: 'aou-org-input-name',
        prefix: 'aou-org-input-prefix',
        tenantId: 'aou-org-input-tenantId',
      },
    },

    quickPlan: {
      button: {
        closeAll: 'quick-plan-button-close-all',
        openAll: 'quick-plan-button-open-all',
      },
      self: 'quick-plan-modal',
    },

    resetPassword: {
      button: {
        cancel: 'reset-password-button-cancel',
        password: {
          copy: 'reset-password-button-copy-password',
          generate: 'reset-password-button-generate-password',
        },
        submit: 'reset-password-button-submit',
      },
      input: {
        password: 'reset-password-input-password',
      },
      text: {
        password: {
          strength: 'reset-password-text-password-strength',
        },
      },
    },

    taskErrors: {
      close: 'task-error-modal-close',
    },
  },

  multiScreen: {
    currentSlide: 'multiscreen-current-slide',
    currentSlideTeacherManual: 'multiscreen-current-slide-teacher-manual',
    goToNextSlide: 'multiscreen-go-to-next-slide',
    goToPreviousSlide: 'multiscreen-go-to-previous-slide',
    mainDisplay: 'multiscreen-main-display',
    makeMainDisplay: 'multiscreen-make-main-display',
    nextSlide: 'multiscreen-next-slide',
    secondaryDisplay: 'multiscreen-secondary-display',
    tertiaryDisplay: 'multiscreen-tertiary-display',
  },

  page: {
    agenda: {
      button: {
        dayDisplay: 'agenda-button-day-display',
        dayDisplayFull: 'agenda-button-day-display-full',
        nextWeek: 'agenda-button-next-week',
        previousWeek: 'agenda-button-previous-week',
        schedule: 'agenda-button-schedule',
        today: 'agenda-button-today',
        weekDisplay: 'agenda-button-week-display',
        weekDisplayFull: 'agenda-button-week-display-full',
      },
    },

    impersonate: {
      id: 'impersonator',
    },

    importUsersAndClasses: {
      button: {
        import: 'import-users-and-classes-button-import',
      },
    },

    learn: {
      chapterItemOverview: {
        lessons: 'chapterItemLessonsLink',
        progress: 'chapterItemProgressLink',
        search: 'filterChapterItemByName',
        select: 'filterChapterItemByTags',
      },

      chapterItemProgress: {
        table: {
          self: 'chapterItemProgressTable',
        },
      },

      chapterOverview: {
        search: 'filterChapterByName',
        select: 'filterChapterByTags',
      },

      ownLessons: {
        button: {
          planOwnContent: 'own-lesson-button-plan-own-content',
          quickPlanOwnContent: 'own-lesson-button-quick-plan-own-content',
        },
        search: 'own-lesson-search',
      },

      programOverview: {
        search: 'filterProgramByName',
      },

      results: {
        table: {
          button: {
            view: {
              result: 'learn-progress-table-button-view-result',
              scratchExercise:
                'learn-progress-table-button-view-scratch-exercise',
            },
          },
          self: 'learn-progress-table-self',
        },
      },
    },

    manage: {
      button: {
        add: {
          class: 'manage-button-add-class',
          organisation: 'manage-button-add-organisation',
          program: 'manage-button-add-program',
          tag: 'manage-button-add-tag',
          user: 'manage-button-add-user',
        },
        extra: {
          id: 'manage-button-extra',
          options: {
            importExcel: 'manage-button-extra-import-excel',
            linkTeachersToAllPrograms:
              'manage-button-extra-link-teachers-to-all-programs',
            user: 'manage-button-extra-add-user',
          },
        },
        stat: {
          classes: 'manage-button-stat-classes',
          organisations: 'manage-button-stat-organisations',
          programs: 'manage-button-stat-programs',
          students: 'manage-button-stat-students',
          teachers: 'manage-button-stat-teachers',
        },
        sync: {
          smartschool: 'manage-button-sync-users-smartschool',
          teams: 'manage-button-sync-users-teams',
        },
        tab: {
          organisations: 'manage-button-tab-organisations',
          programs: 'manage-button-tab-programs',
          reports: 'manage-button-tab-reports',
          tags: 'manage-button-tab-tags',
        },
      },
      input: {
        dailySync: 'manage-input-dailySync',
        name: 'manage-input-name',
        role: 'manage-input-role',
      },
      programs: {
        table: 'manage-program-table',
      },
      smartschool: {
        inputs: {
          filterClassgroup: 'smartschool-filter-classgroup',
        },
        tabs: {
          status: 'smartschool-status-tab',
          sync: 'smartschool-sync-tab',
        },
      },
      table: {
        button: {
          addChild: 'manage-table-button-add-child',
          archive: 'manage-table-button-archive',
          content: 'manage-table-button-content',
          delete: 'manage-table-button-delete',
          edit: 'manage-table-button-edit',
          manageTag: 'manage-table-button-manage-tag',
          unarchive: 'manage-table-button-unarchive',
          usage: 'manage-table-button-usage',
          view: 'manage-table-button-view',
        },
        self: 'manage-table',
      },
      teams: {
        inputs: {
          filterClassgroup: 'teams-filter-classgroup',
        },
        tabs: {
          status: 'teams-status-tab',
          sync: 'teams-sync-tab',
        },
      },
      toggle: {
        multiCollapsable: 'manage-toggle-multi-collapsable',
        showArchived: 'manage-toggle-show-inactive',
        showCalendar: 'manage-toggle-show-calendar',
        showDisabled: 'manage-toggle-show-disabled',
      },
      usage: {
        filter: {
          classId: 'usage-for-program-classId',
          name: 'usage-for-program-name',
        },
        table: 'usage-for-program-table',
      },
      users: {
        actions: {
          disable: 'disable-user',
          edit: 'edit-user',
          editPassword: 'edit-password',
          enable: 'enable-user',
          view: 'view-user-detail',
        },
        dialogs: {
          cancel: {
            deactivate: 'cancel-user-deactivate',
          },
          confirm: {
            deactivate: 'confirm-user-deactivate',
          },
        },
      },
    },

    manageChapter: {
      button: {
        add: {
          chapter: 'manage-chapter-button-add-chapter',
        },
      },
    },

    organisationProfile: {
      button: {
        add: {
          period: 'organisation-profile-button-add-period',
          program: 'organisation-profile-button-add-program',
          schooladmin: 'organisation-profile-button-add-schooladmin',
        },
        stat: {
          classgroup: 'organisation-profile-button-stat-classgroup',
          program: 'organisation-profile-button-stat-program',
          schooladmin: 'organisation-profile-button-stat-schooladmin',
          student: 'organisation-profile-button-stat-student',
          teacher: 'organisation-profile-button-stat-teacher',
        },
        tab: {
          periods: 'organisation-profile-button-tab-periods',
          programs: 'organisation-profile-button-tab-programs',
          reports: 'organisation-profile-button-tab-reports',
          users: 'organisation-profile-button-tab-users',
        },
      },
      dropdown: {
        role: 'organisation-profile-dropdown-role',
      },
      input: {
        name: 'organisation-profile-input-name',
      },
      table: {
        button: {
          delete: 'organisation-profile-table-button-delete',
          disable: 'organisation-profile-table-button-disable',
          edit: 'organisation-profile-table-button-edit',
          enable: 'organisation-profile-table-button-enable',
          impersonate: 'organisation-profile-table-button-impersonate',
          resetPassword: 'organisation-profile-table-button-reset-password',
        },
      },
    },

    profile: {
      avatar: 'profile_avatar',
      codeBlocks: {
        showLineNumbers: 'codeblocks-showLineNumbers',
        showMatchingBraces: 'codeblocks-showMatchingBraces',
        showRainbowBrackets: 'codeblocks-shoMatchingBraceswRainbowBrackets',
        theme: 'codeblocks-theme',
      },
      nav: 'profile_nav',
      notifications: 'profile_notifications',
      preferences: 'profile_preferences',
      profile: 'profile',
      userInfo: 'profile_userinfo',
    },

    results: {
      tabs: {
        exercise: 'results-exercise-tab',
        scratch: 'results-scratch-tab',
      },
    },
  },

  scratch: {
    assistant: {
      speechBubble: 'coach-codi-speech-bubble-text',
    },
  },
};

export const toastClassnameIdentifierId = 'toasties-classname';

export const managePeriodsDataTestIds = {
  periodDescription: 'periodDescription',
  periodName: 'periodName',
};
