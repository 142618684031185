import { useEffect, useState } from 'react';

import useFormatMessage from 'hooks/useFormatMessage';

import { calculatePossibleRoles } from './helperFunctions/calculatePossibleRoles';

import { DraggableMemberRow } from './DraggableMemberRow';

export function Group({ index, members, provided, roles }) {
  const t = useFormatMessage();
  const [possibleOptions, setPossibleOptions] = useState();
  const [groupMembers, setGroupMembers] = useState();
  const adjustPossibleOptions = (choice, memberIndex) => {
    const adjustedRoles = calculatePossibleRoles(
      roles,
      choice,
      memberIndex,
      groupMembers,
    );
    setPossibleOptions(adjustedRoles.newAvailableOptions);
    const newGroupMembers = [...groupMembers];
    newGroupMembers[memberIndex].roles = adjustedRoles.rolesForMember;
    setGroupMembers(newGroupMembers);
  };
  const showRoles = Boolean(roles.length);

  useEffect(() => {
    setPossibleOptions([]);
    setGroupMembers([...members]);
  }, [members]);

  return (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      <h4 className="text-2xl font-semibold">
        {t('groups.group')} {index + 1}
      </h4>
      {groupMembers
        ? groupMembers.map((member, memberIndex) => {
            const key = `dmr${memberIndex}${Date.now()}`;

            return (
              <DraggableMemberRow
                key={key}
                draggableId={`${index}/${memberIndex}`}
                index={Number(`${index}${memberIndex}`)}
                member={member}
                memberIndex={memberIndex}
                options={possibleOptions}
                setPossibleOptions={adjustPossibleOptions}
                showRoles={showRoles}
              />
            );
          })
        : null}
      {provided.placeholder}
    </div>
  );
}
