import { gql } from 'graphql-request';

export const ADMIN_DELETE_PROGRAM_FROM_PERIOD = gql`
  mutation adminDeleteProgramFromPeriod($periodId: Int!, $programId: Int!) {
    adminDeleteProgramFromPeriod(periodId: $periodId, programId: $programId) {
      statusCode
      id
      message
    }
  }
`;
