import { Content, PageHeader } from '@ftrprf/tailwind-components';

export default function ChapterSkeleton({ children, loading }) {
  return loading ? (
    <>
      <PageHeader>
        <div className="flex flex-col space-y-4 md:flex-row w-full justify-between">
          <div className="flex items-end space-x-2">
            <div className="flex flex-col space-y-1">
              <span className="bg-gray-200 animate-pulse w-80 h-10 mt-12" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-8 items-start space-y-4">
            <div className="bg-accent-100 w-32 h-6 animate-pulse mt-2" />
          </div>
        </div>
      </PageHeader>
      <div className="w-full flex flex-col mt-8">
        <div className="mb-4 rounded w-full max-w-5/6 self-center">
          <div className="flex flex-wrap gap-y-4 sm:space-x-2 flex-col sm:flex-row lg:self-end lg:justify-end w-auto">
            <div className="bg-accent-100 w-32 h-6 animate-pulse" />
          </div>
        </div>
      </div>
      <Content>
        <div className="flex flex-col w-full h-80">
          <div className="bg-gray-200 animate-pulse h-full" />
        </div>
      </Content>
    </>
  ) : (
    children
  );
}
