import { generatePath, useParams } from 'react-router-dom';

import { EyeIcon } from '@heroicons/react/24/outline';

import {
  Actions,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS from 'utils/constants/urls';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import { generateStudentResultPagePathWithLesson } from 'utils/generateUrlPaths';
import {
  getHoursAndMinutes,
  getShortDate,
  utcDatetoLocalDate,
} from 'utils/timeHelpers';

export default function LessonResultRow({ lesson }) {
  const { id: userId, isTeacher } = useContext(UserContext);
  const t = useFormatMessage();
  const { programId } = useParams();

  const { endTime, startTime } = lesson.calendarItem;
  const startDate = utcDatetoLocalDate(startTime);
  const endDate = utcDatetoLocalDate(endTime);

  const actions = [
    {
      icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.RESULT.view'),
      href: isTeacher
        ? generatePath(URLS.CALENDAR_ITEM_RESULTS, {
            calendarItemId: lesson.calendarItem.id,

            programId: programId || nonNumericProgramIds.OWN,
          })
        : generateStudentResultPagePathWithLesson(lesson, userId),
    },
  ];

  return (
    <ResponsiveTableRow data={lesson}>
      {isTeacher && (
        <ResponsiveTableCol>
          <div className="flex gap-2">
            {lesson?.calendarItem?.classGroups.map((group) => (
              <span
                key={`class${group.id}`}
                className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-accent-100 text-accent-800"
              >
                {group.name}
              </span>
            ))}
          </div>
        </ResponsiveTableCol>
      )}
      <ResponsiveTableCol>{getShortDate(startDate)}</ResponsiveTableCol>
      <ResponsiveTableCol>
        {getHoursAndMinutes(startDate)}
        <span className="text-sm text-gray-500">
          {' '}
          {t('lessons-overview.until')}{' '}
        </span>
        {getHoursAndMinutes(endDate)}
      </ResponsiveTableCol>
      <ResponsiveTableCol>
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
          {lesson.firstName} {lesson.lastName}
        </span>
      </ResponsiveTableCol>

      <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
        <Actions items={actions} />
      </ResponsiveTableCol>
    </ResponsiveTableRow>
  );
}
