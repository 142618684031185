import { isValidElement, useRef, useState } from 'react';

import c from '../utils/c';

const nonMutatingKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Backspace',
  'Delete',
];

const controlKeys = ['KeyA', 'KeyC', 'KeyX', 'KeyV'];

export function Editable({
  canPaste = true,
  children,
  className = '',
  contentEditable = true,
  defaultValue = '',
  maxChars,
  onBlur = () => {},
  singleLine = false,
  test = '',
}) {
  if (typeof children && isValidElement(children)) {
    throw new Error('No React nodes expected as child');
  }
  const [startContent, setStartContent] = useState('');
  const [previousControlPressed, setPreviousControlPressed] = useState(false);

  const ref = useRef();

  const onKeyDown = (e) => {
    if (!previousControlPressed) {
      setPreviousControlPressed(e.code.startsWith('Control'));
    }
    // prevent typing beyond a certain amount of characters
    if (
      maxChars &&
      ref.current.innerText.length === maxChars &&
      !nonMutatingKeys.includes(e.code) &&
      !(previousControlPressed && controlKeys.includes(e.code))
    ) {
      e.preventDefault();

      return;
    }

    // prevent pressing enter
    if (singleLine && e.code === 'Enter') {
      e.preventDefault();
    }
  };

  const onKeyUp = (e) => {
    if (e.code.startsWith('Control')) {
      setPreviousControlPressed(false);
    }
  };

  const onPasteCapture = (e) => {
    if (!canPaste) {
      e.preventDefault();
    }
  };
  return (
    <div
      ref={ref}
      className={c(
        className,
        'outline-none',
        contentEditable && 'focus:bg-gray-200 hover:bg-gray-200 cursor-text',
      )}
      contentEditable={contentEditable}
      data-test={test}
      onBlur={() => {
        if (ref.current.innerText !== startContent) {
          onBlur(ref.current.innerText);
        }
      }}
      onFocus={(e) => {
        setStartContent(ref.current.innerText);
        if (e.target.innerText === defaultValue) {
          e.target.innerText = '';
          e.target.focus();
        }
      }}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onPasteCapture={onPasteCapture}
      style={{ minWidth: '10em' }}
      suppressContentEditableWarning
    >
      {children}
    </div>
  );
}
