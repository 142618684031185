import { getTimeOptionHours, getTimeOptionMinutes } from 'utils/timeHelpers';
import { DateForLesson } from './TimeInfo/DateForLesson';
import { StartTime } from './TimeInfo/StartTime';
import { EndTime } from './TimeInfo/EndTime';
import { CloseAfterEndTime } from './TimeInfo/CloseAfterEndTime';

export function TimeInfo({ formOption, getValues, itemInfo, validateEndTime }) {
  const { control, errors } = formOption;
  const today = new Date();
  const timeOptionHour = getTimeOptionHours();
  const timeOptionMinute = getTimeOptionMinutes();

  return (
    <>
      <div className="Date sm:flex sm:gap-4">
        <DateForLesson
          control={control}
          errors={errors}
          getValues={getValues}
          today={today}
        />
        <StartTime
          control={control}
          errors={errors}
          itemInfo={itemInfo}
          timeOptionHour={timeOptionHour}
          timeOptionMinute={timeOptionMinute}
          today={today}
        />

        <EndTime
          control={control}
          errors={errors}
          itemInfo={itemInfo}
          timeOptionHour={timeOptionHour}
          timeOptionMinute={timeOptionMinute}
          today={today}
          validateEndTime={validateEndTime}
        />
      </div>
      <CloseAfterEndTime control={control} />
    </>
  );
}
