export function CodeForPreview({ showMatchingBraces, showRainbowBrackets }) {
  if (!showMatchingBraces && !showRainbowBrackets) {
    return (
      <>
        <span className="token keyword">def</span>{' '}
        <span className="token function">name</span>
        <span className="token punctuation">(</span>
        <span className="token punctuation">)</span>
        <span className="token punctuation">:</span>
        <br />
        &emsp;&emsp;&emsp;
        <span className="token keyword">return</span>{' '}
        <span className="token string">&quot;Timo&quot;</span>
        <span className="token punctuation">,</span>
        <span className="token string">&quot;Jannick&quot;</span>
        <br />
        <br />
        <span className="token comment">
          # print the tuple with the returned values
        </span>
        <br />
        <span className="token keyword">print</span>
        <span className="token punctuation">(</span>name
        <span className="token punctuation">(</span>
        <span className="token punctuation">)</span>
        <span className="token punctuation">)</span>
        <br />
        <br />
        <span className="token comment"># get the individual items</span>
        <br />
        name_1<span className="token punctuation">,</span> name_2{' '}
        <span className="token operator">=</span> name
        <span className="token punctuation">(</span>
        <span className="token punctuation">)</span>
        <br />
        <span className="token keyword">print</span>
        <span className="token punctuation">(</span>name_1
        <span className="token punctuation">,</span> name_2
        <span className="token punctuation">)</span>
      </>
    );
  }

  if (showMatchingBraces && !showRainbowBrackets) {
    return (
      <>
        <span className="token keyword">def</span>{' '}
        <span className="token function">name</span>
        <span
          className="token punctuation brace-round brace-open brace-level-1"
          id="pair-45-close"
        >
          (
        </span>
        <span
          className="token punctuation brace-round brace-close brace-level-1"
          id="pair-45-open"
        >
          )
        </span>
        <span className="token punctuation">:</span>
        <br />
        &emsp;&emsp;&emsp;
        <span className="token keyword">return</span>{' '}
        <span className="token string">&quot;Timo&quot;</span>
        <span className="token punctuation">,</span>
        <span className="token string">&quot;Jannick&quot;</span>
        <br />
        <br />
        <span className="token comment">
          # print the tuple with the returned values
        </span>
        <br />
        <span className="token keyword">print</span>
        <span
          className="token punctuation brace-round brace-open brace-level-1"
          id="pair-47-close"
        >
          (
        </span>
        name
        <span
          className="token punctuation brace-round brace-open brace-level-2"
          id="pair-46-close"
        >
          (
        </span>
        <span
          className="token punctuation brace-round brace-close brace-level-2"
          id="pair-46-open"
        >
          )
        </span>
        <span
          className="token punctuation brace-round brace-close brace-level-1"
          id="pair-47-open"
        >
          )
        </span>
        <br />
        <br />
        <span className="token comment"># get the individual items</span>
        <br />
        name_1<span className="token punctuation">,</span> name_2{' '}
        <span className="token operator">=</span> name
        <span
          className="token punctuation brace-round brace-open brace-level-1"
          id="pair-48-close"
        >
          (
        </span>
        <span
          className="token punctuation brace-round brace-close brace-level-1"
          id="pair-48-open"
        >
          )
        </span>
        <br />
        <span className="token keyword">print</span>
        <span
          className="token punctuation brace-round brace-open brace-level-1"
          id="pair-49-close"
        >
          (
        </span>
        name_1<span className="token punctuation">,</span> name_2
        <span
          className="token punctuation brace-round brace-close brace-level-1"
          id="pair-49-open"
        >
          )
        </span>
      </>
    );
  }

  return (
    <>
      <span className="token keyword">def</span>{' '}
      <span className="token function">name</span>
      <span
        className="token punctuation brace-round brace-open brace-level-1"
        id="pair-25-close"
      >
        (
      </span>
      <span
        className="token punctuation brace-round brace-close brace-level-1"
        id="pair-25-open"
      >
        )
      </span>
      <span className="token punctuation">:</span>
      <br />
      &emsp;&emsp;&emsp;
      <span className="token keyword">return</span>{' '}
      <span className="token string">&quot;Timo&quot;</span>
      <span className="token punctuation">,</span>
      <span className="token string">&quot;Jannick&quot;</span>
      <br />
      <br />
      <span className="token comment">
        # print the tuple with the returned values
      </span>
      <br />
      <span className="token keyword">print</span>
      <span
        className="token punctuation brace-round brace-open brace-level-1"
        id="pair-27-close"
      >
        (
      </span>
      name
      <span
        className="token punctuation brace-round brace-open brace-level-2"
        id="pair-26-close"
      >
        (
      </span>
      <span
        className="token punctuation brace-round brace-close brace-level-2"
        id="pair-26-open"
      >
        )
      </span>
      <span
        className="token punctuation brace-round brace-close brace-level-1"
        id="pair-27-open"
      >
        )
      </span>
      <br />
      <br />
      <span className="token comment"># get the individual items</span>
      <br />
      name_1<span className="token punctuation">,</span> name_2{' '}
      <span className="token operator">=</span> name
      <span
        className="token punctuation brace-round brace-open brace-level-1"
        id="pair-28-close"
      >
        (
      </span>
      <span
        className="token punctuation brace-round brace-close brace-level-1"
        id="pair-28-open"
      >
        )
      </span>
      <br />
      <span className="token keyword">print</span>
      <span
        className="token punctuation brace-round brace-open brace-level-1"
        id="pair-29-close"
      >
        (
      </span>
      name_1<span className="token punctuation">,</span> name_2
      <span
        className="token punctuation brace-round brace-close brace-level-1"
        id="pair-29-open"
      >
        )
      </span>
    </>
  );
}
