import { useContext, useEffect, useMemo, useState } from 'react';

import {
  Dropdown,
  Label,
  prismThemes,
  Toggle,
} from '@ftrprf/tailwind-components';

import Divider from 'components/Divider';
import useUpdateOwnUserUiSettings from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserUiSettings';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { dataTestIds } from 'utils/dataTestIds';

import { getUpdatedUiSettingsObject } from 'utils/profileHelpers';
import { CodeBlocksPreview } from './CodeBlocksPreview';

/**
 * Preferences set here are stored in the userSettings.
 * Several of the settings are applied in the SlideViewerLayout.
 */
export default function CodeBlocks({ settings }) {
  const t = useFormatMessage();
  const { refetchSettings } = useContext(UserContext);
  const { mutateAsync: updateUiSettings } = useUpdateOwnUserUiSettings();
  const [theme, setTheme] = useState(
    settings?.slideViewer?.codeBlocksTheme ?? 'twilight',
  );
  const [showLineNumbers, setShowLineNumbers] = useState(
    settings?.slideViewer?.showLineNumbers ?? false,
  );
  const [showMatchingBraces, setShowMatchingBraces] = useState(
    settings?.slideViewer?.showMatchingBraces ?? true,
  );
  const [showRainbowBrackets, setShowRainbowBrackets] = useState(
    settings?.slideViewer?.showRainbowBrackets ?? false,
  );

  const newSlideViewerSettings = useMemo(
    () => ({
      codeBlocksTheme: theme,
      showLineNumbers,
      showMatchingBraces,
      showRainbowBrackets,
    }),
    [showLineNumbers, showMatchingBraces, showRainbowBrackets, theme],
  );

  const updateAndRefetch = (newValuesObject) => {
    updateUiSettings(
      getUpdatedUiSettingsObject(settings, newValuesObject),
    ).then(() => {
      refetchSettings();
    });
  };

  useEffect(() => {
    const { slideViewer } = settings;

    if (slideViewer) {
      const {
        codeBlocksTheme,
        showLineNumbers: lineNumbers,
        showMatchingBraces: matchingBraces,
        showRainbowBrackets: rainbowBrackets,
      } = slideViewer;

      if (codeBlocksTheme !== theme) setTheme(codeBlocksTheme);
      if (lineNumbers !== showLineNumbers) setShowLineNumbers(lineNumbers);
      if (matchingBraces !== showMatchingBraces)
        setShowMatchingBraces(matchingBraces);
      if (rainbowBrackets !== showRainbowBrackets)
        setShowRainbowBrackets(rainbowBrackets);
    }
  }, [
    settings,
    showLineNumbers,
    showMatchingBraces,
    showRainbowBrackets,
    theme,
  ]);

  return (
    <div>
      <Divider text={t('profile.codeblocks.settings')} />
      <div className="mt-4 flex flex-col">
        <Label className="font-medium" htmlFor="chooseCodeBlocksTheme">
          {t('profile.codeblocks')}
        </Label>
        <Dropdown
          className="w-full text-black"
          inputId="chooseCodeBlocksTheme"
          isMulti={false}
          menuPlacement="top"
          onChange={(theme) => {
            setTheme(theme);
            updateAndRefetch({
              slideViewer: {
                ...newSlideViewerSettings,
                codeBlocksTheme: theme,
              },
            });
          }}
          options={Object.keys(prismThemes).map((k) => ({
            key: k,
            value: k,
            label: prismThemes[k].name,
          }))}
          test={dataTestIds.page.profile.codeBlocks.theme}
          value={theme}
        />
      </div>
      <Toggle
        className="my-4"
        label={t('profile.codeblocks.showLineNumbers')}
        onChange={(showLineNumbers) => {
          setShowLineNumbers(showLineNumbers);
          updateAndRefetch({
            slideViewer: { ...newSlideViewerSettings, showLineNumbers },
          });
        }}
        test={dataTestIds.page.profile.codeBlocks.showLineNumbers}
        value={showLineNumbers}
        visibleLabel
      />
      <Toggle
        className="my-4"
        label={t('profile.codeblocks.showMatchingBraces')}
        onChange={(showBraces) => {
          setShowMatchingBraces(showBraces);
          updateAndRefetch({
            slideViewer: {
              ...newSlideViewerSettings,
              showMatchingBraces: showBraces,
            },
          });
        }}
        test={dataTestIds.page.profile.codeBlocks.showMatchingBraces}
        value={showMatchingBraces}
        visibleLabel
      />
      <Toggle
        className="my-4"
        label={t('profile.codeblocks.showRainbowBrackets')}
        onChange={(showBrackets) => {
          setShowRainbowBrackets(showBrackets);
          updateAndRefetch({
            slideViewer: {
              ...newSlideViewerSettings,
              showRainbowBrackets: showBrackets,
            },
          });
        }}
        test={dataTestIds.page.profile.codeBlocks.showRainbowBrackets}
        value={showRainbowBrackets}
        visibleLabel
      />
      <CodeBlocksPreview
        showLineNumbers={showLineNumbers}
        showMatchingBraces={showMatchingBraces}
        showRainbowBrackets={showRainbowBrackets}
        theme={theme}
      />
    </div>
  );
}
