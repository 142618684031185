import { Transition } from '@headlessui/react';

export function FadeInOutTransition({ children, isOpen }) {
  return (
    <Transition
      appear
      enter="transform transition ease-in-out duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={isOpen}
    >
      {children}
    </Transition>
  );
}
