import { shuffleArray } from 'utils/shuffleArray';

/**
 * Divide members into random groups based on the amount.
 * If members % amountOfGroups > 0 then add 1 "surplus" member to each of
 * the groups until the members run out.
 */
export function createRandomGroups(members, amountOfGroups) {
  const groups = [];
  const shuffledMembers = shuffleArray(members);

  for (let i = 0; i < amountOfGroups; i++) {
    groups.push([]);
  }

  let currentGroup = 0;
  do {
    groups[currentGroup].push(shuffledMembers.shift());
    currentGroup += 1;
    if (currentGroup === amountOfGroups) currentGroup = 0;
  } while (shuffledMembers.length);

  return groups;
}
