import { OWN_CONTENT } from 'utils/constants/contentTypes';

export function getStudioIdForLesson(
  teacherPrograms,
  programId,
  chapterId,
  chapterItemId,
  itemInfo,
  studioId,
) {
  if (studioId) return studioId;

  if (programId === OWN_CONTENT || !programId) {
    return itemInfo?.studioId || chapterItemId;
  }

  if (itemInfo?.studioId) return itemInfo?.studioId;

  const program = teacherPrograms?.find((program) => program.id === programId);
  const chapter = program?.chapters.find((chapter) => chapter.id === chapterId);
  const chapterItem = chapter?.items.find(
    (chapterItem) => chapterItem.id === chapterItemId,
  );

  return chapterItem?.lessons[0]?.studioId;
}
