import { ErrorBoundary } from '@sentry/react';
import useFormatMessage from 'hooks/useFormatMessage';
import { DevelopmentError } from './DevelopmentError';
import { ErrorCrashed } from '../pages/Error/ErrorCrashed';

export function ErrorBoundaryWrapper({ children }) {
  const t = useFormatMessage();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const devFallBackComponent = ({ componentStack, error }) => (
    <DevelopmentError componentStack={componentStack} error={error} />
  );

  return (
    <ErrorBoundary
      fallback={
        isDevelopment ? (
          devFallBackComponent
        ) : (
          <ErrorCrashed message={t('errors.unknown')} titleKey="global.error" />
        )
      }
      showDialog
    >
      {children}
    </ErrorBoundary>
  );
}
