import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_DELETE_CALENDAR_ITEM } from 'api/plannerService/teacher/mutations/teacherDeleteCalendarItem';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherDeleteCalendarItem() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useMutation(
    ({ id, input }) =>
      plannerServiceQueryClient.request(TEACHER_DELETE_CALENDAR_ITEM, {
        id,
        input,
      }),
    {
      mutationKey: 'teacherDeleteCalendarItem',
      onSuccess: () => {
        toast(TOASTTYPES.DELETESUCCESS, {
          str: t('global.CALENDARITEM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.DELETEERROR, {
          str: t('global.CALENDARITEM.singular'),
        });
      },
    },
  );

  return { ...response };
}
