export default function PrivacyPolicy() {
  return (
    <div className="flex justify-center pb-36">
      <div className="w-1/2 relative -mt-[5.75rem] overflow-hidden pt-[5.75rem]">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-24">
            <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl">
              Terms of Service
            </h1>
            <p className="mt-4 text-base leading-7 text-gray-500 ">
              Last updated on July 6, 2022
            </p>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
            <p>
              Deze Gebruiksvoorwaarden hebben betrekking op het gebruik van het
              Platform. FTRPRF adviseert de Gebruiker deze Gebruiksvoorwaarden
              aandachtig door te nemen zodat de Gebruiker weet welke rechten en
              verplichtingen hij/zij heeft.
            </p>
            <p>
              <br />
              Bij vragen over deze Gebruiksvoorwaarden of over andere zaken
              i.v.m. het Platform, kan de Gebruiker schrijven naar FTRPRF BVBA,
              Koekelberg 45A, 9450 Denderhoutem, of contact opnemen via&nbsp;
              <a href="mailto:info@ftrprf.be">info@ftrprf.be</a>
            </p>
            <p>
              <strong>
                <u>Artikel 1 Definities</u>
              </strong>
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Aanmeldgegevens:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      de gegevens nodig om aan te melden op het Platform, en
                      indien de Overeenkomst met een School werd aangegaan, de
                      schoolcode, klasnaam, klasnummer en het wachtwoord van de
                      Leerling; de gebruikersnaam en het wachtwoord van de
                      Leerkracht; de gebruikersnaam en het wachtwoord van de
                      Ouder.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Account:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      de persoonlijke sectie die door de Gebruiker wordt
                      gecreëerd en beheerd wanneer hij zich aanmeldt via het
                      Platform en waarmee hij gebruik kan maken van de Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Content:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      alle informatie die door FTRPRF zelf op het Platform is
                      geplaatst, waaronder begrepen maar niet beperkt tot
                      leermateriaal, toetsmateriaal en een geïntegreerde
                      programmeeromgeving, de oefeningen, downloads,
                      video&apos;s, teksten en de lay-out en look and feel van
                      het Platform.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Dienst:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      de dienst die FTRPRF aan de Gebruiker verleent zoals
                      beschreven in artikel 4 van de Gebruiksvoorwaarden.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>FTRPRF:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      FTRPRF BVBA, met maatschappelijke zetel te Koekelberg 45A,
                      9450 Denderhoutem, ingeschreven in de Kruispuntbank van
                      Ondernemingen onder het nummer 0720.949.530
                    </p>
                    <p>(RPR Gent, afdeling Dendermonde).</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Gebruiker:</u>
                      </strong>
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>
                      iedere gebruiker van het Platform, hierbij inbegrepen maar
                      niet beperkt tot de School, de Smartschoolbeheerder, de
                      Ouder, de Leerkracht, de Leerling of de Trainer.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Gebruiksrecht:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      het beperkt recht van toegang, gebruik en weergave van het
                      Platform toegekend door FTRPRF aan de Gebruiker in
                      overeenstemming met artikel 5 van de Gebruiksvoorwaarden.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Gebruiksvoorwaarden:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>deze gebruiksvoorwaarden van FTRPRF.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>IE-rechten:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      alle op dit moment of hierna bestaande (i) octrooien,
                      octrooiaanvragen, openbaar gemaakte patenten en
                      uitvindingen (al dan niet octrooieerbaar), (ii) merken,
                      handelsmerken, dienstmerken, handelsimago, handelsnamen,
                      logos, ondernemingsnamen, internet domeinnamen en
                      registraties en aanvragen voor registratie hiervan samen
                      met alle goodwill hiermee geassocieerd, (iii)
                      auteursrechten en werken vatbaar voor auteursrecht en
                      registraties en aanvragen daarvan, (iv) computersoftware
                      programma’s, met inbegrip van broncode en objectcode,
                      gegevens databases en documentatie daarvan, (v)
                      handelsgeheimen en andere vertrouwelijke informatie, met
                      inbegrip van ideeën, formules, verbeteringen, knowhow,
                      informatie over (productie)processen en -technieken,
                      informatie over onderzoek en ontwikkeling, tekeningen,
                      specificaties, flowcharts, schema’s, protocollen, notities
                      van programmeurs, ontwerpen, ontwerprechten,
                      ontwikkelingen, ontdekkingen, businessplannen,
                      voorstellen, technische en financiële data, en (vi) alle
                      andere vormen van intellectuele eigendom.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Leerkracht:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      in het geval een Overeenkomst wordt aangegaan met een
                      School, een leerkracht (maar ook directie,
                      zorgcoördinator, ICT-coördinator, smartschool beheerder,
                      i.c. alle pedagogische medewerkers) van de School, die
                      gekoppeld is aan het Account van de School en daarmee
                      gebruik kan maken van de Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Leerling:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      – in het geval een Overeenkomst wordt aangegaan met een
                      School, een leerling van de School, die gekoppeld is aan
                      het Account van de School en daarmee gebruik kan maken van
                      de Dienst; of
                    </p>
                    <p>
                      – in het geval geen Overeenkomst wordt aangegaan, een kind
                      waarvoor een Account is aangemaakt en daarmee gebruik kan
                      maken van de Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Ouder:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      – in het geval een Overeenkomst wordt aangegaan met een
                      School, een ouder van de Leerling, die gekoppeld is aan
                      het Account van de School en daarmee gebruik kan maken van
                      de Dienst; of
                    </p>
                    <p>
                      – in het geval geen Overeenkomst wordt aangegaan met een
                      School, een ouder van de Leerling waarvoor een Account is
                      aangemaakt en waarmee de Leerling gebruik kan maken van de
                      Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Overeenkomst:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      de overeenkomst die de School of een andere instelling met
                      FTPRF sluit ten behoeve van het gebruik van de Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Platform:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      het leerplatform van FTRPRF genaamd “FTRPRF” dat via de
                      website&nbsp;
                      <a
                        href="https://schools.ftrprf.be/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        https://schools.ftrprf.be&nbsp;
                      </a>
                      en onderliggende pagina&apos;s bereikbaar is en via welke
                      de Gebruiker toegang tot de Dienst krijgt.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Privacybeleid:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Het privacybeleid van FTRPRF zoals opgenomen in de privacy
                      policy van FTRPRF, te raadplegen via volgende link
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>School:</u>
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      instelling die een Overeenkomst is aangegaan en gebruik
                      maakt van de Dienst.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        <u>Trainer:</u>
                      </strong>
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>
                      een trainer die de Leerling ondersteunt en begeleidt in
                      het kader van de Dienst en waarvoor in dat kader een
                      Account is aangemaakt.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>
                <u>Artikel 2 Toepasselijkheid</u>
              </strong>
            </p>
            <p>
              2.1 Deze Gebruiksvoorwaarden zijn van toepassing op elk gebruik
              dat de Gebruiker van de Dienst maakt en alle overeenkomsten die
              gesloten worden via het Platform en de rechtshandelingen of
              rechtsverhoudingen tussen de Gebruiker en FTRPRF. De algemene
              voorwaarden van de Gebruiker zijn nooit van toepassing en worden
              bij deze uitdrukkelijk uitgesloten.
            </p>
            <p>
              2.2 Door op de knop “Ja, ik ga akkoord” te klikken, erkent en
              aanvaardt de Gebruiker uitdrukkelijk dat deze Gebruiksvoorwaarden
              van toepassing zijn.
            </p>
            <p>
              2.3 FTRPRF is te allen tijde gerechtigd deze Gebruiksvoorwaarden
              te wijzigen of aan te vullen zonder dat dit de Gebruiker
              benadeelt. De meest actuele Gebruiksvoorwaarden zullen op het
              Platform te vinden zijn en worden tijdens het gebruik van het
              Platform onder de aandacht van de Gebruiker gebracht.
            </p>
            <p>
              2.4 In een aantal gevallen maakt de Leerling, de Ouder of de
              Leerkracht gebruik van het Platform omwille van de verhouding met
              een School. Deze School treedt dan op als licentienemer van FTRPRF
              hetgeen bepalend is voor de verdere gebruiksrechten op het
              Platform. Deze onderlinge verhoudingen doen geen afbreuk aan het
              bindende karakter van deze Gebruiksvoorwaarden voor de Gebruiker.
            </p>
            <p>
              <strong>
                <u>Artikel 3 Toegang tot de Dienst</u>
              </strong>
            </p>
            <p>
              3.1 Om gebruik te kunnen maken van de Dienst, moet de Gebruiker
              een Account aanmaken op de manier zoals beschreven op het
              Platform. De Gebruiker staat er jegens FTRPRF voor in dat de
              informatie die de Gebruiker bij het aanmaken van het account
              verstrekt compleet en juist is. Na registratie ontvangt de
              Gebruiker van FTRPRF Aanmeldgegevens.
            </p>
            <p>
              3.2 De Gebruiker is verantwoordelijk voor de correctheid en
              volledigheid van de verstrekte gegevens. Het is de
              verantwoordelijkheid van de Gebruiker om achterhaalde informatie
              en gegevens te (laten) wijzigen of verwijderen van zijn/haar
              Account. De gegevens die door de Gebruiker verstrekt worden,
              worden opgeslagen in een database en verwerkt in overeenstemming
              met het Privacybeleid.
            </p>
            <p>
              3.3 De Gebruiker is verantwoordelijk voor het geheim houden van de
              Aanmeldgegevens. De Gebruiker kan daarvoor maatregelen treffen,
              zoals het periodiek veranderen van de Aanmeldgegevens.
            </p>
            <p>
              3.4 Iedere Account is strikt individueel, persoonlijk en
              vertrouwelijk. De Account kan niet worden overgedragen aan derden.
              De Gebruiker is op exclusieve wijze verantwoordelijk voor alle
              handelingen die met de Account gesteld worden. Elke inbreuk op het
              vertrouwelijk karakter moet gemeld worden ofwel aan de School,
              indien een Overeenkomst met een School werd aangegaan, of aan
              FTRPRF.
            </p>
            <p>
              3.5 In geval een Overeenkomst wordt aangegaan met een School,
              staat de School ervoor in dat de Leerling, de Leerkracht en de
              Ouder die via het Account van de School gekoppeld zijn aan de
              School en toegang hebben tot de Dienst, zich zullen houden aan de
              Gebruiksvoorwaarden.
            </p>
            <p>
              3.6 De Gebruiker engageert zich tot het aanmaken van een
              persoonlijk account per leerling en zich te houden aan de algemene
              verkoopvoorwaarden. De School mag geen accounts onder Leerlingen
              en Leerkrachten dubbel gebruiken vanuit het oogmerk van comfort of
              kostenbesparing.
            </p>
            <p>
              3.7 FTRPRF houdt actieve controle op aangemaakte Accounts en
              behoudt zich het recht voor alle gepaste maatregelen te nemen in
              de gevallen waarin dit vereist wordt. FTRPRF behoudt zich het
              recht voor de aanmeldprocedure te veranderen indien zij dit
              noodzakelijk acht in het belang van het functioneren van het
              Platform.
            </p>
            <p>
              <strong>
                <u>Artikel 4 De Dienst en het gebruik van de Dienst</u>
              </strong>
            </p>
            <p>
              4.1 Het doel van de Dienst is het aanbieden van een educatieve
              tool waarin Content en een geïntegreerde programmeeromgeving
              worden aangeboden aan Leerkrachten en Leerlingen, het ondersteunen
              van de Leerling in zelfstandig leren in zijn thuisomgeving, en het
              ondersteunen van de Leerkracht in zijn onderwijsopdracht.
            </p>
            <p>
              4.2 De Dienst bestaat eruit dat de Leerling, Leerkracht, Trainer
              en Ouder toegang kunnen krijgen tot het Platform, waarop de
              Leerling kan leren – zowel in de klas als thuis- met de daarop
              beschikbaar gestelde Content en de Leerkracht of Trainer en Ouder
              inzicht kunnen krijgen in de vorderingen van de Leerling via de
              daarop voor hen respectievelijk beschikbaar gestelde rapportages.
              Daarnaast beschikt de Leerkracht of de Trainer via de Dienst over
              tools om Content te selecteren en die aan de Leerling toe te
              wijzen.
            </p>
            <p>
              <br />
              4.3 Onverminderd de overige bepalingen van deze
              Gebruiksvoorwaarden mogen de activiteiten in het kader van FTRPRF,
              waaronder mede het gebruik van de Dienst en het Platform door de
              School, de Leerkracht, de Leerling, de Trainer en de Ouder, niet:
            </p>
            <p>a) op onwaarheden gebaseerd en/of misleidend zijn;</p>
            <p>
              b) virussen, Trojaanse paarden, wormen, bots of andere
              programmatuur bevatten die een geautomatiseerd werk kunnen
              beschadigen, onbruikbaar of ontoegankelijk kunnen maken, kunnen
              wissen of zich kunnen toe-eigenen of die bedoeld zijn om
              technische beschermingsmaatregelen van het Platform, de Dienst
              en/of de computersystemen van FTRPRF te omzeilen;
            </p>
            <p>
              c) bestaan uit handelingen die erop gericht zijn om op
              ongeautoriseerde wijze toegang te verkrijgen tot Accounts van
              andere Gebruikers;
            </p>
            <p>
              d) bestaan uit het gebruik maken van andere dan de door de Dienst
              geboden tools om het Platform te doorzoeken dan wel gebruik te
              maken van datamining, robots of andere middelen om gegevens te
              verzamelen;
            </p>
            <p>
              e) bestaan uit het gebruik van enige soft- en/of hardwarematige
              tools en/of oplossingen (in eigen beheer of beschikbaar gesteld
              door derden), voor zover deze gericht zijn op het overnemen van
              enige via de Dienst toegankelijke gemaakte informatie, dan wel om
              het Platform op enigerlei andere wijze te spideren, scrapen,
              doorzoeken of op andere oneigenlijke wijze te gebruiken en/of in
              te zien;
            </p>
            <p>
              f) bestaan uit het gebruiken van de Dienst voor andere doeleinden
              dan beschreven in deze Gebruiksvoorwaarden;
            </p>
            <p>
              g) een commercieel karakter hebben, zonder nadrukkelijke
              schriftelijke voorafgaande toestemming van FTRPRF;
            </p>
            <p>
              h) een onredelijk of disproportioneel beslag op de infrastructuur
              van het Platform leggen of de functionaliteiten van de Dienst
              belemmeren;
            </p>
            <p>
              i) naar de mening van FTRPRF in strijd zijn met de goede zeden of
              openbare orde
            </p>
            <p>
              j) in strijd zijn met deze Gebruiksvoorwaarden, het Privacybeleid
              of enige geldende wet- en/of regelgeving;
            </p>
            <p>
              k) inbreuk maken op de rechten van FTRPRF en/of derden, waaronder
              begrepen maar niet beperkt tot IE-rechten en met betrekking tot de
              bescherming van privacy;
            </p>
            <p>l) op enigerlei wijze anderszins onrechtmatig zijn;</p>
            <p>
              m) de belangen en goede naam van FTRPRF of van derden kunnen
              schaden; of
            </p>
            <p>
              n) gebruik te maken van een vreemde identiteit en/of vreemde
              gegevens bij het aanmaken van een Account. Hieronder valt eveneens
              de aanmelding via een computer die fungeert als open proxy.
            </p>
            <p>
              4.4 FTRPRF behoudt zich het recht voor om de Dienst op elk moment
              te wijzigen, uit te breiden, te beperken of tijdelijk stop te
              zetten. Het gebruik van dit recht vereist geen voorafgaande
              kennisgeving aan de Gebruiker en geeft evenmin aanleiding tot een
              schadevergoeding.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <u>Artikel 5 Gebruiksrecht</u>
              </strong>
            </p>
            <p>
              5.1 Iedere Gebruiker krijgt een Gebruiksrecht op het Platform. De
              werkelijke omvang van het Gebruiksrecht verschilt naargelang de
              hoedanigheid van de Gebruiker. Ieder gebruik van het Platform
              gebeurt steeds op eigen risico van de Gebruiker.
            </p>
            <p>
              <strong>
                <u>Artikel 6 Beschikbaarheid</u>
              </strong>
            </p>
            <p>
              6.1 FTRPRF is te allen tijde gerechtigd om, zonder voorafgaande
              bekendmaking en zonder daarmee schadeplichtig te worden jegens de
              Gebruiker (onderdelen van) de Dienst, het Platform en/of de
              Content (tijdelijk) buiten gebruik te stellen en/of het gebruik
              ervan te beperken indien zulks in haar opvatting noodzakelijk is,
              bijvoorbeeld in het kader van het redelijkerwijs benodigde
              onderhoud van het Platform.
            </p>
            <p>
              6.2 In aanvulling op de andere (rechts)middelen die FTRPRF ten
              dienste staan, is FTRPRF te allen tijde, zonder opgave van redenen
              en zonder voorafgaande uitleg gerechtigd om de activiteiten van de
              Gebruiker in verband met de Dienst (tijdelijk) te beperken, op te
              schorten of buiten gebruik te stellen, de Account tijdelijk of
              blijvend te verwijderen, een waarschuwing te doen uitgaan, de
              Dienst te beëindigen en te weigeren om de Dienst te verlenen, in
              het bijzonder - maar niet daartoe beperkt - indien:
              <br />
              (i) de Gebruiker handelt in strijd met deze Gebruiksvoorwaarden;
              <br />
              (ii) FTPRPRF van mening is dat handelingen van de Gebruiker FTRPRF
              of anderen schade kunnen toebrengen.
            </p>
            <p>
              6.3 FTRPRF garandeert niet dat het Platform en de Dienst te allen
              tijde en zonder onderbrekingen of storingen toegankelijk zijn.
              Storingen in de Dienst kunnen mede, doch niet uitsluitend,
              optreden als gevolg van storingen in de internet- of
              telefonieverbinding of door virussen of fouten/gebreken. FTRPRF is
              jegens de Gebruiker op geen enkele wijze aansprakelijk of
              schadeplichtig voor enige schade die voortvloeit uit of het gevolg
              is van het (tijdelijk) onbeschikbaar zijn of (tussentijds)
              uitvallen van de Dienst.
            </p>
            <p>
              6.4 FTRPRF is niet verantwoordelijk voor de aanschaf en/of goede
              werking van de infrastructuur en deugdelijke telecomfaciliteiten
              (waaronder internetverbinding) die nodig zijn om gebruik te kunnen
              maken van de Dienst.
            </p>
            <p>
              <strong>
                <u>Artikel 7 IE-rechten</u>
              </strong>
            </p>
            <p>
              7.1 De IE-rechten met betrekking tot de Dienst en het Platform
              alsmede de via het Platform openbaar gemaakte Content, berusten
              bij FTRPRF. Niets in deze Gebruiksvoorwaarden is bedoeld om enige
              IE-rechten aan de Gebruiker over te dragen.
            </p>
            <p>
              7.2 Onder de voorwaarden zoals gesteld in deze Gebruiksvoorwaarden
              en voor de duur van de Overeenkomst, verleent FTRPRF aan de
              Gebruiker een beperkt, persoonlijk, herroepelijk, niet-exclusief,
              niet-overdraagbaar recht om van het Platform en de Content gebruik
              te maken en deze te bekijken op de wijze en in het format zoals
              dat via het Platform ter beschikking wordt gesteld.
            </p>
            <p>
              7.3 Het is niet toegestaan om kennisgevingen of vermeldingen met
              betrekking tot IE-rechten te verwijderen, onleesbaar te maken, te
              verbergen of te wijzigen.
            </p>
            <p>
              7.4 De Gebruiker zal geen handelingen verrichten die inbreuk
              kunnen maken op de IE-rechten van FTRPRF zoals het registeren van
              domeinnamen, merken of Google Adwords of het opvragen of
              hergebruiken van substantiële delen van het Platform of de Content
              althans het herhaald en systematisch opvragen of hergebruiken van
              niet-substantiële delen van het Platform of de Content zoals
              bedoeld in de toepasselijke wetgeving inzake gegevensbescherming.
            </p>
            <p>
              Niets uit de Content mag gekopieerd of herwerkt worden zonder
              uitdrukkelijke schriftelijke toestemming van FTRPRF.
            </p>
            <p>
              <strong>
                <u>Artikel 8 Garanties</u>
              </strong>
            </p>
            <p>
              8.1 FTRPRF garandeert dat zij de Dienst met redelijke zorg zal
              verlenen.
            </p>
            <p>
              8.2 De Gebruiker aanvaardt dat de Dienst alleen de
              functionaliteiten, Content en overige eigenschappen bevat zoals de
              Gebruiker die aantreft op het moment van gebruik (op &quot;as
              is&quot; basis) en in functie van de beschikbaarheden (op een “as
              available” basis). FTRPRF geeft geen garanties, toezeggingen en
              vrijwaringen ten aanzien van de kwaliteit, veiligheid,
              rechtmatigheid, volledigheid, integriteit en juistheid van de
              Content en de Dienst, tenzij anders is bepaald in deze
              Gebruiksvoorwaarden. FTRPRF is dan ook niet verplicht om bepaalde
              Content op verzoek toe te voegen aan het Platform.
            </p>
            <p>
              <strong>
                <u>Artikel 9 Aansprakelijkheid</u>
              </strong>
            </p>
            <p>
              9.1 FTRPRF is aansprakelijk om redelijke zorg te besteden aan het
              onderhoud van het Platform.
            </p>
            <p>
              9.2 FTRPRF aanvaardt geen enkele aansprakelijkheid voor schade ten
              gevolge van het verlenen van de Dienst dan wel uit onrechtmatige
              daad of anderszins, anders dan in dit artikel bepaald.
              <br />
              <br />
              9.3 FTRPRF is in ieder geval nooit aansprakelijk voor indirecte
              schade en gevolgschade, waaronder mede begrepen vermogensschade,
              gederfde omzet en winst, schade door verwisseling, verlies of
              beschadiging van elektronische gegevens en/of door vertraging in
              de transport van het dataverkeer en immateriële schade.
            </p>
            <p>
              9.4 FTRPRF is niet aansprakelijk voor schade die voortvloeit uit
              enige ongeoorloofde toegang tot of gebruik van de Dienst door
              derden.
            </p>
            <p>
              9.5 De in dit artikel opgenomen beperking van aansprakelijkheid is
              niet van toepassing in geval van fraude of bedrog in hoofde van
              FTRPRF.
            </p>
            <p>
              9.6 Voorwaarde voor het ontstaan van enig recht op
              schadevergoeding is steeds dat de Gebruiker, op straffe van
              verval, de schade zo spoedig mogelijk, en in ieder geval binnen de
              twee (2) weken na het ontstaan daarvan, schriftelijk bij FTRPRF
              meldt.
              <br />
              <br />
              9.7 Indien de Gebruiker door een hem toerekenbare contractuele of
              buitencontractuele tekortkoming de aansprakelijkheid van FTRPRF in
              het gedrang brengt en/of schade, verlies en kosten (inclusief
              kosten voor juridische bijstand) in hoofde van FTRPRF veroorzaakt,
              moet de Gebruiker FTRPRF volledig vrijwaren voor alle opgelopen
              schade, verlies en kosten.
            </p>
            <p>
              9.8 De aansprakelijkheid van FTRPRF is in ieder geval beperkt tot
              een bedrag van 100 EUR.
            </p>
            <p>
              <strong>
                <u>Artikel 10 Overmacht</u>
              </strong>
            </p>
            <p>
              10.1 FTRPRF is niet verantwoordelijk voor het niet leveren van de
              Dienst wanneer dit het gevolg is van onvoorziene omstandigheden of
              situaties waarover FTRPRF redelijkerwijs geen controle heeft,
              inclusief maar niet beperkt tot natuurverschijnselen, oorlog,
              terrorisme, rellen, embargo&apos;s, handelingen van burgerlijke of
              militaire autoriteiten, brand, overstromingen, ongelukken,
              stakingen, bezettingen, of tekorten aan transport, faciliteiten,
              benzine, energie, arbeidskracht of materialen.
            </p>
            <p>
              10.2 In geval van overmacht, worden de verbintenissen van FTRPRF
              opgeschort gedurende de duur van de overmachtssituatie.
            </p>
            <p>
              <strong>
                <u>Artikel 11 Beëindiging van het Gebruiksrecht</u>
              </strong>
            </p>
            <p>
              11.1 Het Gebruiksrecht wordt toegekend voor een bepaalde duur. In
              geval van een Overeenkomst met School, wordt deze duurtijd bepaald
              door de onderliggende verhoudingen tussen FTRPRF en de School en
              de School en de Gebruiker afzonderlijk. Indien in dergelijk geval
              de School de Account verwijdert, zal het Gebruiksrecht en toegang
              tot het Platform automatisch een einde nemen.
            </p>
            <p>
              11.2 De Gebruiker kan zijn/haar Gebruiksrecht op elk moment
              beëindigen. Na beëindiging van het Gebruiksrecht, om welke reden
              dan ook, vervalt per direct het recht om de Dienst te gebruiken en
              wordt de toegang tot de Dienst voor de Gebruiker direct ontzegd.
            </p>
            <p>
              11.3 FTRPRF verwijdert bij beëindiging van het Gebruiksrecht, om
              welke reden dan ook, onmiddellijk het Account.
            </p>
            <p>
              <strong>
                <u>Artikel 12 Varia</u>
              </strong>
            </p>
            <p>
              12.1 Indien enige bepaling uit deze Gebruiksvoorwaarden nietig of
              vernietigbaar blijkt te zijn dan wel om enige andere dan geheel of
              gedeeltelijk ongeldig wordt of is, blijven de overige bepalingen
              van de Gebruiksvoorwaarden onverminderd van kracht. FTRPRF zal het
              ongeldige beding vervangen door een beding dat wel geldig is en
              waarvan de rechtsgevolgen, gelet op de inhoud en de strekking van
              deze Gebruiksvoorwaarden, zoveel mogelijk overeenstemt met die van
              het ongeldige beding.
            </p>
            <p>
              12.2 FTRPRF mag rechten en verplichtingen die uit de
              Gebruiksvoorwaarden voortvloeien, overdragen aan derden en zal de
              Gebruiker daarvan op de hoogte stellen. Indien de Gebruiker deze
              overdracht van verplichtingen aan een derde niet acceptabel vindt,
              kan de Gebruiker het gebruik van het Platform staken en zijn
              Account beëindigen.
            </p>
            <p>
              12.3 Op deze Gebruiksvoorwaarden en alle geschillen die daaruit
              voortvloeien en/of daarmee verband houden is Belgisch recht van
              toepassing. De rechtbanken van Gent, afdeling Gent zijn bevoegd.
            </p>
            <p>
              12.4 Deze Gebruiksvoorwaarden werden gepubliceerd op 03-09-2019
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
