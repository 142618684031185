import useFormatMessage from 'hooks/useFormatMessage';
import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import viewModes from 'utils/constants/viewModes';
import { lessonTypes } from 'utils/constants/lessonTypes';

export function SelectLesson({
  chapterItem,
  control,
  errors,
  findLessons,
  setIsExam,
  setValue,
}) {
  const t = useFormatMessage();

  return (
    <>
      <Label className="mt-2">{t('calendar.modal.lesson')}</Label>
      <Controller
        control={control}
        name="studioId"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            hasErrors={Boolean(errors.studioId)}
            onBlur={onBlur}
            onChange={(version) => {
              if (version.isExam) {
                setValue('viewMode', viewModes.CLASSICAL);
              }
              setIsExam(version.isExam);
              onChange(version);
            }}
            options={findLessons(chapterItem).map((lesson) => ({
              isExam: lesson.type === lessonTypes.EXAM,
              label: lesson.title,
              value: lesson.studioId,
            }))}
            placeholder={t('calendar.modal.lesson')}
            returnMethod="object"
            value={value ?? undefined}
          />
        )}
        rules={{
          required: {
            message: t('global.required-field'),
            value: true,
          },
        }}
        type="select"
      />
      {errors.studioId && (
        <Label className="text-xs text-red-600">
          {errors.studioId.message}
        </Label>
      )}
    </>
  );
}
