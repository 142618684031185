import { gql } from 'graphql-request';

export const SCHOOLADMIN_PROGRAMS_BY_USER_ID = gql`
  query schooladminProgramsByUserId($userId: String!) {
    schooladminProgramsByUserId(userId: $userId) {
      id
      name
      type
    }
  }
`;
