import { gql } from 'graphql-request';

export const TEACHER_UPDATE_CALENDAR_ITEM = gql`
  mutation teacherUpdateCalendarItem($id: Int!, $input: CalendarItemInput) {
    teacherUpdateCalendarItem(id: $id, input: $input) {
      id
      chapterItem {
        id
        name
        chapterId
      }
      classGroups {
        id
        name
        archived
      }
      closedAfterEndTime
      endTime
      hidden
      startTime
      teamsCalendarItem {
        addToCalendarAction
        assignmentDescription
        assignmentTitle
        resourceDisplayName
      }
      viewMode
    }
  }
`;
