import { useContext, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { ResetPasswordDialog } from 'components/ResetPasswordDialog';

import useUserClassgroupManagement from 'hooks/api/plannerService/CRUD/useUserClassgroupManagement';
import useSchooladminAddProgramsToTeacher from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminAddProgramsToTeacher';
import useSchooladminDeleteTeacherFromProgram from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminDeleteTeacherFromProgram';
import { useSchooladminResetPassword } from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminResetPassword';
import { useSchooladminUpdateStudent } from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminUpdateStudent';
import useSchooladminUpdateTeacher from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminUpdateTeacher';
import useSchooladminClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroups';
import useSchooladminClassGroupsByUserId from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroupsByUserId';
import useSchooladminPrograms from 'hooks/api/plannerService/schooladmin/queries/useSchooladminPrograms';
import useSchooladminProgramsByUserId from 'hooks/api/plannerService/schooladmin/queries/useSchooladminProgramsByUserId';
import useSchooladminUserById from 'hooks/api/plannerService/schooladmin/queries/useSchooladminUserById';
import useFormatMessage from 'hooks/useFormatMessage';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import showDefaultErrorNotification from 'utils/showDefaultError';

import useSchoolAdminUsersIdName from 'hooks/api/plannerService/schooladmin/queries/useSchooladminUsersIdName';
import ftrprfUserRoles from 'utils/constants/ftrprfUserRoles';
import determineRoles from 'utils/determineUserRoles';
import AddClassgroupsDialog from './partials/AddClassgroupsModal';
import { formatSearchParamsForUserManagement } from './partials/formatSearchParamsForUserManagement';
import LinkProgramsDialog from './partials/LinkProgramsDialog';
import { SchooladminUserProfileSkeleton } from './partials/SchooladminUserProfileSkeleton';
import UserProfileSchooladmin from './UserProfileSchooladmin';
import { RetrieveClassGroupData } from './partials/RetrieveClassGroupData';

const MAX_USERS = 9999;
const DEFAULT_PAGE = 0;
export default function UserProfileSchooladminContainer() {
  const canDelete = false;

  const [searchParams] = useSearchParams();
  const formattedSearchParams =
    formatSearchParamsForUserManagement(searchParams);
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const { classId, className, userId } = useParams();
  const { data, isLoading, refetch } = useSchooladminUserById({
    id: userId,
  });
  const [isLinkProgramsModalOpen, setIsLinkProgramsModalOpen] = useState(false);
  const [isAddClassgroupsModalOpen, setIsAddClassgroupsModalOpen] =
    useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [allClassGroups, setAllClassGroups] = useState([]);
  const { isLoading: isResetting, mutateAsync: resetPassword } =
    useSchooladminResetPassword();

  const MAX_ITEMS = 50;
  const MAX_CLASSGROUP_ITEMS = 50;

  const { data: programsData, refetch: refetchPrograms } =
    useSchooladminPrograms({
      filter: [],
      page: 0,
      size: MAX_ITEMS,
      sort: { name: sortOperation.ASC },
    });

  const { data: userClassGroupsData, refetch: refetchUserClassgroups } =
    useSchooladminClassGroupsByUserId({
      userId,
    });

  const { data: classgroupsData, isLoading: isLoadingClassgroups } =
    useSchooladminClassGroups({
      page: DEFAULT_PAGE,
      size: MAX_CLASSGROUP_ITEMS,
      sort: { name: sortOperation.ASC },
    });

  const { data: userProgramsData, refetch: refetchUserPrograms } =
    useSchooladminProgramsByUserId({
      userId,
    });
  const user = data;
  if (user) {
    user.fullName = `${user.firstName} ${user.lastName}`;
  }

  const isNoSchoolAdminOrAdmin =
    !user?.roles.includes(ftrprfUserRoles.SCHOOLADMIN) &&
    !user?.roles.includes(ftrprfUserRoles.ADMIN);

  const userClassGroups = userClassGroupsData;
  const userPrograms = userProgramsData;
  const programs = programsData?.content;

  const uniqueProgramsToLink = useMemo(() => {
    const itemsIds = userPrograms?.map((item) => item.id);

    return programs?.filter((i) => !itemsIds?.includes(i.id)) || [];
  }, [userPrograms, programs]);

  const uniqueClassGroupsToLink = useMemo(() => {
    const itemsIds = userClassGroups?.map((item) => item.id);

    return (
      allClassGroups
        ?.filter((i) => !itemsIds?.includes(i.id))
        ?.filter((item) => !item.archived) || []
    );
  }, [allClassGroups, userClassGroups]);

  const { mutateAsync: updateTeacherInfo } = useSchooladminUpdateTeacher();
  const { mutateAsync: updateStudentInfo } = useSchooladminUpdateStudent();

  const {
    isLoading: isLoadingLinkProgramToTeacher,
    mutateAsync: linkProgramsToTeacher,
  } = useSchooladminAddProgramsToTeacher();

  const {
    isLoading: isLoadingUnlinkTeacherFromProgram,
    mutateAsync: unlinkTeacherFromProgram,
  } = useSchooladminDeleteTeacherFromProgram();

  const { add: useAddUserToClassGroup, remove: useRemoveUserFromClassGroup } =
    useUserClassgroupManagement();

  const {
    isLoading: isLoadingAddClassgroups,
    mutateAsync: addUserToClassGroupHook,
  } = useAddUserToClassGroup();
  const {
    isLoading: isLoadingRemoveClassgroup,
    mutateAsync: removeUserFromClassGroupHook,
  } = useRemoveUserFromClassGroup();

  const refetchUserProfile = async () => {
    await refetchPrograms();
    await refetchUserPrograms();
    await refetchUserClassgroups();
    await refetch();
  };

  const addProgramsToTeacher = async (data, dismiss) => {
    await linkProgramsToTeacher({ programIds: data, userId });
    await refetchUserProfile();

    if (dismiss) dismiss();
  };
  const removeTeacherFromProgram = async (programId, dismiss) => {
    await unlinkTeacherFromProgram({ programId, userId });
    await refetchUserProfile();

    if (dismiss) dismiss();
  };

  const addClassToTeacher = async (ids, dismiss) => {
    await ids.forEach((id) => {
      addUserToClassGroupHook({ classGroupId: id, userId });
    });
    await refetchUserProfile();

    if (dismiss) dismiss();
  };
  const removeTeacherFromClass = async (id, dismiss) => {
    await removeUserFromClassGroupHook({ classGroupId: id, userId });
    await refetchUserProfile();

    if (dismiss) dismiss();
  };
  const isStudent = useMemo(
    () => user?.roles?.includes(ftrprfUserRoles.SCHOOLSTUDENT),
    [user],
  );

  const updateUser = (data) => {
    const input = data;
    const { id } = user;
    const updateUserCall = isStudent ? updateStudentInfo : updateTeacherInfo;
    updateUserCall({ id, input })
      .then(() => {
        void refetch();
      })
      .catch((err) => {
        showDefaultErrorNotification(
          err.message,
          t('schooladmin.error', null),
          addNotification,
        );
      });
  };

  const { data: usersData } = useSchoolAdminUsersIdName({
    filter: [
      {
        key: 'enabled',
        value: formattedSearchParams.enabled,
        operation: filterOperation.EQUAL,
      },
      formattedSearchParams.roles.length > 0 && {
        key: 'roles',
        value: formattedSearchParams.roles,
        operation: filterOperation.IN,
      },
      formattedSearchParams.firstname && {
        key: 'firstname',
        value: formattedSearchParams.firstname,
        operation: filterOperation.LIKE,
      },
      formattedSearchParams.lastname && {
        key: 'lastname',
        value: formattedSearchParams.lastname,
        operation: filterOperation.LIKE,
      },
    ].filter((i) => Boolean(i.value)),
    page: 0,
    size: MAX_USERS,
    sort: { firstname: sortOperation.ASC },
  });

  const mappedUsers = usersData?.content.map((oldUser) => {
    const newUser = { ...determineRoles(oldUser) };
    newUser.fullName = `${oldUser.firstName} ${oldUser.lastName}`;

    return newUser;
  });

  if (!user) {
    return null;
  }

  return (
    <>
      {Boolean(classgroupsData?.pages)
        ? Array.from({ length: classgroupsData.pages }).map((_, index) => {
            const page = index;

            return (
              <RetrieveClassGroupData
                key={page}
                allClassGroups={allClassGroups}
                maxItems={MAX_CLASSGROUP_ITEMS}
                page={page}
                setAllClassGroups={setAllClassGroups}
              />
            );
          })
        : null}
      {isResetPasswordDialogOpen && (
        <ResetPasswordDialog
          isOpen={isResetPasswordDialogOpen}
          isResetting={isResetting}
          onDismiss={() => setIsResetPasswordDialogOpen(false)}
          resetPassword={(newPassword) => {
            void resetPassword({
              id: user.id,
              newPassword,
            });
          }}
        />
      )}
      {/* {canDelete && (
        created new ticket to replace this with TR-1348
        <RemoveUserDialog
          content={t('student-overview.are-you-sure')}
          isOpen={!!removeModalIsOpened}
          onConfirm={() => removeUser(userId)}
          onDismiss={closeRemoveModal}
        />
      )} */}
      {uniqueProgramsToLink?.length !== 0 && (
        <LinkProgramsDialog
          isLoading={isLoadingLinkProgramToTeacher}
          isOpen={isLinkProgramsModalOpen}
          onConfirm={addProgramsToTeacher}
          onDismiss={() => setIsLinkProgramsModalOpen(false)}
          programs={uniqueProgramsToLink}
        />
      )}
      {uniqueClassGroupsToLink?.length !== 0 && (
        <AddClassgroupsDialog
          classGroups={uniqueClassGroupsToLink}
          isLoading={isLoadingAddClassgroups || isLoadingClassgroups}
          isOpen={isAddClassgroupsModalOpen}
          onConfirm={addClassToTeacher}
          onDismiss={() => setIsAddClassgroupsModalOpen(false)}
        />
      )}

      <SchooladminUserProfileSkeleton isLoading={isLoading}>
        <UserProfileSchooladmin
          canDelete={canDelete}
          canResetPassword={isNoSchoolAdminOrAdmin}
          classId={classId}
          className={className}
          formattedSearchParams={formattedSearchParams}
          hasLinkedClass={uniqueClassGroupsToLink?.length !== 0}
          hasLinkedProgram={uniqueProgramsToLink?.length !== 0}
          isLoading={
            isLoading ||
            isLoadingUnlinkTeacherFromProgram ||
            isLoadingRemoveClassgroup
          }
          // isSaving={isSaving}
          openAddClassgroupsModal={() => setIsAddClassgroupsModalOpen(true)}
          openLinkProgramsModal={() => setIsLinkProgramsModalOpen(true)}
          // openRemoveModal={openRemoveModal}
          openResetModal={() => setIsResetPasswordDialogOpen(true)}
          removeClassgroup={removeTeacherFromClass}
          // removeUser={removeUser}
          resetPassword={resetPassword}
          unlinkProgramsModal={removeTeacherFromProgram}
          updateUser={updateUser}
          user={user}
          userClassGroups={userClassGroups ?? []}
          userPrograms={userPrograms ?? []}
          users={mappedUsers}
        />
      </SchooladminUserProfileSkeleton>
    </>
  );
}
