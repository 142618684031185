import SlideViewerBaseSlide from './SlideViewerBaseSlide';
import { processor } from './processor';

export default function SlideViewerTextSlide({
  classNames = '',
  hasBackground = false,
  hasFixedDimensions = false,
  isCk5Thumbnail = false,
  isFullSlideViewer = true,
  value,
}) {
  return (
    <SlideViewerBaseSlide
      classNames={classNames}
      hasBackground={hasBackground}
      hasFixedDimensions={hasFixedDimensions}
      isCk5Thumbnail={isCk5Thumbnail}
      isFullSlideViewer={isFullSlideViewer}
      processor={processor}
      value={value}
    />
  );
}
