import { gql } from 'graphql-request';

export const OPEN_LESSON_EXERCISE = gql`
  query openLessonExercise(
    $studioId: Int!
    $exerciseVersionId: Int!
    $restart: Boolean
  ) {
    openLessonExercise(
      studioId: $studioId
      exerciseVersionId: $exerciseVersionId
      restart: $restart
    ) {
      exerciseId
      exerciseTitle
      exerciseLanguage
      versionId
      versionName
      versionTestPlanId
      content
    }
  }
`;
