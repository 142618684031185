import { DisclosurePlusMinus } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';

export function GroupFilter({
  className,
  emptyText,
  items,
  legend,
  onClick,
  open = true,
  test,
}) {
  const t = useFormatMessage();

  return (
    <>
      {Boolean(!items.length) && (
        <p className="font-normal text-lg p-4">{t(emptyText)}</p>
      )}
      {Boolean(items.length) && (
        <fieldset data-test={test}>
          <DisclosurePlusMinus
            classNameContainer={c(className, 'p-2')}
            isOpen={open}
            title={
              <legend className="text-lg px-2 font-medium text-gray-900">
                {t(legend)}
              </legend>
            }
          >
            <ul className="mt-4 px-2 border-t border-b border-gray-200 divide-y divide-gray-200">
              {items.map((item) => (
                <li key={item.id} className="relative flex items-start py-4">
                  <div className="mr-3 flex items-center h-5">
                    <input
                      className="focus:ring-accent-500 h-4 w-4 text-accent-600 border-gray-300 rounded"
                      defaultChecked
                      id={`value-${item.id}`}
                      name={`value-${item.name}`}
                      onClick={() => onClick(item)}
                      type="checkbox"
                    />
                  </div>
                  <div className="min-w-0 flex-1 text-sm">
                    <label
                      className="font-medium text-gray-700 select-none"
                      htmlFor={`value-${item.id}`}
                    >
                      {item.name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </DisclosurePlusMinus>
        </fieldset>
      )}
    </>
  );
}
