import { gql } from 'graphql-request';

export const ADMIN_UPDATE_CHAPTER = gql`
  mutation adminUpdateChapter($id: Int!, $input: ChapterInput!, $file: Upload) {
    adminUpdateChapter(id: $id, input: $input, file: $file) {
      id
      name
    }
  }
`;
