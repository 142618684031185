import { useOutletContext } from 'react-router-dom';
import { Loader } from './Loader';
import { Scratch } from './Scratch/Scratch';

export default function ScratchExercisesStudent() {
  const [{ calendarItem, data, userId }] = useOutletContext();

  if (!data || !calendarItem) {
    return <Loader />;
  }

  return (
    <Scratch
      exercises={data?.scratchExerciseScores?.exercises}
      lessonSessionId={calendarItem?.sessionId}
      noResultsKey="results_overview.calendar_item.no_results_scratch_student"
      studentId={userId}
    />
  );
}
