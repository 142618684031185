import { Toggle } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function SmartschoolToggles({ setValue, watch }) {
  const t = useFormatMessage();

  const [
    importMembershipOfStudentsToClasses,
    importMembershipOfTeachersToClasses,
    importStudentsValue,
    importTeachersValue,
  ] = watch([
    'importMembershipOfStudentsToClasses',
    'importMembershipOfTeachersToClasses',
    'importStudents',
    'importTeachers',
  ]);

  return (
    <div className="space-y-2 mt-2">
      <Toggle
        className="justify-between"
        label={t('aou-org.import.students')}
        onChange={(value) => setValue('importStudents', value)}
        value={importStudentsValue}
        visibleLabel
      />

      <Toggle
        className="justify-between"
        label={t('aou-org.import.teachers')}
        onChange={(value) => setValue('importTeachers', value)}
        value={importTeachersValue}
        visibleLabel
      />

      <Toggle
        className="justify-between"
        label={t('aou-org.import.membershipOfStudentsToClasses')}
        onChange={(value) =>
          setValue('importMembershipOfStudentsToClasses', value)
        }
        value={importMembershipOfStudentsToClasses}
        visibleLabel
      />

      <Toggle
        className="justify-between"
        label={t('aou-org.import.membershipOfTeachersToClasses')}
        onChange={(value) =>
          setValue('importMembershipOfTeachersToClasses', value)
        }
        value={importMembershipOfTeachersToClasses}
        visibleLabel
      />
    </div>
  );
}
