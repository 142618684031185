import { Link } from 'react-router-dom';
import { linkFocusStyles } from '../../utils/constants/linkFocusStyles';
import c from '../../utils/c';

import { Button } from './Button';

export function MenuButton({
  children = null,
  className = '',
  disabled = false,
  iconBefore: IconBefore = null,
  loading = false,
  onClick = () => {},
  preventClose = false,
  test = '',
}) {
  return (
    <Button
      className={c(
        linkFocusStyles,
        'w-full py-1 px-4 text-left rounded-lg hover:bg-gray-200',
        className,
      )}
      data-test={test}
      disabled={disabled}
      iconBefore={IconBefore}
      loading={loading}
      onClick={onClick}
      preventClose={preventClose}
    >
      {children}
    </Button>
  );
}

export function MenuLink({
  children = null,
  className = '',
  iconBefore: IconBefore = null,
  test = '',
  to = null,
}) {
  return (
    <Link
      className={c(
        linkFocusStyles,
        className,
        'w-full py-1 px-4 text-left rounded-lg hover:bg-gray-200 cursor-pointer flex items-center box-border',
      )}
      data-test={test}
      to={to}
    >
      {IconBefore && (
        <span className="mr-2">
          <IconBefore className="w-3 h-3" />
        </span>
      )}
      {children}
    </Link>
  );
}
