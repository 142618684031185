/* eslint-disable camelcase */
import CODECOSMOS_img from 'assets/idps/CODECOSMOS.png';
import FTRPRF_img from 'assets/idps/FTRPRF.svg';
import ILEARN_img from 'assets/idps/ILEARN.png';
import SMARTSCHOOL_img from 'assets/idps/SMARTSCHOOLS.png';
import TEAMS_img from 'assets/idps/TEAMS.svg';

export const FTRPRF = 'FTRPRF';
export const TEAMS = 'TEAMS';
export const ILEARN = 'ILEARN';
export const SMARTSCHOOL = 'SMARTSCHOOL';
export const CODECOSMOS = 'CODECOSMOS';
export const CODEFEVER = 'CODEFEVER';

export const IDPS = {
  FTRPRF,
  TEAMS,
  ILEARN,
  SMARTSCHOOL,
  CODECOSMOS,
  CODEFEVER,
};

export const IDP_IMAGES = {
  FTRPRF: FTRPRF_img,
  TEAMS: TEAMS_img,
  ILEARN: ILEARN_img,
  SMARTSCHOOL: SMARTSCHOOL_img,
  CODECOSMOS: CODECOSMOS_img,
};
