import { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Input,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useTeacherCreateActivity from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateActivity';
import useFormatMessage from 'hooks/useFormatMessage';

import { Member } from './Groups/Member';

export function GroupRandomMemberDivider({
  calendarItemId,
  members,
  onDismiss,
  submit,
}) {
  const t = useFormatMessage();
  const [activityId, setActivityId] = useState();
  const { mutateAsync: createActivity } = useTeacherCreateActivity();

  const [amountOfGroups, setAmountOfGroups] = useState(
    Math.ceil(members.length / 4),
  );
  const [numberOfSelectedStudents, setNumberOfSelectedStudents] = useState(
    members.length,
  );
  const selectAllStudents = () => {
    members.forEach((member) => {
      // eslint-disable-next-line no-param-reassign
      member.include = true;
    });
    setNumberOfSelectedStudents(members.length);
  };
  const unselectAllStudents = () => {
    members.forEach((member) => {
      // eslint-disable-next-line no-param-reassign
      member.include = false;
    });
    setNumberOfSelectedStudents(0);
  };

  useEffect(() => {
    const createActivityForItem = async (calendarItemId) => {
      const data = await createActivity(calendarItemId);
      if (data?.teacherCreateActivity?.message) {
        setActivityId(data.teacherCreateActivity.message.split('"')[1]);
      }
    };
    void createActivityForItem(calendarItemId);
  }, [calendarItemId, createActivity]);

  return (
    <>
      <DialogContent className="m-4">
        <h4 className="pb-2 text-xl font-bold">
          <label htmlFor="groupsCompositionRandom_amount">
            {t('groups.compose.divider.amount')}
          </label>
        </h4>
        <Input
          className="mt-2"
          defaultValue={amountOfGroups}
          id="groupsCompositionRandom_amount"
          max={members.length}
          min="1"
          onChange={(e) => {
            setAmountOfGroups(Number(e.target.value));
          }}
          type="number"
        />
        <fieldset className="mt-4">
          <legend className="text-xl font-bold leading-none mb-3 pt-2 hidden sm:block w-full">
            <h4 className="flex justify-between w-full">
              {t('groups.members')}
              <span>
                <button
                  className="underline font-normal text-base cursor-pointer text-accent-700 mr-4"
                  onClick={selectAllStudents}
                  type="button"
                >
                  {t('global.select.all')}
                </button>
                <button
                  className="underline font-normal text-base cursor-pointer text-accent-700"
                  onClick={unselectAllStudents}
                  type="button"
                >
                  {t('global.select.none')}
                </button>
              </span>
            </h4>
          </legend>
          <div className="grid grid-cols-3 gap-4">
            {members.map((member) => {
              const onChange = () => {
                // eslint-disable-next-line no-param-reassign
                member.include = !member.include;

                if (!member.include) {
                  setNumberOfSelectedStudents(numberOfSelectedStudents - 1);
                }

                if (member.include) {
                  setNumberOfSelectedStudents(numberOfSelectedStudents + 1);
                }
              };

              return (
                <Member
                  key={`member${member.name}`}
                  member={member}
                  onChange={onChange}
                />
              );
            })}
          </div>
        </fieldset>
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          disabled={
            !numberOfSelectedStudents ||
            amountOfGroups > numberOfSelectedStudents
          }
          onClick={() => submit(amountOfGroups, activityId)}
          send
          type="submit"
        >
          {t('groups.compose.divider.create')}
        </ActionStyledAsButton>
      </DialogActions>
    </>
  );
}
