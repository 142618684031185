import { gql } from 'graphql-request';

export const SCHOOLADMIN_PROGRAM_BY_ID = gql`
  query schooladminProgramById($programId: Int!) {
    schooladminProgramById(programId: $programId) {
      id
      name
      type
      weight
      chapters {
        id
        name
        programId
        weight
        items {
          id
          name
          weight
          chapterId
          studioId
        }
      }
    }
  }
`;
