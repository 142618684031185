import { useState } from 'react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  EyeSlashIcon,
  FolderIcon,
  FolderOpenIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import isFunction from 'lodash-es/isFunction';

import c from '../utils/c';

import { Actions } from './Actions/Actions';
import { Badge } from './Badge';
import { dataTestIds } from '../utils/constants/dataTestIds';

export const MultiCollapsableState = {
  success: 'SUCCESS',
  partial: 'PARTIAL',
  failed: 'FAILED',
};

export function MultiCollapsable({
  actions,
  amount = null,
  boldText = '',
  child = 0,
  children,
  hasDropDown = false,
  id,
  image,
  isHidden = false,
  isOpen,
  mainText,
  setClicked,
  state = '',
  subText,
  tags = null,
  test,
}) {
  const [openState, setOpenState] = useState(
    isOpen === 'undefined' ? true : isOpen,
  );
  const indentation = `pl-${child * 4}`;

  const frontIcon = () => {
    if (image) {
      return (
        <img
          alt=""
          className="rounded w-6 h-6 m-2 min-w-[1.5rem]"
          src={image}
        />
      );
    }

    let textColor;
    switch (state) {
      case MultiCollapsableState.success:
        textColor = 'text-green-500';
        break;
      case MultiCollapsableState.failed:
        textColor = 'text-red-500';
        break;
      case MultiCollapsableState.partial:
        textColor = 'text-yellow-500';
        break;
      default:
        textColor = 'text-accent-500';
        break;
    }

    if (hasDropDown && openState) {
      return (
        <FolderOpenIcon className={`${textColor} w-6 h-6 m-2 min-w-[1.5rem]`} />
      );
    }

    if (hasDropDown && !openState) {
      return (
        <FolderIcon className={`${textColor} w-6 h-6 m-2 min-w-[1.5rem]`} />
      );
    }

    if (state === MultiCollapsableState.success) {
      return (
        <CheckCircleIcon
          className={`${textColor} w-6 h-6 m-2 min-w-[1.5rem]`}
        />
      );
    }

    if (state === MultiCollapsableState.partial) {
      return (
        <ExclamationCircleIcon
          className={`${textColor} w-6 h-6 m-2 min-w-[1.5rem]`}
        />
      );
    }

    if (state === MultiCollapsableState.failed) {
      return (
        <XCircleIcon className={`${textColor} w-6 h-6 m-2 min-w-[1.5rem]`} />
      );
    }

    return (
      <DocumentIcon className="text-accent-500 w-6 h-6 m-2 min-w-[1.5rem]" />
    );
  };

  return (
    <details
      className={c('flex flex-col w-full multicollapsable', indentation)}
      data-test={dataTestIds.component.multiCollapsable.self}
      open={openState}
    >
      <summary
        className="bg-gray-50 flex align-center w-full mx-auto mb-2 self-center p-2 rounded border"
        data-test={test}
        onClick={(e) => {
          // see: https://github.com/facebook/react/issues/15486#issuecomment-873516817
          e.preventDefault();
          setOpenState(!openState);
          if (hasDropDown && isFunction(setClicked))
            setClicked((prev) => (prev === id ? null : id));
        }}
      >
        <div className="flex align-center w-full">
          {frontIcon()}

          <div className="my-auto pl-2 w-full">
            <div
              className={c(
                'text-md align-center',
                tags && Boolean(tags.length) && 'flex justify-between',
              )}
            >
              <span>
                {mainText}{' '}
                {boldText && <span className="font-bold mr-4">{boldText}</span>}
                {isHidden && <EyeSlashIcon className="w-4 h-4 inline-block" />}
              </span>
              {tags && Boolean(tags.length) && (
                <span className="flex gap-1 pt-1 mr-1">
                  {tags.map((tag) => (
                    <Badge key={`title${tag.id}`} color={tag.color}>
                      {tag.name}
                    </Badge>
                  ))}
                </span>
              )}
            </div>
            {!!subText && <p className="text-xs text-gray-500">{subText}</p>}
          </div>
        </div>
        <div className="my-auto align-center flex justify-end items-center mr-2 ml-auto">
          {amount && <p className="text-sm mr-2 whitespace-nowrap">{amount}</p>}
          {actions?.length > 0 && <Actions items={actions} />}
          {hasDropDown &&
            (openState ? (
              <ChevronUpIcon className="w-3 h-3 ml-4 self-center" />
            ) : (
              <ChevronDownIcon className="w-3 h-3 ml-4 self-center" />
            ))}
        </div>
      </summary>
      {openState && children}
    </details>
  );
}
