import { useEffect, useState } from 'react';
import { TbMenu2 } from 'react-icons/tb';

import { Dropdown, Label } from '@ftrprf/tailwind-components';

import { getInitials } from 'utils/getInitials';
import useFormatMessage from '../../../../hooks/useFormatMessage';

export function MemberRow({
  index,
  member,
  options,
  provided,
  setPossibleOptions,
  showRoles,
}) {
  const t = useFormatMessage();
  const [selectedOption, setSelectedOption] = useState();
  const availableOptions = options.map((option) => option.role);

  useEffect(() => {
    setSelectedOption(member.roles.map((role) => role.role));
  }, [member.roles]);

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="flex justify-start items-center mt-2 flex-wrap md:flex-nowrap gap-2 xl:min-w-96"
    >
      <TbMenu2 className="flex-grow-0 h-full text-gray-400 w-8" size="1.5rem" />
      <span className="flex-grow-0 bg-gray-400 rounded-full w-10 h-10 flex justify-center items-center text-white mr-2">
        {getInitials(member.name)}
      </span>
      <span className="flex-grow-0 min-w-1/4 mr-2 max-w-1/2">
        {member.name}
      </span>
      <Label className="sr-only" htmlFor="memberRow_roles">
        {t('groups.compose.memberrow.roles')}
      </Label>
      {showRoles ? (
        <Dropdown
          className="flex-grow-0 md:ml-auto inline-block min-w-1/4 max-w-1/2"
          defaultValue={member.roles.map((role) => role.role)}
          inputId="memberRow_roles"
          isMulti
          onChange={(item) => {
            setSelectedOption(item);
            setPossibleOptions(item, index);
          }}
          options={availableOptions.map((item) => ({
            value: item,
            key: item,
            label: item,
          }))}
          value={selectedOption}
          wrapValue
        />
      ) : null}
    </div>
  );
}
