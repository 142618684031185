import { gql } from 'graphql-request';

export const SCHOOLADMIN_SEATS_BY_PROGRAM_ID = gql`
  query schooladminSeatsByProgramId(
    $programId: Int!
    $periodId: Int!
    $fullName: String
    $classIds: [Int!]
  ) {
    schooladminSeatsByProgramId(
      programId: $programId
      periodId: $periodId
      fullName: $fullName
      classIds: $classIds
    ) {
      user {
        id
        fullName
        avatar
        classGroups {
          id
          name
        }
      }
      calendarItem {
        chapterItem {
          id
          name
        }
        classGroups {
          id
          name
        }
        startTime
      }
      teacher {
        id
        fullName
      }
    }
  }
`;
