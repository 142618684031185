import c from '../../utils/c';

export function FormGroup({
  children,
  className = '',
  hasBorder = true,
  hasMarginBottom = true,
  hasPadding = true,
}) {
  return (
    <div
      className={c(
        className,
        'w-full',
        hasMarginBottom && 'mb-6',
        hasPadding && 'p-4',
        hasBorder && 'border border-gray-300 rounded',
      )}
    >
      {children}
    </div>
  );
}
