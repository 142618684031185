import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Controller, useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { CheckIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import usePasswordStrengthWords from 'hooks/passwordStrengthWords';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import { generatePassword } from 'utils/generatePassword';

export function ResetPasswordDialog({
  isOpen,
  isResetting,
  onDismiss: dismiss,
  resetPassword,
}) {
  const t = useFormatMessage();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  const [copied, setCopied] = useState(false);

  const watcherPassword = watch('password');

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(() => {
    resetPassword(watcherPassword);
    onDismiss();
  }, [onDismiss, resetPassword, watcherPassword]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('global.reset-password')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="resetPasswordDialog-password">
                {t('profile.new_password')}
              </Label>
              <p
                className="text-sm font-normal flex flex-col items-center md:flex-row mb-2 gap-x-4"
                id="descriptionPassword"
              >
                <span>{t('global.password-pattern')}</span>
                <ActionStyledAsButton
                  className="ml-auto"
                  onClick={() => {
                    setValue('password', generatePassword());
                    setCopied(false);
                  }}
                  secondary
                  test={
                    dataTestIds.modal.resetPassword.button.password.generate
                  }
                >
                  {t('password.dialog.generate-password')}
                </ActionStyledAsButton>
              </p>
              <Controller
                control={control}
                name="password"
                render={({ field: { onBlur, onChange, value } }) => (
                  <div className="flex w-full ">
                    <Input
                      aria-describedby="descriptionPassword"
                      autoComplete="new-password"
                      className="w-full"
                      data-test={dataTestIds.modal.resetPassword.input.password}
                      hasErrors={Boolean(errors.password)}
                      id="resetPasswordDialog-password"
                      onBlur={onBlur}
                      onChange={(data) => onChange(data) && setCopied(false)}
                      type="password"
                      value={value || ''}
                    />
                    <CopyToClipboard
                      onCopy={() => setCopied(true)}
                      text={watcherPassword}
                    >
                      <ActionStyledAsButton
                        className="ml-2"
                        success={copied}
                        test={
                          dataTestIds.modal.resetPassword.button.password.copy
                        }
                        variant="text"
                      >
                        {copied ? (
                          <CheckIcon className="h-4 w-4" />
                        ) : (
                          <ClipboardDocumentIcon className="h-4 w-4" />
                        )}
                      </ActionStyledAsButton>
                    </CopyToClipboard>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                  minLength: { value: 8, message: t('global.min-length-8') },
                  maxLength: {
                    value: 256,
                    message: t('global.max-length-255'),
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,256}$/,
                    message: t('global.password-pattern'),
                  },
                }}
                type="input"
              />
              {errors?.password && (
                <p className="mt-1 text-xs text-red-600">
                  {errors.password.message}
                </p>
              )}
              <div
                data-test={
                  dataTestIds.modal.resetPassword.text.password.strength
                }
              >
                <PasswordStrengthBar
                  className="mt-2"
                  password={watcherPassword || ''}
                  scoreWords={usePasswordStrengthWords()}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            disabled={isResetting}
            onClick={onDismiss}
            test={dataTestIds.modal.resetPassword.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={isResetting && !errors}
            loading={isResetting}
            test={dataTestIds.modal.resetPassword.button.submit}
            type="submit"
          >
            {t('global.reset-password.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
