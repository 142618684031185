export const removeDoubleEvents = (events) => {
  const tempEvents = [...events];
  const ids = {};
  const itemsToRemove = [];

  // find doubles
  events.forEach((event, index) => {
    const { id } = event;
    if (!ids[id]) {
      ids[id] = { index };
    } else {
      itemsToRemove.push({ removeItemId: index, addTitleToId: ids[id].index });
    }
  });

  // remove doubles from back to front to avoid the indexes changing due to prior removed items
  itemsToRemove
    .reverse()
    .forEach(({ addTitleToId, removeItemId: indexToRemove }) => {
      const removedItem = tempEvents.splice(indexToRemove, 1)[0];
      if (removedItem.id !== tempEvents[addTitleToId].id) {
        tempEvents[addTitleToId].classGroups.push(
          ...removedItem[0].classGroups,
        );
      }
    });

  return tempEvents;
};
