import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { dataTestIds } from 'utils/dataTestIds';

export default function DeactivateOrganizationDialog({
  isOpen,
  onConfirm: deactivateOrganizaton,
  onDismiss: dismiss,
  organizationName,
}) {
  const t = useFormatMessage();
  const { handleSubmit, reset } = useForm();

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(() => {
    deactivateOrganizaton();
    reset();
    dismiss();
  }, [deactivateOrganizaton, reset, dismiss]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          {t('deactivate-organization-dialog.header')}
        </DialogHeader>
        <DialogContent>
          <p className="flex flex-col space-y-4">
            {t('deactivate-organization-dialog.confirmation-text', {
              organisationName: organizationName,
            })}
          </p>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.deactivateOrganisation.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.deactivateOrganisation.button.submit}
            type="submit"
          >
            {t('deactivate-organization-dialog.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
