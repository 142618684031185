import { gql } from 'graphql-request';

export const TEACHER_CREATE_PERSONALIZED_TESTS = gql`
  mutation teacherCreatePersonalizedTests(
    $classId: Int!
    $assessmentProcedureId: Int!
  ) {
    teacherCreatePersonalizedTests(
      classId: $classId
      assessmentProcedureId: $assessmentProcedureId
    ) {
      statusCode
      id
      message
    }
  }
`;
