import { MultiCollapsable } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import { TestContainer } from './TestContainer';

export function Exercise({ actions, exercise }) {
  const t = useFormatMessage();
  const result = JSON.parse(exercise.judgeResult);
  const amount = result ? (
    `${exercise.successful} / ${exercise.amount}`
  ) : (
    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
      {t('results-overview.calendar_item.no_results_scratch_no_judge')}
    </span>
  );

  return (
    <li>
      <MultiCollapsable
        actions={actions}
        amount={amount}
        hasDropDown={Boolean(result)}
        mainText={exercise.exerciseTitle}
      >
        <ul className="w-full">
          {result?.map((test, index) => (
            <TestContainer key={`scr/tst/${Date.now() + index}`} test={test} />
          ))}
        </ul>
      </MultiCollapsable>
    </li>
  );
}
