import { OWN_CONTENT } from 'utils/constants/contentTypes';
import viewModes from 'utils/constants/viewModes';
import { backendAcceptedDateFormat, setTime } from 'utils/timeHelpers';
import { getStudioIdForLesson } from '../getStudioIdForLesson';

export function submitCalendarItem(
  data,
  isUpdateMode,
  updateCalendarItem,
  addCalendarItem,
  itemInfo,
  teacherPrograms,
) {
  const {
    assignmentAddToCalendarAction,
    assignmentDescription,
    assignmentTitle,
    chapter,
    chapterItem,
    classGroups,
    closeAfterEndTime,
    dateEvent,
    endTimeHour,
    endTimeMinute,
    idEvent,
    program,
    resourceDisplayName,
    sendToTeams,
    startTimeHour,
    startTimeMinute,
    studioId,
    viewMode,
  } = data;

  const startTime = setTime(
    dateEvent,
    startTimeHour.label,
    startTimeMinute.label,
  );
  const endTime = setTime(dateEvent, endTimeHour.label, endTimeMinute.label);

  const isOwnContent = !chapterItem?.studioId;
  const itemInfoChapterItem =
    itemInfo?.chapterItem === OWN_CONTENT ? null : itemInfo?.chapterItem;

  const versionStudioId = studioId?.value;
  const studioIdToSend = getStudioIdForLesson(
    teacherPrograms,
    program?.value,
    chapter?.value,
    chapterItem?.value,
    itemInfo,
    versionStudioId,
  );

  const input = {
    chapterItemId: isOwnContent
      ? itemInfoChapterItem || chapterItem?.value
      : null,
    classGroupIds: classGroups.map((x) => x.value),
    closedAfterEndTime: closeAfterEndTime ?? false,
    endTime: backendAcceptedDateFormat(endTime, true),
    hidden: false,
    startTime: backendAcceptedDateFormat(startTime, true),
    studioId: studioIdToSend,
    viewMode: viewMode?.value ?? viewModes.CLASSICAL,
  };

  if (sendToTeams) {
    input.createExternalItem = true;
    input.assignmentTitle = assignmentTitle;
    input.assignmentDescription = assignmentDescription;
    input.addToCalendarAction = assignmentAddToCalendarAction;
    input.resourceDisplayName = `Open ${
      resourceDisplayName ||
      itemInfo?.title ||
      itemInfo?.chapter?.chapterItem?.name
    } in het FTRPRF Platform`;
  }

  if (isUpdateMode) {
    void updateCalendarItem({ id: idEvent, input });
  } else {
    void addCalendarItem(input);
  }

  if (itemInfo?.setStudioId) itemInfo.setStudioId(null);
}
