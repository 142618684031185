import { FaSave } from 'react-icons/fa';
import useFormatMessage from '../../hooks/useFormatMessage';
import { ActionStyledAsButton } from './ActionStyledAsButton';

export function SaveButton({
  badge = false,
  className = '',
  disabled = false,
  disabledClassName = 'opacity-40 cursor-not-allowed',
  form = '',
  iconMargin = 'mr-2',
  loaderClassName = 'w-5',
  loading = false,
  onClick = null,
  secondary = false,
  size = 'medium',
  target = '',
  test = '',
  text = '',
  title = '',
  type = 'button',
  variant = 'blue',
}) {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      badge={badge}
      className={className}
      disabled={disabled}
      disabledClassName={disabledClassName}
      form={form}
      iconBefore={FaSave}
      iconMargin={iconMargin}
      loaderClassName={loaderClassName}
      loading={loading}
      onClick={onClick}
      secondary={secondary}
      size={size}
      target={target}
      test={test}
      title={title}
      type={type}
      variant={variant}
    >
      {text || t('global.save')}
    </ActionStyledAsButton>
  );
}
