import { gql } from 'graphql-request';

export const STUDENT_USER_GROUP_BY_LESSON_SESSION_ID = gql`
  query studentUserGroupByLessonSessionId($lessonSessionId: String!) {
    studentUserGroupByLessonSessionId(lessonSessionId: $lessonSessionId) {
      steamsGroupId
      name
      userRoles {
        userId
        firstName
        lastName
        roleId
        roleName
      }
    }
  }
`;
