import { Tab, TabList } from 'components/Tab';
import useFormatMessage from 'hooks/useFormatMessage';
import { generatePath, useParams } from 'react-router-dom';
import URLS, { userProfileTabs, classDetailTabs } from 'utils/constants/urls';
import UserProfilePageHeader from './UserProfilePageHeader';

export default function TeacherUserProfilePageHeader({ user }) {
  const { classGroupId, studentId } = useParams();

  const t = useFormatMessage();

  const breadcrumbs = classGroupId
    ? [
        { href: URLS.CLASSGROUP, name: t('header.navigation.classes') },
        {
          href: generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
            classGroupId,
            tab: classDetailTabs.STUDENTS,
          }),
          name: t('class-detail.pageTitle'),
          current: true,
        },
      ]
    : [];

  return (
    <UserProfilePageHeader breadcrumbs={breadcrumbs} user={user}>
      <TabList>
        <Tab
          label={t('global.general.info')}
          to={generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
            studentId,
            classGroupId,
            tab: userProfileTabs.INFO,
          })}
        />
        <Tab
          label={t('global.RESULT.plural')}
          to={generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
            studentId,
            classGroupId,
            tab: userProfileTabs.RESULTS,
          })}
        />
        <Tab
          label={t('global.EXERCISE.plural')}
          to={generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
            studentId,
            classGroupId,
            tab: userProfileTabs.EXERCISES,
          })}
        />
      </TabList>
    </UserProfilePageHeader>
  );
}
