import { Droppable } from 'react-beautiful-dnd';

import { Group } from './Group';

export function DroppableGroup({ index, members, roles }) {
  return (
    <Droppable droppableId={`${index}`}>
      {(provided) => (
        <Group
          index={index}
          members={members}
          provided={provided}
          roles={roles}
        />
      )}
    </Droppable>
  );
}
