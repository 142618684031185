import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { normalize } from 'normalize-diacritics';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Dropdown,
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { supportedLanguages } from 'providers/LanguageProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { isOptionalMail } from 'utils/isMail';
import titleCase from 'utils/titleCase';
import { StyledRadioGroup } from 'components/StyledRadioGroup';
import { dataTestIds } from 'utils/dataTestIds';
import { TEACHER, STUDENT } from 'utils/determineOrganisationRoles';

export default function CreateUserDialog({
  isLoading,
  isOpen,
  onConfirm: addUser,
  onDismiss: dismiss,
  organization,
  role,
  setRole,
}) {
  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [language, setLanguage] = useState();

  const { prefix } = organization;
  const onDismiss = useCallback(() => {
    dismiss();
    setUserName('');
    setFirstName('');
    setLastName('');
    reset();
  }, [dismiss, reset]);

  const onSubmit = async (data) => {
    const input = data;
    input.firstName = input.firstName.trim();
    input.lastName = input.lastName.trim();
    input.language = language || organization.language.toLowerCase();
    input.defaultPassword = input.defaultPassword.trim();
    input.userName = input?.userName
      ? await normalize(
          `${prefix}${input.userName.trim()}`.replace(/[^A-Z0-9]/gi, ''),
        )
      : await normalize(
          `${prefix}${userName.trim()}`.replace(/[^A-Z0-9]/gi, ''),
        );

    input.displayName = `${data.firstName} ${data.lastName}`;
    input.email = input.email?.trim();

    addUser(input, onDismiss);
  };

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          {titleCase(
            t('add-user-dialog.header', {
              what: t(`global.${role.toUpperCase()}.singular`),
            }),
          )}
        </DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            {setRole && (
              <StyledRadioGroup
                label={t('add-user-dialog.user-type')}
                onChange={(role) => {
                  setRole(role);
                }}
                options={[
                  {
                    name: t('global.TEACHER.singular'),
                    value: TEACHER,
                    test: dataTestIds.modal.createUser.radio.teacher,
                  },
                  {
                    name: t('global.STUDENT.singular'),
                    value: STUDENT,
                    test: dataTestIds.modal.createUser.radio.student,
                  },
                ]}
                value={role}
              />
            )}

            {/* firstname */}
            <div className="flex flex-col">
              <Label htmlFor="createUserDialog-firstName" required>
                {t('profile.first_name')}
              </Label>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input
                    data-test={dataTestIds.modal.createUser.input.firstname}
                    disabled={isLoading}
                    hasErrors={Boolean(errors.firstName)}
                    id="createUserDialog-firstName"
                    onBlur={onBlur}
                    onChange={async (e) => {
                      onChange(e);
                      setFirstName(e.target.value);

                      const newUserName = await normalize(
                        `${e.target.value}${lastName}`.replace(
                          /[^A-Z0-9]/gi,
                          '',
                        ),
                      );
                      setUserName(newUserName);
                      setValue('userName', newUserName);
                    }}
                    placeholder={t('profile.first_name')}
                    required
                    value={value || ''}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                  maxLength: {
                    value: 64,
                    message: t('profile.errors.firstname.length'),
                  },
                  minLength: {
                    value: 1,
                    message: t('profile.errors.firstname.length'),
                  },
                }}
                type="input"
              />
              {errors.firstName && (
                <span className="text-red-500 text-xs">
                  {errors.firstName.message}
                </span>
              )}
            </div>
            {/* lastname */}
            <div className="flex flex-col">
              <Label htmlFor="createUserDialog-lastName" required>
                {t('profile.last_name')}
              </Label>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input
                    data-test={dataTestIds.modal.createUser.input.lastname}
                    disabled={isLoading}
                    hasErrors={Boolean(errors.lastName)}
                    id="createUserDialog-lastName"
                    onBlur={onBlur}
                    onChange={async (e) => {
                      onChange(e);
                      setLastName(e.target.value);

                      const newUserName = await normalize(
                        `${firstName}${e.target.value}`.replace(
                          /[^A-Z0-9]/gi,
                          '',
                        ),
                      );
                      setUserName(newUserName);
                      setValue('userName', newUserName);
                    }}
                    placeholder={t('profile.last_name')}
                    required
                    value={value || ''}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                  maxLength: {
                    value: 64,
                    message: t('profile.errors.lastname.length'),
                  },
                  minLength: {
                    value: 1,
                    message: t('profile.errors.lastname.length'),
                  },
                }}
                type="input"
              />
              {errors.lastName && (
                <span className="text-red-500 text-xs">
                  {errors.lastName.message}
                </span>
              )}
            </div>

            {/* email */}
            <div className="flex flex-col">
              <Label htmlFor="createUserDialog-email">
                {t('global.email')}
              </Label>
              <Controller
                control={control}
                name="email"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input
                    data-test={dataTestIds.modal.createUser.input.email}
                    disabled={isLoading}
                    hasErrors={Boolean(errors.email)}
                    id="createUserDialog-email"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="email@mail.com"
                    value={value || ' '}
                  />
                )}
                rules={{
                  validate: { isOptionalMail },
                }}
                type="input"
              />
              {errors.email && (
                <span className="text-red-500 text-xs">
                  {errors.email.message}
                </span>
              )}
              {errors.email?.type === 'isMail' && (
                <span className="text-red-500 text-xs">
                  {t('email.validate-error')}
                </span>
              )}
            </div>

            {/* username */}
            <div className="flex flex-col">
              <Label htmlFor="createUserDialog-userName" required>
                {t('profile.username')}
              </Label>
              <Controller
                control={control}
                name="userName"
                render={({ field: { onBlur, onChange, value } }) => (
                  <div className="mt-1 flex rounded-md shadow-sm  hover:outline hover:outline-blue-500 hover:outline-4 focus-within:outline focus-within:outline-blue-500 focus-within:outline-4">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      {prefix}
                    </span>
                    <Input
                      a11y={false}
                      className="flex-1 min-w-0 block px-3 py-2 rounded-none rounded-r-md"
                      data-test={dataTestIds.modal.createUser.input.username}
                      disabled={isLoading}
                      hasErrors={Boolean(errors.userName)}
                      id="createUserDialog-userName"
                      onBlur={onBlur}
                      onChange={async (e) => {
                        onChange(e);

                        const newUserName = await normalize(
                          `${e.target.value}`.replace(/[^A-Z0-9]/gi, ''),
                        );
                        setUserName(newUserName);
                      }}
                      placeholder={t('profile.username')}
                      required
                      value={value || userName}
                    />
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                  },
                  maxLength: {
                    value: 64,
                    message: t('profile.errors.username.length'),
                  },
                  minLength: {
                    value: 1,
                    message: t('profile.errors.username.length'),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: t('profile.errors.username.pattern'),
                  },
                }}
                type="input"
              />
              {errors.userName && (
                <span className="text-red-500 text-xs">
                  {errors.userName.message}
                </span>
              )}
            </div>
            <div>
              <Label>{t('global.LANGUAGE.singular')}</Label>
              <Dropdown
                data-test={dataTestIds.modal.createUser.dropdown.language}
                onChange={(language) => setLanguage(language.value)}
                options={supportedLanguages.map((language) => ({
                  value: language.key,
                  label: language.label,
                }))}
                returnMethod="object"
                value={
                  language?.toLowerCase() ||
                  supportedLanguages.find(
                    (language) =>
                      language.key?.toLowerCase() ===
                      organization.language?.toLowerCase(),
                  )?.key
                }
              />
            </div>
            {/* default password */}
            <div className="flex flex-col">
              <Label htmlFor="createUserDialog-default_password" required>
                {t('profile.default.password')}
              </Label>
              <Controller
                control={control}
                name="defaultPassword"
                render={({ field: { onBlur, onChange, value } }) => (
                  <>
                    <Input
                      aria-describedby="default_password-description"
                      data-test={dataTestIds.modal.createUser.input.password}
                      disabled={isLoading}
                      hasErrors={Boolean(errors.defaultPassword)}
                      id="createUserDialog-default_password"
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('profile.default.password.placeholder')}
                      required
                      value={value || ''}
                    />
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="default_password-description"
                    >
                      {t('profile.default.password.description')}
                    </p>
                  </>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                  minLength: {
                    value: 8,
                    message: t('profile.errors.password.length'),
                  },
                  validate: (value) => {
                    if (
                      value === value.toUpperCase() ||
                      value === value.toLowerCase()
                    ) {
                      return t('profile.errors.password.capital');
                    }

                    if (!/\d/.test(value)) {
                      return t('profile.errors.password.number');
                    }
                    return true;
                  },
                }}
                type="input"
              />
              {errors.defaultPassword && (
                <span className="text-red-500 text-xs">
                  {errors.defaultPassword.message}
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            disabled={isLoading}
            onClick={onDismiss}
            test={dataTestIds.modal.createUser.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={Object.keys(errors).length > 0 || isLoading}
            loading={isLoading}
            test={dataTestIds.modal.createUser.button.submit}
            type="submit"
          >
            {titleCase(
              t('schooladmin-overview.new-user', {
                what: t(`global.${role.toUpperCase()}.singular`),
              }),
            )}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
