import { useState } from 'react';
import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';

import { ActionStyledAsButton, PageHeader } from '@ftrprf/tailwind-components';
import { SchooladminTaskInfoTable } from 'components/Tables/SchooladminTaskInfoTable';
import useSchooladminUpsertUsersAndClassesViaExcelFileUpload from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminUpsertUsersAndClassesViaExcelFileUpload';
import useSchooladminFindTaskInfo from 'hooks/api/plannerService/schooladmin/queries/useSchooladminFindTaskInfo';
import useFormatMessage from 'hooks/useFormatMessage';
import { taskTypes } from 'utils/constants/taskTypes';
import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import ImportExcelDialog from './partial/ImportExcelDialog';

const DOCUMENTATION_LINK =
  'https://codefeverpublic.blob.core.windows.net/store-content/excel_import/info.pdf';
const TEMPLATE_LINK = 'template.xlsx';

export default function SchooladminImport() {
  const t = useFormatMessage();
  const [showImportDialog, setShowImportDialog] = useState(false);
  const pageSize = 50;

  const breadcrumbsLinks = [{ href: URLS.IMPORT, name: t('global.import') }];

  const {
    data: schooladminFindExcelInfoData,
    isLoading,
    refetch,
  } = useSchooladminFindTaskInfo({
    pageSize,
    taskType: taskTypes.EXCEL,
  });
  const { isLoading: importIsBusy, mutateAsync: startImportFromExcelFile } =
    useSchooladminUpsertUsersAndClassesViaExcelFileUpload();

  return (
    <>
      <ImportExcelDialog
        importIsBusy={importIsBusy}
        isOpen={showImportDialog}
        onDismiss={() => {
          setShowImportDialog(false);
          refetch();
        }}
        onSubmit={async (file) => {
          const item = await startImportFromExcelFile(file);

          refetch();
          setShowImportDialog(false);
          return item;
        }}
      />

      <PageHeader breadcrumbs={breadcrumbsLinks} title={t('global.import')} />
      <div className="flex flex-col max-w-5/6 mx-auto pt-4 pb-8 gap-2">
        <div className="rounded-md bg-blue-100 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-blue-700"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                {t('import.excel.description')}
                <span className="font-semibold block mt-4">
                  {t('import.excel.max')}
                </span>
              </p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6 flex gap-2 flex-col">
                {/* view template documentation */}
                <a
                  className="md:whitespace-nowrap font-medium text-blue-700 underline hover:text-blue-600 flex flex-row"
                  href={DOCUMENTATION_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkIcon
                    aria-hidden="true"
                    className="h-5 w-5 mr-2 text-blue-700"
                  />
                  {t('import.excel.documentation')}
                </a>

                {/* download template */}
                <a
                  className="md:whitespace-nowrap font-medium text-blue-700 underline hover:text-blue-600 flex flex-row"
                  download
                  href={TEMPLATE_LINK}
                >
                  <ArrowDownTrayIcon
                    aria-hidden="true"
                    className="h-5 w-5 mr-2 text-blue-700"
                  />
                  {t('import.excel.download')}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="w-full text-right">
          <ActionStyledAsButton
            onClick={() => setShowImportDialog(true)}
            test={dataTestIds.page.importUsersAndClasses.button.import}
          >
            {t('import.dialog.title')}
          </ActionStyledAsButton>
        </div>
        <SchooladminTaskInfoTable
          data={schooladminFindExcelInfoData}
          isExcelImport
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
