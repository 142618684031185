import c from 'utils/c';

import SkeletonLine from './helpers/SkeletonLine';
// eslint-disable-next-line import/no-cycle
import SkeletonTable from './helpers/SkeletonTable';

export const SKELETON_OPTIONS = {
  /* Horizontal Lines */
  LINE_H_XS: 'L_H_XS',
  LINE_H_SM: 'L_H_SM',
  LINE_H_MD: 'L_H_MD',
  LINE_H_LG: 'L_H_LG',
  LINE_H_XL: 'L_H_XL',
  LINE_H_2XL: 'L_H_2XL',

  /* Vertical Lines */
  LINE_V_XS: 'L_V_XS',
  LINE_V_SM: 'L_V_SM',
  LINE_V_MD: 'L_V_MD',
  LINE_V_LG: 'L_V_LG',
  LINE_V_XL: 'L_V_XL',
  LINE_V_2XL: 'L_V_2XL',

  /* rectangle */
  RECTANGLE: 'R',

  /* Table */
  TABLE: 'T',
};

const typeSelector = (item, index) => {
  const typeToRepeat = item.replace(/[\d&]/gi, '');

  switch (typeToRepeat) {
    /* Horizontal Lines */
    case SKELETON_OPTIONS.LINE_H_XS:
      return <SkeletonLine key={`${index}`} height={2} />;
    case SKELETON_OPTIONS.LINE_H_SM:
      return <SkeletonLine key={`${index}`} height={4} />;
    case SKELETON_OPTIONS.LINE_H_MD:
      return <SkeletonLine key={`${index}`} height={8} />;
    case SKELETON_OPTIONS.LINE_H_LG:
      return <SkeletonLine key={`${index}`} height={16} />;
    case SKELETON_OPTIONS.LINE_H_XL:
      return <SkeletonLine key={`${index}`} height={32} />;
    case SKELETON_OPTIONS.LINE_H_2XL:
      return <SkeletonLine key={`${index}`} height={64} />;

    /* Vertical Lines */
    case SKELETON_OPTIONS.LINE_V_XS:
      return <SkeletonLine key={`${index}`} width={2} />;
    case SKELETON_OPTIONS.LINE_V_SM:
      return <SkeletonLine key={`${index}`} width={4} />;
    case SKELETON_OPTIONS.LINE_V_MD:
      return <SkeletonLine key={`${index}`} width={8} />;
    case SKELETON_OPTIONS.LINE_V_LG:
      return <SkeletonLine key={`${index}`} width={16} />;
    case SKELETON_OPTIONS.LINE_V_XL:
      return <SkeletonLine key={`${index}`} width={32} />;
    case SKELETON_OPTIONS.LINE_V_2XL:
      return <SkeletonLine key={`${index}`} width={64} />;

    /* Rectangle */
    case SKELETON_OPTIONS.RECTANGLE: {
      const rectangleHeight = parseInt(item.match(/\d+&/), 10); // items before &
      const rectangleWidth = parseInt(item.match(/\d+$/), 10); // items after &

      return (
        <SkeletonLine
          key={`${index}`}
          height={rectangleHeight}
          width={rectangleWidth}
        />
      );
    }

    /* Table */
    case SKELETON_OPTIONS.TABLE:
      return <SkeletonTable key={`${index}`} />;
    default:
      return null;
  }
};

/**
 * a skeleton based on the layout string;
 * the layout string is a string of numbers and letters;
 * the numbers are the amount of times the letter is repeated;
 * the letters are the type of skeleton to repeat
 *
 * example: `1${SKELETON_OPTIONS.LINE_V_XL}|20${SKELETON_OPTIONS.LINE_H_XS}|5${SKELETON_OPTIONS.RECTANGLE}12&14 1${SKELETON_OPTIONS.LINE_H_SM} 1${SKELETON_OPTIONS.LINE_H_MD} 1${SKELETON_OPTIONS.LINE_H_LG} 1${SKELETON_OPTIONS.LINE_H_XL} 1T`
 *
 * @param className
 * @param {string} layout
 * @returns a skeleton
 */
export default function SkeletonConstructor({
  className = 'w-full h-full',
  layout,
}) {
  const seconds = new Date().getSeconds();
  const milliSeconds = new Date().getMilliseconds();

  return (
    <div className={c('flex gap-2 flex-col', className)}>
      {layout
        .split(' ')
        .map((item, index) => {
          if (item.match(/\|/g)) {
            const outerIndex = index;
            return (
              <div
                key={outerIndex}
                className="flex flex-row gap-2 justify-center items-center"
              >
                {item.split('|').map((deeperItem, index) => (
                  <SkeletonConstructor
                    key={`${outerIndex}/${Date.now() + index}`}
                    layout={deeperItem}
                  />
                ))}
              </div>
            );
          }

          const repetition = parseInt(item, 10) || 1;

          const intermediateKey =
            seconds * (milliSeconds + index) * Math.random();
          const values = [];

          // use a structure that allows for adding a unique key
          for (let i = 0; i < repetition; i++) {
            values.push(typeSelector(item, intermediateKey + i));
          }

          return values;
        })
        .filter((item) => item)}
    </div>
  );
}
