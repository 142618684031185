import { usePrismTheme } from '@ftrprf/tailwind-components';
import c from 'utils/c';
import { CodeForPreview } from './CodeForPreview';

export function CodeBlocksPreview({
  showLineNumbers,
  showMatchingBraces,
  showRainbowBrackets,
  theme,
}) {
  usePrismTheme(theme);

  return (
    <pre
      className={c(
        showLineNumbers && 'line-numbers',
        'language-python',
        'min-h-[17rem]',
      )}
    >
      <code
        className={c(
          'language-python',
          showMatchingBraces && 'match-braces',
          showRainbowBrackets && 'rainbow-braces',
        )}
      >
        <CodeForPreview
          showMatchingBraces={showMatchingBraces}
          showRainbowBrackets={showRainbowBrackets}
        />
        {showLineNumbers && (
          <>
            <span aria-hidden="true" className="line-numbers-rows">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </span>
            <span className="line-numbers-sizer" style={{ display: 'none' }} />
          </>
        )}
      </code>
    </pre>
  );
}
