import { RadioGroup } from '@headlessui/react';
import c from '../../utils/c';

/**
 * This is an implementation of https://tailwindui.com/components/application-ui/forms/radio-groups#component-7b583a008c3fc62c0fe403d10ca042bb
 * Note it needs some changes to the config:
 * ```
 *   // tailwind.config.js
 *   module.exports = {
 *     // ...
 *     plugins: [
 *       // ...
 *       require('@tailwindcss/forms'),
 *     ],
 *   }
 *   ```
 *
 *   The settings variable is an array of objects with a name & description property.  There is also an optional extra property.
 */
export function RadioButtonList({
  className = '',
  groupName = '',
  onClick = () => {},
  selected,
  settings = [],
}) {
  const { Description, Label, Option } = RadioGroup;

  return (
    <RadioGroup
      by="name"
      className={className}
      onChange={onClick}
      value={selected}
    >
      <Label className="sr-only">{groupName}</Label>
      <div className="-space-y-px rounded-md bg-white">
        {settings.map((setting, settingIndex) => (
          <Option
            key={setting.name}
            className={({ checked }) =>
              c(
                settingIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIndex === settings.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'z-10 border-accent-200 bg-accent-50'
                  : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none',
              )
            }
            value={setting}
          >
            {({ active, checked }) => (
              <>
                <span
                  aria-hidden="true"
                  className={c(
                    checked
                      ? 'bg-accent-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-accent-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <Label
                    as="span"
                    className={c(
                      checked ? 'text-accent-900' : 'text-gray-900',
                      'block text-sm font-medium',
                    )}
                  >
                    {setting.name}
                  </Label>
                  <Description
                    as="span"
                    className={c(
                      checked ? 'text-accent-700' : 'text-gray-500',
                      'block text-sm',
                    )}
                  >
                    {setting.description}
                  </Description>
                  {setting?.extra && setting.extra}
                </div>
              </>
            )}
          </Option>
        ))}
      </div>
    </RadioGroup>
  );
}
