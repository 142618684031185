import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_DELETE_TEACHER_FROM_PROGRAM } from 'api/plannerService/schooladmin/mutations/schooladminDeleteTeacherFromProgram';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminDeleteTeacherFromProgram() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ programId, userId }) =>
      plannerServiceQueryClient.request(
        SCHOOLADMIN_DELETE_TEACHER_FROM_PROGRAM,
        {
          programId,
          id: userId,
        },
      ),
    {
      mutationKey: 'schooladminDeleteTeacherFromProgram',
      onSuccess: () => {
        toast(TOASTTYPES.REMOVESUCCESS, {
          str: t('global.PROGRAM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.REMOVEERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );
  return mutation;
}
