import useFormatMessage from 'hooks/useFormatMessage';
import { ErrorUnplugged } from './ErrorUnplugged';

export function Error401() {
  const t = useFormatMessage();

  const reasons = [
    t('errors.401.message.reason.no_contract_with_ftrprf', {
      cta: (
        <a className="text-accent-500" href="https://demo.ftrprf.be/">
          {t('errors.401.message.cta')}
        </a>
      ),
      website: (
        <a className="text-accent-500" href="https://ftrprf.be">
          FTRPRF.be
        </a>
      ),
    }),
    t('errors.401.message.reason.not_synchronized_with_smartschool'),
  ];

  const showReasons = reasons && reasons.length > 0;

  return (
    <ErrorUnplugged
      showBackLink={false}
      statusCode="401"
      titleKey="errors.401.header"
    >
      <div className="flex flex-col gap-4">
        <p>{t('errors.401.message.intro')}</p>

        <p>
          {t('errors.401.message.description', {
            amountOfReasons: reasons.length,
          })}
        </p>

        {showReasons && (
          <ul className="list-disc list-inside">
            {reasons.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        )}

        <p>
          {t('errors.401.message.conclusion', {
            email: (
              <a className="text-accent-500" href="mailto:info@ftrprf.be">
                info@ftrprf.be
              </a>
            ),
          })}
        </p>
      </div>
    </ErrorUnplugged>
  );
}
