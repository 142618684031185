import { gql } from 'graphql-request';

export const ADMIN_CREATE_CHAPTER_ITEM = gql`
  mutation adminCreateChapterItem(
    $chapterId: Int!
    $input: ChapterItemInput!
    $file: Upload
  ) {
    adminCreateChapterItem(chapterId: $chapterId, input: $input, file: $file) {
      id
      name
      chapterId
      lessons {
        id
      }
    }
  }
`;
