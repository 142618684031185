import useFormatMessage from 'hooks/useFormatMessage';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { dataTestIds } from 'utils/dataTestIds';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

export function ActionsButton() {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      iconAfter={ChevronDownIcon}
      secondary
      test={dataTestIds.page.manage.button.extra.id}
    >
      {t('global.actions')}
    </ActionStyledAsButton>
  );
}
