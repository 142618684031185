import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';
import { taggedListToOptions } from 'utils/listToOptions';

export function SelectChapter({
  control,
  errors,
  findChapters,
  program,
  setValue,
}) {
  const t = useFormatMessage();

  return (
    <div>
      <Label className="mt-2" htmlFor="formCalendarItem_selectChapter">
        {t('calendar.modal.selectChapter')}
      </Label>
      <div>
        <Controller
          control={control}
          name="chapter"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              hasErrors={Boolean(errors.chapter)}
              inputId="formCalendarItem_selectChapter"
              onBlur={onBlur}
              onChange={(e) => {
                setValue('chapterItem', undefined);
                onChange(e);
              }}
              options={taggedListToOptions(findChapters(program))}
              placeholder={t('calendar.modal.selectChapter')}
              returnMethod="object"
              value={value}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('dropdown.no_results'),
            },
          }}
          type="select"
        />
        {errors.chapter && (
          <p className="text-xs text-red-600">{errors.chapter.message}</p>
        )}
      </div>
    </div>
  );
}
