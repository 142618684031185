import useFormatMessage from '../../../../hooks/useFormatMessage';

import { QUESTION_TYPES } from '../../../slideviewer/constants';

import SlideViewerMultipleChoiceQuestion from './SlideViewerMultipleChoiceQuestion';
import SlideViewerOpenQuestion from './SlideViewerOpenQuestion';

export default function SlideViewerQuestion({ canSubmit, question }) {
  const t = useFormatMessage();

  switch (question.type) {
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return (
        <SlideViewerMultipleChoiceQuestion
          canSubmit={canSubmit}
          question={question}
        />
      );
    case QUESTION_TYPES.OPEN:
      return (
        <SlideViewerOpenQuestion canSubmit={canSubmit} question={question} />
      );
    default:
      return <div>{t('styled-slide-viewer.questions.not_supported_type')}</div>;
  }
}
