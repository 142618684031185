import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import getGenericSessionId from 'utils/getGenericSessionId';

export function loginOnMsalThroughCodeFeverAuth(sessionToken, targetLinkUri) {
  if (sessionToken && targetLinkUri) {
    window.location = `${process.env.REACT_APP_CODEFEVER_SERVICE_INIT_URL}?session_token=${sessionToken}&target_link_uri=${targetLinkUri}&redirect_uri=${process.env.REACT_APP_AD_REDIRECT_LINK}`;
  } else {
    // eslint-disable-next-line no-console
    console.error('Codefever page without the right params?');
  }
}

export function redirectToLesson(targetLinkUri, navigate) {
  if (!targetLinkUri) {
    // eslint-disable-next-line no-console
    console.error('Codefever page without the right params?');
  } else {
    const [studioId, viewMode] = targetLinkUri.split('/');

    navigate(
      generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID, {
        sessionId: getGenericSessionId(studioId),
        studioId,
        viewMode,
      }),
    );
  }
}

export function openLesson(idToken, targetLinkUri, navigate) {
  const { launch_uri_redirect_url: launchUriRedirectUrl } = idToken || {};

  redirectToLesson(targetLinkUri || launchUriRedirectUrl, navigate);
}

export function getUserFromMsal(instance) {
  instance.loginRedirect().catch(() => instance.handleRedirectPromise());
}

export function goToLogout(navigate) {
  navigate(generatePath(URLS.CODEFEVER_LOGOUT) + window.location.search);
}
