import { Outlet } from 'react-router-dom';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS from 'utils/constants/urls';

export default function Tools() {
  const t = useFormatMessage();

  return (
    <GeneralLayout>
      <PageHeaderWrapper
        borderBottom={false}
        marginBottom={false}
        title={t('manage.title')}
      >
        <TabList>
          <Tab
            label={`${t('global.LESSON.singular')} ${t(
              'move-lesson.method.move',
            ).toLowerCase()}`}
            to={URLS.TOOLS_MOVE_LESSON}
          />
          <Tab
            label={`${t('global.LESSON.singular')} ${t(
              'move-lesson.method.copy',
            ).toLowerCase()}`}
            to={URLS.TOOLS_COPY_LESSON}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet />
      </div>
    </GeneralLayout>
  );
}
