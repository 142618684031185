import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import throwIfDevelopment from 'utils/throwIfDevelopment';

export default function useFormatMessage() {
  const { formatMessage, locale } = useIntl();

  return useCallback(
    (id, values = null) => {
      const message = formatMessage({ id }, values);

      throwIfDevelopment(
        message === id,
        `No '${locale}' translation found for: ${id}`,
      );

      return message;
    },
    [formatMessage, locale],
  );
}
