import { useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { ConfirmationDialog } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useUserClassgroupManagement from 'hooks/api/plannerService/CRUD/useUserClassgroupManagement';
import useCreateStudent from 'hooks/api/plannerService/schooladmin/mutations/useCreateStudent';
import useSchooladminCreateTeacher from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminCreateTeacher';
import useSchooladminDisableUser from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminDisableUser';
import useSchooladminEnableUser from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminEnableUser';
import useSchooladminUsers from 'hooks/api/plannerService/schooladmin/queries/useSchooladminUsers';
import useFormatMessage from 'hooks/useFormatMessage';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import URLS from 'utils/constants/urls';

import { useSchooladminResetPassword } from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminResetPassword';
import { ResetPasswordDialog } from 'components/ResetPasswordDialog';
import determineRoles, { STUDENT, TEACHER } from 'utils/determineUserRoles';
import useLinkAllTeachersToAllPrograms from 'hooks/api/plannerService/schooladmin/mutations/useLinkAllTeachersToAllPrograms';
import { PeriodsContext } from 'providers/PeriodsProvider';
import ftrprfUserRoles from 'utils/constants/ftrprfUserRoles';
import { dataTestIds } from 'utils/dataTestIds';
import AddUserToClassDialog from './partials/AddUserToClassDialog';
import CreateUserDialog from './partials/CreateUserDialog';
import ManageUsers from './ManageUsers';

const defaultRoles = [
  ftrprfUserRoles.SCHOOLTEACHER,
  ftrprfUserRoles.SCHOOLSTUDENT,
  ftrprfUserRoles.SCHOOLADMIN,
];

export default function ManageUsersContainer() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);
  const [isAddUserToClassDialogOpen, setIsAddUserToClassDialogOpen] =
    useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [userCreateRole, setUserCreateRole] = useState(STUDENT);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ firstname: sortOperation.ASC });
  const [isLinkTeachersModalOpen, setIsLinkTeachersModalOpen] = useState(false);

  const { isSmartSchoolUser, organization, refetchOrganisationInfo } =
    useContext(UserContext);
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const t = useFormatMessage();
  const defaultValues = {
    roles: withDefault(ArrayParam, defaultRoles),
    enabled: withDefault(BooleanParam, true, false),
    fullname: withDefault(StringParam, null, false),
  };
  const [searchParams, setSearchParams] = useQueryParams(defaultValues);
  const { data, isError, isLoading, refetch } = useSchooladminUsers({
    filter: [
      {
        key: 'enabled',
        value: searchParams.enabled,
        operation: filterOperation.EQUAL,
      },
      {
        key: 'roles',
        value: searchParams.roles,
        operation: filterOperation.IN,
      },
      {
        key: 'fullname',
        value: searchParams.fullname,
        operation: filterOperation.LIKE,
      },
    ].filter((i) => Boolean(i.value)),
    page: page - 1,
    size: itemsPerPage,
    sort,
  });

  const { add: useAddUserToClassGroup } = useUserClassgroupManagement();
  const { isLoading: isLoadCreateTeacher, mutateAsync: createTeacher } =
    useSchooladminCreateTeacher();
  const { isLoading: isResetting, mutateAsync: resetPassword } =
    useSchooladminResetPassword();
  const { isLoading: isLoadCreateStudent, mutateAsync: createStudent } =
    useCreateStudent();
  const { mutateAsync: AddUserToClassGroup } = useAddUserToClassGroup();
  const { mutateAsync: DisableUser } = useSchooladminDisableUser();
  const { mutateAsync: EnableUser } = useSchooladminEnableUser();
  const { isLoading: isLinkTeachersLoading, mutateAsync: linkTeachers } =
    useLinkAllTeachersToAllPrograms();

  const createUser = (data, onDismiss) => {
    const createTypeOfUser =
      userCreateRole === TEACHER ? createTeacher : createStudent;

    createTypeOfUser({ input: data }).then(() => {
      refetch().then(() => {
        refetchOrganisationInfo();
        onDismiss();
      });
    });
  };

  const users = data?.content.map((user) => ({
    ...determineRoles(user),
    ...user,
  }));
  const totalPages = data?.pages;
  const totalItems = data?.total;

  const addUserToClassGroup = async (data) => {
    await AddUserToClassGroup({
      classGroupId: data.id,
      userId: userData.id,
    }).then(() => {
      void refetchOrganisationInfo();
      void refetch();
      setIsAddUserToClassDialogOpen(false);
    });
  };

  const disableUser = async (data) => {
    await DisableUser(data);
    void refetch();
  };

  const enableUser = async (data) => {
    await EnableUser(data.id);
    void refetch();
  };

  const canResetPassword = !isSmartSchoolUser;

  return (
    <>
      {canResetPassword && isResetPasswordDialogOpen && (
        <ResetPasswordDialog
          isOpen={isResetPasswordDialogOpen}
          isResetting={isResetting}
          onDismiss={() => setIsResetPasswordDialogOpen(false)}
          resetPassword={(newPassword) => {
            void resetPassword({
              id: userData.id,
              newPassword,
            });
          }}
        />
      )}

      {isAddUserToClassDialogOpen && selectedPeriodIsActivePeriod && (
        <AddUserToClassDialog
          isOpen={isAddUserToClassDialogOpen}
          onConfirm={addUserToClassGroup}
          onDismiss={() => {
            setIsAddUserToClassDialogOpen(false);
          }}
          userId={userData?.id}
        />
      )}

      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          confirmText={t('deactivate-user-dialog.confirm')}
          content={t('deactivate-user-dialog.confirmation-text', {
            user: `${userData?.firstName} ${userData?.lastName}`,
          })}
          isOpen={isConfirmationDialogOpen}
          onConfirm={() => {
            void disableUser(userData?.id);
            setIsConfirmationDialogOpen(false);
          }}
          onDismiss={() => {
            setIsConfirmationDialogOpen(false);
          }}
          testInfo={{
            confirm: dataTestIds.page.manage.users.dialogs.confirm.deactivate,
            dismiss: dataTestIds.page.manage.users.dialogs.cancel.deactivate,
          }}
          title={t('deactivate-user-dialog.header')}
        />
      )}

      {!isSmartSchoolUser && selectedPeriodIsActivePeriod && (
        <CreateUserDialog
          isLoading={isLoadCreateTeacher || isLoadCreateStudent}
          isOpen={isCreateUserDialogOpen}
          onConfirm={createUser}
          onDismiss={() => setIsCreateUserDialogOpen(false)}
          organization={organization}
          role={userCreateRole}
          setRole={setUserCreateRole}
        />
      )}

      <ConfirmationDialog
        content={t('link-teachers-programs.confirmation')}
        isLoading={isLinkTeachersLoading}
        isOpen={isLinkTeachersModalOpen}
        onConfirm={async () => {
          await linkTeachers();
          setIsLinkTeachersModalOpen(false);
        }}
        onDismiss={() => setIsLinkTeachersModalOpen(false)}
      />

      <ManageUsers
        currentPage={page}
        defaultRoles={defaultRoles}
        enableUser={enableUser}
        isError={isError}
        isLoadingUsers={isLoading}
        itemsPerPage={itemsPerPage}
        onClearFilters={() => {
          navigate(generatePath(URLS.MANAGE));
        }}
        openAddUserToClassModal={() => setIsAddUserToClassDialogOpen(true)}
        openCreateUserModal={() => setIsCreateUserDialogOpen(true)}
        openDisableUserModal={() => setIsConfirmationDialogOpen(true)}
        openResetModal={() => setIsResetPasswordDialogOpen(true)}
        searchParams={searchParams}
        setIsLinkTeachersModalOpen={setIsLinkTeachersModalOpen}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        setSearchParams={(value) => {
          setPage(1);
          setSearchParams(value);
        }}
        setSort={setSort}
        setUserData={setUserData}
        totalItems={totalItems}
        totalPages={totalPages}
        users={users ?? []}
      />
    </>
  );
}
