import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_ENABLE_USER } from 'api/plannerService/schooladmin/mutations/schooladminEnableUser';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminEnableUser() {
  const toast = useToast();
  const t = useFormatMessage();
  const toastId = 'enable-user';

  return useMutation(
    (id) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_ENABLE_USER, {
        id,
      }),
    {
      mutationKey: 'schooladminEnableUser',
      onSuccess: () => {
        toast(TOASTTYPES.DEACTIVATESUCCESS, {
          str: t('global.USER.singular'),
          toastId,
        });
      },
      onerror: () => {
        toast(TOASTTYPES.ACTIVATESUCCESS, {
          str: t('global.USER.singular'),
          toastId,
        });
      },
    },
  );
}
