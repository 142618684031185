import { XMarkIcon } from '@heroicons/react/24/solid';
import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

export function CloseButton({
  defaultClassNames = 'justify-center ml-2 bg-white text-black',
  extraClassNames = '',
  fullWidth = false,
  iconClassNames = 'h-4 w-4',
  onClose,
}) {
  const t = useFormatMessage();

  return (
    <button
      className={c(
        defaultClassNames,
        extraClassNames,
        fullWidth && 'absolute top-0 left-0 right-0 bottom-0 w-full',
      )}
      onClick={onClose}
      title={t('styled-slide-viewer.close')}
      type="button"
    >
      <span className="sr-only">{t('styled-slide-viewer.close')}</span>
      <XMarkIcon className={iconClassNames} />
    </button>
  );
}
