import { gql } from 'graphql-request';

export const SCHOOLADMIN_DISABLE_USER = gql`
  mutation schooladminDisableUser($id: ID!) {
    schooladminDisableUser(id: $id) {
      id
      enabled
    }
  }
`;
