import { Draggable } from 'react-beautiful-dnd';

import { MemberRow } from './MemberRow';

export function DraggableMemberRow({
  draggableId,
  index,
  member,
  memberIndex,
  options,
  setPossibleOptions,
  showRoles,
}) {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <MemberRow
          index={memberIndex}
          member={member}
          options={options}
          provided={provided}
          setPossibleOptions={setPossibleOptions}
          showRoles={showRoles}
        />
      )}
    </Draggable>
  );
}
