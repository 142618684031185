import { useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import {
  Actions,
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import useUserClassgroupManagement from 'hooks/api/plannerService/CRUD/useUserClassgroupManagement';
import useSchooladminUsers from 'hooks/api/plannerService/schooladmin/queries/useSchooladminUsers';
import useFormatMessage from 'hooks/useFormatMessage';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import {
  ArrowPathIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import ftrprfUserRoles from '../../utils/constants/ftrprfUserRoles';

export default function ConfigureUsersModal({
  classGroup,
  isOpenConfigureUsersModal,
  refetchUserOfClassgroups,
  setIsOpenConfigureUsersModal,
}) {
  const [loadingUser, setLoadingUser] = useState();
  const [filterState, setFilterState] = useState([
    {
      key: 'roles',
      value: [ftrprfUserRoles.SCHOOLTEACHER, ftrprfUserRoles.SCHOOLSTUDENT],
      operation: filterOperation.EQUAL,
    },
  ]);
  const SORT_USERS = { firstname: sortOperation.ASC };
  const debouncedFilter = useDebounce(filterState, 200);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const t = useFormatMessage();
  const { add: useAddUserToClassGroup, remove: useRemoveUserFromClassGroup } =
    useUserClassgroupManagement();
  const { mutateAsync: addUserToClassGroupHook } = useAddUserToClassGroup();
  const { mutateAsync: removeUserFromClassGroupHook } =
    useRemoveUserFromClassGroup();
  const { data: usersData, refetch: refetchUsers } = useSchooladminUsers({
    filter: debouncedFilter,
    page: page - 1,
    size: itemsPerPage,
    sort: SORT_USERS,
  });

  const setFilter = (value) => {
    setPage(1);
    setFilterState(value);
  };

  const addUserToClassGroup = (data) => {
    setLoadingUser(data.id);
    addUserToClassGroupHook({
      classGroupId: classGroup.id,
      userId: data.id,
    }).then(() => {
      refetchUserOfClassgroups().then(() => {
        refetchUsers().then(() => {
          setLoadingUser();
        });
      });
    });
  };

  const removeUserFromClassGroup = (data) => {
    setLoadingUser(data.id);
    removeUserFromClassGroupHook({
      classGroupId: classGroup.id,
      userId: data.id,
    }).then(() => {
      refetchUserOfClassgroups().then(() => {
        refetchUsers().then(() => {
          setLoadingUser();
        });
      });
    });
  };

  const totalPages = usersData?.pages;
  const totalItems = usersData?.total;
  const users = usersData?.content ?? [];

  const onDismiss = () => {
    setIsOpenConfigureUsersModal(false);
    setFilter([]);
  };

  const updateFilter = (value, key, operation = filterOperation.LIKE) => {
    const filteredFilter = filterState.filter((item) => item.key !== key);
    const obj = [...filteredFilter, { key, value, operation }];

    setFilter(obj);
  };

  const actionsForUser = (user) => {
    const isAdded = user.classGroups.filter(
      (elem) => elem?.id === classGroup?.id,
    );

    let iconComponent;

    if (String(loadingUser) === String(user.id)) {
      iconComponent = <ArrowPathIcon className="outline-none w-4 h-4" />;
    } else if (isAdded.length !== 0) {
      iconComponent = (
        <UserMinusIcon className="text-red-700 outline-none w-4 h-4" />
      );
    } else {
      iconComponent = <UserPlusIcon className="outline-none w-4 h-4" />;
    }

    return [
      {
        icon: iconComponent,
        onClick: () => {
          isAdded.length !== 0
            ? removeUserFromClassGroup(user)
            : addUserToClassGroup(user);
        },
      },
    ];
  };

  return (
    <Dialog isOpen={isOpenConfigureUsersModal} onDismiss={onDismiss}>
      <DialogHeader className="flex justify-between items-center">
        <span>{`${t('class-group.add-user-to-class-group')}: ${
          classGroup?.name
        }`}</span>
      </DialogHeader>
      <DialogContent>
        <>
          <div className="flex">
            <SearchBar
              className="mb-4 mr-4"
              onChange={(value) => updateFilter(value, 'firstname')}
              placeholder={t('profile.first_name')}
            />
            <SearchBar
              className="mb-4"
              onChange={(value) => updateFilter(value, 'lastname')}
              placeholder={t('profile.last_name')}
            />
          </div>

          <ResponsiveTable
            headers={[
              {
                key: 'name',
                label: t('student-overview.column.name'),
                orderable: false,
              },
              {
                key: 'actions',
                label: t('global.actions'),
                orderable: false,
                className: 'max-w-fit sr-only',
              },
            ]}
            mobileBreakpoint={768}
            mobileHeaderWidth={160}
          >
            {users.map((user) => (
              <ResponsiveTableRow key={user.id} data={user}>
                <ResponsiveTableCol>
                  <span
                    className="flex gap-x-4 items-center"
                    title={user.username}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                </ResponsiveTableCol>
                <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                  <Actions items={actionsForUser(user)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            ))}
          </ResponsiveTable>

          {Boolean(users?.length) && (
            <Pagination
              className="mt-12"
              currentPage={page}
              itemsPerPage={itemsPerPage}
              setCurrentPage={(page) => setPage(page)}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
        </>
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
