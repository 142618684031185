import { useQuery } from 'react-query';

import { TEACHER_STUDENT_EXERCISE } from 'api/portfolioService/queries/teacherStudentExercise';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export const useTeacherStudentExercise = ({
  exerciseVersionId,
  lessonSessionId,
  userId,
}) => {
  const response = useQuery(
    ['teacherStudentExercise', lessonSessionId, exerciseVersionId, userId],
    () =>
      portfolioServiceQueryClient.request(TEACHER_STUDENT_EXERCISE, {
        lessonSessionId,
        exerciseVersionId,
        userId,
      }),
  );

  return {
    ...response,
    data: response?.data?.teacherStudentExercise,
  };
};
