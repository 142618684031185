import { Link } from 'react-router-dom';
import { ArrowDownTrayIcon, LockClosedIcon } from '@heroicons/react/24/outline';

import { dataTestIds } from '../../utils/constants/dataTestIds';
import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';
import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';
import { Badge } from '../Badge';
import { linkFocusStyles } from '../../utils/constants/linkFocusStyles';
import { linkHoverStyles } from '../../utils/constants/linkHoverStyles';

export function SubjectCard({
  color = 'black',
  document = null,
  hasContent = true,
  image = undefined,
  isModule = false,
  link = null,
  tags = null,
  title = null,
}) {
  const t = useFormatMessage();

  let text =
    hasContent && hasContent === 1 && isModule
      ? t('subject-card.module')
      : t('subject-card.lesson');
  if (hasContent && hasContent !== 1 && isModule)
    text = t('subject-card.modules');
  if (hasContent && hasContent !== 1 && !isModule)
    text = t('subject-card.lessons');

  return (
    <div
      className={c(
        'subjectCard flex w-full h-full border hover:border-gray-300 hover:bg-gray-100 group rounded focus:bg-gray-100 overflow-hidden',
        linkFocusStyles,
        hasContent
          ? 'hover:shadow-lg hover:shadow-accent-200/50'
          : 'opacity-50',
      )}
      data-test={dataTestIds.component.subjectCard.self}
    >
      <div
        className="w-20 flex-shrink-0 uppercase flex items-center justify-center text-2xl font-bold border-r"
        style={{
          color,
          background: `url(${image}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        {!image && title?.charAt(0)}
      </div>
      {/* The min-w-0 below is necessary to prevent the flex-box from overflowing. */}
      <div className="w-full min-w-0 flex items-center justify-center pl-4 transition-colors duration-200 relative">
        {title && (
          <div className="flex-grow flex flex-col clamp-2 py-2">
            <h3
              className="text-left text-lg leading-tight clamp-2"
              data-test={dataTestIds.component.subjectCard.text.title}
            >
              {title}
            </h3>
            <div className="text-sm text-gray-400 flex justify-between mb-1">
              {hasContent && typeof hasContent === typeof Number(1) && (
                <div>{`${`${hasContent ? `${hasContent} ` : ''}`}${text}`}</div>
              )}
              {link && (
                <p className="sr-only">
                  <Link className="sr-only" tabIndex={-1} to={link}>
                    {title}
                  </Link>
                </p>
              )}
              {document && (
                <a
                  className={c(
                    'mr-2 ml-2 underline hover:text-accent-500',
                    linkHoverStyles,
                    linkFocusStyles,
                  )}
                  data-test={
                    dataTestIds.component.subjectCard.button.downloadDocument
                  }
                  href={document}
                  onClick={(e) => e.stopPropagation()}
                  rel="noreferrer nofollow"
                  target="_blank"
                >
                  <ArrowDownTrayIcon className="mr-2 w-4 h-4 inline" />
                  {isModule
                    ? t('subject-card.module.download')
                    : t('subject-card.lesson.download')}
                </a>
              )}
            </div>
            {tags && Boolean(tags.length) && (
              <ul
                className="flex gap-1 overflow-x-auto max-w-full"
                data-test={dataTestIds.component.subjectCard.text.badges}
              >
                {tags.map((tag) => (
                  <li key={`title${tag.id}`}>
                    <Badge className="min-w-max" color={tag.color}>
                      {tag.name}
                    </Badge>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="absolute top-0 right-0 flex mr-2 mt-2 transition-colors text-gray-400 hover:text-gray-500 focus:text-gray-500 cursor-pointer">
          {!hasContent && <LockClosedIcon className="text-sm h-5 w-5 mr-2" />}
        </div>
      </div>
    </div>
  );
}

export const SubjectCardTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
