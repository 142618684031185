import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_UPDATE_SEATCOUNT } from 'api/plannerService/admin/mutations/adminUpdateSeatCount';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminUpdateSeatCount() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ maxSeats, organizationId, programId }) =>
      plannerServiceQueryClient.request(ADMIN_UPDATE_SEATCOUNT, {
        input: { organizationId, programId, maxSeats },
      }),
    {
      mutationKey: 'adminUpdateSeatCount',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.PROGRAM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );
}
