// eslint-disable-next-line import/no-cycle
import c from '../../../utils/c';
import { SLIDE_BACKGROUNDS } from '../../styled-slideviewer/partials/slideBackgrounds';
import { SlideViewerCk5Slide } from '../SlideViewerCk5Slide';
import { processor } from '../processor';

export function MultiScreenModeSlide({
  className = '',
  hasBackground = false,
  hasFixedDimensions = true,
  id,
  isCk5Thumbnail = false,
  isFullSlideViewer = false,
  slide,
  test,
  ...props
}) {
  /*
   * The content of open question slides is stored in slide.question.
   * The content of other text & multiple choice question slides is stored in slide.content.
   * To ensure there is no content for the slides both properties need to be checked.
   */
  const slideHasNoContent = !slide?.content && !slide?.question;
  if (slideHasNoContent) {
    return (
      <div
        className="bg-white rounded"
        data-test={test}
        style={{ aspectRatio: '16/9' }}
      />
    );
  }

  return (
    <div
      className={c(
        'multiscreen relative w-full',
        className,
        (slide.background && SLIDE_BACKGROUNDS[slide.background]?.className) ||
          'noBackground',
      )}
      data-test={test}
      id={`slideViewerContentContainer${id}`}
      style={{ aspectRatio: '16/9' }}
    >
      <SlideViewerCk5Slide
        currentSlide={slide}
        hasFixedDimensions={hasFixedDimensions}
        id={id}
        isClickable={false}
        isFullSlideViewer={isFullSlideViewer}
        isThumbnail={isCk5Thumbnail}
        isTransparent={hasBackground}
        shouldCenter={false}
        {...props}
      >
        {processor.processSync(slide.content).result.props.children}
      </SlideViewerCk5Slide>
    </div>
  );
}
