export function MultiScreenModeIcon({ active, classNames }) {
  return (
    <svg
      className={classNames}
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 79.1 67.94"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m75.19,13.67h-13.67V3.91c0-2.15-1.73-3.89-3.88-3.91H3.9C1.74,0,0,1.75,0,3.91v37.59c0,2.16,1.74,3.9,3.9,3.91h6.84v-2H3.9c-1.08-.03-1.95-.91-1.95-2v-2.83h8.79v-2H1.95V3.91c0-1.09.87-1.97,1.95-2h53.74c1.1,0,2,.9,2,2v11.72h15.55c1.1,0,2,.9,2,2v32.66H15.64V17.58c0-1.1.9-2,2-2h39v-2H17.58c-2.16-.01-3.93,1.73-3.94,3.9,0,.03,0,.07,0,.1v37.62c0,2.16,1.75,3.9,3.91,3.9h21.76l-1.52,6.84h-3.61c-.55,0-1,.45-1,1s.45,1,1,1h24.46c.55,0,1-.45,1-1s-.45-1-1-1h-3.66l-1.52-6.84h21.73c2.16,0,3.9-1.74,3.91-3.9V17.58c0-2.16-1.75-3.9-3.91-3.91Zm-22.21,52.25h-13.19l1.52-6.84h10.15l1.52,6.84Zm22.21-8.79H17.58c-1.1,0-2-.9-2-2v-2.88h61.57v2.95c-.04,1.06-.9,1.91-1.96,1.93Z"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          fill: active ? '#3b82f6' : 'white',
          stroke: active ? '#3b82f6' : 'white',
        }}
      />
      <circle
        cx="46.39"
        cy="54.69"
        r=".98"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          fill: active ? '#3b82f6' : 'white',
          stroke: active ? '#3b82f6' : 'white',
        }}
      />
      <path
        d="m18.55,19.53v17.09h2v-16.11h14.09v-2h-15.11c-.55.01-.99.47-.98,1.02,0,0,0,0,0,0Z"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          fill: active ? '#3b82f6' : 'white',
          stroke: active ? '#3b82f6' : 'white',
        }}
      />
    </svg>
  );
}
