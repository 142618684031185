import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { CREATE_LESSSON_CONTENT } from 'api/portfolioService/mutations/createLessonContent';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useCreateLessonContent() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ groupLesson, lessonSessionId, studioId, studioVersion }) => {
      portfolioServiceQueryClient.request(CREATE_LESSSON_CONTENT, {
        input: {
          groupLesson,
          lessonSessionId,
          studioId,
          studioVersion,
        },
      });
    },
    {
      mutationKey: 'createLessonContent',
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('global.CONTENT.singular'),
        });
      },
    },
  );
}
