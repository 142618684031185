import { gql } from 'graphql-request';

export const TEACHER_CREATE_CONTENT_FEEDBACK = gql`
  mutation teacherCreateContentFeedback(
    $file: Upload
    $input: ContentFeedbackInput
  ) {
    teacherCreateContentFeedback(file: $file, input: $input) {
      id
      message
      statusCode
    }
  }
`;
