import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_DEACTIVE_ORGANIZATION } from 'api/plannerService/admin/mutations/adminDeactivateOrganization';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminDeactivateOrganization() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ id }) =>
      plannerServiceQueryClient.request(ADMIN_DEACTIVE_ORGANIZATION, {
        id,
      }),
    {
      mutationKey: 'deactivateOrganization',
      onSuccess: () => {
        toast(TOASTTYPES.DEACTIVATESUCCESS, {
          str: t('global.ORGANIZATION.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.DEACTIVATEERROR, {
          str: t('global.ORGANIZATION.singular'),
        });
      },
    },
  );

  return mutation;
}
