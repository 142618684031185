import { Tooltip } from '../../Tooltip/Tooltip';
import c from '../../../utils/c';
import useFormatMessage from '../../../hooks/useFormatMessage';

export function ProjectionTooltip({ active, children, label }) {
  const t = useFormatMessage();
  let classNames = '';

  if (!active) classNames = 'text-white bg-accent-500 border-none';

  return (
    <Tooltip label={t(label)}>
      <div
        className={c(
          'flex items-center justify-center rounded-full border-2 border-accent-500 text-accent-500 ml-2 h-8 w-8',
          classNames,
        )}
      >
        {children}
      </div>
    </Tooltip>
  );
}
