import { gql } from 'graphql-request';

export const ADMIN_AVAILABLE_REPORTS = gql`
  query adminAvailableReports {
    adminAvailableReports {
      genericReportTypes
      organizationReportTypes
    }
  }
`;
