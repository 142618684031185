// the below suppression is because it is present in the next package.json
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  accessoryMap,
  bodyMap,
  clothingMap,
  eyebrowsMap,
  eyesMap,
  facialHairMap,
  graphicsMap,
  hairMap,
  hatMap,
  mouthsMap,
  theme,
} from '@ftrprf/bigheads-core';

export const bigheadSettings = {
  body: {
    body: bodyMap,
    clothing: clothingMap,
    clothingColor: theme.colors.clothing,
    graphic: graphicsMap,
  },
  face: {
    eyes: eyesMap,
    eyebrows: eyebrowsMap,
    lashes: true,
    faceMask: true,
    faceMaskColor: theme.colors.clothing,
    facialHair: facialHairMap,
    hair: hairMap,
    hairColor: theme.colors.hair,
    hat: hatMap,
    hatColor: theme.colors.clothing,
    mouth: mouthsMap,
    lipColor: theme.colors.lipColors,
  },
  general: {
    accessory: accessoryMap,
    mask: true,
    skinTone: theme.colors.skin,
  },
};
