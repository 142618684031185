import { TbSwitch3 } from 'react-icons/tb';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';

export function ShuffleButton({ className, onClick, textKey }) {
  const t = useFormatMessage();

  return (
    <button
      className={c(
        className,
        'flex items-center py-1 px-2 bg-gray-100 rounded' +
          ' cursor-pointer mr-6',
      )}
      onClick={onClick}
      type="button"
    >
      {t(textKey)}
      <TbSwitch3 className="ml-2" />
    </button>
  );
}
