import { useState } from 'react';
import viewModes from 'utils/constants/viewModes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import useTeacherChapterReport from 'hooks/api/plannerService/teacher/queries/useTeacherChapterReport';
import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import { reportOptions } from 'utils/constants/reportOptions';
import { EmptyState } from 'components/EmptyState';
import inboxEmpty from 'assets/vectors/inbox-empty.svg';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import { Dialog } from '@ftrprf/tailwind-components';
import { useParams } from 'react-router-dom';
import { ChapterItemReportTable } from './ChapterItemReportTable';
import { ChapterItemReportFilters } from './ChapterItemReportFilters';

export function ChapterItemReportContainer() {
  const MAX_ITEMS = 50;
  const [activeViewMode, setActiveViewMode] = useState(viewModes.CLASSICAL);
  const [filterClassGroup, setFilterClassGroup] = useState([]);
  const [activeScoreMode, setActiveScoreMode] = useState(reportOptions.SCORE);
  const [filterStudents, setFilterStudents] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(MAX_ITEMS);
  const [page, setPage] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const debouncedNameFilter = useDebounce(filterStudents, 300);
  const { chapterId } = useParams();

  const { chapterReports, data, isLoading } = useTeacherChapterReport({
    chapterId,
    filter: [
      Boolean(filterClassGroup.length) && {
        key: 'classGroupId',
        value: filterClassGroup,
        operation: filterOperation.EQUAL,
      },
      Boolean(filterStudents) && {
        key: 'fullname',
        value: debouncedNameFilter,
        operation: filterOperation.LIKE,
      },
    ].filter(Boolean),
    page: page - 1,
    size: itemsPerPage,
    sort: { fullname: sortOperation.ASC },
  });

  const { data: classData } = useTeacherClassGroups({
    page: 0,
    size: 100,
    sort: { name: sortOperation.ASC },
  });

  const hasReports = Boolean(chapterReports?.length);

  return (
    <div>
      <div className="w-full mx-auto">
        <ChapterItemReportFilters
          activeScoreMode={activeScoreMode}
          activeViewMode={activeViewMode}
          classGroups={classData?.content}
          filterClassGroup={filterClassGroup}
          filterStudents={filterStudents}
          setActiveScoreMode={setActiveScoreMode}
          setActiveViewMode={setActiveViewMode}
          setFilterClassGroup={setFilterClassGroup}
          setFilterStudents={setFilterStudents}
        />
        {(hasReports || isLoading) && (
          <ChapterItemReportTable
            activeScoreMode={activeScoreMode}
            activeViewMode={activeViewMode}
            chapterReports={chapterReports}
            classGroups={classData?.content}
            currentPage={page}
            data={data}
            isLoading={isLoading}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setPage}
            setDialogContent={setDialogContent}
            setIsDialogOpen={setIsDialogOpen}
            setItemsPerPage={setItemsPerPage}
          />
        )}
        {!hasReports && !isLoading && (
          <EmptyState
            image={inboxEmpty}
            textKey="chapterItemReport.no-results-found"
          />
        )}
        <Dialog
          className="w-1/4"
          isOpen={isDialogOpen}
          onDismiss={() => setIsDialogOpen(false)}
        >
          {dialogContent}
        </Dialog>
      </div>
    </div>
  );
}
