import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Dropdown,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useSchooladminClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroups';
import useSchooladminClassGroupsByUserId from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroupsByUserId';
import useFormatMessage from 'hooks/useFormatMessage';

export default function AddUserToClassDialog({
  isOpen,
  onConfirm: addUser,
  onDismiss: dismiss,
  userId,
}) {
  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { data, isLoading } = useSchooladminClassGroups({
    page: 0,
    size: 10,
  });
  const _classGroups = data?.content;

  const { data: userClassGroupsData } = useSchooladminClassGroupsByUserId({
    userId,
  });

  const itemsIds = userClassGroupsData?.map((item) => item.id);

  const uniqClassGroups = _classGroups?.filter(
    (i) => !itemsIds?.includes(i.id),
  );

  const classGroups = useMemo(
    () =>
      !uniqClassGroups
        ? {}
        : Object.fromEntries(
            uniqClassGroups?.map((classGroup) => [
              classGroup.name,
              {
                key: classGroup.id,
                value: classGroup.name,
                label: classGroup.name,
              },
            ]),
          ),
    [uniqClassGroups],
  );

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(
    (data) => {
      const classGroupData = uniqClassGroups.find(
        (el) => el.name === data.classGroup,
      );
      addUser(classGroupData);

      reset();
      dismiss();
    },
    [uniqClassGroups, addUser, dismiss, reset],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('link-user.to.class-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="addUserToClassDialog_linkedClassGroups">
                {t('add-user-dialog.linked_classgroups')}
              </Label>
              <Controller
                control={control}
                defaultValue={[]}
                name="classGroup"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Dropdown
                    inputId="addUserToClassDialog_linkedClassGroups"
                    onBlur={onBlur}
                    onChange={onChange}
                    options={Object.keys(classGroups)}
                    value={value}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                }}
                type="select"
              />
              {errors.classGroup && (
                <span className="text-red-500 text-xs">
                  {errors.classGroup.message}
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            disabled={isLoading}
            onClick={onDismiss}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={Object.keys(errors).length > 0 || isLoading}
            loading={isLoading}
            type="submit"
          >
            {t('link-user.to.class-dialog.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
