import { useEffect, useState } from 'react';

import useFormatMessage from '../hooks/useFormatMessage';

import { Dialog, DialogActions, DialogContent, DialogHeader } from './dialogs';
import { Dropdown } from './Dropdown';
import { ActionStyledAsButton } from './buttons/ActionStyledAsButton';

export function SendPersonalityTestForm({
  isOpen,
  onDismiss,
  onSubmit: submit,
  options,
}) {
  const t = useFormatMessage();
  const [selectedOption, setSelectedOption] = useState();
  useEffect(() => {
    setSelectedOption(options[0].assessmentProcedureId);
  }, [options]);
  return (
    <Dialog
      className="flex bg-white shadow-base flex-col max-w-min rounded-lg"
      isOpen={isOpen}
      unstyled
    >
      <DialogHeader>{t('personality.test.send')}</DialogHeader>

      <DialogContent className="m-4">
        <Dropdown
          className="pb-2"
          onChange={(item) => {
            setSelectedOption(item);
          }}
          options={options?.map((item) => ({
            value: item.assessmentProcedureId,
            key: item.assessmentProcedureId,
            label: item.name,
          }))}
          value={selectedOption}
        />
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          onClick={() => submit(selectedOption)}
          send
          type="submit"
        >
          {t('personality.test.form.send')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
