import c from '../../utils/c';

export function FieldError({ children, className = '', ...props }) {
  return (
    <span className={c(className, 'text-red-500 text-xs')} {...props}>
      {children}
    </span>
  );
}

FieldError.defaultProps = {
  children: null,
};
