import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_ORGANIZATION_USERS } from 'api/plannerService/teacher/queries/teacherOrganizationUsers';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherOrganizationUsers({
  filter,
  page,
  size,
  sort,
}) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    [`teacherOrganizationUsers`, filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(TEACHER_ORGANIZATION_USERS, {
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.TEACHER.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.teacherOrganizationUsers,
  };
}
