import { useContext, useState } from 'react';

import { UserContext } from 'providers/UserProvider';

import { PeriodsContext } from 'providers/PeriodsProvider';
import { AdminHeader } from './AdminHeader';
import { EmptyHeader } from './EmptyHeader';
import { SchoolAdminHeader } from './SchoolAdminHeader';
import { StudentHeader } from './StudentHeader';
import { TeacherHeader } from './TeacherHeader';
import { PeriodDialog } from './partials/PeriodDialog';
import { CodefeverHeader } from './CodefeverHeader';

export default function Header({ isEmpty }) {
  const { isAdmin, isSchoolAdmin, isTeacher, organization } =
    useContext(UserContext);
  const { isCodefever } = organization;
  const { activePeriod, hasActivePeriod, selectedPeriod } =
    useContext(PeriodsContext);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);

  let RoleHeader = StudentHeader;
  if (isTeacher) {
    RoleHeader = TeacherHeader;
  }
  if (isSchoolAdmin) {
    RoleHeader = SchoolAdminHeader;
  }
  if (isAdmin) {
    RoleHeader = AdminHeader;
  }
  if (isCodefever) {
    RoleHeader = CodefeverHeader;
  }

  if (isEmpty) {
    RoleHeader = EmptyHeader;
  }

  return (
    <>
      <RoleHeader
        choseActivePeriod={isAdmin || selectedPeriod?.id === activePeriod?.id}
        currentPeriod={isAdmin || selectedPeriod}
        hasActivePeriod={hasActivePeriod}
        onPeriodClick={() => setIsPeriodModalOpen(true)}
      />
      {!isAdmin && selectedPeriod && !isCodefever && (
        <PeriodDialog
          isOpen={isPeriodModalOpen}
          onDismiss={() => setIsPeriodModalOpen(false)}
        />
      )}
    </>
  );
}
