import { useQuery } from 'react-query';

import { OPEN_LESSON_CONTENT } from 'api/slideViewerService/queries/openLessonContent';

import { slideViewerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useLessonContent(studioId, sessionId) {
  const info = useQuery(
    ['openLessonContent', studioId],
    () =>
      slideViewerServiceQueryClient.request(OPEN_LESSON_CONTENT, {
        studioId,
      }),
    {
      enabled: !!sessionId,
    },
  );

  return { ...info, data: info?.data?.openLessonContent };
}
