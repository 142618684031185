import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { OPEN_LESSON_EXERCISE } from 'api/slideViewerService/queries/openLessonExercise';

import { slideViewerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useLessonExercise({
  exerciseVersionId,
  restart,
  studioId,
}) {
  const toast = useToast();
  const t = useFormatMessage();

  const info = useQuery(
    ['openLessonExercise', studioId, exerciseVersionId, restart],
    () =>
      slideViewerServiceQueryClient.request(OPEN_LESSON_EXERCISE, {
        studioId,
        exerciseVersionId,
        restart,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.EXERCISE.singular'),
        });
      },
    },
  );

  return { ...info, data: info?.data?.openLessonExercise };
}
