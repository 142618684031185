import Codi from '../images/Codi.svg';
import Aisha from '../images/Aisha.svg';
import Milo from '../images/Milo.svg';
import Freya from '../images/Freya.svg';
import Youssef from '../images/Youssef.svg';
import Bilal from '../images/Bilal.svg';
import Suki from '../images/Suki.svg';
import Chanel from '../images/Chanel.svg';
import Farah from '../images/Farah.svg';
import Paul from '../images/Paul.svg';
import Ramses from '../images/Ramses.svg';

// properties must be unique on their name field, i.e. no 2 properties can be named "Coach codi" for instance
export const CODI = 'Codi';
export const AISHA = 'Aisha';
export const YOUSSEF = 'Youssef';
export const FREYA = 'Freya';
export const MILO = 'Milo';
export const BILAL = 'Bilal';
export const CHANEL = 'Chanel';
export const FARAH = 'Farah';
export const PAUL = 'Paul';
export const RAMSES = 'Ramses';
export const SUKI = 'Suki';

export const coaches = {
  [CODI]: Codi,
  [AISHA]: Aisha,
  [MILO]: Milo,
  [FREYA]: Freya,
  [YOUSSEF]: Youssef,
  [BILAL]: Bilal,
  [CHANEL]: Chanel,
  [FARAH]: Farah,
  [PAUL]: Paul,
  [RAMSES]: Ramses,
  [SUKI]: Suki,
};

export const DEFAULT_ASSISTANT = CODI;

export function determineCoach(coach) {
  let currentCoach;

  if (coach && Object.keys(coaches).includes(coach)) {
    currentCoach = coach;
  } else {
    currentCoach = DEFAULT_ASSISTANT;
  }

  return currentCoach;
}

export function determineCoachIcon(coach) {
  const currentCoach = determineCoach(coach);

  return coaches[currentCoach];
}

export function determineCoachName(t, coach) {
  const currentCoach = determineCoach(coach);

  const translatedName = t('assistant.name', { name: currentCoach });
  return translatedName;
}

function determineCoachImageAltText(t, coach) {
  const currentCoach = determineCoach(coach);

  return t(`assistant.image.alt.${currentCoach.toLowerCase()}`);
}

export function determineCoachAltText(t, coach) {
  const coachName = determineCoachName(t, coach);

  return t(`assistant.image.what`, {
    who: coachName,
    alt: determineCoachImageAltText(t, coach),
  });
}
