import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import useFormatMessage from 'hooks/useFormatMessage';
import { Link } from 'react-router-dom';

export function PagerLink({ children, getLink, items, newIndex, onClick }) {
  const isValidIndex = newIndex >= 0 && newIndex < items.length;
  const t = useFormatMessage();

  return (
    <>
      {isValidIndex && (
        <Link
          className="p-4 flex justify-center content-center"
          onClick={onClick}
          to={{
            pathname: getLink(),
            search: window.location.search,
          }}
        >
          <span className="sr-only">
            {t('global.to')} {items[newIndex].fullName}
          </span>
          {children}
        </Link>
      )}
      {!isValidIndex && (
        <span className="text-neutral-200 p-4 flex justify-center content-center">
          {children}
        </span>
      )}
    </>
  );
}

export function PagerLinkLeft({ getLink, items, newIndex, onClick }) {
  return (
    <PagerLink
      getLink={getLink}
      items={items}
      newIndex={newIndex}
      onClick={onClick}
    >
      <FaChevronLeft className="self-center w-4 h-4" />
    </PagerLink>
  );
}

export function PagerLinkRight({ getLink, items, newIndex, onClick }) {
  return (
    <PagerLink
      getLink={getLink}
      items={items}
      newIndex={newIndex}
      onClick={onClick}
    >
      <FaChevronRight className="self-center w-4 h-4" />
    </PagerLink>
  );
}
