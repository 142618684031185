import { sortBy } from 'lodash-es';
import { StatusBullet, statusBulletStates } from '@ftrprf/tailwind-components';
import ProgressBar, { IMPORT_TASK_THRESHOLDS } from 'components/ProgressBar';
import { calculateProgress } from 'utils/calculateProgress';
import useFormatMessage from 'hooks/useFormatMessage';

export function ImportTaskAmount({ importTask, isExcelImport }) {
  const t = useFormatMessage();

  const { classesProgress, processedUsers, totalUsers } =
    importTask.taskProgress;

  if (isExcelImport) {
    return `${processedUsers} / ${totalUsers}`;
  }

  let totalProcessedClassUsers = 0;
  let totalClassUsers = 0;

  const classesWithPercentages = classesProgress.map((classProgress) => {
    // calculation for total percentage
    totalClassUsers += classProgress?.totalUsers || 0;
    totalProcessedClassUsers += classProgress?.processedUsers || 0;

    // calculation for class percentage
    return {
      ...classProgress,
      percentage: calculateProgress(
        classProgress?.processedUsers || 0,
        classProgress?.totalUsers || 0,
      ),
    };
  });

  const sortedClasses = sortBy(classesWithPercentages, [
    (o) => o.percentage,
    (o) => o.name,
  ]);

  const overallPercentage = calculateProgress(
    totalProcessedClassUsers,
    totalClassUsers,
  );

  return (
    <>
      <ProgressBar
        className="my-4"
        label={t('synchronize.percentage', {
          percentage: overallPercentage,
        })}
        percent={overallPercentage}
        thresholds={IMPORT_TASK_THRESHOLDS}
      />

      <ul>
        {sortedClasses?.map((classProgress, index) => {
          const fractionProcessedUsers = `(${
            classProgress?.processedUsers || '-'
          }/${classProgress?.totalUsers || '-'})`;

          return (
            <li
              // TODO: find a better way to make a unique key
              key={`classProgress_${classProgress.classGroupId}_${index}`} // eslint-disable-line react/no-array-index-key
              className="flex items-center"
            >
              {/* All users are imported */}
              {Boolean(classProgress.totalUsers) &&
                classProgress?.processedUsers === classProgress?.totalUsers && (
                  <StatusBullet status={statusBulletStates.SUCCESS} />
                )}
              {/* Not all users are imported, but some are */}
              {Boolean(classProgress.totalUsers) &&
                Boolean(classProgress?.processedUsers) &&
                classProgress?.processedUsers !== classProgress?.totalUsers && (
                  <StatusBullet status={statusBulletStates.IN_PROGRESS} />
                )}
              {/* Not a single user was imported */}
              {Boolean(classProgress.totalUsers) &&
                !Boolean(classProgress?.processedUsers) && (
                  <StatusBullet status={statusBulletStates.FAILED} />
                )}
              {Boolean(classProgress?.totalUsers) && (
                <span className="ml-2">
                  {classProgress?.name} {fractionProcessedUsers}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}
