export const displayIds = {
  CURRENT_SLIDE: 'currentSlide',
  NEXT_SLIDE: 'nextSlide',
  TEACHER_MANUAL: 'teacherManual',
};

export function getSecondaryDisplay(primaryDisplay) {
  const mainIsCurrentSlide = primaryDisplay === displayIds.CURRENT_SLIDE;
  if (mainIsCurrentSlide) return displayIds.NEXT_SLIDE;

  return displayIds.CURRENT_SLIDE;
}

export function getTertiaryDisplay(primaryDisplay) {
  const mainIsTeacherManual = primaryDisplay === displayIds.TEACHER_MANUAL;
  if (mainIsTeacherManual) return displayIds.NEXT_SLIDE;

  return displayIds.TEACHER_MANUAL;
}
