import { gql } from 'graphql-request';

export const SCHOOLADMIN_CREATE_CLASS_GROUP = gql`
  mutation schooladminCreateClassGroup($name: String!) {
    schooladminCreateClassGroup(name: $name) {
      id
      name
      organizationId
      archived
    }
  }
`;
