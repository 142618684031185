import c from '../../../utils/c';
import { sizesClassNames } from './classNameHelpers';

export function getIconClassNames(size, secondary, variant) {
  const isTextBlue200 =
    (secondary && variant === 'white') ||
    (!secondary && variant !== 'blue' && variant !== 'black');
  const isTextBlue500 =
    variant !== 'black' && variant !== 'blue' && !isTextBlue200;

  return c(
    sizesClassNames[size].icon,
    isTextBlue200 && 'text-blue-200',
    isTextBlue500 && 'text-blue-500',
  );
}
