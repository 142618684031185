import { useEffect, useState } from 'react';

export const useDebounce = (value, milliseconds) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, milliseconds);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value, milliseconds]);

  return debouncedValue;
};
