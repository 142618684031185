import Select from 'react-select';
import { badgeStyles } from '@ftrprf/tailwind-components';

export function BadgeSelect({
  id,
  options,
  placeholder,
  selected,
  setSelected,
}) {
  return (
    <>
      <label className="sr-only" htmlFor={id}>
        {placeholder}
      </label>
      <Select
        classNamePrefix="react-select"
        classNames={{
          multiValue: ({ data }) => {
            const { color } = data;
            return `${
              badgeStyles[color.toUpperCase()]
            } rounded ring-1 ring-inset text-xs font-medium inline-flex mr-2 my-1`;
          },
          multiValueLabel: ({ data }) => {
            const { color } = data;
            return `${badgeStyles[color.toUpperCase()]} px-2 py-1`;
          },
          multiValueRemove: ({ data }) => {
            const { color } = data;
            return `${badgeStyles[color.toUpperCase()]} px-2 py-1`;
          },
        }}
        inputId={id}
        isMulti
        onChange={(values) => {
          setSelected(values);
        }}
        options={options}
        placeholder={placeholder}
        styles={{
          multiValue: () => {},
          multiValueLabel: () => {},
          multiValueRemove: () => {},
          dropdownIndicator: (base) => ({
            ...base,
            color: 'black',
          }),
          input: (provided) => ({
            ...provided,
            '> input': {
              boxShadow: 'none',
            },
            boxShadow: 'none',
          }),
        }}
        value={selected}
      />
    </>
  );
}
