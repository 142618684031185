import { SlideViewerCloseButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function SlideViewerError({ onClose }) {
  const t = useFormatMessage();

  return (
    <div className="flex flex-col w-full h-full items-center justify-center relative">
      <div className="absolute right-2 top-2">
        <SlideViewerCloseButton onClose={onClose} />
      </div>
      <h1 className="text-3xl font-bold mb-2">{t('global.empty-lesson')}</h1>
      <div className="flex divide-x-2 divide-gray-300 items-center justify-center leading-tight">
        {t('slideviewer.error')}
      </div>
    </div>
  );
}
