import useHackRooms from 'hooks/api/plannerService/common/queries/useHackRooms';

import { HackRoom } from './HackRoom';

export function HackRoomContainer() {
  const {
    data: studentHackRoomPrograms,
    isLoading: isLoadingHackRoomPrograms,
  } = useHackRooms();

  return (
    <HackRoom
      hackRoomPrograms={studentHackRoomPrograms}
      isLoadingHackRoomPrograms={isLoadingHackRoomPrograms}
    />
  );
}
