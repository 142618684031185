import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';
import { getStudioIdForLesson } from '../../Calendar/getStudioIdForLesson';

export function postResponse(createdCalendarItem, teacherPrograms, watch) {
  if (createdCalendarItem && teacherPrograms) {
    const { chapter, chapterItem, program, studioId } = watch();
    const { teacherCreateCalendarItem } = createdCalendarItem;
    (() => {
      window.opener.postMessage(
        [
          {
            title: studioId?.label || chapterItem.label,
            url:
              window.location.origin +
              generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
                sessionId: teacherCreateCalendarItem.sessionId,
                studioId: getStudioIdForLesson(
                  teacherPrograms,
                  program?.value,
                  chapter?.value,
                  chapterItem?.value,
                  null,
                  studioId?.value,
                ),
                viewMode:
                  teacherCreateCalendarItem.viewMode ?? viewModes.CLASSICAL,
              }),
          },
        ],
        '*',
      );
      window.close();
    })();
  }
}
