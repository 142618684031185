import c from '../../utils/c';

export function InfoBar({ className, container: _container, text }) {
  const container = _container || ((children) => <div>{children}</div>);

  return (
    <div className="flex w-full bg-accent-50 text-accent-800 border-b border-gray-300">
      {container(
        <div className={c('w-full flex flex-col', className)}>{text}</div>,
      )}
    </div>
  );
}
