export const getTeachers = (events) => {
  const teachers = [];
  const ids = [];

  events.forEach((event) => {
    if (!ids.includes(event.userId)) {
      teachers.push({
        name: `${event.firstName} ${event.lastName}`,
        id: event.userId,
      });
      ids.push(event.userId);
    }
  });

  return teachers;
};
