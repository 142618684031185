import { Content } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import Cloud from 'assets/vectors/error-pages/cloud.svg';
import URLS from 'utils/constants/urls';
import c from 'utils/c';

export function ErrorBase({
  centerHorizontally = true,
  children,
  image,
  isSubtitle = false,
  messages,
  redirectUrl = URLS.ROOT,
  showBackLink = true,
  showMessagesAsList = false,
  statusCode = '',
  title,
}) {
  const t = useFormatMessage();
  const isPrimaryTitle = !isSubtitle;

  const showMessages = Boolean(messages && messages.length > 0);

  return (
    <Content
      centerHorizontally={centerHorizontally}
      className="h-full flex flex-col items-center md:grid md:min-h-full md:grid-rows-[1fr,auto,1fr] md:grid-cols-[max(50%,36rem),1fr]"
    >
      <div aria-hidden="true" className="md:hidden">
        <img alt="" className="inset-0 object-cover py-4 w-64" src={image} />
      </div>
      <div
        className={c(
          'relative lg:w-full max-w-7xl py-12 lg:col-span-2 lg:col-start-1 lg:row-start-2 2lg:my-32',
          centerHorizontally && 'mx-auto',
        )}
        data-test={statusCode}
      >
        <div aria-hidden="true" className="hidden lg:relative lg:block">
          <img
            alt=""
            className="absolute inset-0 object-cover -top-10 w-28 ml-52"
            src={Cloud}
          />
        </div>
        <div className="max-w-lg">
          {statusCode && (
            <p className="text-base font-semibold leading-8 text-indigo-600">
              {statusCode}
            </p>
          )}
          {isPrimaryTitle && (
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6">
              {title}
            </h1>
          )}
          {isSubtitle && (
            <h2 className="mt-4 mb-2 text-2xl font-bold">{title}</h2>
          )}

          {showMessages && (
            <ul className={showMessagesAsList ? 'list-disc list-inside' : ''}>
              {messages.map((message, index) => (
                <li
                  key={`errorMessage-${index + 1}`}
                  className="text-base leading-7 text-gray-600"
                >
                  {message}
                </li>
              ))}
            </ul>
          )}
          {children}

          {showBackLink && (
            <div className="mt-10">
              <a
                className="text-sm font-semibold leading-7 text-indigo-600"
                href={redirectUrl}
              >
                <span aria-hidden="true">&larr;</span>{' '}
                {redirectUrl.length === 1
                  ? t('errors.action.home')
                  : t('errors.back')}
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        aria-hidden="true"
        className="hidden md:block lg:relative lg:col-start-2 lg:row-start-1"
      >
        <img
          alt=""
          className="inset-0 object-cover py-24 w-64 lg:absolute xl:py-12 lg:w-full"
          src={image}
        />
      </div>
    </Content>
  );
}
