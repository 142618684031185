export default {
  "assistant.image.what": "Een afbeelding van {who}, {alt}",

  // alt texts for assistants
  "assistant.image.alt.codi": "een speelse witte cartoonrobot met groene ogen op een zwart scherm. Het draagt een groene hoofdband met het woord 'c0di', en heeft een industriële uitstraling geeft.",
  "assistant.image.alt.aisha": "een vriendelijke afbeelding van een vrouw met een gebruinde huid en blond haar. Ze knipoogt vrolijk, gekleed in een blauwe casual zakelijke blouse, wat een uitnodigende uitstraling creëert.",
  "assistant.image.alt.youssef": "een vrolijke afbeelding van een man met licht gebruinde huid en lichtbruin haar. Hij draagt ronde bril met donkerbruine montuur, een cyaan T-shirt en heeft een nerdy en vrolijke uitdrukking.",
  "assistant.image.alt.milo": "een stoere afbeelding van een man met matig donkere huid en donkerbruin haar. Hij draagt bruine halfcirkelvormige zonnebril met donkerbruine montuur, een blauw T-shirt en heeft een prettige glimlach.",
  "assistant.image.alt.freya": "een beetje gek maar vriendelijk beeld van een lichte vrouw met rood haar. Ze draagt rode lippenstift, een zwart T-shirt en een cyaan muts, wat een unieke en vriendelijke uitstraling geeft.",
  "assistant.image.alt.suki": "een wijze oudere vrouw met grijs haar, rode lippenstift en groene kleding. Ze draagt gele ronde bril, een grijze riem en heeft een kenmerkende stijl met cyaan en grijze accenten. Suki heeft een lichte teint en straalt wijsheid uit.",
  "assistant.image.alt.bilal": "een man met levendige groene kleding, gele ronde bril en een lichtbruine baard. Zijn krullende haar gaat van blond bovenaan naar bruin bij de baard. Bilal draagt rode lippenstift, een zwart T-shirt en heeft een uniek gevoel voor mode.",
  "assistant.image.alt.farah": "een vrouw met violette kleding, roze haarverlengingen en groene ogen. Farah draagt een zwart T-shirt onder haar violette kleding, wat een stijlvolle en kleurrijke uitstraling geeft.",
  "assistant.image.alt.ramses": "een man met donkerbruin krullend haar, zwarte ogen en felroze kleding. Ramses draagt rode lippenstift en een grijs T-shirt onder zijn levendige outfit, wat een gedurfd en opvallend uiterlijk laat zien.",
  "assistant.image.alt.paul": "een man met een gemillimeterd kapsel, donkergroene ogen en witte kleding met cyaan en grijze accenten. Paul draagt rode lippenstift en een grijs T-shirt onder zijn witte kleding, wat een schone en eenvoudige stijl presenteert.",
  "assistant.image.alt.chanel": "een vrouw met rood haar in knotjes, zwarte ogen en blauwe kleding met cyaan en grijze accenten. Chanel draagt rode lippenstift en een grijs T-shirt onder haar blauwe kleding, wat een modieuze en leuke uitstraling laat zien."

};
