import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { STUDENT_CALENDAR_ITEM_BY_ID } from 'api/plannerService/student/queries/studentCalendarItemById';
import { TEACHER_CALENDAR_ITEM_BY_ID } from 'api/plannerService/teacher/queries/teacherCalendarItemById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export const useCalendarItemById = (id) => {
  const toast = useToast();
  const t = useFormatMessage();
  const { isStudent } = useContext(UserContext);

  const gqlQuery = isStudent
    ? STUDENT_CALENDAR_ITEM_BY_ID
    : TEACHER_CALENDAR_ITEM_BY_ID;
  const functionName = isStudent
    ? 'studentCalendarItemById'
    : 'teacherCalendarItemById';

  const info = useQuery(
    [functionName, id],
    () =>
      plannerServiceQueryClient.request(gqlQuery, {
        id,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          toastId: functionName,
          str: t('global.CALENDARITEM.plural'),
        });
      },
    },
  );

  return { ...info, data: info?.data?.[functionName] };
};
