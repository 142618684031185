import { flatMap, sortBy, uniqBy } from 'lodash-es';

export const filteredByName = (objectItems, name) =>
  objectItems.filter(({ name: objectItemName }) =>
    objectItemName.toLowerCase().includes(name.toLowerCase()),
  );

export const filterByTags = (objectItems, tags) => {
  let filteredItems = [...objectItems];

  tags.forEach((tagName) => {
    filteredItems = filteredItems.filter((item) =>
      item.tags.some((tag) => tag.name === tagName),
    );
  });

  return filteredItems;
};

export const getUniqueSortedTags = (objectItems) => {
  const listOfTags = flatMap(objectItems, 'tags');
  const uniqueTags = uniqBy(listOfTags, 'id');

  return sortBy(uniqueTags, 'name');
};
