import { Dropdown } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { reportOptions } from 'utils/constants/reportOptions';

export function ScoreModePicker({
  activeScoreMode,
  availableScoreModes = [reportOptions.SCORE, reportOptions.QUESTIONS],
  setActiveScoreMode,
}) {
  const t = useFormatMessage();
  return (
    <Dropdown
      inputId="scoreModePicker"
      isMulti={false}
      onChange={(e) => {
        setActiveScoreMode(e);
      }}
      options={availableScoreModes.map((scoreMode) => ({
        value: scoreMode,
        label: t(`chapterItemReport.filter.${scoreMode.toLowerCase()}`),
      }))}
      value={activeScoreMode}
    />
  );
}
