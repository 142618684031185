import {
  ActionStyledAsButton,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SaveButton,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { PeriodsContext } from 'providers/PeriodsProvider';
import { useContext, useState } from 'react';

export function PeriodDialog({ isOpen, onDismiss }) {
  const t = useFormatMessage();
  const { periods, selectedPeriod, setSelectedPeriod } =
    useContext(PeriodsContext);

  const [selectedPeriodId, setSelectedPeriodId] = useState(selectedPeriod.id);

  const selectPeriod = (id) => {
    setSelectedPeriodId(id);
  };

  return (
    <Dialog isOpen={isOpen} modal onDismiss={onDismiss}>
      <DialogHeader>{t('periods.selector.select-period')}</DialogHeader>
      <DialogContent>
        <ResponsiveTable
          headers={[
            {
              key: 'name',
              label: t('global.period'),
            },
            {
              key: 'startDate',
              label: t('periods.startDate'),
            },
            {
              key: 'end Date',
              label: t('periods.endDate'),
            },
            {
              key: 'select',
              className: 'sr-only',
            },
          ]}
          onRowClick={selectPeriod}
        >
          {periods?.map((period) => (
            <ResponsiveTableRow key={period.id} data={period.id}>
              <ResponsiveTableCol>
                <div className="flex gap-2">
                  {period.name}
                  {new Date(period.startDate) <= Date.now() &&
                    Date.now() <= new Date(period.endDate) && (
                      <Badge className="w-fit px-2 lowercase">
                        {t('periods.active')}
                      </Badge>
                    )}
                </div>
                <p className="block text-slate-500 whitespace-normal">
                  {period.description}
                </p>
              </ResponsiveTableCol>
              <ResponsiveTableCol>{period.startDate}</ResponsiveTableCol>
              <ResponsiveTableCol>{period.endDate}</ResponsiveTableCol>
              <ResponsiveTableCol>
                <input
                  checked={selectedPeriodId === period.id}
                  name="periodRadio"
                  onChange={() => setSelectedPeriodId(period.id)}
                  type="radio"
                />
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          ))}
        </ResponsiveTable>
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <SaveButton
          disabled={selectedPeriod.id === selectedPeriodId}
          onClick={() => {
            setSelectedPeriod(
              periods.find((period) => period.id === selectedPeriodId),
            );
            onDismiss();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
