import { gql } from 'graphql-request';

export const TEACHER_GROUP_COMPOSITION_BY_ACTIVITY = gql`
  query teacherGroupCompositionByActivity($activity: String!) {
    teacherGroupCompositionByActivity(activity: $activity) {
      id
      activity
      label
      students {
        id
        userName
        firstName
        lastName
        fullName
        displayName
        language
        email
        enabled
        roles
        classGroups {
          id
          name
          archived
          amountOfStudents
        }
        assessment {
          id
          userId
          status
        }
      }
    }
  }
`;
