import { useQuery } from 'react-query';

import { TEACHER_RESULTS_BY_USERID } from 'api/plannerService/teacher/queries/teacherResultsByUserId';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { toast } from '@mobiscroll/react';
import { TOASTTYPES } from 'hooks/useToast';
import useFormatMessage from 'hooks/useFormatMessage';

export default function useTeacherResultsByUserId({
  filter = [],
  page = 0,
  size = 20,
  sort,
  userId,
}) {
  const t = useFormatMessage();
  const response = useQuery(
    ['teacherResultsByUserId', userId, filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(TEACHER_RESULTS_BY_USERID, {
        userId,
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.EXERCISE.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.teacherResultsByUserId,
  };
}
