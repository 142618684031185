import { Logo } from '@ftrprf/tailwind-components';
import { Steps } from 'components/Steps/Steps';
import { UserContext } from 'providers/UserProvider';
import { useContext } from 'react';
import { EmptyState } from 'components/EmptyState';
import { usePopupContext } from 'providers/PopupProvider';
import { LoaderBars } from 'components/LoaderBars';
import useFormatMessage from 'hooks/useFormatMessage';
import { SelectTarget } from './SelectTarget';
import { SelectLesson } from './SelectLesson';
import { SelectDisplay } from './SelectDisplay';

export function PopupContent() {
  const t = useFormatMessage();

  const { organization } = useContext(UserContext);
  const { currentStepIndex, setCurrentStepIndex, steps, teacherPrograms } =
    usePopupContext();

  if (!teacherPrograms) {
    return <LoaderBars message={t('smartschool.popup.lessonsLoading')} />;
  }

  const stepComponents = [
    <SelectTarget />,
    <SelectLesson />,
    <SelectDisplay />,
  ];

  const hasPrograms = Boolean(teacherPrograms?.length);

  return (
    <>
      <Logo className="mb-4" organization={organization} />
      <Steps
        className="mb-4"
        currentStepIndex={currentStepIndex}
        id="use_in_smartschool"
        setCurrentStepIndex={setCurrentStepIndex}
        steps={steps}
      />

      {hasPrograms ? (
        stepComponents[currentStepIndex]
      ) : (
        <EmptyState imageClass="w-32" textKey="smartschool.popup.noPrograms" />
      )}
    </>
  );
}
