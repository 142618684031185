import useFormatMessage from 'hooks/useFormatMessage';

export function AvatarDropdownProperty({ id, map, props, updateProp }) {
  const t = useFormatMessage();

  return (
    <div key={id} className="flex items-center mt-4">
      <label className="font-semibold w-1/3" htmlFor={id}>
        {t(`avatar.prop.${id}`)}
      </label>
      <select
        className="w-2/3 bg-white capitalize rounded border border-gray-400 focus:outline-none focus:border-purple-500 text-base px-4 py-2"
        defaultValue={`${props[id]}`}
        id={id}
        name={id}
        onChange={updateProp}
      >
        {Object.keys(map).map((value) => (
          <option key={value} value={value}>
            {t(`avatar.value.${id}.${value}`)}
          </option>
        ))}
      </select>
    </div>
  );
}
