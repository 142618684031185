export const defaultAvatar = {
  accessory: 'shades',
  body: 'chest',
  circleColor: 'blue',
  clothing: 'shirt',
  clothingColor: 'black',
  eyebrows: 'leftLowered',
  eyes: 'happy',
  faceMask: false,
  faceMaskColor: 'red',
  facialHair: 'none',
  graphic: 'gatsby',
  hair: 'long',
  hairColor: 'blonde',
  hat: 'none5',
  hatColor: 'white',
  lashes: false,
  lipColor: 'green',
  mask: true,
  mouth: 'tongue',
  skinTone: 'dark',
};
