import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export default function DeleteProgramFromOrganizationDialog({
  isOpen,
  removeProgram,
  selectedProgram,
  setIsRemoveDialogOpen,
}) {
  const t = useFormatMessage();
  const { handleSubmit, reset } = useForm();

  const onDismiss = useCallback(() => {
    setIsRemoveDialogOpen(false);
    reset();
  }, [reset, setIsRemoveDialogOpen]);

  const onSubmit = useCallback(() => {
    removeProgram();
    setIsRemoveDialogOpen(false);
    reset();
  }, [removeProgram, reset, setIsRemoveDialogOpen]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('delete-program-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <h3>
                {t('delete-program-dialog.confirmation-text')}{' '}
                {selectedProgram?.name}?
              </h3>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.deleteProgramFromOrganization.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.deleteProgramFromOrganization.button.submit}
            type="submit"
          >
            {t('delete-program-dialog.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
