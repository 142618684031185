import { cloneElement, useRef } from 'react';

import useCloseState from '../hooks/useCloseState';

import c from '../utils/c';

export function DropdownMenu({
  children,
  className = '',
  leftAligned = false,
  trigger,
}) {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useCloseState(ref);

  const hide = () => setIsOpen(false);

  const triggerElement = trigger({ isOpen });

  return (
    <div ref={ref} className={c('relative inline-block', className)}>
      {cloneElement(triggerElement, {
        onClick: () => {
          if (triggerElement.props?.onClick) {
            triggerElement.props.onClick();
          }
          setIsOpen((o) => !o);
        },
      })}
      {isOpen && (
        <ul
          className={c(
            leftAligned ? 'left-0' : 'right-0',
            'text-sm absolute z-30 min-w-full bg-white border-1 border-gray-500 shadow-base whitespace-nowrap' +
              ' rounded p-1 overflow-y-auto max-h-80 max-w-80',
          )}
        >
          {children instanceof Function ? children({ hide }) : children}
        </ul>
      )}
    </div>
  );
}
