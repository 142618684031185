export const dutchAvatar = {
  "avatar.pick.random": "Waag je kans",
  "avatar.prop.accessory": "Accessoire",
  "avatar.prop.body": "Lichaam",
  "avatar.prop.clothing": "Kledij",
  "avatar.prop.clothingColor": "Kleur kleding",
  "avatar.prop.eyebrows": "Wenkbrauwen",
  "avatar.prop.eyes": "Ogen",
  "avatar.prop.faceMask": "Corona-masker",
  "avatar.prop.faceMaskColor": "Kleur corona-masker",
  "avatar.prop.facialHair": "Gezichtsbeharing",
  "avatar.prop.graphic": "Afbeelding",
  "avatar.prop.hair": "Haar",
  "avatar.prop.hairColor": "Haarkleur",
  "avatar.prop.hat": "Hoofddeksel",
  "avatar.prop.hatColor": "Kleur hoofddeksel",
  "avatar.prop.lashes": "Wimpers",
  "avatar.prop.lipColor": "Lipkleur",
  "avatar.prop.mask": "Achtergrond",
  "avatar.prop.mouth": "Mond",
  "avatar.prop.skinTone": "Huidskleur",
  "avatar.section.body": "Lichaams-instellingen",
  "avatar.section.facial": "Gezichts-instellingen",
  "avatar.section.general": "Algemene instellingen",
  "avatar.value.accessory.none": "Geen",
  "avatar.value.accessory.roundGlasses": "Ronde bril",
  "avatar.value.accessory.shades": "Zonnebril",
  "avatar.value.accessory.tinyGlasses": "Kleine bril",
  "avatar.value.body.breasts": "Borsten",
  "avatar.value.body.chest": "Borstkas",
  "avatar.value.clothing.dress": "Jurk",
  "avatar.value.clothing.dressShirt": "Overhemd",
  "avatar.value.clothing.naked": "Naakt",
  "avatar.value.clothing.shirt": "T-shirt",
  "avatar.value.clothing.tankTop": "Tanktop",
  "avatar.value.clothing.vneck": "V-hals",
  "avatar.value.clothingColor.black": "Zwart",
  "avatar.value.clothingColor.blue": "Blauw",
  "avatar.value.clothingColor.green": "Groen",
  "avatar.value.clothingColor.red": "Rood",
  "avatar.value.clothingColor.white": "Wit",
  "avatar.value.eyebrows.angry": "Boos",
  "avatar.value.eyebrows.concerned": "Bezorgd",
  "avatar.value.eyebrows.leftLowered": "Links verlaagd",
  "avatar.value.eyebrows.raised": "Opgeheven",
  "avatar.value.eyebrows.serious": "Serieus",
  "avatar.value.eyes.content": "Tevreden",
  "avatar.value.eyes.dizzy": "Duizelig",
  "avatar.value.eyes.happy": "Gelukkig",
  "avatar.value.eyes.heart": "Hartjes",
  "avatar.value.eyes.leftTwitch": "Schok links",
  "avatar.value.eyes.normal": "Normaal",
  "avatar.value.eyes.simple": "Simpel",
  "avatar.value.eyes.squint": "Scheel",
  "avatar.value.eyes.wink": "Knipoog",
  "avatar.value.faceMaskColor.black": "Zwart",
  "avatar.value.faceMaskColor.blue": "Blauw",
  "avatar.value.faceMaskColor.green": "Groen",
  "avatar.value.faceMaskColor.red": "Rood",
  "avatar.value.faceMaskColor.white": "Wit",
  "avatar.value.facialHair.mediumBeard": "Baard",
  "avatar.value.facialHair.none": "Geen",
  "avatar.value.facialHair.none2": "Geen",
  "avatar.value.facialHair.none3": "Geen",
  "avatar.value.facialHair.stubble": "Stoppels",
  "avatar.value.graphic.codeCosmos": "CodeCosmos",
  "avatar.value.graphic.ftrprf": "FTRPRF",
  "avatar.value.graphic.gatsby": "Gatsby",
  "avatar.value.graphic.graphQL": "GraphQL",
  "avatar.value.graphic.minecraft": "Minecraft",
  "avatar.value.graphic.minecraftPick": "Minecraft Houweel",
  "avatar.value.graphic.none": "Geen",
  "avatar.value.graphic.react": "React",
  "avatar.value.graphic.redwood": "Redwood",
  "avatar.value.graphic.scratch": "Scratch",
  "avatar.value.graphic.vue": "Vue",
  "avatar.value.hair.afro": "Afro",
  "avatar.value.hair.balding": "Kalend",
  "avatar.value.hair.bob": "Bob",
  "avatar.value.hair.bun": "Knot",
  "avatar.value.hair.buzz": "Buzz",
  "avatar.value.hair.long": "Lang",
  "avatar.value.hair.none": "Geen",
  "avatar.value.hair.pixie": "Pixie",
  "avatar.value.hair.short": "Kort",
  "avatar.value.hairColor.black": "Zwart",
  "avatar.value.hairColor.blonde": "Blond",
  "avatar.value.hairColor.blue": "Blauw",
  "avatar.value.hairColor.brown": "Bruin",
  "avatar.value.hairColor.orange": "Oranje",
  "avatar.value.hairColor.pink": "Roze",
  "avatar.value.hairColor.white": "Wit",
  "avatar.value.hat.beanie": "Muts",
  "avatar.value.hat.none": "Geen",
  "avatar.value.hat.none2": "Geen",
  "avatar.value.hat.none3": "Geen",
  "avatar.value.hat.none4": "Geen",
  "avatar.value.hat.none5": "Geen",
  "avatar.value.hat.turban": "Tulband",
  "avatar.value.hatColor.black": "Zwart",
  "avatar.value.hatColor.blue": "Blauw",
  "avatar.value.hatColor.green": "Groen",
  "avatar.value.hatColor.red": "Rood",
  "avatar.value.hatColor.white": "Wit",
  "avatar.value.lipColor.green": "Groen",
  "avatar.value.lipColor.pink": "Roze",
  "avatar.value.lipColor.purple": "Paars",
  "avatar.value.lipColor.red": "Rood",
  "avatar.value.lipColor.turqoise": "Turquoise",
  "avatar.value.mouth.grin": "Grijns",
  "avatar.value.mouth.lips": "Lippen",
  "avatar.value.mouth.open": "Open",
  "avatar.value.mouth.openSmile": "Open lach",
  "avatar.value.mouth.sad": "Triestig",
  "avatar.value.mouth.serious": "Serieus",
  "avatar.value.mouth.tongue": "Tong",
  "avatar.value.skinTone.avatar": "Pandoraans (Avatar)",
  "avatar.value.skinTone.brown": "Bruin",
  "avatar.value.skinTone.black": "Zwart",
  "avatar.value.skinTone.dark": "Donker",
  "avatar.value.skinTone.light": "Licht",
  "avatar.value.skinTone.orc": "Ork",
  "avatar.value.skinTone.pitchBlack": "Donkerzwart",
  "avatar.value.skinTone.red": "Rood",
  "avatar.value.skinTone.yellow": "Geel",
};
