import { useEffect } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import ck4Styles from '!!raw-loader!@ftrprf/tailwind-components/slideviewer-styles.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
import ck5Styles from '!!raw-loader!@ftrprf/tailwind-components/ck5-slideviewer-styles-build.css';

export const useEditorStyleSheet = (ck5) => {
  useEffect(() => {
    if (ck5 !== undefined) {
      const styleElement = document.createElement('style');

      styleElement.innerHTML = ck5 ? ck5Styles : ck4Styles;

      document.head.append(styleElement);

      return () => styleElement.remove();
    }

    return null;
  }, [ck5]);
};
