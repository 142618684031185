import { idp } from './idp';
import DotsCodeFever from '../../assets/dots_codefever.svg';
import DotsCodeCosmos from '../../assets/dots_codecosmos.svg';
import DotsFTRPRF from '../../assets/dots.svg';

export const logos = {
  [idp.CODEFEVER]: DotsCodeFever,
  [idp.CODECOSMOS]: DotsCodeCosmos,
  [idp.FTRPRF]: DotsFTRPRF,
};
