import useFormatMessage from 'hooks/useFormatMessage';
import OnFire from 'assets/vectors/error-pages/onfire.svg';
import { isArray } from 'lodash-es';
import URLS from 'utils/constants/urls';
import { ErrorBase } from './ErrorBase';

export function ErrorOnFire({
  centerHorizontally,
  isSubtitle,
  message,
  redirectUrl = URLS.ROOT,
  showBackLink = true,
  showMessagesAsList = false,
  statusCode = '',
  titleKey = 'global.error',
}) {
  const t = useFormatMessage();
  const title = t(titleKey);
  const messages = isArray(message) ? message : [message];

  return (
    <ErrorBase
      centerHorizontally={centerHorizontally}
      image={OnFire}
      isSubtitle={isSubtitle}
      messages={messages}
      redirectUrl={redirectUrl}
      showBackLink={showBackLink}
      showMessagesAsList={showMessagesAsList}
      statusCode={statusCode}
      title={title}
    />
  );
}
