import { gql } from 'graphql-request';

export const ADMIN_CREATE_CHAPTER = gql`
  mutation adminCreateChapter(
    $programId: Int!
    $input: ChapterInput!
    $file: Upload
  ) {
    adminCreateChapter(programId: $programId, input: $input, file: $file) {
      id
      name
    }
  }
`;
