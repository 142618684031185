import { useContext } from 'react';

import c from '../../utils/c';

import SlideViewerQuestion from '../styled-slideviewer/partials/questions/SlideViewerQuestion';
import { SlideViewerContext } from './SlideViewerContextProvider';
import SlideViewerTextSlide from './SlideViewerTextSlide';

export default function SlideViewerSlide({ className }) {
  const { canEdit, currentSlide, fontSizeIndex, isCk5 } =
    useContext(SlideViewerContext);

  const [question] = currentSlide.questions;

  // if they can edit
  // and if it's not yet submitted
  // or when they can override answers
  const canSubmit = canEdit;

  const ContentComponent = currentSlide.component;

  return (
    <div
      className={c('SlideViewerTextSlide', className)}
      id="slideViewerContentContainer"
      style={isCk5 ? {} : { fontSize: `${1.5 + fontSizeIndex * 0.3}rem` }}
    >
      {!isCk5 && currentSlide.title && (
        <h1 style={{ fontSize: `${2.25 + fontSizeIndex * 0.3}rem` }}>
          {currentSlide.title}
        </h1>
      )}

      {ContentComponent ? (
        <ContentComponent />
      ) : (
        <SlideViewerTextSlide
          hasBackground={
            !!currentSlide.background && !(currentSlide.background === 'white')
          }
          hasFixedDimensions
          value={currentSlide.content || currentSlide.question?.value || ''}
        />
      )}

      {!isCk5 && question && (
        <SlideViewerQuestion
          key={`question-${currentSlide.id}`}
          canSubmit={canSubmit}
          question={question}
        />
      )}
    </div>
  );
}
