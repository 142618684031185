import { gql } from 'graphql-request';

export const TEACHER_UPDATE_QUESTION_SCORE = gql`
  mutation teacherUpdateQuestionScore(
    $lessonSessionId: String!
    $userId: String!
    $input: UpdateQuestionScoreInput!
  ) {
    teacherUpdateQuestionScore(
      lessonSessionId: $lessonSessionId
      userId: $userId
      input: $input
    ) {
      statuscode
      id
      message
    }
  }
`;
