export default {
  'styled-slide-viewer.open_the_sidebar': 'Open de zijbalk',

  'styled-slide-viewer.sidebar.questions_not_saved':
    'De antwoorden op de vragen zullen niet worden opgeslagen',

  'styled-slide-viewer.previous': 'Vorige',
  'styled-slide-viewer.next': 'Volgende',

  'styled-slide-viewer.teacher_manual': 'Handleiding leerkracht',
  'styled-slide-viewer.manual': 'Handleiding',
  'styled-slide-viewer.teacher_manual_empty':
    'Er is geen extra uitleg nodig bij deze slide',

  'styled-slide-viewer.table_of_contents': 'Inhoudsopgave',

  'styled-slide-viewer.steams': 'Steams',
  'styled-slide-viewer.settings': 'Instellingen',
  'styled-slide-viewer.settings.feedback': 'Feedback',
  'styled-slide-viewer.settings.feedback_explanation':
    'Geef feedback op deze slide',

  'styled-slide-viewer.settings.zoom': 'Zoom',
  'styled-slide-viewer.settings.zoom_explanation':
    'Gebruik + en - om de slide in en uit te zoomen',

  'styled-slide-viewer.userGroupInfo': 'Groepsinfo',

  'styled-slide-viewer.toggle.sidebar': 'Toggle sidebar',

  'styled-slide-viewer.view-modes.projection': 'Projectie',
  'styled-slide-viewer.view-modes.selfstudy': 'Zelfstudie',
  'styled-slide-viewer.view-modes.classical': 'Klassikaal',

  'styled-slide-viewer.submit': 'Dien in',
  'styled-slide-viewer.submitted': 'Ingediend',
  'styled-slide-viewer.save': 'Sla op',
  'styled-slide-viewer.saved': 'Opgeslagen',
  'styled-slide-viewer.close': 'Sluit',
  'styled-slide-viewer.no_slides': 'Er zijn geen slides in deze les.',
  'styled-slide-viewer.not_available':
    'Deze slide is of niet beschikbaar voor deze projectiemodus, of bestaat niet.',
  'styled-slide-viewer.not_available_editor':
    'Deze slide bestaat niet voor deze les.',
  'styled-slide-viewer.empty_title': 'geen titel',
  'styled-slide-viewer.submit_answers': 'Antwoorden indienen',
  'styled-slide-viewer.answer_placeholder': 'Typ hier jouw antwoord',
  'student-answers.correct_answer': 'Het juiste antwoord is:',
  'student-answers.no_answer': 'Antwoord niet opgegeven',

  'styled-slide-viewer.example_answer': 'Voorbeeldantwoord',
  'styled-slide-viewer.hints': 'Hints',
  'styled-slide-viewer.hint': 'Hint',
  'styled-slide-viewer.hint-dialog.title': 'Hint',

  'styled-slide-viewer.questions.not_supported_type':
    'De vraag kon niet getoond worden: het type van de vraag wordt niet ondersteunt.',
  'styled-slide-viewer.multiplechoiceanswercard.explanation_less':
    'Toon minder',
  'styled-slide-viewer.multiplechoiceanswercard.explanation_more': 'Toon meer',
  'styled-slide-viewer.feedback.title': 'Wij horen graag jouw feedback',
  'styled-slide-viewer.feedback.target':
    'Over welk soort inhoud gaat jouw feedback?',
  'styled-slide-viewer.feedback.target.error':
    'Gelieve een geldige waarde te kiezen',
  'styled-slide-viewer.feedback.target.placeholder':
    'Gelieve een type te kiezen',
  'styled-slide-viewer.feedback.feedback': 'Wat is je feedback?',
  'styled-slide-viewer.feedback.notify':
    'Hou me op de hoogte over mijn feedback',
  'styled-slide-viewer.feedback.file.label':
    'Wil je een bestand toevoegen om je feedback toe te lichten?',
  'styled-slide-viewer.feedback.file.drag':
    'Sleep een bestand, of zoek in jouw bestanden',
  'styled-slide-viewer.feedback.type.SLIDE': 'Slide',
  'styled-slide-viewer.feedback.type.TEACHER_MANUAL': 'Leraarhandleiding',
  'styled-slide-viewer.feedback.type.EXERCISE': 'Oefening',
  'styled-slide-viewer.feedback.type.WORKING_SHEET': 'Werkblad',
  'styled-slide-viewer.feedback.type.LESSON_DOCUMENT': 'Lesdocument',
  'styled-slide-viewer.feedback.email': 'E-mail',
  'styled-slide-viewer.feedback.email.validate-error': 'Ongeldig e-mailformaat',
  'styled-slide-viewer.feedback.email.required':
    'E-mail is verplicht aangezien je op de hoogte wil blijven',
  'styled-slide-viewer.confirmation.title':
    'Vergeet niet je antwoorden in te dienen!',
  'styled-slide-viewer.confirmation.content':
    'Als je geen antwoord indient, dan krijg je ook geen punten.',
  'styled-slide-viewer.confirmation.confirm': 'Indienen en doorgaan',
  'styled-slide-viewer.confirmation.cancel': 'Doorgaan zonder indienen',

  // question disclosure
  'question.show.answers': 'Toon antwoorden',
  'question.hide.answers': 'Verberg antwoorden',
};
