import { useParams } from 'react-router-dom';

import useSchooladminProgramById from 'hooks/api/plannerService/schooladmin/queries/useSchooladminProgramById';
import useSchooladminPrograms from 'hooks/api/plannerService/schooladmin/queries/useSchooladminPrograms';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import useSchooladminSeatsByProgramId from 'hooks/api/plannerService/schooladmin/queries/useSchooladminSeatsByProgramId';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';
import { getSelectedPeriodIdFromLocalStorage } from 'utils/selectedPeriod';
import ManageProgramUsage from './ManageProgramUsage';

export default function ManageProgramUsageContainerSchoolAdmin() {
  const { filter, programId } = useParams();
  const MAX_PROGRAMS = 500;
  const [classFilter, setClassFilter] = useState([]);
  const [fullName, setFullName] = useState('');
  const [periodId] = useState(getSelectedPeriodIdFromLocalStorage());
  const [uniqueClassGroups, setUniqueClassGroups] = useState([]);

  const {
    data: program,
    isError: isErrorProgram,
    isLoading: isLoadingProgram,
  } = useSchooladminProgramById(programId);

  const {
    data: userCalendarItems,
    isLoading: isLoadingUserCalendarItems,
    refetch: refetchUsage,
  } = useSchooladminSeatsByProgramId(
    programId,
    periodId,
    fullName,
    classFilter.map((group) => group.id),
  );

  const { data: programsData } = useSchooladminPrograms({
    filter: [
      {
        key: 'name',
        value: filter || '',
        operation: filterOperation.LIKE,
      },
    ],
    page: 0,
    size: MAX_PROGRAMS,
    sort: { name: sortOperation.ASC },
  });

  useEffect(() => {
    if (!!userCalendarItems) {
      let allCalendarItems = [];

      for (const item of userCalendarItems) {
        allCalendarItems = allCalendarItems.concat(
          item?.calendarItem.classGroups,
        );
      }

      setUniqueClassGroups(uniqBy(allCalendarItems, 'id'));
    }
  }, [userCalendarItems]);

  useEffect(() => {
    refetchUsage();
  }, [classFilter, fullName, refetchUsage]);

  return (
    <ManageProgramUsage
      classIds={classFilter}
      currentProgram={program}
      filter={filter}
      isBeingSearched={Boolean(fullName) || Boolean(classFilter.length)}
      isErrorProgram={isErrorProgram}
      isLoading={isLoadingProgram || isLoadingUserCalendarItems}
      programs={programsData?.content}
      setClassIds={setClassFilter}
      setFullName={setFullName}
      uniqueClassGroups={uniqueClassGroups}
      userCalendarItems={userCalendarItems}
    />
  );
}
