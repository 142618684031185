export const errorKeys = {
  ACCESS_TOKEN_NOT_FOUND: 'ACCESS_TOKEN_NOT_FOUND',
  EXCEL_IMPORT_GENERAL: 'EXCEL_IMPORT_GENERAL',
  EXCEL_TOO_MANY_USERS: 'EXCEL_TOO_MANY_USERS',
  MICROSOFT_GRAPH_CALL_FAILED: 'MICROSOFT_GRAPH_CALL_FAILED',
  TEAMS_ACCESS_TOKEN_NOT_FOUND: 'TEAMS_EXCEPTION_ACCESS_TOKEN_NOT_FOUND',
  TEAMS_ACCESS_TOKEN_NOT_FOUND_TEMP: 'no valid teams access token',
  EXTERNAL_SYNC_GENERAL: 'EXTERNAL_SYNC_GENERAL',
  SYNC_USER_UPSERTING_FAILED: 'SYNC_USER_UPSERTING_FAILED',
  SYNC_USER_CREATION_FAILED: 'SYNC_USER_CREATION_FAILED',
  USER_MISSING_FIRST_AND_LAST_NAME: 'USER_MISSING_FIRST_AND_LAST_NAME',
  USER_MISSING_FIRST_NAME: 'USER_MISSING_FIRST_NAME',
  USER_MISSING_LAST_NAME: 'USER_MISSING_LAST_NAME',
  USER_MISSING_ISSUER: 'USER_MISSING_ISSUER',
  USER_MISSING_USER_NAME: 'USER_MISSING_USER_NAME',
  USER_MISSING_USER_ID: 'USER_MISSING_USER_ID',
  USER_INVALID_EMAIL: 'USER_INVALID_EMAIL',
  USER_MISSING: 'USER_MISSING',
  USER_MISSING_DISPLAY_NAME: 'USER_MISSING_DISPLAY_NAME',
  USER_MISSING_LANGUAGE: 'USER_MISSING_LANGUAGE',
  USER_MISSING_ORGANIZATION_ID: 'USER_MISSING_ORGANIZATION_ID',
};

export const UNKNOWN_ERROR = 'GENERAL';
