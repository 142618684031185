import { gql } from 'graphql-request';

export const ADMIN_UPDATE_CHAPTER_ITEM = gql`
  mutation adminUpdateChapterItem(
    $id: Int!
    $input: ChapterItemInput!
    $file: Upload
  ) {
    adminUpdateChapterItem(id: $id, input: $input, file: $file) {
      id
      name
      chapterId
      lessons {
        id
      }
    }
  }
`;
