import { createContext, useCallback, useContext, useMemo } from 'react';
import axios from 'axios';
import msalInstance from 'msalInstance';

import useAdminAvailableReports from 'hooks/api/plannerService/admin/queries/useAdminAvailableReports';
import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { getToken } from 'api';

import { getLongYearMonthDay } from 'utils/timeHelpers';
import { UserContext } from './UserProvider';

const FileDownload = require('js-file-download');

export const ReportsContext = createContext({});

export function ReportsProvider({ children }) {
  const { isAdmin } = useContext(UserContext);
  const { data: availableReports } = useAdminAvailableReports(isAdmin);
  const toast = useToast();
  const t = useFormatMessage();

  const download = useCallback(
    async (type, organisation) => {
      const token = await getToken(msalInstance);

      axios({
        url: `${process.env.REACT_APP_PLANNER_SERVICE_REST_URL}reports/downloadReport`,
        method: 'GET',
        responseType: 'blob',
        params: { reportType: type, organizationId: organisation },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          FileDownload(
            response.data,
            `${type}${
              organisation ? `_${organisation}` : ''
            }_${getLongYearMonthDay(new Date())}.xlsx`,
          );
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast(TOASTTYPES.NOTFOUND, {
              str: `${t('global.THEH')} ${t('global.FILE.singular')}`,
            });
          } else {
            toast(TOASTTYPES.GETERROR, {
              str: `${t('global.THEH')} ${t('global.FILE.singular')}`,
            });
          }
        });
    },
    [t, toast],
  );

  const value = useMemo(
    () => ({
      availableReports,
      download,
    }),
    [availableReports, download],
  );

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
}
