import { gql } from 'graphql-request';

export const ADMIN_MOVE_LESSON = gql`
  mutation adminMoveLesson($studioId: Int!, $toOrganizationId: Int!) {
    adminMoveLesson(studioId: $studioId, toOrganizationId: $toOrganizationId) {
      statusCode
      message
    }
  }
`;
