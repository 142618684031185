import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import { NOTIFICATIONS } from 'api/plannerService/common/queries/notifications';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useNotifications({
  filter,
  page = 0,
  showAll = true,
  size,
  sort,
}) {
  const { initialToken } = useContext(UserContext);

  const response = useQuery(
    ['notifications', showAll, filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(NOTIFICATIONS, {
        showAll,
        filter,
        page,
        size,
        sort,
      }),
    {
      enabled: !!initialToken,
    },
  );

  return {
    ...response,
    data: response?.data?.notifications,
  };
}
