/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */
export const prismThemes = {
  twilight: {
    name: 'Twilight',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/twilight.css'),
  },
  vs: {
    name: 'VS Code light',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/vs.css'),
  },
  vsDark: {
    name: 'VS Code dark',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/vs-dark.css'),
  },
  darcula: {
    name: 'Darcula',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/darcula.css'),
  },
  a11yDark: {
    name: 'a11y Dark',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/a11y-dark.css'),
  },
  dracula: {
    name: 'Dracula',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/dracula.css'),
  },
  coldarkCold: {
    name: 'Coldark Cold',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/coldark-cold.css'),
  },
  coldarkDark: {
    name: 'Coldark dark',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/coldark-dark.css'),
  },
  gruvboxLight: {
    name: 'Gruvbox light',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/gruvbox-light.css'),
  },
  gruvboxDark: {
    name: 'Gruvbox dark',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/gruvbox-dark.css'),
  },
  nightOwl: {
    name: 'Night owl',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/night-owl.css'),
  },
  holi: {
    name: 'Holi',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/holi.css'),
  },
  laserWave: {
    name: 'Laser wave',
    styles: require('!!raw-loader!@ftrprf/tailwind-components/src/components/slideviewer/prism-themes/laserwave.css'),
  },
};

export const prismPluginThemes = {
  diffHighlight: {
    name: 'DiffHighlight',
    styles: require('!!raw-loader!prismjs/plugins/diff-highlight/prism-diff-highlight.min.css'),
  },
  lineHighlight: {
    name: 'LineHighlight',
    styles: require('!!raw-loader!prismjs/plugins/line-highlight/prism-line-highlight.min.css'),
  },
  lineNumbers: {
    name: 'LineNumbers',
    styles: require('!!raw-loader!prismjs/plugins/line-numbers/prism-line-numbers.min.css'),
  },
  matchBraces: {
    name: 'MatchBraces',
    styles: require('!!raw-loader!prismjs/plugins/match-braces/prism-match-braces.min.css'),
  },
  toolbar: {
    name: 'Toolbar',
    styles: require('!!raw-loader!prismjs/plugins/toolbar/prism-toolbar.min.css'),
  },
};
