import { gql } from 'graphql-request';

export const IMPERSONATE_USER = gql`
  mutation impersonateUser($id: String!) {
    impersonateUser(id: $id) {
      message
      id
      statusCode
    }
  }
`;
