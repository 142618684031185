import { useMsal } from '@azure/msal-react';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import URLS from 'utils/constants/urls';

export default function CodeFeverLogout() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const logout = () => {
    instance.logoutPopup().then(() => {
      navigate(generatePath(URLS.CODEFEVER) + window.location.search);
    });
  };

  useEffect(() => {
    // logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="text-center pt-8">
        We loggen je even opnieuw in. <br />
        <span className="text-slate-600 text-sm">
          Zie je deze pagina langer dan 5 seconden? Klik dan even op de knop
          hieronder.
        </span>{' '}
        <br />
        <ActionStyledAsButton
          className="mt-8"
          onClick={() => {
            logout();
          }}
        >
          Opnieuw inloggen
        </ActionStyledAsButton>
      </div>
    </div>
  );
}
