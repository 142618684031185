import { useState } from 'react';
import {
  ChatBubbleBottomCenterTextIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

export function DisclosureFeedback({ children, hasFeedback, test, title }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const openSummary = (e) => {
    e.stopPropagation();
    toggleOpen();
  };

  return (
    <details className="focus-visible:outline focus-visible:outline-blue-500 focus-visible:outline-4 focus-visible:outline-offset-2 focus-within:border-none details mt-4">
      <summary
        className="w-fit hover:bg-neutral-200 list-none rounded-full mt-4 p-3"
        data-test={test}
        onClick={openSummary}
      >
        {/* note: this can't be a div because a summary only accepts phrasing content (and h-elements), not flow content */}
        <span className="sr-only">{title}</span>
        {!open ? (
          <ChatBubbleBottomCenterTextIcon
            aria-hidden="true"
            className={`w-6 h-6 ${hasFeedback ? 'text-blue-700' : ''}`}
          />
        ) : (
          <XMarkIcon
            aria-hidden="true"
            className={`w-6 h-6 ${hasFeedback ? 'text-blue-700' : ''}`}
          />
        )}
      </summary>
      <div className="flex gap-4 items-start">
        <ChatBubbleBottomCenterTextIcon
          aria-hidden="true"
          className={`w-6 h-6 mt-4 ${hasFeedback ? 'text-blue-700' : ''}`}
        />
        {children}
      </div>
    </details>
  );
}
