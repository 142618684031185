import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import useTeacherOrganizationContent from 'hooks/api/plannerService/teacher/queries/useTeacherOrganizationContent';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { filterOperation } from 'utils/constants/filter';
import URLS from 'utils/constants/urls';
import { StudioButton } from './partials/StudioButton';
import { OwnLessonsOverview } from './OwnLessonsOverview';

export default function TeacherOwnLessonsOverviewContainer() {
  const t = useFormatMessage();

  const [filterLessons, setFilterLessons] = useState('');
  const [pageLessons, setPageLessons] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const debouncedFilterLessons = useDebounce(filterLessons, 200);

  const user = useContext(UserContext);
  const { organizationid } = user;
  const organizationId = organizationid;

  const { data: classGroupsData } = useTeacherClassGroups({
    page: 0,
    size: 500,
  });

  const {
    data,
    isLoading,
    refetch: refetchLessons,
  } = useTeacherOrganizationContent({
    filter: [
      {
        key: 'organizationId',
        operation: filterOperation.EQUAL,
        value: `${organizationId}`,
      },
      {
        key: 'title',
        operation: filterOperation.LIKE,
        value: debouncedFilterLessons,
      },
      // todo: uncomment once https://codefever.atlassian.net/browse/TR-2707 is done
      // {
      //   key: 'status',
      //   operation: filterOperation.NOT_EQUAL,
      //   value: lessonStatus.ARCHIVED
      // }
    ],
    page: pageLessons - 1,
    size: itemsPerPage,
  });

  const breadcrumbsLinks = [
    { href: URLS.LEARN, name: t('curricula.overview') },
    { name: t('curricula.lessons.own') },
  ];

  useEffect(() => {
    void refetchLessons();
  }, [debouncedFilterLessons, refetchLessons, pageLessons]);

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        button={<StudioButton />}
        buttonOnTitleRow
        title={t('curricula.lessons.own')}
      />
      <OwnLessonsOverview
        classGroupsData={classGroupsData}
        currentPage={pageLessons}
        data={data}
        isFiltering={!!filterLessons.length}
        isLoading={isLoading}
        itemsPerPage={itemsPerPage}
        ownContent
        setFilter={(value) => {
          setPageLessons(1);
          setFilterLessons(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPageLessons}
      />
    </>
  );
}
