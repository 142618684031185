import useFormatMessage from 'hooks/useFormatMessage';
import { Tooltip } from '@ftrprf/tailwind-components';
import { getReadableDateTime } from '../../CalendarItemResults/partials/getReadableDateTime';

export function Scores({
  bulletColour,
  numberOfCalendarItems = 1,
  report,
  showDateInLabel = false,
  showDateInTooltip = false,
}) {
  const t = useFormatMessage();
  const {
    questionScoresEarned,
    questionScoresMax,
    questionsToGrade,
    startTime,
    viewMode,
  } = report;

  const thereAreQuestionsToGrade = questionsToGrade > 0;
  const hasMultipleQuestions = numberOfCalendarItems > 1;
  const showTooltip =
    showDateInTooltip || thereAreQuestionsToGrade || hasMultipleQuestions;
  const questionsToGradeLabel = t('chapterItemReport.tooltip.to_grade', {
    amountToGrade: questionsToGrade,
  });
  const dateLabel = t(
    `chapterItemReport.tooltip.multiple_no_grade_${viewMode.toLowerCase()}`,
    { startTime: getReadableDateTime(startTime, t) },
  );

  /*
   * This is the default, as the label is only shown when there's a tooltip, which is:
   * - when there are questionsToGrade
   * - when showDateInTooltip is true
   * Given that the second case overwrites the label, the first can be default.
   */
  let scoreModeLabel = questionsToGradeLabel;

  if (showDateInTooltip) {
    scoreModeLabel = `${dateLabel}${
      thereAreQuestionsToGrade ? `: ${questionsToGradeLabel}` : ''
    }`;
  }

  const multipleQuestionsLabel = t(
    'chapterItemReport.tooltip.multiple_questions',
  );

  let scoreModeText = `${questionScoresEarned}/${questionScoresMax}`;

  if (showDateInLabel) {
    scoreModeText = `${dateLabel}: ${questionScoresEarned}/${questionScoresMax}${
      thereAreQuestionsToGrade ? `, ${questionsToGradeLabel}` : ''
    }`;
  }

  if (showTooltip) {
    return (
      <Tooltip
        label={hasMultipleQuestions ? multipleQuestionsLabel : scoreModeLabel}
      >
        <span
          className={
            (thereAreQuestionsToGrade || hasMultipleQuestions) &&
            !showDateInLabel
              ? 'flex gap-1'
              : ''
          }
        >
          <span className="hover:underline-offset-4 hover:underline hover:decoration-2">
            {scoreModeText}
          </span>
          {(thereAreQuestionsToGrade || hasMultipleQuestions) &&
            !showDateInLabel && (
              <span
                className={`rounded-full ${bulletColour} text-white text-xs w-3 h-3 flex justify-center items-center font-bold p-1 hover:decoration-0`}
              >
                {thereAreQuestionsToGrade && !hasMultipleQuestions && '!'}
                {hasMultipleQuestions && numberOfCalendarItems}
              </span>
            )}
        </span>
      </Tooltip>
    );
  }

  return <span>{scoreModeText}</span>;
}
