import { useContext } from 'react';

import en from './translations/en';
import fr from './translations/fr';
import nl from './translations/nl';
import es from './translations/es';
import de from './translations/de';

import c from '../../utils/c';

import { Button } from '../buttons/Button';
import SpeechBubble from './partials/SpeechBubble';
import { Chat } from './partials/Chat';
import { CoachImage } from './partials/CoachImage';
import { SlideViewerContext } from '../slideviewer/SlideViewerContextProvider';
import { dataTestIds } from '../../utils/constants/dataTestIds';

export function Assistant({
  chatLayout = false,
  isFacingRight = false,
  isInCorner = true,
  text,
}) {
  // The default values below are because the Assistant is used in Scratch as well.
  // There is no contextProvider there, so we need default values for the two things which might break it.
  const {
    coachCodiIsOpen: isOpen = true,
    currentSlide,
    lesson,
    setCoachCodiIsOpen: setIsOpen = () => {},
  } = useContext(SlideViewerContext);
  const showAlert =
    !isOpen && (currentSlide?.motivation || currentSlide?.hints?.length);

  return (
    <div
      className={c(
        'z-20 m-1',
        isInCorner
          ? 'absolute bottom-0 overflow-hidden'
          : 'flex items-center flex-wrap gap-2',
        isFacingRight ? 'left-0 flex-row-reverse' : 'right-0',
      )}
    >
      {chatLayout ? (
        <Chat
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
          slidesUntilNow={[currentSlide]}
        />
      ) : (
        <div
          className={c(
            'space-y-2',
            isInCorner && '-mb-10 min-h-[3rem]',
            isInCorner && isFacingRight && 'ml-32',
            isInCorner && !isFacingRight && 'mr-32',
          )}
        >
          {text && (
            <SpeechBubble
              isFacingRight={isFacingRight}
              isInline={!isInCorner}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              text={text}
            />
          )}
        </div>
      )}

      <Button
        className={c(
          'w-28 p-2 relative',
          isFacingRight ? 'float-left' : 'float-right',
        )}
        disabled={chatLayout ? false : !text}
        disabledClassName="cursor-default"
        onClick={() => setIsOpen((prev) => !prev)}
        test={dataTestIds.component.assistant.closedButton}
      >
        {Boolean(showAlert) && (
          <span className="absolute top-2 right-4 z-20 bg-red-600 rounded-full w-4 h-4" />
        )}
        <CoachImage coach={lesson?.coach} isFacingRight={isFacingRight} />
      </Button>
    </div>
  );
}

export const AssistantTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
