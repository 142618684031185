import { ReactComponent as LoaderSvg } from 'assets/vectors/logo-animated-lines.svg';

export function LoaderBars({ message = '' }) {
  return (
    <div className="p-4 w-full flex flex-col justify-center items-center">
      <p>{message}</p>
      <LoaderSvg className="w-32 p-8" />
    </div>
  );
}
