import useFormatMessage from '../../hooks/useFormatMessage';

export function ErrorMessage({ description, error, title }) {
  const t = useFormatMessage();

  if (!error) {
    return null;
  }

  return (
    <div className="flex bg-white border border-gray-300 p-4 rounded my-4">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <svg
          aria-hidden="true"
          className="h-6 w-6 text-red-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title || t('message.error-message.title')}
        </h3>
        <div className="mt-1">
          {description && <p className="text-gray-600">{description}</p>}
          {error?.message && <p className="text-gray-600">{error?.message}</p>}
        </div>
      </div>
    </div>
  );
}
