/* eslint-disable react/jsx-props-no-spreading */
/* prop spreading needed for react-hook-form */
import { forwardRef } from 'react';

import c from '../../utils/c';

import PasswordInput from './PasswordInput';

const commonClassnames = (disabled) =>
  c(
    'outline-none appearance-none w-full px-2 py-1.5 border border-gray-300 placeholder-gray-500 rounded shadow-form',
    disabled && 'opacity-50 cursor-not-allowed',
  );

export const Input = forwardRef(
  (
    {
      a11y = true,
      a11yClassNames = '',
      a11yErrorClassNames = '',
      className = '',
      disabled = false,
      hasErrors = false,
      type = 'text',
      ...props
    },
    ref,
  ) => {
    const base11yClasses =
      'hover:outline hover:outline-accent-500 hover:outline-2 focus:outline focus:outline-accent-500 focus:outline-2';
    const a11yClasses = a11yClassNames || base11yClasses;
    const a11yErrorClasses =
      a11yErrorClassNames || base11yClasses.replaceAll('accent', 'red');

    const classNames = c(
      commonClassnames(disabled),
      className,
      hasErrors && 'border-2 border-red-500',
      a11y && !hasErrors && a11yClasses,
      a11y && hasErrors && a11yErrorClasses,
    );

    if (type === 'multiline') {
      return (
        <textarea
          ref={ref}
          className={classNames}
          {...props}
          disabled={disabled}
        />
      );
    }

    if (type === 'password') {
      return (
        <PasswordInput
          ref={ref}
          className={classNames}
          {...props}
          disabled={disabled}
        />
      );
    }

    return (
      <input
        ref={ref}
        className={classNames}
        type={type}
        {...props}
        disabled={disabled}
      />
    );
  },
);
