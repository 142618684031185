import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { UPDATE_OWN_USER_UI_SETTINGS } from 'api/plannerService/common/mutations/updateOwnUserUiSettings';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUpdateOwnUserUiSettings() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    (uiSettings) =>
      plannerServiceQueryClient.request(UPDATE_OWN_USER_UI_SETTINGS, {
        uiSettings: JSON.stringify(uiSettings),
      }),
    {
      mutationKey: 'updateOwnUserUiSettings',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('header.uiSettings'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('header.uiSettings'),
        });
      },
    },
  );
}
