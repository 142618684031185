export default {
  "assistant.image.what": "An image of {who}, {alt}",

  // alt texts for assistants
  "assistant.image.alt.codi": "a playful white cartoon robot with green eyes on a black screen. It wears a green headband with the word 'c0di', and has an industrial look.",
  "assistant.image.alt.aisha": "a friendly image of a woman with tan skin and blonde hair. She happily winks, dressed in a blue casual business blouse, creating an inviting appearance.",
  "assistant.image.alt.youssef": "a cheerful image of a man with slightly tan skin and light brown hair. He wears round glasses with dark brown frames, a cyan t-shirt, and has a nerdy and happy expression.",
  "assistant.image.alt.milo": "a cool image of a man with moderately dark skin and dark brown hair. He wears brown semi-circular sunglasses with dark brown frames, a blue t-shirt, and has a pleasant smile.",
  "assistant.image.alt.freya": "a bit goofy but kind image of a fair-skinned woman with red hair. She wears red lipstick, a black t-shirt, and a cyan beanie, creating a unique and friendly appearance.",
  "assistant.image.alt.suki": "a wise older woman with gray hair, red lipstick, and green clothing. She wears yellow round glasses, a gray belt, and has a distinctive style with cyan and gray accents. Suki has a fair complexion and exudes wisdom.",
  "assistant.image.alt.bilal": "a man with vibrant green clothing, yellow round glasses, and a light brown beard. His curly hair transitions from blond on top to brown at the beard. Bilal wears red lipstick, a black t-shirt, and has a unique fashion sense.",
  "assistant.image.alt.farah": "a woman with violet clothing, pink hair extensions, and green eyes. Farah wears a black t-shirt under her violet clothing, creating a stylish and colorful appearance.",
  "assistant.image.alt.ramses": "a man with dark brown curly hair, black eyes, and bright pink clothing. Ramses wears red lipstick and a gray t-shirt under his vibrant attire, showcasing a bold and distinctive look.",
  "assistant.image.alt.paul": "a man with a buzz-cut, dark green eyes, and white clothing with cyan and gray accents. Paul wears red lipstick and a gray t-shirt under his white attire, presenting a clean and simple style.",
  "assistant.image.alt.chanel": "a woman with red hair in buns, black eyes, and blue clothing with cyan and gray accents. Chanel wears red lipstick and a gray t-shirt under her blue clothing, displaying a fashionable and fun appearance."
};
