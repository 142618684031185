import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Loader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function ArchiveClassGroupDialog({
  archiveClassGroup,
  isLoading,
  isOpen,
  selectClass,
  setIsArchiveModalOpen,
  setSelectClass,
}) {
  const t = useFormatMessage();

  const onDismiss = () => {
    setIsArchiveModalOpen(false);
    setSelectClass(null);
  };

  return (
    <Dialog
      className={isLoading && 'flex justify-center items-center'}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      {isLoading ? (
        <div className="h-8 w-8 my-5">
          <Loader />
        </div>
      ) : (
        <>
          <DialogHeader>{`${t('delete-class.group-dialog.header')} ${
            selectClass?.name
          }`}</DialogHeader>
          <DialogContent>
            <p>
              {t('delete-class.group-dialog.content')}{' '}
              <span className="italic underline">{selectClass?.name}</span> ?
            </p>
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton onClick={onDismiss} variant="text">
              {t('global.close')}
            </ActionStyledAsButton>
            <ActionStyledAsButton onClick={() => archiveClassGroup(onDismiss)}>
              {t('global.archive')}
            </ActionStyledAsButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
