import { useState } from 'react';
import * as sanitizeHtml from 'sanitize-html';

import useFormatMessage from 'hooks/useFormatMessage';

import { AnswerToMultipleChoiceQuestion } from './AnswerToMultipleChoiceQuestion';
import { AnswerToOpenQuestionForStudent } from './AnswerToOpenQuestionForStudent';

export function QuestionItemForStudent({ index, question }) {
  const t = useFormatMessage();
  const { earned, feedback, max, questionTitle: title, type } = question;
  const [hasFeedback] = useState(Boolean(feedback));

  return (
    <li
      className={`${
        index !== 0 ? 'border-t border-t-neutral-100 ' : ''
      } border-b border-b-neutral-100 py-8`}
    >
      <article className="flex mb-6">
        <div className="mr-8 text-right w-[7rem] min-w-[7rem]">
          <div
            className="text-neutral-400 uppercase whitespace-nowrap"
            data-test="test"
          >
            {t('student-answers.question_label')} {index + 1}
          </div>
          <div className="flex flex-col">
            <div className="mt-6 flex items-center justify-end">
              {earned || 0} / {max}
            </div>
          </div>
        </div>
        <div className="grow">
          <h2
            className="font-semibold"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
          />
          {type === 'OPEN' && (
            <AnswerToOpenQuestionForStudent
              answer={question.userAnswer}
              exampleAnswer={question?.exampleAnswer}
            />
          )}
          {type === 'MULTIPLE_CHOICE' && (
            <AnswerToMultipleChoiceQuestion
              answers={question?.answers}
              questionIndex={index}
              userAnswers={question?.userAnswers}
            />
          )}
          {hasFeedback && (
            <div className="mt-8">
              <h3 className="font-semibold mb-6">Feedback leerkracht</h3>
              <p>{feedback}</p>
            </div>
          )}
        </div>
      </article>
    </li>
  );
}
