import { formatDateDDMonthYYYY, getHoursAndMinutes } from 'utils/timeHelpers';

export function getReadableDateTime(dateString, t) {
  if (!dateString) return '';

  const date = new Date(dateString);
  const formattedDate = formatDateDDMonthYYYY(date);
  const formattedHour = getHoursAndMinutes(date).replace(
    ':',
    t('global.hourAbbreviation'),
  );

  return `${formattedDate} ${t('global.at')} ${formattedHour}`;
}
