import {
  ContentFlowTranslations,
  DemoInfoBarTranslations,
  DialogTranslations,
  GeneralTranslations,
  MessageTranslations,
  NotificationTranslations,
  ResponsiveTableTranslations,
  StyledSlideViewerTranslations,
  SubjectCardTranslations,
  AssistantTranslations
} from '@ftrprf/tailwind-components';

// Add external translations in the array below
const externalTranslations = [
  DemoInfoBarTranslations,
  DialogTranslations,
  NotificationTranslations,
  ContentFlowTranslations,
  StyledSlideViewerTranslations,
  ResponsiveTableTranslations,
  MessageTranslations,
  SubjectCardTranslations,
  GeneralTranslations,
  AssistantTranslations
];

export const externalEn = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.en),
);

export const externalNl = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.nl),
);

export const externalFr = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.fr),
);
