import { AvatarSection } from './AvatarSection';
import { AvatarBooleanProperty } from './AvatarBooleanProperty';
import { AvatarDropdownProperty } from './AvatarDropdownProperty';
import { shouldReturnNullForFacialProperties } from './shouldReturnNullForFacialProperties';
import useFormatMessage from '../../../../../hooks/useFormatMessage';

export function AvatarFacialSettings({
  props,
  section,
  updateBooleanProp,
  updateProp,
}) {
  const t = useFormatMessage();

  return (
    <AvatarSection name={t('avatar.section.facial')}>
      {Object.entries(section).map(([key, map]) => {
        if (typeof map === 'boolean') {
          return (
            <AvatarBooleanProperty
              key={key}
              id={key}
              selected={props[key]}
              updateProp={updateBooleanProp}
            />
          );
        }

        if (shouldReturnNullForFacialProperties(key, props)) return null;

        return (
          <AvatarDropdownProperty
            key={key}
            id={key}
            map={map}
            props={props}
            updateProp={updateProp}
          />
        );
      })}
    </AvatarSection>
  );
}
