import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_PROGRAMS } from 'api/plannerService/schooladmin/queries/schooladminPrograms';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminPrograms({ filter, page, size, sort }) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminPrograms', filter, page, size, sort],
    () => {
      const schooladminPrograms = plannerServiceQueryClient.request(
        SCHOOLADMIN_PROGRAMS,
        {
          filter,
          page,
          size,
          sort,
        },
      );
      return schooladminPrograms;
    },
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.plural'),
        });
      },
      keepPreviousData: true,
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminPrograms,
  };
}
