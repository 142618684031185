import { StatusBullet, statusBulletStates } from '@ftrprf/tailwind-components';
import { generatePath, Outlet } from 'react-router-dom';
import {
  TabColumn,
  TabColumnList,
  TabColumnWithImage,
} from 'components/TabColumn';
import useSchooladminFindTaskInfo from 'hooks/api/plannerService/schooladmin/queries/useSchooladminFindTaskInfo';
import useFormatMessage from 'hooks/useFormatMessage';
import { taskTypes } from 'utils/constants/taskTypes';
import URLS, { smartschoolSyncTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';

export function SmartschoolSync() {
  const t = useFormatMessage();
  const pageSize = 5;

  const {
    data: importData,
    isFetching: isLoading,
    refetch: refetchTaskInfo,
  } = useSchooladminFindTaskInfo({
    pageSize,
    taskType: taskTypes.SMARTSCHOOL_SYNC,
  });

  return (
    <div className="w-full mt-2" data-test="profile">
      <div className="w-full flex flex-col md:flex-row gap-4 h-full">
        <TabColumnList navLabel={t('synchronize.navLabel')}>
          <TabColumn
            label={t('synchronize.synchronize')}
            test={dataTestIds.page.manage.smartschool.tabs.sync}
            to={generatePath(URLS.MANAGE_SMARTSCHOOL_TAB, {
              tab: smartschoolSyncTabs.SYNC,
            })}
          />

          <TabColumnWithImage
            image={<StatusBullet status={statusBulletStates.SUCCESS} />}
            label={t('global.status')}
            test={dataTestIds.page.manage.smartschool.tabs.status}
            to={generatePath(URLS.MANAGE_SMARTSCHOOL_TAB, {
              tab: smartschoolSyncTabs.STATUS,
            })}
          />
        </TabColumnList>

        <div className="w-full h-full p-4 xl:p-8 border border-gray-300 rounded gap-14 items-stretch">
          <Outlet
            context={{
              importData,
              isLoading,
              pageSize,
              refetchTaskInfo,
            }}
          />
        </div>
      </div>
    </div>
  );
}
