import { gql } from 'graphql-request';

export const TEACHER_DELETE_CALENDAR_ITEM = gql`
  mutation teacherDeleteCalendarItem($id: Int!) {
    teacherDeleteCalendarItem(id: $id) {
      statusCode
      id
      message
    }
  }
`;
