import { Content, FormGroup, PageHeader } from '@ftrprf/tailwind-components';

export default function UserProfileSkeleton({
  canDelete,
  children,
  isLoading,
}) {
  if (!isLoading) {
    return children;
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-col space-y-4 md:flex-row w-full justify-between">
          <div className="flex items-end space-x-2">
            <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse" />
            <div className="flex flex-col space-y-1">
              <span className="bg-gray-200 animate-pulse w-32 h-6" />
              <span className="bg-gray-200 animate-pulse w-80 h-10" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-8 md:self-end items-start md:items-end space-y-4">
            <div className="bg-accent-100 w-52 h-6 animate-pulse" />
            <div className="flex gap-x-2">
              <div className="bg-accent-100 h-6 w-52 animate-pulse" />
              {canDelete && (
                <div className="bg-red-100 h-6 w-52 animate-pulse" />
              )}
            </div>
          </div>
        </div>
      </PageHeader>
      <Content>
        <div className="flex flex-col w-full md:w-2/3 xl:w-1/2">
          <div className="flex flex-col">
            <FormGroup className="bg-gray-200 animate-pulse h-1/2" />
          </div>
          <div className="flex flex-col">
            <FormGroup className="bg-gray-200 animate-pulse h-1/2" />
          </div>
          <div className="flex flex-col">
            <FormGroup className="bg-gray-200 animate-pulse h-1/2" />
          </div>
          <div className="flex flex-col">
            <FormGroup className="bg-gray-200 animate-pulse h-1/2" />
          </div>
          <div className="flex flex-col">
            <FormGroup className="bg-gray-200 animate-pulse h-1/2" />
          </div>
        </div>
      </Content>
    </>
  );
}
