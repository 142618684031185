import { generatePath, Navigate, useParams } from 'react-router-dom';
import URLS, { chapterItemTabs } from 'utils/constants/urls';

export default function ChapterItemIndex() {
  const { chapterId, programId } = useParams();

  return (
    <Navigate
      replace
      to={generatePath(URLS.LEARN_TAB_PROGRAM_CHAPTER, {
        programId,
        chapterId,
        tab: chapterItemTabs.DEFAULT,
      })}
    />
  );
}
