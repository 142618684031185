import c from '../../utils/c';

export function RadioButton({
  checked = false,
  children = null,
  className = '',
  disabled = false,
  label = '',
  name = '',
  onChange,
  value,
}) {
  const id = `${name}::${value}`;

  return (
    <div className="flex items-center group">
      <div className="relative w-5 h-5">
        <input
          checked={checked}
          className="absolute w-full h-full opacity-0 z-10 cursor-pointer"
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <div
          className={c(
            className,
            'w-full h-full',
            'flex items-center justify-center',
            'rounded-full transition duration-200',
            'border border-gray-400 group-hover:border-gray-500',
            'focus:outline-none focus:shadow-focus',
            disabled
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-white cursor-pointer',
          )}
        >
          {checked && (
            <div
              className={c(
                'h-3 w-3 bg-accent-500 group-hover:bg-accent-600 rounded-full',
              )}
            />
          )}
        </div>
      </div>
      <label
        className={c(
          'ml-2',
          disabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer',
        )}
        htmlFor={id}
      >
        {children || label}
      </label>
    </div>
  );
}
