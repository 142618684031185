import { generatePath, Outlet, useParams } from 'react-router-dom';

import {
  Content as ContentContainer,
  PageHeader,
} from '@ftrprf/tailwind-components';

import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';
import { Tab, TabList } from 'components/Tab';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS, { chapterItemTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';

import '../learn.css';
import { ProgramEmptyState } from './partials/ProgramEmptyState';
import { OverviewButton } from './partials/OverviewButton';

export default function TeacherChapterItemOverview({
  breadcrumbsLinks,
  currentChapter,
  isLoading,
  pageTitle,
  sortedPrograms,
}) {
  const t = useFormatMessage();
  const { chapterId, programId } = useParams();
  const chapterItems = currentChapter?.items?.sort(sortByWeightThenByName);

  return (
    <>
      <PageHeader
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        button={<OverviewButton />}
        buttonOnTitleRow
        marginBottom={false}
        title={pageTitle}
      >
        {currentChapter && (
          <TabList>
            <Tab
              label={t('global.LESSON.plural')}
              test={dataTestIds.page.learn.chapterItemOverview.lessons}
              to={generatePath(URLS.LEARN_TAB_PROGRAM_CHAPTER, {
                chapterId,
                programId,
                tab: chapterItemTabs.LESSONS,
              })}
            />
            <Tab
              label={t('global.progress')}
              test={dataTestIds.page.learn.chapterItemOverview.progress}
              to={generatePath(URLS.LEARN_TAB_PROGRAM_CHAPTER, {
                chapterId,
                programId,
                tab: chapterItemTabs.PROGRESS,
              })}
            />
          </TabList>
        )}
      </PageHeader>
      <ContentContainer hasPaddingBottom>
        <div className="w-full mx-auto">
          <ListSkeleton loading={isLoading}>
            <div className="flex flex-col w-full">
              {sortedPrograms?.length > 0 && (
                <div>
                  <Outlet
                    context={{
                      chapterItems,
                      pageTitle,
                    }}
                  />
                </div>
              )}
            </div>
          </ListSkeleton>
          {sortedPrograms?.length === 0 && <ProgramEmptyState />}
        </div>
      </ContentContainer>
    </>
  );
}
