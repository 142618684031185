import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_CREATE_PROGRAM } from 'api/plannerService/admin/mutations/adminCreateProgram';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminCreateProgram() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ file, input }) =>
      plannerServiceQueryClient.request(ADMIN_CREATE_PROGRAM, {
        input,
        file,
      }),
    {
      mutationKey: 'adminCreateProgram',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.PROGRAM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );
}
