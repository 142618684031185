import { formatDate, locale } from '@mobiscroll/react';
import c from '../../../utils/c';

export function RenderDay({
  args,
  isNotEnglish,
  language,
  setSelectedDate,
  windowWidth,
}) {
  const { date } = args;

  return (
    <div
      className={c(
        'header-template-container md:my-2',
        args.selected && 'today',
      )}
    >
      {windowWidth > 800 ? (
        <div>
          <div className="font-bold text-md">
            {formatDate(
              'DDDD',
              date,
              isNotEnglish && {
                dayNames: locale[language].dayNames,
              },
            )}
          </div>
          <div className="text-gray-500 text-sm">
            {formatDate(
              'MMMM DD',
              date,
              isNotEnglish && {
                monthNames: locale[language].monthNames,
              },
            )}
          </div>
        </div>
      ) : (
        <button onClick={() => setSelectedDate(date)} type="button">
          <span className="text-gray-900 text-sm w-full block debug">
            {formatDate(
              'DDD',
              date,
              isNotEnglish && {
                dayNamesShort: locale[language].dayNamesShort,
              },
            )}
          </span>
          <span className="text-gray-700 text-sm block">
            {formatDate('DD', date)}
          </span>
        </button>
      )}
    </div>
  );
}
