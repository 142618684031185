import { useState } from 'react';

import useAdminCreateProgram from 'hooks/api/plannerService/admin/mutations/useAdminCreateProgram';
import useAdminDeleteProgram from 'hooks/api/plannerService/admin/mutations/useAdminDeleteProgram';
import useAdminUpdateProgram from 'hooks/api/plannerService/admin/mutations/useAdminUpdateProgram';

import { ConfirmationDialog } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import { sortOperation } from 'utils/constants/sort';
import { filterOperation } from 'utils/constants/filter';
import useAdminPrograms from 'hooks/api/plannerService/admin/queries/useAdminPrograms';
import AddProgramDialog from './partials/AddProgramDialog';
import UpdateProgramDialog from './partials/UpdateProgramDialog';
import ManagePrograms from './ManagePrograms';
import { sortByWeightThenByName } from '../../../utils/sortByWeightThenByName';

export default function ManageProgramsContainer() {
  const t = useFormatMessage();

  const [isDeleteProgramModalOpen, setIsDeleteProgramModalOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);

  const [programData, setProgramData] = useState();
  const openDeleteProgramModal = () => {
    setIsDeleteProgramModalOpen(true);
  };

  const closeDeleteProgramModal = () => {
    setIsDeleteProgramModalOpen(false);
  };

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    weight: sortOperation.DESC,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const debouncedFilterPrograms = useDebounce(filter, 300);

  const { data, isError, isLoading, refetch } = useAdminPrograms({
    filter: [
      {
        key: 'name',
        value: debouncedFilterPrograms,
        operation: filterOperation.LIKE,
      },
    ],
    page: page - 1,
    size: itemsPerPage,
    sort,
  });

  const { mutateAsync: adminCreateProgram } = useAdminCreateProgram();
  const { mutateAsync: adminDeleteProgram } = useAdminDeleteProgram();
  const { mutateAsync: adminUpdateProgram } = useAdminUpdateProgram();

  const programs = data?.content?.sort(sortByWeightThenByName);
  const totalPages = data?.pages;
  const totalItems = data?.total;

  const addProgram = async (data, file) => {
    await adminCreateProgram({ input: data, file });
    refetch();
  };

  const editProgram = async (data, file) => {
    await adminUpdateProgram({ programId: programData?.id, input: data, file });
    refetch();
  };

  const removeProgram = async () => {
    await adminDeleteProgram(programData?.id);
    refetch();
  };

  return (
    <>
      <AddProgramDialog
        isOpen={isAddProgramModalOpen}
        onConfirm={addProgram}
        onDismiss={setIsAddProgramModalOpen}
      />

      <UpdateProgramDialog
        isOpen={isEditModalOpen}
        onConfirm={editProgram}
        onDismiss={setIsEditModalOpen}
        programData={programData}
      />

      <ConfirmationDialog
        content={t('global.delete.what.confirm', {
          what: t('global.PROGRAM.singular'),
        })}
        isOpen={isDeleteProgramModalOpen}
        onConfirm={removeProgram}
        onDismiss={closeDeleteProgramModal}
      />

      <ManagePrograms
        currentPage={page}
        filter={filter}
        isErrorLoadingPrograms={isError}
        isLoadingPrograms={isLoading}
        itemsPerPage={itemsPerPage}
        openAddModal={setIsAddProgramModalOpen}
        openDeleteProgramModal={openDeleteProgramModal}
        openEditModal={() => setIsEditModalOpen(true)}
        programData={programData}
        programs={programs ?? []}
        setFilter={(value) => {
          setPage(1);
          setFilter(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        setProgramData={setProgramData}
        setSort={setSort}
        sort={sort}
        totalItems={totalItems}
        totalPages={totalPages}
        updateProgram={editProgram}
      />
    </>
  );
}
