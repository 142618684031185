import c from '../../../utils/c';
import useFormatMessage from '../../../hooks/useFormatMessage';
import {
  CODI,
  determineCoach,
  determineCoachAltText,
  determineCoachIcon,
} from './coachesHelper';
import CodiFlipped from '../images/coach-codi-flipped.png';

export function CoachImage({ className = '', coach, isFacingRight = false }) {
  const t = useFormatMessage();

  const currentCoach = determineCoach(coach);
  const coachCodiIsFlipped = isFacingRight && currentCoach === CODI;

  return (
    <img
      alt={determineCoachAltText(t, coach)}
      className={c(
        className,
        !coachCodiIsFlipped && isFacingRight && 'transform scale-x-[-1]',
      )}
      src={coachCodiIsFlipped ? CodiFlipped : determineCoachIcon(currentCoach)}
    />
  );
}
