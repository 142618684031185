import { useOutletContext } from 'react-router-dom';
import ChapterItemOverview from './ChapterItemOverview';

export function TeacherChapterItemOverviewWrapper() {
  const { chapterItems, pageTitle } = useOutletContext();

  return (
    <ChapterItemOverview chapterItems={chapterItems} pageTitle={pageTitle} />
  );
}
