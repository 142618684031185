import useAdminProgramsByOrganizationId from 'hooks/api/plannerService/admin/queries/useAdminProgramsByOrganizationId';
import { useEffect } from 'react';

export function GetProgramsByPeriodId({
  refetch,
  setIsFetchingPrograms,
  setProgramsByOrganizationData,
  setRefetch,
  variables,
}) {
  const {
    data: programsByOrganizationData,
    isFetching: isFetchingProgramsByOrganization,
    refetch: refetchProgramsByOrganization,
  } = useAdminProgramsByOrganizationId(variables);

  useEffect(() => {
    setIsFetchingPrograms(isFetchingProgramsByOrganization);

    if (!isFetchingProgramsByOrganization && programsByOrganizationData) {
      setProgramsByOrganizationData(programsByOrganizationData);
    }

    if (refetch) {
      void refetchProgramsByOrganization().then(() => {
        setRefetch(false);
      });
    }
  }, [
    isFetchingProgramsByOrganization,
    programsByOrganizationData,
    refetch,
    refetchProgramsByOrganization,
    setIsFetchingPrograms,
    setProgramsByOrganizationData,
    setRefetch,
  ]);

  return null;
}
