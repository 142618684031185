// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import {
  Dropdown,
  Error,
  Input,
  Label,
  Toggle,
} from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';
import issuers from 'utils/constants/issuers';
import {
  languagesForDropdown,
  DEFAULT_LANGUAGE_KEY,
} from 'utils/constants/languages';
import { dataTestIds } from 'utils/dataTestIds';

export function SharedFields({
  alreadyExistPrefixes,
  control,
  errors,
  isAddOrganization,
  organizationData,
  register,
  setIssuer,
  setValue,
  showCalendarValue,
}) {
  const t = useFormatMessage();

  return (
    <div>
      <div className="flex flex-col">
        <Label className="font-medium" htmlFor="aouOrganizationDialog-name">
          {t('aou-org.name')}
        </Label>
        <Input
          data-test={dataTestIds.modal.organization.input.name}
          defaultValue={organizationData?.name}
          hasErrors={Boolean(errors.name)}
          id="aouOrganizationDialog-name"
          type="text"
          {...register('name', {
            required: {
              message: t('global.required-field'),
              value: true,
            },
          })}
        />
        <Error message={errors.name?.message} shouldShow={errors.name} />
      </div>
      <Toggle
        className="mt-3"
        label={t('calendar.show')}
        onChange={(value) => setValue('showCalendar', value)}
        test={dataTestIds.modal.organization.button.calendar}
        value={showCalendarValue}
        visibleLabel
      />

      {isAddOrganization && (
        <div className="flex flex-col mt-3">
          <Label className="font-medium" htmlFor="aouOrganizationDialog-prefix">
            {t('aou-org.prefix')}
          </Label>
          <Input
            data-test={dataTestIds.modal.organization.input.prefix}
            hasErrors={Boolean(errors.prefix)}
            id="aouOrganizationDialog-prefix"
            type="text"
            {...register('prefix', {
              required: {
                message: t('global.required-field'),
                value: true,
              },
              validate: (value) =>
                !alreadyExistPrefixes.includes(value) ||
                t('aou-org.prefix-error'),
            })}
          />
          <Error message={errors.prefix?.message} shouldShow={errors.prefix} />
        </div>
      )}

      <div className="flex flex-col mt-3">
        <Label className="font-medium" htmlFor="aouOrganizationDialog_language">
          {t('aou-org.language')}
        </Label>
        <Controller
          control={control}
          defaultValue={organizationData?.language || DEFAULT_LANGUAGE_KEY}
          hasErrors={Boolean(errors.language)}
          name="language"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              data-test={dataTestIds.modal.organization.dropdown.language}
              inputId="aouOrganizationDialog_language"
              isMulti={false}
              onBlur={onBlur}
              onChange={(data) => {
                onChange(data);
              }}
              options={languagesForDropdown}
              value={value}
            />
          )}
          rules={{
            required: {
              message: t('global.required-field'),
              value: true,
            },
          }}
          type="select"
        />
        <Error
          message={errors.language?.message}
          shouldShow={errors.language}
        />
      </div>

      <div className="flex flex-col mt-3">
        <Label className="font-medium" htmlFor="aouOrganizationDialog_issuer">
          {t('aou-org.issuer')}
        </Label>
        <Controller
          control={control}
          defaultValue={organizationData?.issuer}
          hasErrors={Boolean(errors.issuer)}
          name="issuer"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              data-test={dataTestIds.modal.organization.dropdown.issuer}
              inputId="aouOrganizationDialog_issuer"
              isMulti={false}
              onBlur={onBlur}
              onChange={(data) => {
                onChange(data);
                setIssuer(data);
              }}
              options={Object.values(issuers)}
              value={value}
            />
          )}
          rules={{
            required: {
              message: t('global.required-field'),
              value: true,
            },
          }}
          type="select"
        />
        <Error message={errors.issuer?.message} shouldShow={errors.issuer} />
      </div>
    </div>
  );
}
