import { ChosenCorrect } from './MultipleChoiceAnswerParts/ChosenCorrect';
import { ChosenInCorrect } from './MultipleChoiceAnswerParts/ChosenInCorrect';
import { NotChosenCorrect } from './MultipleChoiceAnswerParts/NotChosenCorrect';
import { NotChosenInCorrect } from './MultipleChoiceAnswerParts/NotChosenInCorrect';

export function MultipleChoiceQuestionAnswerPart({ answer, chosenByUser }) {
  return (
    <>
      {chosenByUser && answer.correct && <ChosenCorrect answer={answer} />}
      {chosenByUser && !answer.correct && <ChosenInCorrect answer={answer} />}
      {!chosenByUser && answer.correct && <NotChosenCorrect answer={answer} />}
      {!chosenByUser && !answer.correct && (
        <NotChosenInCorrect answer={answer} />
      )}
    </>
  );
}
