import { useQuery } from 'react-query';

import { ORGANIZATION_INFO } from 'api/plannerService/common/queries/organizationInfo';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useOrganizationInfo(account, disable = false) {
  const response = useQuery(
    'organizationInfo',
    () => plannerServiceQueryClient.request(ORGANIZATION_INFO, {}),
    {
      enabled:
        !!account && !disable && !!account?.idTokenClaims?.organizationid,
    },
  );

  return {
    ...response,
    data: response?.data?.organizationInfo,
  };
}
