import { Navigate, generatePath, useParams } from 'react-router-dom';
import URLS, { organizationTabs } from 'utils/constants/urls';

export default function ManageOrganizationIndex() {
  const { organizationId } = useParams();

  return (
    <Navigate
      replace
      to={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
        organizationId,
        tab: organizationTabs.DEFAULT,
      })}
    />
  );
}
