import { generatePath } from 'react-router-dom';

import { getItemIndex } from 'components/Pager/getItemIndex';
import { Pager } from 'components/Pager/Pager';

import URLS from 'utils/constants/urls';

export function UserPager({
  currentUser,
  searchString = '',
  setUserId = () => {},
  users,
}) {
  const formattedSearchString = searchString ? `?${searchString}` : '';
  const getLink = (value) =>
    `${generatePath(URLS.USER_PROFILE, {
      userId: value?.id,
    })}${formattedSearchString}`;

  return (
    <div className="w-full flex justify-center mt-2 items-center">
      <Pager
        currentItem={currentUser}
        getItemIndex={getItemIndex}
        getNewLocation={getLink}
        items={users}
        nameAttribute="fullName"
        setItemId={setUserId}
      />
    </div>
  );
}
