import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_UPDATE_CALENDAR_ITEM } from 'api/plannerService/teacher/mutations/teacherUpdateCalendarItem';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherUpdateCalendarItem() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ id, input }) =>
      plannerServiceQueryClient.request(TEACHER_UPDATE_CALENDAR_ITEM, {
        id,
        input,
      }),
    {
      mutationKey: 'teacherUpdateCalendarItem',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.CALENDARITEM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.CALENDARITEM.singular'),
        });
      },
    },
  );
}
