import { Navigate, generatePath } from 'react-router-dom';
import URLS, { teamsSyncTabs } from 'utils/constants/urls';

export default function TeamsSyncIndex() {
  return (
    <Navigate
      replace
      to={generatePath(URLS.MANAGE_TEAMS_TAB, {
        tab: teamsSyncTabs.SYNC,
      })}
    />
  );
}
