export function getHeaders(t, chapterItems) {
  const tempHeaders = [
    {
      key: 'name',
      label: t('global.name'),
      orderable: true,
    },
  ];

  if (!chapterItems) return tempHeaders;

  const ids = [];

  chapterItems.forEach((chapterItem) => {
    const { id, name } = chapterItem;
    if (!ids.includes(id)) {
      ids.push(id);

      tempHeaders.push({
        key: `chapterItem_${id}`,
        label: name,
      });
    }
  });

  return tempHeaders;
}
