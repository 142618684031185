export const sizesClassNames = {
  small: { button: 'px-2 py-1 text-xs', icon: 'h-3' },
  medium: { button: 'px-2.5 py-1.5 text-sm', icon: 'h-6' },
  large: { button: 'px-3.5 py-2.5 text-sm', icon: 'h-9' },
};

export const primaryVariantClassNames = {
  black:
    'bg-transparent hover:bg-gray-200 font-normal text-black hover:underline hover:text-accent-600 focus:underline-offset-2 focus:ring-2 focus:ring-accent-500 focus:outline-none',
  blue: `font-semibold bg-accent-600 ring-accent-600 hover:bg-accent-500 hover:ring-accent-500 text-white ring-1 border border-transparent focus:ring-2 focus:ring-accent-500 focus:outline-none focus:ring-offset-2`,
  text:
    'font-medium bg-transparent hover:bg-gray-100 text-accent-600 focus:underline-offset-2 focus:ring-2' +
    ' focus:ring-accent-500 focus:outline-none',
  white:
    'font-semibold bg-white border border-gray-100 text-gray-600 outline outline-1 outline-gray-100/50 hover:outline-offset-2 ring-offset-transparent',
};

export const secondaryVariantClassNames = {
  black: 'font-semibold bg-transparent hover:bg-gray-200',
  blue: 'font-semibold bg-white border border-gray-200 text-gray-600 hover:border-accent-500 hover:ring-1 hover:ring-gray-200 hover:decoration-white-600 focus:underline-offset-2 focus:ring-2 focus:ring-accent-500 focus:outline-none',
  text: 'font-semibold bg-transparent hover:bg-gray-100/50 text-white hover:underline hover:decoration-white-600 focus:underline-offset-2 focus:ring-2 focus:ring-accent-500 focus:outline-none',
  white:
    'font-semibold bg-transparent border border-white text-white outline outline-1 outline-gray-100/50 hover:outline-offset-2 ring-offset-transparent hover:bg-transparent',
};

export const baseClassName =
  'group inline-flex items-center justify-center rounded-full m-[3px]';

export const enabledClassName =
  'cursor-pointer transition-all hover:bg-opacity-95 hover:ring-offset-2';
