import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';
import useAdminCreatePeriod from 'hooks/api/plannerService/admin/mutations/useAdminCreatePeriod';
import useAdminUpdatePeriod from 'hooks/api/plannerService/admin/mutations/useAdminUpdatePeriod';
import useFormatMessage from 'hooks/useFormatMessage';
import { useForm } from 'react-hook-form';
import { dataTestIds } from 'utils/dataTestIds';
import { dateToUtcForBackend } from 'utils/timeHelpers';

export function EditPeriodDialog({
  dismiss,
  existingPeriods,
  isDefaultPeriodForOrganization,
  isOpen,
  organization,
  organizationId,
  selectedPeriod,
}) {
  const t = useFormatMessage();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      name: selectedPeriod?.name,
      description: selectedPeriod?.description,
      startDate: selectedPeriod?.startDate,
      endDate: selectedPeriod?.endDate,
    },
  });

  const { mutateAsync: createPeriod } = useAdminCreatePeriod(organizationId);

  const { mutateAsync: updatePeriod } = useAdminUpdatePeriod(
    selectedPeriod?.id,
  );

  const isEdit = Boolean(selectedPeriod);

  const onSubmit = (data) => {
    const input = { ...data };
    input.endDate = dateToUtcForBackend(data.endDate);
    input.startDate = dateToUtcForBackend(data.startDate);

    (isEdit ? updatePeriod({ input }) : createPeriod({ input })).then(() => {
      dismiss(true);
      reset();
    });
  };

  const areDatesOverlappingWithExistingPeriods = (startDate, endDate) => {
    for (const period of existingPeriods) {
      if (
        new Date(period.endDate) >= startDate &&
        new Date(period.startDate) <= endDate
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss}>
      <DialogHeader>
        {isDefaultPeriodForOrganization && organization?.name
          ? `${t('periods.new.for')} ${organization?.name}`
          : t('periods.new')}
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <InputGroup>
            <Label className="font-bold" htmlFor="periodName">
              {t('profile.name')}
            </Label>
            <Input
              data-test={dataTestIds.modal.editPeriod.input.name}
              id="periodName"
              {...register('name', { required: true })}
            />
          </InputGroup>

          <InputGroup>
            <Label className="font-bold" htmlFor="periodDescription">
              {t('periods.description')}
            </Label>
            <Input
              data-test={dataTestIds.modal.editPeriod.input.description}
              id="periodDescription"
              type="multiline"
              {...register('description')}
            />
          </InputGroup>

          <div className="flex gap-2">
            <InputGroup className="grow">
              <Label className="font-bold" htmlFor="periodStartDate">
                {t('periods.startDate')}
              </Label>
              <Input
                data-test={dataTestIds.modal.editPeriod.input.startDate}
                id="periodStartDate"
                type="date"
                {...register('startDate', {
                  required: true,
                  valueAsDate: true,
                  validate: {
                    before: (startDate, { endDate }) =>
                      startDate < endDate ||
                      t('periods.errors.startdate-before-enddate'),
                    overlapping: (startDate, { endDate }) =>
                      !areDatesOverlappingWithExistingPeriods(
                        startDate,
                        endDate,
                      ) || t('periods.errors.dates-overlap'),
                  },
                })}
              />
            </InputGroup>

            <InputGroup className="grow">
              <Label className="font-bold" htmlFor="periodEndDate">
                {t('periods.endDate')}
              </Label>
              <Input
                data-test={dataTestIds.modal.editPeriod.input.endDate}
                id="periodEndDate"
                type="date"
                {...register('endDate', {
                  required: true,
                  valueAsDate: true,
                })}
              />
            </InputGroup>
          </div>
          {Object.values(errors).map((error, index) => (
            <p key={`${error.type}${index + 1}`} className="text-red-500">
              {error.message}
            </p>
          ))}
        </DialogContent>

        <DialogActions>
          <ActionStyledAsButton
            onClick={dismiss}
            test={dataTestIds.modal.editPeriod.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.editPeriod.button.submit}
            type="submit"
          >
            {t(isEdit ? 'global.save.changes' : 'periods.submit')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
