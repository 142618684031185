import { FaMinus, FaPlus } from 'react-icons/fa';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { DefaultButton } from '../../buttons/DefaultButton';
import { SlideViewerZoom } from '../../slideviewer/SlideViewer';

export default function SideBarSettings() {
  const t = useFormatMessage();

  return (
    <div className="overflow-hidden flex flex-col flex-grow px-4">
      <h3 className="mt-4 mb-1 text-2xl font-bold">
        {t('styled-slide-viewer.settings')}
      </h3>
      <div className="overflow-y-auto flex-grow">
        <div>
          <div className="mb-4">
            <div>{t('styled-slide-viewer.settings.zoom_explanation')}</div>
            <SlideViewerZoom>
              {({ min, plus }) => (
                <div className="flex items-center">
                  <DefaultButton onClick={min}>
                    <FaMinus />
                  </DefaultButton>

                  <span className="mx-2 lowercase">
                    {t('styled-slide-viewer.settings.zoom')}
                  </span>

                  <DefaultButton onClick={plus}>
                    <FaPlus />
                  </DefaultButton>
                </div>
              )}
            </SlideViewerZoom>
          </div>
        </div>
      </div>
    </div>
  );
}
