import useFormatMessage from '../../../hooks/useFormatMessage';

export function ExampleAnswer({ solution }) {
  const t = useFormatMessage();
  return (
    <div className="overflow-hidden flex flex-col flex-grow px-4 example-answer">
      <h3 className="mt-4 mb-4 text-2xl font-bold">
        {t('styled-slide-viewer.example_answer')}
      </h3>
      <div className="overflow-y-auto flex-grow">{solution}</div>
    </div>
  );
}
