import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { IMPERSONATE_USER } from 'api/plannerService/common/mutations/impersonateUser';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useImpersonateUser() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useMutation(
    (id) =>
      plannerServiceQueryClient.request(IMPERSONATE_USER, {
        id,
      }),
    {
      mutationKey: 'impersonateUser',
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.NOTIFICATION.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.impersonateUser,
  };
}
