import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addHours } from 'date-fns';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
} from '@ftrprf/tailwind-components';

import { SmartschoolButton } from 'components/Buttons/SmartschoolButton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTeacherCreateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateCalendarItem';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import useTeacherPrograms from 'hooks/api/plannerService/teacher/queries/useTeacherPrograms';

import { viewModes } from 'utils/constants/viewModes';
import { OWN_CONTENT } from 'utils/constants/contentTypes';
import { backendAcceptedDateFormat } from 'utils/timeHelpers';

import { PopupProvider } from 'providers/PopupProvider';
import { getStudioIdForLesson } from '../Calendar/getStudioIdForLesson';
import { postResponse } from './partials/postResponse';
import { PopupContent } from './partials/PopupContent';

export default function SmartschoolPopup() {
  const t = useFormatMessage();
  const [classes, setClasses] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [target, setTarget] = useState({
    description: t('smartschool.popup.target.all.description'),
    id: 0,
    name: t('smartschool.popup.target.all.name'),
  });
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      chapter: '',
      chapterItem: '',
      classGroups: classes,
      program: '',
      target,
      viewMode: viewModes.CLASSICAL,
    },
  });
  const { chapterItem } = watch();
  const { data: teacherPrograms, isLoading: isLoadingTeacherPrograms } =
    useTeacherPrograms();
  const steps = useMemo(
    () => [
      { id: '01', isDisabled: false, name: t('smartschool.popup.step1') },
      {
        id: '02',
        isDisabled: Boolean(!classes?.length),
        name: t('smartschool.popup.step2'),
      },
      {
        id: '03',
        isDisabled: Boolean(!chapterItem?.value),
        name: t('smartschool.popup.step3'),
      },
    ],
    [chapterItem?.value, t, classes?.length],
  );

  const { data: classGroupsData, isLoading: isLoadingClasses } =
    useTeacherClassGroups({
      page: 0,
      size: 500,
    });

  const {
    isLoading: createCalendarItemLoading,
    mutateAsync: createCalendarItem,
  } = useTeacherCreateCalendarItem();

  const addCalendarItem = async () => {
    const { chapter, chapterItem, program, studioId, viewMode } = watch();
    const currentTime = new Date();
    const oneHourAway = addHours(currentTime, 1);
    const isOwnContent = program?.value === OWN_CONTENT;
    const input = {
      chapterItemId: isOwnContent ? null : chapterItem?.value,
      classGroupIds: classes.map(
        (classGroup) => classGroup?.value || classGroup?.id, // first if it's not the all classes option, second if it is
      ),
      closedAfterEndTime: false,
      endTime: backendAcceptedDateFormat(oneHourAway),
      hidden: true,
      startTime: backendAcceptedDateFormat(currentTime),
      studioId: getStudioIdForLesson(
        teacherPrograms,
        program?.value,
        chapter?.value,
        chapterItem?.value,
        null,
        studioId?.value,
      ),
      viewMode: viewMode?.value ?? viewModes.CLASSICAL,
    };

    return createCalendarItem(input);
  };

  useEffect(() => {
    const hasRetrievedClassGroups =
      classGroupsData && Boolean(classGroupsData.content);
    if (hasRetrievedClassGroups && target.id === 0 && !classes.length)
      setClasses(classGroupsData.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classGroupsData]);

  const showNextStepButton =
    (isLoadingClasses || Boolean(classGroupsData?.content?.length)) &&
    (isLoadingTeacherPrograms || Boolean(teacherPrograms?.length));

  return (
    <PopupProvider
      classes={classes}
      classGroupsData={classGroupsData}
      control={control}
      currentStepIndex={currentStepIndex}
      errors={errors}
      register={register}
      setClasses={setClasses}
      setCurrentStepIndex={setCurrentStepIndex}
      setTarget={setTarget}
      setValue={setValue}
      steps={steps}
      target={target}
      teacherPrograms={teacherPrograms}
      watch={watch}
    >
      <Dialog
        className="overflow-visible md:min-h-[24rem] md:min-w-[43rem]"
        isOpen
        modal
      >
        <DialogContent>
          <PopupContent />
        </DialogContent>
        <DialogActions>
          {currentStepIndex !== 2 ? (
            <>
              {currentStepIndex !== 0 && (
                <ActionStyledAsButton
                  onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                  variant="text"
                >
                  {t('global.previous')}
                </ActionStyledAsButton>
              )}

              {showNextStepButton && (
                <ActionStyledAsButton
                  disabled={steps[currentStepIndex + 1].isDisabled}
                  onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                >
                  {t('smartschool.popup.next', { step: currentStepIndex + 2 })}
                </ActionStyledAsButton>
              )}
            </>
          ) : (
            <SmartschoolButton
              isLoading={createCalendarItemLoading}
              onClick={async () => {
                await addCalendarItem().then((data) => {
                  postResponse(data, teacherPrograms, watch);
                });
              }}
              text={t('smartschool.popup.use')}
            />
          )}
        </DialogActions>
      </Dialog>
    </PopupProvider>
  );
}
