import { gql } from 'graphql-request';

export const ADMIN_UPDATE_USER = gql`
  mutation adminUpdateUser($id: String!, $input: UserInput) {
    adminUpdateUser(id: $id, input: $input) {
      id
      userName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
    }
  }
`;
