import { useContext, useMemo, useState } from 'react';

import { useLocalStorage } from '@ftrprf/hooks';

import useFormatMessage from '../../hooks/useFormatMessage';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { SlideViewerContext } from '../slideviewer/SlideViewer';

import EmptySlides from './partials/EmptySlides';
import SideBar from './partials/SideBar';
import SlideViewerCenter from './partials/SlideViewerCenter';
import SlideViewerFooter from './partials/SlideViewerFooter';
import SlideViewerLeft from './partials/SlideViewerLeft';
import SlideViewerRight from './partials/SlideViewerRight';
import { SlideNotAvailable } from './partials/SlideNotAvailable';
import { Assistant } from '../Assistant/Assistant';
import { dataTestIds } from '../../utils/constants/dataTestIds';

export function StyledSlideViewer({
  onClose,
  showTeacherInfoTab = false,
  showViewModes = false,
  steamsTab = null,
}) {
  const {
    currentSlide,
    currentSlideIndex,
    hasAssistant,
    isCodefever,
    lesson,
    setShowConfirmationDialog,
    setSlide,
    setSubmitAnswer,
    showConfirmationDialog,
    slides,
    submitAnswer,
    viewMode,
  } = useContext(SlideViewerContext);
  const t = useFormatMessage();

  const [isSideBarOpen, setIsSideBarOpen] = useLocalStorage(
    'SLIDE_VIEWER_SIDEBAR_OPEN',
    false,
  );

  const hasMotivation = useMemo(() => {
    const slidesWithMotivation = slides?.filter((slide) => !!slide.motivation);
    return slidesWithMotivation.length > 0;
  }, [slides]);

  const [slideViewerCenterKey, setSlideViewerCenterKey] = useState(Date.now());

  const slidesUptilNow = useMemo(
    () => slides.slice(0, currentSlideIndex + 1),
    [slides, currentSlideIndex],
  );

  if (!slides && slides.length === 0) {
    return <EmptySlides onClose={onClose} />;
  }

  if (!currentSlide) {
    return <SlideNotAvailable onClose={onClose} />;
  }

  return (
    <div
      className="w-full h-full relative manual-height flex flex-grow overflow-y-auto"
      id="SlideViewerFullscreen"
    >
      {showConfirmationDialog && (
        <ConfirmationDialog
          cancelText={t('styled-slide-viewer.confirmation.cancel')}
          confirmText={t('styled-slide-viewer.confirmation.confirm')}
          content={t('styled-slide-viewer.confirmation.content')}
          isOpen={showConfirmationDialog}
          onConfirm={() => {
            setShowConfirmationDialog(false);
            if (submitAnswer) {
              submitAnswer();
            }
            setSubmitAnswer(null);
            setSlide(currentSlideIndex + 1);
          }}
          onDismiss={() => {
            setShowConfirmationDialog(false);
            setSubmitAnswer(null);
            setSlide(currentSlideIndex + 1);
          }}
          testInfo={{
            confirm:
              dataTestIds.modal.slideQuestionConfirmationModal.button.submit,
            dismiss:
              dataTestIds.modal.slideQuestionConfirmationModal.button.cancel,
            modal: dataTestIds.modal.slideQuestionConfirmationModal.self,
          }}
          title={t('styled-slide-viewer.confirmation.title')}
        />
      )}
      {isSideBarOpen && (
        <SideBar
          currentSlide={currentSlide}
          lesson={lesson}
          showTeacherInfoTab={showTeacherInfoTab}
          steamsTab={steamsTab}
        />
      )}
      <div className="bg-white flex flex-grow flex-col items-center justify-between w-full h-full relative">
        <SlideViewerLeft
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={(value) => {
            setIsSideBarOpen(value);
            // re-render SlideViewerCenter when opening and closing the teacherManual so the scale of the slide adapts to fit the screen
            setSlideViewerCenterKey(Date.now());
          }}
        />
        <SlideViewerCenter
          key={slideViewerCenterKey}
          currentSlide={currentSlide}
        />
        <SlideViewerRight
          hints={currentSlide.hints}
          isCodefever={isCodefever}
          onClose={onClose}
          showViewModes={showViewModes}
        />

        {hasAssistant &&
          Boolean(hasMotivation || !!currentSlide?.hints?.length) && (
            <Assistant chatLayout slidesUptilNow={slidesUptilNow} />
          )}
        <SlideViewerFooter viewMode={viewMode} />
      </div>
    </div>
  );
}

export const StyledSlideViewerTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
