/**
 * Always return a 2-string initial for a given name.  This means it ignores
 * middle names & names with a compound lastname (eg. Van Den Driessche).
 *
 * @param name string
 * @returns {string}
 */
export const getInitials = (name) => {
  const names = name.trim().toUpperCase().split(' ');
  const firstname = names.shift();
  const lastname = names.pop();
  const firstInitial = firstname.substring(0, 1);

  return lastname
    ? firstInitial.concat(lastname.substring(0, 1))
    : firstInitial.concat(firstname.substring(1, 2));
};
