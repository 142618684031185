import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export default function AddClassGroupDialog({
  addClassGroup,
  isLoading,
  isOpen,
  onDismiss: dismiss,
}) {
  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(
    (data) => {
      addClassGroup(data.name, onDismiss);
      reset();
    },
    [addClassGroup, onDismiss, reset],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('add-class.group-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="addClassGroupDialog-name">
                {t('add-class.group-dialog.name')}
              </Label>
              <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input
                    data-test={dataTestIds.modal.createClassgroup.input.name}
                    hasErrors={Boolean(errors.name)}
                    htmlFor="addClassGroupDialog-name"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('dropdown.no_results'),
                  },
                }}
                type="text"
              />
              {errors.name && (
                <span className="text-red-500 text-xs">
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.createClassgroup.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={isLoading}
            test={dataTestIds.modal.createClassgroup.button.submit}
            type="submit"
          >
            {t('add-class.group-dialog.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
