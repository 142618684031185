import { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';

import c from '../utils/c';

import { Input } from './form/Input';
import { dataTestIds } from '../utils/constants/dataTestIds';
import useFormatMessage from '../hooks/useFormatMessage';

export function SearchBar({
  className = '',
  defaultValue = '',
  id = '',
  onChange = () => {},
  placeholder = '',
  test = null,
}) {
  const t = useFormatMessage();
  const [searchString, setSearchString] = useState(defaultValue);
  const isSearching = searchString && searchString !== '';
  const newId = id || placeholder.replaceAll(' ', '');

  return (
    <div className={c(className, 'w-full relative')}>
      <label className="sr-only" htmlFor={newId}>
        {placeholder}
      </label>
      <Input
        data-test={test}
        id={newId}
        onChange={(e) => {
          setSearchString(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        type="text"
        value={searchString}
      />
      <button
        className={c(
          'absolute h-full top-0 flex items-center right-3 text-gray-500 text-sm',
          isSearching && 'cursor-pointer',
        )}
        data-test={dataTestIds.component.searchBar.button.clear}
        onClick={() => {
          setSearchString('');
          onChange('');
        }}
        type="button"
      >
        <span className="sr-only">{t('global.search')}</span>
        {isSearching ? <FaTimes /> : <FaSearch />}
      </button>
    </div>
  );
}
