/* eslint-disable react/jsx-props-no-spreading */
// the disabled rule above is because ReachDialog works with prop spreading
import { FaTimes } from 'react-icons/fa';
import { Dialog as ReachDialog } from '@reach/dialog';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';
import getDialogStyle from '../../utils/getComponentStyle';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';

const dialogStyles = {
  CARD: 'bg-white shadow-base max-w-2xl flex flex-col w-full',
  MODAL: 'bg-white shadow-base flex flex-col min-h-1/4',
  UNSTYLED: 'w-full',
};

export function Dialog({
  children = null,
  className = '',
  modal = false,
  unstyled = false,
  ...props
}) {
  const style = getDialogStyle(dialogStyles, { unstyled, modal }, 'CARD');

  return (
    <ReachDialog
      aria-label={props.ariaLabel || 'Dialog'}
      className={c(
        className,
        dialogStyles[style],
        'min-w-96 rounded-lg',
        modal && 'overflow-auto resize',
      )}
      {...props}
    >
      {children}
    </ReachDialog>
  );
}

export function DialogHeader({
  children = null,
  className = '',
  hasCloseButton = false,
  headerType: HeaderType = 'h2',
  onDismiss = () => {},
  otherButton = null,
}) {
  return (
    <div
      className={c(
        'p-4 border-b border-gray-300 text-xl font-bold relative',
        className,
      )}
    >
      {otherButton ? (
        <DialogHeaderWithButton
          hasCloseButton={hasCloseButton}
          headerType={HeaderType}
          onDismiss={onDismiss}
          otherButton={otherButton}
        >
          {children}
        </DialogHeaderWithButton>
      ) : (
        <BaseDialogHeader
          hasCloseButton={hasCloseButton}
          headerType={HeaderType}
          onDismiss={onDismiss}
        >
          {children}
        </BaseDialogHeader>
      )}
    </div>
  );
}

function DialogHeaderWithButton({
  children,
  hasCloseButton,
  headerType: HeaderType = 'h2',
  onDismiss,
  otherButton,
}) {
  const t = useFormatMessage();

  return (
    <div className="flex justify-between align-center">
      <BaseDialogHeader
        hasCloseButton={false}
        headerType={HeaderType}
        onDismiss={onDismiss}
      >
        {children}
      </BaseDialogHeader>
      {otherButton}
      {hasCloseButton && onDismiss && (
        <button
          aria-label={t('dialog.close')}
          className="absolute top-0 right-0 p-2 text-base text-gray-300 hover:text-gray-400 transition-colors duration-100"
          onClick={onDismiss}
          title={t('dialog.close')}
          type="button"
        >
          <FaTimes />
        </button>
      )}
    </div>
  );
}

function BaseDialogHeader({
  children,
  hasCloseButton,
  headerType: HeaderType = 'h2',
  onDismiss,
}) {
  const t = useFormatMessage();

  return (
    <>
      <HeaderType>{children}</HeaderType>
      {hasCloseButton && onDismiss && (
        <button
          aria-label={t('dialog.close')}
          className="absolute top-0 right-0 p-2 text-base text-gray-300 hover:text-gray-400 transition-colors duration-100"
          onClick={onDismiss}
          title={t('dialog.close')}
          type="button"
        >
          <FaTimes />
        </button>
      )}
    </>
  );
}

export function DialogContent({ children, className = '', ...props }) {
  return (
    <div className={c('m-4', className)} {...props}>
      {children}
    </div>
  );
}

export function DialogFooter({ children = null, className = '' }) {
  return (
    <div
      className={c(
        'p-4 text-right flex justify-end gap-2 border-t border-gray-300',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function DialogActions({ children, className = '' }) {
  return <DialogFooter className={className}>{children}</DialogFooter>;
}

export const DialogTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
