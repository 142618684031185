import {
  ActionStyledAsButton,
  DialogActions,
  DialogContent,
  DialogHeader,
  ResponsiveTableCol,
} from '@ftrprf/tailwind-components';
import { useState } from 'react';
import { reportOptions } from 'utils/constants/reportOptions';
import { sortByStartDateThenByEndDate } from 'utils/sortByStartDateThenByEndDate';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import {
  bulletColour,
  classNamesForDiv,
  classNamesForTableCell,
  testStatus,
} from './classNamesForTableCell';
import { ResultForStudentForChapterItem } from './ResultForStudentForChapterItem';
import c from '../../../../utils/c';

export function ChapterItemReportTableColumn({
  activeScoreMode,
  activeViewMode,
  chapterItem,
  index,
  setDialogContent,
  setIsDialogOpen,
  userId,
}) {
  const t = useFormatMessage();
  const { calendarItems } = chapterItem;
  const [highestScore, setHighestScore] = useState(0);
  const key = `kolom_${chapterItem.id}_${index + 1}`;
  const chapterHasNoCalendarItems = calendarItems?.length === 0;

  if (chapterHasNoCalendarItems) {
    return <ResponsiveTableCol key={key}>-</ResponsiveTableCol>;
  }
  const allScoresForChapterItem = [];
  const calendarItemsWithScores = calendarItems.filter(
    (calendarItem) => calendarItem.reportOverallScores?.length > 0,
  );

  calendarItemsWithScores.forEach((calendarItem) => {
    const { endTime, id, reportOverallScores, startTime } = calendarItem;
    reportOverallScores.forEach((reportOverallScore) => {
      const { viewMode } = reportOverallScore;
      if (viewMode === activeViewMode) {
        allScoresForChapterItem.push({
          endTime,
          id,
          startTime,
          userId,
          ...reportOverallScore,
        });
      }
    });
  });
  const chapterItemHasNoScores = allScoresForChapterItem?.length === 0;

  if (chapterItemHasNoScores) {
    return <ResponsiveTableCol key={key}>-</ResponsiveTableCol>;
  }

  // ensure order is from most recent to oldest
  allScoresForChapterItem.sort(sortByStartDateThenByEndDate).reverse();

  allScoresForChapterItem.forEach((scoreObject) => {
    let percentage = 0;
    if (activeScoreMode === reportOptions.SCORE) {
      const { questionScoresEarned, questionScoresMax } = scoreObject;
      percentage = (questionScoresEarned / questionScoresMax) * 100;
    }

    if (activeScoreMode === reportOptions.QUESTIONS) {
      const { amountOfQuestions, answeredQuestions } = scoreObject;
      percentage = (answeredQuestions / amountOfQuestions) * 100;
    }

    if (percentage > highestScore) setHighestScore(percentage);
  });

  let status = testStatus.FLUNKED;
  if (highestScore > 50) {
    status = testStatus.PASSED;
  }

  if (
    (activeScoreMode === reportOptions.SCORE && highestScore > 75) ||
    (activeScoreMode === reportOptions.QUESTIONS && highestScore === 100)
  ) {
    status = testStatus.ACED_IT;
  }

  let openDialog;

  if (allScoresForChapterItem?.length > 1) {
    openDialog = () => {
      const dialogContent = (
        <>
          <DialogHeader>
            {t(
              `chapterItemReport.dialog.title.${activeScoreMode.toLowerCase()}`,
            )}
          </DialogHeader>
          <DialogContent>
            <ul className="list-disc ml-4">
              {allScoresForChapterItem.map((scoreObject, index) => (
                <li key={`resultaat_${chapterItem.id}_${index + 1}`}>
                  <ResultForStudentForChapterItem
                    activeScoreMode={activeScoreMode}
                    report={scoreObject}
                    showDateInLabel
                  />
                </li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton
              onClick={() => setIsDialogOpen(false)}
              test={dataTestIds.modal.calendarItem.button.cancel}
            >
              {t('global.close')}
            </ActionStyledAsButton>
          </DialogActions>
        </>
      );

      setDialogContent(dialogContent);
      setIsDialogOpen(true);
    };
  }

  const hasMultipleScores = allScoresForChapterItem?.length > 1;
  const mostRecentChapterItem = allScoresForChapterItem[0];

  return (
    <ResponsiveTableCol key={key} className={classNamesForTableCell[status]}>
      <div
        className={c(
          'flex flex-col gap-2 items-center py-4 w-full h-full',
          classNamesForDiv[status],
        )}
      >
        {!hasMultipleScores && (
          <ResultForStudentForChapterItem
            activeScoreMode={activeScoreMode}
            bulletColour={bulletColour[status]}
            report={mostRecentChapterItem}
          />
        )}
        {hasMultipleScores && (
          <button onClick={openDialog} type="button">
            <ResultForStudentForChapterItem
              activeScoreMode={activeScoreMode}
              bulletColour={bulletColour[status]}
              hasMultipleScores
              numberOfCalendarItems={allScoresForChapterItem.length}
              report={mostRecentChapterItem}
            />
          </button>
        )}
      </div>
    </ResponsiveTableCol>
  );
}
