import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { HACKROOMS } from 'api/plannerService/common/queries/hackRooms';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useHackRooms() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    `hackRooms`,
    () => plannerServiceQueryClient.request(HACKROOMS, {}),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.hackRooms,
  };
}
