import { gql } from 'graphql-request';

export const TEACHER_STUDENT_EXERCISE = gql`
  query teacherStudentExercise(
    $lessonSessionId: String!
    $userId: String!
    $exerciseVersionId: Int!
  ) {
    teacherStudentExercise(
      lessonSessionId: $lessonSessionId
      userId: $userId
      exerciseVersionId: $exerciseVersionId
    ) {
      exerciseVersionId
      exerciseId
      exerciseTitle
      content
      testPlanId
    }
  }
`;
