import { gql } from 'graphql-request';

export const SAVED_ANSWERS = gql`
  query savedAnswers($lessonSessionId: String!) {
    savedAnswers(lessonSessionId: $lessonSessionId) {
      questionId
      type
      teacherFeedback
      __typename
      ... on AnswerOpen {
        userAnswer
      }
      ... on AnswerMultipleChoice {
        userAnswers
        questionAnswersMultipleChoice {
          id
          value
          correct
          explanation
        }
      }
    }
  }
`;
