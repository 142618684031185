export const SLIDE_BACKGROUNDS = {
  white: {
    value: 'white',
    translationKey: 'content-editor.colors.white',
    className: '',
    previewUrl: '',
  },
  whiteLavaLamp: {
    value: 'whiteLavaLamp',
    className: 'whiteLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/white-mesh.webp',
  },
  blueLavaLamp: {
    value: 'blueLavaLamp',
    className: 'blueLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/blue-mesh.webp',
  },
  stars: {
    value: 'stars',
    className: 'starsBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/stars.webp',
  },
  // don't forget to add thumbnails later
  /* FTRPRF variants */
  FTRPRFBlueLavaLamp: {
    value: 'FTRPRFBlueLavaLamp',
    className: 'FTRPRFBlueLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/blue-mesh.webp',
  },
  FTRPRFSideBlueLavaLamp: {
    value: 'FTRPRFSideBlueLavaLamp',
    className: 'FTRPRFSideBlueLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/side-blue-mesh.webp',
  },
  FTRPRFWhiteLavaLamp: {
    value: 'FTRPRFWhiteLavaLamp',
    className: 'FTRPRFWhiteLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/white-mesh.webp',
  },
  /* CodeCosmos variants */
  CodeCosmosBlueLavaLamp: {
    value: 'CodeCosmosBlueLavaLamp',
    className: 'CodeCosmosBlueLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/blue-mesh.webp',
  },
  CodeCosmosSideBlueLavaLamp: {
    value: 'CodeCosmosSideBlueLavaLamp',
    className: 'CodeCosmosSideBlueLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/side-blue-mesh.webp',
  },
  CodeCosmosWhiteLavaLamp: {
    value: 'CodeCosmosWhiteLavaLamp',
    className: 'CodeCosmosWhiteLavaLampBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/white-mesh.webp',
  },
  /* CodeFever variants */
  CodeFeverAnonymous: {
    value: 'CodeFeverAnonymous',
    className: 'CodeFeverAnonymousBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-anonymous.jpg',
  },
  CodeFeverEriders: {
    value: 'CodeFeverEriders',
    className: 'CodeFeverEridersBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-eriders.jpg',
  },
  CodeFeverMinecraft: {
    value: 'CodeFeverMinecraft',
    className: 'CodeFeverMinecraftBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-minecraft.jpg',
  },
  CodeFeverPython: {
    value: 'CodeFeverPython',
    className: 'CodeFeverPythonBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-python.jpg',
  },
  CodeFeverStreepke: {
    value: 'CodeFeverStreepke',
    className: 'CodeFeverStreepkeBackground',
    previewUrl:
      'https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-streep.jpg',
  },
};
