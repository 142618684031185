import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';

export function SelectClass({ classGroups, formOption, itemInfo }) {
  const { control, errors } = formOption;
  const t = useFormatMessage();

  return (
    <div>
      <Label htmlFor="formCalendarItem_selectClass">
        {t('calendar.modal.selectClass')}
      </Label>
      <div>
        <Controller
          control={control}
          defaultValue={itemInfo?.classInfo ? [itemInfo.classInfo] : []}
          name="classGroups"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              hasErrors={Boolean(errors.classGroups)}
              inputId="formCalendarItem_selectClass"
              isMulti
              onBlur={onBlur}
              onChange={onChange}
              options={classGroups.map((classgroup) => ({
                value: classgroup.id,
                label: classgroup.name,
              }))}
              placeholder={t('calendar.modal.selectClass')}
              returnMethod="object"
              value={[...value]}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('side-calendar-classes.no-classes'),
            },
          }}
          type="select"
        />
        {errors.classGroups && (
          <p className="text-xs text-red-600">{errors.classGroups.message}</p>
        )}
      </div>
    </div>
  );
}
