import { generatePath, Link } from 'react-router-dom';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS, { profileTab } from 'utils/constants/urls';

export function TeamsSettingsMessage() {
  const t = useFormatMessage();

  return (
    <span>
      {t('global.default.via.profile.part1')}
      <Link
        className="text-blue-500 underline"
        rel="noopener noreferrer"
        target="_blank"
        to={generatePath(URLS.PROFILE_TAB, {
          tab: profileTab.TEAMS,
        })}
      >
        {t('global.default.via.profile.part2')}
      </Link>
    </span>
  );
}
