import { useCallback, useContext, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import useSchooladminArchiveClassGroup from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminArchiveClassGroup';
import useSchooladminCreateClassGroup from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminCreateClassGroup';
import useSchooladminClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroups';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import { UserContext } from 'providers/UserProvider';
import AddClassGroupDialog from './partials/AddClassGroupDialog';
import ArchiveClassGroupDialog from './partials/ArchiveClassGroupDialog';
import ManageClasses from './ManageClasses';

export default function ManageClassesContainer({}) {
  const [showArchived, setShowArchived] = useState(false);
  const [pageClasses, setPageClasses] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortClasses, setSortClasses] = useState({ name: sortOperation.ASC });
  const [filterClassgroups, setFilterClassgroups] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [classgroupData, setClassgroupData] = useState(null);
  const { isSmartSchoolUser, refetchOrganisationInfo } =
    useContext(UserContext);

  const canCreate = !isSmartSchoolUser;
  const canDelete = !isSmartSchoolUser;

  const { isLoading: isLoadingCreateClass, mutateAsync: createClassGroup } =
    useSchooladminCreateClassGroup();
  const debouncedFilterClassgroups = useDebounce(filterClassgroups, 500);

  const {
    isLoading: isLoadingArchiveClassGroup,
    mutateAsync: archiveClassGroupHook,
  } = useSchooladminArchiveClassGroup();

  const {
    data: classesData,
    isError: isErrorClasses,
    isLoading: isLoadingClasses,
    refetch: refetchClasses,
  } = useSchooladminClassGroups({
    filter: [
      {
        key: 'name',
        value: debouncedFilterClassgroups,
        operation: filterOperation.LIKE,
      },
      {
        key: 'archived',
        value: showArchived,
        operation: filterOperation.EQUAL,
      },
    ] // if showArchived == true, show all items
      .filter((item) => !(item.key === 'archived' && showArchived)),
    page: pageClasses - 1,
    size: itemsPerPage,
    sort: sortClasses,
  });

  const classgroups = classesData?.content;
  const totalPages = classesData?.pages;
  const totalItems = classesData?.total;

  const addClassGroup = async (name, onDismiss) => {
    await createClassGroup(name).then(async () => {
      void refetchClasses();
      void refetchOrganisationInfo();
      void onDismiss();
    });
  };

  const archiveClassGroup = async (onDismiss) => {
    if (classgroupData?.id) {
      await archiveClassGroupHook(classgroupData.id).then(() => {
        void refetchClasses();
        void onDismiss();
      });
    }
  };

  const handleCheckBoxClick = useCallback(
    (value) => {
      setShowArchived(value);
      setPageClasses(1);
    },
    [setPageClasses, setShowArchived],
  );

  useEffect(() => {
    void refetchClasses();
  }, [debouncedFilterClassgroups, refetchClasses]);

  return (
    <>
      <AddClassGroupDialog
        addClassGroup={addClassGroup}
        isLoading={isLoadingCreateClass}
        isOpen={isAddModalOpen}
        onDismiss={() => setIsAddModalOpen(false)}
      />
      <ArchiveClassGroupDialog
        archiveClassGroup={archiveClassGroup}
        isLoading={isLoadingArchiveClassGroup}
        isOpen={isArchiveModalOpen}
        selectClass={classgroupData}
        setIsArchiveModalOpen={setIsArchiveModalOpen}
        setSelectClass={setClassgroupData}
      />
      <ManageClasses
        canCreate={canCreate}
        canDelete={canDelete}
        classgroups={classgroups ?? []}
        currentPage={pageClasses}
        filter={debouncedFilterClassgroups}
        handleCheckBoxClick={handleCheckBoxClick}
        isError={isErrorClasses}
        isLoadingClasses={isLoadingClasses}
        itemsPerPage={itemsPerPage}
        openAddModal={() => setIsAddModalOpen(true)}
        openArchiveModal={() => setIsArchiveModalOpen(true)}
        setClassgroupData={setClassgroupData}
        setFilter={(value) => {
          setPageClasses(1);
          setFilterClassgroups(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPageClasses}
        setSort={setSortClasses}
        showArchived={showArchived}
        sort={sortClasses}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </>
  );
}
