import {
  linkFocusStyles,
  MultiScreenModeSlide,
} from '@ftrprf/tailwind-components';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import useFormatMessage from 'hooks/useFormatMessage';
import c from 'utils/c';
import { dataTestIds } from 'utils/dataTestIds';

export function CurrentSlide({
  currentSlide,
  lessonLength,
  onNextSlide,
  onPreviousSlide,
}) {
  const t = useFormatMessage();

  return (
    <>
      <MultiScreenModeSlide
        id={dataTestIds.multiScreen.currentSlide}
        isFullSlideViewer
        slide={currentSlide}
        test={dataTestIds.multiScreen.currentSlide}
      />
      <div className="flex gap-5 justify-center">
        <button
          className={c(
            currentSlide.index === 0 && 'invisible',
            'p-4',
            linkFocusStyles,
            'hover:ring-offset-2 hover:ring-2 hover:ring-accent-500 hover:outline-none',
          )}
          data-test={dataTestIds.multiScreen.goToPreviousSlide}
          onClick={onPreviousSlide}
          type="button"
        >
          <span className="sr-only">
            {t('multi-screen-mode.previous-slide')}
          </span>
          <ChevronRightIcon className="rotate-180 w-4" />
        </button>
        <button
          className={c(
            currentSlide.index >= lessonLength - 1 && 'invisible',
            'p-4',
            linkFocusStyles,
            'hover:ring-offset-2 hover:ring-2 hover:ring-accent-500 hover:outline-none',
          )}
          data-test={dataTestIds.multiScreen.goToNextSlide}
          onClick={onNextSlide}
          type="button"
        >
          <span className="sr-only">{t('multi-screen-mode.next-slide')}</span>
          <ChevronRightIcon className="w-4" />
        </button>
      </div>
    </>
  );
}
