import useFormatMessage from '../../../hooks/useFormatMessage';

import { MemberRole } from './MemberRole';

export function UserGroupInfo({ userGroupInfo }) {
  const t = useFormatMessage();

  return (
    <div className="px-4 h-full">
      <h3 className="mt-4 mb-1 text-2xl font-bold">
        {t('styled-slide-viewer.userGroupInfo')}
      </h3>
      <ul className="mx-auto">
        {userGroupInfo.userRoles.map((role) => (
          <MemberRole key={role.roleId} role={role} />
        ))}
      </ul>
    </div>
  );
}
