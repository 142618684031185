import useTeacherOrganizationContent from 'hooks/api/plannerService/teacher/queries/useTeacherOrganizationContent';
import { filterOperation } from 'utils/constants/filter';
import { useCallback, useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { usePopupContext } from 'providers/PopupProvider';
import { LessonInfo } from '../../Calendar/partials/LessonInfo';
import { getLessonsForChapter } from '../../Calendar/utils/getLessonsForChapter';
import { getChaptersForProgram } from '../../Calendar/utils/getChaptersForProgram';
import { getChapterItemsForProgram } from '../../Calendar/utils/getChapterItemsForChapter';

export function SelectLesson() {
  const { organization } = useContext(UserContext);
  const { formOptions, teacherPrograms } = usePopupContext();
  const { watch } = formOptions;

  const { data: organizationContent } = useTeacherOrganizationContent({
    filter: [
      {
        key: 'organizationId',
        operation: filterOperation.EQUAL,
        value: `${organization?.id}`,
      },
    ],
    page: 0,
    size: 50,
  });
  const ownContent = organizationContent?.content;

  const { chapter, program } = watch();
  const findChapters = useCallback(
    (programId) => getChaptersForProgram(teacherPrograms, programId),
    [teacherPrograms],
  );

  const findChapterItems = useCallback(
    (chapterId) =>
      getChapterItemsForProgram(program?.value, findChapters, chapterId),
    [findChapters, program?.value],
  );

  const findLessons = useCallback(
    (chapterItemId) =>
      getLessonsForChapter(chapter?.value, findChapterItems, chapterItemId),
    [findChapterItems, chapter?.value],
  );

  return (
    <LessonInfo
      findChapterItems={findChapterItems}
      findChapters={findChapters}
      findLessons={findLessons}
      formOption={formOptions}
      itemInfo={{
        isTeams: false,
        isUpdate: false,
        itemIsInThePast: false,
      }}
      ownContent={ownContent}
      setIsExam={() => false}
      teacherPrograms={teacherPrograms}
    />
  );
}
