import { gql } from 'graphql-request';

export const TEACHER_STUDENTS_BY_CALENDAR_ITEM_ID = gql`
  query teacherStudentsByCalendarItemId(
    $calendarItemId: Int!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherStudentsByCalendarItemId(
      calendarItemId: $calendarItemId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        userName
        firstName
        lastName
        fullName
        displayName
        avatar
        language
        email
        enabled
        roles
        classGroups {
          id
          name
          archived
          amountOfStudents
        }
        assessment {
          id
          userId
          status
        }
        reportOverallScores {
          questionScoresEarned
          questionScoresMax
          scratchScoresSuccessful
          scratchScoresTotal
          questionsToGrade
          answeredQuestions
          amountOfOpenQuestions
          amountOfMcQuestions
          amountOfQuestions
          viewMode
        }
      }
    }
  }
`;
