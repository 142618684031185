import { Loader as LoaderTW } from '../../Loader';
import { sizesClassNames } from './classNameHelpers';

export default function Loader({ color, loaderClassName, secondary, size }) {
  return (
    <div className={loaderClassName}>
      <LoaderTW
        blue={
          (secondary && color === 'blue') || (!secondary && color === 'white')
        }
        className={sizesClassNames[size].icon}
        white={
          (secondary && color === 'white') || (!secondary && color === 'blue')
        }
      />
    </div>
  );
}
