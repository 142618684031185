import { useCallback, useMemo } from 'react';
import { useTable } from 'react-table';

import c from '../utils/c';

function UnstyledTable({
  cellClassName,
  className,
  columnClassName,
  columns,
  data,
  headerClassName,
  onRowClick: _onRowClick,
  rowClassName,
}) {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const onRowClick = useCallback(
    (row) => {
      if (_onRowClick) {
        _onRowClick(row);
      }
    },
    [_onRowClick],
  );

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } =
    useTable({
      columns: memoizedColumns || [],
      data: memoizedData || [],
    });

  return (
    <table className={className} {...getTableProps()}>
      <thead className="rounded-t-md">
        {headerGroups.map((headerGroup) => (
          <tr
            key={headerGroup}
            {...headerGroup.getHeaderGroupProps([
              { className: headerClassName },
            ])}
          >
            {headerGroup.headers.map((column) => (
              <th
                key={column}
                {...column.getHeaderProps([
                  {
                    className: columnClassName,
                  },
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              key={row}
              className={c(!!_onRowClick && 'hover:bg-gray-200', rowClassName)}
              onClick={() => onRowClick(row.original)}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <td
                  key={cell}
                  {...cell.getCellProps([{ className: cellClassName }])}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function Table({ columns, data, onRowClick, rowClassName }) {
  return (
    <UnstyledTable
      cellClassName="p-2 whitespace-nowrap"
      className="overflow-x-auto h-8 w-full border border-gray-200 rounded-md"
      columnClassName="p-2 font-normal"
      columns={columns}
      data={data}
      headerClassName="uppercase text-xs leading-4 tracking-wide text-left rounded-t text-gray-600 bg-gray-200 h-8"
      onRowClick={onRowClick}
      rowClassName={c(rowClassName, 'border-b border-gray-200')}
    />
  );
}
