import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { Pagination, ResponsiveTable } from '@ftrprf/tailwind-components';
import { useEffect, useState } from 'react';
import { dataTestIds } from 'utils/dataTestIds';
import useFormatMessage from 'hooks/useFormatMessage';
import { getHeaders } from './getHeaders';
import { ChapterItemReportTableRow } from './ChapterItemReportTableRow';

export function ChapterItemReportTable({
  activeScoreMode,
  activeViewMode,
  chapterReports,
  classGroups,
  currentPage,
  data,
  isLoading,
  itemsPerPage,
  setCurrentPage,
  setDialogContent,
  setIsDialogOpen,
  setItemsPerPage,
}) {
  const t = useFormatMessage();
  const [headers, setHeaders] = useState([
    {
      key: 'name',
      label: t('global.name'),
      orderable: true,
    },
  ]);
  const { chapterItems } = chapterReports?.[0] || {};

  useEffect(() => {
    if (chapterItems) {
      setHeaders(getHeaders(t, chapterItems));
    }
  }, [chapterItems, t]);

  return (
    <TableSkeleton loading={isLoading}>
      {!isLoading && (
        <ResponsiveTable
          emptyText={t('chapterItemReport.no-results-found')}
          hasBorders={false}
          headers={headers}
          mobileBreakpoint={768}
          mobileHeaderWidth={160}
          test={dataTestIds.page.learn.chapterItemProgress.table.self}
          turnHeaders
          wrapperClassName="overflow-x-auto"
        >
          {chapterReports?.map((chapterReport, index) => (
            <ChapterItemReportTableRow
              key={`report_${chapterReport.id}_${index + 1}`}
              activeScoreMode={activeScoreMode}
              activeViewMode={activeViewMode}
              allClassGroups={classGroups}
              chapterReport={chapterReport}
              setDialogContent={setDialogContent}
              setIsDialogOpen={setIsDialogOpen}
            />
          ))}
        </ResponsiveTable>
      )}

      {Boolean(chapterItems?.length) && (
        <Pagination
          className="mt-12"
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsPerPageOptions={[10, 20, 50, 100, 500]}
          setCurrentPage={(page) => setCurrentPage(page)}
          setItemsPerPage={setItemsPerPage}
          totalItems={data?.total}
          totalPages={data?.pages}
        />
      )}
    </TableSkeleton>
  );
}
