import { createContext, useMemo } from 'react';

export const TailwindContext = createContext({});

export function TailwindProvider({ children, t }) {
  const context = useMemo(
    () => ({
      t,
    }),
    [t],
  );

  return (
    <TailwindContext.Provider value={context}>
      {children}
    </TailwindContext.Provider>
  );
}
