import { gql } from 'graphql-request';

export const UPDATE_OWN_USER_INFO = gql`
  mutation updateOwnUserInfo($input: UpdateOwnUserInfoInput!) {
    updateOwnUserInfo(input: $input) {
      statusCode
      id
      message
    }
  }
`;
