import { useQuery } from 'react-query';

import { USER_INFO } from 'api/plannerService/common/queries/userInfo';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUserInfo(initialToken) {
  const response = useQuery(
    'userInfo',
    () => plannerServiceQueryClient.request(USER_INFO, {}),
    {
      enabled: !!initialToken,
    },
  );

  return {
    ...response,
    data: response?.data?.userInfo,
  };
}
