import { FaSearch, FaTimes } from 'react-icons/fa';

import { Button, Input, Label } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export function ExerciseFilter({ filterTitle, isSearching, setFilterTitle }) {
  const t = useFormatMessage();

  return (
    <div className="flex mt-2 min-w-56 max-w-md">
      <div className="relative flex-grow ">
        <Label
          className="sr-only"
          htmlFor="exercisesoverview-filterExercisesOnTitle"
        >
          {t('exercises-overview.label.filter')}
        </Label>
        <Input
          id="exercisesoverview-filterExercisesOnTitle"
          onChange={(e) => setFilterTitle(e.target.value)}
          placeholder={t('exercises-overview.filter_placeholder')}
          value={filterTitle}
        />
        <div className="absolute h-full top-0 flex items-center right-3 text-gray-500 text-sm">
          {isSearching ? (
            <Button onClick={() => isSearching && setFilterTitle('')}>
              <FaTimes />
            </Button>
          ) : (
            <FaSearch />
          )}
        </div>
      </div>
    </div>
  );
}
