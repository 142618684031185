const colors = [
  { label: 'black', color: '#000000' },
  { label: 'gray', color: '#a3a3a3' },
  { label: 'red', color: '#dc2626' },
  { label: 'orange', color: '#f97316' },
  { label: 'yellow', color: '#facc15' },
  { label: 'green', color: '#86efac' },
  { label: 'lime', color: '#a3e635' },
  { label: 'emerald', color: '#10b981' },
  { label: 'sky', color: '#7dd3fc' },
  { label: 'blue', color: '#3b82f6' },
  { label: 'pink', color: '#f9a8d4' },
  { label: 'rose', color: '#fb7185' },
  { label: 'purple', color: '#a21caf' },
];

export const badgeColors = [
  { label: 'gray', color: '#d1d5db' },
  { label: 'red', color: '#fca5a5' },
  { label: 'yellow', color: '#fde047' },
  { label: 'green', color: '#86efac' },
  { label: 'blue', color: '#93c5fd' },
  { label: 'indigo', color: '#a5b4fc' },
  { label: 'purple', color: '#d8b4fe' },
  { label: 'pink', color: '#f9a8d4' },
];

export const prettyOrderBadgeColours = [
  'purple',
  'green',
  'yellow',
  'pink',
  'indigo',
  'red',
  'blue',
  'gray',
];

export default colors;
