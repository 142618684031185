import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_CREATE_PERSONALIZED_TESTS } from 'api/plannerService/teacher/mutations/teacherCreatePersonalizedTests';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherCreatePersonalizedTests() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ assessmentProcedureId, classId }) =>
      plannerServiceQueryClient.request(TEACHER_CREATE_PERSONALIZED_TESTS, {
        classId,
        assessmentProcedureId,
      }),
    {
      mutationKey: 'teacherCreatePersonalizedTests',
      // returns 200, but with message No users available for personalized tests
      onSuccess: (res) => {
        if (
          res.teacherCreatePersonalizedTests.message ===
          'No users available for personalized tests'
        ) {
          toast(TOASTTYPES.NOUSERSWARNING, {});
        } else {
          toast(TOASTTYPES.POSTSUCCESS, {
            str: t('global.personality.test.plural'),
          });
        }
      },
      onError: () => {
        toast(TOASTTYPES.POSTERROR, {
          str: t('global.personality.test.plural'),
        });
      },
    },
  );

  return mutation;
}
