import { useContext } from 'react';
import c from '../../../utils/c';
import useScrollTopRef from '../../../hooks/useScrollTopRef';

import {
  SlideViewerContext,
  SlideViewerSlide,
} from '../../slideviewer/SlideViewer';
import { SLIDE_BACKGROUNDS } from './slideBackgrounds';
import { useEditorStyleSheet } from '../../../hooks/useEditorStyleSheet';

export default function SlideViewerCenter({ currentSlide }) {
  const scrollRef = useScrollTopRef(currentSlide);
  const { isCk5 } = useContext(SlideViewerContext);
  useEditorStyleSheet(isCk5);

  return (
    <div
      ref={scrollRef}
      className={c(
        'w-full flex flex-col items-center justify-center overflow-auto h-full z-10 py-12 bg-cover bg-center',
        isCk5 ? 'bg-gray-100 relative px-[33px] lg:px-[66px]' : 'pb-20',
        SLIDE_BACKGROUNDS[currentSlide.background]?.className,
      )}
    >
      <SlideViewerSlide
        className={c(
          'h-full block relative SlideViewerSlide flex-grow',
          isCk5 ? 'w-full' : 'w-9/12',
        )}
      />
    </div>
  );
}
