import {
  Actions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';
import { TrashIcon } from '@heroicons/react/24/outline';
import useAdminUnlinkTag from 'hooks/api/plannerService/admin/mutations/useAdminUnlinkTag';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export default function ManageItemOnTag({ isOpen, onDismiss = () => {}, tag }) {
  const t = useFormatMessage();

  const { mutateAsync: unlinkItem } = useAdminUnlinkTag();

  const itemActions = (item, chapter = false) => [
    {
      icon: <TrashIcon className="h-4 w-4 mr-2" />,
      label: t('global.unlink.what', { what: t('global.TAG.singular') }),
      onClick: () => {
        const unlinkValues = { id: tag.id };

        if (chapter) {
          unlinkValues.chapterId = item.id;
        } else {
          unlinkValues.chapterItemId = item.id;
        }

        unlinkItem(unlinkValues);
      },
    },
  ];

  if (!tag) {
    return null;
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader>
        {`${t('global.manage.what', { what: t('global.TAG.singular') })} - ${
          tag.name
        }`}
      </DialogHeader>

      <DialogContent className="flex flex-col gap-10">
        <div>
          <h2 className="font-bold">{t('global.CHAPTER.plural')}</h2>

          <ResponsiveTable
            childrenLength={tag.chapters.length}
            emptyText={t('global.empty.link', {
              what: t('global.CHAPTER.plural'),
              thing: tag.name,
            })}
            headers={[
              {
                key: 'name',
                label: t('global.name'),
                orderable: false,
              },
              {
                key: 'actions',
                label: t('global.actions'),
                orderable: false,
                className: 'max-w-fit sr-only',
              },
            ]}
          >
            {tag.chapters.map((chapter) => (
              <ResponsiveTableRow key={chapter.id}>
                <ResponsiveTableCol>{chapter.name}</ResponsiveTableCol>

                <ResponsiveTableCol className="p-2 text-right">
                  <Actions items={itemActions(chapter, true)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            ))}
          </ResponsiveTable>
        </div>

        <div>
          <h2 className="font-bold">{t('global.CHAPTERITEM.plural')}</h2>

          <ResponsiveTable
            childrenLength={tag.chapters.length}
            emptyText={t('global.empty.link', {
              what: t('global.CHAPTERITEM.plural'),
              thing: tag.name,
            })}
            headers={[
              {
                key: 'name',
                label: t('global.name'),
                orderable: false,
              },
              {
                key: 'actions',
                label: t('global.actions'),
                orderable: false,
                className: 'max-w-fit sr-only',
              },
            ]}
          >
            {tag.chapterItems.map((chapterItem) => (
              <ResponsiveTableRow key={chapterItem.id}>
                <ResponsiveTableCol>{chapterItem.name}</ResponsiveTableCol>

                <ResponsiveTableCol className="p-2 text-right">
                  <Actions items={itemActions(chapterItem)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            ))}
          </ResponsiveTable>
        </div>
      </DialogContent>

      <DialogActions>
        <ActionStyledAsButton
          onClick={onDismiss}
          test={dataTestIds.modal.editTag.button.cancel}
          variant="text"
        >
          {t('global.close')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
