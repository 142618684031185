import {
  ConfirmationDialog,
  MultiCollapsable,
} from '@ftrprf/tailwind-components';
import {
  TrashIcon,
  TagIcon,
  PencilIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import useAdminDeleteChapterItem from 'hooks/api/plannerService/admin/mutations/useAdminDeleteChapterItem';
import useAdminUpdateChapterItem from 'hooks/api/plannerService/admin/mutations/useAdminUpdateChapterItem';
import useFormatMessage from 'hooks/useFormatMessage';
import { useContext, useState } from 'react';
import { UserContext } from 'providers/UserProvider';
import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';
import { dataTestIds } from 'utils/dataTestIds';
import getGenericSessionId from 'utils/getGenericSessionId';
import UpdateChapterItemDialog from '../../partials/UpdateChapterItemDialog';

export function ChapterItem({
  chapterItem,
  color,
  refetch,
  setChapterItemId,
  setIsManageTagOnItemModalOpen,
}) {
  const t = useFormatMessage();

  const { isAdmin } = useContext(UserContext);

  const [isUpdateChapterItemModalOpen, setIsUpdateChapterItemModalOpen] =
    useState(false);
  const [isDeleteChapterItemModalOpen, setIsDeleteChapterItemModalOpen] =
    useState(false);

  const { mutateAsync: adminUpdateChapterItem } = useAdminUpdateChapterItem();
  const { mutateAsync: adminDeleteChapterItem } = useAdminDeleteChapterItem();

  const removeChapterItem = async () => {
    await adminDeleteChapterItem({ id: chapterItem?.id });
    refetch();
  };
  const editChapterItem = async (data, file) => {
    await adminUpdateChapterItem({
      id: chapterItem?.id,
      input: data,
      file,
    });
    refetch();
  };

  const actionsForchapterItem = (chapterItem) => {
    const { id } = chapterItem;

    let actions = [];
    if (isAdmin) {
      actions = [
        {
          icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('manage-program-details.edit-chapter-item'),
          testName: dataTestIds.page.manage.table.button.edit,
          onClick: () => {
            setChapterItemId(id);
            setIsUpdateChapterItemModalOpen(true);
          },
        },
        {
          icon: <TagIcon className="h-4 w-4 inline-block mr-2" />,
          label: `${t('global.manage.what', {
            what: t('global.TAG.plural'),
          })}`,
          testName: dataTestIds.page.manage.table.button.manageTag,
          onClick: () => {
            setChapterItemId(id);
            setIsManageTagOnItemModalOpen(true);
          },
        },
        {
          icon: <TrashIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('delete-chapter-item-dialog.header'),
          testName: dataTestIds.page.manage.table.button.delete,
          onClick: () => {
            setChapterItemId(id);
            setIsDeleteChapterItemModalOpen(true);
          },
        },
      ];
    }

    if (!isAdmin) {
      actions = [
        {
          icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('global.view'),
          href: `${generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
            studioId: chapterItem.studioId,
            viewMode: viewModes.PROJECTION,
            sessionId: getGenericSessionId(chapterItem.studioId),
          })}?return=/manage/programs/21`,
        },
      ];
    }

    return actions;
  };

  return (
    <>
      <ConfirmationDialog
        content={t('global.delete.what.confirm', {
          what: t('global.CHAPTERITEM.singular'),
        })}
        isOpen={isDeleteChapterItemModalOpen}
        onConfirm={removeChapterItem}
        onDismiss={() => {
          setChapterItemId(null);
          setIsDeleteChapterItemModalOpen(false);
        }}
      />
      <UpdateChapterItemDialog
        chapterItem={chapterItem}
        color={color}
        isOpen={isUpdateChapterItemModalOpen}
        onConfirm={editChapterItem}
        onDismiss={() => {
          setIsUpdateChapterItemModalOpen(false);
          setChapterItemId(null);
        }}
      />

      <MultiCollapsable
        key={chapterItem.id}
        actions={actionsForchapterItem(chapterItem)}
        child={1}
        hasDropDown={false}
        image={chapterItem.blobUri}
        mainText={chapterItem.name}
        subText={
          isAdmin ? `${t('global.weight')} ${chapterItem.weight}` : undefined
        }
        tags={chapterItem?.tags}
      />
    </>
  );
}
