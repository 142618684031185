import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addYears, subYears } from 'date-fns';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import DateRangeDialog from 'components/DateRangeDialog';

import useTeacherLessons from 'hooks/api/plannerService/teacher/queries/useTeacherLessons';
import useTeacherOrganizationUsers from 'hooks/api/plannerService/teacher/queries/useTeacherOrganizationUsers';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import { backendAcceptedDateFormat } from 'utils/timeHelpers';
import ftrprfUserRoles from '../../../utils/constants/ftrprfUserRoles';
import LessonsOverview from './LessonsOverview';

export default function LessonsOverviewContainer() {
  // Final items
  const { classGroupId } = useParams();
  const MAX_ITEMS_USERS = 500;

  // State
  const [nameFilter, setNameFilter] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [sort, setSort] = useState({ endTime: sortOperation.DESC });
  const [dateRange, setDateRange] = useState({
    startDate: subYears(new Date(), 1),
    endDate: addYears(new Date(), 1),
    key: 'selection',
  });
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState(false);

  const debouncedNameFilter = useDebounce(nameFilter, 300);

  const [filterTeacher, setFilterTeacher] = useState();

  const { data: teachersData } = useTeacherOrganizationUsers({
    filter: [
      {
        key: 'roles',
        value: [ftrprfUserRoles.SCHOOLTEACHER],
        operation: filterOperation.EQUAL,
      },
    ],
    page: 0,
    size: MAX_ITEMS_USERS,
    sort: { firstname: sortOperation.ASC },
  });
  const teachers = teachersData?.content;

  const filterObj = [
    {
      key: 'classGroupId',
      value: classGroupId,
      operation: filterOperation.EQUAL,
    },
    { key: 'userId', value: filterTeacher, operation: filterOperation.EQUAL },
    {
      key: 'startTime',
      value: backendAcceptedDateFormat(dateRange.startDate),
      operation: filterOperation.GREATER_THAN,
    },
    {
      key: 'endTime',
      value: backendAcceptedDateFormat(dateRange.endDate),
      operation: filterOperation.LESS_THAN,
    },
    {
      key: 'chapterItemName',
      value: debouncedNameFilter,
      operation: filterOperation.LIKE,
    },
  ];
  const {
    data: lessonsData,
    isLoading,
    refetch,
  } = useTeacherLessons({
    filter: filterObj.filter((item) => item.value),
    page: page - 1,
    size: itemsPerPage,
    sort,
  });
  const lessons = lessonsData?.content;
  const pages = lessonsData?.pages;
  const totalItems = lessonsData?.total;

  useEffect(() => {
    refetch();
  }, [refetch, debouncedNameFilter, dateRange, filterTeacher, page, sort]);

  return (
    <>
      <DateRangeDialog
        dateRange={dateRange}
        isOpen={isDateRangeDialogOpen}
        onDismiss={() => setIsDateRangeDialogOpen(false)}
        onSubmit={() => {
          setIsDateRangeDialogOpen(false);
        }}
        setDateRange={setDateRange}
      />
      <LessonsOverview
        currentPage={page}
        dateRange={dateRange}
        filterTeacher={filterTeacher}
        isLoading={isLoading}
        itemsPerPage={itemsPerPage}
        lessons={lessons}
        openDateRangeDialog={() => setIsDateRangeDialogOpen(true)}
        pages={pages}
        setFilter={(value) => {
          setPage(1);
          setNameFilter(value);
        }}
        setFilterTeacher={(item) => {
          setPage(1);
          if (item === filterTeacher) {
            setFilterTeacher();
          } else setFilterTeacher(item);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        setSort={setSort}
        sort={sort}
        teachers={teachers}
        totalItems={totalItems}
      />
    </>
  );
}
