export default {
  'global.required-field': 'Required field',
  'global.search': 'Search',
  'header.impersonated': 'Impersonated',
  'pagination.ellipsis': 'Ellipsis',
  'pagination.go-to-page': 'Go to page {number}',
  'pagination.items-per-page': 'items per page',
  'pagination.next': 'Next',
  'pagination.previous': 'Previous',
  'pagination.results-info':
    'Result {startItem} to {endItem} of {totalItems} results',
};
