import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import useSchooladminPrograms from 'hooks/api/plannerService/schooladmin/queries/useSchooladminPrograms';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import ManagePrograms from './ManagePrograms';

export default function ManageProgramsContainerSchooladmin() {
  const [programData, setProgramData] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ name: sortOperation.ASC });

  const debouncedFilterPrograms = useDebounce(filter, 500);

  const {
    data: programsData,
    isError: isErrorPrograms,
    isLoading: isLoadingPrograms,
    refetch: refetchPrograms,
  } = useSchooladminPrograms({
    filter: [
      {
        key: 'name',
        value: debouncedFilterPrograms,
        operation: filterOperation.LIKE,
      },
    ],
    page: page - 1,
    size: itemsPerPage,
    sort,
  });

  const programs = programsData?.content;
  const totalPages = programsData?.pages;
  const totalItems = programsData?.total;

  useEffect(() => {
    void refetchPrograms();
  }, [debouncedFilterPrograms, refetchPrograms]);

  return (
    <ManagePrograms
      currentPage={page}
      filter={debouncedFilterPrograms}
      isErrorLoadingPrograms={isErrorPrograms}
      isLoadingPrograms={isLoadingPrograms}
      itemsPerPage={itemsPerPage}
      programData={programData}
      programs={programs ?? []}
      setFilter={(value) => {
        setPage(1);
        setFilter(value);
      }}
      setItemsPerPage={setItemsPerPage}
      setPage={setPage}
      setProgramData={setProgramData}
      setSort={setSort}
      sort={sort}
      totalItems={totalItems}
      totalPages={totalPages}
    />
  );
}
