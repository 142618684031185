import Header from 'components/Header/Header';

export default function LoggedOutLayout({ children }) {
  return (
    <div className="w-full">
      <main className="h-full">
        <Header isEmpty />
        {children}
      </main>
    </div>
  );
}
