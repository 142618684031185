import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ResetPasswordDialog } from 'components/ResetPasswordDialog';

import useTeacherResetStudentPassword from 'hooks/api/plannerService/teacher/mutations/useTeacherResetStudentPassword';
import useTeacherStudentById from 'hooks/api/plannerService/teacher/queries/useTeacherStudentById';

import UserProfileSkeleton from './partials/UserProfileSkeleton';
import UserProfile from './UserProfile';
import determineRoles from '../../utils/determineUserRoles';

export default function UserProfileTeacherContainer() {
  const { classGroupId, studentId: userId } = useParams();
  const { data: user, isLoading, refetch } = useTeacherStudentById(userId);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const { isLoading: isResetting, mutateAsync: resetPassword } =
    useTeacherResetStudentPassword();
  const updateStudent = () => {
    refetch();
  };

  const mappedUser = { ...determineRoles(user) };

  return (
    <UserProfileSkeleton canDelete={false} isLoading={isLoading}>
      {isResetPasswordDialogOpen && (
        <ResetPasswordDialog
          isOpen={isResetPasswordDialogOpen}
          isResetting={isResetting}
          onDismiss={() => setIsResetPasswordDialogOpen(false)}
          resetPassword={(newPassword) => {
            resetPassword({
              studentId: userId,
              newPassword,
            });
          }}
        />
      )}
      <UserProfile
        canDelete={false}
        canEdit={false}
        canResetPassword={mappedUser.isFtrprfUser}
        classGroupId={classGroupId}
        isLoading={isLoading}
        openResetModal={() => setIsResetPasswordDialogOpen(true)}
        updateUser={updateStudent}
        user={mappedUser}
      />
    </UserProfileSkeleton>
  );
}
