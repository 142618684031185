import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';

export * from './ErrorMessage';
export * from './SuccessMessage';

export const MessageTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
