import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_SEATS_BY_PROGRAM_ID } from 'api/plannerService/schooladmin/queries/schooladminSeatsByProgramId';

export default function useSchooladminSeatsByProgramId(
  programId,
  periodId,
  fullName,
  classIds,
) {
  const toast = useToast();
  const t = useFormatMessage();

  const variables = { programId, periodId };
  if (fullName) variables.fullName = fullName;
  if (classIds) variables.classIds = classIds;

  const response = useQuery(
    ['schooladminSeatsByProgramId', programId],
    () =>
      plannerServiceQueryClient.request(
        SCHOOLADMIN_SEATS_BY_PROGRAM_ID,
        variables,
      ),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminSeatsByProgramId,
  };
}
