import { useContext } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ContentCard } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';
import contentTypes from 'utils/constants/contentTypes';
import publishStatuses from 'utils/constants/publishStatuses';
import URLS from 'utils/constants/urls';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';

import padStart from 'lodash-es/padStart';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import viewModes from 'utils/constants/viewModes';
import { generateStudentResultPagePath } from 'utils/generateUrlPaths';
import { ChapterItemEmptyState } from './ChapterItemEmptyState';
import { ContentActions } from './ContentActions';
import { getEarliestCalendarItem } from './getEarliestCalendarDate';

export function OwnContentOverview({
  itemInfo,
  lessons,
  openCalendarModal,
  openQuickPlanModal,
  pageTitle,
  setItemInfo,
}) {
  const t = useFormatMessage();

  const { id: userId, isTeacher, organization } = useContext(UserContext);

  if (!lessons || lessons?.length === 0) {
    if (isTeacher) return <ChapterItemEmptyState ownContent />;

    return <p>{t('global.empty')}</p>;
  }

  return (
    <ul className="w-full grid gap-6 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
      {lessons.map((lesson) => {
        const sessionIdTeacher = lesson.calendarItems
          ? getEarliestCalendarItem(lesson.calendarItems).sessionId
          : `1cc3b91a-bfb5-4831-b6eb-f849c6${padStart(
              lesson.studioId,
              6,
              String(0),
            )}`;
        const viewUrl = isTeacher
          ? generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
              studioId: lesson.studioId,
              viewMode: viewModes.CLASSICAL,
              sessionId: sessionIdTeacher,
            })
          : generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
              studioId: lesson.studioId,
              viewMode: lesson.calendarItemViewMode,
              sessionId: lesson.calendarItemSessionId,
            });

        const resultsUrl = isTeacher
          ? generatePath(URLS.OWN_LESSONS_RESULTS, {
              studioId: lesson.studioId,
            })
          : generateStudentResultPagePath(
              lesson.calendarItemId,
              nonNumericProgramIds.OWN, // because we come from own-content
              userId,
              lesson.calendarItemSessionId,
            );

        // eslint-disable-next-line no-param-reassign
        lesson.hasCalendarItems = isTeacher
          ? lesson.hasCalendarItems
          : !!lesson.calendarItemId;

        return (
          <li key={lesson.id} className="w-full" data-test="ownContentItem">
            <ContentCard
              defaultImage={EmptyImage}
              extraActions={
                isTeacher && (
                  <div className="pt-1 px-1 text-right">
                    <ContentActions
                      chapterItemId={lesson.id}
                      document={lesson.document}
                      hasPlanButtons={organization.showCalendar}
                      isExam={lesson.type === contentTypes.EXAM}
                      itemInfo={itemInfo}
                      openCalendarModal={openCalendarModal}
                      openQuickPlanModal={openQuickPlanModal}
                      ownContent
                      quickplanInfo={{
                        lessonTitle: lesson.title,
                        pageTitle,
                      }}
                      setItemInfo={setItemInfo}
                      studioId={lesson?.studioId}
                    />
                  </div>
                )
              }
              hasActions
              hideOverflow={false}
              image={
                isTeacher ? lesson?.imageUri : lesson.lessonContentImageUri
              }
              title={isTeacher ? lesson.title : lesson.lessonContentTitle}
            >
              <div className="w-full flex flex-grow justify-between align-middle h-8">
                {(lesson?.status !== publishStatuses.CLOSED || isTeacher) && (
                  <>
                    <ContentCard.Action className="flex flex-grow justify-center">
                      <Link
                        className="w-full h-full self-center pt-[0.625rem]"
                        to={viewUrl || ''}
                      >
                        {t('global.view')}
                      </Link>
                    </ContentCard.Action>
                    <ContentCard.Action className="flex flex-grow justify-center">
                      <Link
                        className={c(
                          'w-full h-full self-center pt-[0.625rem]',
                          lesson.hasCalendarItems ||
                            'opacity-30 cursor-not-allowed text-gray-500',
                        )}
                        to={lesson.hasCalendarItems ? resultsUrl || '' : null}
                      >
                        {t('content.results')}
                      </Link>
                    </ContentCard.Action>
                  </>
                )}
              </div>
            </ContentCard>
          </li>
        );
      })}
    </ul>
  );
}
