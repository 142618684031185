/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import useFormatMessage from '../hooks/useFormatMessage';

import isArray from '../utils/isArray';
import isObject from '../utils/isObject';
import c from '../utils/c';

const customStyles = (hasErrors = false) => ({
  control: (styles) => ({
    ...styles,
    ...(hasErrors && { border: '2px solid rgb(239 68 68)' }),
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'black',
  }),
  input: (provided) => ({
    ...provided,
    '> input': {
      boxShadow: 'none',
    },
    boxShadow: 'none',
  }),
});

function transformToObject(option) {
  return { key: `${option}`, label: `${option}`, value: `${option}` };
}

function transformArrayOptions(options) {
  if (options.length === 0) return [];

  return options.map((option) =>
    isObject(option) ? option : transformToObject(option),
  );
}

function transformNonArrayOptions(options) {
  return isObject(options) ? options : transformToObject(options);
}

const transformOptions = (options) =>
  isArray(options)
    ? transformArrayOptions(options)
    : transformNonArrayOptions(options);

const transformSelectedOptions = (selectedOptions, returnMethod) => {
  if (!selectedOptions) {
    return null;
  }

  if (returnMethod === 'object') {
    return isArray(selectedOptions)
      ? selectedOptions.map((x) => x)
      : selectedOptions;
  }

  return isArray(selectedOptions)
    ? selectedOptions.map((x) => x.value)
    : selectedOptions.value;
};

export function Dropdown({
  // the eslint disable on the next line is because we use props spreading
  // eslint-disable-next-line no-unused-vars
  async = null,
  className = 'w-full',
  creatable = null,
  // the eslint disable on the next line is because we use props spreading
  // eslint-disable-next-line no-unused-vars
  defaultOptions = null,
  defaultValue = [],
  description = null,
  descriptionClassName = 'mt-2 text-sm text-gray-500',
  disabled = false,
  endgoal = null,
  hasErrors = false,
  isMulti = false,
  // the eslint disable on the next line is because we use props spreading
  // eslint-disable-next-line no-unused-vars
  loadOptions = null,
  menuPlacement = 'auto',
  onChange = () => {},
  options = [],
  placeholder = '',
  returnMethod = null,
  value = null,
  ...props
}) {
  const t = useFormatMessage();

  if (isObject(options) && !options.key && !options.label && !options.value) {
    throw new Error(
      `options is an object and does not contain all of following keys: "key, label, value"`,
    );
  }

  const transformedOptions = useMemo(() => {
    if (options.length) {
      return transformOptions(options);
    }

    return options;
  }, [options]);

  const transformedValue = useMemo(() => {
    if (!value) {
      return null;
    }

    if (isArray(value)) {
      return transformOptions(value);
    }

    if (isObject(value)) {
      return value;
    }

    if (isObject(transformedOptions[0])) {
      return transformedOptions.find((o) => o.value === value);
    }

    return transformOptions([value])[0];
  }, [value, transformedOptions]);

  if (creatable) {
    return (
      <div>
        <CreatableSelect
          aria-describedby={`select_for_${props.inputId}`}
          className={c('react-select-container', className)}
          defaultValue={transformOptions(defaultValue)}
          isDisabled={disabled}
          isMulti={isMulti}
          menuPlacement={menuPlacement}
          noOptionsMessage={() => t('dropdown.no_results')}
          onChange={(selectedOptions) =>
            onChange(transformSelectedOptions(selectedOptions, returnMethod))
          }
          options={transformedOptions}
          placeholder={placeholder}
          styles={customStyles(hasErrors)}
          {...props}
        />
        <p className={descriptionClassName} id={`select_for_${props.inputId}`}>
          {description}
        </p>
      </div>
    );
  }

  if (endgoal) {
    return (
      <div>
        <Select
          aria-describedby={`select_for_${props.inputId}`}
          className={c('react-select-container', className)}
          defaultValue={transformOptions(defaultValue)}
          isDisabled={disabled}
          isMulti={isMulti}
          noOptionsMessage={() => t('dropdown.no_results')}
          onChange={(selectedOptions) =>
            onChange(transformSelectedOptions(selectedOptions, returnMethod))
          }
          options={transformedOptions}
          placeholder={placeholder}
          styles={customStyles(hasErrors)}
          {...props}
          menuPlacement={menuPlacement}
        />
        <p className={descriptionClassName} id={`select_for_${props.inputId}`}>
          {description}
        </p>
      </div>
    );
  }

  return (
    <div>
      <Select
        aria-describedby={`select_for_${props.inputId}`}
        className={c('react-select-container', className)}
        classNamePrefix="react-select"
        defaultValue={transformOptions(defaultValue)}
        isDisabled={disabled}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => t('dropdown.no_results')}
        onChange={(selectedOptions) =>
          onChange(transformSelectedOptions(selectedOptions, returnMethod))
        }
        options={transformedOptions}
        placeholder={placeholder}
        styles={customStyles(hasErrors)}
        value={transformedValue}
        {...props}
      />
      <p className={descriptionClassName} id={`select_for_${props.inputId}`}>
        {description}
      </p>
    </div>
  );
}
