import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function SolutionButton({ hasSolution, onClick, showSolution }) {
  const t = useFormatMessage();

  if (!hasSolution) return null;

  return (
    <ActionStyledAsButton onClick={() => onClick(!showSolution)} variant="text">
      {`${showSolution ? t('global.hide') : t('global.show')} ${t(
        'styled-slide-viewer.example_answer',
      ).toLowerCase()}`}
    </ActionStyledAsButton>
  );
}
