/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// the above disable is because the li has a tabindex.  That's fine, as there's a SR-element in it which can get the
// focus and the LI has an on-click handler, so it's interactive
import useFormatMessage from 'hooks/useFormatMessage';
import { SearchBar, SubjectCard } from '@ftrprf/tailwind-components';
import { generatePath, useNavigate } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'providers/UserProvider';
import { EmptyState } from 'components/EmptyState';
import { ProgramItems } from './ProgramItems';
import OwnContentImage from '../../../assets/vectors/own_content.svg';

export default function ProgramOverview({ programs, showOwnContent }) {
  const t = useFormatMessage();
  const navigate = useNavigate();
  const { isTeacher } = useContext(UserContext);
  const [filteredPrograms, setFilteredPrograms] = useState([...programs]);
  const [filter, setFilter] = useState('');
  const [showOwnContentAfterFilter, setShowOwnContentAfterFilter] =
    useState(showOwnContent);

  const ownContentTitle = t(
    isTeacher ? 'curricula.lessons.own' : 'curricula.lessons.school',
  );

  useEffect(() => {
    const tempPrograms = [...programs];
    const lowercasedFilter = filter.toLowerCase();

    if (filter) {
      const programsByName = tempPrograms.filter((program) =>
        program.name.toLowerCase().includes(lowercasedFilter),
      );

      setFilteredPrograms(programsByName);
    } else {
      setFilteredPrograms(tempPrograms);
    }

    if (showOwnContent) {
      setShowOwnContentAfterFilter(
        ownContentTitle.toLowerCase().includes(lowercasedFilter),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, programs, showOwnContent]);

  return (
    <div>
      <div className="w-1/2">
        <SearchBar
          id="filterProgramByName"
          onChange={setFilter}
          placeholder={t('global.search.by.name')}
          test="filterProgramByName"
        />
      </div>

      {Boolean(!filteredPrograms?.length) && (
        <EmptyState
          imageClass="w-40 md:w-80"
          textKey="program-overview.empty.search"
        />
      )}
      {Boolean(filteredPrograms?.length) && (
        <ul
          className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          data-test="programOverview"
        >
          <ProgramItems programs={filteredPrograms} />
          {showOwnContent && showOwnContentAfterFilter && (
            // the subject card has the required link for the SR-users.
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
            <li
              key="owncontent"
              className="cursor-pointer programItem"
              onClick={() => navigate(generatePath(URLS.OWN_LESSONS_OVERVIEW))}
              tabIndex={0}
            >
              <SubjectCard
                color="#10b981"
                hasContent
                image={OwnContentImage}
                link={generatePath(URLS.OWN_LESSONS_OVERVIEW)}
                title={ownContentTitle}
              />
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
