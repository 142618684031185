// the below is suppressed because Big Heads works with prop spreading
/* eslint-disable react/jsx-props-no-spreading */
// the below suppression is because it is present in the next package.json
// eslint-disable-next-line import/no-extraneous-dependencies
import { BigHead } from '@ftrprf/bigheads-core';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import qs from 'query-string';
import { useQueryParams } from 'use-query-params';
import { ActionStyledAsButton, SaveButton } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import useUpdateOwnUserInfo from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserInfo';
import { UserContext } from 'providers/UserProvider';
import { isEqual } from 'lodash-es';
import { bigheadSettings } from './partials/bigheadSettings';
import { getRandomOptions } from './partials/getRandomOptions';
import { getProps } from './partials/getProps';
import { AvatarGeneralSettings } from './partials/AvatarGeneralSettings';
import { AvatarFacialSettings } from './partials/AvatarFacialSettings';
import { AvatarBodySettings } from './partials/AvatarBodySettings';

export default function ProfileAvatar() {
  const t = useFormatMessage();
  const [, setQueryParams] = useQueryParams();
  const { avatar, isRefetchingSettings, refetchSettings } =
    useContext(UserContext);

  const props = useMemo(
    () =>
      location.search
        ? getProps(qs.parse, location.search)
        : getRandomOptions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search, avatar],
  );

  const svgRef = useRef(null);
  const { isLoading: isSavingAvatar, mutateAsync: updateAvatar } =
    useUpdateOwnUserInfo();
  const saveAvatar = async (avatarInfo) => {
    await updateAvatar({ avatar: avatarInfo });
    refetchSettings();
  };

  const updateBooleanProp = useCallback(
    (e, name) => {
      const value = e ? 'true' : 'false';
      setQueryParams({ ...props, [name]: value });
    },
    [props, setQueryParams],
  );

  const updateRadioButtonProp = useCallback(
    (e) => {
      const { id, name } = e.target;
      setQueryParams({ ...props, [name]: id });
    },
    [props, setQueryParams],
  );

  const updateProp = useCallback(
    (e) => {
      const { name, value } = e.target;

      setQueryParams({ ...props, [name]: value });
    },
    [props, setQueryParams],
  );

  useEffect(() => {
    if (!location.search && avatar) {
      setQueryParams(JSON.parse(avatar));
    }
  }, [avatar, setQueryParams]);

  const avatarsAreTheSame = useMemo(
    () => Boolean(avatar && isEqual(props, JSON.parse(avatar || ''))),
    [avatar, props],
  );
  const canSaveAvatarDisabled = useMemo(
    () => isSavingAvatar || avatarsAreTheSame || isRefetchingSettings,
    [avatarsAreTheSame, isRefetchingSettings, isSavingAvatar],
  );

  return (
    <div
      key={`avatarSettings${JSON.stringify(props)}`}
      className="h-full w-full p-4 border border-gray-300 rounded flex gap-14 items-stretch"
    >
      <div className="max-w-3/4 w-[40rem]">
        <AvatarGeneralSettings
          props={props}
          section={bigheadSettings.general}
          updateBooleanProp={updateBooleanProp}
          updateProp={updateProp}
          updateRadioButtonProp={updateRadioButtonProp}
        />
        <AvatarFacialSettings
          props={props}
          section={bigheadSettings.face}
          updateBooleanProp={updateBooleanProp}
          updateProp={updateProp}
        />
        <AvatarBodySettings
          props={props}
          section={bigheadSettings.body}
          updateProp={updateProp}
        />
      </div>
      <div className="max-w-full w-[20rem] h-full mb-8 overflow-hidden">
        {props && (
          <div className="fixed top-[30%] w-1/8 max-w-80 flex flex-col gap-4 items-center">
            <BigHead ref={svgRef} {...props} />
            <ActionStyledAsButton
              className="max-w-fit"
              onClick={() => setQueryParams(getRandomOptions())}
              variant="text"
            >
              <span className="px-8">{t('avatar.pick.random')}</span>
            </ActionStyledAsButton>
            <SaveButton
              className="max-w-fit"
              disabled={canSaveAvatarDisabled}
              onClick={() => {
                setQueryParams(props);
                saveAvatar(JSON.stringify(props));
              }}
              text={t('global.account.avatar.save')}
            />
          </div>
        )}
      </div>
    </div>
  );
}
