import { Link } from 'react-router-dom';
import { linkFocusStyles } from '../utils/constants/linkFocusStyles';
import c from '../utils/c';
import StatsContent from './StatsContent';

export function Stats({
  href = '',
  isLoading = false,
  logo,
  name,
  test = '',
  total = 0,
}) {
  const className = c(
    linkFocusStyles,
    isLoading && 'animate-pulse',
    'flex bg-white p-2 shadow rounded-lg overflow-hidden items-center ring-offset-transparent transition-all',
    'outline outline-1 outline-offset-0 outline-white/0',
    'hover:outline-white/80 hover:outline-offset-2',
    'focus:outline-offset-2 focus:outline-2 focus:outline-white/80',
    href && 'hover:cursor-pointer',
    !href && 'hover:cursor-not-allowwed',
  );

  if (href) {
    return (
      <Link className={className} data-test={test} to={href}>
        <StatsContent logo={logo} name={name} total={total} />
      </Link>
    );
  }

  return (
    <div className={className} data-test={test}>
      <StatsContent logo={logo} name={name} total={total} />
    </div>
  );
}
