import { Link, useLocation } from 'react-router-dom';
import c from 'utils/c';

export function Tab({ label, test, to }) {
  const location = useLocation();
  const currentPath = location.pathname;

  const active = currentPath.includes(to);

  return (
    <li>
      <Link
        className={c(
          'group inline-flex items-center mt-6 py-1 px-3 font-medium text-sm rounded-t outline outline-1 outline-offset-0 outline-white/0 ring-offset-transparent transition-all',
          'focus:outline-offset-2 focus:outline-white/50 focus:outline-2',
          'hover:outline-white/50 hover:outline-offset-2',
          active
            ? 'text-accent-600 bg-white'
            : ' text-white hover:bg-white/20 bg-white/10',
        )}
        data-test={test}
        to={to}
      >
        {label}
      </Link>
    </li>
  );
}

export function TabList({ children }) {
  return <ul className="w-full flex gap-4">{children}</ul>;
}
