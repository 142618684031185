import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_USER_BY_ID } from 'api/plannerService/schooladmin/queries/schooladminUserById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminUserById({ id }) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    [`schooladminUserById`, id],
    () => plannerServiceQueryClient.request(SCHOOLADMIN_USER_BY_ID, { id }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.USER.singular'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminUserById,
  };
}
