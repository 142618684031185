import { gql } from 'graphql-request';

export const TEACHER_CREATE_ANSWER = gql`
  mutation teacherCreateAnswer($input: AnswerInput!) {
    teacherCreateAnswer(input: $input) {
      questionId
      type
      teacherFeedback
      ... on AnswerMultipleChoice {
        userAnswers
        questionAnswersMultipleChoice {
          id
          correct
          explanation
        }
      }
    }
  }
`;
