import {
  addHours,
  format,
  intlFormat,
  isBefore,
  set,
  startOfHour,
} from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Convert the local dateTime of the user in UTC time and format it for the backend.
 *
 * We don't use date-fns for this due to: https://github.com/marnusw/date-fns-tz/issues/174
 *
 * @param date
 * @returns {string}
 */
export function dateToUtcForBackend(date) {
  return moment.utc(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
}

/**
 * Convert a string representing utc time into a date in the users timezone
 * @param dateString
 * @returns {Date}
 */
export function utcDatetoLocalDate(dateString) {
  return utcToZonedTime(`${dateString}.000Z`, userTimezone);
}

/**
 * Converts a local date to a backend accepted format in UTC
 * @param {Date} date
 * @returns {string | null} "2015-05-22T20:12:23"
 */
export function backendAcceptedDateFormat(date) {
  if (!(date instanceof Date)) {
    // eslint-disable-next-line no-console
    console.error('Invalid date input: ', date);
    return null;
  }

  return dateToUtcForBackend(date);
}

/**
 * Formats date to internationalizationized string, with 'dd MMM yyyy' pattern
 *
 * @param {Date} date
 * @returns {string} datestring in the format "28 aug 2022"
 */
export function formatDateDDMonthYYYY(date) {
  return intlFormat(date, { day: 'numeric', month: 'short', year: 'numeric' });
}

export function getCurrentMinutes(itemInfo, date) {
  const minutes = date.getMinutes();
  let minutesToReturn = '00';

  if (!itemInfo?.quickPlan)
    return { value: minutesToReturn, label: minutesToReturn };

  minutesToReturn = minutes.toString();
  if (Number(minutes) < 10) minutesToReturn = `0${minutes}`;

  return { value: minutesToReturn, label: minutesToReturn };
}

/**
 * @param {Date} date
 * @returns
 */
export function getFormattedDates(date = new Date()) {
  /**
   * Reason for taking start of hour:
   * 10:42 looks less nice than 10:00
   */

  const startTime = startOfHour(date);

  const start = backendAcceptedDateFormat(startTime);
  const end = backendAcceptedDateFormat(addHours(startTime, 1));

  return {
    start,
    end,
  };
}

export function getHour(itemInfo, today, modifier = 0) {
  let hour = '00';

  if (itemInfo?.quickPlan) {
    const nonStringHour = today.getHours() + modifier;
    hour = nonStringHour < 10 ? `0${nonStringHour}` : nonStringHour.toString();
  }

  return { value: hour, label: hour };
}

/**
 * Formats date to string, with 'HH:mm' pattern
 *
 * @param {Date} date
 * @returns {string} hours and minutes, concatenated with ":" in between, e.g.: "10:26"
 */
export function getHoursAndMinutes(date) {
  return format(date, 'HH:mm');
}

/**
 * Formats date to string, with 'HH:mm:ss' pattern
 *
 * @param {Date} date
 * @returns {string} hours, minutes and seconds, concatenated with ":" in between, e.g.: "10:26:23"
 */
export function getHoursMinutesAndSeconds(date) {
  return format(date, 'HH:mm:ss');
}

/**
 * Formats date to string, with 'HH' pattern
 *
 * @param {Date} date
 * @returns {string} hours as a string with leading zero (when needed), e.g.: "10"
 */
export function getHoursWithLeadingZero(date) {
  return format(date, 'HH');
}

/**
 * Formats date to string, with 'yyyy-MM-dd' pattern
 *
 * @param {Date} date
 * @returns {string} day, month and year, concatenated with "/" in between
 */
export function getLongYearMonthDay(date) {
  return format(date, 'yyyy-MM-dd');
}

export function getMinutesRoundedDownToFive(today) {
  let minutes = today.getMinutes();
  let minutesToReturn;
  const modulus = minutes % 5;

  if (modulus) minutes -= modulus;

  minutesToReturn = minutes.toString();
  if (minutes < 10) minutesToReturn = `0${minutes}`;

  return minutesToReturn;
}

export function getMinutesRoundedDownToFiveFormItem(itemInfo, date) {
  let minutesToReturn = '00';

  if (!itemInfo?.quickPlan)
    return { value: minutesToReturn, label: minutesToReturn };

  minutesToReturn = getMinutesRoundedDownToFive(date);
  return { value: minutesToReturn, label: minutesToReturn };
}

/**
 * Formats date to string, with 'mm' pattern
 *
 * @param {Date} date
 * @returns {string} minutes as a string with leading zero (when needed), e.g.: "26"
 */
export function getMinutesWithLeadingZero(date) {
  return format(date, 'mm');
}

/**
 * Formats date to string, with 'dd/MM/yy' pattern
 *
 * @param {Date} date
 * @returns {string} day, month and year, concatenated with "/" in between, e.g.: "22/05/2015"
 */
export function getShortDate(date) {
  return format(date, 'dd/MM/yy');
}

/**
 * Get an array containing all hours of the day
 *
 * @returns {string[]}
 */
export function getTimeOptionHours() {
  const timeOptionHour = [];

  for (let i = 0; i < 24; i++) {
    const formattedHour = i < 10 ? `0${i}` : `${i}`;
    timeOptionHour.push({
      value: formattedHour,
      label: formattedHour,
    });
  }

  return timeOptionHour;
}

/**
 * Get an array containing all minutes in an hours
 *
 * @returns {string[]}
 */
export function getTimeOptionMinutes() {
  const timeOptionMinute = [];

  for (let i = 0; i <= 59; i += 1) {
    const formattedMinute = i < 10 ? `0${i}` : `${i}`;
    timeOptionMinute.push({
      value: formattedMinute,
      label: formattedMinute,
    });
  }

  return timeOptionMinute;
}

/**
 * Lets you know if the passed date is in the past
 *
 * @param {Date} date
 * @returns {boolean}
 */
export function isDateInThePast(date) {
  const currentDate = new Date();

  return isBefore(date, currentDate);
}

/**
 *
 * @param {Date} date
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @returns {Date} new date where the time is set to what's received in the function
 */
export function setTime(date, hours = 0, minutes = 0, seconds = 0) {
  return set(date, { hours, minutes, seconds });
}

/**
 * Formats a given date string into a human-readable format.
 * @param {string} dateString - The date string to be formatted. Should be in a format parsable by the Date constructor.
 * @param {boolean} utc - should de date be parsed as UTC time and formatted as user Timezone.
 * @returns {string} The formatted date string including both date and time parts.
 * @example
 * // Returns "3/4/2024 - 08:30"
 * dateFormat("2024-03-04T08:30:00")
 */
export function dateFormat(dateString, utc = false) {
  if (!dateString) return '';

  let date = new Date(dateString);

  if (utc) {
    date = utcDatetoLocalDate(dateString);
  }

  return format(date, 'dd/MM/yyyy - HH:mm');
}
