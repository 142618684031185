// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  SubjectCard,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function UpdateChapterDialog({
  chapter,
  color,
  isOpen,
  onConfirm: addChapter,
  onDismiss: dismiss,
}) {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState('');

  const t = useFormatMessage();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const resetState = useCallback(
    ({ blobUri }) => {
      setFiles([]);
      setImage(blobUri);
      reset();
    },
    [reset, setFiles, setImage],
  );

  useEffect(() => {
    if (chapter) resetState(chapter);
  }, [resetState, chapter]);

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ErrorCode.FileInvalidType) {
            setError(`Error: ${err.message}`);
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      return acceptedFiles;
    },
  });

  const onDismiss = useCallback(() => {
    resetState(chapter);
    dismiss(false);
  }, [dismiss, resetState, chapter]);

  const onSubmit = useCallback(
    (data) => {
      // eslint-disable-next-line no-param-reassign
      delete data.file;
      addChapter(data, files[0] ? acceptedFiles[0] : undefined);
      resetState(chapter);
      dismiss(false);
    },
    [addChapter, dismiss, resetState, acceptedFiles, files, chapter],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('update-chapter-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="updateChapterDialog-name">
                {t('chapter-dialog.name')}
              </Label>
              <Input
                defaultValue={chapter?.name}
                hasErrors={Boolean(errors.name)}
                id="updateChapterDialog-name"
                type="text"
                {...register('name', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.name && (
                <span className="text-red-500 text-xs">
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="updateChapterDialog-weight">
                {t('global.weight')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('chapter-dialog.weight.description')}
              </p>
              <Input
                defaultValue={chapter?.weight || '0'}
                id="updateChapterDialog-weight"
                min={0}
                type="number"
                {...register('weight', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <Label htmlFor="updateChapterDialog-document">
                {t('chapter-dialog.document')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('chapter-dialog.document.description')}
              </p>
              <Input
                defaultValue={chapter?.document}
                hasErrors={Boolean(errors.document)}
                id="updateChapterDialog-document"
                type="text"
                {...register('document', {
                  required: {
                    value: false,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.document && (
                <span className="text-red-500 text-xs">
                  {errors.document.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('global.image.update')}</Label>
              {files[0] || image ? (
                <div
                  className="grid grid-cols-2 w-full p-2 justify-center items-center rounded cursor-pointer border border-gray-300"
                  onClick={() => {
                    setFiles([]);
                    setImage('');
                  }}
                >
                  <div className="p-2 flex flex-row justify-start items-center">
                    <img
                      alt=""
                      className="h-7 w-7 mr-2"
                      src={files[0]?.preview || image}
                    />
                    {files[0]?.name}
                  </div>
                  <div className="p-2 flex flex-row justify-end items-center">
                    <ActionStyledAsButton danger variant="text">
                      <XCircleIcon className="h-3 w-3 mr-2" />
                      {t('global.image.delete')}
                    </ActionStyledAsButton>
                  </div>
                </div>
              ) : (
                <div
                  {...getRootProps()}
                  className="flex flex-row gap-x-3 justify-center items-center p-7 rounded border border-gray-300"
                >
                  <Input
                    htmlFor="updateChapterDialog-file"
                    type="file"
                    {...getInputProps()}
                  />
                  <div className="w-full flex flex-row justify-center items-center">
                    <ArrowUpTrayIcon
                      className="h-3 w-3 mr-2"
                      id="updateChapterDialog-file"
                    />
                    {t('global.drag_and_drop_or_browse.image')}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('global.example')}</Label>
              <SubjectCard
                color={color}
                image={files[0]?.preview || image}
                title={chapter?.name}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton onClick={onDismiss} variant="text">
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton type="submit">
            {t('global.update')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
