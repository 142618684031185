/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// the above disable is because the li has a tabindex.  That's fine, as there's a SR-element in it which can get the
// focus and the LI has an on-click handler, so it's interactive
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SubjectCard } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import URLS from 'utils/constants/urls';

export function ProgramItems({ programs }) {
  const { isTeacher } = useContext(UserContext);
  const navigate = useNavigate();

  return programs?.map((program) => {
    const link = generatePath(URLS.LEARN_PROGRAM, {
      programId: program.id,
    });
    const onCardClick = () => navigate(link);
    return (
      // the subject card has the required link for the SR-users.
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
      <li
        key={`${program.name}${program.id}`}
        className="cursor-pointer programItem"
        onClick={onCardClick}
        tabIndex={0}
      >
        <SubjectCard
          color={program.color}
          document={isTeacher ? program.document : false}
          hasContent={program.chapters?.length}
          image={program.blobUri}
          isModule
          link={link}
          title={program.name}
        />
      </li>
    );
  });
}
