import useFormatMessage from 'hooks/useFormatMessage';

import titleCase from 'utils/titleCase';

export function RoleHeader({ user }) {
  const t = useFormatMessage();

  return (
    <h2 className="text-3xl font-bold leading-none mb-4">
      {titleCase(
        `${t(`global.${user.role}.singular`)} ${t('class-group.details')}`,
      )}
    </h2>
  );
}
