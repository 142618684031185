import useFormatMessage from 'hooks/useFormatMessage';
import AmongUs from 'assets/vectors/among-us.svg';
import { dataTestIds } from 'utils/dataTestIds';

export function InfoImpersonatorAmongUs() {
  const t = useFormatMessage();

  return (
    <div
      className="flex flex-col w-full h-full items-center justify-center px-4"
      data-test={dataTestIds.page.impersonate.id}
    >
      <div className="-mb-8 max-w-xs">
        <svg viewBox="0 0 500 200" width="100%">
          <path
            d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
            fill="transparent"
            id="curve"
          />
          <text>
            <textPath
              fill="black"
              fontSize={34}
              fontWeight="600"
              xlinkHref="#curve"
            >
              {t('impersonator.joke')}
            </textPath>
          </text>
        </svg>
      </div>
      <div>
        <img
          alt={t('impersonator.alt')}
          className="block h-32 w-auto"
          src={AmongUs}
        />
      </div>
      <div className="flex justify-center items-center flex-col max-w-lg mt-8">
        <h1 className="text-center px-3 text-2xl font-medium text-gray-900 mb-4">
          {t('impersonator.header')}
        </h1>
      </div>
    </div>
  );
}
