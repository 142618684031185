import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

export function Label({
  children,
  className = '',
  required = false,
  ...props
}) {
  const t = useFormatMessage();

  return (
    <label
      className={c(
        className,
        `${required ? 'flex justify-between' : 'block'} mb-1`,
      )}
      {...props}
    >
      {!required ? (
        children
      ) : (
        <>
          <span>{children}</span>
          <span aria-hidden className="text-sm text-gray-400">
            * {t('global.required-field')}
          </span>
        </>
      )}
    </label>
  );
}
