import { Children, useEffect, useState } from 'react';

export default function Tabs({
  children,
  containerClassName,
  footerContainerClassName,
  footerExtra: FooterExtra,
  labelClassName,
  labelClassNameActive,
  labelsContainerClassName,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const labels = Children.map(children, (child) => child && child.props.label);
  const items = Children.map(
    children,
    (child) => child && child.props.children,
  );

  useEffect(() => {
    // because sometimes items will be removed from the tabs & then we want to show something not broken
    if (activeTab > labels.length - 1) {
      setActiveTab(0);
    }
  }, [activeTab, labels, setActiveTab]);

  return (
    <div className={containerClassName}>
      {items && items[activeTab]}
      <div className={footerContainerClassName}>
        <div className={labelsContainerClassName}>
          {labels &&
            labels.map((label, index) => (
              <div
                key={`${Date.now() + index}`}
                className={
                  index === activeTab ? labelClassNameActive : labelClassName
                }
                onClick={() => setActiveTab(index)}
              >
                {label}
              </div>
            ))}
        </div>
        {FooterExtra}
      </div>
    </div>
  );
}
