import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_UPDATE_CLASS_GROUP } from 'api/plannerService/schooladmin/mutations/schooladminUpdateClassGroup';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminUpdateClassGroup() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ id, name }) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_UPDATE_CLASS_GROUP, {
        id,
        name,
      }),
    {
      mutationKey: 'schooladminUpdateClassGroup',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.CLASS.singular'),
        });
      },
    },
  );
}
