import { Avatar } from '@ftrprf/tailwind-components';
import { ErrorBoundaryWrapper } from './ErrorBoundaryWrapper';

export function AvatarWrapper({ avatar }) {
  return (
    <ErrorBoundaryWrapper>
      <Avatar avatar={avatar} />
    </ErrorBoundaryWrapper>
  );
}
