import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  PresentationChartBarIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

import {
  Actions,
  Badge,
  Content,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import URLS, { classDetailTabs } from 'utils/constants/urls';
import { isNull } from 'lodash-es';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';

export default function ClassesOverview({
  classes,
  currentPage,
  isLoading,
  itemsPerPage,
  pages,
  setFilter,
  setPage,
  setSort,
  sort,
  totalItems,
}) {
  const t = useFormatMessage();
  const navigate = useNavigate();
  useTitle(t('global.CLASS.plural'));

  const routeToDefault = useCallback(
    ({ id: classGroupId }) => {
      navigate(
        generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
          classGroupId,
          tab: classDetailTabs.DEFAULT,
        }),
      );
    },
    [navigate],
  );

  const routeToStudents = (classGroupId) =>
    generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
      classGroupId,
      tab: classDetailTabs.STUDENTS,
    });

  const routeToLessons = (classGroupId) =>
    generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
      classGroupId,
      tab: classDetailTabs.LESSONS,
    });

  // const routeToExercises = (classGroupId) =>
  //   generatePath(`${URLS.CLASSGROUP_DETAIL_TAB}`, {
  //     classGroupId,
  //     tab: classDetailTabs.EXERCISES,
  //   });

  // const routeToTests = (classGroupId) =>
  //   generatePath(`${URLS.CLASSGROUP_DETAIL_TAB}`, {
  //     classGroupId,
  //     tab: classDetailTabs.TESTS,
  //   });

  const actions = (classGroup) => [
    {
      href: routeToStudents(classGroup.id),
      icon: <UsersIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('classes-overview.view.students'),
    },
    {
      href: routeToLessons(classGroup.id),
      icon: <PresentationChartBarIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('classes-overview.view.lessons'),
    },
    // {
    //   href: routeToExercises(classGroup.id),
    //   icon: <ClipboardIcon className="h-4 w-4 inline-block mr-2" />,
    //   label: t('classes-overview.view.exercises'),
    // },
    // {
    //   href: routeToTests(classGroup.id),
    //   icon: <ClipboardCheckIcon className="h-4 w-4 inline-block mr-2" />,
    //   label: t('classes-overview.view.tests'),
    // },
  ];

  const breadcrumbsLinks = [
    {
      current: true,
      href: URLS.CLASSGROUP,
      name: t('header.navigation.classes'),
    },
  ];

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        title={t('global.CLASS.plural')}
      />

      <Content className="flex flex-col">
        <div className="w-full mx-auto">
          <div className="w-full flex justify-between mb-4">
            <div className="w-3/6 mr-5">
              <SearchBar
                onChange={(value) => setFilter(value, 'name')}
                placeholder={t('classes-overview.column.classname')}
              />
            </div>
          </div>

          <TableSkeleton loading={isLoading}>
            <ResponsiveTable
              headers={[
                {
                  key: 'name',
                  label: t('classes-overview.column.classname'),
                },
                {
                  key: 'students',
                  label: t('classes-overview.column.students'),
                  orderable: false,
                },
                {
                  className: 'max-w-fit sr-only',
                  key: 'actions',
                  label: t('global.actions'),
                  orderable: false,
                },
              ]}
              mobileBreakpoint={768}
              mobileHeaderWidth={160}
              onRowClick={routeToDefault}
              onSortChange={(newSort) => setSort(newSort)}
              sort={sort}
            >
              {classes?.map((classGroup) => (
                <ResponsiveTableRow
                  key={classGroup.id}
                  data={classGroup}
                  onRowClick={() => actions(classGroup)[0] || undefined}
                >
                  <ResponsiveTableCol>{classGroup.name}</ResponsiveTableCol>
                  <ResponsiveTableCol>
                    {isNull(classGroup.amountOfStudents) ? (
                      ''
                    ) : (
                      <Badge>{classGroup.amountOfStudents}</Badge>
                    )}
                  </ResponsiveTableCol>
                  <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                    <Actions items={actions(classGroup)} />
                  </ResponsiveTableCol>
                </ResponsiveTableRow>
              ))}
            </ResponsiveTable>
          </TableSkeleton>
        </div>

        {Boolean(classes && classes.length) && (
          <Pagination
            className="mt-12"
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={(page) => setPage(page)}
            totalItems={totalItems}
            totalPages={pages}
          />
        )}
      </Content>
    </>
  );
}
