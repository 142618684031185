import { errorKeys } from 'utils/constants/errorKeys';

export function getMissingFieldValues(error, t) {
  const { errorCategoryName } = error;

  switch (errorCategoryName) {
    case errorKeys.USER_INVALID_EMAIL:
      return { missingFields: t('global.email') };
    case errorKeys.USER_MISSING_LANGUAGE:
      return { missingFields: t('global.LANGUAGE.singular') };
    case errorKeys.USER_MISSING_ORGANIZATION_ID:
      return { missingFields: t('global.ORGANIZATION.singular') };
    case errorKeys.USER_MISSING_FIRST_AND_LAST_NAME:
      return { missingFields: t('global.first_and_last_name') };
    case errorKeys.USER_MISSING_FIRST_NAME:
      return { missingFields: t('global.first_name') };
    case errorKeys.USER_MISSING_LAST_NAME:
      return { missingFields: t('global.last_name') };
    case errorKeys.USER_MISSING_ISSUER:
      return { missingFields: t('global.issuer') };
    case errorKeys.USER_MISSING:
      return { missingFields: t('global.USER.singular') };
    case errorKeys.USER_MISSING_DISPLAY_NAME:
      return { missingFields: t('global.display_name') };
    case errorKeys.USER_MISSING_USER_NAME:
      return { missingFields: t('global.username') };
    case errorKeys.USER_MISSING_USER_ID:
      return { missingFields: t('global.userId') };

    default:
      return { missingFields: t('global.unknown') };
  }
}
