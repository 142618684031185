import { gql } from 'graphql-request';

export const ADMIN_ACTIVE_ORGANIZATION = gql`
  mutation adminActivateOrganization($id: Int!) {
    adminActivateOrganization(id: $id) {
      id
      name
      prefix
    }
  }
`;
