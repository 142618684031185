import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext } from 'providers/UserProvider';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { getLongYearMonthDay } from 'utils/timeHelpers';
import UserProfilePageHeader from './partials/UserProfilePageHeaders/UserPageHeader';

export default function UserProfile({
  canDelete,
  canEdit,
  canResetPassword,
  isSaving,
  openRemoveModal,
  openResetModal,
  updateUser,
  user,
  users,
}) {
  const t = useFormatMessage();
  useTitle(t('global.USER.singular'), `${user?.firstName} ${user?.lastName}`);

  const { isSchoolAdmin } = useContext(UserContext);

  const showEndDate =
    isSchoolAdmin &&
    !user.isSchoolAdmin &&
    canEdit &&
    user.endDate &&
    user.endDate !== 'null';

  const defaultValues = {
    displayName: user.displayName || '',
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
  };

  if (showEndDate) {
    defaultValues.endDate = getLongYearMonthDay(new Date(user.endDate));
  }

  return (
    <>
      <UserProfilePageHeader user={user} users={users} />

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet
          context={[
            {
              canDelete,
              canEdit,
              canResetPassword,
              isSaving,
              openRemoveModal,
              openResetModal,
              updateUser,
              user,
            },
          ]}
        />
      </div>
    </>
  );
}
