import { useEffect } from 'react';
import { prismPluginThemes, prismThemes } from './themes';

export const usePrismTheme = (theme = 'twilight') => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const styleElementForPlugins = document.createElement('style');
    styleElementForPlugins.innerHTML = prismPluginThemes.diffHighlight.styles;
    styleElementForPlugins.innerHTML += prismPluginThemes.lineHighlight.styles;
    styleElementForPlugins.innerHTML += prismPluginThemes.matchBraces.styles;
    styleElementForPlugins.innerHTML += prismPluginThemes.toolbar.styles;

    if (prismThemes[theme]) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = prismThemes[theme].styles;

      document.head.append(styleElement);
      document.head.append(styleElementForPlugins);

      return () => {
        styleElement.remove();
        styleElementForPlugins.remove();
      };
    }
    document.head.append(styleElementForPlugins);

    return () => styleElementForPlugins.remove();
  }, [theme]);
};
