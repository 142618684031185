import c from '../../utils/c';
import getButtonStyle from '../../utils/getComponentStyle';

import { Button } from './Button';

const buttonStyles = {
  ACCENT: 'text-accent-700 text-center border-accent-700 bg-white',
  SECONDARY: 'text-gray-600 text-center bg-white',
  DANGER: 'text-red-600 text-center bg-white',
};

export function OutlineButton({
  accent = false,
  children = null,
  className = '',
  custom = false,
  danger = false,
  disabled = false,
  secondary = false,
  ...props
}) {
  const style = getButtonStyle(
    buttonStyles,
    { accent, secondary, danger },
    'ACCENT',
  );

  return (
    <Button
      className={
        !custom
          ? c(
              className,
              buttonStyles[style],
              !disabled &&
                'hover:bg-gray-50 hover:outline-none hover:ring-1 hover:ring-accent-500 ',
              'border border-solid transform px-4 py-1 rounded',
            )
          : className
      }
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
}
