import { GiCheckMark } from 'react-icons/gi';

export function NotChosenCorrect({ answer }) {
  return (
    <li>
      <div className="rounded border-2 border-red-400 border-dashed p-8 flex max-h-fit justify-between">
        <div>
          <div
            className="font-medium"
            dangerouslySetInnerHTML={{ __html: answer.value }}
          />
          <div
            className="text-sm text-gray-500"
            dangerouslySetInnerHTML={{ __html: answer.explanation }}
          />
        </div>
        <GiCheckMark
          className="text-gray-500 stroke-2 ml-4 min-w-[2rem] w-8 min-h-12 h-12"
          size="3rem"
        />
      </div>
    </li>
  );
}
