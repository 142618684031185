export function isUpdate(teamsCalendarItem) {
  if (!teamsCalendarItem) return false;

  const { addToCalendarAction, assignmentDescription, assignmentTitle } =
    teamsCalendarItem;

  return (
    Boolean(assignmentDescription) ||
    Boolean(assignmentTitle) ||
    Boolean(addToCalendarAction)
  );
}
