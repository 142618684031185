import { gql } from 'graphql-request';

export const SCHOOLADMIN_UPDATE_TEACHER = gql`
  mutation schooladminUpdateTeacher($id: ID!, $input: UpdateTeacherInput!) {
    schooladminUpdateTeacher(id: $id, input: $input) {
      id
      userName
      firstName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
    }
  }
`;
