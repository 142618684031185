import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_CALENDAR_ITEM_BY_ID } from 'api/plannerService/teacher/queries/teacherCalendarItemById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

const toastId = 'teacherCalendarItemById';

export const useTeacherCalendarItemById = (id) => {
  const toast = useToast();
  const t = useFormatMessage();

  const info = useQuery(
    ['teacherCalendarItemById', id],
    () =>
      plannerServiceQueryClient.request(TEACHER_CALENDAR_ITEM_BY_ID, {
        id,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          toastId,
          str: t('global.CALENDARITEM.plural'),
        });
      },
    },
  );

  return { ...info, data: info?.data?.teacherCalendarItemById };
};
