import { useQuery } from 'react-query';

import { SAVED_ANSWERS } from 'api/portfolioService/queries/savedAnswers';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSavedAnswers(lessonSessionId) {
  const response = useQuery(
    ['savedAnswers', lessonSessionId],
    () =>
      portfolioServiceQueryClient.request(SAVED_ANSWERS, {
        lessonSessionId,
      }),
    {
      enabled: !!lessonSessionId,
    },
  );

  return {
    ...response,
    data: response?.data?.savedAnswers,
  };
}
