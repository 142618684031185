import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_CREATE_TEACHER } from 'api/plannerService/schooladmin/mutations/schooladminCreateTeacher';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminCreateTeacher() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ input }) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_CREATE_TEACHER, {
        input,
      }),
    {
      mutationKey: 'createTeacher',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.TEACHER.singular'),
        });
      },
      onError: (error) => {
        const errorString = JSON.stringify(error);

        if (errorString.includes('User already exists')) {
          toast(TOASTTYPES.CREATEERROR, {
            str: `${t('global.TEACHER.singular')} ${t(
              'global.ERROR.USERNAME_ALREADY_IN_USE',
            )}`,
          });
        } else {
          toast(TOASTTYPES.CREATEERROR, {
            str: t('global.TEACHER.singular'),
          });
        }
      },
    },
  );

  return mutation;
}
