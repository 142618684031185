import { useCallback, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from 'date-fns';

import {
  Dialog,
  DialogActions,
  DialogHeader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function DateRangeDialog({
  dateRange,
  isOpen,
  onDismiss: dismiss,
  onSubmit: submit,
  setDateRange,
}) {
  const t = useFormatMessage();

  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const onDismiss = useCallback(() => {
    dismiss();
  }, [dismiss]);

  const onSubmit = useCallback(() => {
    setDateRange(tempDateRange);
    submit();
  }, [submit, tempDateRange, setDateRange]);

  return (
    <Dialog
      className="flex bg-white shadow-base flex-col max-w-min rounded-lg"
      isOpen={isOpen}
      onDismiss={onDismiss}
      unstyled
    >
      <DialogHeader>{t('date-range-dialog.header')}</DialogHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <DateRangePicker
          direction="vertical"
          editableDateInputs
          maxDate={addDays(new Date(), 365)}
          minDate={subDays(new Date(), 365)}
          months={1}
          moveRangeOnFirstSelection={false}
          onChange={(item) => setTempDateRange(item.selection)}
          ranges={[tempDateRange]}
          scroll={{ enabled: true }}
        />

        <DialogActions>
          <ActionStyledAsButton onClick={onDismiss} variant="text">
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton type="submit">
            {t('date-range-dialog.apply')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
