import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import { OWN_CONTENT } from 'utils/constants/contentTypes';
import { listToOptions } from 'utils/listToOptions';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';
import useFormatMessage from 'hooks/useFormatMessage';

export function SelectProgram({
  control,
  errors,
  ownContent,
  setValue,
  teacherPrograms,
}) {
  const t = useFormatMessage();
  const teacherProgramOptions = listToOptions(
    teacherPrograms?.sort(sortByWeightThenByName),
  );
  const programOptions = Boolean(ownContent?.length)
    ? [
        {
          value: OWN_CONTENT,
          label: t('curricula.lessons.own'),
        },
        ...teacherProgramOptions,
      ]
    : teacherProgramOptions;

  return (
    <div>
      <Label className="mt-2" htmlFor="formCalendarItem_selectProgram">
        {t('calendar.modal.selectProgram')}
      </Label>
      <div>
        <Controller
          control={control}
          name="program"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              hasErrors={Boolean(errors.program)}
              inputId="formCalendarItem_selectProgram"
              onBlur={onBlur}
              onChange={(e) => {
                setValue('chapter', undefined);
                setValue('chapterItem', undefined);
                onChange(e);
              }}
              options={programOptions}
              placeholder={t('calendar.modal.selectProgram')}
              returnMethod="object"
              value={value}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('dropdown.no_results'),
            },
          }}
          type="select"
        />
        {errors.program && (
          <p className="text-xs text-red-600">{errors.program.message}</p>
        )}
      </div>
    </div>
  );
}
