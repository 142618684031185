import { useRef } from 'react';

export function FileInput({
  children,
  id,
  multiple = false,
  onUpload = () => {},
  test = '',
}) {
  const ref = useRef();

  return (
    <>
      {children(() => ref.current?.click())}
      <input
        ref={ref}
        className="hidden"
        data-test={test}
        id={id}
        multiple={multiple}
        onChange={(e) => onUpload(e.target.files)}
        type="file"
      />
    </>
  );
}
