import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import { STUDENT_USER_GROUP_BY_LESSON_SESSION_ID } from 'api/portfolioService/queries/studentUserGroupByLessonSessionId';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useStudentUserGroupByLessonSessionId(sessionId) {
  const { isStudent } = useContext(UserContext);

  const response = useQuery(
    ['studentUserGroupByLessonSessionId', sessionId],
    () =>
      portfolioServiceQueryClient.request(
        STUDENT_USER_GROUP_BY_LESSON_SESSION_ID,
        {
          lessonSessionId: sessionId,
        },
      ),
    {
      enabled: isStudent,
    },
  );

  return {
    ...response,
    data: response?.data?.studentUserGroupByLessonSessionId,
  };
}
