import { useContext } from 'react';

import { UserContext } from 'providers/UserProvider';

import ClassProfileContainer from './ClassProfileContainer';

export default function SchooladminClassProfile({ showArchived = false }) {
  const { isSmartSchoolUser } = useContext(UserContext);

  return (
    <ClassProfileContainer
      canDelete={!isSmartSchoolUser}
      showArchived={showArchived}
    />
  );
}
