import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { ADMIN_UPSERT_TAG } from 'api/plannerService/admin/mutations/adminUpsertTag';

export default function useAdminUpsertTag(isCreate) {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ id, input }) =>
      plannerServiceQueryClient.request(ADMIN_UPSERT_TAG, {
        id,
        input,
      }),
    {
      mutationKey: 'adminUpsertTag',
      onSuccess: () => {
        toast(isCreate ? TOASTTYPES.CREATESUCCESS : TOASTTYPES.UPDATESUCCESS, {
          str: t('global.TAG.singular'),
        });
      },
      onError: (response) => {
        const errorMessage = JSON.stringify(response);
        const tagNameMustBeUnique =
          errorMessage && errorMessage.includes('EntityExistsException')
            ? t('global.validation.unique.what', { what: t('global.name') })
            : '';

        const toastMessage = tagNameMustBeUnique
          ? `${t('global.TAG.singular')}, ${tagNameMustBeUnique}`
          : t('global.TAG.singular');

        toast(isCreate ? TOASTTYPES.CREATEERROR : TOASTTYPES.UPDATEERROR, {
          str: toastMessage,
        });
      },
    },
  );
}
