import { gql } from 'graphql-request';

export const ADMIN_LINK_TAG = gql`
  mutation adminLinkTag($name: String!, $chapterId: Int, $chapterItemId: Int) {
    adminLinkTag(
      name: $name
      chapterId: $chapterId
      chapterItemId: $chapterItemId
    ) {
      id
    }
  }
`;
