import { useContext } from 'react';

import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';
import { WarningNotification } from './WarningNotification';
import { NotificationContext } from '.';

const types = {
  error: ErrorNotification,
  success: SuccessNotification,
  warning: WarningNotification,
};

export function NotificationBar() {
  const { notifications, removeNotification } = useContext(NotificationContext);

  return (
    <div className="absolute top-0 right-0 left-0 z-30 m-8 pointer-events-none">
      {notifications.map((notification) => {
        const Component = types[notification.type];

        return (
          <div
            key={notification.id}
            className="mb-4 flex justify-end"
            onClick={() => removeNotification(notification)}
          >
            <Component
              className="cursor-pointer pointer-events-auto"
              {...notification}
            />
          </div>
        );
      })}
    </div>
  );
}
