import c from '../../utils/c';

/**
 *
 * @param buttons   => { checked, id, label, name, value }
 * @param groupName => must be unique
 * @param legend    => legend for the fieldset.
 * @param onChange  => onChange handler
 * @param test      => id for testing with playwright
 */
export function RadioButtonGroup({
  buttons,
  groupName,
  legend,
  onChange,
  test = '',
}) {
  return (
    <div className="flex items-center justify-center">
      <span className="font-medium mr-4 text-gray-900">{legend}</span>
      <fieldset
        className="inline-flex overflow-hidden border border-gray-200 rounded-lg"
        data-test={test}
      >
        <legend className="sr-only font-semibold">{legend}</legend>
        {buttons.map((button, index) => (
          <div
            key={`radio-${groupName}-${index + 1}`}
            className={c(
              'relative inline-flex items-center h-full space-x-2 text-sm',
              'focus-within:border-2 focus-within:border-accent-500 border-2 border-white',
              'has-[:checked]:bg-[var(--accent-color-200)] has-[:checked]:underline',
              'has-[:checked]:underline-offset-4 has-[:checked]:decoration-accent-500',
              'has-[:checked]:border-accent-200 focus-within:has-[:checked]:border-accent-500',
              index > 0 && 'border-l border-gray-200',
              index === 0 && 'rounded-s-lg',
              index === buttons.length - 1 && 'rounded-e-lg',
              button.checked &&
                'underline-offset-4 decoration-accent-500 underline',
              button.checked &&
                'focus-within:border-accent-500 !border-accent-200 bg-[var(--accent-color-200)]',
            )}
          >
            <input
              className="sr-only"
              data-index={index}
              id={button.id}
              name={button.name}
              onChange={onChange}
              type="radio"
              value={button.value}
            />
            <label
              key={`${groupName}-${index + 1}`}
              className="first-letter:uppercase cursor-pointer py-2 px-4"
              htmlFor={button.id}
            >
              <span className="before:w-2 before:h-2 before:bg-[var(--accent-color-500)] before:rounded-full before:absolute before:top-[14px] before:left-3 ">
                {button.label}
              </span>
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  );
}
