import { useParams } from 'react-router-dom';

import useSchooladminProgramById from 'hooks/api/plannerService/schooladmin/queries/useSchooladminProgramById';
import useSchooladminPrograms from 'hooks/api/plannerService/schooladmin/queries/useSchooladminPrograms';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import Chapter from './Chapter';

export default function ChapterContainerSchoolAdmin() {
  const { filter, programId } = useParams();
  const MAX_PROGRAMS = 500;

  const {
    data: program,
    isLoading: isLoadingProgram,
    refetch,
  } = useSchooladminProgramById(programId);

  const { data: programsData } = useSchooladminPrograms({
    filter: [
      {
        key: 'name',
        value: filter || '',
        operation: filterOperation.LIKE,
      },
    ],
    page: 0,
    size: MAX_PROGRAMS,
    sort: { name: sortOperation.ASC },
  });

  return (
    <Chapter
      currentProgram={program}
      isLoading={isLoadingProgram}
      programs={programsData?.content}
      refetch={refetch}
    />
  );
}
