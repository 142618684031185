import { Navigate, generatePath, useParams } from 'react-router-dom';
import URLS, { userProfileTabs } from 'utils/constants/urls';

export default function ClassgroupStudentProfileIndex() {
  const { classGroupId, studentId } = useParams();

  return (
    <Navigate
      replace
      to={generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
        classGroupId,
        studentId,
        tab: userProfileTabs.DEFAULT,
      })}
    />
  );
}
