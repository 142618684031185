import useFormatMessage from '../../../hooks/useFormatMessage';
import useScrollTopRef from '../../../hooks/useScrollTopRef';

import { SlideViewerTextSlide } from '../../slideviewer/SlideViewer';

export default function SideBarTeacherInfo({ currentSlide }) {
  const t = useFormatMessage();
  const scrollRef = useScrollTopRef(currentSlide);

  return (
    <div className="overflow-hidden flex flex-col flex-grow px-4 teacher-sidebar">
      <h3 className="mt-4 mb-4 text-2xl font-bold">
        {t('styled-slide-viewer.teacher_manual')}
      </h3>
      <div ref={scrollRef} className="overflow-y-auto flex-grow">
        {currentSlide.info ? (
          <SlideViewerTextSlide
            classNames="TeacherInfoSidebar"
            value={currentSlide.info}
          />
        ) : (
          <div className="text-gray-600 text-sm">
            {t('styled-slide-viewer.teacher_manual_empty')}
          </div>
        )}
      </div>
    </div>
  );
}
