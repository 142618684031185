/* eslint-disable react/jsx-props-no-spreading */
// the above disable is because draggable works with props spreading
import React, { useContext } from 'react';
import classNames from 'classnames';

import { ResponsiveTableContext } from './ResponsiveTableContext';

export function ResponsiveTableRow({
  children = null,
  className = '',
  data = null,
  dragHandleProps = null,
  draggableProps = null,
  innerRef = null,
  noDivider = false,
}) {
  const { isMobile, onRowClick } = useContext(ResponsiveTableContext);

  if (isMobile) {
    return (
      children &&
      React.Children.map(
        children,
        (child, i) =>
          child &&
          React.cloneElement(child, {
            key: `${Date.now() + i}`,
            index: i,
            className,
          }),
      )
    );
  }

  return (
    <tr
      ref={innerRef}
      className={classNames([
        { 'border-t': !noDivider },
        { 'hover:bg-accent-50 cursor-pointer': !!onRowClick },
        className,
      ])}
      onClick={() => !!onRowClick && data && onRowClick(data)}
      {...draggableProps}
      {...dragHandleProps}
    >
      {children}
    </tr>
  );
}
