import { useCallback, useContext } from 'react';
import { ReactComponent as SkoreLogo } from 'assets/vectors/smartschool-skore.svg';
import { ReactComponent as TeamsLogo } from 'assets/vectors/teams.svg';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { dateFormat } from 'utils/timeHelpers';
import {
  ActionStyledAsButton,
  StatusBullet,
} from '@ftrprf/tailwind-components';
import { eventStatus } from './eventStatus';

export function ExportResultButton({
  exportResultsHandler,
  isSendToScoreButtonDisabled,
  lastEventStatus,
}) {
  const { isSmartschoolUser, isTeamsUser } = useContext(UserContext);
  const t = useFormatMessage();
  const isTeams = isTeamsUser;
  // needs to be before the if-condition to avoid a conditional calling error from eslint
  const renderLogo = useCallback(
    () =>
      isTeams ? (
        <TeamsLogo className="w-6 h-6 fill-white" />
      ) : (
        <SkoreLogo className="w-6" />
      ),
    [isTeams],
  );

  // the below is to ensure we can always assume that if the user isn't a teamsUser who should see a button, it should
  // be a smartschooluser who should see a button
  if (!isTeams && !isSmartschoolUser) {
    return null;
  }

  const isSuccess = lastEventStatus?.status === eventStatus.SUCCESS;
  const isFailure = lastEventStatus?.status === eventStatus.FAILED;
  const isLoading = !isSuccess && !isFailure;
  const dateString = dateFormat(lastEventStatus?.eventTime, true);

  const where = isTeams ? 'Teams' : 'Skore';
  const message =
    isFailure || isSuccess
      ? t(`students-overview.exportResults.${lastEventStatus.status}`, {
          dateTime: dateString,
          where,
        })
      : t('students-overview.exportResults.isLoading', { where });

  return (
    <div className="text-right">
      <ActionStyledAsButton
        className="relative"
        disabled={isSendToScoreButtonDisabled}
        iconBefore={renderLogo}
        onClick={exportResultsHandler}
        secondary
        title={message}
        variant="white"
      >
        <span className="py-2 pl-2">
          {t('students-overview.exportResults.export', { where })}
        </span>
        <aside className="absolute -top-2 right-2 flex items-center justify-center">
          {message && <span className="sr-only">{message}</span>}
          {isSuccess && <StatusBullet status={eventStatus.SUCCESS} />}
          {isFailure && <StatusBullet status={eventStatus.FAILED} />}
          {isLoading && <StatusBullet status={eventStatus.NOT_STARTED} />}
        </aside>
      </ActionStyledAsButton>
    </div>
  );
}
