import NotificationLanguage from 'hooks/NotificationLanguage';
import useFormatMessage from 'hooks/useFormatMessage';

/**
 *
 * @param {notificationObject} notification
 * @returns a styled notification as a list item
 */
export function NotificationListItem({ notification }) {
  const translatedNotification = NotificationLanguage(notification);
  const t = useFormatMessage();

  return (
    <li>
      <div className="rounded py-5 px-4">
        <h2 className="font-semibold text-gray-800">
          {translatedNotification.title}
        </h2>
        <p className="mt-1 text-sm text-gray-600 line-clamp-2">
          {translatedNotification.body}
        </p>
        <a
          className="inline-block mt-4 bg-accent-100 text-accent-600 px-4 py-1 hover:shadow-none hover:translate-x-px hover:translate-y-px transform rounded hover:outline hover:outline-2 outline-transparent hover:outline-blue-500 focus:outline focus:outline-blue-500 focus:outline-2 cursor-pointer box-border"
          href={translatedNotification.actionUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('global.view')}
        </a>
      </div>
    </li>
  );
}
