import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_CREATE_CONTENT_FEEDBACK } from 'api/plannerService/teacher/mutations/teacherCreateContentFeedback';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherCreateContentFeedback() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ file, input }) =>
      plannerServiceQueryClient.request(TEACHER_CREATE_CONTENT_FEEDBACK, {
        file,
        input,
      }),
    {
      mutationKey: 'teacherCreateContentFeedback',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.FEEDBACK'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.FEEDBACK'),
        });
      },
    },
  );
}
