import { useContext, useState } from 'react';

import {
  ActionStyledAsButton,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useUpdateOwnUserInfo from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserInfo';
import useFormatMessage from 'hooks/useFormatMessage';

import { IDPS } from 'utils/constants/idp';

export function ChangePassword() {
  const t = useFormatMessage();
  const { idp, isAdmin, isSchoolAdmin } = useContext(UserContext);

  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const { isLoading, mutateAsync: updateOwnUserInfo } = useUpdateOwnUserInfo();

  if ((isAdmin || isSchoolAdmin) && idp === IDPS.FTRPRF) {
    if (!changePassword) {
      return (
        <ActionStyledAsButton
          className="mt-6"
          onClick={() => {
            setChangePassword(true);
          }}
        >
          {t('profile.change_password')}
        </ActionStyledAsButton>
      );
    }

    const resetNewPassword = () => {
      setChangePassword(false);
      setNewPassword('');
    };

    return (
      <form className="mt-6">
        <InputGroup>
          <Label htmlFor="profileInfo-password">
            {t('profile.change_password')}
          </Label>
          <p className="text-sm text-gray-400 my-1">
            {t('profile.errors.passwords_validation')}{' '}
            {t('profile.errors.password.length')}
          </p>
          <Input
            id="profileInfo-password"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            type="password"
            value={newPassword}
          />
        </InputGroup>

        <div className="flex flex-row gap-2">
          <ActionStyledAsButton
            onClick={() => setChangePassword(false)}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={
              /*
                when you want to change your password, you need to have a valid password
                1. at least 8 characters
                2. at least 1 uppercase letter
                3. at least 1 lowercase letter
                4. at least 1 number
              */
              isLoading ||
              Boolean(
                !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword),
              )
            }
            onClick={() => {
              if (!changePassword) {
                setChangePassword(true);
              } else {
                updateOwnUserInfo({ newPassword }).then(() =>
                  resetNewPassword(),
                );
              }
            }}
            type="submit"
          >
            {t('profile.change_password')}
          </ActionStyledAsButton>
        </div>
      </form>
    );
  }
  return null;
}
