import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_PROGRAMS } from 'api/plannerService/admin/queries/adminPrograms';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminPrograms({ filter, page, size, sort }) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['adminPrograms', filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(ADMIN_PROGRAMS, {
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
      keepPreviousData: true,
    },
  );

  return {
    ...response,
    data: response.data?.adminPrograms,
  };
}
