import {
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import viewModes from 'utils/constants/viewModes';

export const defaultCalenderItemsResultsOverviewSearchParams = {
  classgroups: withDefault(DelimitedNumericArrayParam, []),
  fullname: withDefault(StringParam, ''),
  page: withDefault(NumberParam, 1),
  viewmode: withDefault(StringParam, viewModes.CLASSICAL),
};
