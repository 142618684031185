import { generatePath, Navigate } from 'react-router-dom';
import URLS, { smartschoolSyncTabs } from 'utils/constants/urls';

export default function SmartschoolSyncIndex() {
  return (
    <Navigate
      replace
      to={generatePath(URLS.MANAGE_SMARTSCHOOL_TAB, {
        tab: smartschoolSyncTabs.SYNC,
      })}
    />
  );
}
