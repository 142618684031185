import { Header } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS, { profileTab } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

export function CodefeverHeader() {
  const t = useFormatMessage();

  const user = useContext(UserContext);
  const { isImpersonating, organization } = user;

  const profileLinks = [
    {
      label: t('profile.preferences'),
      to: generatePath(URLS.PROFILE_TAB, { tab: profileTab.PREFERENCES }),
      test: dataTestIds.header.userMenu.links.profile,
    },
    {
      label: isImpersonating ? t('global.unimpersonate') : t('header.logout'),
      to: URLS.LOGOUT,
      test: dataTestIds.header.userMenu.links.logout,
    },
  ];

  return (
    <Header
      choseActivePeriod
      organization={organization}
      profileLinks={profileLinks}
      user={user}
    />
  );
}
