import { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Dropdown,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export function GroupTypePicker({ onDismiss, options, submit }) {
  const t = useFormatMessage();
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    setSelectedOption(options[0].name);
  }, [options]);

  return (
    <>
      <DialogContent className="m-4 min-h-16">
        <Label htmlFor="groupTypePicker">{t('groups.compose.type')}</Label>
        <Dropdown
          className="pb-2"
          defaultValue={options[0].name}
          inputId="groupTypePicker"
          onChange={(value) => {
            setSelectedOption(value);
          }}
          options={options?.map((option) => ({
            value: option.name,
            key: option.id,
            label: option.name,
          }))}
          value={selectedOption}
        />
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          onClick={() => submit(selectedOption.split(' ')[0].toLowerCase())}
          send
          type="submit"
        >
          {t('groups.compose.start')}
        </ActionStyledAsButton>
      </DialogActions>
    </>
  );
}
