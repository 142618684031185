import { gql } from 'graphql-request';

export const ADMIN_DELETE_CHAPTER_ITEM = gql`
  mutation adminDeleteChapterItem($id: Int!) {
    adminDeleteChapterItem(id: $id) {
      statusCode
      id
      message
    }
  }
`;
