import { createContext, useMemo } from 'react';

export const MultiScreenModeContext = createContext({});

export function MultiScreenModeContextProvider({
  children,
  generateCurrentLessonPath,
  generateScratchExercisePath,
}) {
  const context = useMemo(
    () => ({ generateScratchExercisePath, generateCurrentLessonPath }),
    [generateCurrentLessonPath, generateScratchExercisePath],
  );

  return (
    <MultiScreenModeContext.Provider value={context}>
      {children}
    </MultiScreenModeContext.Provider>
  );
}
