import { HackRoomLessonItem } from './HackRoomLessonItem';

export function HackRoomChapterItem({ chapter, filter }) {
  return (
    <li className="py-1">
      <h3 className="text-lg text-gray-700 leading-none">{chapter.name}</h3>
      <ul className="w-full mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {chapter.items
          ?.filter((lesson) => lesson.name.toLowerCase().includes(filter))
          .map((lesson) => (
            <HackRoomLessonItem key={lesson.id} lesson={lesson} />
          ))}
      </ul>
    </li>
  );
}
