import { gql } from 'graphql-request';

export const SCHOOLADMIN_UPDATE_STUDENT = gql`
  mutation schooladminUpdateStudent($id: ID!, $input: UpdateStudentInput!) {
    schooladminUpdateStudent(id: $id, input: $input) {
      id
      userName
      firstName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
    }
  }
`;
