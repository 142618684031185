import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import SchooladminUserProfilePageHeader from './SchooladminUserProfilePageHeader';
import TeacherUserProfilePageHeader from './TeacherUserProfilePageHeader';
import UserProfilePageHeader from './UserProfilePageHeader';

export default function UserPageHeader({ user, users }) {
  const { isSchoolAdmin, isTeacher } = useContext(UserContext);

  let RoleUserProfilePageHeader = UserProfilePageHeader;
  if (isTeacher) {
    RoleUserProfilePageHeader = TeacherUserProfilePageHeader;
  }
  if (isSchoolAdmin) {
    RoleUserProfilePageHeader = SchooladminUserProfilePageHeader;
  }

  return <RoleUserProfilePageHeader user={user} users={users} />;
}
