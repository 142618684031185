/* eslint-disable react/no-unstable-nested-components */
import {
  multiScreenModeActions,
  MultiScreenModeContextProvider,
  useEditorStyleSheet,
  usePrismTheme,
} from '@ftrprf/tailwind-components';
import { BroadcastChannel } from 'broadcast-channel';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TeacherManual } from './partials/TeacherManual';
import { NextSlide } from './partials/NextSlide';
import { CurrentSlide } from './partials/CurrentSlide';
import { CurrentSlideNumberIndicator } from './partials/CurrentSlideNumberIndicator';
import {
  displayIds,
  getSecondaryDisplay,
  getTertiaryDisplay,
} from './partials/displayHelpers';
import { MainDisplay } from './partials/MainDisplay';
import { SecondaryDisplay } from './partials/SecondaryDisplay';
import { TertiaryDisplay } from './partials/TertiaryDisplay';
import {
  generateCurrentLessonPath,
  generateScratchExercisePath,
} from './utils/helpers';
import { SolutionButton } from './partials/SolutionButton';

export function MultiScreenMode() {
  const [mainDisplay, setMainDisplay] = useState(displayIds.CURRENT_SLIDE);
  const [showSolution, setShowSolution] = useState(false);
  const [multiScreenModeBroadcastChannel] = useState(
    new BroadcastChannel('multiScreen'),
  );
  const [presentatorControlsBroadcastChannel] = useState(
    new BroadcastChannel('multiScreenControls'),
  );
  const t = useFormatMessage();
  const { settings } = useContext(UserContext);
  usePrismTheme(settings?.slideViewer?.codeBlocksTheme);
  useEditorStyleSheet(true);
  const [
    { currentSlide, length, nextSlide, sessionId, studioId, viewMode },
    setSlidesData,
  ] = useState({});

  const onPreviousSlide = useCallback(() => {
    if (currentSlide?.index > 0) {
      void presentatorControlsBroadcastChannel.postMessage(
        multiScreenModeActions.previousSlide,
      );
    }
  }, [currentSlide?.index, presentatorControlsBroadcastChannel]);

  const onNextSlide = useCallback(() => {
    if (currentSlide?.index < length - 1) {
      void presentatorControlsBroadcastChannel.postMessage(
        multiScreenModeActions.nextSlide,
      );
    }
  }, [currentSlide?.index, length, presentatorControlsBroadcastChannel]);

  useEffect(() => {
    document.onkeydown = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          onPreviousSlide();
          break;
        case 'ArrowRight':
          onNextSlide();
          break;
        default:
          break;
      }
    };
  }, [onNextSlide, onPreviousSlide]);

  useEffect(() => {
    void presentatorControlsBroadcastChannel.postMessage(
      multiScreenModeActions.initialize,
    );
    multiScreenModeBroadcastChannel.onmessage = (message) => {
      setSlidesData(message);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const views = useMemo(
    () => ({
      currentSlide: {
        id: displayIds.CURRENT_SLIDE,
        title: (
          <CurrentSlideNumberIndicator
            currentSlideIndex={currentSlide?.index}
            lessonLength={length}
          />
        ),
        Component: (
          <CurrentSlide
            currentSlide={currentSlide}
            lessonLength={length}
            onNextSlide={onNextSlide}
            onPreviousSlide={onPreviousSlide}
          />
        ),
      },
      nextSlide: {
        id: displayIds.NEXT_SLIDE,
        title: t('multi-screen-mode.next-slide'),
        Component: (
          <NextSlide
            currentSlideIndex={currentSlide?.index}
            lessonLength={length}
            nextSlide={nextSlide}
          />
        ),
      },
      teacherManual: {
        id: displayIds.TEACHER_MANUAL,
        SolutionButton: (
          <SolutionButton
            hasSolution={Boolean(currentSlide?.question?.solution)}
            onClick={setShowSolution}
            showSolution={showSolution}
          />
        ),
        title: t('styled-slide-viewer.manual'),
        Component: (
          <TeacherManual
            currentSlide={currentSlide}
            showSolution={showSolution}
          />
        ),
      },
    }),
    [
      currentSlide,
      length,
      nextSlide,
      onNextSlide,
      onPreviousSlide,
      showSolution,
      t,
    ],
  );

  if (!currentSlide) {
    return null;
  }

  return (
    <div className="bg-gray-100 h-screen w-screen flex overflow-hidden space-x-10 p-10">
      <MultiScreenModeContextProvider
        generateCurrentLessonPath={({ slideId }) =>
          generateCurrentLessonPath({
            slideId,
            sessionId,
            studioId,
            viewMode,
          })
        }
        generateScratchExercisePath={({ versionId }) =>
          generateScratchExercisePath({
            studioId,
            versionId,
            sessionId,
          })
        }
      >
        <MainDisplay item={views[mainDisplay]} />

        <div className="grow flex flex-col gap-10">
          <SecondaryDisplay
            item={views[getSecondaryDisplay(mainDisplay)]}
            setMainDisplay={setMainDisplay}
          />
          <TertiaryDisplay
            item={views[getTertiaryDisplay(mainDisplay)]}
            setMainDisplay={setMainDisplay}
          />
        </div>
      </MultiScreenModeContextProvider>
    </div>
  );
}
