export default {
  'global.required-field': 'Verplicht veld',
  'global.search': 'Zoek',
  'header.impersonated': 'Impersonated',
  'pagination.ellipsis': 'Ellipsis',
  'pagination.go-to-page': 'Ga naar pagina {number}',
  'pagination.items-per-page': 'items per pagina',
  'pagination.next': 'Volgende',
  'pagination.previous': 'Vorige',
  'pagination.results-info':
    'Resultaat {startItem} tot {endItem} van {totalItems} resultaten',
};
