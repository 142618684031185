import { generatePath, Link } from 'react-router-dom';

import { ContentCard } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS from 'utils/constants/urls';

export function ExerciseActions({ exercise }) {
  const t = useFormatMessage();
  const { exerciseVersionId, lessonSessionId, userId } = exercise;
  const viewUrl = generatePath(URLS.EXERCISE_OPEN, {
    exerciseVersionId,
    lessonSessionId,
    userId,
  });
  const resultsUrl = ''; // todo: wait for result of https://codefever.atlassian.net/browse/TR-2061

  return (
    <div className="w-full flex justify-between align-middle h-8 mb-2">
      {viewUrl && (
        <ContentCard.Action className="flex flex-grow justify-center">
          <Link
            className="w-full h-full self-center text-base pt-[0.625rem] mr-[3px]"
            target="_blank"
            to={viewUrl}
          >
            {t('global.view')}
          </Link>
        </ContentCard.Action>
      )}
      {resultsUrl && (
        <ContentCard.Action className="flex flex-grow justify-center">
          <Link
            className="w-full h-full self-center text-base pt-[0.625rem] mr-[3px]"
            to={resultsUrl}
          >
            {t('content.results')}
          </Link>
        </ContentCard.Action>
      )}
    </div>
  );
}
