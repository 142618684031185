import { Link, useLocation } from 'react-router-dom';
import c from 'utils/c';

export function TabColumn({ className, label, test, to }) {
  const location = useLocation();
  const currentPath = location.pathname;

  const active = currentPath === to;

  return (
    <li>
      <Link
        className={c(
          active
            ? 'bg-accent-100 border-accent-500 text-accent-700'
            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
          'block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
          className,
        )}
        data-test={test}
        to={to}
      >
        {label}
      </Link>
    </li>
  );
}

export function TabColumnList({ children, navLabel, test }) {
  return (
    <nav aria-label={navLabel} data-test={test}>
      <ul className="rounded py-2 w-full md:w-[25%] lg:w-[15%] bg-gray-50 border-gray-300 border min-w-56">
        {children}
      </ul>
    </nav>
  );
}

export function TabColumnWithImage({ image, label, test, to }) {
  return (
    <TabColumn
      className="flex justify-between"
      label={
        <>
          {label} {image}
        </>
      }
      test={test}
      to={to}
    />
  );
}
