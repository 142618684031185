export function CurrentStep({ onClick, step }) {
  return (
    <button
      aria-current="step"
      className="flex items-center px-6 py-2 h-fit text-sm font-medium"
      onClick={onClick}
      type="button"
    >
      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-accent-600">
        <span className="text-accent-600">{step.id}</span>
      </span>
      <span className="ml-4 text-sm font-medium text-accent-600">
        {step.name}
      </span>
    </button>
  );
}
