import {
  NotificationContextProvider,
  TailwindProvider as TailwindComponentsProvider,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../hooks/useFormatMessage';

export default function TailwindProvider({ children }) {
  const t = useFormatMessage();

  return (
    <TailwindComponentsProvider t={t}>
      <NotificationContextProvider>{children}</NotificationContextProvider>
    </TailwindComponentsProvider>
  );
}
