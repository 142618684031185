import { gql } from 'graphql-request';

export const SCHOOLADMIN_SYNC_EXTERNAL_CLASS_GROUPS = gql`
  mutation schooladminSyncExternalClassGroups(
    $externalClassGroups: [SyncExternalClassGroupInput]!
  ) {
    schooladminSyncExternalClassGroups(
      externalClassGroups: $externalClassGroups
    ) {
      statusCode
      id
      message
    }
  }
`;
