import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import c from '../../utils/c';

export const ActionInternalLink = forwardRef(
  ({ classNames, item, truncate }, ref) => {
    let href = null;

    if (item?.search) {
      href = { pathname: item?.href, search: item?.search };
    } else {
      href = item?.href;
    }

    return (
      <Link
        className={classNames}
        data-test={item?.testName}
        onClick={item?.onClick}
        to={href}
        {...item?.options}
        ref={ref}
      >
        {item.icon}{' '}
        <span className={c(truncate && 'truncate')}>{item.label}</span>
      </Link>
    );
  },
);

export const ActionExternalLink = forwardRef(
  ({ classNames, item, truncate }, ref) => {
    let href = null;

    if (item.href && item.search) {
      href = `${item.href}?${item.search}`;
    } else {
      href = item.href;
    }

    return (
      <a
        ref={ref}
        className={classNames}
        data-test={item?.testName}
        href={href}
        rel="noreferrer nofollow"
        target="_blank"
      >
        {item.icon}{' '}
        <span className={c(truncate && 'truncate')}>{item.label}</span>
      </a>
    );
  },
);

export const ActionButton = forwardRef(
  ({ classNames, item, truncate }, ref) => (
    <button
      ref={ref}
      className={classNames}
      data-test={item?.testName}
      disabled={item?.disabled}
      onClick={(e) => {
        e.stopPropagation();
        item.onClick(e);
      }}
      type="button"
    >
      {item.icon}{' '}
      <span className={c(truncate && 'truncate')}>{item.label}</span>
    </button>
  ),
);
