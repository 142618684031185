import { Content } from '@ftrprf/tailwind-components';
import { LoaderBars } from 'components/LoaderBars';
import useFormatMessage from 'hooks/useFormatMessage';

export function Loader() {
  const t = useFormatMessage();

  return (
    <Content hasPaddingBottom={false}>
      <LoaderBars message={t('results_overview.calendar_item.loading')} />
    </Content>
  );
}
