import { useContext, useEffect, useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

import { ActionStyledAsButton } from '../../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../../hooks/useFormatMessage';

import { Input } from '../../../form/Input';
import { Label } from '../../../form/Label';
import { SlideViewerContext } from '../../../slideviewer/SlideViewerContextProvider';
import { dataTestIds } from '../../../../utils/constants/dataTestIds';

export default function SlideViewerOpenQuestion({ canSubmit, question }) {
  const t = useFormatMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    canOverrideAnswers,
    isStudent,
    isSubmittingAnswers,
    onSubmitQuestionAnswers,
    refetchAnswers,
    setShowConfirmationDialog,
    setSubmitAnswer,
    submittedQuestionAnswers,
  } = useContext(SlideViewerContext);

  // We make use of template literals to make sure the types are the same
  const submittedQuestionAnswer = submittedQuestionAnswers?.find(
    (submittedQuestion) =>
      `${submittedQuestion.questionId}` === `${question.id}`,
  );
  const [answer, setAnswer] = useState(
    submittedQuestionAnswer?.userAnswer || '',
  );

  useEffect(() => {
    if (question.id && !submittedQuestionAnswer) {
      setAnswer('');
    } else if (submittedQuestionAnswer) {
      setAnswer(submittedQuestionAnswer.userAnswer);
    }
  }, [question.id, submittedQuestionAnswer]);

  // the button is disabled if we can't submit or override the answer
  // or when there is no value
  // or when the value consists of only spaces
  const disabled =
    !canSubmit ||
    (!canOverrideAnswers && !!submittedQuestionAnswer) ||
    !answer ||
    (answer && answer.trim() === '');

  const isStudentCanSeeAnswer =
    !!isStudent && question.showModelAnswer && !!submittedQuestionAnswer;

  let buttonText =
    canOverrideAnswers && canSubmit
      ? t('styled-slide-viewer.save')
      : t('styled-slide-viewer.saved');
  if (!canOverrideAnswers)
    buttonText = canSubmit
      ? t('styled-slide-viewer.submit')
      : t('styled-slide-viewer.submitted');

  // ensure the button goes back to normal once response is in
  useEffect(() => {
    if (
      (isSubmittingAnswers && !isSubmitting) ||
      (!isSubmittingAnswers && isSubmitting)
    ) {
      setIsSubmitting(isSubmittingAnswers);
    }
  }, [isSubmitting, isSubmittingAnswers]);

  useEffect(() => {
    let timeoutId;

    if (isSubmitting) {
      timeoutId = setTimeout(() => {
        refetchAnswers();
      }, 5000);
    }

    return () => {
      if (!isSubmitting && timeoutId) clearTimeout(timeoutId);
    };
  }, [isSubmitting, refetchAnswers]);

  return (
    <div>
      <div className="mt-4">
        <Input
          className="text-xl"
          data-test={dataTestIds.component.slideviewer.slide.input.openQuestion}
          disabled={!!submittedQuestionAnswer && !canOverrideAnswers}
          onChange={(e) => {
            setAnswer(e.target.value);
            if (e.target.value) {
              setSubmitAnswer(
                () => () => onSubmitQuestionAnswers(question, e.target.value),
              );
            } else {
              setSubmitAnswer(null);
            }
          }}
          onKeyUp={(e) => e.stopPropagation()}
          placeholder={
            question.placeholder ?? t('styled-slide-viewer.answer_placeholder')
          }
          rows={4}
          type="multiline"
          value={answer || ''}
        />
      </div>
      <div className="mt-4 w-full flex justify-center">
        <ActionStyledAsButton
          disabled={disabled || isSubmitting}
          iconBefore={PaperAirplaneIcon}
          onClick={() => {
            setIsSubmitting(true);
            onSubmitQuestionAnswers(question, answer);
            setShowConfirmationDialog(false);
            setSubmitAnswer(null);
          }}
          test={
            dataTestIds.component.slideviewer.slide.button.submitOpenQuestion
          }
        >
          {buttonText}
        </ActionStyledAsButton>
      </div>
      {isStudentCanSeeAnswer && (
        <Label>
          {question.solution
            ? `${t('student-answers.correct_answer')} ${question?.solution}`
            : t('student-answers.no_answer')}
        </Label>
      )}
    </div>
  );
}
