import { useOutletContext } from 'react-router-dom';
import { NoResults } from './NoResults';
import { Questions } from './Questions/Questions';
import { Loader } from './Loader';

export default function ResponsesStudent() {
  const [{ calendarItem, data, denominator, error, max, setDenominator }] =
    useOutletContext();

  return (
    <>
      {error && error?.message.includes('NotFoundException') && !data ? (
        <NoResults textKey="results_overview.calendar_item.no_results_student" />
      ) : null}
      {data ? (
        <Questions
          earned={denominator}
          isStudent
          max={max}
          questions={data?.questionScores?.questions.filter(
            (question) =>
              question.viewModes.indexOf(calendarItem.viewMode) > -1,
          )}
          setDenominator={setDenominator}
        />
      ) : (
        !error?.message && <Loader />
      )}
    </>
  );
}
