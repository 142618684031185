import { useContext } from 'react';
import { useMutation } from 'react-query';

import { LanguageContext } from 'providers/LanguageProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { UPDATE_OWN_USER_INFO } from 'api/plannerService/common/mutations/updateOwnUserInfo';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUpdateOwnUserInfo() {
  const toast = useToast();
  const t = useFormatMessage();
  const { language, setLanguage } = useContext(LanguageContext);
  let currentLanguage = language;
  let newInput;

  return useMutation(
    (input) => {
      newInput = input;
      if (input.language) {
        currentLanguage = input.language;
      }
      return plannerServiceQueryClient.request(UPDATE_OWN_USER_INFO, {
        input,
      });
    },
    {
      mutationKey: 'updateOwnUserInfoInput',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('header.profile'),
        });
        if (newInput.language) {
          setLanguage(currentLanguage);
        }
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('header.profile'),
        });
      },
    },
  );
}
