import { intersection } from 'lodash-es';

export function listToOptions(list) {
  return list?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}

export function taggedListToOptions(list) {
  return list?.map((item) => {
    const { id, name, tags } = item;
    const tagNames = tags.map((tag) => tag.name);
    const tagsToCheck = ['A-Stroom', 'B-Stroom'];
    const tagsForTitle = intersection(tagNames, tagsToCheck);

    const chapterName =
      tagsForTitle.length > 0 ? `${name} (${tagsForTitle.join(', ')})` : name;

    return {
      value: id,
      label: chapterName,
    };
  });
}

export function listToOptionsValueElem(list) {
  return list?.map((item) => ({
    value: item,
    label: item.name,
  }));
}
