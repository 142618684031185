import { useContext, useEffect, useState } from 'react';

import { Toggle } from '@ftrprf/tailwind-components';

import Divider from 'components/Divider';
import { TeamsAddToCalendarAction } from 'components/Form/TeamsAddToCalendarAction';
import useUpdateOwnUserUiSettings from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserUiSettings';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import { TeamsAssignmentAddToCalendarActionOptions } from 'utils/constants/teams';

import { useForm } from 'react-hook-form';

export default function TeamsCalendarSettings() {
  const { refetchSettings, settings } = useContext(UserContext);
  const t = useFormatMessage();

  const [sendToTeams, setSendToTeams] = useState(
    settings?.teams?.sendToTeams ?? true,
  );
  const [assignmentAddToCalendarAction, setAssignmentAddToCalendarAction] =
    useState(
      settings?.teams?.assignmentAddToCalendarAction ??
        TeamsAssignmentAddToCalendarActionOptions.NONE,
    );

  const { mutateAsync: updateUiSettings } = useUpdateOwnUserUiSettings();
  const { control } = useForm();

  useEffect(() => {
    const changedSettings = { ...settings };
    changedSettings.teams = {
      assignmentAddToCalendarAction,
      sendToTeams,
    };

    const aChangeWasMade = settings.teams
      ? settings.teams.assignmentAddToCalendarAction !==
          assignmentAddToCalendarAction ||
        settings.teams.sendToTeams !== sendToTeams
      : assignmentAddToCalendarAction || sendToTeams;

    if (aChangeWasMade) {
      updateUiSettings({
        uiSettings: changedSettings,
      }).then(() => {
        refetchSettings();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentAddToCalendarAction, sendToTeams]);
  // don't include all dependencies, to prevent double updates/refetches

  return (
    <div>
      <Divider text={t('global.calendar')} />

      <div className="mt-4 flex flex-col lg:flex-row">
        <div className="flex-grow space-y-4">
          <Toggle
            label={t('profile.teams.sendTo')}
            onChange={setSendToTeams}
            test="sendToTeams"
            value={sendToTeams}
            visibleLabel
          />
          <TeamsAddToCalendarAction
            assignmentAddToCalendarAction={assignmentAddToCalendarAction}
            control={control}
            setAssignmentAddToCalendarAction={setAssignmentAddToCalendarAction}
          />
        </div>
      </div>
    </div>
  );
}
