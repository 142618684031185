import { CheckIcon } from '@heroicons/react/24/solid';

export function CompleteStep({ onClick, step }) {
  return (
    <button
      className="group flex w-full items-center h-fit"
      onClick={onClick}
      type="button"
    >
      <span className="flex items-center px-6 py-2 text-sm font-medium">
        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
          <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
        </span>
        <span className="ml-4 text-sm font-medium text-gray-900">
          {step.name}
        </span>
      </span>
    </button>
  );
}
