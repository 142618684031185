import { generatePath } from 'react-router-dom';
import { CalendarIcon, EyeIcon } from '@heroicons/react/24/outline';
import { intlFormat } from 'date-fns';

import {
  Actions,
  Dropdown,
  Label,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';

import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import URLS from 'utils/constants/urls';
import titleCase from 'utils/titleCase';

import { utcDatetoLocalDate } from 'utils/timeHelpers';
import { ReactComponent as EmptyImage } from '../../../assets/vectors/empty-image.svg';

export default function LessonsOverview({
  currentPage,
  dateRange,
  filterTeacher,
  isLoading,
  itemsPerPage,
  lessons,
  openDateRangeDialog,
  pages,
  setFilter,
  setFilterTeacher,
  setItemsPerPage,
  setPage,
  setSort,
  sort,
  teachers,
  totalItems,
}) {
  const t = useFormatMessage();

  const dateFormat = (dateString) => {
    const date = utcDatetoLocalDate(dateString);
    return (
      <div>
        {`${intlFormat(date, {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
        })} `}
        <span className="text-gray-400 text-sm">
          {t('lessons-overview.at')}
        </span>
        {` ${intlFormat(date, {
          hour: '2-digit',
          minute: '2-digit',
        })}`}
      </div>
    );
  };

  const routeToSlides = (lesson) =>
    generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
      studioId: lesson.calendarItem.studioId,
      viewMode: lesson.calendarItem.viewMode,
      sessionId: lesson.calendarItem.sessionId,
    });

  const routeToResults = (lesson) =>
    generatePath(URLS.CALENDAR_ITEM_RESULTS, {
      calendarItemId: lesson.calendarItem.id,
      programId: lesson?.chapter?.programId || nonNumericProgramIds.OWN,
    });

  const actions = (lesson) => {
    if (lesson) {
      return [
        {
          icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('global.LESSON.view'),
          href: routeToSlides(lesson),
        },
        {
          icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('global.RESULT.view'),
          href: routeToResults(lesson),
        },
      ];
    }
    return [];
  };

  return (
    <>
      <div className="w-full">
        <div className="w-full flex md:justify-between md:items-center flex-col md:flex-row mb-4 gap-4">
          <div className="flex-grow">
            <SearchBar
              onChange={setFilter}
              placeholder={t('lessons-overview.search')}
            />
          </div>
          <div className="flex-grow">
            <div
              className="flex flex-row border-collapse outline-none appearance-none w-full rounded shadow-form border-gray-300 border text-gray-500"
              onClick={openDateRangeDialog}
            >
              <span className=" px-2 py-1 rounded-l-md bg-gray-50 text-gray-500 rounded-r-none border-r border-gray-300">
                {t('lessons-overview.range')}
              </span>
              <span className="flex-1 min-w-0 block w-full  px-2 py-1 rounded-none rounded-r-md focus:ring-accent-500 focus:border-accent-500 rounded-l-none">
                {dateRange &&
                  ` ${intlFormat(dateRange.startDate, {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                  })} - ${intlFormat(dateRange.endDate, {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                  })}`}
              </span>
              <span className="flex items-center px-2 py-1">
                <CalendarIcon className="w-5 h-5" />
              </span>
            </div>
          </div>
          <div className="flex-grow">
            <Label className="sr-only" htmlFor="lessonOverview_filterTeacher">
              {t('lessons-overview.filterTeacher')}
            </Label>
            <Dropdown
              disabled={!teachers}
              inputId="lessonOverview_filterTeacher"
              onChange={setFilterTeacher}
              options={teachers?.map((item) => ({
                value: item.id,
                key: item.id,
                label: item.fullName,
              }))}
              value={filterTeacher}
            />
          </div>
        </div>
      </div>
      <TableSkeleton loading={isLoading}>
        <ResponsiveTable
          headers={[
            {
              key: 'chapterItemName',
              label: t('lessons-overview.column.chapterItemName'),
            },
            {
              key: 'startTime',
              label: t('lessons-overview.column.startDate'),
            },
            {
              key: 'endTime',
              label: t('lessons-overview.column.endDate'),
            },
            {
              key: 'type',
              label: t('lessons-overview.column.type'),
              orderable: false,
            },
            {
              key: 'author',
              label: t('lessons-overview.column.author'),
              orderable: false,
            },
            {
              key: 'actions',
              label: t('global.actions'),
              orderable: false,
              className: 'max-w-fit sr-only',
            },
          ]}
          mobileBreakpoint={1700}
          mobileHeaderWidth={160}
          onSortChange={(newSort) => setSort(newSort)}
          sort={sort}
        >
          {lessons?.map((lesson) => (
            <ResponsiveTableRow key={lesson.calendarItem.id} data={lesson}>
              <ResponsiveTableCol>
                <div className="flex flex-row">
                  <div className="flex flex-col mr-2">
                    {lesson.lessonContentImageUri ||
                    lesson.chapterItem?.blobUri ? (
                      <img
                        alt=""
                        className="rounded h-12 w-12 object-cover"
                        src={
                          lesson.chapterItem?.blobUri ??
                          lesson.lessonContentImageUri
                        }
                      />
                    ) : (
                      <EmptyImage className="rounded bg-accent-50 h-12 w-12 object-cover" />
                    )}
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-gray-400 text-sm">
                      {lesson.chapter?.name || t('curricula.lessons.own')}
                    </div>
                    <div>
                      {lesson.chapterItem?.name || lesson.lessonContentTitle}
                    </div>
                  </div>
                </div>
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                <div className="text-gray-400 text-sm">
                  {`${t('lessons-overview.planned_start')}`}
                </div>
                {dateFormat(lesson.calendarItem.startTime)}
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                <div className="text-gray-400 text-sm">
                  {`${t('lessons-overview.planned_end')}`}
                </div>
                {dateFormat(lesson.calendarItem.endTime)}
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                {titleCase(lesson.lessonContentType)}
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                {lesson.firstName} {lesson.lastName}
              </ResponsiveTableCol>
              <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                <Actions items={actions(lesson)} />
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          ))}
        </ResponsiveTable>

        {Boolean(lessons && lessons.length) && (
          <Pagination
            className="mt-12"
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={(page) => setPage(page)}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
            totalPages={pages}
          />
        )}
      </TableSkeleton>
    </>
  );
}
