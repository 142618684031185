import { ErrorUnplugged } from './ErrorUnplugged';

export function Error403({ message }) {
  return (
    <ErrorUnplugged
      message={message}
      statusCode="403"
      titleKey="errors.403.header"
    />
  );
}
