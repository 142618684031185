export function useFileDownload(mode) {
  const forceDownload = (blob, filename) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const downloadResource = (url, filename) => {
    let modifiedFilename = filename.slice();
    if (!filename) modifiedFilename = url.split('\\').pop().split('/').pop();
    fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, modifiedFilename);
      });
  };

  return downloadResource;
}

useFileDownload.defaultProps = {
  mode: 'cors',
};
