import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { TeamsAssignmentAddToCalendarActionOptions } from 'utils/constants/teams';
import { TeamsSettingsMessage } from 'pages/Calendar/partials/TeamsInfo/TeamsSettingsMessage';

export function TeamsAddToCalendarAction({
  assignmentAddToCalendarAction,
  control,
  labelClassName = 'font-semibold mr-4',
  setAssignmentAddToCalendarAction,
  showDescription = false,
  wrapperClassName = 'mt-4 flex flex-col lg:flex-row items-center',
}) {
  const t = useFormatMessage();
  const teamsAssignmentDropdownOptions = useMemo(
    () =>
      Object.keys(TeamsAssignmentAddToCalendarActionOptions).map((key) => ({
        key,
        label: t(`profile.teams.assignmentAddToCalendarActions.${key}`),
        value: key,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [TeamsAssignmentAddToCalendarActionOptions],
  );

  return (
    <div className={wrapperClassName}>
      <Label className={labelClassName} htmlFor="assignmentaddToCalendarAction">
        {t('profile.teams.assignmentAddToCalendarActions')}
      </Label>
      <Controller
        control={control}
        defaultValue={assignmentAddToCalendarAction}
        name="type"
        render={() => (
          <Dropdown
            className="min-w-64 text-black"
            description={showDescription ? <TeamsSettingsMessage /> : null}
            descriptionClassName="mt-1 text-xs text-gray-500"
            inputId="assignmentAddToCalendarAction"
            isMulti={false}
            onChange={setAssignmentAddToCalendarAction}
            options={teamsAssignmentDropdownOptions}
            value={assignmentAddToCalendarAction}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('global.required-field'),
          },
        }}
        type="select"
      />
    </div>
  );
}
