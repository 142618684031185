import { gql } from 'graphql-request';

export const TEACHER_ORGANIZATION_USERS = gql`
  query teacherOrganizationUsers(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherOrganizationUsers(
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        userName
        firstName
        lastName
        fullName
        displayName
        language
        email
        organizationId
        enabled
        roles
        avatar
      }
    }
  }
`;
