import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';

import c from 'utils/c';

import { PagerLinkLeft, PagerLinkRight } from './PagerLink';

export function Pager({
  currentItem,
  getItemIndex,
  getNewLocation,
  items,
  nameAttribute,
  setItemId,
}) {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(
    getItemIndex(items, currentItem.id),
  );
  const [selectedItem, setSelectedItem] = useState(currentItem);
  const [query, setQuery] = useState('');
  const onComboBoxChange = (value) => {
    setSelectedItem(value);
    const selectedItemIndex = getItemIndex(items, value.id);
    setCurrentIndex(selectedItemIndex);
    setItemId(value.id);
    const newLocation = getNewLocation(value);
    navigate(newLocation);
  };

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) =>
          item[nameAttribute].toLowerCase().includes(query.toLowerCase()),
        );

  const changeIndex = (e, newIndex) => {
    e.preventDefault();
    setCurrentIndex(newIndex);
    setSelectedItem(items[newIndex]);
    setItemId(items[newIndex].id);
  };

  const goBack = (e) => {
    onComboBoxChange(items[currentIndex - 1]);
    changeIndex(e, currentIndex - 1);
  };

  const goForward = (e) => {
    onComboBoxChange(items[currentIndex + 1]);
    changeIndex(e, currentIndex + 1);
  };

  return (
    <div className="w-full flex justify-center mt-2 items-center">
      <PagerLinkLeft
        getLink={() => getNewLocation(selectedItem)}
        items={items}
        newIndex={currentIndex - 1}
        onClick={goBack}
      />
      <Combobox as="span" onChange={onComboBoxChange} value={selectedItem}>
        <Combobox.Label className="sr-only">
          {selectedItem[nameAttribute]}
        </Combobox.Label>
        <span className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-accent-500 focus:outline-none focus:ring-1 focus:ring-accent-500 sm:text-sm text-center"
            data-test="studentPager"
            displayValue={(selectedStudent) => selectedStudent?.[nameAttribute]}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </Combobox.Button>

          {filteredItems.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.map((item) => (
                <Combobox.Option
                  key={item.id}
                  className={({ active }) =>
                    c(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-accent-600 text-white' : 'text-gray-900',
                    )
                  }
                  value={item}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={c(
                          'block truncate',
                          selected && 'font-semibold',
                        )}
                      >
                        {item[nameAttribute]}
                      </span>

                      {selected && (
                        <span
                          className={c(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-accent-600',
                          )}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </span>
      </Combobox>
      <PagerLinkRight
        getLink={() => getNewLocation(selectedItem)}
        items={items}
        newIndex={currentIndex + 1}
        onClick={goForward}
      />
    </div>
  );
}
