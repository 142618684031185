import MicrosoftLogo from 'assets/vectors/microsoftLogo.svg';
import { generatePath } from 'react-router-dom';
import { Loader } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS, { schooladminTabs } from 'utils/constants/urls';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';

export function MicrosoftButton({
  isLoading,
  targetLink = generatePath(URLS.MANAGE_TAB, { tab: schooladminTabs.TEAMS }),
  translationKey = 'global.login.with.microsoft',
}) {
  const t = useFormatMessage();

  const { initialToken: jwtToken } = useContext(UserContext);
  const authUrl = process.env.REACT_APP_TEAMS_AUTH_URL;
  const domainName = process.env.REACT_APP_AD_REDIRECT_LINK;

  const url = `${authUrl}?target_link_uri=${domainName}${targetLink}&jwt_token=${jwtToken}`;

  return (
    <a
      className="border-2 border-black flex items-center py-1 bg-black text-white mx-auto w-fit"
      href={url}
    >
      <img alt="" className="block h-6 px-1 w-auto" src={MicrosoftLogo} />
      <span className="flex px-3">
        {isLoading && <Loader className="h-6 w-6 max-w-[1.5rem] mr-2" white />}
        {t(translationKey)}
      </span>
    </a>
  );
}
