import * as sanitizeHtml from 'sanitize-html';

export function sanitizeHtmlAllowingInternalImages(valueToSanitize) {
  const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img']);
  const { allowedAttributes } = sanitizeHtml.defaults;

  const filterExternalImages = (frame) =>
    frame.tag === 'img' &&
    !frame.attribs.src.startsWith(
      'https://codefeverpublic.blob.core.windows.net/public-content',
    );

  return sanitizeHtml(valueToSanitize, {
    allowedAttributes,
    allowedTags,
    exclusiveFilter: filterExternalImages,
  });
}
