import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_ADD_PROGRAMS_TO_TEACHER } from 'api/plannerService/schooladmin/mutations/schooladminAddProgramsToTeacher';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminAddProgramsToTeacher() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ programIds, userId }) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_ADD_PROGRAMS_TO_TEACHER, {
        input: { programIds, userId },
      }),
    {
      mutationKey: 'schooladminAddProgramsToTeacher',
      onSuccess: () => {
        toast(TOASTTYPES.ADDSUCCESS, {
          str: t('global.PROGRAM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.ADDERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );
  return mutation;
}
