/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import c from '../utils/c';

export const CheckBox = forwardRef(
  (
    {
      checked: givenChecked,
      className = '',
      classNameLabel = '',
      defaultChecked = false,
      disabled = false,
      label = '',
      onChange = () => {},
      ...props
    },
    ref,
  ) => {
    const [checked, setChecked] = useState(defaultChecked);

    useEffect(() => {
      setChecked(givenChecked);
    }, [givenChecked]);

    return (
      <button
        className="flex items-center"
        disabled={disabled}
        onClick={() => {
          // prevent race conditions
          const newValue = !checked;

          setChecked(newValue);
          onChange(newValue);
        }}
        type="button"
        {...props}
      >
        <span
          ref={ref}
          aria-checked={checked}
          aria-labelledby={label && label?.replaceAll(' ', '')}
          className={c(
            className,
            'w-4 h-4 rounded cursor-pointer transition duration-200 border border-gray-400 hover:outline hover:outline-offset-2 hover:outline-2 hover:outline-accent-600 focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-accent-600',
            checked && 'bg-accent-600 border-accent-600',
            !checked && 'bg-white',
            disabled ? 'bg-gray-300 cursor-not-allowed' : 'cursor-pointer',
          )}
          role="checkbox"
        >
          <FaCheck
            className={c(
              'text-white stroke-current w-full h-full m-auto transform transition duration-200',
              !checked && 'scale-0',
              checked && 'scale-75',
            )}
          />
        </span>
        {label && (
          <span
            className={c(
              classNameLabel,
              'ml-2',
              disabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer',
            )}
            id={label.replace(' ', '')}
          >
            {label}
          </span>
        )}
      </button>
    );
  },
);
