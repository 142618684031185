import { utcDatetoLocalDate } from 'utils/timeHelpers';

export const structureEvents = (events) =>
  events.map((event) => ({
    ...event,
    id: event.calendarItem.id,
    chapterItemId: event.chapterItem?.id,
    classGroups: event.calendarItem.classGroups,
    closedAfterEndTime: event.closedAfterEndTime,
    darkerColor: event.color,
    display: 'backgroundColor',
    editable: utcDatetoLocalDate(event.calendarItem.startTime) > new Date(),
    end: utcDatetoLocalDate(event.calendarItem.endTime),
    endDate: event.calendarItem.endTime,
    sessionId: event.calendarItem.sessionId,
    start: utcDatetoLocalDate(event.calendarItem.startTime),
    startDate: event.calendarItem.startTime,
    studioId: event.calendarItem.studioId,
    teamsCalendarItem: event.calendarItem.teamsCalendarItem,
    title: event.chapterItem?.name || event.lessonContentTitle,
    viewMode: event.calendarItem.viewMode,
  }));
