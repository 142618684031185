export function formatSearchParamsForUserManagement(urlSearchParams) {
  const searchParams = {
    enabled: true,
    firstname: '',
    lastname: '',
    roles: [],
  };

  urlSearchParams.forEach((value, key) => {
    if (key === 'roles') {
      searchParams.roles.push(value);
    } else if (!(value === 'null')) searchParams[key] = value;
  });

  switch (searchParams.enabled) {
    case 'false':
      searchParams.enabled = false;
      break;
    case 'true':
      searchParams.enabled = true;
      break;
    default:
      searchParams.enabled = true;
      break;
  }

  return searchParams;
}
