import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import './fullviewCalendar.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

import { useContext, useState } from 'react';
import { Datepicker, locale } from '@mobiscroll/react';
import { addDays, subDays } from 'date-fns';

import { LanguageContext } from 'providers/LanguageProvider';
import { UserContext } from 'providers/UserProvider';

import c from 'utils/c';
import {
  MONTHNAMES_EN,
  MONTHNAMES_SHORT_EN,
  MONTHNAMES_SHORT_FR,
  MONTHNAMES_SHORT_NL,
} from 'utils/constants/months';

import { CalendarContext } from '../Home/Home';

import { GroupFilter } from './partials/GroupFilter';

export default function CalendarAsideContainer({
  classGroups,
  className,
  selectedDate,
}) {
  const { isTeacher } = useContext(UserContext);

  const calendarData = useContext(CalendarContext);
  const { events, filteredEvents, selectedClasses, setClasses, setDay } =
    calendarData;
  const { teachers } = calendarData;
  const { setRange, setSelectedTeacher } = calendarData;
  const [setInstance] = useState(null);
  const { language } = useContext(LanguageContext);

  const classButtonClick = (classGroup) => {
    if (selectedClasses.includes(classGroup)) {
      setClasses(selectedClasses?.filter((c) => c.id !== classGroup.id));
    } else {
      setClasses([...selectedClasses, classGroup]);
    }
  };

  const teacherButtonClick = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const changeWeek = (event) => {
    const foo = new Date(event.date);
    const day = foo.getDay();
    let diff = foo.getDate() - day;
    if (foo.getDay() === 0) {
      diff -= 6;
    } else {
      diff += 1;
    }
    const temp = new Date(foo.setDate(diff));
    setDay(temp);
  };

  const changeView = (event) => {
    setTimeout(() => {
      setRange(subDays(event.firstDay, 1), addDays(event.lastDay, 1));
    }, 300);
  };

  // We have to use the NL locale, because otherwise the calendar layout gets changed (weeks starting on sunday)
  // So we need to change the months to english
  // We also got linguistic complaints about the short versions of months, so we need to use our own
  const copyLocale = language === 'en' ? locale.nl : locale[language];
  if (language === 'en') {
    copyLocale.monthNames = MONTHNAMES_EN;
    copyLocale.monthNamesShort = MONTHNAMES_SHORT_EN;
  }

  if (language === 'nl') {
    copyLocale.monthNamesShort = MONTHNAMES_SHORT_NL;
  }

  if (language === 'fr') {
    copyLocale.monthNamesShort = MONTHNAMES_SHORT_FR;
  }

  return (
    <div
      className={c(className, 'pt-2 sticky top-0')}
      style={{ alignSelf: 'start' }}
    >
      <div style={{ height: '350px' }}>
        <Datepicker
          ref={setInstance}
          controls={['calendar']}
          display="inline"
          firstSelectDay={1}
          locale={copyLocale}
          marked={
            isTeacher
              ? filteredEvents.map((event) => ({
                  date: event.start,
                  color: event.color,
                }))
              : events.map((event) => ({
                  date: event.start,
                  color: event.color,
                }))
          }
          onCellClick={changeWeek}
          onInit={() =>
            document.getElementsByClassName('mbsc-calendar-today')[0].click()
          }
          onPageChange={changeView}
          onPageLoaded={changeView}
          select="preset-range"
          selectSize={7}
          theme="material"
          themeVariant="light"
          touchUi
          value={selectedDate}
        />
      </div>

      {isTeacher && (
        <>
          <GroupFilter
            emptyText="calendar.no_classes"
            items={classGroups}
            legend="global.CLASS.plural"
            onClick={classButtonClick}
            test="classGroupFilter"
          />
          <GroupFilter
            className="mt-6"
            emptyText="calendar.no_teachers"
            items={teachers}
            legend="global.TEACHER.plural"
            onClick={teacherButtonClick}
            open={false}
            test="teacherFilter"
          />
        </>
      )}
    </div>
  );
}
