import { generatePath } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/outline';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS from 'utils/constants/urls';

import { NoResults } from '../NoResults';

import { Exercise } from './Exercise';

export function Scratch({
  exercises,
  lessonSessionId,
  noResultsKey,
  studentId,
}) {
  const t = useFormatMessage();
  return exercises && exercises.length ? (
    <ul className="w-full">
      {exercises.map((exercise, index) => {
        const key = `scr/ex/${Date.now() + index}`;
        return (
          <Exercise
            key={key}
            actions={[
              {
                href: generatePath(URLS.EXERCISE_OPEN, {
                  exerciseVersionId: exercise.exerciseVersionId,
                  lessonSessionId,
                  userId: studentId,
                }),
                icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
                label: t('global.EXERCISE.view'),
                options: {
                  rel: 'noopener noreferrer',
                  target: '_blank',
                },
              },
            ]}
            exercise={exercise}
          />
        );
      })}
    </ul>
  ) : (
    <NoResults textKey={noResultsKey} />
  );
}
