import { useEffect, useState } from 'react';

import useFormatMessage from '../../hooks/useFormatMessage';

import { Dialog, DialogActions, DialogContent, DialogHeader } from './index';
import { ActionStyledAsButton } from '../buttons/ActionStyledAsButton';

export function ConfirmationDialog({
  cancelText = '',
  confirmText = '',
  content = null,
  isLoading = false,
  isOpen,
  onConfirm,
  onDismiss,
  testInfo = null,
  title = '',
}) {
  const t = useFormatMessage();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisabled(false);
    }
  }, [isOpen]);

  const finalTitle = title || t('confirmation-dialog.title');

  return (
    <Dialog aria-label={finalTitle} isOpen={isOpen} onDismiss={onDismiss}>
      <div data-test={testInfo?.modal}>
        <DialogHeader>{finalTitle}</DialogHeader>

        {content && <DialogContent>{content}</DialogContent>}

        <DialogActions>
          <ActionStyledAsButton
            disabled={disabled}
            onClick={onDismiss}
            test={testInfo?.dismiss}
            variant="text"
          >
            {cancelText || t('dialog.cancel')}
          </ActionStyledAsButton>

          <ActionStyledAsButton
            disabled={disabled}
            loading={isLoading}
            onClick={() => {
              setDisabled(true);
              onConfirm();
            }}
            test={testInfo?.confirm}
          >
            {confirmText || t('confirmation-dialog.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}
