// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { Error, Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import issuers from 'utils/constants/issuers';
import { dataTestIds } from 'utils/dataTestIds';

export function TeamsFields({ errors, issuer, register }) {
  const t = useFormatMessage();

  return (
    <fieldset className="border border-solid border-gray-300 p-4">
      <legend className="font-semibold text-xs p-1">{issuer}</legend>

      <div className="flex flex-col">
        <Label htmlFor="aouOrganizationDialog-tenantId">
          {t('aou-org.tenantId')}
        </Label>
        <Input
          data-test={dataTestIds.modal.organization.input.tenantId}
          hasErrors={Boolean(errors.tenantId)}
          id="aouOrganizationDialog-tenantId"
          type="text"
          {...register('tenantId', {
            required: {
              message: t('global.required-field'),
              value: true,
            },
            validate: (value) =>
              issuer !== issuers.TEAMS ||
              value.trim() !== '' ||
              t('global.required-field'),
          })}
        />
        <Error
          message={errors.tenantId?.message}
          shouldShow={errors.tenantId}
        />
      </div>
    </fieldset>
  );
}
