import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { ActionStyledAsButton } from '../buttons/ActionStyledAsButton';

export function ShowHideTransition({
  buttonClasses = 'absolute -top-8 right-4 z-20',
  buttonTextOnHide = 'Show',
  buttonTextOnShow = 'Hide',
  children,
  className = '',
  defaultOpen = true,
}) {
  const [isShowing, setIsShowing] = useState(defaultOpen);
  const [buttonText, setButtonText] = useState(
    defaultOpen ? buttonTextOnShow : buttonTextOnHide,
  );

  useEffect(() => {
    /**
     * The below is added because the transition takes 300ms.
     * Without this, the button text changes immediately, which comes across like an error.
     * With this, the text changes right as the transition finishes, avoiding that problem.
     */
    setTimeout(() => {
      setButtonText(isShowing ? buttonTextOnShow : buttonTextOnHide);
    }, 300);
  }, [buttonTextOnHide, buttonTextOnShow, isShowing]);

  return (
    <div className="relative pt-4">
      <ActionStyledAsButton
        className={buttonClasses}
        onClick={() => setIsShowing((isShowing) => !isShowing)}
        secondary
      >
        {buttonText}
      </ActionStyledAsButton>
      <Transition
        className={className}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        show={isShowing}
      >
        {children}
      </Transition>
    </div>
  );
}
