import { Toggle } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function AvatarBooleanProperty({ id, selected = false, updateProp }) {
  const t = useFormatMessage();

  return (
    <Toggle
      className="py-2 mt-4"
      label={t(`avatar.prop.${id}`)}
      labelClassName="font-semibold w-1/3"
      onChange={(e) => updateProp(e, id)}
      test={`avatar_${id}`}
      value={selected}
      visibleLabel
    />
  );
}
