import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_AVAILABLE_REPORTS } from 'api/plannerService/admin/queries/adminAvailableReports';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminAvailableReports(isAdmin) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['adminAvailableReports'],
    () => plannerServiceQueryClient.request(ADMIN_AVAILABLE_REPORTS, {}),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.REPORT.plural'),
        });
      },
      keepPreviousData: true,
      enabled: !!isAdmin,
    },
  );

  return {
    ...response,
    data: response.data?.adminAvailableReports,
  };
}
