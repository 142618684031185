import { gql } from 'graphql-request';

export const ADMIN_CREATE_PROGRAM = gql`
  mutation adminCreateProgram($input: ProgramInput, $file: Upload) {
    adminCreateProgram(input: $input, file: $file) {
      id
      name
      type
    }
  }
`;
