import { FaExclamationTriangle } from 'react-icons/fa';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

export default function ErrorNotification({ className, content, title }) {
  const t = useFormatMessage();

  return (
    <div
      className={c(
        className,
        'w-full max-w-sm bg-red-600 py-3 px-3 text-white flex rounded',
      )}
    >
      <div className="mt-1 mr-2">
        <FaExclamationTriangle />
      </div>
      <div>
        <div className="uppercase font-bold mb-px">
          {title || t('notifications.errors.label')}
        </div>
        <div>{content || t('notifications.errors.something_went_wrong')}</div>
      </div>
    </div>
  );
}
