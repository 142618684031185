import { gql } from 'graphql-request';

export const STUDENT_LESSONS = gql`
  query studentLessons($filter: [JSON], $page: Int!, $size: Int!, $sort: JSON) {
    studentLessons(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        calendarItem {
          closedAfterEndTime
          endTime
          hidden
          id
          viewMode
          studioId
          sessionId
          startTime
          classGroups {
            id
            name
          }
        }
        userId
        firstName
        lastName
        chapter {
          id
          name
          programId
          blobUri
        }
        chapterItem {
          blobUri
          id
          name
          studioId
        }
        color
        lessonContentTitle
        lessonContentType
        lessonContentImageUri
        groupRoles {
          id
          role
        }
      }
    }
  }
`;
