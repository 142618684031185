import { useContext } from 'react';
import { generatePath, Link } from 'react-router-dom';
import padStart from 'lodash-es/padStart';

import {
  Badge,
  ContentCard,
  linkFocusStyles,
  linkHoverStyles,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import c from 'utils/c';
import contentTypes from 'utils/constants/contentTypes';
import { lessonTypes } from 'utils/constants/lessonTypes';
import publishStatuses from 'utils/constants/publishStatuses';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';

import { dateFormat } from 'utils/timeHelpers';
import { ChapterItemEmptyState } from './ChapterItemEmptyState';
import { ContentActions } from './ContentActions';
import {
  getEarliestCalendarDate,
  getSortedCalendarItems,
} from './getEarliestCalendarDate';

export default function ContentOverview({
  chapterItems,
  itemInfo,
  openCalendarModal,
  openQuickPlanModal,
  pageTitle,
  setItemInfo,
}) {
  const t = useFormatMessage();
  const { chapterId, programId } = useParams();

  const { isStudent, isTeacher, organization, settings } =
    useContext(UserContext);

  if (chapterItems?.length === 0) {
    return <ChapterItemEmptyState ownContent={false} />;
  }
  return (
    <ul className="w-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 chapterItems">
      {chapterItems &&
        chapterItems.map((chapterItem) => {
          const earliestCalItem = getSortedCalendarItems(
            chapterItem.calendarItems,
          )?.[0];
          const earliestCalendarDate = chapterItem.calendarItems
            ? getEarliestCalendarDate(chapterItem.calendarItems)
            : undefined;

          const shouldBeProjection =
            isTeacher && chapterItem?.lessons[0].type !== lessonTypes.EXAM;
          const viewMode = shouldBeProjection
            ? settings?.slideViewer?.viewMode ?? viewModes.PROJECTION
            : earliestCalItem?.viewMode ?? viewModes.CLASSICAL;
          // Just add a random hardcoded uuid for a teacher, this solves all the issues
          const viewUrl = generatePath(
            URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID,
            {
              sessionId: isTeacher
                ? `1cc3b91a-bfb5-4831-b6eb-f849c6${padStart(
                    chapterItem?.lessons[0]?.studioId,
                    6,
                    String(0),
                  )}`
                : earliestCalItem?.sessionId,
              studioId:
                earliestCalItem?.studioId || chapterItem?.lessons[0]?.studioId,
              viewMode,
            },
          );
          const resultsUrl = generatePath(URLS.LESSON_RESULTS, {
            chapterId,
            chapterItemId: chapterItem.id,
            lessonId: chapterItem?.lessons[0].id,
            programId,
          });

          return (
            <li
              key={chapterItem.id}
              className={c(
                'w-full',
                chapterItem?.lessons.length === 1 || isStudent
                  ? 'col-span-1'
                  : 'md:col-span-2',
              )}
            >
              <ContentCard
                defaultImage={EmptyImage}
                extraActions={
                  isTeacher &&
                  chapterItem?.lessons.length === 1 && (
                    <div className="pt-1 px-1 text-right">
                      <ContentActions
                        chapterItemId={chapterItem.id}
                        document={chapterItem.document}
                        hasPlanButtons={organization.showCalendar}
                        isExam={
                          chapterItem.type === contentTypes.EXAM ||
                          chapterItem.lessons[0]?.type === contentTypes.EXAM
                        }
                        itemInfo={itemInfo}
                        openCalendarModal={openCalendarModal}
                        openQuickPlanModal={openQuickPlanModal}
                        quickplanInfo={{
                          chapterItem,
                          pageTitle,
                        }}
                        setItemInfo={setItemInfo}
                        studioId={chapterItem?.lessons[0]?.studioId}
                      />
                    </div>
                  )
                }
                hasActions
                hideOverflow={false}
                image={
                  chapterItem.blobUri
                    ? chapterItem.blobUri
                    : chapterItem?.lessons[0].imageUri
                }
                subTitle={dateFormat(earliestCalendarDate, true)}
                tags={chapterItem.tags || []}
                title={chapterItem.name}
              >
                {chapterItem?.lessons.length === 1 || isStudent ? (
                  <div className="w-full flex flex-grow justify-between align-middle h-8">
                    {((chapterItem?.lessons[0].status !==
                      publishStatuses.CLOSED &&
                      new Date(earliestCalendarDate) < new Date()) ||
                      isTeacher) && (
                      <ContentCard.Action className="flex flex-grow justify-center">
                        <Link
                          className="w-full h-full self-center pt-[0.625rem]"
                          to={viewUrl || ''}
                        >
                          {t('global.view')}
                        </Link>
                      </ContentCard.Action>
                    )}
                    {resultsUrl && (
                      <ContentCard.Action className="flex flex-grow justify-center">
                        <Link
                          className="w-full h-full self-center pt-[0.625rem]"
                          to={resultsUrl}
                        >
                          {t('content.results')}
                        </Link>
                      </ContentCard.Action>
                    )}
                  </div>
                ) : (
                  <div className="w-full p-4 pt-0">
                    {chapterItem?.lessons.map((lesson) => {
                      const shouldBeProjection =
                        isTeacher &&
                        chapterItem?.lessons[0].type !== lessonTypes.EXAM;
                      const viewMode = shouldBeProjection
                        ? settings.slideViewer?.viewMode ?? viewModes.PROJECTION
                        : earliestCalItem?.viewMode ?? viewModes.CLASSICAL;
                      const viewUrl = generatePath(
                        URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID,
                        {
                          sessionId: isTeacher
                            ? `1cc3b91a-bfb5-4831-b6eb-f849c6${padStart(
                                lesson?.studioId,
                                6,
                                String(0),
                              )}`
                            : earliestCalItem?.sessionId,
                          studioId: lesson?.studioId,
                          viewMode,
                        },
                      );

                      return (
                        <div key={lesson.id} className="flex h-8 items-center">
                          <div className=" text-gray-500 flex-grow text-left">
                            {lesson.title}
                          </div>
                          <div>
                            {lesson.copiedBy && (
                              <Badge warning>{lesson.copiedBy.firstName}</Badge>
                            )}
                          </div>
                          <div className="ml-4 flex items-center">
                            <ContentCard.Action className="px-4">
                              <Link
                                className={c(
                                  'pt-[5px]',
                                  linkFocusStyles,
                                  linkHoverStyles,
                                )}
                                replace={false}
                                to={viewUrl || ''}
                              >
                                {t('global.view')}
                              </Link>
                            </ContentCard.Action>
                            <ContentCard.Action className="px-4">
                              <Link
                                className={c(
                                  'pt-[5px]',
                                  linkFocusStyles,
                                  linkHoverStyles,
                                )}
                                to={generatePath(URLS.LESSON_RESULTS, {
                                  chapterId,
                                  chapterItemId: chapterItem.id,
                                  lessonId: chapterItem?.lessons[0].id,
                                  programId,
                                })}
                              >
                                {t('content.results')}
                              </Link>
                            </ContentCard.Action>
                            {isTeacher && (
                              <ContentActions
                                chapterItemId={chapterItem.id}
                                copiedLesson={lesson.copiedBy}
                                document={chapterItem.document}
                                hasPlanButtons={organization.showCalendar}
                                isExam={
                                  chapterItem.type === contentTypes.EXAM ||
                                  chapterItem.lessons[0]?.type ===
                                    contentTypes.EXAM
                                }
                                itemInfo={itemInfo}
                                openCalendarModal={openCalendarModal}
                                openQuickPlanModal={openQuickPlanModal}
                                quickplanInfo={{
                                  chapterItem,
                                  pageTitle,
                                }}
                                setItemInfo={setItemInfo}
                                status={lesson.status}
                                studioId={lesson.studioId}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </ContentCard>
            </li>
          );
        })}
    </ul>
  );
}
