function getNameId(name) {
  return name.trim().replace(/ /g, '').toLowerCase();
}

export function Member({ member, onChange }) {
  const nameId = getNameId(member.name);

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          checked={member.include}
          className="focus:ring-accent-500 h-4 w-4 text-accent-600 border-gray-300 rounded"
          id={nameId}
          name="members"
          onChange={onChange}
          type="checkbox"
        />
      </div>
      <label
        className="inline-block ml-3 text-sm font-medium text-gray-700"
        htmlFor={nameId}
      >
        {member.name}
      </label>
    </div>
  );
}
