import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_CREATE_CHAPTER } from 'api/plannerService/admin/mutations/adminCreateChapter';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminCreateChapter() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ file, input, programId }) =>
      plannerServiceQueryClient.request(ADMIN_CREATE_CHAPTER, {
        programId,
        input,
        file,
      }),
    {
      mutationKey: 'adminCreateChapter',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.CHAPTER.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('global.CHAPTER.singular'),
        });
      },
    },
  );
}
