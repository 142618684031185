import { gql } from 'graphql-request';

export const ADMIN_ADD_PROGRAM_TO_PERIOD = gql`
  mutation adminAddProgramToPeriod(
    $periodId: Int!
    $programId: Int!
    $maxSeats: Int!
  ) {
    adminAddProgramToPeriod(
      periodId: $periodId
      programId: $programId
      maxSeats: $maxSeats
    ) {
      statusCode
      id
      message
    }
  }
`;
