import { useEffect } from 'react';

export default function useTitle(...titles) {
  useEffect(() => {
    const previousTitle = window.document.title;
    window.document.title =
      titles.filter(Boolean).length > 0
        ? `FTRPRF - ${titles.join(' - ')}`
        : 'FTRPRF';

    return () => {
      window.document.title = previousTitle;
    };
  }, [titles]);
}
