export default function IconAfterWithLoader({
  iconAfter,
  loader,
  loading,
  showIcon,
}) {
  if (showIcon) {
    return <span className="ml-2">{loading ? loader() : iconAfter()}</span>;
  }

  return null;
}
