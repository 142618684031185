// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { Error, Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { useEffect } from 'react';
import { Buffer } from 'buffer';
import validateUuid from 'uuid-validate';
import { dataTestIds } from '../../../../../utils/dataTestIds';

export function OneRosterFields({ errors, register }) {
  const t = useFormatMessage();

  useEffect(() => {
    // https://github.com/microsoft/uuid-validate/issues/9#issuecomment-1225756345
    window.Buffer = Buffer;
  }, []);

  return (
    <fieldset className="border border-solid border-gray-300 p-4 flex flex-col gap-4">
      <legend className="font-semibold text-xs p-1">
        {t('aou-org.import.one-roster.title')}
      </legend>

      <div className="flex flex-col">
        <Label htmlFor="aouOrganizationDialog-oneRoster-clientId">
          {t('aou-org.import.clientId')}
        </Label>
        <Input
          data-test={dataTestIds.modal.organization.input.clientId}
          hasErrors={Boolean(
            errors.oneRosterCredentials && errors.oneRosterCredentials.clientId,
          )}
          id="aouOrganizationDialog-oneRoster-clientId"
          type="text"
          {...register('oneRosterCredentials.clientId', {
            required: {
              message: t('global.required-field'),
              value: true,
            },
            validate: (value) => {
              if (value?.length !== 36) {
                return t('global.uuid-length');
              }

              if (validateUuid(value)) {
                return true;
              }

              return t('global.uuid-valid');
            },
          })}
        />
        <Error
          message={errors.oneRosterCredentials?.clientId?.message}
          shouldShow={errors.oneRosterCredentials?.clientId}
        />
      </div>

      <div className="flex flex-col">
        <Label htmlFor="aouOrganizationDialog-oneRoster-clientSecret">
          {t('aou-org.import.clientSecret')}
        </Label>
        <Input
          data-test={dataTestIds.modal.organization.input.clientSecret}
          hasErrors={Boolean(
            errors.oneRosterCredentials &&
              errors.oneRosterCredentials.clientSecret,
          )}
          id="aouOrganizationDialog-oneRoster-clientSecret"
          type="text"
          {...register('oneRosterCredentials.clientSecret', {
            required: {
              message: t('global.required-field'),
              value: true,
            },
          })}
        />
        <Error
          message={errors.oneRosterCredentials?.clientSecret?.message}
          shouldShow={errors.oneRosterCredentials?.clientSecret}
        />
      </div>
    </fieldset>
  );
}
