import useFormatMessage from 'hooks/useFormatMessage';

export function CurrentSlideNumberIndicator({
  currentSlideIndex,
  lessonLength,
}) {
  const t = useFormatMessage();

  return (
    <>
      {t('multi-screen-mode.current-slide')}
      <span className="font-normal text-sm">
        {` (${t('multi-screen-mode.progress', {
          currentIndex: currentSlideIndex + 1,
          length: lessonLength,
        })})`}
      </span>
    </>
  );
}
