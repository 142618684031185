export const testStatus = {
  ACED_IT: 'ACED_IT',
  FLUNKED: 'FLUNKED',
  PASSED: 'PASSED',
};

export const classNamesForTableCell = {
  ACED_IT: 'bg-green-50 text-green-600',
  FLUNKED: 'bg-red-50 text-red-600',
  PASSED: 'bg-yellow-50 text-yellow-600',
};

export const classNamesForDiv = {
  ACED_IT:
    'border-2 border-green-50 hover:border-green-600 hover:decoration-green-600',
  FLUNKED:
    'border-2 border-red-50 hover:border-red-600 hover:decoration-red-600',
  PASSED:
    'border-2 border-yellow-50 hover:border-yellow-600 hover:decoration-yellow-600',
};

export const bulletColour = {
  ACED_IT: 'bg-green-600',
  FLUNKED: 'bg-red-600',
  PASSED: 'bg-yellow-600',
};
