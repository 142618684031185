import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

import { dataTestIds } from '../../utils/constants/dataTestIds';
import c from '../../utils/c';

import { ActionButton, ActionExternalLink, ActionInternalLink } from './Action';

export function Actions({
  buttonClassName = '',
  className = '',
  items = [],
  noShadow = false,
  small = false,
  width = '',
}) {
  const mainItem = items[0];
  const mainItemClasses = c(
    'relative inline-flex whitespace-nowrap items-center border border-gray-300 border-solid bg-white  text-gray-700 hover:bg-gray-50 hover:outline-none hover:ring-1 hover:ring-accent-500 focus:z-10 focus:outline-none focus:ring-1 focus:ring-accent-500 focus:border-accent-500',
    small ? 'px-2 py-1 text-sm font-normal' : 'px-4 py-2  text-sm font-medium',
    items.length > 1 ? 'rounded-l-md' : 'rounded',
    buttonClassName,
  );

  return (
    <div
      className={c(
        'relative inline-flex rounded-md test',
        noShadow ? '' : 'shadow-sm',
        width,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {mainItem?.href && !mainItem.href.startsWith('http') && (
        <ActionInternalLink
          classNames={mainItemClasses}
          item={mainItem}
          truncate
        />
      )}
      {mainItem?.href && mainItem.href.startsWith('http') && (
        <ActionExternalLink
          classNames={mainItemClasses}
          item={mainItem}
          truncate
        />
      )}
      {mainItem?.onClick && !mainItem?.href && (
        <ActionButton classNames={mainItemClasses} item={mainItem} truncate />
      )}
      {items.length > 1 && (
        <Menu
          as="span"
          className={c('-ml-px relative block', className)}
          data-test={dataTestIds.component.actions.button.dropdown}
        >
          <Menu.Button
            className={c(
              'relative inline-flex items-center rounded-r-md border border-gray-300 border-solid bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-accent-500 focus:border-accent-500 border-l',
              small ? 'px-1 py-1' : 'px-2 py-2',
            )}
            data-test={dataTestIds.component.actions.button.showMore}
          >
            <span className="sr-only">Open options</span>
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {items.map((item) => (
                  <Menu.Item key={item.label} disabled={item.disabled}>
                    {({ active }) => {
                      const classNames = c(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm text-left w-full box-border focus:underline focus:underline-offset-4 focus:bg-gray-300 hover:underline hover:bg-gray-300 hover:underline-offset-4 overflow-hidden',
                      );

                      if (item?.href && !item.href.startsWith('http')) {
                        return (
                          <ActionInternalLink
                            classNames={classNames}
                            item={item}
                          />
                        );
                      }

                      if (item?.href && item.href.startsWith('http')) {
                        return (
                          <ActionExternalLink
                            classNames={classNames}
                            item={item}
                          />
                        );
                      }

                      if (item?.onClick && !item?.href) {
                        return (
                          <ActionButton classNames={classNames} item={item} />
                        );
                      }

                      return <div />;
                    }}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
}
