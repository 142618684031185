import useAdminOrganizationById from 'hooks/api/plannerService/admin/queries/useAdminOrganizationById';
import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { useState } from 'react';
import { ConfirmationDialog } from '@ftrprf/tailwind-components';
import { useAdminDeletePeriod } from 'hooks/api/plannerService/admin/mutations/useAdminDeletePeriod';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import { useParams } from 'react-router-dom';
import { ManagePeriods } from './ManagePeriods';
import { EditPeriodDialog } from './partials/EditPeriodDialog';

export function ManagePeriodsContainer() {
  const { organizationId } = useParams();
  const t = useFormatMessage();

  const {
    data: organizationInfo,
    isLoading,
    refetch: refetchOrganizationInfo,
  } = useAdminOrganizationById(organizationId);
  const { mutateAsync: deletePeriod } = useAdminDeletePeriod();
  const [dialog, setDialog] = useState();

  return (
    <>
      <TableSkeleton loading={isLoading}>
        <ManagePeriods
          organizationInfo={organizationInfo}
          setDialog={setDialog}
        />
      </TableSkeleton>
      <EditPeriodDialog
        key={`editDialog-${dialog?.period?.id || 'new'}`}
        dismiss={(refetch = false) => {
          if (refetch) void refetchOrganizationInfo();
          setDialog();
        }}
        existingPeriods={
          organizationInfo?.periods.filter(
            (period) => period.id !== dialog?.period?.id,
          ) || []
        }
        isOpen={dialog?.type === 'edit'}
        organizationId={organizationId}
        selectedPeriod={dialog?.period}
      />
      <ConfirmationDialog
        content={t('periods.delete-confirmation')}
        isOpen={dialog?.type === 'delete'}
        onConfirm={() => {
          deletePeriod(dialog.period.id).then(() => refetchOrganizationInfo());
          setDialog();
        }}
        onDismiss={() => setDialog()}
        testInfo={{
          modal: dataTestIds.component.confirmationDialog.id,
          dismiss: dataTestIds.component.confirmationDialog.button.cancel,
          confirm: dataTestIds.component.confirmationDialog.button.submit,
        }}
      />
    </>
  );
}
