import { gql } from 'graphql-request';

export const SCHOOLADMIN_ADD_PROGRAMS_TO_TEACHER = gql`
  mutation schooladminAddProgramsToTeacher($input: LinkProgramsToUserInput!) {
    schooladminAddProgramsToTeacher(input: $input) {
      statusCode
      id
      message
    }
  }
`;
