import { useLocation, useParams as RRDUseParams } from 'react-router-dom';

export default function useParams() {
  return {
    ...RRDUseParams(),
    ...Object.fromEntries([
      ...new URLSearchParams(useLocation().search).entries(),
    ]),
  };
}
