import { reportOptions } from 'utils/constants/reportOptions';
import { generateStudentResultPagePath } from 'utils/generateUrlPaths';
import { useParams } from 'react-router-dom';
import { Scores } from './Scores';
import { Questions } from './Questions';

export function ResultForStudentForChapterItem({
  activeScoreMode,
  bulletColour,
  hasMultipleScores = false,
  numberOfCalendarItems = 1,
  report,
  showDateInLabel = false,
}) {
  const showScoreMode = activeScoreMode === reportOptions.SCORE;
  const { calendarItem, userId } = report;
  const { id: calendarItemId, sessionId } = calendarItem;
  const { programId } = useParams();

  // we don't want a link here as if it has multiplescores the wrapping element is a button
  if (hasMultipleScores) {
    return (
      <>
        {showScoreMode && (
          <Scores
            bulletColour={bulletColour}
            numberOfCalendarItems={numberOfCalendarItems}
            report={report}
            showDateInTooltip
          />
        )}
        {!showScoreMode && (
          <Questions
            bulletColour={bulletColour}
            numberOfCalendarItems={numberOfCalendarItems}
            report={report}
            showDateInTooltip
          />
        )}
      </>
    );
  }

  return (
    <a
      href={generateStudentResultPagePath(
        calendarItemId,
        programId,
        sessionId,
        userId,
      )}
    >
      {showScoreMode && (
        <Scores
          bulletColour={bulletColour}
          report={report}
          showDateInLabel={showDateInLabel}
        />
      )}
      {!showScoreMode && (
        <Questions
          bulletColour={bulletColour}
          report={report}
          showDateInLabel={showDateInLabel}
        />
      )}
    </a>
  );
}
