import { externalFr } from './external';
import { french } from './internal.fr';
import { frenchAvatar } from './avatar.fr';

export default {
  // Internal
  ...french,
  // External
  ...externalFr,
  // Avatar from bigheads
  ...frenchAvatar,
};
