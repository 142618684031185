import TeamsAuthenticationDialog from 'components/TeamsAuthenticationDialog';
import { createContext, useContext, useMemo, useState } from 'react';
import { UserContext } from './UserProvider';

export const TeamsAuthenticationContext = createContext({});

export const useTeamsAuthenticationContext = () => {
  const context = useContext(TeamsAuthenticationContext);

  if (!context) {
    throw new Error(
      'useTeamsAuthenticationContext must be used within a TeamsAuthenticationProvider',
    );
  }

  return context;
};

export default function TeamsAuthenticationProvider({ children }) {
  const { isTeamsUser, organization } = useContext(UserContext);

  const { isTeams } = organization;

  const [showTeamsAuthenticationDialog, setShowTeamsAuthenticationDialog] =
    useState(false);

  const context = useMemo(
    () => ({
      setShowTeamsAuthenticationDialog,
    }),
    [],
  );

  return (
    <TeamsAuthenticationContext.Provider value={context}>
      {Boolean(isTeamsUser || isTeams) && (
        <TeamsAuthenticationDialog
          hasCloseButton
          isOpen={showTeamsAuthenticationDialog}
          onDismiss={() => setShowTeamsAuthenticationDialog(false)}
        />
      )}
      {children}
    </TeamsAuthenticationContext.Provider>
  );
}
