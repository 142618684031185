import { Controller } from 'react-hook-form';
import { CheckBox } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function UpdateChapterItem({
  boxUpdateChapterItem,
  formOption,
  setBoxUpdateChapterItem,
}) {
  const { control, setValue } = formOption;
  const t = useFormatMessage();

  return (
    <Controller
      control={control}
      name="updateChapterItem"
      render={() => (
        <CheckBox
          className="mt-5"
          classNameLabel="mt-5"
          label={t('calendar.modal.editLessonContent')}
          onChange={(e) => {
            setValue('program', undefined);
            setValue('chapter', undefined);
            setValue('chapterItem', undefined);
            setBoxUpdateChapterItem(e);
          }}
          value={boxUpdateChapterItem}
        />
      )}
      type="checkbox"
    />
  );
}
