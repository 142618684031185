import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function DeleteUserDialog({
  deleteUser,
  disableUser,
  isLoadingDeleteUser,
  isOpen,
  setOpenModalDeleteUser,
  setSelectedUser,
}) {
  const t = useFormatMessage();
  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onDismiss = useCallback(() => {
    setOpenModalDeleteUser(false);
    setSelectedUser(null);
    reset();
  }, [reset, setOpenModalDeleteUser, setSelectedUser]);

  const onSubmit = useCallback(() => {
    deleteUser(onDismiss);
    reset();
  }, [deleteUser, reset, onDismiss]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('delete-user-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <p>{t('users-overview.confirmation-text')}</p>
            <p>{t('users-overview.use-disable-text')}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            className="mr-auto"
            disabled={isLoadingDeleteUser}
            onClick={onDismiss}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={Object.keys(errors).length > 0 || isLoadingDeleteUser}
            loading={isLoadingDeleteUser}
            secondary
            type="submit"
          >
            {t('delete-program-dialog.confirm')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={isLoadingDeleteUser}
            loading={isLoadingDeleteUser}
            onClick={() => {
              disableUser();
              onDismiss();
            }}
          >
            {t('users-overview.disable')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
