import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { UPDATE_NOTIFICATION_STATUS } from 'api/plannerService/common/mutations/updateNotificationStatus';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUpdateNotificationStatus() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useMutation(
    ({ canceled = false, id, snoozeUntil }) =>
      plannerServiceQueryClient.request(UPDATE_NOTIFICATION_STATUS, {
        id,
        canceled,
        snoozeUntil,
      }),
    {
      mutationKey: 'updateNotificationStatus',
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.NOTIFICATION.plural'),
        });
      },
      // no onsuccess needed => already feedback when you click on the snooze/open button and the toast disappears
    },
  );

  return {
    ...response,
    data: response?.data?.updateNotificationStatus,
  };
}
