import { useContext } from 'react';
import { Link as LinkTo } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { SlideViewerContext } from '../SlideViewerContextProvider';
// eslint-disable-next-line import/no-cycle
import ModalButton from './ModalButton';
import { MultiScreenModeContext } from '../MultiScreenMode/MultiScreenModeContextProvider';

export const SLIDEVIEWER_PREFIX = 'SlideViewer__';
export const STYLED_BUTTON_CLASS = `${SLIDEVIEWER_PREFIX}StyledButton`;

export const allLinkStyles = {
  EXERCISE: 'ExerciseLink',
  MOVIE: 'VideoLink',
  EXTERNAL: 'ExternalLink',
  OTHER: 'OtherLink',
  DOWNLOAD: 'DownloadLink',
  SLIDE: 'SlideLink',
  STEAMS: 'SteamsQR',
};

export const EXERCISE_LINKS = {
  OLD: 'EXERCISE_SCRATCH',
  NEW: 'SlideViewer__ExerciseLink',
};

export const LESSON_ID = 'LESSON_ID';

const forceDownload = (blob, filename) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const downloadResource = (url, filename) => {
  let modifiedFilename = filename.slice();

  if (!filename) {
    modifiedFilename = url.split('\\').pop().split('/').pop();
  } else if (filename.split('.').length < 2) {
    modifiedFilename = `${filename}.${url.split('.').pop()}`;
  }

  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, modifiedFilename);
    });
};

export default function Link({
  children,
  className,
  'data-href': url,
  'data-studio-link-id': dataStudioLinkId,
  'data-target-slide-id': slideId,
  'data-version-id': dataVersionId,
  'download-file-name': downloadFileName,
  'download-url': downloadUrl,
  href,
  target,
  versionid: versionId,
  ...rest
}) {
  const {
    generateCurrentLessonPath: slideviewerGenerateCurrentLessonPath,
    generateScratchExercisePath: slideviewerGenerateScratchExercisePath,
    groupId,
    lesson,
  } = useContext(SlideViewerContext);

  const {
    generateCurrentLessonPath: multiScreenGenerateCurrentLessonPath,
    generateScratchExercisePath: multiScreenGenerateScratchExercisePath,
  } = useContext(MultiScreenModeContext);

  const isExercise =
    className?.includes(EXERCISE_LINKS.OLD) ||
    className?.includes(EXERCISE_LINKS.NEW);

  const isModal = className?.includes('SlideViewer__ModalLink');

  if (isModal) {
    return (
      <ModalButton
        className={className}
        label={rest['data-label']}
        modalcontent={rest['data-modal-content']}
        modalsize={rest['data-modal-size']}
        modaltitle={rest['data-modal-title']}
      >
        {children}
      </ModalButton>
    );
  }

  if (isExercise) {
    const generateScratchExercisePath =
      slideviewerGenerateScratchExercisePath ??
      multiScreenGenerateScratchExercisePath;
    return (
      <a
        className={`${STYLED_BUTTON_CLASS} ${EXERCISE_LINKS.NEW}`}
        // there might be a possibility where the exercises contain other hrefs
        // to external exercises for example. If this is the case, just pass href
        href={
          !!dataStudioLinkId &&
          !!(versionId || dataVersionId) &&
          generateScratchExercisePath
            ? generateScratchExercisePath({
                versionId: versionId || dataVersionId,
                lessonContentId: dataStudioLinkId,
              })
            : href || url
        }
        rel="noreferrer noopener"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  const isQR = className?.includes('SlideViewer__SteamsQR');

  if (isQR) {
    return <QRCode value={href + (groupId ? `?groupId=${groupId}` : '')} />;
  }

  const generateCurrentLessonPath =
    slideviewerGenerateCurrentLessonPath ??
    multiScreenGenerateCurrentLessonPath;

  if (slideId && target !== '_blank') {
    const url = generateCurrentLessonPath
      ? generateCurrentLessonPath({ slideId })
      : '#';

    return (
      <LinkTo className={className} to={url}>
        {children}
      </LinkTo>
    );
  }

  if (downloadUrl) {
    return (
      <button
        className={className}
        onClick={() => downloadResource(downloadUrl, downloadFileName)}
        type="button"
      >
        {children}
      </button>
    );
  }

  let modifiedHref = href?.slice();
  if (modifiedHref?.includes(LESSON_ID)) {
    modifiedHref = modifiedHref.replace(LESSON_ID, lesson?.id);
  }

  if (slideId)
    modifiedHref = generateCurrentLessonPath
      ? generateCurrentLessonPath({ slideId })
      : '#';

  return (
    <a
      className={className}
      href={modifiedHref || url}
      rel="noreferrer noopener"
      target={target}
    >
      {children}
    </a>
  );
}
