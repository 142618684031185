import { dataTestIds } from '../utils/constants/dataTestIds';

export default function StatsContent({ logo, name, total }) {
  return (
    <>
      <span className="bg-accent-500 rounded-md p-2 mr-2 flex items-center">
        {logo}
      </span>
      <span>
        <span
          className="text-left text-xs font-medium text-gray-500 truncate block"
          data-test={dataTestIds.component.stats.name}
        >
          {name}
        </span>
        <span
          className="text-left font-semibold text-gray-900 block"
          data-test={dataTestIds.component.stats.amount}
        >
          {total}
        </span>
      </span>
    </>
  );
}
