import { gql } from 'graphql-request';

export const NOTIFICATIONS = gql`
  query notifications(
    $showAll: Boolean
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    notifications(
      showAll: $showAll
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        titleNL
        titleEN
        bodyNL
        bodyEN
        actionUrl
        canceled
        expireDate
      }
    }
  }
`;
