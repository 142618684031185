import {
  Badge,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { ImportTaskAmount } from 'pages/ManageSchoolAdmin/TeamsSync/partials/ImportTaskAmount';
import { ImportTaskMessage } from 'pages/ManageSchoolAdmin/TeamsSync/partials/ImportTaskMessage';
import { dateFormat } from 'utils/timeHelpers';
import { importStatus } from 'utils/constants/importStatus';
import TableSkeleton from '../SkeletonLoader/TableSkeleton';
import { EmptyState } from '../EmptyState';

export function SchooladminTaskInfoTable({
  data,
  isExcelImport = false,
  isLoading,
  setDialogContent,
  setIsDialogOpen,
}) {
  const t = useFormatMessage();

  if (!data?.content?.length > 0) {
    return <EmptyState textKey="synchronize.notSyncedYet" />;
  }

  return (
    <TableSkeleton loading={isLoading}>
      <ResponsiveTable
        headers={[
          {
            key: 'startedAt',
            label: t('global.table-header.started_on'),
            orderable: true,
          },
          {
            key: 'status',
            label: t('global.table-header.status'),
            orderable: false,
          },
          {
            key: 'message',
            label: t(
              `global.table-header.imported.${
                isExcelImport ? 'users' : 'classes'
              }`,
            ),
            orderable: false,
          },
          {
            key: 'messages',
            label: t('global.table-header.errorMessages'),
            orderable: false,
          },
        ]}
        mobileBreakpoint={768}
        mobileHeaderWidth={160}
      >
        {data?.content?.map((importTask) => (
          <ResponsiveTableRow key={importTask.taskId} data={importTask}>
            <ResponsiveTableCol>
              {dateFormat(importTask.startedAt, true)}
            </ResponsiveTableCol>
            <ResponsiveTableCol>
              <Badge
                danger={importTask.status === importStatus.FAILED}
                info={importTask.status === importStatus.IN_PROGRESS}
                success={importTask.status === importStatus.COMPLETED}
              >
                {t(`global.taskInfoStatus.${importTask.status.toLowerCase()}`)}
              </Badge>
            </ResponsiveTableCol>
            <ResponsiveTableCol>
              <ImportTaskAmount
                importTask={importTask}
                isExcelImport={isExcelImport}
              />
            </ResponsiveTableCol>
            <ResponsiveTableCol className="py-2 pr-4" wrapRow>
              <ImportTaskMessage
                importTask={importTask}
                setDialogContent={setDialogContent}
                setIsDialogOpen={setIsDialogOpen}
              />
            </ResponsiveTableCol>
          </ResponsiveTableRow>
        ))}
      </ResponsiveTable>
    </TableSkeleton>
  );
}
