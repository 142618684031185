import { gql } from 'graphql-request';

export const SCHOOLADMIN_SYNC_TEAMS_CLASS_GROUPS = gql`
  mutation schooladminSyncTeamsClassGroups(
    $teamsClassGroups: [SyncTeamsClassGroupInput]!
  ) {
    schooladminSyncTeamsClassGroups(teamsClassGroups: $teamsClassGroups) {
      statusCode
      id
      message
    }
  }
`;
