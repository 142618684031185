import { gql } from 'graphql-request';

export const SCHOOLADMIN_RESET_PASSWORD = gql`
  mutation schooladminResetPassword($id: String!, $newPassword: String!) {
    schooladminResetPassword(id: $id, newPassword: $newPassword) {
      statusCode
      id
      message
    }
  }
`;
