import {
  ActionStyledAsButton,
  DialogActions,
  DialogContent,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

import { ImportTaskError } from './ImportTaskError';
import { getIsSingleErrorMessage } from '../helpers/getIsSingleErrorMessage';

export function ImportTaskMessage({
  importTask,
  setDialogContent,
  setIsDialogOpen,
}) {
  const t = useFormatMessage();

  if (!importTask.messages?.length) {
    return null;
  }

  const isSingleErrorMessage = getIsSingleErrorMessage(importTask.messages);

  if (isSingleErrorMessage) {
    return (
      <p className="max-w-[70ch] text-sm text-gray-700">
        {t('global.error.message')}
      </p>
    );
  }

  const dialogContent = (
    <>
      <DialogContent>
        <ImportTaskError importTask={importTask} />
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton
          onClick={() => setIsDialogOpen(false)}
          test={dataTestIds.modal.taskErrors.close}
        >
          {t('global.close')}
        </ActionStyledAsButton>
      </DialogActions>
    </>
  );

  return (
    <ActionStyledAsButton
      className="float-right"
      onClick={() => {
        setDialogContent(dialogContent);
        setIsDialogOpen(true);
      }}
    >
      {t('global.showErrors')}
    </ActionStyledAsButton>
  );
}
