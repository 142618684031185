import { gql } from 'graphql-request';

export const SCHOOLADMIN_USERS_BY_CLASS_GROUP_ID = gql`
  query schooladminUsersByClassGroupId(
    $classGroupId: Int!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    schooladminUsersByClassGroupId(
      classGroupId: $classGroupId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        userName
        firstName
        lastName
        avatar
        enabled
        roles
      }
    }
  }
`;
