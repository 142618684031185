import { Dropdown } from '@ftrprf/tailwind-components';
import VIEWMODES from 'utils/constants/viewModes';
import useFormatMessage from 'hooks/useFormatMessage';

export function ViewModePicker({
  activeViewMode,
  availableViewModes = [VIEWMODES.CLASSICAL, VIEWMODES.SELFSTUDY],
  setActiveViewMode,
}) {
  const t = useFormatMessage();
  return (
    <Dropdown
      aria-label={t('global.viewMode')}
      inputId="viewModePicker"
      isMulti={false}
      onChange={(e) => {
        setActiveViewMode(e);
      }}
      options={availableViewModes.map((viewMode) => ({
        label: t(`view-modes.${viewMode.toLowerCase()}`),
        value: viewMode,
      }))}
      value={activeViewMode}
    />
  );
}
