import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_DELETE_USER } from 'api/plannerService/admin/mutations/adminDeleteUser';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminDeleteUser() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    (id) =>
      plannerServiceQueryClient.request(ADMIN_DELETE_USER, {
        id,
      }),
    {
      mutationKey: 'adminDeleteUser',
      onSuccess: () => {
        toast(TOASTTYPES.DELETESUCCESS, {
          str: t('global.USER.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.DELETEERROR, {
          str: t('global.USER.singular'),
        });
      },
    },
  );
}
