import { gql } from 'graphql-request';

export const SCHOOLADMIN_CREATE_STUDENT = gql`
  mutation schooladminCreateStudent($input: CreateStudentInput!) {
    schooladminCreateStudent(input: $input) {
      id
      userName
      firstName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
    }
  }
`;
