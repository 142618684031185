import useFormatMessage from 'hooks/useFormatMessage';

export default function usePasswordStrengthWords() {
  const t = useFormatMessage();
  return [
    t('password-strength.very_weak'),
    t('password-strength.weak'),
    t('password-strength.almost_there'),
    t('password-strength.good'),
    t('password-strength.excellent'),
  ];
}
