export default function showDefaultErrorNotification(
  actualBEError,
  customError,
  addNotification,
) {
  switch (process.env.NODE_ENV) {
    case 'development':
      return addNotification({
        type: 'error',
        content: actualBEError,
      });
    case 'production':
      return addNotification({
        type: 'error',
        content: customError,
      });
    default:
      return addNotification({
        type: 'error',
        content: customError,
      });
  }
}
