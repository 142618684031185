import c from '../../utils/c';
import { statusBulletStates } from '../../utils/constants/statusBulletStates';

export function StatusBullet({ status }) {
  return (
    <span
      className={c(
        'flex-none rounded-full p-1 w-4 h-4 max-w-4 self-center inline-block',
        status === statusBulletStates.FAILED && 'text-rose-500 bg-rose-200',
        status === statusBulletStates.IN_PROGRESS &&
          'text-orange-500 bg-orange-200',
        (status === statusBulletStates.NOT_STARTED || !status) &&
          'text-gray-500 bg-gray-300',
        status === statusBulletStates.SUCCESS && 'text-green-500 bg-green-200',
      )}
    >
      <span className="h-2 w-2 rounded-full bg-current flex" />
    </span>
  );
}
