import useFormatMessage from '../../../hooks/useFormatMessage';

import { Dialog, DialogContent, DialogHeader } from '../../dialogs';
import SlideViewerTextSlide from '../../slideviewer/SlideViewerTextSlide';

export function HintDialog({ content, isOpen, onDismiss, title }) {
  const t = useFormatMessage();

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader hasCloseButton onDismiss={onDismiss}>
        {title || t('styled-slide-viewer.hint-dialog.title')}
      </DialogHeader>

      <DialogContent>
        <SlideViewerTextSlide value={content} />
      </DialogContent>
    </Dialog>
  );
}

HintDialog.defaultProps = {
  content: '',
  onDismiss: () => {},
};
