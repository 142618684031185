import { forwardRef } from 'react';
import c from 'utils/c';

export const CalendarButtonIcon = forwardRef(
  ({ className, icon = null, onClick, value }, ref) => (
    <div
      ref={ref}
      className={c(
        'inline-flex hover:border-accent-900 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-accent-500',
        className,
      )}
      onClick={onClick}
    >
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
        {icon}
      </div>
      <div className="focus:ring-accent-500 focus:border-accent-500 pl-5 sm:text-sm border-gray-300 rounded-md">
        {value}
      </div>
    </div>
  ),
);
