import { Controller } from 'react-hook-form';
import { CheckBox } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function CloseAfterEndTime({ control }) {
  const t = useFormatMessage();

  return (
    <div className="mb-4">
      <Controller
        control={control}
        name="closeAfterEndTime"
        render={({ field: { onChange, value } }) => (
          <CheckBox
            checked={value}
            label={t('calendar.modal.closeAfterEndTime')}
            onChange={onChange}
          />
        )}
        type="checkbox"
      />
    </div>
  );
}
