import { MultiScreenModeSlide } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export function NextSlide({ currentSlideIndex, lessonLength, nextSlide }) {
  const t = useFormatMessage();

  if (currentSlideIndex < lessonLength - 1) {
    return (
      <MultiScreenModeSlide
        id={dataTestIds.multiScreen.nextSlide}
        slide={nextSlide}
        test={dataTestIds.multiScreen.nextSlide}
      />
    );
  }

  return <p>{t('multi-screen-mode.end')}</p>;
}
