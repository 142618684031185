import { gql } from 'graphql-request';

export const CREATE_SCRATCH_JUDGE = gql`
  mutation CreateScratchJudge(
    $exerciseVersionId: Int!
    $testPlanId: Int!
    $exerciseId: Int!
    $file: Upload
    $language: String
  ) {
    createScratchJudge(
      input: {
        exerciseVersionId: $exerciseVersionId
        testPlanId: $testPlanId
        language: $language
        exerciseId: $exerciseId
      }
      file: $file
    ) {
      id
      message
    }
  }
`;
