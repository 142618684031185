import useFormatMessage from 'hooks/useFormatMessage';
import { ErrorAbducted } from './ErrorAbducted';

export function Error404() {
  const t = useFormatMessage();
  const messages = [t('errors.404.message.main'), t('errors.404.message.sub')];

  return (
    <ErrorAbducted
      message={messages}
      statusCode="404"
      titleKey="errors.404.header"
    />
  );
}
