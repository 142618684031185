import { generatePath } from 'react-router-dom';

import { getItemIndex } from 'components/Pager/getItemIndex';
import { Pager } from 'components/Pager/Pager';

import URLS from 'utils/constants/urls';

export function ClassPager({
  classGroups,
  currentClass,
  setClassId = () => {},
}) {
  return (
    <div className="w-full flex justify-center mt-2 items-center">
      <Pager
        currentItem={currentClass}
        getItemIndex={getItemIndex}
        getNewLocation={(value) =>
          generatePath(URLS.MANAGE_CLASS_DETAILS, {
            classId: value?.id,
          })
        }
        items={classGroups}
        nameAttribute="name"
        setItemId={setClassId}
      />
    </div>
  );
}
