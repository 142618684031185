import {
  Content as ContentContainer,
  PageHeader,
} from '@ftrprf/tailwind-components';

import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';

import '../learn.css';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';
import ChapterItemOverview from './ChapterItemOverview';
import { ProgramEmptyState } from './partials/ProgramEmptyState';

export default function StudentChapterItemOverview({
  breadcrumbsLinks,
  currentChapter,
  isLoading,
  pageTitle,
  sortedPrograms,
}) {
  const chapterItems = currentChapter?.items?.sort(sortByWeightThenByName);

  return (
    <>
      <PageHeader
        breadcrumbs={breadcrumbsLinks}
        buttonOnTitleRow
        title={pageTitle}
      />
      <ContentContainer hasPaddingBottom>
        <div className="w-full mx-auto">
          <ListSkeleton loading={isLoading}>
            <div className="flex flex-col w-full">
              {sortedPrograms?.length > 0 && (
                <div>
                  {currentChapter && (
                    <div data-test="lessonOverview">
                      <ChapterItemOverview
                        chapterItems={chapterItems}
                        pageTitle={pageTitle}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </ListSkeleton>
          {sortedPrograms?.length === 0 && <ProgramEmptyState />}
        </div>
      </ContentContainer>
    </>
  );
}
