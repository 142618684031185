import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { STUDENT_PROGRAMS } from 'api/plannerService/student/queries/studentPrograms';
import { TEACHER_PROGRAMS } from 'api/plannerService/teacher/queries/teacherPrograms';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function usePrograms() {
  const toast = useToast();
  const t = useFormatMessage();
  const { isStudent } = useContext(UserContext);

  const hookObject = {
    id: isStudent ? `studentPrograms` : 'teacherPrograms',
    query: isStudent ? STUDENT_PROGRAMS : TEACHER_PROGRAMS,
  };

  const info = useQuery(
    hookObject.id,
    () => plannerServiceQueryClient.request(hookObject.query, {}),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.plural'),
        });
      },
    },
  );

  return {
    ...info,
    data: info?.data?.[hookObject.id],
  };
}
