import { useEffect, useState } from 'react';
import Unplugged from 'assets/vectors/error-pages/unplugged.svg';
import { EmptyState } from 'components/EmptyState';
import useSchooladminTeamsClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminTeamsClassGroups';
import useFormatMessage from 'hooks/useFormatMessage';
import { useTeamsAuthenticationContext } from 'providers/TeamsLoginProvider';
import { errorKeys } from 'utils/constants/errorKeys';
import { dataTestIds } from 'utils/dataTestIds';
import { SearchBar } from '@ftrprf/tailwind-components';
import { sortBy } from 'lodash-es';
import { SyncClassesTable } from './SyncClassesTable';

export function SynchronizeTeams() {
  const { setShowTeamsAuthenticationDialog } = useTeamsAuthenticationContext();
  const t = useFormatMessage();

  const {
    data: originalClassGroups,
    error,
    isLoading,
    refetch,
  } = useSchooladminTeamsClassGroups();

  const hasNoToken =
    error?.message.includes(errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND) ||
    error?.message.includes(errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND_TEMP);

  const [classGroups, setClassGroups] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredClassGroups, setFilteredClassGroups] = useState([]);

  useEffect(() => {
    if (Boolean(originalClassGroups)) {
      setClassGroups(
        sortBy(originalClassGroups, (classgroup) => classgroup.name),
      );
    }
  }, [originalClassGroups]);

  useEffect(() => {
    setFilteredClassGroups(
      classGroups.filter((classGroup) =>
        classGroup.name.toLowerCase().includes(filter.toLowerCase()),
      ),
    );
  }, [classGroups, filter]);

  if (hasNoToken) {
    setShowTeamsAuthenticationDialog(true);

    return (
      <EmptyState image={Unplugged} textKey="teams.error.synchronize.title" />
    );
  }

  return (
    <div>
      <div className="md:w-1/2 mb-4">
        <SearchBar
          id="filterClassgroupByName"
          onChange={setFilter}
          placeholder={t('global.search.by.name')}
          test={dataTestIds.page.manage.teams.inputs.filterClassgroup}
        />
      </div>
      <SyncClassesTable
        classGroups={filteredClassGroups}
        isLoading={isLoading}
        refetch={refetch}
        setClassGroups={setFilteredClassGroups}
      />
    </div>
  );
}
