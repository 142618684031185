export default {
  'styled-slide-viewer.open_the_sidebar': 'Ouvrir la barre latérale',

  'styled-slide-viewer.sidebar.questions_not_saved':
    'Les réponses aux questions ne seront pas enregistrées',

  'styled-slide-viewer.previous': 'Précédent',
  'styled-slide-viewer.next': 'Suivante',

  'styled-slide-viewer.teacher_manual': "Manuel de l'enseignant",
  'styled-slide-viewer.manual': 'Manuel',
  'styled-slide-viewer.teacher_manual_empty':
    "Aucune explication supplémentaire n'est nécessaire avec cette diapositive",

  'styled-slide-viewer.table_of_contents': 'Table des matières',

  'styled-slide-viewer.steams': 'Steams',
  'styled-slide-viewer.settings': 'Réglages',
  'styled-slide-viewer.settings.feedback': "Retour d'information",
  'styled-slide-viewer.settings.feedback_explanation':
    'Donnez votre avis sur cette diapositive',

  'styled-slide-viewer.settings.zoom': 'Zoom',
  'styled-slide-viewer.settings.zoom_explanation':
    'Utilisez + et - pour zoomer et dézoomer la diapositive',

  'styled-slide-viewer.userGroupInfo': 'Informations sur le groupe',

  'styled-slide-viewer.toggle.sidebar': 'Basculer la barre latérale`',

  'styled-slide-viewer.view-modes.projection': 'Projection',
  'styled-slide-viewer.view-modes.selfstudy': 'Auto-apprentissage',
  'styled-slide-viewer.view-modes.classical': 'Classique',

  'styled-slide-viewer.submit': 'Soumettre',
  'styled-slide-viewer.submitted': 'Soumis',
  'styled-slide-viewer.save': 'Sauvegarder',
  'styled-slide-viewer.saved': 'Stockée',
  'styled-slide-viewer.close': 'Fermer',
  'styled-slide-viewer.no_slides':
    "Il n'y a pas de diapositives dans cette leçon.",
  'styled-slide-viewer.not_available':
    "Cette diapositive n'est pas disponible pour ce mode de projection, ou elle n'existe pas.",
  'styled-slide-viewer.not_available_editor':
    "Cette diapositive n'existe pas pour cette leçon.",
  'styled-slide-viewer.empty_title': 'pas de titre',
  'styled-slide-viewer.submit_answers': 'Soumettre les réponses',
  'styled-slide-viewer.answer_placeholder': 'Tapez votre réponse ici',
  'student-answers.correct_answer': 'La bonne réponse est:',
  'student-answers.no_answer': 'Réponse non précisée',

  'styled-slide-viewer.example_answer': 'Exemple de réponse',
  'styled-slide-viewer.hints': 'Astuces',
  'styled-slide-viewer.hint': 'Indice',
  'styled-slide-viewer.hint-dialog.title': 'Indice',

  'styled-slide-viewer.questions.not_supported_type':
    "La question n'a pas pu être affichée: le type de la question n'est pas pris en charge.",
  'styled-slide-viewer.multiplechoiceanswercard.explanation_less':
    'Montrer moins',
  'styled-slide-viewer.multiplechoiceanswercard.explanation_more':
    'Montrer plus',
  'styled-slide-viewer.feedback.title':
    'Nous aimerions entendre votre commentaire',
  'styled-slide-viewer.feedback.target':
    'Sur quel type de contenu portez-vous vos commentaires?',
  'styled-slide-viewer.feedback.target.error':
    'Veuillez choisir une valeur valide',
  'styled-slide-viewer.feedback.target.placeholder': 'Veuillez choisir un type',
  'styled-slide-viewer.feedback.feedback': 'Quel est votre commentaire?',
  'styled-slide-viewer.feedback.notify':
    'Tenez-moi au courant de mes commentaires',
  'styled-slide-viewer.feedback.file.label':
    'Souhaitez-vous ajouter un fichier pour expliquer votre commentaires ?',
  'styled-slide-viewer.feedback.file.drag':
    'Faites glisser un fichier ou recherchez vos fichiers',
  'styled-slide-viewer.feedback.type.SLIDE': 'Diapositive',
  'styled-slide-viewer.feedback.type.TEACHER_MANUAL': "Manuel de l'enseignant",
  'styled-slide-viewer.feedback.type.EXERCISE': 'Exercise',
  'styled-slide-viewer.feedback.type.WORKING_SHEET': 'Feuille de travail',
  'styled-slide-viewer.feedback.type.LESSON_DOCUMENT': 'Document pédagogique',
  'styled-slide-viewer.feedback.email': 'E-mail',
  'styled-slide-viewer.feedback.email.validate-error':
    "Format d'email invalide",
  'styled-slide-viewer.feedback.email.required':
    'Un e-mail est requis car vous souhaitez rester informé',
  'styled-slide-viewer.confirmation.title':
    "N'oubliez pas de soumettre vos réponses !",
  'styled-slide-viewer.confirmation.content':
    'Si vous ne soumettez aucune réponse, vous ne recevrez aucun point.',
  'styled-slide-viewer.confirmation.confirm': 'Soumettre et continuer',
  'styled-slide-viewer.confirmation.cancel': 'Continuer sans envoyer',

  // question disclosure
  'question.show.answers': 'Show answers',
  'question.hide.answers': 'Hide answers',
};
