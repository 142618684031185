import { gql } from 'graphql-request';

export const SCHOOLADMIN_CREATE_TEACHER = gql`
  mutation schooladminCreateTeacher($input: CreateTeacherInput!) {
    schooladminCreateTeacher(input: $input) {
      id
      userName
      firstName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
      language
    }
  }
`;
