import { gql } from 'graphql-request';

export const TEACHER_ORGANIZATION_CONTENT = gql`
  query teacherOrganizationContent(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherOrganizationContent(
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        title
        studioId
        version
        status
        type
        blobUri
        imageUri
        organizationId
        tags
        copiedBy {
          firstName
          lastName
        }
        hasCalendarItems
      }
    }
  }
`;
