import { gql } from 'graphql-request';

export const SCHOOLADMIN_PROGRAMS = gql`
  query schooladminPrograms(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    schooladminPrograms(
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        name
        type
        weight
        color
        blobUri
        maxSeats
        totalUsed
      }
    }
  }
`;
