import { generatePath } from 'react-router-dom';

import {
  Badge,
  Content,
  Filter,
  PageHeader,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import { EmptyState } from 'components/EmptyState';

import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import URLS from 'utils/constants/urls';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { laptop } from 'utils/constants/mediaWidth';
import { dataTestIds } from 'utils/dataTestIds';
import useTitle from 'hooks/useTitle';
import { Error403 } from 'pages/Error/Error403';
import { AvatarWrapper } from 'components/AvatarWrapper';
import ProgramLoadingSkeleton from './partials/ProgramLoadingSkeleton';
import { ProgramPager } from './partials/ProgramPager';

export default function ManageProgramUsage({
  classIds,
  currentProgram,
  filter,
  isBeingSearched,
  isErrorProgram = false,
  isLoading,
  programs,
  setClassIds,
  setFullName,
  uniqueClassGroups,
  userCalendarItems,
}) {
  const { programId } = useParams();

  const t = useFormatMessage();
  const { name } = currentProgram ?? { name: '' };
  const title = `${t('global.usage')} ${name}`;

  const breadcrumbsLinks = [
    {
      href: generatePath(URLS.MANAGE_TAB, { tab: 'programs' }),
      name: t('global.PROGRAM.plural'),
    },
    {
      current: true,
      href: generatePath(URLS.MANAGE_PROGRAM_USAGE, { filter, programId }),
      name: title,
    },
  ];

  useTitle(title);

  if (isErrorProgram)
    return (
      <>
        <PageHeader breadcrumbs={breadcrumbsLinks} title={title} />
        <Error403 message={t('program-usage-overview.no-access')} />;
      </>
    );

  return (
    <ProgramLoadingSkeleton loading={isLoading}>
      <PageHeader breadcrumbs={breadcrumbsLinks} title={title}>
        {programs && currentProgram && (
          <ProgramPager currentProgram={currentProgram} programs={programs} />
        )}
      </PageHeader>
      <Content className="flex-col">
        {userCalendarItems?.length || isBeingSearched ? (
          <div className="w-full">
            <div className="flex">
              <SearchBar
                className="mb-4 mr-4 max-w-1/2"
                id={dataTestIds.page.manage.usage.filter.name}
                onChange={setFullName}
                placeholder={t('global.search.by.name')}
                test={dataTestIds.page.manage.usage.filter.name}
              />
              {Boolean(uniqueClassGroups.length) && (
                <Filter
                  activeFilters={classIds}
                  allowNoneSelected
                  displayOptionFn={(classGroup) => classGroup.name}
                  name={t('program-usage-overview.classes.filter')}
                  options={uniqueClassGroups}
                  setFilter={(value) => {
                    setClassIds(value);
                  }}
                  test={dataTestIds.page.manage.usage.filter.classId}
                />
              )}
            </div>
            <TableSkeleton loading={isLoading}>
              <ResponsiveTable
                emptyText={t('program-usage-overview.empty.search')}
                headers={[
                  {
                    key: 'name',
                    label: t('program-usage-overview.name'),
                    orderable: false,
                  },
                  {
                    key: 'teacher',
                    label: t('program-usage-overview.teacher'),
                    orderable: false,
                  },
                  {
                    key: 'chapter',
                    label: t('program-usage-overview.chapter'),
                    orderable: false,
                  },
                  {
                    key: 'start',
                    label: t('program-usage-overview.start'),
                    orderable: false,
                  },
                  {
                    key: 'classes',
                    label: t('program-usage-overview.classes'),
                    orderable: false,
                  },
                ]}
                mobileBreakpoint={laptop}
                mobileHeaderWidth={160}
                test={dataTestIds.page.manage.usage.table}
              >
                {userCalendarItems?.map((item) => {
                  const { calendarItem, teacher, user } = item;
                  const classesForUser = user.classGroups.map(
                    (classGroup) => classGroup?.id,
                  );

                  return (
                    <ResponsiveTableRow key={user.id}>
                      <ResponsiveTableCol>
                        <AvatarWrapper avatar={user.avatar} />
                        {user.fullName}
                      </ResponsiveTableCol>
                      <ResponsiveTableCol>
                        {teacher.fullName}
                      </ResponsiveTableCol>
                      <ResponsiveTableCol>
                        {calendarItem?.chapterItem
                          ? calendarItem?.chapterItem.name
                          : calendarItem?.lessonContentTitle || ''}
                      </ResponsiveTableCol>
                      <ResponsiveTableCol>
                        {calendarItem.startTime.replace('00:00:00', '')}
                      </ResponsiveTableCol>
                      <ResponsiveTableCol>
                        {calendarItem.classGroups.map((classGroup) => {
                          const isAClassGroupForUser = classesForUser.includes(
                            classGroup.id,
                          );

                          return isAClassGroupForUser ? (
                            <Badge
                              key={`${classGroup.id}${user.id}`}
                              className="mr-2"
                              success={isAClassGroupForUser}
                            >
                              {classGroup.name}
                            </Badge>
                          ) : null;
                        })}
                      </ResponsiveTableCol>
                    </ResponsiveTableRow>
                  );
                })}
              </ResponsiveTable>
            </TableSkeleton>
          </div>
        ) : (
          <EmptyState textKey="program-usage-overview.empty" />
        )}
      </Content>
    </ProgramLoadingSkeleton>
  );
}
