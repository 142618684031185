import { gql } from 'graphql-request';

export const ADMIN_PROGRAMS = gql`
  query adminPrograms($filter: [JSON], $page: Int!, $size: Int!, $sort: JSON) {
    adminPrograms(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        id
        name
        type
        document
        blobUri
        color
        weight
      }
    }
  }
`;
