import { useQuery } from 'react-query';

import { EXERCISES } from 'api/portfolioService/queries/exercises';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export const useExercises = (title, requestContinuation, size = 50) => {
  const response = useQuery(['exercises', title], () =>
    portfolioServiceQueryClient.request(EXERCISES, {
      title,
      requestContinuation,
      size,
    }),
  );

  return {
    ...response,
    data: response?.data?.exercises.content,
  };
};
