import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_UPDATE_CLASS_GROUP_SCHEDULED_SYNC } from 'api/plannerService/schooladmin/mutations/schooladminUpdateClassGroupsScheduledSync';

export function useSchooladminUpdateClassGroupScheduledSync() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ classGroupScheduledSyncInputs }) =>
      plannerServiceQueryClient.request(
        SCHOOLADMIN_UPDATE_CLASS_GROUP_SCHEDULED_SYNC,
        {
          classGroupScheduledSyncInputs,
        },
      ),
    {
      mutationKey: 'schooladminUpdateClassGroupsScheduledSync',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.CLASS.singular'),
        });
      },
    },
  );
}
