import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import intlFormat from 'date-fns/intlFormat';

import {
  Dialog,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useUpdateOwnUserUiSettings from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserUiSettings';
import useFormatMessage from 'hooks/useFormatMessage';

import Codi from './assets/codi.png';
import Screenshot from './assets/codi_screenshot.jpg';
import ScreenshotEN from './assets/codi_screenshot_en.jpg';

export function CodiIntroDialog({ hasCodi }) {
  const t = useFormatMessage();
  const { locale: currentLocale } = useIntl();

  const [codiDialogHasBeenClosed, setCodiDialogHasBeenClosed] = useState(false);
  const [codiChangeHasBeenSubmitted, setCodiChangeHasBeenSubmitted] =
    useState(false);
  const [isCodiDialogOpen, setIsCodiDialogOpen] = useState(false);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const { refetchSettings, settings } = useContext(UserContext);
  const { mutateAsync: updateUiSettings } = useUpdateOwnUserUiSettings();

  const onDialogClose = useCallback(() => {
    setIsCodiDialogOpen(false);
    setCodiDialogHasBeenClosed(true);
  }, []);

  useEffect(() => {
    if (settings) {
      const { getToKnowCoachCodi } = settings;

      if (isFirstPageLoad) {
        if (hasCodi && !getToKnowCoachCodi) {
          setIsCodiDialogOpen(true);
        }

        setIsFirstPageLoad(false);
      }
    }

    // codiChangeHasBeenSubmitted is an endless loop guard
    if (hasCodi && codiDialogHasBeenClosed && !codiChangeHasBeenSubmitted) {
      const changedSettings = { ...settings };
      changedSettings.getToKnowCoachCodi = intlFormat(new Date(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });

      updateUiSettings({ uiSettings: changedSettings }).then(() => {
        setCodiChangeHasBeenSubmitted(true);
        refetchSettings();
      });
    }
  }, [
    codiChangeHasBeenSubmitted,
    codiDialogHasBeenClosed,
    hasCodi,
    isCodiDialogOpen,
    isFirstPageLoad,
    refetchSettings,
    settings,
    updateUiSettings,
  ]);

  // code to easily reset coach Codi locally whilst testing
  // useEffect(() => {
  //   if (settings) {
  //     const changedSettings = { ...settings };
  //     changedSettings.getToKnowCoachCodi = null;
  //     updateUiSettings({ uiSettings: changedSettings }).then(() => {
  //       refetchSettings();
  //     });
  //   }
  // }, [settings]);

  return (
    <Dialog
      className="w-3/4 overflow-hidden"
      data-test="codiDialog"
      isOpen={isCodiDialogOpen}
    >
      <DialogHeader
        className="uppercase text-center"
        hasCloseButton
        onDismiss={onDialogClose}
      >
        {t('assistant.getToKnow.title')}
      </DialogHeader>
      <DialogContent>
        <div className="flex items-end">
          <img alt="Coach Codi" className="h-60 -ml-14 -mb-7 z-20" src={Codi} />
          <div className="flex flex-col gap-8 -ml-[4.5rem] z-10">
            <p>{t('assistant.getToKnow.body')}</p>
            <img
              alt={t('assistant.getToKnow.screenshot')}
              className="max-w-[405px] m-auto mb-8"
              src={currentLocale === 'nl' ? Screenshot : ScreenshotEN}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
