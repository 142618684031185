import { externalEn } from './external';
import { english } from './internal.en';
import { englishAvatar } from './avatar.en';

export default {
  // Internal
  ...english,
  // External
  ...externalEn,
  // Avatar from bigHeads
  ...englishAvatar
};
