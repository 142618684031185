import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_UPDATE_STUDENT } from 'api/plannerService/schooladmin/mutations/schooladminUpdateStudent';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export const useSchooladminUpdateStudent = () => {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ id, input }) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_UPDATE_STUDENT, {
        id,
        input,
      }),
    {
      mutationKey: 'updateStudent',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.STUDENT.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.STUDENT.singular'),
        });
      },
    },
  );
};
