import {
  SlideViewerRightAction,
  SlideViewerViewModes,
} from '../../slideviewer/SlideViewer';
import ChevronRight from './ChevronRight';

import { SlideViewerCloseButton } from './CloseButon';

export default function SlideViewerRight({
  isCodefever = false,
  onClose = () => {},
  showViewModes = false,
}) {
  return (
    <div className="flex-shrink-0 flex flex-col items-end justify-between w-[33px] lg:w-[66px] h-full absolute right-0 z-10">
      <div className="h-1/2 flex self-end mr-6 mt-2 z-10">
        {showViewModes && (
          <SlideViewerViewModes className="flex flex-row items-start" />
        )}
        {!isCodefever && <SlideViewerCloseButton onClose={onClose} />}
      </div>
      <SlideViewerRightAction className="z-20 ml-auto lg:mr-2 p-2 duration-400">
        <ChevronRight className="w-4 lg:w-8 min-w-[3rem] min-h-[3rem]" />
      </SlideViewerRightAction>

      <div className="h-1/2" />
    </div>
  );
}
