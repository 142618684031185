import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '../Tooltip/Tooltip';
import Loader from './partials/Loader';
import ActionContent from './partials/ActionContent';
import { getClassNamesForAction } from './partials/getClassNamesForAction';
import { getIconClassNames } from './partials/getIconClassNames';
import { getValidButtonType } from './partials/getValidButtonType';

export function ActionStyledAsButton({
  badge = false,
  children = null,
  className = '',
  disabled = false,
  disabledClassName = 'opacity-40 cursor-not-allowed',
  download = false,
  form = '',
  href = '',
  iconAfter: IconAfter = null,
  iconBefore: IconBefore = null,
  iconMargin = 'mr-2',
  loaderClassName = 'w-5',
  loading = false,
  onClick = null,
  secondary = false,
  size = 'medium',
  target = '',
  test = '',
  title = '',
  to = '',
  type = 'button',
  variant = 'blue',
}) {
  const iconClassNames = getIconClassNames(size, secondary, variant);

  const loader = useCallback(
    () => (
      <Loader
        color={variant}
        loaderClassName={loaderClassName}
        secondary={secondary}
        size={size}
      />
    ),
    [loaderClassName, secondary, size, variant],
  );

  const actionContent = useMemo(
    () => (
      <ActionContent
        badge={badge}
        IconAfter={IconAfter}
        IconBefore={IconBefore}
        iconClassNames={iconClassNames}
        iconMargin={iconMargin}
        loader={loader}
        loading={loading}
        size={size}
      >
        {children}
      </ActionContent>
    ),
    [
      IconAfter,
      IconBefore,
      badge,
      children,
      iconClassNames,
      iconMargin,
      loader,
      loading,
      size,
    ],
  );

  const classNamesForAction = getClassNamesForAction(
    badge,
    className,
    disabled,
    disabledClassName,
    loading,
    secondary,
    size,
    variant,
  );

  let action;

  if (href) {
    action = (
      <a
        className={classNamesForAction}
        data-test={test}
        download={download}
        href={href}
        onClick={onClick}
        target={target}
      >
        {actionContent}
      </a>
    );
  } else if (to) {
    action = (
      <Link
        className={classNamesForAction}
        data-test={test}
        download={download}
        onClick={onClick}
        target={target}
        to={to}
      >
        {actionContent}
      </Link>
    );
  } else {
    const validButtonType = getValidButtonType(type);

    if (type === 'submit' && form) {
      // TODO: figure out where this is used, since a.t.m. it doesn't do anything and fix it
      action = (
        <button
          className={classNamesForAction}
          data-test={test}
          disabled={disabled}
          form={form}
          onClick={onClick}
          // eslint-disable-next-line react/button-has-type
          type={validButtonType}
        >
          {actionContent}
        </button>
      );
    }

    action = (
      <button
        className={classNamesForAction}
        data-test={test}
        disabled={disabled}
        onClick={onClick}
        // eslint-disable-next-line react/button-has-type
        type={validButtonType}
      >
        {actionContent}
      </button>
    );
  }

  if (title) {
    return <Tooltip label={title}>{action}</Tooltip>;
  }

  return action;
}
