import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

import { ADMIN_MOVE_LESSON } from 'api/plannerService/admin/mutations/adminMoveLesson';

export default function useAdminMoveLesson() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ studioId, toOrganizationId }) =>
      plannerServiceQueryClient.request(ADMIN_MOVE_LESSON, {
        studioId,
        toOrganizationId,
      }),
    {
      mutationKey: 'adminMoveLesson',
      onSuccess: (data) => {
        // Backand returns a 200 status in the headers all the time, even when it fails.
        // So we need to check the status in the response as well.
        if (data.adminMoveLesson.statusCode === 200) {
          toast(TOASTTYPES.MOVESUCCESS, {
            str: t('global.LESSON.singular'),
          });
        } else {
          toast(TOASTTYPES.MOVEERROR, {
            str: t('global.LESSON.singular'),
          });
        }
      },
      onError: () => {
        toast(TOASTTYPES.MOVEERROR, {
          str: t('global.LESSON.singular'),
        });
      },
    },
  );
}
