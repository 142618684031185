import { useContext } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline';

import {
  Actions,
  Badge,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import assessmentStatus from 'utils/constants/assessmentStatus';
import URLS from 'utils/constants/urls';
import { AvatarWrapper } from 'components/AvatarWrapper';

export default function StudentsOverview({
  canResetPassword,
  currentPage,
  isLoading,
  itemsPerPage,
  openResetModal,
  pages,
  setFilter,
  setItemsPerPage,
  setPage,
  setSort,
  setStudentData,
  sort,
  students,
  totalItems,
}) {
  const navigate = useNavigate();
  const t = useFormatMessage();
  const { classGroupId } = useParams();
  const { organization } = useContext(UserContext);

  const generateRouteToStudentDetail = ({ id: studentId }) =>
    generatePath(URLS.CLASSGROUP_STUDENT_PROFILE, {
      classGroupId,
      studentId,
    });

  const routeToStudentDetail = (student) => {
    navigate(generateRouteToStudentDetail(student));
  };

  const actions = (student) => {
    const possibleActions = [
      {
        href: generateRouteToStudentDetail(student),
        icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.STUDENT.view'),
      },
    ];

    if (canResetPassword) {
      possibleActions.push({
        icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.reset-password'),
        onClick: () => {
          setStudentData(student);
          openResetModal();
        },
      });
    }

    return possibleActions;
  };

  return (
    <div className="w-full mx-auto">
      <div className="w-full flex justify-between mb-4">
        <div className="flex w-1/2 items-center">
          <div className="w-5/6 mr-5">
            <SearchBar
              onChange={(value) => setFilter(value, 'fullName')}
              placeholder={t('global.search.by.name')}
            />
          </div>
        </div>
      </div>
      <TableSkeleton loading={isLoading}>
        <ResponsiveTable
          headers={[
            {
              key: 'name',
              label: t('students-overview.column.name'),
              orderable: false,
            },
            {
              key: 'username',
              label: t('students-overview.column.username'),
              orderable: false,
            },
            organization.steams && {
              key: 'profile test',
              label: t('students-overview.column.profiletest'),
              orderable: false,
            },
            {
              key: 'actions',
              label: t('global.actions'),
              orderable: false,
              className: 'max-w-fit sr-only',
            },
          ]}
          mobileBreakpoint={768}
          mobileHeaderWidth={160}
          onRowClick={routeToStudentDetail}
          onSortChange={(newSort) => setSort(newSort)}
          sort={sort}
        >
          {students?.map((student) => {
            const studentAssesmentStatus = student.assessment?.status;
            const { CREATED, FINISHED, NOT_SEND, STARTED } = assessmentStatus;

            return (
              <ResponsiveTableRow key={student.id} data={student}>
                <ResponsiveTableCol>
                  <AvatarWrapper avatar={student.avatar} />
                  {student.fullName}
                </ResponsiveTableCol>
                <ResponsiveTableCol>{student.userName}</ResponsiveTableCol>
                {organization.steams && (
                  <ResponsiveTableCol>
                    <Badge
                      dark={studentAssesmentStatus === CREATED}
                      info={studentAssesmentStatus === STARTED}
                      success={studentAssesmentStatus === FINISHED}
                      warning={studentAssesmentStatus === NOT_SEND}
                    >
                      {t(
                        `assessment.status.${(student.assessment?.status
                          ? student.assessment.status
                          : 'NOT_SEND'
                        ).toLowerCase()}`,
                      )}
                    </Badge>
                  </ResponsiveTableCol>
                )}
                <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                  <Actions items={actions(student)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            );
          })}
        </ResponsiveTable>

        {Boolean(students && students.length) && (
          <Pagination
            className="mt-12"
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={(page) => setPage(page)}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
            totalPages={pages}
          />
        )}
      </TableSkeleton>
    </div>
  );
}
