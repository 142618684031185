import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_USERS_ID_NAME } from 'api/plannerService/schooladmin/queries/schooladminUsersIdName';

export default function useSchoolAdminUsersIdName({
  filter,
  page,
  size,
  sort,
}) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminUsers_id_name', filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(SCHOOLADMIN_USERS_ID_NAME, {
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.USER.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminUsers,
  };
}
