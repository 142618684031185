/**
 * Divide the roles over the group members.
 * If there are too few roles for the amount of members then some members
 * will get no role.
 * If there are too many roles for the amount of members then some members
 * will get more than one role.
 */
export const setRolesForGroup = function (group, roles) {
  roles.forEach((role, index) => {
    let indexToInsert = index;
    const numberOfMembers = group.length;

    if (index >= numberOfMembers) {
      indexToInsert = index % numberOfMembers;
    }
    group[indexToInsert].roles.push(role);
  });

  return group;
};
