import { Controller } from 'react-hook-form';

import {
  FieldError,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { MiniCopyToClipboardButton } from 'components/Buttons/MiniCopyToClipboardButton';
import c from 'utils/c';

export function UserFormFields({
  canEdit,
  control,
  errors,
  showEndDate,
  user,
}) {
  const t = useFormatMessage();

  return (
    <>
      <div className="grid lg:grid-cols-2 gap-x-4">
        <InputGroup>
          <Label className="font-bold" htmlFor="userProfile-first_name">
            {t('profile.first_name')}
          </Label>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <Input
                className={c(!canEdit && 'cursor-not-allowed')}
                disabled={!canEdit}
                hasErrors={Boolean(errors.firstName)}
                id="userProfile-first_name"
                readOnly={!canEdit}
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('global.required-field'),
              },
            }}
          />
          {errors.firstName && (
            <FieldError>{errors.firstName.message}</FieldError>
          )}
        </InputGroup>

        <InputGroup>
          <Label className="font-bold" htmlFor="userProfile-last_name">
            {t('profile.last_name')}
          </Label>
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <Input
                className={c(!canEdit && 'cursor-not-allowed')}
                disabled={!canEdit}
                hasErrors={Boolean(errors.lastName)}
                id="userProfile-last_name"
                readOnly={!canEdit}
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('global.required-field'),
              },
            }}
          />
          {errors.lastName && (
            <FieldError>{errors.lastName.message}</FieldError>
          )}
        </InputGroup>
      </div>

      <div className="grid lg:grid-cols-2 gap-x-4">
        <InputGroup>
          <Label className="font-bold">{t('global.display_name')}</Label>
          <Controller
            control={control}
            name="displayName"
            render={({ field }) => (
              <Input
                className={c(!canEdit && 'cursor-not-allowed')}
                disabled={!canEdit}
                hasErrors={Boolean(errors.displayName)}
                id="userProfile-display_name"
                readOnly={!canEdit}
                {...field}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('global.required-field'),
              },
            }}
          />
          {errors.displayName && (
            <FieldError>{errors.displayName.message}</FieldError>
          )}
        </InputGroup>

        {user.isFtrprfUser && (
          <div>
            <div className="font-bold">
              {t('student-overview.column.username')}
            </div>
            <p className="pt-2">
              {user.userName}
              <MiniCopyToClipboardButton text={user.userName} />
            </p>
          </div>
        )}
      </div>

      <InputGroup>
        <Label className="font-bold">{t('profile.mail')}</Label>
        <Controller
          control={control}
          id=""
          name="email"
          render={({ field }) => (
            <Input
              className={c(!canEdit && 'cursor-not-allowed')}
              disabled={!canEdit}
              hasErrors={Boolean(errors.email)}
              readOnly={!canEdit}
              {...field}
              id="userProfile-email"
            />
          )}
          rules={{
            required: {
              value: !(user.isStudent || user.isTeacher),
              message: t('global.required-field'),
            },
          }}
        />
        {errors.email && <FieldError>{errors.email.message}</FieldError>}
      </InputGroup>

      {showEndDate && (
        <InputGroup>
          <Label className="font-bold">{t('profile.endDate')}</Label>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <Input
                {...field}
                hasErrors={Boolean(errors.endDate)}
                id="userProfile-endDate"
                type="date"
              />
            )}
          />
          {errors.endDate && <FieldError>{errors.endDate.message}</FieldError>}
        </InputGroup>
      )}
    </>
  );
}
