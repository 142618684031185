import { forwardRef, useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const PasswordInput = forwardRef(({ type, ...props }, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="relative w-full">
      <input
        type={passwordVisible ? 'text' : 'password'}
        {...props}
        ref={ref}
      />
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-end pointer-events-none">
        <button
          aria-controls="password"
          aria-expanded={passwordVisible ? 'true' : 'false'}
          className="text-gray-300 hover:text-gray-500 transition-colors duration-500 mr-2 pointer-events-auto"
          onClick={togglePasswordVisible}
          tabIndex="-1"
          type="button"
        >
          {passwordVisible ? (
            <FaRegEyeSlash className="w-5 h-5" />
          ) : (
            <FaRegEye className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
});

export default PasswordInput;
