import { Children, isValidElement } from 'react';
import { FaEllipsisV, FaPaperclip } from 'react-icons/fa';

import c from '../../utils/c';
import { Badge } from '../Badge';
import { dataTestIds } from '../../utils/constants/dataTestIds';

export function ContentCard({
  children = null,
  defaultImage: DefaultImage,
  disabled = false,
  extraActions = null,
  hasActions = false,
  hideOverflow = true,
  image = null,
  isFeatured = false,
  onDocument = null,
  statusLabel = '',
  subTitle = '',
  tags = null,
  title = '',
  titleLevel = 2,
  toggleContextMenu = null,
  version = '',
}) {
  // filters out falsy elements
  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child),
  );

  const count = validChildren.length;
  const HeadingTag = `h${titleLevel}`;

  return (
    <div
      className={c(
        'flex w-full h-full border group rounded',
        disabled
          ? 'opacity-50 cursor-not-allowed'
          : 'hover:shadow-lg hover:shadow-accent-200/50',
        isFeatured && 'flex-col',
        !hasActions && 'hover:border-gray-300 hover:bg-gray-50',
        hideOverflow && 'overflow-hidden',
      )}
      data-test={dataTestIds.component.contentCard.self}
    >
      <div
        className={c(
          'flex-shrink-0 border-r',
          isFeatured ? 'aspect-w-1 aspect-h-1' : 'w-20',
        )}
        style={{
          background: `url(${image}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        {!image && (
          <DefaultImage className="bg-accent-50 h-full w-full object-cover" />
        )}
      </div>
      <div className="relative h-full flex flex-col justify-end grow-0 min-w-0 w-full">
        {statusLabel ||
          (version && (
            <div className="flex items-center md:justify-between p-4">
              <div className="flex-grow">
                <div className="w-auto inline-block">{statusLabel}</div>
                {version ?? <span className="w-auto inline"> {version} </span>}
              </div>
              <div className="flex space-x-1 text-gray-500">
                {onDocument && (
                  <button onClick={onDocument} type="button">
                    <FaPaperclip />
                  </button>
                )}
                {toggleContextMenu && (
                  <FaEllipsisV
                    className="text-xs"
                    onClick={toggleContextMenu}
                  />
                )}
              </div>
            </div>
          ))}

        {extraActions}

        <div
          className={c(
            'w-auto flex-grow',
            extraActions && 'p-3',
            !extraActions && 'mt-2 p-4',
          )}
        >
          <HeadingTag
            className={c(
              'leading-tight text-xl font-normal flex flex-col flex-wrap justify-between items-center',
              tags && 'flex justify-between items-center',
              !tags && 'clamp-2',
            )}
          >
            <span>{title}</span>
            {tags && Boolean(tags.length) && (
              <div className="flex gap-1 overflow-x-auto mt-2 max-w-full">
                {tags.map((tag) => (
                  <Badge
                    key={`title${tag.id}`}
                    className="min-w-max"
                    color={tag.color}
                  >
                    {tag.name}
                  </Badge>
                ))}
              </div>
            )}
          </HeadingTag>
          {subTitle && <p className="text-gray-500 text-xs mt-2">{subTitle}</p>}
        </div>
        {count > 0 && (
          <div
            className={c(
              'flex flex-grow justify-start items-end text-sm leading-none',
            )}
          >
            {validChildren.map((child, index) => {
              if (!isValidElement(child)) {
                return null;
              }

              return (
                <div
                  key={`${Date.now() + index}`}
                  className={c(
                    'flex flex-grow',
                    index !== count - 1 &&
                      'mr-2 pr-2 border-r border-gray-400 flex items-center',
                  )}
                >
                  {child}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function Action({ children = null, className = '' }) {
  return (
    <div
      className={c(
        className,
        'leading-none text-accent-600 hover:text-accent-900 hover:border-gray-300 hover:bg-gray-50 hover:underline',
      )}
    >
      {children}
    </div>
  );
}

ContentCard.Action = Action;
