import useFormatMessage from 'hooks/useFormatMessage';
import Unplugged from 'assets/vectors/error-pages/unplugged.svg';
import { isArray } from 'lodash-es';
import { ErrorBase } from './ErrorBase';

export function ErrorUnplugged({
  children,
  message,
  redirectUrl,
  showBackLink,
  statusCode = '',
  titleKey,
}) {
  const t = useFormatMessage();
  const title = t(titleKey);
  const messages = isArray(message) ? message : [message];

  return (
    <ErrorBase
      image={Unplugged}
      messages={messages}
      redirectUrl={redirectUrl}
      showBackLink={showBackLink}
      statusCode={statusCode}
      title={title}
    >
      {children}
    </ErrorBase>
  );
}
