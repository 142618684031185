/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_ADD_USER_TO_CLASS_GROUP } from 'api/plannerService/schooladmin/mutations/schooladminAddUserToClassGroup';
import { SCHOOLADMIN_DELETE_USER_FROM_CLASS_GROUP } from 'api/plannerService/schooladmin/mutations/schooladminDeleteUserFromClassGroup';
import { SCHOOLADMIN_USERS_BY_CLASS_GROUP_ID } from 'api/plannerService/schooladmin/queries/schoolAdminUsersByClassGroupId';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUserClassgroupManagement() {
  const add = () => {
    const toast = useToast();
    const t = useFormatMessage();
    const toastId = 'add-user-to-class-group';
    return useMutation(
      ({ classGroupId, userId }) =>
        plannerServiceQueryClient.request(SCHOOLADMIN_ADD_USER_TO_CLASS_GROUP, {
          classGroupId,
          userId,
        }),
      {
        mutationKey: 'schooladminAddUserToClassGroup',
        onSuccess: () => {
          toast(TOASTTYPES.ADDSUCCESS, {
            str: t('global.CLASSGROUP.singular'),
            toastId,
          });
        },
        onerror: () => {
          toast(TOASTTYPES.ADDERROR, {
            str: t('global.CLASSGROUP.singular'),
            toastId,
          });
        },
      },
    );
  };

  const remove = () => {
    const toast = useToast();
    const t = useFormatMessage();
    const mutation = useMutation(
      ({ classGroupId, userId }) =>
        plannerServiceQueryClient.request(
          SCHOOLADMIN_DELETE_USER_FROM_CLASS_GROUP,
          {
            classGroupId,
            userId,
          },
        ),
      {
        mutationKey: 'schooladminDeleteUserFromClassGroup',
        onSuccess: () => {
          toast(TOASTTYPES.DELETESUCCESS, {
            str: t('global.USER.singular'),
          });
        },
        onerror: () => {
          toast(TOASTTYPES.DELETEERROR, {
            str: t('global.USER.singular'),
          });
        },
      },
    );
    return mutation;
  };

  const read = ({ classGroupId, filter, page, size, sort }) => {
    const toast = useToast();
    const t = useFormatMessage();

    const response = useQuery(
      ['usersClassGroup', classGroupId, filter, page, size, sort],
      () =>
        plannerServiceQueryClient.request(SCHOOLADMIN_USERS_BY_CLASS_GROUP_ID, {
          classGroupId,
          filter,
          page,
          size,
          sort,
        }),
      {
        onerror: () => {
          toast(TOASTTYPES.GETERROR, {
            str: t('global.USER.plural'),
          });
        },
      },
    );

    return {
      ...response,
      data: response?.data?.schooladminUsersByClassGroupId,
    };
  };

  return {
    add,
    remove,
    read,
  };
}
