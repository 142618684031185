import { useTeacherExercisesByUserId } from 'hooks/api/portfolioService/queries/useTeacherExercisesByUserId';
import { ContentCardList } from 'pages/ExercisesOverview/partials/ContentCardList';
import { EmptyExercises } from 'pages/ExercisesOverview/partials/EmptyExercises';
import { ExerciseFilter } from 'pages/ExercisesOverview/partials/ExerciseFilter';
import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

export function UserExercises() {
  const [{ user }] = useOutletContext();
  const { id: userId } = user;

  const {
    data: exercises,
    isError,
    isLoading,
  } = useTeacherExercisesByUserId(userId);
  const [filterTitle, setFilterTitle] = useState('');
  const isSearching = filterTitle !== '';

  const filteredExercises = useMemo(
    () =>
      isSearching
        ? exercises.filter((exercise) =>
            exercise.exerciseTitle
              ?.toLowerCase()
              .includes(filterTitle.toLowerCase()),
          )
        : exercises,
    [isSearching, exercises, filterTitle],
  );
  if (!isLoading && !isError && exercises?.length === 0) {
    return <EmptyExercises hasCreateExercise={false} />;
  }

  return (
    <>
      <ExerciseFilter
        filterTitle={filterTitle}
        isSearching={isSearching}
        setFilterTitle={setFilterTitle}
      />
      <ContentCardList
        filteredExercises={filteredExercises ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
