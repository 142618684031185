import { gql } from 'graphql-request';

export const SCHOOLADMIN_DELETE_USER_FROM_CLASS_GROUP = gql`
  mutation schooladminDeleteUserFromClassGroup(
    $classGroupId: Int!
    $userId: String!
  ) {
    schooladminDeleteUserFromClassGroup(
      classGroupId: $classGroupId
      userId: $userId
    ) {
      statusCode
      id
      message
    }
  }
`;
