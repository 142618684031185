import { useEffect, useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/solid';

import {
  Actions,
  ActionStyledAsButton,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useAdminAddProgramToPeriod from 'hooks/api/plannerService/admin/mutations/useAdminAddProgramToPeriod';
import useAdminDeleteProgramFromPeriod from 'hooks/api/plannerService/admin/mutations/useAdminDeleteProgramFromPeriod';
import useAdminUpdateSeatCount from 'hooks/api/plannerService/admin/mutations/useAdminUpdateSeatCount';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';
import useAdminOrganizationById from 'hooks/api/plannerService/admin/queries/useAdminOrganizationById';

import { mobile } from 'utils/constants/mediaWidth';
import { sortOperation } from 'utils/constants/sort';
import { dataTestIds } from 'utils/dataTestIds';
import { filterOperation } from 'utils/constants/filter';
import { getCurrentPeriod } from 'utils/getCurrentPeriod';
import { formatDateDDMonthYYYY, utcDatetoLocalDate } from 'utils/timeHelpers';

import AddProgramToOrganizationDialog from './programs/AddProgramToOrganizationDialog';
import DeleteProgramFromOrganizationDialog from './programs/DeleteProgramFromOrganizationDialog';
import EditSeatsForOrganizationProgram from './programs/EditSeatsForOrganizationProgram';
import EnterSeatsCountDialog from './programs/EnterSeatsCountDialog';
import { GetProgramsByPeriodId } from './programs/GetProgramsByPeriodId';
import {
  createOptionForPeriod,
  createOptionsForPeriods,
} from './programs/createOptionsForPeriods';
import { PeriodsDropdown } from './programs/PeriodsDropdown';

export default function ManageOrganizationPrograms() {
  const t = useFormatMessage();
  const { organizationId } = useParams();

  // state
  const [page, setPage] = useState(1);
  const [fetch, setFetch] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [sort, setSort] = useState({});
  const [programId, setProgramId] = useState(null);
  const [periodId, setPeriodId] = useState(null);
  const [isEditSeatsDialogOpen, setIsEditSeatsDialogOpen] = useState(false);

  /**
   * This code and other code related to editing a period for a program is
   * nearly ready (just needs a new backend call).
   * But given that it's not high prio this might take a while.  So the original
   * ticket (https://codefever.atlassian.net/browse/TR-2271) was adjusted to no
   * longer have this part in.  If Juul asks for it, we'll make it.  Else we won't.
   */
  // const [isEditPeriodDialogOpen, setIsEditPeriodDialogOpen] = useState(false);
  const [isAddProgramDialogOpen, setIsAddProgramDialogOpen] = useState(false);
  const [isEnterSeatsDialogOpen, setIsEnterSeatsDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [programsVariables, setProgramsVariables] = useState(null);
  const [isFetchingPrograms, setIsFetchingPrograms] = useState(false);
  const [programsByOrganizationData, setProgramsByOrganizationData] = useState(
    [],
  );
  const [chosenPeriod, setChosenPeriodState] = useState(null);

  // fetch hooks
  const { data: organization } = useAdminOrganizationById(organizationId);
  const periods = organization?.periods;

  // mutation hooks
  const { isLoading: isUpdatingSeatCount, mutateAsync: updateSeatCountHook } =
    useAdminUpdateSeatCount();
  const { mutateAsync: AdminDeleteProgramFromPeriod } =
    useAdminDeleteProgramFromPeriod();
  const { mutateAsync: addProgramToPeriod } = useAdminAddProgramToPeriod();
  // const {
  //   mutateAsync: updatePeriodHook,
  //   isLoading: isUpdatingPeriodsForProgram,
  // } = useAdminUpdatePeriod();

  const {
    content: programsByOrganization,
    pages: totalPages,
    total: totalItems,
  } = programsByOrganizationData || { content: [], total: 0, pages: 0 };

  const setChosenPeriod = (value) => {
    setPage(1);
    setChosenPeriodState(value);
  };

  const removeProgram = async () => {
    await AdminDeleteProgramFromPeriod({
      periodId: chosenPeriod?.id,
      programId: selectedProgram?.id,
    });
    setFetch(true);
  };

  // mutation hook wrappers
  const addProgram = async (periodId, programId, maxSeats) => {
    await addProgramToPeriod({
      periodId,
      programId,
      maxSeats,
    }).then(() => {
      setFetch(true);
    });
  };
  const addProgramToPeriodWrapper = async (data) => {
    if (programId) {
      addProgram(periodId, programId, data).then(() => {
        setFetch(true);
      });
    }
  };
  const updateSeatCountForProgram = (data, dismiss) => {
    updateSeatCountHook({
      organizationId,
      programId: selectedProgram.id,
      maxSeats: data.maxSeats,
    }).then(() => {
      dismiss();
      setFetch(true);
    });
  };

  // const updatePeriodForProgram = (id, data, dismiss) => {
  //   updatePeriodHook({
  //     id: selectedProgram.id,
  //     data
  //   }).then(() => {
  //     dismiss();
  //     void refetchAdminProgramsByOrganization();
  //   });
  // };

  // buttons
  const actionsForProgram = (program) => [
    {
      icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('edit-seats-for-program.edit'),
      testName: dataTestIds.page.organisationProfile.table.button.edit,
      onClick: () => {
        setSelectedProgram(program);
        setIsEditSeatsDialogOpen(true);
      },
    },
    // {
    //   icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
    //   label: t('edit-period-for-program.edit'),
    //   testName: dataTestIds.page.organisationProfile.table.button.edit,
    //   onClick: () => {
    //     setSelectedProgram(program);
    //     setIsEditPeriodDialogOpen(true);
    //   },
    // },
    {
      icon: <XCircleIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.delete'),
      testName: dataTestIds.page.organisationProfile.table.button.delete,
      onClick: () => {
        setSelectedProgram(program);
        setIsRemoveDialogOpen(true);
      },
    },
  ];

  const periodOptions = createOptionsForPeriods(periods);
  const currentPeriod = createOptionForPeriod(getCurrentPeriod(periods));

  useEffect(() => {
    const filter = [
      {
        key: 'periodId',
        operation: filterOperation.EQUAL,
      },
    ];
    const variables = {
      organizationId: Number(organizationId),
      page: page - 1,
      size: itemsPerPage,
      sort: { programName: sortOperation.ASC },
      filter,
    };

    if (periods && !chosenPeriod && !!currentPeriod) {
      setChosenPeriod(currentPeriod);
      filter[0].value = currentPeriod.id;
    }

    if (!!chosenPeriod) {
      filter[0].value = chosenPeriod.id;
    }

    if (filter[0].value) {
      setProgramsVariables({ ...variables });
      setFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPeriod, organizationId, page, periods]);

  return (
    <>
      {!!programsVariables && (
        <GetProgramsByPeriodId
          refetch={fetch}
          setIsFetchingPrograms={setIsFetchingPrograms}
          setProgramsByOrganizationData={setProgramsByOrganizationData}
          setRefetch={setFetch}
          variables={programsVariables}
        />
      )}
      {isRemoveDialogOpen && (
        <DeleteProgramFromOrganizationDialog
          isOpen={isRemoveDialogOpen}
          removeProgram={removeProgram}
          selectedProgram={selectedProgram}
          setIsRemoveDialogOpen={setIsRemoveDialogOpen}
        />
      )}
      {isAddProgramDialogOpen && (
        <AddProgramToOrganizationDialog
          currentPeriod={chosenPeriod}
          isOpen={isAddProgramDialogOpen}
          periods={periodOptions}
          programsByOrganization={programsByOrganization}
          setIsAddProgramDialogOpen={setIsAddProgramDialogOpen}
          setIsEnterSeatsDialogOpen={setIsEnterSeatsDialogOpen}
          setPeriodId={setPeriodId}
          setProgramId={setProgramId}
        />
      )}
      {isEnterSeatsDialogOpen && (
        <EnterSeatsCountDialog
          addProgramToPeriod={addProgramToPeriodWrapper}
          isOpen={isEnterSeatsDialogOpen}
          setFetch={setFetch}
          setIsEnterSeatsDialogOpen={setIsEnterSeatsDialogOpen}
          setProgramId={setProgramId}
        />
      )}
      {isEditSeatsDialogOpen && (
        <EditSeatsForOrganizationProgram
          isLoadingUpdateProgram={isUpdatingSeatCount}
          isOpen={isEditSeatsDialogOpen}
          selectedProgram={selectedProgram}
          setIsEditSeatsDialogOpen={setIsEditSeatsDialogOpen}
          setSelectedProgram={setSelectedProgram}
          update={updateSeatCountForProgram}
        />
      )}
      {/* { isEditPeriodDialogOpen && <EditPeriodForOrganizationProgram */}
      {/*  isLoadingUpdateProgram={isUpdatingPeriodsForProgram} */}
      {/*  isOpen={isEditPeriodDialogOpen} */}
      {/*  periods={periods} */}
      {/*  setIsEditPeriodDialogOpen={setIsEditPeriodDialogOpen} */}
      {/*  selectedProgram={selectedProgram} */}
      {/*  setSelectedProgram={setSelectedProgram} */}
      {/*  update={updatePeriodForProgram} */}
      {/* />} */}
      <>
        <div className="w-full flex justify-between">
          {!!chosenPeriod && (
            <PeriodsDropdown
              id="filterByPeriod"
              inModal={false}
              period={chosenPeriod}
              periods={periodOptions}
              setPeriod={setChosenPeriod}
            />
          )}
          <ActionStyledAsButton
            className="mb-4 ml-auto"
            iconBefore={PlusIcon}
            onClick={() => {
              setIsAddProgramDialogOpen(true);
            }}
            test={dataTestIds.page.organisationProfile.button.add.program}
          >
            {t('program-overview.new-program')}
          </ActionStyledAsButton>
        </div>

        <TableSkeleton loading={isFetchingPrograms}>
          <ResponsiveTable
            emptyText={t('program-overview.empty')}
            headers={[
              {
                key: 'name',
                label: t('program-overview.column.name'),
                orderable: false,
              },
              {
                key: 'type',
                label: t('program-overview.column.type'),
                orderable: false,
              },
              {
                key: 'maxSeats',
                label: t('program-overview.column.seatCount'),
                orderable: false,
              },
              {
                key: 'seatPeriod',
                label: t('program-overview.column.seatPeriod'),
                orderable: false,
              },
              {
                key: 'actions',
                label: t('global.actions'),
                orderable: false,
                className: 'max-w-fit sr-only',
              },
            ]}
            mobileBreakpoint={mobile}
            mobileHeaderWidth={160}
            onSortChange={(newSort) => setSort(newSort)}
            sort={sort}
          >
            {programsByOrganization?.map((programByOrganization) => (
              <ResponsiveTableRow
                key={programByOrganization.id}
                data={programByOrganization}
              >
                <ResponsiveTableCol whitespaceNoWrapOnMobile={false} wrapRow>
                  {programByOrganization.name}
                </ResponsiveTableCol>
                <ResponsiveTableCol>
                  {programByOrganization.type}
                </ResponsiveTableCol>
                <ResponsiveTableCol>
                  {`${programByOrganization.claimedSeats} / ${programByOrganization.maxSeats}`}
                </ResponsiveTableCol>
                <ResponsiveTableCol>
                  {programByOrganization?.period && (
                    <>
                      <p>{programByOrganization.period.name}</p>
                      <p className="text-sm">
                        {formatDateDDMonthYYYY(
                          utcDatetoLocalDate(
                            programByOrganization.period.startDate,
                          ),
                        )}{' '}
                        -{' '}
                        {formatDateDDMonthYYYY(
                          utcDatetoLocalDate(
                            programByOrganization.period.endDate,
                          ),
                        )}
                      </p>
                    </>
                  )}
                </ResponsiveTableCol>
                <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                  <Actions items={actionsForProgram(programByOrganization)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            ))}
          </ResponsiveTable>

          {Boolean(programsByOrganization && programsByOrganization.length) && (
            <Pagination
              className="mt-12"
              currentPage={page}
              itemsPerPage={itemsPerPage}
              setCurrentPage={(page) => setPage(page)}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
        </TableSkeleton>
      </>
    </>
  );
}
