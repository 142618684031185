import { gql } from 'graphql-request';

export const ADMIN_USERS_BY_ORGANIZATION_ID = gql`
  query adminUsersByOrganizationId(
    $organizationId: Int!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    adminUsersByOrganizationId(
      organizationId: $organizationId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        classGroups {
          id
          name
          archived
          amountOfStudents
        }
        displayName
        email
        enabled
        firstName
        fullName
        id
        idp
        language
        lastName
        organizationId
        roles
        userName
        avatar
      }
    }
  }
`;
