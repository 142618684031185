/**
 * Get the indexes whence the dragged item moved from and to.
 * Indexes are set in Group.js.  Behaviour is different for the first group,
 * but consistent for all other ones.
 */
export const getIndexesForDraggedItem = function (result) {
  const sourceGroupIndex = Number(result.source.droppableId);
  const destinationGroupIndex = Number(result.destination.droppableId);

  return {
    sourceGroupIndex,
    destinationGroupIndex,
    sourceMemberIndex:
      sourceGroupIndex === 0
        ? result.source.index
        : Number(`${result.source.index}`[1]),
    destinationMemberIndex:
      destinationGroupIndex === 0
        ? result.destination.index
        : Number(`${result.destination.index}`[1]),
  };
};
