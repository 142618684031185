import { gql } from 'graphql-request';

export const ADMIN_PROGRAMS_BY_ORGANIZATION_ID = gql`
  query adminProgramsByOrganizationId(
    $organizationId: Int!
    $page: Int!
    $size: Int!
    $sort: JSON
    $filter: [JSON]
  ) {
    adminProgramsByOrganizationId(
      organizationId: $organizationId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      total
      pages
      currentPage
      content {
        id
        name
        type
        maxSeats
        claimedSeats
        period {
          id
          name
          description
          startDate
          endDate
        }
      }
    }
  }
`;
