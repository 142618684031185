import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_UPDATE_CHAPTER_ITEM } from 'api/plannerService/admin/mutations/adminUpdateChapterItem';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminUpdateChapterItem() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ file, id, input }) =>
      plannerServiceQueryClient.request(ADMIN_UPDATE_CHAPTER_ITEM, {
        id,
        input,
        file,
      }),
    {
      mutationKey: 'adminUpdateChapterItem',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.CHAPTERITEM.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.CHAPTERITEM.singular'),
        });
      },
    },
  );
}
