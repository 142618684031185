import { gql } from 'graphql-request';

export const ADMIN_DELETE_PERIOD = gql`
  mutation adminDeletePeriod($id: Int!) {
    adminDeletePeriod(id: $id) {
      id
      message
    }
  }
`;
