import { useContext } from 'react';

import c from '../../utils/c';

import parseHtml from './parseHtml';
import { SlideViewerContext } from './SlideViewerContextProvider';
// eslint-disable-next-line import/no-cycle
import { SlideViewerCk5Slide } from './SlideViewerCk5Slide';
import { processor } from './processor';

export default function SlideViewerBaseSlide({
  classNames = '',
  hasBackground = false,
  hasFixedDimensions = false,
  isCk5Thumbnail = false,
  isFullSlideViewer = true,
  value,
}) {
  const context = useContext(SlideViewerContext);
  const { currentSlide, isCk5 } = context;

  const className = c('SlideViewerTextSlide', classNames);

  // when no context is found, don't try to parse with custom components
  if (!context) {
    return <div className={className}>{parseHtml(value)}</div>;
  }

  if (isCk5 || isCk5Thumbnail) {
    return (
      <SlideViewerCk5Slide
        currentSlide={currentSlide}
        hasFixedDimensions={hasFixedDimensions}
        isFullSlideViewer={isFullSlideViewer}
        isThumbnail={isCk5Thumbnail}
        isTransparent={hasBackground}
      >
        {processor.processSync(value).result.props.children}
      </SlideViewerCk5Slide>
    );
  }

  return <div className={className}>{processor.processSync(value).result}</div>;
}
