import { useCallback, useMemo } from 'react';
import { FaChalkboardTeacher, FaUser, FaUsers } from 'react-icons/fa';

import useFormatMessage from '../../hooks/useFormatMessage';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';

import { IconOrDefault } from './activityTypeIcons';
import SLIDEMETHODS from './slideMethods';

function Start() {
  const methods = [
    [SLIDEMETHODS.CLASS, FaChalkboardTeacher],
    [SLIDEMETHODS.GROUP, FaUsers],
    [SLIDEMETHODS.INDIVIDUALLY, FaUser],
  ];
  return (
    <div className="relative flex flex-col h-full justify-between items-center">
      {methods.map(([name, Icon]) => (
        <div key={name} className="flex-grow flex flex-col justify-center  ">
          <div className="flex bg-blue-400 rounded w-36 h-8 items-center px-2 font-medium text-gray-100">
            <Icon />
            <div className="ml-2">{name}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

function Lines() {
  return (
    <div className="flex flex-col h-full justify-between items-center  ">
      {[0, 1, 2].map(() => (
        <div
          key={Date.now()}
          className=" flex-grow flex flex-col justify-center "
        >
          <div className="flex  items-center ">
            <div
              className="absolute bg-gray-500 w-full -z-10"
              style={{ height: 2 }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

function RoundedIcon({ className, itemName, onClick }) {
  return (
    <div
      className={`${className} flex items-center w-12 h-12 rounded-full bg-blue-400 text-gray-100 cursor-pointer`}
    >
      <div className="block m-auto items-center">
        <IconOrDefault itemName={itemName} onClick={onClick} size={30} />
      </div>
    </div>
  );
}

const itemPositions = {
  [SLIDEMETHODS.CLASS]: 'justify-start',
  [SLIDEMETHODS.GROUP]: 'justify-center',
  [SLIDEMETHODS.INDIVIDUALLY]: 'justify-end',
};

function Item({
  activity,
  firstId,
  highlight,
  icon,
  method,
  onItemClick = () => {},
}) {
  const position = itemPositions[method];
  const highlightStyle = highlight ? 'border-2 border-accent-300' : '';
  const selectItem = useCallback(
    () => onItemClick(firstId),
    [firstId, onItemClick],
  );
  return (
    <div className={`relative h-full flex flex-col w-auto ${position}`}>
      <div className="flex flex-col h-1/3 ">
        <div className="h-1/3" />
        <div className="h-1/3 flex flex-col justify-center items-center">
          <RoundedIcon
            className={`z-1 ${highlightStyle}`}
            itemName={icon}
            onClick={selectItem}
          />
        </div>
        <div className="h-1/3 mx-2">
          <div
            className={`${highlightStyle} relative -top-5 -z-1 p-2 w-auto rounded-lg shadow break-words text-sm text-center cursor-pointer`}
            onClick={selectItem}
            style={{ minWidth: 130 }}
          >
            {activity || 'No Activity'}
          </div>
        </div>
      </div>
    </div>
  );
}

function Part({
  highlightIdx,
  index,
  isStart = false,
  items,
  name,
  onItemClick,
}) {
  return (
    <div className="w-full flex flex-col">
      <div className="px-2 bg-gray-100 h-16 flex flex-col justify-center border-r border-gray-300 text-gray-700 border-b border-b-gray-300">
        <div className="text-sm">Deel {index + 1}:</div>
        <div className="capitalize font-bold">{name.toLowerCase()}</div>
      </div>
      <div
        className={`${
          isStart ? 'pl-5' : ''
        } relative border-r border-dashed border-gray-400 h-5/6 w-full flex flex-grow`}
      >
        <Lines />
        {isStart && <Start />}
        {items.map((item, i) => (
          <Item
            key={`${Date.now() + highlightIdx + i}`}
            highlight={highlightIdx === i}
            onItemClick={onItemClick}
            {...item}
          />
        ))}
      </div>
    </div>
  );
}

const segmentSlides = (slides) => {
  const segments = [];
  let [prevmethod, prevactivity, prevpart] = [null, null, null];
  const idToSegment = new Map();
  for (let i = 0; i < slides.length; i++) {
    const { activityType, id, method, part } = slides[i];
    const { icon = null, key: activity = null } = activityType ?? {};

    if (part !== prevpart) {
      segments.push({ part, items: [{ method, activity, icon, firstId: id }] });
      prevpart = part;
      prevmethod = method;
      prevactivity = activity;
    } else if (method !== prevmethod || activity !== prevactivity) {
      segments.at(-1).items.push({ method, activity, icon, firstId: id });
      prevmethod = method;
      prevactivity = activity;
    }
    idToSegment.set(id, [
      segments.length - 1,
      segments.at(-1) ? segments.at(-1).items.length - 1 : 0,
    ]);
  }
  return { segments, idToSegment };
};

export function ContentFlowDiagram({
  content,
  onItemClick = null,
  slideId = null,
}) {
  const slides = content?.slides;
  const t = useFormatMessage();

  const { idToSegment, segments } = useMemo(
    () => segmentSlides(slides),
    [slides],
  );
  const [hlSegIdx, hlItemIdx] = slideId ? idToSegment.get(slideId) : [-1, -1];

  if (!slides) {
    return (
      <div className="w-full h-full flex justify-center">
        <p className="self-center font-bold text-2xl">
          {t('content-flow.loading')}
        </p>
      </div>
    );
  }

  if (!slides[0] && !slides[0]?.activityType) {
    return (
      <div className="w-full h-full flex justify-center">
        <p className="self-center font-bold text-2xl">
          {t('content-flow.none')}
        </p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto flex flex-row h-full justify-start flex-grow">
      {segments.map((s, i) => (
        <Part
          key={`${Date.now() + hlSegIdx + hlItemIdx + i}`}
          highlightIdx={hlSegIdx === i ? hlItemIdx : -1}
          index={i}
          isStart={i === 0}
          items={s.items}
          name={s.part}
          onItemClick={onItemClick}
        />
      ))}
    </div>
  );
}

export const ContentFlowTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
