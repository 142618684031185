import { gql } from 'graphql-request';

export const SCHOOLADMIN_ADD_USER_TO_CLASS_GROUP = gql`
  mutation schooladminAddUserToClassGroup(
    $classGroupId: Int!
    $userId: String!
  ) {
    schooladminAddUserToClassGroup(
      classGroupId: $classGroupId
      userId: $userId
    ) {
      statusCode
      id
      message
    }
  }
`;
