import useFormatMessage from 'hooks/useFormatMessage';
import { Tooltip } from '@ftrprf/tailwind-components';
import { getReadableDateTime } from '../../CalendarItemResults/partials/getReadableDateTime';

export function Questions({
  bulletColour,
  numberOfCalendarItems = 1,
  report,
  showDateInLabel = false,
  showDateInTooltip = false,
}) {
  const t = useFormatMessage();
  const { amountOfQuestions, answeredQuestions, startTime, viewMode } = report;
  const hasMultipleQuestions = numberOfCalendarItems > 1;

  const dateLabel = t(
    `chapterItemReport.tooltip.multiple_no_grade_${viewMode.toLowerCase()}`,
    { startTime: getReadableDateTime(startTime, t) },
  );
  const scoreModeText = `${answeredQuestions}/${amountOfQuestions}`;

  const multipleQuestionsLabel = t(
    'chapterItemReport.tooltip.multiple_questions',
  );

  if (showDateInTooltip) {
    return (
      <Tooltip
        label={hasMultipleQuestions ? multipleQuestionsLabel : dateLabel}
      >
        <div className={hasMultipleQuestions ? 'flex gap-1' : ''}>
          <span className="hover:underline-offset-4 hover:underline hover:decoration-2">
            {scoreModeText}
          </span>
          {hasMultipleQuestions && (
            <span
              className={`rounded-full ${bulletColour} text-white text-xs w-3 h-3 flex justify-center items-center font-bold p-1 hover:decoration-0`}
            >
              {numberOfCalendarItems}
            </span>
          )}
        </div>
      </Tooltip>
    );
  }

  return (
    <span>
      {showDateInLabel && `${dateLabel}: `}
      {scoreModeText}
    </span>
  );
}
