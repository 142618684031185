/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import { XCircleIcon } from '@heroicons/react/24/solid';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogHeader,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import titleCase from 'utils/titleCase';
import { dataTestIds } from 'utils/dataTestIds';

export default function ImportExcelDialog({
  importIsBusy,
  isOpen = false,
  onDismiss: dismiss,
  onSubmit: submit,
}) {
  const t = useFormatMessage();

  const [file, setFile] = useState(false);
  const [excelErrors, setExcelErrors] = useState([]);

  const onDismiss = useCallback(() => {
    setFile(false);
    setExcelErrors([]);
    dismiss();
  }, [dismiss]);

  const onSubmit = useCallback(() => {
    submit(file[0]).then((e) => {
      const errors = Object.values(e)[0];

      if (!errors.length) {
        onDismiss();
      } else {
        setExcelErrors(errors);
      }
    });
  }, [onDismiss, file, submit]);

  const typeValidator = useCallback(
    (file) => {
      if (
        file.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel'
      ) {
        return {
          code: 'fileType',
          message: t('import.dialog.file.unsupported'),
        };
      }
      return null;
    },
    [t],
  );

  return (
    <Dialog
      className=" max-w-5/6 lg:max-w-2xl flex bg-white shadow-base flex-col max-w-min rounded-lg"
      isOpen={isOpen || Boolean(excelErrors.length)}
      onDismiss={onDismiss}
      unstyled
    >
      <DialogHeader>{t('import.dialog.title')}</DialogHeader>
      <form>
        <Dropzone
          maxFiles={1}
          onDrop={(acceptedFiles) => {
            setFile(acceptedFiles);
            setExcelErrors([]);
          }}
          validator={typeValidator}
        >
          {({ acceptedFiles, fileRejections, getInputProps, getRootProps }) => (
            <div {...getRootProps()} className="p-4 text-center">
              <div className="p-4 border-2 border-dashed border-gray-300 hover:border-gray-400 rounded-2xl">
                <input
                  data-test={dataTestIds.modal.importExcel.input.import}
                  {...getInputProps()}
                />
                <p>{t('global.drag_and_drop_or_browse.excel')}</p>
                <p className="text-xs">
                  <em>{t('import.dialog.description')}</em>
                </p>
                {acceptedFiles.length > 0 && (
                  <>
                    <h4 className="text-lg font-bold">
                      {t('import.dialog.file.valid')}
                    </h4>
                    <ul>
                      {acceptedFiles.map((file) => (
                        <li key={file.path}>
                          {file.path} - {file.size / 1000} kb
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {fileRejections.length > 0 && (
                <>
                  <h3 className="text-lg font-bold">
                    {t('import.dialog.file.invalid')}
                  </h3>
                  <ul>
                    {fileRejections.map(({ errors, file }) => (
                      <li key={file.path}>
                        {file.path} - {file.size / 1000} kb
                        <ul>
                          {errors.map((e) => (
                            <li key={e.code}>{e.message}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
        </Dropzone>

        {excelErrors.length > 0 && (
          <div
            className="bg-red-100 p-4"
            data-test={dataTestIds.modal.importExcel.text.error}
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-red-800"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {t('import.dialog.submission.error')}
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc space-y-1 pl-5">
                    {excelErrors?.map((value, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`excelerror/${index}/${Date.now()}`}>
                        <div>
                          {`${value.error}, in the sheet with name "${value.sheetName}", at column: ${value.column}, at row: ${value.row}`}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.importExcel.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={!file[0] || excelErrors.length > 0 || importIsBusy}
            loading={importIsBusy}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSubmit();
            }}
            test={dataTestIds.modal.importExcel.button.startImport}
          >
            {titleCase(`${t('global.start')} ${t('global.import')}`)}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
