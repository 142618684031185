import { Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { Controller } from 'react-hook-form';

export function AssignmentDescription({
  assignmentDescription,
  control,
  setAssignmentDescription,
}) {
  const t = useFormatMessage();

  return (
    <div className="mt-4">
      <Label htmlFor="assignmentDescription">
        {t('calendar.modal.teams.assignmentDescription')}
      </Label>
      <Controller
        control={control}
        name="assignmentDescription"
        render={() => (
          <Input
            id="assignmentDescription"
            onChange={setAssignmentDescription}
            type="multiline"
            value={assignmentDescription}
          />
        )}
        type="select"
      />
    </div>
  );
}
