import { ArrowDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import c from '../../utils/c';
import useFormatMessage from '../../hooks/useFormatMessage';

export function ScrollIndicator({ contentElementRef }) {
  const t = useFormatMessage();
  const element = contentElementRef.current;
  const [isArrowDown, setIsArrowDown] = useState(true);

  const isContentOverflowing = useMemo(() => {
    if (!element) return false;

    return element.scrollHeight > element.clientHeight;
  }, [element]);

  useEffect(() => {
    if (!element) return;

    element.addEventListener('scroll', () => {
      if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
        setIsArrowDown(false);
        return;
      }

      if (element.scrollTop === 0) {
        setIsArrowDown(true);
      }
    });
  }, [element]);

  if (!isContentOverflowing) {
    return null;
  }

  return (
    <div className="absolute left-0 right-0 flex justify-center -top-5">
      <div className="absolute -top-5 h-10 left-0 right-5 bg-gradient-to-t from-white" />
      <button
        className="text-white bg-accent-500 rounded-full shadow z-10 p-2"
        onClick={() => {
          element.scrollTo({
            top: isArrowDown ? element.scrollHeight : 0,
            behavior: 'smooth',
          });
        }}
        type="button"
      >
        <span className="sr-only">
          {t(
            isArrowDown ? 'slide_viewer.scroll_down' : 'slide_viewer.scroll_up',
          )}
        </span>
        <ArrowDownIcon
          className={c('h-5 w-5 transition', isArrowDown || 'rotate-180')}
        />
      </button>
    </div>
  );
}
