import { useEffect, useState } from 'react';

import { ActionStyledAsButton } from '../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { HintDialog } from './HintDialog';

export default function Hints({ hints }) {
  const t = useFormatMessage();

  const [openHint, setOpenHint] = useState(null);

  useEffect(() => {
    setOpenHint(null);
  }, [hints]);

  const onOpen = (hint, index) => {
    setOpenHint({ ...hint, index });
  };

  const onClose = () => {
    setOpenHint(null);
  };

  return (
    <>
      {openHint && (
        <HintDialog
          content={openHint.content || ''}
          isOpen={!!openHint}
          onDismiss={onClose}
          title={
            openHint.title ||
            `${t('styled-slide-viewer.hint')} ${openHint.index}`
          }
        />
      )}
      <div className="divide-y-2 flex flex-col">
        {hints.map((hint, index) => {
          const disabled = openHint?.id === hint.id;
          return (
            <ActionStyledAsButton
              key={hint.id}
              disabled={disabled}
              onClick={() => onOpen(hint, index + 1)}
              size="small"
              variant="blue"
            >
              {hint.title || `${t('styled-slide-viewer.hint')} ${index + 1}`}
            </ActionStyledAsButton>
          );
        })}
      </div>
    </>
  );
}
