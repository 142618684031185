import { Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { Controller } from 'react-hook-form';

export function AssignmentTitle({
  assignmentTitle = '',
  control,
  errors,
  setAssignmentTitle,
}) {
  const t = useFormatMessage();

  return (
    <div className="mt-4">
      <Label htmlFor="assignmentTitle">
        {t('calendar.modal.teams.assignmentTitle')}
      </Label>
      <Controller
        control={control}
        name="assignmentTitle"
        render={() => (
          <Input
            hasErrors={Boolean(errors.assignmentTitle)}
            id="assignmentTitle"
            onChange={setAssignmentTitle}
            type="text"
            value={assignmentTitle}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('global.required-field'),
          },
        }}
        type="select"
      />
      {errors.assignmentTitle && (
        <span className="text-red-500 text-xs">
          {errors.assignmentTitle.message}
        </span>
      )}
    </div>
  );
}
