import { gql } from 'graphql-request';

export const EXERCISES = gql`
  query exercises($title: String, $requestContinuation: String, $size: Int) {
    exercises(
      title: $title
      requestContinuation: $requestContinuation
      size: $size
    ) {
      total
      pages
      requestContinuation
      currentPage
      content {
        content
        createdAt
        exerciseId
        exerciseImage
        exerciseTitle
        exerciseVersionId
        lastModified
        lessonSessionId
        location
        userId
      }
    }
  }
`;
