/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { ActionStyledAsButton } from '../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { feedbackTargets } from '../../../utils/constants/feedbackTargets';
import { isMail } from '../../../utils/isMail';

import { CheckBox } from '../../CheckBox';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '../../dialogs';
import { Dropdown } from '../../Dropdown';
import { Input } from '../../form/Input';
import { Label } from '../../form/Label';
import { Loader } from '../../Loader';
import { SlideViewerContext } from '../../slideviewer/SlideViewerContextProvider';

const feedbackOptions = [
  {
    id: 'slide',
    name: feedbackTargets.SLIDE,
  },
  {
    id: 'teacher_manual',
    name: feedbackTargets.TEACHER_MANUAL,
  },
  {
    id: 'exercise',
    name: feedbackTargets.EXERCISE,
  },
  {
    id: 'working_sheet',
    name: feedbackTargets.WORKING_SHEET,
  },
  {
    id: 'lesson_document',
    name: feedbackTargets.LESSON_DOCUMENT,
  },
];

export default function FeedbackDialog({ isOpen, setClosed }) {
  const t = useFormatMessage();
  const {
    lesson,
    lessonSessionId,
    slideId,
    studioId,
    useTeacherCreateContentFeedback,
    user,
  } = useContext(SlideViewerContext);
  const [wantsToStayPosted, setWantsToStayPosted] = useState(false);
  const [files, setFiles] = useState([]);

  const { createContentFeedback, saveLoading } =
    useTeacherCreateContentFeedback;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm();

  const handleCloseFeedbackDialog = useCallback(() => {
    setClosed();
    setFiles([]);
    setWantsToStayPosted(false);
    reset();
  }, [reset, setClosed]);

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/tiff': ['.tif', '.tiff'],
      'image/webp': ['.webp'],
      'image/gif': ['.gif'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          setError(`Error: ${err.message}`);
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      return acceptedFiles;
    },
  });

  const addFeedbackItem = async (data) => {
    const feedbackFiles = files ? acceptedFiles[0] : undefined;
    const idForStudio = studioId || lesson?.studioId;
    const feedbackInput = {
      email: data.email,
      feedback: data.feedbackText,
      target: data.target.toUpperCase(),
      sourceUri: `${process.env.REACT_APP_STUDIO_URL}/lesson/${idForStudio}/edit`,
      slideId,
      studioId: idForStudio,
      lessonTitle: lesson?.title,
      calendarItemSessionId: lessonSessionId,
    };

    await createContentFeedback({
      file: feedbackFiles,
      input: feedbackInput,
    });

    // clean up
    handleCloseFeedbackDialog();
  };

  return (
    <Dialog
      className={`w-3/4 ${
        saveLoading ? 'flex justify-center items-center' : ''
      }`}
      data-test="feedbackDialog"
      isOpen={isOpen}
      onDismiss={handleCloseFeedbackDialog}
    >
      {saveLoading ? (
        <div className="p-4 w-10 h-10\my-10 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(addFeedbackItem)}>
          <DialogHeader>{t('styled-slide-viewer.feedback.title')}</DialogHeader>
          <DialogContent>
            <div className="flex flex-col mb-4">
              <Label className="font-semibold" htmlFor="feedbackTarget">
                {t('styled-slide-viewer.feedback.target')}
              </Label>
              <Controller
                control={control}
                defaultValue={[]}
                name="target"
                render={({ field: { onBlur, onChange, value } }) => (
                  <Dropdown
                    id="feedbackTarget"
                    isMulti={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    options={feedbackOptions.map((option) => ({
                      value: option.id,
                      label: t(
                        `styled-slide-viewer.feedback.type.${option.name}`,
                      ),
                    }))}
                    placeholder={t(
                      'styled-slide-viewer.feedback.target.placeholder',
                    )}
                    value={[...value]}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('styled-slide-viewer.feedback.target.error'),
                  },
                }}
                type="select"
              />
              {errors.target && (
                <p
                  className="text-xs text-red-600 mt-2"
                  data-test="errorTypeField"
                >
                  {errors.target.message}
                </p>
              )}
            </div>

            <div className="flex flex-col mb-4">
              <Label className="font-semibold" htmlFor="feedbackText">
                {t('styled-slide-viewer.feedback.feedback')}
              </Label>
              <Controller
                control={control}
                name="feedbackText"
                render={({ field: { onBlur, onChange, value } }) => (
                  <div className="max-w-[70ch]">
                    <Input
                      className="bg-neutral-100 p-1 rounded w-full"
                      defaultValue=""
                      id="feedbackText"
                      onBlur={onBlur}
                      onChange={onChange}
                      onMouseLeave={onBlur}
                      rows={4}
                      type="multiline"
                      value={value}
                    />
                  </div>
                )}
                type="textarea"
              />
            </div>

            <div className="flex flex-col mb-4">
              <Label className="font-semibold" htmlFor="feedbackFile">
                {t('styled-slide-viewer.feedback.file.label')}
              </Label>
              <Controller
                control={control}
                name="feedbackFile"
                render={({ field: { onChange, value } }) => (
                  <div>
                    {files[0] ? (
                      <div
                        className="grid grid-cols-2 w-full p-2 justify-center items-center rounded cursor-pointer border border-gray-300"
                        data-test="feedbackFileToUpload"
                        onClick={() => {
                          setFiles([]);
                        }}
                      >
                        <div className="p-2 flex flex-row justify-start items-center">
                          <img
                            alt=""
                            className="h-7 w-7 mr-2"
                            src={files[0]?.preview}
                          />
                          {files[0]?.name}
                        </div>
                        <div className="p-2 flex flex-row justify-end items-center">
                          <ActionStyledAsButton danger variant="text">
                            <XCircleIcon className="h-3 w-3 mr-2" />
                            {t('global.file.delete')}
                          </ActionStyledAsButton>
                        </div>
                      </div>
                    ) : (
                      <div
                        {...getRootProps()}
                        className="flex flex-row gap-x-3 justify-center items-center p-7 rounded border border-gray-300"
                      >
                        <Input
                          id="feedbackFile"
                          onChange={onChange}
                          type="file"
                          value={value}
                          {...getInputProps()}
                        />
                        <div className="w-full flex flex-row justify-center items-center">
                          <ArrowUpTrayIcon
                            className="h-3 w-3 mr-2"
                            id="addFeedbackDialog-file"
                          />
                          {t('global.drag_and_drop_or_browse.image')}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>

            <div className="flex flex-col">
              <Controller
                control={control}
                defaultValue={wantsToStayPosted}
                name="feedbackNotify"
                render={({ field: { onChange, value } }) => (
                  <CheckBox
                    checked={value}
                    className="my-5"
                    data-test="feedbackNotify"
                    id="notify"
                    label={t('styled-slide-viewer.feedback.notify')}
                    onChange={(value) => {
                      setWantsToStayPosted(value);
                      onChange(value);
                    }}
                    value={value}
                  />
                )}
                type="checkbox"
              />
            </div>

            {wantsToStayPosted && (
              <div className="flex flex-col mb-4">
                <Label className="font-semibold" htmlFor="feedbackEmail">
                  {t('styled-slide-viewer.feedback.email')}
                </Label>
                <Controller
                  control={control}
                  defaultValue={user.email || ''}
                  name="email"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <Input
                      disabled={!wantsToStayPosted}
                      id="feedbackEmail"
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder="email@mail.com"
                      value={wantsToStayPosted ? value || ' ' : ''}
                    />
                  )}
                  rules={{
                    validate: { isMail },
                    required: {
                      value: wantsToStayPosted,
                      message: t('styled-slide-viewer.feedback.email.required'),
                    },
                  }}
                  type="input"
                />
                {errors.email && (
                  <p
                    className="text-red-500 text-xs mt-2"
                    data-test="errorEmailField"
                  >
                    {errors.email.message}
                  </p>
                )}
                {errors.email?.type === 'isMail' && (
                  <p
                    className="text-red-500 text-xs mt-2"
                    data-test="errorEmailValidation"
                  >
                    {t('styled-slide-viewer.feedback.email.validate-error')}
                  </p>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton
              onClick={() => handleCloseFeedbackDialog()}
              test="closeFeedbackDialogButton"
              type="button"
              variant="text"
            >
              {t('global.cancel')}
            </ActionStyledAsButton>

            <ActionStyledAsButton
              test="submitFeedbackDialogButton"
              type="submit"
            >
              {t('global.save')}
            </ActionStyledAsButton>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}
