import { gql } from 'graphql-request';

export const SCHOOLADMIN_UPDATE_CLASS_GROUP_SCHEDULED_SYNC = gql`
  mutation schooladminUpdateClassGroupScheduledSync(
    $classGroupScheduledSyncInputs: [ClassGroupScheduledSyncInput]!
  ) {
    schooladminUpdateClassGroupScheduledSync(
      classGroupScheduledSyncInputs: $classGroupScheduledSyncInputs
    ) {
      amountOfStudents
      archived
      id
      dailySync
      externalId
      lastSyncedOn
      name
      organizationId
      periodId
    }
  }
`;
