import { dataTestIds } from 'utils/dataTestIds';

import SkeletonConstructor, { SKELETON_OPTIONS } from './SkeletonConstructor';

export default function TableSkeleton({ children, loading }) {
  return loading ? (
    <div data-test={dataTestIds.component.loader}>
      <SkeletonConstructor
        className="mt-4"
        layout={`1${SKELETON_OPTIONS.TABLE}`}
      />
    </div>
  ) : (
    children
  );
}
