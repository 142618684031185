import {
  MultiCollapsable,
  MultiCollapsableState,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { determineCorrectAndTotal } from './determineCorrectandTotal';

export function TestContainer({ index = 0, test }) {
  const t = useFormatMessage();
  const { allCorrect, totalTests } = determineCorrectAndTotal(test);
  const id = `tst/${index}`;
  const hasAllAnswersCorrect = allCorrect === totalTests;
  let state = hasAllAnswersCorrect
    ? MultiCollapsableState.success
    : MultiCollapsableState.partial;
  if (!hasAllAnswersCorrect && allCorrect === 0)
    state = MultiCollapsableState.failed;

  return (
    <li className="mx-0 md:mx-5 mb-1 mt-0 rounded-lg overflow-hidden">
      <MultiCollapsable
        key={id}
        amount={test.children ? `${allCorrect}/${totalTests}` : ''}
        hasDropDown={!!test.children}
        mainText={test.children ? test.name : test.feedback}
        state={state}
        subText={
          test.children
            ? `${test.children.length} ${t('gui.ftrprf.testresult.tests')}`
            : false
        }
      >
        {test.children ? (
          <ul className="border-l-2 border-gray-300 ml-5 ">
            {test?.children.map((child, index) => (
              <TestContainer key={`${Date.now() + index}${id}`} test={child} />
            ))}
          </ul>
        ) : null}
      </MultiCollapsable>
    </li>
  );
}
