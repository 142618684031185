import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SAVE_SCRATCH_EXERCISE } from 'api/portfolioService/mutations/saveScratchExercise';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSaveScratchExercise() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({
      exerciseId,
      exerciseVersionId,
      file,
      language,
      lessonSessionId,
      studioId,
      testPlanId,
    }) =>
      portfolioServiceQueryClient.request(SAVE_SCRATCH_EXERCISE, {
        input: {
          lessonSessionId,
          exerciseId,
          exerciseVersionId,
          studioId,
          language,
          testPlanId,
        },
        file,
      }),
    {
      mutationKey: 'saveScratchExercise',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.EXERCISE.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.EXERCISE.singular'),
        });
      },
    },
  );

  return mutation;
}
