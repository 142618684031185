import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Content, Pagination, SearchBar } from '@ftrprf/tailwind-components';

import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';

import { UserContext } from 'providers/UserProvider';
import useFormatMessage from 'hooks/useFormatMessage';

import useTitle from 'hooks/useTitle';
import { dataTestIds } from 'utils/dataTestIds';
import { PeriodsContext } from 'providers/PeriodsProvider';
import CalendarItemDialog from '../../Calendar/CalendarItemDialog';

import { OwnContentOverview } from './partials/OwnContentOverview';
import { QuickPlanDialog } from './partials/QuickPlanDialog';

export function OwnLessonsOverview({
  classGroupsData,
  currentPage,
  data,
  isFiltering,
  isLoading,
  itemsPerPage,
  setFilter,
  setItemsPerPage,
  setPage,
}) {
  const t = useFormatMessage();
  const pageTitle = t('curricula.lessons.own');
  useTitle(pageTitle);
  const { isStudent, isTeacher, organization } = useContext(UserContext);
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const { pathname } = useLocation();

  const lessons = useMemo(
    () => (isStudent ? data || [] : data?.content || []),
    [data, isStudent],
  );
  const totalPages = data?.pages;
  const totalItems = data?.total;

  const [isOpenModalCalendarItem, setIsOpenModalCalendarItem] = useState(false);
  const [isOpenQuickPlanModal, setIsOpenQuickPlanModal] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [classGroups, setClassGroups] = useState();

  useEffect(() => {
    if (classGroupsData?.content) setClassGroups(classGroupsData.content);
  }, [classGroupsData, setClassGroups]);

  const stableChild = useMemo(
    () => (
      <div className="text-center w-full mx-auto">
        <ListSkeleton loading={isLoading}>
          <div className="mx-auto mt-4 w-1/2 xl:w-1/3">
            {(lessons.length || isFiltering) && (
              <SearchBar
                onChange={(value) => setFilter(value)}
                placeholder={t('global.search.by.name')}
                test={dataTestIds.page.learn.ownLessons.search}
              />
            )}
          </div>
          <div className="mt-4 flex-grow">
            <OwnContentOverview
              itemInfo={itemInfo}
              lessons={lessons}
              openCalendarModal={setIsOpenModalCalendarItem}
              openQuickPlanModal={setIsOpenQuickPlanModal}
              pageTitle={pageTitle}
              setItemInfo={setItemInfo}
            />
          </div>

          {Boolean(lessons && lessons.length) && (
            <Pagination
              className="mt-12"
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={(page) => setPage(page)}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
        </ListSkeleton>
      </div>
    ),
    [
      isLoading,
      lessons,
      isFiltering,
      t,
      itemInfo,
      pageTitle,
      currentPage,
      totalPages,
      totalItems,
      itemsPerPage,
      setItemsPerPage,
      setFilter,
      setPage,
    ],
  );

  return (
    <Content hasPaddingBottom>
      {isTeacher && (
        <>
          <CalendarItemDialog
            calendarModalOption={{
              isOpenModalCalendarItem,
              setIsOpenModalCalendarItem,
            }}
            classGroups={classGroups}
            getCalendarItems={() => true} // not needed in this context
            itemInfo={itemInfo}
            targetLink={pathname}
            updateItemOption={{ isUpdateMode, setIsUpdateMode }}
          />
          {selectedPeriodIsActivePeriod && (
            <QuickPlanDialog
              hasPlanButton={organization.showCalendar}
              isOpen={isOpenQuickPlanModal}
              itemInfo={itemInfo}
              onClose={() => {
                setIsOpenQuickPlanModal(false);
                setItemInfo({});
              }}
              openCalendarModal={() => setIsOpenModalCalendarItem(true)}
              setItemInfo={setItemInfo}
            />
          )}
        </>
      )}

      {stableChild}
    </Content>
  );
}
