import { gql } from 'graphql-request';

export const ADMIN_DELETE_CHAPTER = gql`
  mutation adminDeleteChapter($id: Int!) {
    adminDeleteChapter(id: $id) {
      statusCode
      id
      message
    }
  }
`;
