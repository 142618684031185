import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import { EyeIcon, PencilIcon, UserMinusIcon } from '@heroicons/react/24/solid';
import queryString from 'query-string';
import { dataTestIds } from 'utils/dataTestIds';

export function routeToUserProfilePage(userId) {
  return generatePath(URLS.USER_PROFILE, {
    userId,
  });
}

export function getInitialActionsForUser(searchParams, setUserData, t, user) {
  return [
    {
      icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.view'),
      onClick: () => {
        setUserData(user);
      },
      href: routeToUserProfilePage(user.id),
      search: queryString.stringify(searchParams),
      testName: dataTestIds.page.manage.users.actions.view,
    },
  ];
}

export function addActionsIfNotFtrprfSchoolAdmin(
  openDisableUserModal,
  openResetModal,
  possibleActions,
  selectedPeriodIsActivePeriod,
  setUserData,
  t,
  user,
) {
  if (user.isFtrprfUser) {
    possibleActions.push({
      icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.edit_password'),
      onClick: () => {
        setUserData(user);
        openResetModal();
      },
      testName: dataTestIds.page.manage.users.actions.editPassword,
    });
  }

  if (user.enabled && selectedPeriodIsActivePeriod) {
    possibleActions.push({
      disabled: user.isSmartschoolUser,
      icon: <UserMinusIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.deactivate'),
      onClick: () => {
        setUserData(user);
        openDisableUserModal();
      },
      testName: dataTestIds.page.manage.users.actions.disable,
    });
  }
}
