import { CurrentStep } from './CurrentStep';
import { CompleteStep } from './CompleteStep';
import { DefaultStep } from './DefaultStep';
import { ArrowSeparator } from './ArrowSeparator';

export function StepItem({
  isComplete,
  isCurrentStep,
  isNotLastStep,
  onClick,
  step,
}) {
  const isDefaultStep = !isComplete && !isCurrentStep;

  return (
    <li key={step.name} className="relative md:flex md:flex-1">
      {isComplete && !isCurrentStep && (
        <CompleteStep onClick={onClick} step={step} />
      )}
      {isCurrentStep && <CurrentStep onClick={onClick} step={step} />}
      {isDefaultStep && <DefaultStep onClick={onClick} step={step} />}

      {/* Arrow separator for lg screens and up */}
      {isNotLastStep && <ArrowSeparator />}
    </li>
  );
}
