import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import { ItemTitle } from './ItemTitle';

export function TertiaryDisplay({ item, setMainDisplay }) {
  const t = useFormatMessage();

  return (
    <div
      className="flex flex-col gap-5 overflow-auto"
      data-test={dataTestIds.multiScreen.tertiaryDisplay}
    >
      <div className="flex justify-between gap-2">
        <ItemTitle item={item} />
        <button
          data-test={dataTestIds.multiScreen.makeMainDisplay}
          onClick={() => setMainDisplay(item.id)}
          type="button"
        >
          <span className="sr-only">
            {t('multi-screen-mode.make-main-display')}
          </span>
          <ArrowsPointingOutIcon className="w-4" />
        </button>
      </div>
      {item.Component}
    </div>
  );
}
