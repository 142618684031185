import { gql } from 'graphql-request';

export const ADMIN_UPDATE_PROGRAM = gql`
  mutation adminUpdateProgram(
    $programId: Int!
    $input: ProgramInput
    $file: Upload
  ) {
    adminUpdateProgram(programId: $programId, input: $input, file: $file) {
      id
      name
      type
    }
  }
`;
