import {
  Dialog,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';
import { MicrosoftButton } from 'components/Buttons/MicrosoftButton';
import useFormatMessage from 'hooks/useFormatMessage';
import { useLocation } from 'react-router-dom';

export default function TeamsAuthenticationDialog({
  hasCloseButton,
  isOpen,
  onDismiss,
}) {
  const t = useFormatMessage();
  const title = t('teams.login.title');
  const { pathname } = useLocation();

  return (
    <Dialog aria-label={title} isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader hasCloseButton={hasCloseButton} onDismiss={onDismiss}>
        {title}
      </DialogHeader>

      <DialogContent className="my-8">
        <p className="mb-8 text-center">{t('teams.login.explanation')}</p>
        <MicrosoftButton targetLink={pathname} />
      </DialogContent>
    </Dialog>
  );
}
