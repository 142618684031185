import { useContext } from 'react';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { StudioButton } from './StudioButton';

export function ChapterItemEmptyState({ ownContent }) {
  const { isStudent } = useContext(UserContext);
  const t = useFormatMessage();
  const noItemsMessage = t(
    `curricula.LESSON.content-overview.${
      isStudent ? 'STUDENT' : 'TEACHER'
    }.empty`,
  );

  if (ownContent) {
    return (
      <div className="text-center">
        <p className="mt-6">
          {t('curricula.lessons.own.none')}
          <br />
          {t('curricula.lessons.own.create')}
        </p>
        <div className="mt-6">
          <StudioButton notInHeader />
        </div>
      </div>
    );
  }

  return <p>{noItemsMessage}</p>;
}
