import { toast } from 'react-toastify';

import useFormatMessage from 'hooks/useFormatMessage';

import titleCase from 'utils/titleCase';

const TOASTIFYTYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warn',
  DEFAULT: 'default',
};

export const TOASTTYPES = {
  /*
    difference with the other values below:
    These values offer more flexibility, by allowing us set any string in our notification.
    Whilst below, they extend a predefined string.

    //---- example usage ----//

    toast(TOASTTYPES.SUCCESS, {
      str: t('sendToSkore.success'),
    });
  */
  NOTIFICATION: {
    toastifyType: TOASTIFYTYPE.DEFAULT,
    autoClose: false,
  },
  SUCCESS: {
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  ERROR: {
    toastifyType: TOASTIFYTYPE.ERROR,
  },

  /*
    These values come pre-filled with a string, to which additional information can be attached.
    They are designed to be standalone and are not intended to be combined with additional values.
  */

  // SUCCESS
  ACTIVATESUCCESS: {
    type: 'global.activate.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  ADDSUCCESS: {
    type: 'global.add.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  ARCHIVESUCCESS: {
    type: 'global.archive.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  CREATESUCCESS: {
    type: 'global.create.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  DEACTIVATESUCCESS: {
    type: 'global.deactivate.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  DELETESUCCESS: {
    type: 'global.delete.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  DISABLESUCCESS: {
    type: 'global.disable.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  ENABLESUCCESS: {
    type: 'global.enable.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  MOVESUCCESS: {
    type: 'global.move.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  POSTSUCCESS: {
    type: 'global.post.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  UPDATESUCCESS: {
    type: 'global.update.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },
  REMOVESUCCESS: {
    type: 'global.remove.success',
    toastifyType: TOASTIFYTYPE.SUCCESS,
  },

  // ERRORS
  ACTIVATEERROR: {
    type: 'global.activate.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  ADDERROR: {
    type: 'global.add.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  ARCHIVEERROR: {
    type: 'global.archive.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  CREATEERROR: {
    type: 'global.create.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  DEACTIVATEERROR: {
    type: 'global.deactivate.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  DELETEERROR: {
    type: 'global.delete.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  DISABLEERROR: {
    type: 'global.disable.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  ENABLEERROR: {
    type: 'global.enable.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  GETERROR: {
    type: 'global.get.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  MOVEERROR: {
    type: 'global.move.error',
    toastifyType: TOASTIFYTYPE.ERROR,
    autoClose: false,
  },
  NOTFOUND: {
    type: 'global.not_found.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  NOUSERSWARNING: {
    type: 'global.no.users.warning',
    toastifyType: TOASTIFYTYPE.WARNING,
    autoClose: false,
  },
  POSTERROR: {
    type: 'global.post.error',
    toastifyType: TOASTIFYTYPE.ERROR,
    autoClose: false,
  },
  REMOVEERROR: {
    type: 'global.remove.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
  UPDATEERROR: {
    type: 'global.update.error',
    toastifyType: TOASTIFYTYPE.ERROR,
  },
};

/*
  TODO: possibly the isLoading toast could use toast.loading
  POSSIBLE FUTURE TODO: add undo functionality in the toast?
*/
/**
 * @param  {TOASTTYPES} type creates toast of defined type TOASTTYPES, otherwise normal toast
 * @param  {object} options object fields: { str, toastId }
 */
export default function useToast() {
  const t = useFormatMessage();

  const response = (
    { autoClose = 8000, toastifyType = TOASTIFYTYPE.DEFAULT, type },
    { str, toastId },
  ) => {
    toast[toastifyType](type ? titleCase(t(type, { what: str })) : str, {
      toastId,
      autoClose,
    });
  };

  return response;
}
