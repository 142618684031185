import confetti from 'canvas-confetti';

export function removeIdFromArray(ids, idToRemove) {
  return [...ids.filter((id) => id !== idToRemove)];
}

export function getAnswersForQuestion(
  questionToGetAnswersFor,
  submittedQuestionAnswers,
) {
  return submittedQuestionAnswers?.filter(
    (answer) =>
      answer.questionId.toString() === questionToGetAnswersFor.id.toString(),
  )?.[0]?.questionAnswersMultipleChoice;
}

export function getCorrectAnswersForQuestion(answers) {
  return answers?.filter((answer) => answer.correct).map((answer) => answer.id);
}

export function getUserAnswers(answers) {
  return answers?.map((answer) => answer.userAnswers?.[0]);
}

export function showSuccessAnimationIfAllIsCorrect(
  questions,
  answers,
  canOverrideAnswers,
) {
  const allCorrectItemIds = questions.questionAnswersMultipleChoice
    .filter((i) => i.correct)
    .map((i) => i.id.toString());

  const allChosenItemIds = answers.map((a) => a.toString());

  const allChosenWrongItemIds = allChosenItemIds.filter(
    (id) => !allCorrectItemIds.includes(id),
  );

  // user has chosen one or more wrong items
  if (allChosenWrongItemIds.length > 0) {
    return;
  }

  const allChosenCorrectItemIds = allChosenItemIds.filter((id) =>
    allCorrectItemIds.includes(id),
  );

  if (allChosenCorrectItemIds.length === allCorrectItemIds.length) {
    if (!canOverrideAnswers) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        startVelocity: 60,
        ticks: 70,
        gravity: 2,
      });
    }
  }
}

export function getAnswerCopy(answer, answersForThisQuestion, answerId) {
  const answerCopy = { ...answer };

  if (answersForThisQuestion?.length) {
    const fullResult = answersForThisQuestion.filter(
      (answer) => answer.id === answerId,
    )?.[0];
    answerCopy.correct = Boolean(fullResult?.correct);
    answerCopy.explanation = fullResult?.explanation;
  }

  return answerCopy;
}
