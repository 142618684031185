/* eslint-disable react/no-unstable-nested-components */

import {
  ActionStyledAsButton,
  CheckBox,
  SelectOrClearAllRow,
} from '@ftrprf/tailwind-components';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { EmptyState } from 'components/EmptyState';
import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { useSchooladminSyncTeamsClassGroups } from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminSyncTeamsClassGroups';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import titleCase from 'utils/titleCase';
import { dateFormat } from 'utils/timeHelpers';
import { Column, Table } from 'react-virtualized';
import { useRef } from 'react';
import 'react-virtualized/styles.css';

export function SyncClassesTable({
  classGroups,
  isLoading,
  refetch,
  setClassGroups,
}) {
  const t = useFormatMessage();
  const hasSelectedClasses =
    classGroups?.filter((group) => group.isChecked).length > 0;
  const {
    isLoading: isUpdatingTeamsClassGroups,
    mutateAsync: updateTeamsClassGroups,
  } = useSchooladminSyncTeamsClassGroups();
  const container = useRef();
  const containerWidth = container.current?.offsetWidth;

  return (
    <div ref={container}>
      {!isLoading && !Boolean(classGroups?.length) && (
        <EmptyState textKey="synchronize.noneFound" />
      )}
      <TableSkeleton loading={isLoading}>
        {Boolean(containerWidth) && (
          <Table
            className="min-w-full divide-gray-200 border border-b-0 border-gray-200"
            headerClassName="bg-gray-50 border-b"
            headerHeight={41}
            height={600}
            rowClassName="border-b"
            rowCount={classGroups.length}
            rowGetter={({ index }) => classGroups[index]}
            rowHeight={57}
            // removing the "-2" makes the table expand for 2px every render
            width={containerWidth - 2}
          >
            <Column
              cellRenderer={({ rowIndex }) => {
                const classGroup = classGroups[rowIndex];
                return (
                  <CheckBox
                    checked={classGroup?.isChecked}
                    className="mx-4"
                    classNameLabel="sr-only"
                    data-test={classGroup.externalId}
                    label={classGroup?.name}
                    onChange={() => {
                      const newClassGroups = [...classGroups];
                      newClassGroups[rowIndex].isChecked =
                        !classGroup?.isChecked;
                      setClassGroups(newClassGroups);
                    }}
                  />
                );
              }}
              className="pr-6 py-4 whitespace-nowrap w-auto"
              dataKey="checkbox"
              headerClassName="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider mr-0"
              label=""
              width={50}
            />
            <Column
              className="px-6 py-4 whitespace-nowrap w-auto"
              dataKey="name"
              flexGrow={1}
              headerClassName="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider mr-0"
              label={t('global.name')}
              width={200}
            />

            <Column
              cellRenderer={({ cellData }) =>
                Boolean(cellData)
                  ? dateFormat(cellData.slice(0, -10), true)
                  : '-'
              }
              className="px-6 py-4 whitespace-nowrap w-auto"
              dataKey="lastSyncedOn"
              flexGrow={1}
              headerClassName="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider mr-0"
              label={t('synchronize.lastSynchronized')}
              width={100}
            />
          </Table>
        )}
      </TableSkeleton>
      {!isLoading && Boolean(classGroups?.length) && (
        <SelectOrClearAllRow
          allButtonText={t('synchronize.checkAll')}
          clearButtonText={t('synchronize.clearAll')}
          onClickAll={() => {
            const newClassGroups = [...classGroups];
            newClassGroups.forEach((classGroup) => {
              // eslint-disable-next-line no-param-reassign
              classGroup.isChecked = true;
            });
            setClassGroups(newClassGroups);
          }}
          onClickClear={() => {
            const newClassGroups = [...classGroups];
            newClassGroups.forEach((classGroup) => {
              // eslint-disable-next-line no-param-reassign
              classGroup.isChecked = false;
            });
            setClassGroups(newClassGroups);
          }}
          showClearButton={hasSelectedClasses}
        />
      )}

      {!isLoading && hasSelectedClasses && (
        <p className="mt-8">
          <ActionStyledAsButton
            disabled={isUpdatingTeamsClassGroups}
            iconBefore={ArrowPathIcon}
            onClick={() => {
              const teamsClassGroups = classGroups
                .filter((group) => group.isChecked)
                .map((group) => ({
                  classGroupName: group.name,
                  externalClassGroupId: group.externalId,
                }));

              updateTeamsClassGroups({
                teamsClassGroups,
              }).then(() => refetch());
            }}
            test={dataTestIds.page.manage.button.sync.teams}
          >
            {titleCase(t('synchronize.selected'))}
          </ActionStyledAsButton>
        </p>
      )}
    </div>
  );
}
