import { gql } from 'graphql-request';

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus(
    $id: Int!
    $canceled: Boolean!
    $snoozeUntil: String
  ) {
    updateNotificationStatus(
      id: $id
      canceled: $canceled
      snoozeUntil: $snoozeUntil
    ) {
      statusCode
      id
      message
    }
  }
`;
