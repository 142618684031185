import useFormatMessage from 'hooks/useFormatMessage';

export function NoNotifications() {
  const t = useFormatMessage();

  return (
    <li>
      <div className="rounded py-5 px-4">
        <h2 className="font-semibold text-gray-800">
          {t('profile.notifications.empty')}
        </h2>
      </div>
    </li>
  );
}
