export const filterEventsBySelectedClasses = (events, selectedClasses) =>
  events?.filter((event) => {
    const eventClassNames = event.classGroups?.map((item) => item.name);
    const selectedClassesNames = selectedClasses?.map(
      (selectedClass) => selectedClass.name,
    );
    return selectedClassesNames?.some(
      (item) => eventClassNames?.indexOf(item) >= 0,
    );
  });

export const filterEventsBySelectedTeachers = (events, selectedTeachers) => {
  const teacherIds = selectedTeachers.map((teacher) => teacher.id);

  return events.filter((event) => teacherIds.includes(event.userId));
};

export const filterEventsBySelectedClassesAndTeachers = (
  events,
  selectedClasses,
  selectedTeachers,
) => {
  const eventsFilteredByClassNames = filterEventsBySelectedClasses(
    events,
    selectedClasses,
  );

  return filterEventsBySelectedTeachers(
    eventsFilteredByClassNames,
    selectedTeachers,
  );
};
