import { useEffect, useState } from 'react';
import ClampLines from 'react-clamp-lines';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

// eslint-disable-next-line import/no-cycle
import { processor } from '../../../slideviewer/processor';
import useFormatMessage from '../../../../hooks/useFormatMessage';

import c from '../../../../utils/c';

/**
 * A helper funtion to get the string respresentation for a multipleChoiceAnswerCard at a given index
 *
 * @param {number} index
 * @returns {string} The string representation of the index
 */
const indexToUniqueString = (index) => {
  const numberOfLettersInAlphabet = 1 + 'Z'.charCodeAt(0) - 'A'.charCodeAt(0);
  return String.fromCharCode(
    (index % numberOfLettersInAlphabet) + 'A'.charCodeAt(0),
  ).repeat(1 + index / numberOfLettersInAlphabet);
};

export default function SlideViewerMultipleChoiceAnswerCard({
  answer,
  disabled,
  hasResults,
  index,
  isClicked,
  onClick = () => {},
  selectAnswer,
}) {
  const t = useFormatMessage();

  const [clicked, setClicked] = useState(isClicked);

  const clickAnswer = () => {
    if (!hasResults) {
      setClicked(!clicked);
      selectAnswer();
      onClick();
    }
  };

  useEffect(() => {
    setClicked(isClicked);
  }, [isClicked]);

  let resultClassColor = hasResults ? 'bg-green-500' : 'bg-accent-500';
  if (hasResults && !answer.correct) resultClassColor = 'bg-red-500';

  let resultIcon = hasResults ? (
    <CheckIcon className="w-8 h-8" />
  ) : (
    indexToUniqueString(index)
  );
  if (hasResults && !answer.correct)
    resultIcon = <XMarkIcon className="w-8 h-8" />;

  return (
    <div className="SlideViewerMultipleChoiceAnswerCard flex flex-col flex-1">
      <div
        className={c(
          'rounded text-gray-100 flex items-center justify-center self-end -mb-3 w-8 h-8 font-bold text-lg z-10 mr-2',
          resultClassColor,
        )}
      >
        <p style={{ margin: '0' }}>{resultIcon}</p>
      </div>
      <div
        className={c(
          'appearence-none focus:outline-none flex flex-col flex-grow rounded p-4 border-solid border-2 shadow-base bg-white',
          disabled && 'cursor-not-allowed',
          hasResults && 'cursor-default',
          !hasResults && 'cursor-pointer',
          hasResults &&
            (answer.correct ? 'border-green-500' : 'border-red-500'),
          !hasResults &&
            (clicked
              ? 'border-accent-500'
              : 'hover:border-gray-200 border-transparent'),
        )}
        onClick={clickAnswer}
      >
        <div
          className={c(
            'break-words w-full overflow-hidden text-lg text-center',
          )}
        >
          {processor.processSync(answer.value).result.props.children}
        </div>
        {answer.explanation && hasResults && (
          <div
            className={c(
              'mt-5 flex-grow overflow-hidden w-full break-words text-sm text-gray-600 text-left',
            )}
          >
            <ClampLines
              ellipsis="..."
              id={answer.id}
              innerElement="p"
              lessText={t(
                'styled-slide-viewer.multiplechoiceanswercard.explanation_less',
              )}
              lines={2}
              moreText={t(
                'styled-slide-viewer.multiplechoiceanswercard.explanation_more',
              )}
              text={answer.explanation ?? ''}
            />
          </div>
        )}
      </div>
    </div>
  );
}
