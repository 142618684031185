import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import {
  DialogActions,
  DialogContent,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';

import { DroppableGroup } from './Groups/DroppableGroup';
import { createRandomGroups } from './Groups/helperFunctions/createRandomGroups';
import { locationDidNotChange } from './Groups/helperFunctions/locationDidNotChange';
import { moveMemberToNewGroup } from './Groups/helperFunctions/moveMemberToNewGroup';
import { randomizeRolesForGroups } from './Groups/helperFunctions/randomizeRolesForGroups';
import { wasDraggedToInvalidDestination } from './Groups/helperFunctions/wasDraggedToInvalidDestination';
import { ShuffleButton } from './Groups/ShuffleButton';

export function Groups({
  amount,
  groupType,
  members,
  onDismiss,
  roles,
  steamsGroups,
  submit,
}) {
  const t = useFormatMessage();
  const [groups, setGroups] = useState([]);
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (wasDraggedToInvalidDestination(destination)) return;
    if (locationDidNotChange(destination, source)) return;

    // deep copy of the groups, else they won't rerender (use-effect)
    const newGroups = JSON.parse(JSON.stringify(groups));

    moveMemberToNewGroup(result, newGroups);

    // persist the change
    setGroups(newGroups);
  };

  useEffect(() => {
    const groupsToRandomize = steamsGroups?.length
      ? steamsGroups
      : createRandomGroups(members, amount);
    setGroups(randomizeRolesForGroups(groupsToRandomize, roles));
  }, [amount, members, roles, steamsGroups]);

  return (
    <>
      <DialogContent className="m-4">
        <div className="flex justify-items-start my-4">
          {groupType === 'random' ? (
            <ShuffleButton
              className="mr-6"
              onClick={() => {
                setGroups(
                  randomizeRolesForGroups(
                    createRandomGroups(members, amount),
                    roles,
                  ),
                );
              }}
              textKey="groups.compose.randomize.members"
            />
          ) : null}
          {roles.length ? (
            <ShuffleButton
              onClick={() => {
                setGroups(randomizeRolesForGroups(groups, roles));
              }}
              textKey="groups.compose.randomize.roles"
            />
          ) : null}
        </div>
        <div
          className={c(
            'grid grid-cols-1 gap-8 min-w-96',
            groups.length > 1 && 'md:grid-cols-2',
          )}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {groups.map((group, index) => {
              const key = `dg${index}${Date.now()}`;
              return (
                <DroppableGroup
                  key={key}
                  index={index}
                  members={group}
                  roles={roles}
                />
              );
            })}
          </DragDropContext>
        </div>
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton onClick={() => submit(groups)} type="submit">
          {t('groups.compose.save')}
        </ActionStyledAsButton>
      </DialogActions>
    </>
  );
}
