import { useEffect, useRef } from 'react';

export default function useScrollTopRefRef(trigger) {
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [scrollRef, trigger]);

  return scrollRef;
}
