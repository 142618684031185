import { gql } from 'graphql-request';

export const TEACHER_CLASSGROUPS = gql`
  query teacherClassGroups(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherClassGroups(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        id
        name
        archived
        amountOfStudents
      }
    }
  }
`;
