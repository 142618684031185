import { ErrorBoundaryWrapper } from 'components/ErrorBoundaryWrapper';
import Header from 'components/Header/Header';

export default function GeneralLayout({ children }) {
  return (
    <div className="w-full h-screen">
      <main className="h-full">
        <Header />
        <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>
      </main>
    </div>
  );
}
