import { OWN_CONTENT } from 'utils/constants/contentTypes';
import { SelectProgram } from './LessonInfo/SelectProgram';
import { SelectChapter } from './LessonInfo/SelectChapter';
import { SelectChapterItem } from './LessonInfo/SelectChapterItem';
import { SelectLesson } from './LessonInfo/SelectLesson';

export function LessonInfo({
  findChapterItems,
  findChapters,
  findLessons,
  formOption,
  itemInfo,
  ownContent,
  setIsExam,
  teacherPrograms,
}) {
  const { control, errors, setValue, watch } = formOption;
  const watcherProgram = watch('program');
  const watcherChapter = watch('chapter');
  const watcherChapterItem = watch('chapterItem');
  const showLessonDropdown =
    (!itemInfo?.chapterItem && Boolean(watcherChapter?.value)) ||
    (!itemInfo?.chapterItem && watcherProgram?.value === OWN_CONTENT);

  return (
    <>
      <SelectProgram
        control={control}
        errors={errors}
        ownContent={ownContent}
        setValue={setValue}
        teacherPrograms={teacherPrograms}
      />

      {!itemInfo?.chapterItem &&
        watcherProgram &&
        watcherProgram.value !== OWN_CONTENT && (
          <SelectChapter
            control={control}
            errors={errors}
            findChapters={findChapters}
            program={watcherProgram.value}
            setValue={setValue}
          />
        )}

      {showLessonDropdown && (
        <SelectChapterItem
          chapter={watcherChapter?.value}
          control={control}
          errors={errors}
          findChapterItems={findChapterItems}
          ownContent={ownContent}
          program={watcherProgram.value}
          setIsExam={setIsExam}
          setValue={setValue}
        />
      )}
      {watcherChapterItem &&
        findLessons(watcherChapterItem.value)?.length > 1 && (
          <SelectLesson
            chapterItem={watcherChapterItem.value}
            control={control}
            errors={errors}
            findLessons={findLessons}
            setIsExam={setIsExam}
            setValue={setValue}
          />
        )}
    </>
  );
}
