import { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/solid';

import { UserContext } from 'providers/UserProvider';

import c from 'utils/c';
import { lessonTypes } from 'utils/constants/lessonTypes';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';
import { pSBC } from 'utils/newShade';
import { isEventClosed } from '../utils/isEventClosed';

export default function CalendarEvent({ event }) {
  const { isStudent, isTeacher, settings } = useContext(UserContext);
  const original = event?.original;
  const shouldBeProjection =
    isTeacher && original.lessonContentType !== lessonTypes.EXAM;
  const shouldGenerateCyLink =
    original &&
    original.chapterItem?.studioId &&
    original?.calendarItem?.sessionId;

  const isClosed = isEventClosed(
    original?.calendarItem?.closedAfterEndTime,
    event.endDate,
  );

  return (
    <div
      className="md-custom-event-cont"
      data-eventstartdate={event.startDate.toDateString()}
    >
      <div
        className={c(
          'md-custom-event-wrapper',
          isClosed && 'md-custom-event-wrapper-closed',
          isClosed && isStudent && 'cursor-not-allowed',
        )}
      >
        {event?.color && (
          <div
            className="h-2 rounded-lg"
            style={{ backgroundColor: event?.color?.toLowerCase() }}
          />
        )}
        <div
          className="p-2 rounded border border-gray-200"
          style={{ height: '100%' }}
        >
          <div className="font-bold text-black flex gap-1">
            {isClosed && (
              <div className="flex-none mt-1">
                <LockClosedIcon className="w-3" />
              </div>
            )}
            <h2
              className="overflow-hidden text-ellipsis whitespace-nowrap"
              title={event.title}
            >
              {event.title}
            </h2>
          </div>
          <div
            className={c(isClosed && 'brightness-50')}
            style={{ color: pSBC(-0.4, event.color?.toLowerCase()) }}
          >
            {original?.classGroups
              ?.map((classGroup) => classGroup.name)
              .join(', ')}
          </div>
          <div
            className="text-gray-400 text-sm"
            data-cylink={
              shouldGenerateCyLink &&
              generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
                sessionId: original?.calendarItem?.sessionId,
                studioId: original?.chapterItem?.studioId,
                viewMode: shouldBeProjection
                  ? settings?.slideViewer?.viewMode ?? viewModes.PROJECTION
                  : original?.viewMode ?? viewModes.CLASSICAL,
              })
            }
          >
            {event.start?.split(' ')[0]}-{event.end?.split(' ')[0]}
          </div>
        </div>
      </div>
    </div>
  );
}
