export function validateEndTimeHours(
  endTimeHour,
  endTimeMinute,
  startTimeHour,
  startTimeMinute,
) {
  if (!endTimeHour || !endTimeMinute || !startTimeHour || !startTimeMinute) {
    return false; // false == validate fails
  }

  const isEndHourAfterStartHour = endTimeHour.label > startTimeHour.label;
  if (isEndHourAfterStartHour) return true;

  const isEndHourBeforeStartHour = endTimeHour.label < startTimeHour.label;
  if (isEndHourBeforeStartHour) return false;

  // start and end hour are the same
  const isEndTimeMinuteBeforeStartTimeMinute =
    endTimeMinute.label < startTimeMinute.label;
  return !isEndTimeMinuteBeforeStartTimeMinute;
}
