import { useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Actions,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Label,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';
import CreatableSelect from 'react-select/creatable';

import useFormatMessage from 'hooks/useFormatMessage';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useAdminUnlinkTag from 'hooks/api/plannerService/admin/mutations/useAdminUnlinkTag';
import useAdminLinkTag from 'hooks/api/plannerService/admin/mutations/useAdminLinkTag';
import { debounce } from 'lodash-es';
import useAdminTags from 'hooks/api/plannerService/admin/queries/useAdminTags';
import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import { dataTestIds } from 'utils/dataTestIds';

export default function ManageTagOnItemDialog({
  chapter,
  chapterItem,
  isOpen,
  onDismiss,
  refetchTags,
}) {
  const item = chapter ?? chapterItem;

  const [searchText, setSearchText] = useState('');

  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchDebounced = useRef(
    debounce((searchText) => setSearchText(searchText), 250),
  ).current;

  const { data: tagsData } = useAdminTags({
    filter: [
      {
        key: 'name',
        value: searchText,
        operation: filterOperation.LIKE,
      },
    ],
    sort: { name: sortOperation.ASC },
    size: 50,
    page: 0,
  });

  const { mutateAsync: unlinkTag } = useAdminUnlinkTag();
  const { mutateAsync: linkTag } = useAdminLinkTag();

  const itemHasTags = Boolean(item && item.tags && item.tags.length > 0);
  const isChapter = chapter !== null;
  const isChapterItem = chapterItem !== null;

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setIsLoading(true);
      handleSearchDebounced(inputText);
    }
  };

  const tagActions = (tag) => [
    {
      icon: <TrashIcon className="h-4 w-4 mr-2" />,
      label: t('global.unlink.what', { what: t('global.TAG.singular') }),
      testName: dataTestIds.modal.manageTagOnItem.button.unlink,
      onClick: () => {
        const unlinkValues = { id: tag.id };

        if (isChapter) {
          unlinkValues.chapterId = item.id;
        } else if (isChapterItem) {
          unlinkValues.chapterItemId = item.id;
        }

        unlinkTag(unlinkValues).then(() => {
          setIsLoading(true);
          refetchTags().then(() => {
            setIsLoading(false);
          });
        });
      },
    },
  ];

  const dropdownOptions = useCallback(() => {
    const tags = tagsData?.content.map((tag) => ({
      value: tag.name,
      label: tag.name,
    }));

    setIsLoading(false);
    return tags;
  }, [tagsData?.content]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader>
        {t('global.link.what', { what: t('global.TAG.singular') })}
      </DialogHeader>

      <DialogContent className="flex flex-col gap-8">
        <form
          className="flex gap-2 flex-col"
          onSubmit={handleSubmit((data) => {
            const sendData = { name: data.tagName.value };

            if (isChapter) {
              sendData.chapterId = item.id;
            } else if (isChapterItem) {
              sendData.chapterItemId = item.id;
            }

            linkTag(sendData).then(() => {
              setIsLoading(true);
              refetchTags().then(() => {
                setIsLoading(false);
                reset();
              });
            });
          })}
        >
          <Label>{t('global.TAG.singular')}</Label>

          <Controller
            control={control}
            defaultValue={[]}
            name="tagName"
            render={({ field: { onBlur, onChange, value } }) => (
              <CreatableSelect
                className="react-select-container"
                classNamePrefix="react-select"
                classNames="w-full"
                inputId={dataTestIds.modal.manageTagOnItem.input.tagName}
                isLoading={isLoading}
                isSearchable
                noOptionsMessage={() => t('dropdown.no_results')}
                onBlur={onBlur}
                onChange={onChange}
                onInputChange={handleInputChange}
                options={dropdownOptions()}
                value={value}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('global.required-field'),
              },
            }}
            type="select"
          />

          {Object.entries(errors).length > 0 && (
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                {Object.entries(errors).map(([key, value]) => (
                  <li key={key}>{value.message}</li>
                ))}
              </ul>
            </div>
          )}

          <ActionStyledAsButton
            test={dataTestIds.modal.manageTagOnItem.button.link}
            type="submit"
          >
            {t('global.add.what', { what: t('global.TAG.singular') })}
          </ActionStyledAsButton>
        </form>

        <ResponsiveTable
          headers={[
            {
              key: 'name',
              label: t('global.name'),
              orderable: false,
            },
            {
              key: 'actions',
              label: t('global.actions'),
              orderable: false,
              className: 'max-w-fit sr-only',
            },
          ]}
        >
          {itemHasTags &&
            item.tags.map((tag) => (
              <ResponsiveTableRow key={tag.id}>
                <ResponsiveTableCol>
                  <Badge
                    {...{
                      [tag.color]: true,
                    }}
                  >
                    {tag.name}
                  </Badge>
                </ResponsiveTableCol>

                <ResponsiveTableCol className="p-2 text-right">
                  <Actions items={tagActions(tag)} />
                </ResponsiveTableCol>
              </ResponsiveTableRow>
            ))}
        </ResponsiveTable>
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton
          iconBefore={XMarkIcon}
          onClick={onDismiss}
          test={dataTestIds.modal.manageTagOnItem.button.close}
          variant="text"
        >
          {t('global.close')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
