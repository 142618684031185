/**
 * Calculate the new list of possible roles for the dropdowns in a member
 * row for a group.
 */
export const calculatePossibleRoles = function (
  availableOptions,
  choiceForMember,
  memberIndex,
  members,
) {
  const newAvailableOptions = [...availableOptions];
  const flattenedRoles = availableOptions.map((role) => role.role);
  const rolesForMember = [];

  members.forEach((member, index) => {
    let roles = [...member.roles];

    if (index === memberIndex) {
      choiceForMember.forEach((roleName) => {
        rolesForMember.push(
          newAvailableOptions[flattenedRoles.indexOf(roleName)],
        );
      });
      roles = [...rolesForMember];
    }

    roles.forEach((role) => {
      const indexOfItem = flattenedRoles.indexOf(role.role);

      flattenedRoles.splice(indexOfItem, 1);
      newAvailableOptions.splice(indexOfItem, 1);
    });
  });

  return {
    newAvailableOptions,
    rolesForMember,
  };
};
