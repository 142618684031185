import {
  Actions,
  ActionStyledAsButton,
  Badge,
  ConfirmationDialog,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';
import {
  BookOpenIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { dataTestIds } from 'utils/dataTestIds';
import { laptop } from 'utils/constants/mediaWidth';
import useAdminDeleteTag from 'hooks/api/plannerService/admin/mutations/useAdminDeleteTag';
import useAdminTags from 'hooks/api/plannerService/admin/queries/useAdminTags';
import useFormatMessage from 'hooks/useFormatMessage';
import { useState } from 'react';

import { filterOperation } from 'utils/constants/filter';
import { useDebounce } from '@ftrprf/hooks';
import { sortOperation } from 'utils/constants/sort';
import EditTagDialog from './partials/EditTagDialog';
import ManageItemOnTag from './partials/ManageItemOnTag';

const DIALOGS = {
  UPSERT: 'upsert',
  MANAGE: 'manage',
  DELETE: 'delete',
  NONE: null,
};

export default function ManageTags() {
  const t = useFormatMessage();
  const [openDialog, setOpenDialog] = useState(DIALOGS.NONE);
  const [tag, setTag] = useState(null);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const debouncedFilter = useDebounce(filter, 250);

  const {
    data: tagsData,
    isLoading: isLoadingTags,
    refetch: refetchTags,
  } = useAdminTags({
    filter: [
      {
        key: 'name',
        value: debouncedFilter,
        operation: filterOperation.LIKE,
      },
    ],
    sort: { name: sortOperation.ASC },
    size: itemsPerPage,
    page: currentPage - 1,
  });
  const {
    content: tags,
    pages: totalPages,
    total: totalItems,
  } = tagsData || {
    content: [],
    total: 0,
    pages: 0,
  };
  const { mutateAsync: deleteTag } = useAdminDeleteTag();

  const tagActions = (tag) => {
    const actions = [
      {
        icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.edit'),
        onClick: () => {
          setTag(tag);
          setOpenDialog(DIALOGS.UPSERT);
        },
        testName: dataTestIds.page.manage.table.button.edit,
      },

      {
        icon: <TrashIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.delete'),
        onClick: () => {
          setTag(tag);
          setOpenDialog(DIALOGS.DELETE);
        },
        testName: dataTestIds.page.manage.table.button.delete,
      },
    ];

    if (tag.chapters.length + tag.chapterItems.length > 0) {
      actions.push({
        icon: <BookOpenIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.manage.what', { what: t('global.TAG.plural') }),
        onClick: () => {
          setTag(tag);
          setOpenDialog(DIALOGS.MANAGE);
        },
      });
    }

    return actions;
  };

  const onDismiss = () => {
    setTag(null);
    setOpenDialog(DIALOGS.NONE);
  };

  return (
    <>
      <div className="w-full flex justify-between">
        <div className="justify-between w-1/2">
          <SearchBar
            className="mb-4"
            id={dataTestIds.page.manage.input.name}
            onChange={setFilter}
            placeholder={t('hack-room.what_are_you_looking_for')}
            test={dataTestIds.page.manage.input.name}
          />
        </div>
        <ActionStyledAsButton
          className="ml-auto mb-4"
          iconBefore={PlusIcon}
          onClick={() => setOpenDialog(DIALOGS.UPSERT)}
          test={dataTestIds.page.manage.button.add.tag}
        >
          {t('global.create.what', { what: t('global.TAG.singular') })}
        </ActionStyledAsButton>
      </div>
      <TableSkeleton loading={isLoadingTags}>
        <ResponsiveTable
          emptyText={
            Boolean(filter.length)
              ? t('global.empty.search', { what: t('global.TAG.plural') })
              : t('global.empty')
          }
          headers={[
            {
              key: 'name',
              label: t('global.TAG.singular'),
            },
            {
              key: 'chapter',
              label: t('global.CHAPTER.singular'),
            },
            {
              key: 'chapterItems',
              label: t('global.CHAPTERITEM.singular'),
            },
            {
              key: 'actions',
              label: t('global.actions'),
              orderable: false,
              className: 'max-w-fit sr-only',
            },
          ]}
          mobileBreakpoint={laptop}
          mobileHeaderWidth={160}
        >
          {tags?.map((tag) => (
            <ResponsiveTableRow key={tag.name + tag.color}>
              <ResponsiveTableCol>
                <Badge
                  className={`tag-color-${tag.color}`}
                  {...{
                    [tag.color]: true,
                  }}
                >
                  {tag.name || t('global.example')}
                </Badge>
              </ResponsiveTableCol>

              <ResponsiveTableCol>{tag.chapters.length}</ResponsiveTableCol>

              <ResponsiveTableCol>{tag.chapterItems.length}</ResponsiveTableCol>

              <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right justify-end">
                <Actions items={tagActions(tag)} />
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          ))}
        </ResponsiveTable>
      </TableSkeleton>

      {Boolean(tags && tags.length) && (
        <Pagination
          className="mt-12"
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={(page) => setCurrentPage(page)}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems}
          totalPages={totalPages}
        />
      )}

      <ManageItemOnTag
        isOpen={openDialog === DIALOGS.MANAGE}
        onDismiss={onDismiss}
        tag={tag}
      />
      <EditTagDialog
        isOpen={openDialog === DIALOGS.UPSERT}
        onConfirm={() => {
          refetchTags();
          onDismiss();
        }}
        onDismiss={onDismiss}
        tag={tag}
      />
      <ConfirmationDialog
        content={t('global.delete.what.confirm', {
          what: t('global.TAG.singular'),
        })}
        isOpen={openDialog === DIALOGS.DELETE}
        onConfirm={() => {
          deleteTag({ id: tag.id }).then(() => refetchTags());
          onDismiss();
        }}
        onDismiss={() => onDismiss()}
        testInfo={{
          modal: dataTestIds.component.confirmationDialog.id,
          dismiss: dataTestIds.component.confirmationDialog.button.cancel,
          confirm: dataTestIds.component.confirmationDialog.button.submit,
        }}
      />
    </>
  );
}
