import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

export default function SlideViewerProgress({ children }) {
  const { currentSlideIndex, slides } = useContext(SlideViewerContext);

  const progress =
    slides.length <= 1
      ? 100
      : Math.round((currentSlideIndex / (slides.length - 1)) * 100);

  return children({ progress });
}
