import { createOptionsFromClassGroups } from './createOptionsFromClassGroups';

export function setEventValues(setValue, itemInfo, teamsInfo) {
  const { chapter, classInfo, eventTimeInfo, selectedEvent } = itemInfo;

  // in case of drag & drop
  if (eventTimeInfo) {
    const {
      dateEvent,
      endTimeHours,
      endTimeMinutes,
      startTimeHours,
      startTimeMinutes,
    } = eventTimeInfo;

    setValue('startTimeHour', {
      value: startTimeHours,
      label: startTimeHours,
    });
    setValue('startTimeMinute', {
      value: startTimeMinutes,
      label: startTimeMinutes,
    });

    setValue('endTimeHour', {
      value: endTimeHours,
      label: endTimeHours,
    });
    setValue('endTimeMinute', {
      value: endTimeMinutes,
      label: endTimeMinutes,
    });
    setValue('dateEvent', dateEvent);
  }

  // in case it's an update
  if (selectedEvent) {
    const {
      calendarItem,
      chapterItem,
      classGroups,
      id: eventId,
      teamsCalendarItem,
    } = selectedEvent;
    const { closedAfterEndTime, lessonContentId, viewMode } = calendarItem;

    setValue('closeAfterEndTime', closedAfterEndTime);
    setValue('classGroups', createOptionsFromClassGroups(classGroups));
    setValue('idEvent', eventId);
    setValue('chapterItem', { value: chapterItem?.id });
    setValue('lessonContentId', {
      value: lessonContentId,
    });
    setValue('viewMode', viewMode);

    if (teamsCalendarItem) {
      const { addToCalendarAction, assignmentDescription, assignmentTitle } =
        teamsCalendarItem;

      setValue('sendToTeams', true);
      setValue('assignmentAddToCalendarAction', addToCalendarAction);
      setValue('assignmentDescription', assignmentDescription);
      setValue('assignmentTitle', assignmentTitle);
    } else {
      setValue('sendToTeams', teamsInfo?.sendToTeams);
    }
  }

  // in case it's via quickplan
  if (classInfo) {
    const { assignmentAddToCalendarAction, isTeams, sendToTeams } = teamsInfo;

    setValue('classGroups', [classInfo]);

    if (isTeams) {
      setValue('sendToTeams', sendToTeams);
      setValue('assignmentDescription', '');
      setValue('assignmentAddToCalendarAction', assignmentAddToCalendarAction);
      setValue('assignmentTitle', chapter?.chapterItem?.name);
    }
  }
}
