import {
  baseClassName,
  enabledClassName,
  primaryVariantClassNames,
  secondaryVariantClassNames,
  sizesClassNames,
} from './classNameHelpers';
import c from '../../../utils/c';

export function getClassNamesForAction(
  badge,
  className,
  disabled,
  disabledClassName,
  loading,
  secondary,
  size,
  variant,
) {
  let dynamicDisabledClasses = disabled ? disabledClassName : 'cursor-wait';
  if (!disabled && !loading) dynamicDisabledClasses = enabledClassName;

  return c(
    badge && 'relative',
    baseClassName,
    className,
    dynamicDisabledClasses,
    secondary
      ? secondaryVariantClassNames[variant]
      : primaryVariantClassNames[variant],
    sizesClassNames[size].button,
  );
}
