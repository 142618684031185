import { useState } from 'react';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useStudentOrganizationContent from 'hooks/api/plannerService/student/queries/useStudentOrganizationContent';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS from 'utils/constants/urls';
import { OwnLessonsOverview } from './OwnLessonsOverview';
import { filterLessonsByTitle } from './partials/filterLessonsByTitle';

export default function StudentOwnContentOverviewContainer() {
  const t = useFormatMessage();
  const [filter, setFilter] = useState();

  const { data, isLoading } = useStudentOrganizationContent();

  const filteredLessons = filterLessonsByTitle(data, filter);

  const breadcrumbsLinks = [
    { href: URLS.LEARN, name: t('curricula.overview') },
    { name: t('curricula.lessons.school') },
  ];

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        title={t('curricula.lessons.school')}
      />
      <OwnLessonsOverview
        data={filteredLessons}
        isFiltering={!!filter?.length}
        isLoading={isLoading}
        ownContent
        setFilter={setFilter}
      />
    </>
  );
}
