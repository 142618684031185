import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/solid';
import { generatePath } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/24/outline';
import { dataTestIds } from 'utils/dataTestIds';
import URLS from 'utils/constants/urls';
import {
  DropdownMenu,
  MenuButton,
  MenuLink,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { ActionsButton } from './ActionsButton';
import { AddUserButton } from './AddUserButton';
import { SyncTeamsButton } from './SyncTeamsButton';
import { SyncSmartschoolButton } from './SyncSmartschoolButton';

export function ManageUsersGlobalActions({
  openCreateUserModal,
  setIsLinkTeachersModalOpen,
}) {
  const { organization } = useContext(UserContext);
  const { isSmartschool, isTeams } = organization;
  const t = useFormatMessage();

  return (
    <div className="flex items-center justify-center">
      {!isTeams && !isSmartschool && (
        <AddUserButton openCreateUserModal={openCreateUserModal} />
      )}
      {isTeams && <SyncTeamsButton />}
      {isSmartschool && <SyncSmartschoolButton />}

      <DropdownMenu trigger={ActionsButton}>
        {({ hide }) => (
          <>
            <li>
              <MenuLink
                iconBefore={ArrowUpTrayIcon}
                test={dataTestIds.page.manage.button.extra.options.importExcel}
                to={generatePath(URLS.IMPORT)}
              >
                {t('import.excel')}
              </MenuLink>
            </li>
            <li>
              <MenuButton
                iconBefore={LinkIcon}
                onClick={() => {
                  hide();
                  setIsLinkTeachersModalOpen(true);
                }}
                secondary
                test={
                  dataTestIds.page.manage.button.extra.options
                    .linkTeachersToAllPrograms
                }
                variant="white"
              >
                {t('link-teachers-programs.button')}
              </MenuButton>
            </li>
            {(isTeams || isSmartschool) && (
              <li>
                <MenuButton
                  iconBefore={PlusIcon}
                  onClick={() => {
                    hide();
                    openCreateUserModal();
                  }}
                  secondary
                  test={dataTestIds.page.manage.button.extra.options.user}
                  variant="white"
                >
                  {t('schooladmin-overview.new-user', {
                    what: t('global.USER.singular'),
                  })}
                </MenuButton>
              </li>
            )}
          </>
        )}
      </DropdownMenu>
    </div>
  );
}
