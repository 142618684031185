export function ErrorWithTable({
  className = 'flex flex-col',
  errorWithImage,
  table,
}) {
  return (
    <div className={className}>
      {errorWithImage}
      {table}
    </div>
  );
}
