import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { STUDENT_ORGANIZATION_CONTENT } from 'api/plannerService/student/queries/studentOrganizationContent';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

import { uniqBy } from 'lodash-es';

export default function useStudentOrganizationContent(
  { enabled } = { enabled: true },
) {
  const response = useQuery(
    `studentOrganizationContent`,
    () => plannerServiceQueryClient.request(STUDENT_ORGANIZATION_CONTENT),
    { enabled },
  );

  const dataWithoutDuplicates = useMemo(() => {
    const data = response?.data?.studentOrganizationContent;
    return uniqBy(data, 'lessonContentId');
  }, [response?.data?.studentOrganizationContent]);

  return {
    ...response,
    data: dataWithoutDuplicates,
  };
}
