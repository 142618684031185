/* eslint-disable react/jsx-props-no-spreading */
import { BigHead } from '@ftrprf/bigheads-core';
import { useEffect, useRef, useState } from 'react';
import { defaultAvatar } from '../utils/constants/defaultAvatar';
import c from '../utils/c';

export function Avatar({ avatar, className = 'w-8 h-8 inline mr-2' }) {
  const ref = useRef();
  const [visible, setVisible] = useState(false);

  // Bigheads generates a svg with whitespace. This shaves the whitespace off the svg
  // Timeout because svg needs to be drawn first
  useEffect(() => {
    if (ref?.current && avatar !== undefined) {
      setTimeout(() => {
        if (ref.current) {
          const bbox = ref.current.getBBox();
          const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
          ref.current.setAttribute('viewBox', viewBox);
          setVisible(true);
        }
      }, 200);
    }
  }, [avatar]);

  return (
    <BigHead
      ref={ref}
      className={c(className, !visible && 'invisible')}
      {...(avatar ? JSON.parse(avatar) : defaultAvatar)}
    />
  );
}
