import { useEffect, useMemo, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';

import useFormatMessage from '../../hooks/useFormatMessage';

import checkIfMobile from './utils/checkIfMobile';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';

import { ResponsiveTableContext } from './ResponsiveTableContext';
import { dataTestIds } from '../../utils/constants/dataTestIds';
import c from '../../utils/c';

export function ResponsiveTable({
  children = null,
  childrenLength = null,
  className = '',
  emptyText = '',
  hasBorders = true,
  headers,
  mobileBreakpoint = null,
  mobileHeaderWidth = null,
  onRowClick = null,
  onSortChange = null,
  sort = {},
  test = '',
  turnHeaders = false,
  wrapperClassName = '',
}) {
  const [isMobile, setMobile] = useState(checkIfMobile(mobileBreakpoint));
  const [order, setOrder] = useState(sort);
  const t = useFormatMessage();
  /*
   * Filter out all falsey values, for those headers where there are conditional entries.
   * At the time of writing those are ManageUsers, ManageClasses and StudentsOverview.
   */
  const filteredHeaders = headers.filter(Boolean);
  const getOrderIcon = (header) => {
    if (!onSortChange || !header.orderable) {
      return null;
    }

    if (order[header.key] === 'DESC') {
      return (
        <button
          data-test={`sort-asc-${header.key}`}
          onClick={() => setOrder({ [header.key]: 'ASC' })}
          type="button"
        >
          <span className="sr-only">{t('sort.asc')}</span>
          <ArrowUpIcon className="ml-2 h-3 w-3 text-blue-500 block hover:text-blue-700 cursor-pointer" />
        </button>
      );
    }

    if (order[header.key] === 'ASC') {
      return (
        <button
          data-test={`sort-desc-${header.key}`}
          onClick={() => setOrder({ [header.key]: 'DESC' })}
          type="button"
        >
          <span className="sr-only">{t('sort.desc')}</span>
          <ArrowDownIcon className="ml-2 h-3 w-3 text-blue-500 block hover:text-blue-700 cursor-pointer" />
        </button>
      );
    }

    return (
      <button
        data-test={`sort-desc-${header.key}`}
        onClick={() => setOrder({ [header.key]: 'DESC' })}
        type="button"
      >
        <span className="sr-only">{t('sort.desc')}</span>
        <ArrowDownIcon className="ml-2 h-3 w-3 text-gray-300 block hover:text-gray-500 cursor-pointer" />
      </button>
    );
  };

  useEffect(() => {
    if (onSortChange && Object.keys(order).length !== 0) {
      onSortChange(order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const fullHeaders = useMemo(
    () =>
      filteredHeaders.map((header) => {
        if (typeof header === 'string' || header instanceof String) {
          return {
            key: header,
            label: header,
            orderable: true,
            className: '',
          };
        }

        const { className, extraLabel, key, label, orderable } = header;
        return {
          className: className || '',
          extraLabel,
          key,
          label: label || key,
          orderable: orderable !== undefined ? orderable : true,
        };
      }),
    [filteredHeaders],
  );

  useEffect(() => {
    const resizeHandler = () => {
      setMobile(checkIfMobile(mobileBreakpoint));
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [mobileBreakpoint]);

  const valueObject = useMemo(
    () => ({
      headers: fullHeaders,
      isMobile,
      onRowClick,
      mobileHeaderWidth,
    }),
    [fullHeaders, isMobile, mobileHeaderWidth, onRowClick],
  );

  return (
    <ResponsiveTableContext.Provider value={valueObject}>
      {isMobile ? (
        <div>
          {children &&
            children?.map((child, index) => (
              <div
                key={`${Date.now() + index}`}
                className={`${
                  hasBorders ? 'border border-gray-200' : ''
                } sm:rounded-sm mb-2 ${wrapperClassName}`}
              >
                <table className="min-w-full divide-gray-200">
                  <tbody className="bg-white">{child}</tbody>
                </table>
              </div>
            ))}
        </div>
      ) : (
        <div
          className={`${
            hasBorders ? 'border border-gray-200' : ''
          } sm:rounded-sm max-w-full ${wrapperClassName}`}
        >
          <table
            className={`min-w-full divide-gray-200 ${className ?? ''} ${
              turnHeaders && 'rc-table-header-rotated'
            }`}
            data-test={test}
          >
            <thead className="bg-gray-50">
              <tr>
                {fullHeaders.map((header, index) => (
                  <th
                    key={`header-${header.key}-${index + 1}`}
                    className={c(
                      !turnHeaders &&
                        'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                      !turnHeaders && header.className,
                      turnHeaders &&
                        index > 0 &&
                        'rc-column rc-criterion rc-rotate-45',
                    )}
                    data-test={header.key}
                    scope="col"
                  >
                    <div
                      className={
                        turnHeaders
                          ? 'bg-gray-50'
                          : c(
                              'flex',
                              header.className.includes('text-center') &&
                                'justify-center',
                            )
                      }
                    >
                      <span>
                        {header.label}
                        {header.extraLabel ? (
                          <span className="lowercase">
                            {` (${header.extraLabel}*)`}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                      {getOrderIcon(header)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-gray-200">{children}</tbody>
          </table>
        </div>
      )}

      {/* if there is nothing given to the table a message is shown */}
      {(childrenLength === 0 || !children || children?.length === 0) && (
        <div
          className="flex flex-grow w-full items-center justify-center mt-8"
          data-test={dataTestIds.component.table.empty}
        >
          {emptyText ?? <span>{t('global.empty')}</span>}
        </div>
      )}
    </ResponsiveTableContext.Provider>
  );
}

export const ResponsiveTableTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
