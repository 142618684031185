import { createContext, useCallback, useMemo, useState } from 'react';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';

export const NotificationContext = createContext({});

let index = 1;

export function NotificationContextProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((newNotification) => {
    const newEnhancedNotification = {
      id: index,
      ...newNotification,
    };
    index += 1;

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newEnhancedNotification,
    ]);

    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n !== newEnhancedNotification),
      );
    }, 5000);
  }, []);

  const removeNotification = (notification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n !== notification),
    );
  };

  const context = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
    }),
    [notifications, addNotification],
  );

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}

export const NotificationTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
