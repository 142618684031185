import useFormatMessage from 'hooks/useFormatMessage';

import { CreateExerciseButton } from './CreateExerciseButton';

export function EmptyExercises({ hasCreateExercise }) {
  const t = useFormatMessage();

  return (
    <div className="flex flex-col justify-center space-y-2">
      <span className="mt-8 text-center">
        {t('exercises-overview.no_exercises')}
      </span>
      {hasCreateExercise && <CreateExerciseButton />}
    </div>
  );
}
