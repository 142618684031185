/*
 * the below is disabled because the props on this component get used via child.props.xxx syntax,
 * which sucks monkeyballs and makes it very hard to see how we can eliminate the props-spreading
 */
/* eslint-disable react/button-has-type,react/jsx-props-no-spreading */
import { Tooltip } from '../Tooltip/Tooltip';

import c from '../../utils/c';

import { Loader } from '../Loader';

export function Button({
  badge = false,
  children = null,
  className = '',
  disabled = false,
  disabledClassName = 'opacity-40 cursor-not-allowed',
  iconAfter = null,
  iconBefore = null,
  iconBeforeSize = '',
  iconSizeClassName = 'w-3 h-3',
  loaderClassName = 'w-5 h-5',
  loading = false,
  test = '',
  title = '',
  type = 'button',
  ...props
}) {
  const IconBefore = iconBefore;
  const IconAfter = iconAfter;
  const propsToSpread = { ...props };
  delete propsToSpread.preventClose;

  // todo: when refactoring this, look at ActionStyledAsButton and whether or not we can just replace all instances
  // where this is used by that one.
  const button = (
    <button
      className={c(
        className,
        disabled ? disabledClassName : 'cursor-pointer',
        'flex items-center box-border',
        badge && 'relative',
      )}
      data-test={test}
      disabled={disabled}
      type={type}
      {...propsToSpread}
    >
      {iconBefore && (
        <span className="mr-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconBefore
              className={iconSizeClassName}
              height={iconBeforeSize}
              width={iconBeforeSize}
            />
          )}
        </span>
      )}
      {loading && !iconBefore && !iconAfter ? (
        <div className={loaderClassName}>
          <Loader />
        </div>
      ) : (
        children
      )}
      {iconAfter && (
        <span className="ml-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconAfter className={iconSizeClassName} />
          )}{' '}
        </span>
      )}
      {badge && (
        <div className="absolute top-0 right-0 text-xxs py-1 px-2 -mr-1 bg-accent-100 text-accent-600 rounded-full leading-none -mt-3 font-semibold shadow-sm">
          {badge}
        </div>
      )}
    </button>
  );

  if (title) {
    return <Tooltip label={title}>{button}</Tooltip>;
  }

  return button;
}
