import { useContext } from 'react';

import { UserContext } from 'providers/UserProvider';

import { ResultsForStudent } from './ResultsForStudent';
import { ResultsForTeacher } from './ResultsForTeacher';

export function StudentResultsForLesson() {
  const { isTeacher } = useContext(UserContext);

  return isTeacher ? <ResultsForTeacher /> : <ResultsForStudent />;
}
