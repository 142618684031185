import { useQuery } from 'react-query';

import { TEACHER_ORGANIZATION_CONTENT } from 'api/plannerService/teacher/queries/teacherOrganizationContent';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherOrganizationContent({
  enabled,
  filter,
  page,
  size,
  sort,
}) {
  const response = useQuery(
    ['teacherOrganizationContent', filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(TEACHER_ORGANIZATION_CONTENT, {
        filter,
        page,
        size,
        sort,
      }),
    {
      enabled,
      keepPreviousData: true,
    },
  );

  return {
    ...response,
    data: response?.data?.teacherOrganizationContent,
  };
}
