import { gql } from 'graphql-request';

export const HACKROOMS = gql`
  query hackRooms {
    hackRooms {
      id
      name
      type
      color
      blobUri
      chapters {
        id
        name
        programId
        blobUri
        items {
          id
          name
          position
          chapterId
          blobUri
          lessons {
            id
            title
            studioId
            version
            status
            type
            blobUri
            imageUri
            organizationId
            copiedBy {
              id
              userName
              firstName
              lastName
              fullName
              displayName
              language
              email
              organizationId
              enabled
              roles
            }
            tags
            groupRoles {
              id
              role
            }
          }
        }
      }
    }
  }
`;
