import { OutlineButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { useContext } from 'react';
import { TeamsAssignmentAddToCalendarActionOptions } from 'utils/constants/teams';
import { UserContext } from 'providers/UserProvider';

const hoverClasses = 'hover:translate-x-px hover:translate-y-px';

export function QuickPlanButton({ buttonInfo, classGroup, itemInfo }) {
  const {
    organization: { isTeams },
    settings,
  } = useContext(UserContext);
  const t = useFormatMessage();
  const borderClasses = 'border border-solid rounded';
  const otherClasses = 'transform px-4 py-1 ml-auto mr-2';
  const quickPlanClasses =
    'text-slate-800 text-center bg-white border-gray-300 text-gray-700 text-sm font-normal rounded-l-md';

  const classNames = [
    hoverClasses,
    borderClasses,
    otherClasses,
    quickPlanClasses,
  ].join(' ');

  const sendToTeams = settings?.teams?.sendToTeams ?? false;
  const assignmentAddToCalendarAction =
    settings?.teams?.assignmentAddToCalendarAction ??
    TeamsAssignmentAddToCalendarActionOptions.NONE;
  const teamsInfo = {
    assignmentAddToCalendarAction,
    isTeams,
    sendToTeams,
  };

  return (
    <OutlineButton
      className={classNames}
      custom
      data-test={buttonInfo.test}
      disabled={buttonInfo.disabled}
      onClick={() => buttonInfo.action(classGroup, itemInfo, teamsInfo)}
    >
      {t(buttonInfo.text)}
    </OutlineButton>
  );
}

export function DoubleQuickPlanButton({
  classGroup,
  firstButtonInfo,
  itemInfo,
  secondButtonInfo,
}) {
  const {
    organization: { isTeams },
    settings,
  } = useContext(UserContext);
  const t = useFormatMessage();
  const otherClasses = 'transform cursor-pointer box-border';

  const classNames = [
    'text-slate-800 text-center',
    hoverClasses,
    otherClasses,
  ].join(' ');

  const sendToTeams = settings?.teams?.sendToTeams ?? false;
  const assignmentAddToCalendarAction =
    settings?.teams?.assignmentAddToCalendarAction ??
    TeamsAssignmentAddToCalendarActionOptions.NONE;
  const teamsInfo = {
    assignmentAddToCalendarAction,
    isTeams,
    sendToTeams,
  };

  return (
    <div className="flex items-center justify-end mr-2">
      <OutlineButton
        className={`border border-solid px-4 py-1 rounded-l border-gray-300 text-gray-700 text-sm font-normal ${classNames}`}
        custom
        disabled={firstButtonInfo.disabled}
        onClick={() => firstButtonInfo.action(classGroup, itemInfo, teamsInfo)}
      >
        {t(firstButtonInfo.text)}
      </OutlineButton>
      <OutlineButton
        className={`border border-solid border-l-0 px-4 py-1 rounded-r border-gray-300 text-gray-700 text-sm font-normal ${classNames}`}
        custom
        disabled={secondButtonInfo.disabled}
        onClick={() => secondButtonInfo.action(classGroup, itemInfo, teamsInfo)}
      >
        {t(secondButtonInfo.text)}
      </OutlineButton>
    </div>
  );
}
