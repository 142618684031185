import { useEffect, useState, createElement } from 'react';

import parsePlugin from 'rehype-parse';
import rehypeReact from 'rehype-react';
import unified from 'unified';

import { ActionStyledAsButton } from '../../buttons/ActionStyledAsButton';
import { Dialog, DialogContent, DialogHeader } from '../../dialogs';
// eslint-disable-next-line import/no-cycle
import SlideViewerBaseSlide from '../SlideViewerBaseSlide';

// eslint-disable-next-line import/no-cycle
import Link from './Link';

const processor = unified()
  .use(parsePlugin, { fragment: true })
  .use(rehypeReact, {
    createElement,
    components: { a: Link },
  });
export default function ModalButton({
  children,
  className,
  label,
  modalcontent,
  modalsize,
  modaltitle,
}) {
  const [isModalOpen, setIsModalOpen] = useState();

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsModalOpen(false);
    }
  };

  const clickAutoClose = (event) => {
    if (
      event.toElement == document.querySelector('[data-reach-dialog-overlay]')
    ) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keyup', escFunction);
      document.addEventListener('mousedown', clickAutoClose);
    } else {
      document.removeEventListener('keyup', escFunction);
      document.removeEventListener('mousedown', clickAutoClose);
    }
  }, [isModalOpen]);

  if (!modaltitle) {
    return (
      <button className={className} type="button">
        {children}
      </button>
    );
  }

  let sizeClass = modalsize === 'S' ? 'w-3/12' : 'w-9/12';
  if (modalsize === 'M') sizeClass = 'w-6/12';

  return (
    <>
      {isModalOpen && (
        <Dialog className={sizeClass} modal>
          <DialogHeader hasCloseButton onDismiss={() => setIsModalOpen(false)}>
            {modaltitle}
          </DialogHeader>
          <DialogContent>
            <SlideViewerBaseSlide processor={processor} value={modalcontent} />
          </DialogContent>
        </Dialog>
      )}
      <ActionStyledAsButton
        className={className}
        onClick={() => setIsModalOpen(true)}
        variant="text"
      >
        {label}
      </ActionStyledAsButton>
    </>
  );
}
