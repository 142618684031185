import { generatePath, Outlet } from 'react-router-dom';
import {
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Stats } from '@ftrprf/tailwind-components';
import { useMemo } from 'react';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import useAdminOrganizationById from 'hooks/api/plannerService/admin/queries/useAdminOrganizationById';
import useCurrentTab from 'hooks/useCurrentTab';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';
import useTitle from 'hooks/useTitle';
import ftrprfUserRoles from 'utils/constants/ftrprfUserRoles';
import URLS, { organizationTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { removeLeadingAndTrailingSlash } from 'utils/removeLeadingAndTrailingSlash';
import { getCurrentPeriod } from 'utils/getCurrentPeriod';

export default function OrganizationProfile() {
  const t = useFormatMessage();
  const { organizationId } = useParams();

  const currentTab = useCurrentTab();

  const { data: organization, refetch: refetchOrganisationInfo } =
    useAdminOrganizationById(organizationId);

  useTitle(organization?.name);

  const activeTabElement = removeLeadingAndTrailingSlash(
    Object.values(organizationTabs).includes(currentTab)
      ? currentTab
      : organizationTabs.DEFAULT,
  );
  const DIVIDER = ' | ';

  const breadcrumbsLinks = [
    {
      href: generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
        organizationId,
        tab: activeTabElement,
      }),
      name: organization?.name,
      current: true,
    },
  ];

  const periods = useMemo(() => organization?.periods, [organization]);

  return (
    <GeneralLayout>
      <PageHeaderWrapper
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        marginBottom={false}
        title={[organization?.prefix, organization?.name].join(DIVIDER)}
      >
        <div className="mt-5 flex gap-2 p-1">
          <Stats
            href={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: `${organizationTabs.USERS}?roles=${ftrprfUserRoles.SCHOOLADMIN}`,
            })}
            logo={<AcademicCapIcon className="h-5 w-5 text-white" />}
            name={t('global.SCHOOLADMIN.plural')}
            test={dataTestIds.page.organisationProfile.button.stat.schooladmin}
            total={organization?.stats?.schooladmins || '0'}
          />
          <Stats
            href={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: `${organizationTabs.USERS}?roles=${ftrprfUserRoles.SCHOOLSTUDENT}`,
            })}
            logo={<UserIcon className="h-5 w-5 text-white" />}
            name={t('global.STUDENT.plural')}
            test={dataTestIds.page.organisationProfile.button.stat.student}
            total={organization?.stats?.students || 0}
          />
          <Stats
            href={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: `${organizationTabs.USERS}?roles=${ftrprfUserRoles.SCHOOLTEACHER}`,
            })}
            logo={<UserIcon className="h-5 w-5 text-white" />}
            name={t('global.TEACHER.plural')}
            test={dataTestIds.page.organisationProfile.button.stat.teacher}
            total={organization?.stats?.teachers || 0}
          />
          <Stats
            logo={<UserGroupIcon className="h-5 w-5 text-white" />}
            name={t('global.CLASS.plural')}
            test={dataTestIds.page.organisationProfile.button.stat.classgroup}
            total={getCurrentPeriod(periods)?.stats?.classes || 0}
          />
          <Stats
            href={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: organizationTabs.PROGRAMS,
            })}
            logo={<BookOpenIcon className="h-5 w-5 text-white" />}
            name={t('global.PROGRAM.plural')}
            test={dataTestIds.page.organisationProfile.button.stat.program}
            total={getCurrentPeriod(periods)?.stats?.programs || 0}
          />
        </div>

        <TabList>
          <Tab
            label={t('global.USER.plural')}
            test={dataTestIds.page.organisationProfile.button.tab.users}
            to={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: organizationTabs.USERS,
            })}
          />
          <Tab
            label={t('global.PROGRAM.plural')}
            test={dataTestIds.page.organisationProfile.button.tab.programs}
            to={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: organizationTabs.PROGRAMS,
            })}
          />
          <Tab
            label={t('global.REPORT.plural')}
            test={dataTestIds.page.organisationProfile.button.tab.reports}
            to={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: organizationTabs.REPORTS,
            })}
          />
          <Tab
            label={t('global.periods')}
            test={dataTestIds.page.organisationProfile.button.tab.periods}
            to={generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
              organizationId,
              tab: organizationTabs.PERIODS,
            })}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet context={{ refetchOrganisationInfo }} />
      </div>
    </GeneralLayout>
  );
}
