/* eslint-disable */
import { generatePath, useNavigate } from 'react-router-dom';
import { ArchiveBoxIcon, EyeIcon, PlusIcon } from '@heroicons/react/24/outline';

import {
  Actions,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
  Toggle,
  ActionStyledAsButton,
  Badge,
  Pagination,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { mobile } from 'utils/constants/mediaWidth';
import URLS from 'utils/constants/urls';
import { useContext } from 'react';
import { PeriodsContext } from '../../../providers/PeriodsProvider';
import { dataTestIds } from 'utils/dataTestIds';

export default function ManageClasses({
  canCreate = false,
  canDelete = false,
  classgroups,
  currentPage,
  filter,
  handleCheckBoxClick,
  isLoadingClasses,
  openAddModal,
  openArchiveModal,
  totalPages,
  setClassgroupData,
  setFilter,
  setPage,
  setSort,
  showArchived,
  sort,
  totalItems,
  itemsPerPage,
  setItemsPerPage,
}) {
  const t = useFormatMessage();
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const navigate = useNavigate();
  useTitle(t('manage.title'), t('global.CLASS.plural'));

  const routeToDetail = (classId) => {
    let route = URLS.MANAGE_CLASS_DETAILS;
    if (showArchived) route = URLS.MANAGE_CLASS_DETAILS_ALL;

    return generatePath(route, {
      classId,
      filter,
    });
  };

  const goToClassDetail = ({ id: classId }) => {
    navigate(routeToDetail(classId));
  };

  const actionsForClassgroups = (classgroup) => {
    const possibleActions = [
      {
        icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.view'),
        onClick: () => {
          setClassgroupData(classgroup);
        },
        href: routeToDetail(classgroup.id),
      },
    ];

    if (!classgroup.archived && canDelete && selectedPeriodIsActivePeriod)
      possibleActions.push({
        icon: <ArchiveBoxIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.archive'),
        onClick: () => {
          setClassgroupData(classgroup);
          openArchiveModal();
        },
      });

    return possibleActions;
  };

  return (
    <>
      <div className="w-full flex md:justify-between md:items-center  mb-4 gap-4 flex-col md:flex-row">
        <div className="flex-grow">
          <SearchBar
            onChange={setFilter}
            placeholder={t('hack-room.what_are_you_looking_for')}
            test={dataTestIds.page.manage.input.name}
          />
        </div>
        <div className="md:px-8">
          <Toggle
            label={t('classgroup-overview.not-archived')}
            onChange={handleCheckBoxClick}
            value={showArchived}
            visibleLabel={true}
            test={dataTestIds.page.manage.toggle.showArchived}
          />
        </div>

        {canCreate && selectedPeriodIsActivePeriod && (
          <ActionStyledAsButton
            iconBefore={PlusIcon}
            onClick={openAddModal}
            test={dataTestIds.page.manage.button.add.class}
          >
            {t('schooladmin-overview.new-class-group')}
          </ActionStyledAsButton>
        )}
      </div>
      <TableSkeleton loading={isLoadingClasses} maxItems={itemsPerPage}>
        <ResponsiveTable
          headers={[
            { key: 'name', label: t('classgroup-overview.column.name') },
            showArchived && {
              key: 'archived',
              label: t('classgroup-overview.column.archived'),
              orderable: false,
            },
            {
              key: 'actions',
              label: t('global.actions'),
              orderable: false,
              className: 'max-w-fit sr-only',
            },
          ]}
          mobileBreakpoint={mobile}
          mobileHeaderWidth={160}
          onRowClick={goToClassDetail}
          onSortChange={(newSort) => setSort(newSort)}
          sort={sort}
        >
          {classgroups.map((classgroup) => (
            <ResponsiveTableRow data={classgroup} key={classgroup.id}>
              <ResponsiveTableCol>{classgroup.name}</ResponsiveTableCol>
              {showArchived && (
                <ResponsiveTableCol>
                  {classgroup.archived && (
                    <Badge warning>
                      {t(`classgroup-overview.archived.${classgroup.archived}`)}
                    </Badge>
                  )}
                </ResponsiveTableCol>
              )}
              <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                <Actions items={actionsForClassgroups(classgroup)} />
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          ))}
        </ResponsiveTable>

        {Boolean(classgroups && classgroups.length) && (
          <Pagination
            className="mt-12"
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={(page) => setPage(page)}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        )}
      </TableSkeleton>
    </>
  );
}
