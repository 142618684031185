import { gql } from 'graphql-request';

export const SCHOOLADMIN_SMARTSCHOOL_CLASSGROUPS = gql`
  query schooladminSmartSchoolClassGroups {
    schooladminSmartSchoolClassGroups {
      classGroupId
      dailySync
      externalId
      lastSyncedOn
      name
    }
  }
`;
