import { useContext, useEffect, useState } from 'react';
import {
  BookOpenIcon,
  ChatBubbleLeftRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

import { ActionStyledAsButton } from '../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../hooks/useFormatMessage';

import {
  SlideViewerContext,
  SlideViewerLeftAction,
} from '../../slideviewer/SlideViewer';

import ChevronRight from './ChevronRight';
import FeedbackDialog from './FeedbackDialog';

export default function SlideViewerLeft({ isSideBarOpen, setIsSideBarOpen }) {
  const [showButtonText, setShowButtonText] = useState(false);
  const { isFeedbackDialogOpen, preview, setIsFeedbackDialogOpen, user } =
    useContext(SlideViewerContext);
  const { isStudent } = user;

  const t = useFormatMessage();

  useEffect(() => {
    if (isSideBarOpen) {
      setShowButtonText(false);
    } else {
      setShowButtonText(true);
    }
  }, [isSideBarOpen]);

  return (
    <>
      {!isStudent && !preview && (
        <FeedbackDialog
          isOpen={isFeedbackDialogOpen}
          setClosed={() => setIsFeedbackDialogOpen(false)}
        />
      )}

      <div className="flex items-center justify-between z-20 w-[33px] lg:w-[66px] flex-shrink-0 h-full left-0 absolute">
        <SlideViewerLeftAction className="lg:ml-2 p-2 duration-400">
          <ChevronRight className="rotate-180 w-4 lg:w-8 min-w-[3rem] min-h-[3rem]" />
        </SlideViewerLeftAction>
      </div>

      <div className="z-20 left-2 absolute bottom-2 flex flex-col gap-2">
        <div>
          <ActionStyledAsButton
            iconBefore={isSideBarOpen ? XMarkIcon : BookOpenIcon}
            iconMargin={isSideBarOpen ? 'mr-0' : 'mr-2'}
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
            onMouseEnter={() => {
              if (isSideBarOpen) {
                setShowButtonText(true);
              }
            }}
            onMouseLeave={() => {
              if (isSideBarOpen) {
                setShowButtonText(false);
              }
            }}
            test="slideviewerOpenSidebar"
          >
            <span className={`ml-2 ${showButtonText ? '' : 'sr-only'}`}>
              {t(
                isStudent
                  ? 'styled-slide-viewer.table_of_contents'
                  : 'styled-slide-viewer.manual',
              )}
            </span>
          </ActionStyledAsButton>
        </div>
        {!isStudent && !preview && (
          <div>
            <ActionStyledAsButton
              iconBefore={ChatBubbleLeftRightIcon}
              iconMargin={isSideBarOpen ? 'mr-0' : 'mr-2'}
              onClick={() => setIsFeedbackDialogOpen(true)}
              onMouseEnter={() => {
                if (isSideBarOpen) {
                  setShowButtonText(true);
                }
              }}
              onMouseLeave={() => {
                if (isSideBarOpen) {
                  setShowButtonText(false);
                }
              }}
              secondary
              test="feedbackButton"
            >
              <span className={`${showButtonText ? '' : 'sr-only'}`}>
                {t('styled-slide-viewer.settings.feedback')}
              </span>
            </ActionStyledAsButton>
          </div>
        )}
      </div>
    </>
  );
}
