export default {
  "assistant.image.what": "Une image de {who}, {alt}",

  // alt texts for assistants
  "assistant.image.alt.codi": "un robot blanc et ludique avec des yeux verts sur un écran noir. Il porte un bandeau vert avec le mot 'c0di', et a un aspect industriel.",
  "assistant.image.alt.aisha": "une image amicale d'une femme à la peau bronzée et aux cheveux blonds. Elle fait un clin d'œil joyeux, habillée d'un chemisier décontracté bleu, créant une apparence accueillante.",
  "assistant.image.alt.youssef": "une image joyeuse d'un homme à la peau légèrement bronzée et aux cheveux châtains clairs. Il porte des lunettes rondes avec une monture marron foncé, un t-shirt cyan, et a une expression joyeuse et nerd.",
  "assistant.image.alt.milo": "une image cool d'un homme à la peau modérément foncée et aux cheveux brun foncé. Il porte des lunettes de soleil semi-circulaires marron avec une monture marron foncé, un t-shirt bleu, et a un sourire agréable.",
  "assistant.image.alt.freya": "une image un peu ridicule mais gentille d'une femme à la peau claire avec des cheveux roux. Elle porte du rouge à lèvres rouge, un t-shirt noir, et une casquette cyan, créant une apparence unique et amicale.",
  "assistant.image.alt.suki": "une sage femme plus âgée aux cheveux gris, rouge à lèvres rouge et vêtements verts. Elle porte des lunettes rondes jaunes, une ceinture grise, et a un style distinctif avec des accents cyan et gris. Suki a un teint clair et dégage de la sagesse.",
  "assistant.image.alt.bilal": "un homme avec des vêtements verts vibrants, des lunettes rondes jaunes et une barbe brun clair. Ses cheveux bouclés passent du blond sur le dessus au brun au niveau de la barbe. Bilal porte du rouge à lèvres rouge, un t-shirt noir, et a un sens de la mode unique.",
  "assistant.image.alt.farah": "une femme avec des vêtements violets, des extensions de cheveux roses et des yeux verts. Farah porte un t-shirt noir sous ses vêtements violets, créant une apparence élégante et colorée.",
  "assistant.image.alt.ramses": "un homme aux cheveux bouclés brun foncé, aux yeux noirs et aux vêtements roses vifs. Ramses porte du rouge à lèvres rouge et un t-shirt gris sous sa tenue vibrante, présentant un look audacieux et distinctif.",
  "assistant.image.alt.paul": "un homme avec une coupe de cheveux courte, des yeux verts foncés et des vêtements blancs avec des accents cyan et gris. Paul porte du rouge à lèvres rouge et un t-shirt gris sous ses vêtements blancs, présentant un style propre et simple.",
  "assistant.image.alt.chanel": "une femme aux cheveux rouges en chignons, aux yeux noirs et aux vêtements bleus avec des accents cyan et gris. Chanel porte du rouge à lèvres rouge et un t-shirt gris sous ses vêtements bleus, affichant une apparence à la mode et amusante."
};
