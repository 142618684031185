import { useContext } from 'react';

import { LanguageContext } from 'providers/LanguageProvider';
import { cloneDeep } from 'lodash-es';

export default function NotificationLanguage(notification) {
  const { language } = useContext(LanguageContext);

  const clone = cloneDeep(notification);

  switch (language.toUpperCase()) {
    case 'EN':
      clone.title = notification.titleEN;
      clone.body = notification.bodyEN;
      break;
    default:
      clone.title = notification.titleNL;
      clone.body = notification.bodyNL;
      break;
  }

  return clone;
}
