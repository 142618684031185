import IconBeforeWithLoader from './IconBeforeWithLoader';
import IconAfterWithLoader from './IconAfterWithLoader';

export default function ActionContent({
  IconAfter,
  IconBefore,
  badge,
  children,
  iconClassNames,
  iconMargin,
  loader,
  loading,
  size,
}) {
  const afterIcon = () => <IconAfter className={iconClassNames} />;
  const beforeIcon = () => <IconBefore className={iconClassNames} />;

  return (
    <>
      <IconBeforeWithLoader
        iconBefore={beforeIcon}
        iconMargin={iconMargin}
        loader={loader}
        loading={loading}
        showIcon={Boolean(IconBefore)}
        size={size}
      />
      {loading && !IconBefore && !IconAfter ? loader() : children}
      <IconAfterWithLoader
        iconAfter={afterIcon}
        loader={loader}
        loading={loading}
        showIcon={Boolean(IconAfter)}
      />
      {badge && (
        <div className="absolute top-0 right-0 text-xxs py-1 px-2 -mr-1 bg-blue-100 text-blue-600 rounded-full leading-none -mt-3 font-semibold shadow-sm">
          {badge}
        </div>
      )}
    </>
  );
}
