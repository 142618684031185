import { gql } from 'graphql-request';

export const ADMIN_ENABLE_USER = gql`
  mutation adminEnableUser($id: String!) {
    adminEnableUser(id: $id) {
      id
      message
      statusCode
    }
  }
`;
