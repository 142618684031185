import useFormatMessage from '../../hooks/useFormatMessage';

export function SuccessMessage({ description, title }) {
  const t = useFormatMessage();

  return (
    <div className="flex bg-white border border-gray-300 p-4 rounded my-4">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
        <svg
          aria-hidden="true"
          className="h-6 w-6 text-green-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 13l4 4L19 7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title || t('message.success-message.title')}
        </h3>
        <div className="mt-1">
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
}
