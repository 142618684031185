import c from '../../utils/c';
import getComponentStyle from '../../utils/getComponentStyle';

import ProgressBase from './ProgressBase';

const progressStyles = {
  ACCENT: {
    bg: 'bg-accent-400',
    text: 'text-accent-600',
  },
  SUCCESS: {
    bg: 'bg-green-400',
    text: 'text-green-600',
  },
  ERROR: {
    bg: 'bg-red-400',
    text: 'text-red-600',
  },
  WARNING: {
    bg: 'bg-yellow-400',
    text: 'text-yellow-600',
  },
  BLACK: {
    bg: 'bg-gray-400',
    text: 'text-gray-600',
  },
};

export function ProgressBar({
  accent,
  black,
  className,
  error,
  maximumProgress,
  progress,
  success,
  warning,
}) {
  const style = getComponentStyle(
    progressStyles,
    { accent, success, error, warning, black },
    'ACCENT',
  );

  const progressStyle = progressStyles[style];
  const percentage = Math.round((progress / maximumProgress) * 100);

  return (
    <div className={c('flex flex-col items-end', className)}>
      <div className={c('text-xs', progressStyle.text)}>{`${percentage}%`}</div>
      <ProgressBase className={progressStyle.bg} percentage={percentage} />
    </div>
  );
}
