import { createContext, useContext, useMemo } from 'react';

const PopupContext = createContext();

export const usePopupContext = () => useContext(PopupContext);

export function PopupProvider({
  children,
  classGroupsData,
  classes,
  control,
  currentStepIndex,
  errors,
  register,
  setClasses,
  setCurrentStepIndex,
  setTarget,
  setValue,
  steps,
  target,
  teacherPrograms,
  watch,
}) {
  const context = useMemo(
    () => ({
      classGroupsData,
      classes,
      currentStepIndex,
      formOptions: { control, errors, register, setValue, watch },
      setClasses,
      setCurrentStepIndex,
      setTarget,
      steps,
      target,
      teacherPrograms,
    }),
    [
      classGroupsData,
      classes,
      currentStepIndex,
      control,
      errors,
      register,
      setValue,
      watch,
      setClasses,
      setCurrentStepIndex,
      setTarget,
      steps,
      target,
      teacherPrograms,
    ],
  );

  return (
    <PopupContext.Provider value={context}>{children}</PopupContext.Provider>
  );
}
