import { gql } from 'graphql-request';

export const SCHOOLADMIN_CLASS_GROUP_BY_ID = gql`
  query schooladminClassGroupById($classGroupId: Int!) {
    schooladminClassGroupById(classGroupId: $classGroupId) {
      id
      name
      archived
      amountOfStudents
    }
  }
`;
