import { useLocation } from 'react-router-dom';

export default function useCurrentTab() {
  const { pathname } = useLocation();

  const pathnameSections = pathname.split('/');

  // last element
  return pathnameSections[pathnameSections.length - 1];
}
