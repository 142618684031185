import { NavLink } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/outline';

import c from '../utils/c';
import { linkHoverStyles } from '../utils/constants/linkHoverStyles';
import { linkFocusStyles } from '../utils/constants/linkFocusStyles';

export function Breadcrumbs({
  className = '',
  homeLink = '',
  homeText = '',
  links,
  user = null,
}) {
  if (!links || links.length === 0) {
    return null;
  }

  let showCalendar = true;

  if (user) {
    showCalendar = user?.organization?.showCalendar;
  }

  const firstItem = links[0];

  const hoverBreadcrumbStyles = 'hover:text-gray-400 hover:decoration-white';
  const breadcrumbStyles = 'text-gray-200 rounded px-1';

  return (
    <nav aria-label="Breadcrumb" className={c('flex', className)}>
      <ol className="flex items-center text-sm w-full">
        <li className="focus-visible:outline-none flex items-center">
          <NavLink
            className={c(
              'breadcrumbHomelink focus:outline-none focus-visible:outline-none',
              breadcrumbStyles,
              linkHoverStyles,
              linkFocusStyles,
              hoverBreadcrumbStyles,
            )}
            to={homeLink || (showCalendar ? '/home' : links.shift().href)}
          >
            <span className="flex items-center font-medium">
              <HomeIcon
                aria-hidden="true"
                className="flex-shrink-0 h-5 w-5 mr-1"
              />
              <span className="homeText">
                {homeText || (showCalendar ? 'Home' : firstItem.name)}
              </span>
            </span>
          </NavLink>
        </li>
        {links.map((link) =>
          link.href ? (
            <li
              key={link.href}
              className="focus-visible:outline-none flex items-center"
            >
              <ChevronRightIcon
                aria-hidden="true"
                className="flex-shrink-0 h-5 w-5 text-gray-300"
              />
              <NavLink
                aria-current={link.current ? 'page' : undefined}
                className={c(
                  'my-1 font-medium',
                  breadcrumbStyles,
                  linkHoverStyles,
                  linkFocusStyles,
                  hoverBreadcrumbStyles,
                )}
                to={{ pathname: link.href, search: link.search }}
              >
                {link.name || link.href?.substring(link.href?.lastIndexOf('/'))}
              </NavLink>
            </li>
          ) : (
            <li key={link.name} className="focus-visible:outline-none">
              <div
                className={c(
                  'my-1 flex items-center',
                  linkHoverStyles,
                  linkFocusStyles,
                )}
              >
                <ChevronRightIcon
                  aria-hidden="true"
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                />
                <span className="font-medium text-gray-200">{link.name}</span>
              </div>
            </li>
          ),
        )}
      </ol>
    </nav>
  );
}
