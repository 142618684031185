import { gql } from 'graphql-request';

export const ADMIN_CREATE_USER = gql`
  mutation adminCreateUser($input: UserInput) {
    adminCreateUser(input: $input) {
      id
      userName
      firstName
      lastName
      displayName
      email
      organizationId
      enabled
      roles
    }
  }
`;
