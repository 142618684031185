import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { LANGUAGE } from 'utils/constants/localStorage';

import appEn from '../translations/app.en';
import appFr from '../translations/app.fr';
import appNl from '../translations/app.nl';

import { UserContext } from './UserProvider';

export const languages = {
  nl: appNl,
  en: appEn,
  fr: appFr,
};

export const supportedLanguages = [
  { key: 'en', label: 'English' },
  { key: 'nl', label: 'Nederlands' },
  { key: 'fr', label: 'Français' },
];

export const LanguageContext = createContext({});

let browserLanguage = navigator.language?.split('-')[0] || 'nl';

if (localStorage.getItem(LANGUAGE) !== null) {
  browserLanguage = localStorage.getItem(LANGUAGE);
}

export default function LanguageProvider({ children, onError }) {
  const { language: userLanguage } = useContext(UserContext);

  const [language, setLanguage] = useState(
    userLanguage?.toLowerCase() || browserLanguage,
  );

  const onChangeLanguage = (language) => {
    if (language in languages) {
      setLanguage(language);

      try {
        localStorage.setItem(LANGUAGE, language);
      } catch (e) {
        console.error('localstorage not available');
      }
    }
  };

  const context = useMemo(
    () => ({
      language,
      setLanguage: onChangeLanguage,
    }),
    [language],
  );

  useEffect(() => {
    onChangeLanguage(userLanguage);
  }, [userLanguage]);

  return (
    <LanguageContext.Provider value={context}>
      <IntlProvider
        locale={language}
        messages={languages[language]}
        onError={onError}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
