import { gql } from 'graphql-request';

export const ADMIN_UPDATE_SEATCOUNT = gql`
  mutation adminUpdateSeatCount($input: UpdateSeatCountInput) {
    adminUpdateSeatCount(input: $input) {
      statusCode
      id
      message
    }
  }
`;
