import { useContext } from 'react';
import { useMutation } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { STUDENT_CREATE_ANSWER } from 'api/portfolioService/mutations/studentCreateAnswer';
import { TEACHER_CREATE_ANSWER } from 'api/portfolioService/mutations/teacherCreateAnswer';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

const hookObject = (isStudent) => ({
  id: `${isStudent ? 'student' : 'teacher'}CreateAnswer`,
  query: isStudent ? STUDENT_CREATE_ANSWER : TEACHER_CREATE_ANSWER,
});

export default function useSaveAnswer() {
  const toast = useToast();
  const t = useFormatMessage();
  const { isStudent } = useContext(UserContext);

  const hookInfo = hookObject(isStudent);

  return useMutation(
    (input) =>
      portfolioServiceQueryClient.request(hookInfo.query, {
        input,
      }),
    {
      mutationKey: hookInfo.id,
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          toastId: hookInfo.id,
          str: t('global.ANSWER.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          toastId: hookInfo.id,
          str: t('global.ANSWER.singular'),
        });
      },
    },
  );
}
