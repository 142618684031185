import { useContext } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import {
  Actions,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import { ReportsContext } from 'providers/ReportsProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { laptop } from 'utils/constants/mediaWidth';
import { useParams } from 'react-router-dom';

export function ManageReports() {
  const { organizationId } = useParams();

  const { availableReports, download } = useContext(ReportsContext);
  const t = useFormatMessage();

  const reports = organizationId
    ? availableReports?.organizationReportTypes
    : availableReports?.genericReportTypes;

  return (
    <ResponsiveTable
      headers={[
        {
          key: 'name',
          label: t('global.REPORT.singular'),
        },
        {
          key: 'actions',
          label: t('global.actions'),
          orderable: false,
          className: 'max-w-fit sr-only',
        },
      ]}
      mobileBreakpoint={laptop}
      mobileHeaderWidth={160}
      onRowClick={() => {}}
    >
      {reports?.map((type) => (
        <ResponsiveTableRow key={type}>
          <ResponsiveTableCol
            className="px-6 py-2 whitespace-normal"
            whitespaceNoWrapOnMobile={false}
          >
            {t(`reports.${type}`)}
            <small className="block text-slate-500 break-words">
              {t(`reports.${type}.extra`)}
            </small>
          </ResponsiveTableCol>

          <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right justify-end">
            <Actions
              items={[
                {
                  icon: (
                    <ArrowDownTrayIcon className="h-4 w-4 inline-block mr-2" />
                  ),
                  label: t('global.download'),
                  onClick: () => {
                    download(type, organizationId);
                  },
                },
              ]}
            />
          </ResponsiveTableCol>
        </ResponsiveTableRow>
      ))}
    </ResponsiveTable>
  );
}
