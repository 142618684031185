import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import {
  loginOnMsalThroughCodeFeverAuth,
  getUserFromMsal,
  openLesson,
  goToLogout,
} from './helperFunctions';

export default function CodeFeverLanding() {
  const [searchParams] = useSearchParams();
  const { inProgress, instance } = useMsal();
  const account = instance.getActiveAccount();

  const navigate = useNavigate();

  const idToken = searchParams.get('id_token')
    ? jwtDecode(searchParams.get('id_token'))
    : undefined;
  const sessionToken = searchParams.get('session_token');
  const targetLinkUri = searchParams.get('target_link_uri');

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const userIsNotLoggedIn = !account;

      if (userIsNotLoggedIn) {
        if (sessionToken) {
          loginOnMsalThroughCodeFeverAuth(sessionToken, targetLinkUri);
        }

        if (idToken) {
          setTimeout(() => getUserFromMsal(instance), 2000);
        }
      } else {
        // A non-codefever user won't have the session_token.
        const notMatchingSessionOrNoCodeFeverUser =
          sessionToken !== account.idTokenClaims?.session_token;

        if (sessionToken && notMatchingSessionOrNoCodeFeverUser) {
          /* 
            You might wonder why we don't just do the logoutPopup here. Good Question.
            We tried that, but it seems like MSAL updates the instance before you're
            actually logged out on Microsoft side. Updating the instance triggers a 
            rerender, canceling the logoutPopup. There's probably an nicer way of handling
            this. But just handing it of to a completely different component was the easiest.
          */
          goToLogout(navigate);
        } else {
          openLesson(idToken, targetLinkUri, navigate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, inProgress]);

  return (
    <div className="p-8 text-center">
      <p>We laden je les...</p>
    </div>
  );
}
