import { gql } from 'graphql-request';

export const TEACHER_RESULTS_BY_USERID = gql`
  query teacherResultsByUserId(
    $userId: String!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherResultsByUserId(
      userId: $userId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        calendarItem {
          id
          lessonContentTitle
          sessionId
          startTime
        }
        questionScoresEarned
        questionScoresMax
        scratchScoresSuccessful
        scratchScoresTotal
        questionsToGrade
        answeredQuestions
        amountOfOpenQuestions
        amountOfMcQuestions
        amountOfQuestions
        viewMode
      }
    }
  }
`;
