import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep, flatten } from 'lodash-es';

import { badgeStyles, Content, SearchBar } from '@ftrprf/tailwind-components';

import { BadgeSelect } from 'components/BadgeSelect';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';
import { SKELETON_OPTIONS } from 'components/SkeletonLoader/SkeletonConstructor';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import URLS from 'utils/constants/urls';
import { HackRoomProgramItem } from './partials/HackRoomProgramItem';

export function HackRoom({ hackRoomPrograms, isLoadingHackRoomPrograms }) {
  const t = useFormatMessage();
  useTitle(t('global.hack_room'));
  const [programs, setPrograms] = useState(hackRoomPrograms);
  const [showPrograms, setShowPrograms] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const lowerCasedFilter = filter.toLowerCase();

    if (hackRoomPrograms) {
      // filter by lesson name
      // filter by chapter id
      const programIds = showPrograms.map((showProgram) => showProgram.value);

      setPrograms(
        cloneDeep(hackRoomPrograms)
          .filter(
            (program) =>
              !showPrograms.length ||
              (showPrograms && programIds.includes(program.id)),
          )
          ?.filter((program) =>
            flatten(
              program.chapters.map((chapter) =>
                chapter.items.map((lesson) => lesson.name),
              ),
            ).some((item) => item.toLowerCase().includes(lowerCasedFilter)),
          ),
      );
    }
  }, [filter, hackRoomPrograms, showPrograms]);

  const breadcrumbsLinks = [
    {
      href: URLS.HACKROOM,
      name: t('global.hack_room'),
      current: true,
    },
  ];

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        title={t('global.hack_room')}
      />
      <Content>
        <div className="w-full flex flex-col space-y-4">
          <ListSkeleton
            amount={5}
            loading={isLoadingHackRoomPrograms}
            size={SKELETON_OPTIONS.LINE_H_2XL}
          >
            <div className="flex-col md:flex-row mb-2 flex gap-4">
              <div>
                <SearchBar
                  onChange={setFilter}
                  placeholder={t('hack-room.what_are_you_looking_for')}
                >
                  {({ item: { id, url, value } }) => (
                    <Link key={id} to={url}>
                      <div className="hover:bg-gray-200">{value}</div>
                    </Link>
                  )}
                </SearchBar>
              </div>

              <BadgeSelect
                id="hackroomItemSelect"
                options={hackRoomPrograms?.map((program) => ({
                  color: badgeStyles.SUCCESS,
                  value: program.id,
                  label: program.name,
                }))}
                placeholder={t('hack-room.select')}
                selected={showPrograms}
                setSelected={setShowPrograms}
              />
            </div>
            {hackRoomPrograms?.length === 0 && (
              <div className="flex flex-grow w-full items-center justify-center">
                <span>{t('global.empty')}</span>
              </div>
            )}
            {hackRoomPrograms?.length !== 0 && programs?.length === 0 && (
              <div className="flex flex-grow w-full items-center justify-center">
                <span>{t('global.empty.adjust_filter')}</span>
              </div>
            )}
            {hackRoomPrograms?.length !== 0 && programs?.length !== 0 && (
              <ul className="w-full grid gap-6 grid-cols-1">
                {programs?.map((program) => (
                  <HackRoomProgramItem
                    key={program.id}
                    filter={filter.toLowerCase()}
                    program={program}
                  />
                ))}
              </ul>
            )}
          </ListSkeleton>
        </div>
      </Content>
    </>
  );
}
