import { dataTestIds } from 'utils/dataTestIds';
import { ItemTitle } from './ItemTitle';

export function MainDisplay({ item }) {
  return (
    <div
      className="w-2/3 shrink-0 flex flex-col gap-5"
      data-test={dataTestIds.multiScreen.mainDisplay}
    >
      <ItemTitle item={item} />
      {item.Component}
    </div>
  );
}
