import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { ADMIN_DISABLE_USER } from 'api/plannerService/admin/mutations/adminDisableUser';

export function useAdminDisableUser() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    (id) =>
      plannerServiceQueryClient.request(ADMIN_DISABLE_USER, {
        id,
      }),
    {
      mutationKey: 'adminDisableUser',
      onSuccess: () => {
        toast(TOASTTYPES.DISABLESUCCESS, {
          str: t('global.USER.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.DISABLEERROR, {
          str: t('global.USER.singular'),
        });
      },
    },
  );
}
