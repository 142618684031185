/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// the above disable is because the li has a tabindex.  That's fine, as there's a SR-element in it which can get the
// focus and the LI has an on-click handler, so it's interactive
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SubjectCard } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import URLS, { chapterItemTabs } from 'utils/constants/urls';
import '../learn.css';

export function ChaptersList({ chapters, programId }) {
  const { isTeacher } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <ul
      className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      data-test="chapterOverview"
    >
      {chapters?.map((chapter) => {
        const linkVariables = {
          programId,
          chapterId: chapter.id,
        };
        const url = isTeacher
          ? URLS.LEARN_TAB_PROGRAM_CHAPTER
          : URLS.LEARN_PROGRAM_CHAPTER;

        if (isTeacher) linkVariables.tab = chapterItemTabs.DEFAULT;

        const link = generatePath(url, linkVariables);
        const onCardClick = () => navigate(link);

        return (
          // the subject card has the required link for the SR-users.
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
          <li
            key={`${chapter.name}${chapter.id}`}
            className="cursor-pointer chapterItem"
            onClick={onCardClick}
            tabIndex={0}
          >
            <SubjectCard
              color={chapter.color}
              document={isTeacher ? chapter.document : false}
              hasContent={chapter.items?.length}
              image={chapter.blobUri}
              isModule={false}
              link={link}
              tags={chapter.tags || []}
              title={chapter.name}
            />
          </li>
        );
      })}
    </ul>
  );
}
