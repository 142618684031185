import { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import {
  AcademicCapIcon,
  PencilIcon,
  PresentationChartBarIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { formatDate } from '@mobiscroll/react';

import { ActionStyledAsButton, Button } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { lessonTypes } from 'utils/constants/lessonTypes';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';
import { PeriodsContext } from 'providers/PeriodsProvider';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';

export default function EventPopup({
  event,
  onClose,
  onComposeGroups,
  onDelete,
  onEdit,
}) {
  const t = useFormatMessage();
  const { id, isTeacher, organization, settings } = useContext(UserContext);
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const canEditAndDelete = id === event.userId;
  const shouldBeProjection =
    isTeacher && event.lessonContentType !== lessonTypes.EXAM;
  const slideViewerLink = generatePath(
    URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID,
    {
      sessionId: event.sessionId,
      studioId: event.studioId,
      viewMode: shouldBeProjection
        ? settings.uiSettings?.slideViewer?.viewMode ?? viewModes.PROJECTION
        : event.calendarItem.viewMode,
    },
  );

  return (
    <div className="rounded-sm shadow-md bg-white border-0">
      <div className="p-4 text-black relative">
        <div className="">
          <div className="flex pt-2 justify-center items-center">
            <div
              className="h-2 w-2 p-2 m-0 mr-2 rounded-lg "
              style={{
                backgroundColor: event?.color?.toLowerCase(),
              }}
            />
            <span className="font-bold">
              {event?.chapter?.name || t('curricula.lessons.own')}
            </span>
          </div>
          <h4 className="text-gray-500">{event?.title}</h4>
          <p className="text-sm text-gray-400 mb-2">
            {event?.start && formatDate('HH:mm', event?.start)} -{' '}
            {event?.end && formatDate('HH:mm', event?.end)}
          </p>
          <p className="mb-2">
            {event?.classGroups?.map((classGroup) => (
              <span
                key={classGroup.name}
                className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-accent-100 text-accent-800 mr-2"
              >
                {classGroup.name}
              </span>
            ))}
          </p>
          <p>
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
              {event.firstName} {event.lastName}
            </span>
          </p>
          <div className="grid grid-cols-2 gap-2 mt-4">
            {selectedPeriodIsActivePeriod && canEditAndDelete && (
              <ActionStyledAsButton
                iconBefore={PencilIcon}
                onClick={onEdit}
                test="editEvent"
              >
                {t('global.edit')}
              </ActionStyledAsButton>
            )}
            {selectedPeriodIsActivePeriod && canEditAndDelete && (
              <ActionStyledAsButton
                disabled={event.start < new Date()}
                iconBefore={TrashIcon}
                onClick={onDelete}
                secondary
                test="deleteEvent"
                title={t('eventpopup.delete.past_event')}
              >
                {t('global.delete')}
              </ActionStyledAsButton>
            )}
            <ActionStyledAsButton
              iconBefore={AcademicCapIcon}
              secondary
              test="resultsForEvent"
              to={generatePath(URLS.CALENDAR_ITEM_RESULTS, {
                calendarItemId: event.calendarItem.id,
                programId:
                  event.calendarItem.chapter?.programId ||
                  nonNumericProgramIds.AGENDA,
              })}
            >
              {t('global.RESULT.plural')}
            </ActionStyledAsButton>
            <ActionStyledAsButton
              iconBefore={PresentationChartBarIcon}
              secondary
              test="presentEvent"
              to={slideViewerLink}
            >
              {t('global.presentation')}
            </ActionStyledAsButton>
            {organization.steams && (
              <div className="col-span-2">
                <ActionStyledAsButton
                  className="w-full justify-center"
                  onClick={onComposeGroups}
                >
                  <UsersIcon className="h-4 w-4 mr-2" />
                  {t('eventpopup.divide_groups')}
                </ActionStyledAsButton>
              </div>
            )}
          </div>
        </div>
        <Button
          className="p-4 absolute top-0 right-0"
          iconBefore={XMarkIcon}
          iconSizeClassName="w-6 hover:outline hover:outline-blue-500 hover:outline-2 hover:rounded focus:outline focus:outline-blue-500 focus:outline-2 focus:rounded"
          onClick={onClose}
        >
          <span className="sr-only">{t('global.close')}</span>
        </Button>
      </div>
    </div>
  );
}
