/* eslint-disable no-param-reassign */
import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { addYears, intlFormat, subMinutes, subYears } from 'date-fns';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  Loader,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import useTeacherCreateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateCalendarItem';
import useTeacherDeleteCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherDeleteCalendarItem';
import useTeacherUpdateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherUpdateCalendarItem';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import useTeacherLessons from 'hooks/api/plannerService/teacher/queries/useTeacherLessons';
import useFormatMessage from 'hooks/useFormatMessage';
import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import viewModes from 'utils/constants/viewModes';
import {
  backendAcceptedDateFormat,
  getFormattedDates,
} from 'utils/timeHelpers';
import { dataTestIds } from 'utils/dataTestIds';

import { DoubleQuickPlanButton, QuickPlanButton } from './QuickPlanButton';
import {
  closeCalendarItemsForGroup,
  isOpenCalendarItem,
  setStatusInfo,
} from './setStatusInfo';
import ViewModeDropdown from './ViewModeDropdown';

export function QuickPlanDialog({
  hasPlanButton = true,
  isOpen,
  itemInfo,
  onClose = () => {},
  openCalendarModal,
  setItemInfo,
}) {
  const t = useFormatMessage();
  const { locale } = useIntl();
  const MAX_ITEMS = 100;
  const { chapter, isExam, studioId } = itemInfo;
  const { chapterItem, lessonTitle, pageTitle } = chapter || {};
  const now = useMemo(() => new Date(), []);
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);
  const [dateRange] = useState({
    endDate: addYears(now, 1),
    key: 'selection',
    startDate: subYears(now, 1),
  });
  const [classGroups, setClassGroups] = useState([]);

  const showTitleAndSubtitle = () =>
    (chapterItem?.name && pageTitle) || (pageTitle && lessonTitle);

  const planCalendarItem = (classInfo) => {
    setItemInfo({ ...itemInfo, classInfo, quickPlan: true });
    openCalendarModal();
  };

  const handleCloseModal = () => {
    setClassGroups([]);
    setLoading(true);
    document.body.dispatchEvent(
      new KeyboardEvent('keydown', {
        keyCode: 27,
      }),
    );
    onClose();
  };

  const {
    data: lessonsData,
    isLoading: isLoadingLessons,
    isPreviousData: isPreviousLessonsData,
    refetch: refetchLessons,
  } = useTeacherLessons({
    filter: [
      {
        key: 'studioId',
        operation: filterOperation.EQUAL,
        value: studioId,
      },
      {
        key: 'startTime',
        operation: filterOperation.GREATER_THAN,
        value: backendAcceptedDateFormat(dateRange.startDate),
      },
    ],
    page: page - 1,
    size: MAX_ITEMS,
    sort: { endTime: sortOperation.DESC },
  });

  const { data: classData, isLoading: isLoadingClasses } =
    useTeacherClassGroups({
      page: 0,
      size: MAX_ITEMS,
      sort: { name: sortOperation.ASC },
    });

  const { isLoading: isCreatingCalendarItem, mutate: createCalendarItem } =
    useTeacherCreateCalendarItem();
  const { isLoading: isUpdatingCalendarItem, mutate: updateCalendarItem } =
    useTeacherUpdateCalendarItem();
  const { isLoading: isDeletingCalendarItem, mutateAsync: deleteCalendarItem } =
    useTeacherDeleteCalendarItem();

  const openAllClassGroups = () => {
    classGroups.forEach(async (group) => {
      if (group.hasOpenItem) return;

      const { end, start } = getFormattedDates(subMinutes(now, 5));
      const input = {
        chapterItemId: itemInfo.chapterItem ?? null,
        classGroupIds: [group.id],
        closedAfterEndTime: false,
        endTime: end,
        hidden: true,
        startTime: start,
        studioId: itemInfo.studioId,
        viewMode: viewModes.CLASSICAL,
      };

      await createCalendarItem(input);
    });

    setTimeout(() => refetchLessons(), 500);
  };

  const closeAllClassGroups = () => {
    classGroups.forEach(async (group) => {
      if (!group.hasOpenItem) return;

      await closeCalendarItemsForGroup(group, updateCalendarItem);
    });

    setTimeout(() => refetchLessons(), 500);
  };

  const isRetrievingOrUpdatingInfo =
    isCreatingCalendarItem ||
    isDeletingCalendarItem ||
    isUpdatingCalendarItem ||
    isLoadingLessons ||
    isLoadingClasses;

  useEffect(() => {
    let currentClassGroups;
    if (classData) {
      currentClassGroups = classData.content;
    }

    if (
      currentClassGroups &&
      isOpen &&
      !isLoadingLessons &&
      !isPreviousLessonsData
    ) {
      currentClassGroups.forEach((group) => {
        const calendarItemsForClassGroup = lessonsData?.content
          .filter(
            (item) =>
              studioId &&
              !(
                item.calendarItem.studioId === studioId &&
                item.calendarItem.closedAfterEndTime &&
                new Date(item.calendarItem.endTime) < now
              ),
          )
          .filter((lesson) => {
            const { classGroups: classGroupsForCalendarItem } =
              lesson.calendarItem;

            // see if there are any calendar items with the same class-group id as the current classGroup
            return (
              classGroupsForCalendarItem?.filter(
                (classGroup) => classGroup.id === group.id,
              ).length > 0
            );
          })
          .map((lesson) => lesson.calendarItem);

        group.calendarItems = calendarItemsForClassGroup;

        group.hasOpenItem =
          calendarItemsForClassGroup &&
          calendarItemsForClassGroup.filter((calendarItem) =>
            isOpenCalendarItem(calendarItem, now),
          ).length > 0;

        group.chapterItemId = chapterItem?.id || null;
        group.studioId = studioId;

        setStatusInfo(
          group,
          calendarItemsForClassGroup,
          {
            createCalendarItem,
            deleteCalendarItem,
            isRetrievingOrUpdatingInfo,
            planCalendarItem,
            refetchLessons,
            updateCalendarItem,
          },
          hasPlanButton,
        );

        const mostRecentItem = calendarItemsForClassGroup?.slice(-1)[0];
        const mostRecentDate =
          mostRecentItem && new Date(mostRecentItem.startTime);

        group.firstDate = mostRecentItem?.startTime;
        group.firstDateFormatted = mostRecentDate
          ? intlFormat(
              mostRecentDate,
              {
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                month: 'numeric',
                year: 'numeric',
              },
              {
                locale,
              },
            )
          : '';
      });

      setClassGroups([...currentClassGroups]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonsData,
    classData,
    isCreatingCalendarItem,
    isDeletingCalendarItem,
    isUpdatingCalendarItem,
    itemInfo.chapterItem,
    locale,
    now,
    isOpen,
    isLoadingLessons,
    isPreviousLessonsData,
    chapterItem,
    studioId,
  ]); // do not set classGroups as a dependency, because if you do it keeps re-rendering

  const classGroupsAreAllOpen = useMemo(
    () => classGroups?.filter((group) => !group.hasOpenItem).length === 0,
    [classGroups],
  );

  const classGroupsAreAllClosed = useMemo(
    () => classGroups?.filter((group) => group.hasOpenItem).length === 0,
    [classGroups],
  );

  if (!isOpen) return <div />;

  const openButtonInfo = {
    action: openAllClassGroups,
    disabled: classGroupsAreAllOpen || isRetrievingOrUpdatingInfo,
    test: dataTestIds.modal.quickPlan.button.openAll,
    text: 'calendar.quickplan.open.all',
  };

  const closeButtonInfo = {
    action: closeAllClassGroups,
    disabled: classGroupsAreAllClosed || isRetrievingOrUpdatingInfo,
    test: dataTestIds.modal.quickPlan.button.closeAll,
    text: 'calendar.quickplan.close.all',
  };

  return (
    <Dialog
      className="pb-8"
      data-test={dataTestIds.modal.quickPlan.self}
      isOpen={isOpen}
      modal
      onDismiss={handleCloseModal}
    >
      {isLoadingClasses || isLoadingLessons || loading ? (
        <div className="p-4 w-10 h-10\my-10 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <DialogHeader hasCloseButton onDismiss={onClose}>
            <div className="flex justify-between align-center w-full pl-8 pr-6 px-2">
              {showTitleAndSubtitle() && (
                <div>
                  <h3 className="text-l font-bold">
                    {chapterItem?.name || lessonTitle}
                  </h3>
                  <p className="text-gray-500 text-sm">{pageTitle}</p>
                </div>
              )}
              <QuickPlanButton
                buttonInfo={
                  classGroupsAreAllOpen ? closeButtonInfo : openButtonInfo
                }
              />
            </div>
          </DialogHeader>

          <DialogContent className="m-0">
            <ResponsiveTable
              className="w-full"
              hasBorders={false}
              headers={[
                {
                  className: 'sr-only',
                  key: 'quickplanClass',
                  label: t('global.CLASS.singular'),
                  orderable: false,
                },
                {
                  className: 'sr-only',
                  key: 'quickplanStatus',
                  label: t('global.table-header.status'),
                  orderable: false,
                },
                {
                  className: 'sr-only',
                  key: 'quickplanActions',
                  label: t('global.actions'),
                  orderable: false,
                },
              ]}
              mobileBreakpoint={160}
              mobileHeaderWidth={1700}
            >
              {classGroups.map((classGroup, index) => {
                const {
                  firstDate,
                  firstDateFormatted,
                  hasOpenItem,
                  id,
                  name,
                  statusInfo,
                } = classGroup;
                const hasMultipleButtons =
                  statusInfo && statusInfo.buttons.length > 1;
                return (
                  <ResponsiveTableRow
                    key={`quickplan${id}`}
                    className={`px-8 py-2 ${
                      index % 2 === 0 ? 'bg-slate-50 ' : ''
                    }`}
                    noDivider
                  >
                    <ResponsiveTableCol className="font-bold pl-8 py-4">
                      {name}
                    </ResponsiveTableCol>
                    <ResponsiveTableCol>
                      {statusInfo && (
                        <div className="flex flex-col items-center gap-1">
                          <span
                            className={`inline-block px-2 py-1 rounded-full text-sm font-semibold ${statusInfo.classes}`}
                          >
                            &#x2022; {statusInfo && t(statusInfo.text)}
                          </span>
                          {hasOpenItem && (
                            <time
                              className="text-gray-500 text-sm"
                              dateTime={firstDate}
                            >
                              {firstDateFormatted}
                            </time>
                          )}
                        </div>
                      )}
                    </ResponsiveTableCol>
                    <ResponsiveTableCol>
                      {!hasMultipleButtons &&
                        statusInfo &&
                        !isExam &&
                        statusInfo.text === 'global.open' && (
                          <ViewModeDropdown
                            chapterItemId={chapterItem?.id}
                            classGroup={classGroup}
                          />
                        )}
                    </ResponsiveTableCol>
                    <ResponsiveTableCol>
                      {hasMultipleButtons && (
                        <DoubleQuickPlanButton
                          classGroup={classGroup}
                          firstButtonInfo={statusInfo.buttons[0]}
                          itemInfo={itemInfo}
                          secondButtonInfo={statusInfo.buttons[1]}
                        />
                      )}
                      {!hasMultipleButtons && statusInfo && (
                        <QuickPlanButton
                          buttonInfo={statusInfo.buttons[0]}
                          classGroup={classGroup}
                          itemInfo={itemInfo}
                        />
                      )}
                    </ResponsiveTableCol>
                  </ResponsiveTableRow>
                );
              })}
            </ResponsiveTable>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
