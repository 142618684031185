import { gql } from 'graphql-request';

export const TEACHER_CALENDAR_ITEM_BY_ID = gql`
  query teacherCalendarItemById($id: Int!) {
    teacherCalendarItemById(id: $id) {
      id
      userId
      sessionId
      startTime
      endTime
      eventStatuses {
        eventTime
        message
        status
      }
      lessonContentTitle
      chapterItem {
        id
        name
        position
        chapterId
        blobUri
        lessons {
          id
          title
          studioId
          version
          status
          type
          blobUri
          imageUri
          copiedBy {
            id
            userName
            firstName
            lastName
            fullName
            displayName
            language
            email
            enabled
            roles
            classGroups {
              id
              name
              archived
              amountOfStudents
            }
            assessment {
              id
              userId
              status
            }
          }
          tags
          groupRoles {
            id
            role
          }
        }
        studioId
      }
      closedAfterEndTime
      classGroups {
        id
        name
        archived
        amountOfStudents
      }
      groupLesson
      viewMode
      studioId
      externalAssignmentId
      externalAssignmentType
    }
  }
`;
