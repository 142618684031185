import { gql } from 'graphql-request';

export const ADMIN_UPDATE_PERIOD = gql`
  mutation adminUpdatePeriod($id: Int!, $input: PeriodInput) {
    adminUpdatePeriod(id: $id, input: $input) {
      id
      organizationId
      name
      description
      startDate
      endDate
      stats {
        classes
        programs
      }
    }
  }
`;
