import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import viewModes from 'utils/constants/viewModes';
import contentTypes, { OWN_CONTENT } from 'utils/constants/contentTypes';
import { taggedListToOptions } from 'utils/listToOptions';
import useFormatMessage from 'hooks/useFormatMessage';

export function SelectChapterItem({
  chapter,
  control,
  errors,
  findChapterItems,
  ownContent,
  program,
  setIsExam,
  setValue,
}) {
  const t = useFormatMessage();

  return (
    <div>
      <Label className="mt-2" htmlFor="formCalendarItem_selectChapterItem">
        {t('calendar.modal.selectChapterItem')}
      </Label>
      <Controller
        control={control}
        name="chapterItem"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            hasErrors={Boolean(errors.chapterItem)}
            inputId="formCalendarItem_selectChapterItem"
            onBlur={onBlur}
            onChange={(e) => {
              if (e.isExam) {
                setValue('viewMode', viewModes.CLASSICAL);
              }
              setIsExam(e.isExam);
              onChange(e);
            }}
            options={
              program === OWN_CONTENT
                ? ownContent?.map((item) => ({
                    value: item.id,
                    label: item.title,
                    studioId: item.studioId,
                    isExam: item.type === contentTypes.EXAM,
                  }))
                : taggedListToOptions(findChapterItems(chapter))
            }
            placeholder={t('calendar.modal.selectChapterItem')}
            returnMethod="object"
            value={value}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('dropdown.no_results'),
          },
        }}
        type="select"
      />
      {errors.chapterItem && (
        <p className="text-xs text-red-600">{errors.chapterItem.message}</p>
      )}
    </div>
  );
}
