import { UserContext } from 'providers/UserProvider';
import { useContext, useEffect } from 'react';
import URLS from 'utils/constants/urls';
import { IMPERSONATE_ATTEMPTS } from 'utils/constants/localStorage';
import { Loader } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { Error404 } from 'pages/Error/Error404';
import { InfoImpersonatorAmongUs } from './partials/InfoImpersonatorAmongUs';

export function Impersonate() {
  const t = useFormatMessage();
  const user = useContext(UserContext);
  const { isAdmin, isImpersonating } = user;
  const currentAttempts =
    parseInt(localStorage.getItem(IMPERSONATE_ATTEMPTS), 10) || 0;

  const canImpersonate = isAdmin;

  const isAttempting =
    canImpersonate && !isImpersonating && currentAttempts < 10;

  useEffect(() => {
    if (isAttempting) {
      const intervalId = setInterval(() => {
        localStorage.clear();
        localStorage.setItem(IMPERSONATE_ATTEMPTS, `${currentAttempts + 1}`);

        // Navigate to the same URL to refresh the page
        window.location = `${process.env.REACT_APP_AD_REDIRECT_LINK}${URLS.IMPERSONATE}`;
      }, 5000);

      return () => {
        // Clean up the interval when the component is unmounted
        clearInterval(intervalId);
      };
    }

    return () => {};
  }, [canImpersonate, currentAttempts, isAttempting, isImpersonating]);

  if (isImpersonating) {
    localStorage.setItem(IMPERSONATE_ATTEMPTS, undefined);

    return <InfoImpersonatorAmongUs />;
  }

  if (canImpersonate) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col gap-6">
        <div className="max-w-lg">
          <h1 className="text-center px-3 text-2xl font-medium text-gray-900 mb-4">
            {isAttempting
              ? t('impersonate.header')
              : t('impersonate.header.failed')}
          </h1>
        </div>
        {isAttempting ? (
          <div className="overflow-hidden max-w-1/8">
            <Loader />
          </div>
        ) : (
          <p>{t('impersonate.description')}</p>
        )}
      </div>
    );
  }

  return <Error404 />;
}
