import useSchooladminClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroups';
import { sortOperation } from 'utils/constants/sort';
import { useEffect } from 'react';
import { sortBy, uniqBy } from 'lodash-es';

export function RetrieveClassGroupData({
  allClassGroups,
  maxItems,
  page = 1,
  setAllClassGroups,
}) {
  const { data: classgroupsData } = useSchooladminClassGroups({
    page,
    size: maxItems,
    sort: { name: sortOperation.ASC },
  });

  useEffect(() => {
    if (classgroupsData?.content) {
      const newAllClassGroups = [...allClassGroups, ...classgroupsData.content];

      const uniqueClasses = uniqBy(newAllClassGroups, (x) => x.id);
      const sortedUniqueClasses = sortBy(uniqueClasses, (x) => x.name);

      setAllClassGroups(sortedUniqueClasses);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classgroupsData]);

  return <div />;
}
