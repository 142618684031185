import { gql } from 'graphql-request';

export const CREATE_LESSSON_CONTENT = gql`
  mutation createLessonContent($input: UserLessonContentInput!) {
    createLessonContent(input: $input) {
      statuscode
      id
      message
    }
  }
`;
