import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

export default function SlideViewerQuiz({ children, className }) {
  const { setSlide, slides } = useContext(SlideViewerContext);

  const quizSlideIndex = slides.findIndex((slide) => slide.module === 'QUIZ');

  if (quizSlideIndex < 0) {
    return null;
  }

  return (
    <button
      className={className}
      onClick={() => setSlide(quizSlideIndex)}
      type="submit"
    >
      {children}
    </button>
  );
}
