import { DisclosureWidget } from './DisclosureWidget';
import { flattenTextArray } from './flattenTextArray';

export function DisclosureWidgetEllipsis({
  children,
  content,
  limit,
  textKey = '',
}) {
  const flattenedContent = Array.isArray(content)
    ? flattenTextArray(content, textKey)
    : content[0][textKey];
  const contentTooLong = flattenedContent.length > limit;
  const title = contentTooLong
    ? `${flattenedContent.substring(0, limit - 3)}...`
    : flattenedContent;

  return (
    <div>
      {!contentTooLong ? (
        children
      ) : (
        <DisclosureWidget title={<span className="detailsTitle">{title}</span>}>
          {children}
        </DisclosureWidget>
      )}
    </div>
  );
}
