import { gql } from 'graphql-request';

export const STUDENT_PROGRAMS = gql`
  query studentPrograms {
    studentPrograms {
      id
      name
      type
      blobUri
      weight
      color
      chapters {
        id
        name
        programId
        weight
        blobUri
        items {
          id
          name
          position
          weight
          chapterId
          blobUri
          calendarItems {
            closedAfterEndTime
            id
            startTime
            sessionId
            viewMode
            studioId
          }
          lessons {
            id
            title
            studioId
            version
            status
            type
            blobUri
            imageUri
            organizationId
            copiedBy {
              id
              userName
              firstName
              lastName
              fullName
              displayName
              language
              email
              organizationId
              enabled
              roles
            }
            tags
            groupRoles {
              id
              role
            }
          }
        }
      }
    }
  }
`;
