import { useContext, useEffect, useState } from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';

import {
  ActionStyledAsButton,
  SendPersonalityTestForm,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useTeacherCreatePersonalizedTests from 'hooks/api/plannerService/teacher/mutations/useTeacherCreatePersonalizedTests';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { filterOperation } from 'utils/constants/filter';
import URLS, { classDetailTabs } from 'utils/constants/urls';
import { removeLeadingAndTrailingSlash } from 'utils/removeLeadingAndTrailingSlash';

import GeneralLayout from 'app/layouts/GeneralLayout';
import { Tab, TabList } from 'components/Tab';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';

export const lessonContentType = {
  EXAM: 'EXAM',
  LESSON: 'LESSON',
};

export default function ClassDetail() {
  const t = useFormatMessage();
  const { organization } = useContext(UserContext);
  const { classGroupId, tab = classDetailTabs.DEFAULT } = useParams();

  const { data: classData, refetch } = useTeacherClassGroups({
    filter: [
      {
        key: 'classGroupId',
        operation: filterOperation.EQUAL,
        value: classGroupId,
      },
    ],
    page: 0,
    size: 1,
  });
  const classGroup =
    classData?.content.filter(
      (item) => String(item.id) === String(classGroupId),
    )?.[0] || {};

  const activeTabElement = removeLeadingAndTrailingSlash(
    Object.values(classDetailTabs).includes(tab)
      ? tab
      : classDetailTabs.DEFAULT,
  );
  useEffect(() => {
    refetch();
  }, [refetch]);

  useTitle(classGroup.name, activeTabElement);

  const [sendFormIsOpen, setSendFormIsOpen] = useState(false);

  const toggleSendForm = () => {
    setSendFormIsOpen(!sendFormIsOpen);
  };

  const { mutateAsync } = useTeacherCreatePersonalizedTests();

  const sendPersonalityTests = (e) => {
    mutateAsync({
      assessmentProcedureId: e,
      classId: classGroupId,
    });

    toggleSendForm();
  };

  const breadcrumbsLinks = [
    { href: URLS.CLASSGROUP, name: t('header.navigation.classes') },
    {
      current: true,
      href: generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
        classGroupId,
        tab: classDetailTabs.DEFAULT,
      }),
      name: t('class-detail.pageTitle'),
    },
  ];

  return (
    <GeneralLayout>
      <SendPersonalityTestForm
        isOpen={sendFormIsOpen}
        onDismiss={() => setSendFormIsOpen(false)}
        onSubmit={(e) => {
          sendPersonalityTests(e);
        }}
        options={[
          {
            assessmentProcedureId: 2152,
            name: t('personality.test.adults'),
          },
          {
            assessmentProcedureId: 2151,
            name: t('personality.test.children'),
          },
        ]}
      />
      <PageHeaderWrapper
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        marginBottom={false}
        title={`${t('class-detail.pageTitle')}: ${classGroup.name}`}
      >
        {organization.steams && (
          <div className="w-full flex flex-col md:flex-row">
            <ActionStyledAsButton
              className="md:ml-auto self-start"
              onClick={() => toggleSendForm()}
              send
            >
              {t('personality.test.send')}
            </ActionStyledAsButton>
          </div>
        )}

        <TabList>
          <Tab
            label={t('global.STUDENT.plural')}
            to={generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
              classGroupId,
              tab: classDetailTabs.STUDENTS,
            })}
          />
          <Tab
            label={t('global.LESSON.plural')}
            to={generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
              classGroupId,
              tab: classDetailTabs.LESSONS,
            })}
          />

          {/* <Tab
            label={t('global.EXERCISE.plural')}
            to={generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
              classGroupId: classGroupId,
              tab: classDetailTabs.EXERCISES,
            })}
          />

          <Tab
            label={t('global.TEST.plural')}
            to={generatePath(URLS.CLASSGROUP_DETAIL_TAB, {
              classGroupId: classGroupId,
              tab: classDetailTabs.TESTS,
            })}
          /> */}
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet />
      </div>
    </GeneralLayout>
  );
}
