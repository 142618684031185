import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import { PlusIcon, PresentationChartBarIcon } from '@heroicons/react/24/solid';

import useFormatMessage from 'hooks/useFormatMessage';
import { useContext } from 'react';
import { PeriodsContext } from '../../../../providers/PeriodsProvider';

export function StudioButton({ notInHeader = false }) {
  const t = useFormatMessage();
  const { selectedPeriodIsNotActivePeriod } = useContext(PeriodsContext);

  if (selectedPeriodIsNotActivePeriod) return null;

  return (
    <ActionStyledAsButton
      href={`${process.env.REACT_APP_STUDIO_URL}/lessons/new`}
      iconBefore={PresentationChartBarIcon}
      rel="noreferrer noopener"
      secondary={!notInHeader}
      target="_blank"
      test="createOwnContent"
      variant={notInHeader ? 'blue' : 'white'}
    >
      <PlusIcon aria-hidden="true" className="-ml-1 mr-2 h-5 w-5" />
      {t('global.lesson.new')}
    </ActionStyledAsButton>
  );
}
