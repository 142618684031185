import useFormatMessage from '../../../../hooks/useFormatMessage';
import { ShowHideTransition } from '../../../Transition/showHideTransition';

export function MultipleChoiceQuestionWrapper({
  children,
  hasResults,
  isCodefever,
}) {
  const t = useFormatMessage();

  if (isCodefever || hasResults) {
    /**
     * We add a transition here because in CodeFever lessons multiple choice question answers can be very long.
     * So long that they obscure the answer completely.
     * To solve this we add a "hide answers" button, but for them only.
     * Our content team ensures that the answers are never too long.
     * */
    return (
      <ShowHideTransition
        buttonTextOnHide={t('question.show.answers')}
        buttonTextOnShow={t('question.hide.answers')}
      >
        {children}
      </ShowHideTransition>
    );
  }

  return children;
}
