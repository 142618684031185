import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_STUDENT_BY_ID } from 'api/plannerService/teacher/queries/teacherStudentById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherStudentById(id) {
  const toast = useToast();
  const t = useFormatMessage();

  const info = useQuery(
    ['teacherStudentById', id],
    () =>
      plannerServiceQueryClient.request(TEACHER_STUDENT_BY_ID, {
        userId: id,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.TEACHER.plural'),
        });
      },
    },
  );

  return { ...info, data: info?.data?.teacherStudentById };
}
