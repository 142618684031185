import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Error403 } from 'pages/Error/Error403';

import { LocationHistoryContext } from 'providers/LocationHistoryProvider';
import { UserContext } from 'providers/UserProvider';

import useCreateLessonContent from 'hooks/api/portfolioService/mutations/useCreateLessonContent';
import useSaveAnswer from 'hooks/api/portfolioService/mutations/useSaveAnswer';
import useFindSavedAnswers from 'hooks/api/portfolioService/queries/useSavedAnswers';
import useStudentUserGroupByLessonSessionId from 'hooks/api/portfolioService/queries/useStudentUserGroupByLessonSessionId';
import useLessonContent from 'hooks/api/slidesService/useLessonContent';
import useLessonSlides from 'hooks/api/slidesService/useLessonSlides';
import useFormatMessage from 'hooks/useFormatMessage';

import { SLIDE_VIEWER_SIDEBAR_OPEN } from 'utils/constants/localStorage';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';

import { ReactComponent as LoaderBars } from 'assets/vectors/logo-animated-lines.svg';
import { Content } from '@ftrprf/tailwind-components';
import { PeriodsContext } from 'providers/PeriodsProvider';
import Abducted from 'assets/vectors/error-pages/abducted.svg';
import SlideViewerError from './partials/SlideViewerError';
import { SlideViewer } from './SlideViewer';
import {
  generateCurrentLessonPath,
  viewModeToLowerCase,
  viewModeToUpperCase,
} from './utils/helpers';

export default function SlideViewerContainer() {
  const navigate = useNavigate();
  const t = useFormatMessage();

  const { pathname } = useLocation();
  const { selectedPeriodIsNotActivePeriod } = useContext(PeriodsContext);
  const { hasHistory, locationHistory } = useContext(LocationHistoryContext);
  const { id, isStudent, organization } = useContext(UserContext);
  const { isCodefever } = organization;

  const {
    sessionId,
    slideId: urlSlideId,
    studioId,
    viewMode: urlViewMode,
  } = useParams();

  const [viewMode, setViewMode] = useState(viewModeToUpperCase(urlViewMode));
  const [slideId, setSlideId] = useState(urlSlideId);
  const [answers, setAnswers] = useState([]);
  const [visibleSlides, setVisibleSlides] = useState([]);

  useEffect(() => {
    if (slideId !== urlSlideId) {
      setSlideId(urlSlideId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlideId]);

  const { mutateAsync: createLessonContent } = useCreateLessonContent();

  // won't fetch lesson if no sessionId => if teacher is previewing
  const { data: lesson, isLoading: isLoadingLesson } = useLessonContent(
    studioId,
    sessionId,
  );

  const { data: userGroupInfo } =
    useStudentUserGroupByLessonSessionId(sessionId);

  // fetch all roles related to a lesson
  const { data: lessonRoles } = useStudentUserGroupByLessonSessionId(sessionId);
  // convert all roles to only roles from the current user
  const userRoles = useMemo(
    () =>
      lessonRoles?.userRoles
        .filter((i) => i.userId === id)
        .map((i) => i.roleName) || [],
    [lessonRoles?.userRoles, id],
  );

  const {
    data: slides,
    error,
    isLoading: isLoadingSlides,
    refetch: refetchLessonSlides,
  } = useLessonSlides({
    lessonSessionId: sessionId,
    selectedPeriodIsNotActivePeriod,
    studioId,
    viewMode: viewMode ?? viewModes.CLASSICAL,
  });

  useEffect(() => {
    refetchLessonSlides();
  }, [refetchLessonSlides, selectedPeriodIsNotActivePeriod, viewMode]);

  useEffect(() => {
    if (slides && organization.steams) {
      const filteredSlides = slides?.filter(
        (slide) =>
          // if the slide has no grouproles (i.e. it is a public slide)
          slide.groupRoles.length === 0 ||
          !!slide.groupRoles.filter((groupRole) =>
            userRoles.includes(groupRole.role),
          ).length,
      );
      setVisibleSlides(filteredSlides);
      if (filteredSlides.length > 0) {
        setSlideId(filteredSlides[0].id);
      }
    }
  }, [slides, organization.steams, userRoles]);

  useEffect(() => {
    // don't create lessenContent when teacher is previewing (doesn't have sessionId)
    if (lesson && sessionId) {
      createLessonContent({
        groupLesson: false,
        lessonSessionId: sessionId,
        studioId,

        studioVersion: lesson.version,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson]);

  const { isLoading: isSubmittingAnswer, mutateAsync: saveAnswer } =
    useSaveAnswer();

  useEffect(() => {
    if (!slideId && slides && slides.length !== 0) {
      setSlideId(slides[0].id);
    }
  }, [slideId, slides, setSlideId]);

  useEffect(() => {
    if (slides && slides.length !== 0) {
      if (sessionId && urlSlideId) {
        /* --- take a seat and some rest, that's life at its best ---*/
        /*
          when the sessionId and the urlSlideId are present, everything needed is present
        */
      } else if (sessionId && !urlSlideId) {
        navigate(
          generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID_SLIDEID, {
            studioId,
            viewMode: viewModeToLowerCase(viewMode),
            slideId: slideId ?? slides[0]?.id,
            sessionId,
          }),
          { replace: true },
        );
      } else {
        navigate(
          generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE, {
            studioId,
            viewMode: viewModeToLowerCase(viewMode),
          }),
          { replace: true },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideId, slides, viewMode]);

  const onChangeViewMode = useCallback(
    (newViewMode) => {
      if (newViewMode && newViewMode !== viewMode) {
        setViewMode(newViewMode);
      }
    },
    [viewMode],
  );

  const onChangeSlide = useCallback(
    (newSlideId) => {
      if (newSlideId && newSlideId !== slideId) {
        setSlideId(newSlideId);
        navigate(
          generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID_SLIDEID, {
            studioId,
            viewMode: viewModeToLowerCase(viewMode),
            slideId: newSlideId,
            sessionId,
          }),
        );
      }
    },
    [navigate, sessionId, slideId, studioId, viewMode],
  );

  const { data: savedAnswers, refetch: refetchSavedAnswers } =
    useFindSavedAnswers(sessionId);

  useEffect(() => {
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);

  const onSubmitQuestionAnswers = async (question, values) => {
    const data = await saveAnswer({
      questionId: question.id,
      questionType: question.type,
      answer: values,
      lessonSessionId: sessionId,
    });
    if (Object.values(data)[0]) {
      setAnswers([...answers, Object.values(data)[0]]);
    } else {
      setAnswers([
        ...answers,
        {
          questionId: question.id,
          questionType: question.type,
          userAnswer: values,
        },
      ]);
    }

    return Object.values(data)[0];
  };

  const onClose = useCallback(() => {
    if (hasHistory) {
      navigate(
        locationHistory.find(
          (val) =>
            // this beautiful piece of regex returns all sections of a pathname:
            // e.g.: "/studio/1/overview/slide/1"
            // would return ["/studio", "/1", "/overview", "/slide", "/1"]
            // and then we just want the first one that's different from the current pathname (current path: /slideviewer), e.g.: /home or /learn
            val.match(/^\/([^/])*/)[0] !== pathname.match(/^\/([^/])*/)[0],
        ),
        // when we have retrieved the right URL from our history, we can navigate out of slideviewer, to the first page that isn't part of slideviewer
      );
    } else {
      navigate(generatePath(URLS.HOME));
    }
  }, [hasHistory, locationHistory, navigate, pathname]);

  useEffect(() => {
    localStorage.setItem(SLIDE_VIEWER_SIDEBAR_OPEN, false);
  }, []);

  useEffect(() => {
    if (sessionId) {
      refetchSavedAnswers();
    }
  }, [sessionId, refetchSavedAnswers]);

  if (slides?.length === 0) {
    return <SlideViewerError onClose={onClose} />;
  }

  if (error && JSON.stringify(error).includes('FORBIDDEN')) {
    return (
      <Error403
        message={<span className="text-center">{t('errors.403.message')}</span>}
      />
    );
  }

  const slidesToShow = organization.steams ? visibleSlides : slides;

  return isLoadingLesson || isLoadingSlides ? (
    <Content hasPaddingBottom={false}>
      <div className="p-4 w-full flex flex-col justify-center items-center">
        <img alt="" className="inset-0 object-cover py-4 w-64" src={Abducted} />
        <LoaderBars className="w-32 p-8" />
      </div>
    </Content>
  ) : (
    <SlideViewer
      generateCurrentLessonPath={({ slideId }) =>
        generateCurrentLessonPath({ slideId, sessionId, studioId, viewMode })
      }
      isCodefever={isCodefever}
      isLoadingLesson={isLoadingLesson}
      isLoadingSlides={isLoadingSlides}
      isSubmittingAnswers={isSubmittingAnswer}
      lesson={lesson}
      lessonSessionId={sessionId}
      onChangeSlide={onChangeSlide}
      onChangeViewMode={onChangeViewMode}
      onClose={onClose}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      refetchAnswers={refetchSavedAnswers}
      selectedPeriodIsNotActivePeriod={selectedPeriodIsNotActivePeriod}
      setViewMode={setViewMode}
      slideId={slideId}
      slides={slidesToShow}
      studioId={studioId}
      submittedQuestionAnswers={answers}
      userGroupInfo={isStudent ? userGroupInfo : false}
      viewMode={viewMode}
    />
  );
}
