import { MapIcon } from '@heroicons/react/24/solid';
import { externalUrls } from 'utils/constants/externalUrls';
import useFormatMessage from 'hooks/useFormatMessage';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

export function OverviewButton() {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      href={externalUrls.learningMaterial}
      iconBefore={MapIcon}
      secondary
      target="_blank"
      variant="white"
    >
      {t('curricula.overview.button')}
    </ActionStyledAsButton>
  );
}
