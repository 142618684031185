import c from '../../utils/c';

import SlideViewerTextSlide from './SlideViewerTextSlide';

export default function SlideViewerSlideThumbnail({
  className = '',
  currentSlide,
  fontSizeIndex,
  isCk5,
  isFullSlideViewer = false,
}) {
  return (
    <div
      className={c('SlideViewerTextSlide', className)}
      style={{
        zoom: isCk5 ? false : fontSizeIndex,
        color: !currentSlide.background && 'black',
      }}
    >
      {isCk5 || <h1>{currentSlide.title}</h1>}
      <SlideViewerTextSlide
        hasFixedDimensions
        isCk5Thumbnail={isCk5}
        isFullSlideViewer={isFullSlideViewer}
        value={currentSlide.content}
      />
    </div>
  );
}
