import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { ADMIN_USERS_BY_ORGANIZATION_ID } from 'api/plannerService/admin/queries/adminUsersByOrganizationId';

export default function useAdminUsersByOrganizationId({
  filter = [],
  organizationId,
  page,
  size,
  sort,
}) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['adminUsersByOrganizationId', organizationId, filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(ADMIN_USERS_BY_ORGANIZATION_ID, {
        organizationId,
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.USER.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.adminUsersByOrganizationId,
  };
}
