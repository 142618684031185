import { gql } from 'graphql-request';

export const ADMIN_UPSERT_TAG = gql`
  mutation adminUpsertTag($id: Int, $input: TagInput!) {
    adminUpsertTag(id: $id, input: $input) {
      id
      name
      color
    }
  }
`;
