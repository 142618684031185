import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Navigate } from 'react-router-dom';
import { addDays, endOfWeek, startOfWeek, subDays } from 'date-fns';
import flatMap from 'lodash-es/flatMap';

import CalendarAsideContainer from 'pages/Calendar/CalendarAsideContainer';
import CalendarItemDialog from 'pages/Calendar/CalendarItemDialog';
import CalendarMainContainer from 'pages/Calendar/CalendarMainContainer';

import { UserContext } from 'providers/UserProvider';

import useLessons from 'hooks/api/plannerService/shared/useLessons';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import useWindowSize from 'hooks/useWindowSize';

import c from 'utils/c';
import { filterOperation } from 'utils/constants/filter';
import URLS from 'utils/constants/urls';
import { backendAcceptedDateFormat } from 'utils/timeHelpers';
import { filterEventsBySelectedClassesAndTeachers } from './utils/filterEvents';
import { getTeachers } from './utils/getTeachers';
import { removeDoubleEvents } from './utils/removeDoubleEvents';
import { structureEvents } from './utils/structureEvents';

export const CalendarContext = createContext();

export default function Home({ classGroups = [] }) {
  const t = useFormatMessage();

  const { isAdmin, isTeacher } = useContext(UserContext);
  const [events, setEvents] = useState(new Map());
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState(classGroups || []);
  const [teachers, setTeachers] = useState(new Map());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEventTitle, setSelectedEventTitle] = useState('');
  const [isOpenModalCalendarItem, setIsOpenModalCalendarItem] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventTimeInfo, setEventTimeInfo] = useState(null);
  const [range, setRange] = useState({ start: new Date(), end: new Date() }); // object with start and end dates of the month in the aside calendar
  const [studioId, setStudioId] = useState();
  const { width: windowWidth } = useWindowSize();

  useTitle(t('calendar.title'));

  const upsertEvents = useCallback(
    (event) => {
      setEvents((prev) => new Map(prev).set(range.start.toISOString(), event));
    },
    [range.start],
  );

  const upsertTeacher = useCallback((teacher) => {
    setTeachers((prev) =>
      new Map(prev).set(teacher.id, {
        ...teacher,
        selected: !teacher.selected,
      }),
    );
  }, []);

  const selectedTeachers = useCallback(
    () => Array.from(teachers.values()).filter((t) => t.selected),
    [teachers],
  );

  const getEvents = useCallback(
    () => flatMap(Array.from(events.values())),
    [events],
  );

  const { data: calendarItems, refetch } = useLessons({
    page: 0,
    size: 500,
    filter: [
      {
        key: 'startTime',
        value: backendAcceptedDateFormat(range.start),
        operation: filterOperation.GREATER_THAN,
      },
      {
        key: 'endTime',
        value: backendAcceptedDateFormat(range.end),
        operation: filterOperation.LESS_THAN,
      },
      {
        key: 'calendarItemHidden',
        value: true,
        operation: filterOperation.NOT_EQUAL,
      },
    ],
    onBefore: () => setEvents(new Map()),
  });

  const weekStart = backendAcceptedDateFormat(
    subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
  );
  const weekEnd = backendAcceptedDateFormat(
    addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
  );

  const { data: thisWeeksCalendarItems, refetch: refetchThisWeek } = useLessons(
    {
      page: 0,
      size: 100,
      filter: [
        {
          key: 'startTime',
          value: weekStart,
          operation: filterOperation.GREATER_THAN,
        },
        {
          key: 'endTime',
          value: weekEnd,
          operation: filterOperation.LESS_THAN,
        },
        {
          key: 'calendarItemHidden',
          value: true,
          operation: filterOperation.NOT_EQUAL,
        },
      ],
    },
  );

  const refetchCalendarItems = () => {
    refetch();
    refetchThisWeek();
  };

  const setFilteredEventsWithoutDoubles = (events) => {
    setFilteredEvents(removeDoubleEvents(events));
  };

  const handleOpenModalCalendarItem = (studioId) => {
    setIsOpenModalCalendarItem(true);
    setStudioId(studioId);
  };

  useEffect(() => {
    setFilteredEventsWithoutDoubles(
      filterEventsBySelectedClassesAndTeachers(
        getEvents(),
        selectedClasses,
        selectedTeachers(),
      ),
    );
  }, [getEvents, selectedClasses, selectedTeachers]);

  useEffect(() => {
    let tempEvents = [];
    if (calendarItems && calendarItems.content) {
      tempEvents = calendarItems.content;
    }
    if (thisWeeksCalendarItems && thisWeeksCalendarItems.content) {
      tempEvents = [
        ...new Set([...tempEvents, ...thisWeeksCalendarItems.content]),
      ];
    }
    if (tempEvents?.length) {
      upsertEvents(structureEvents(tempEvents));
    } else {
      upsertEvents([]);
    }
  }, [
    calendarItems?.content,
    calendarItems,
    thisWeeksCalendarItems,
    thisWeeksCalendarItems?.content,
    upsertEvents,
  ]);

  useEffect(() => {
    // get all teachers from the events
    const eventTeachers = getTeachers(getEvents());

    // get all the already existing teachers from the state, previously seen in an event (if any)
    const teacherIds = Array.from(teachers.values()).map(
      (teacher) => teacher.id,
    );

    // filter out from all the teachers from the events the ones that are already in the state
    const newTeachers = eventTeachers.filter(
      (teacher) => !teacherIds.includes(teacher.id),
    );

    // add the new teachers to the state
    newTeachers.forEach((teacher) => {
      upsertTeacher(teacher);
    });
  }, [getEvents, teachers, upsertTeacher]);

  useEffect(() => {
    if (isTeacher) {
      setSelectedClasses(classGroups);
    }
  }, [classGroups, isTeacher]);

  const CalendarContextObject = useMemo(
    () => ({
      classGroups,
      events: removeDoubleEvents(structureEvents(getEvents())),
      filteredEvents,
      selectedClasses,
      setDay: async (date) => {
        setSelectedDate(date);
      },
      setClasses: (classes) => {
        setSelectedClasses(classes);
      },
      setRange: (start, end) => {
        if (!events.get(start.toISOString())) {
          setRange({ start, end });
        }
      },
      setSelectedTeacher: (teacher) => {
        // update the "teacher.selected" field on a teacher (see upsertTeacher)
        upsertTeacher(teacher);
      },
      teachers: Array.from(teachers.values()),
      selectedTeachers: selectedTeachers(),
    }),
    [
      classGroups,
      events,
      filteredEvents,
      getEvents,
      selectedClasses,
      selectedTeachers,
      teachers,
      upsertTeacher,
    ],
  );

  return (
    <div>
      {isAdmin && <Navigate from={URLS.HOME} to={URLS.MANAGE} />}

      <h1 className="sr-only">{t('global.calendar')}</h1>

      {isTeacher && (
        <CalendarItemDialog
          calendarModalOption={{
            isOpenModalCalendarItem,
            setIsOpenModalCalendarItem,
          }}
          classGroups={classGroups}
          getCalendarItems={refetchCalendarItems}
          itemInfo={
            studioId
              ? {
                  eventTimeInfo,
                  selectedEvent,
                  title: selectedEventTitle,
                  setStudioId,
                  studioId,
                }
              : {
                  eventTimeInfo,
                  selectedEvent,
                  title: selectedEventTitle,
                }
          }
          onClose={() => {
            setEvents(new Map(events));
            setEventTimeInfo(null);
            setSelectedEvent(null);
          }}
          updateItemOption={{ isUpdateMode, setIsUpdateMode }}
        />
      )}

      <div
        className={c(
          'block grid-cols-5 h-screen',
          windowWidth > 1030 && 'grid',
        )}
      >
        <CalendarContext.Provider value={CalendarContextObject}>
          {windowWidth > 1030 && (
            <CalendarAsideContainer
              classGroups={classGroups}
              className="md:col-span-1 md:block hidden"
              id="aside-calendar"
              selectedDate={selectedDate}
            />
          )}
          <CalendarMainContainer
            classGroups={classGroups}
            className={c(windowWidth > 1030 ? 'col-span-4' : 'col-span-5')}
            handleOpenModalCalendarItem={handleOpenModalCalendarItem}
            selectedDate={selectedDate}
            setEventTimeInfo={setEventTimeInfo}
            setFilteredEvents={setFilteredEventsWithoutDoubles}
            setSelectedDate={setSelectedDate}
            setSelectedEvent={setSelectedEvent}
            setSelectedEventTitle={setSelectedEventTitle}
            updateItemOption={{ isUpdateMode, setIsUpdateMode }}
          />
        </CalendarContext.Provider>
      </div>
    </div>
  );
}
