import { gql } from 'graphql-request';

export const TEACHER_PROGRAMS = gql`
  query teacherPrograms {
    teacherPrograms {
      id
      name
      type
      color
      weight
      blobUri
      document
      chapters {
        id
        name
        programId
        weight
        blobUri
        document
        tags {
          id
          name
          color
        }
        items {
          id
          name
          position
          weight
          chapterId
          blobUri
          document
          calendarItems {
            classGroups {
              id
              name
            }
            startTime
            endTime
            sessionId
            viewMode
          }
          lessons {
            id
            title
            studioId
            version
            status
            type
            blobUri
            imageUri
            organizationId
            copiedBy {
              id
              userName
              firstName
              lastName
              fullName
              displayName
              language
              email
              organizationId
              enabled
              roles
            }
            tags
            groupRoles {
              id
              role
            }
          }
          tags {
            id
            name
            color
          }
        }
      }
    }
  }
`;
