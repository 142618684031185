import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_ORGANIZATION_BY_ID } from 'api/plannerService/admin/queries/adminOrganizationById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminOrganizationById(organizationId) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['adminOrganizationById', organizationId],
    () =>
      plannerServiceQueryClient.request(ADMIN_ORGANIZATION_BY_ID, {
        organizationId,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.ORGANIZATION.singular'),
        });
      },
    },
  );

  return {
    ...response,
    data: response.data?.adminOrganizationById,
  };
}
