import { gql } from 'graphql-request';

export const ADMIN_PROGRAM_BY_ID = gql`
  query adminProgramById($programId: Int!) {
    adminProgramById(programId: $programId) {
      id
      name
      color
      blobUri
      type
      weight
      chapters {
        id
        name
        programId
        blobUri
        document
        hidden
        weight
        tags {
          id
          name
          color
        }
        items {
          id
          name
          chapterId
          blobUri
          document
          weight
          studioId
          tags {
            id
            name
            color
          }
          lessons {
            id
          }
        }
      }
    }
  }
`;
