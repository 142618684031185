import { useContext } from 'react';
import { UserPlusIcon } from '@heroicons/react/24/solid';
// the below is suppressed 'cause it is in the project dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { PeriodsContext } from 'providers/PeriodsProvider';
import {
  addActionsIfNotFtrprfSchoolAdmin,
  getInitialActionsForUser,
} from './partials/helperFunctions';
import { ManageUsersFilters } from './partials/ManageUsersFilterAndActionBar';
import { ManageUsersGlobalActions } from './partials/ManageUsersGlobalActions';
import { ManageUsersTable } from './partials/ManageUsersTable';
import { dataTestIds } from '../../../utils/dataTestIds';

export default function ManageUsers({
  currentPage,
  defaultRoles,
  enableUser,
  isLoadingUsers,
  itemsPerPage,
  onClearFilters,
  openCreateUserModal,
  openDisableUserModal,
  openResetModal,
  searchParams,
  setIsLinkTeachersModalOpen,
  setItemsPerPage,
  setPage,
  setSearchParams,
  setUserData,
  totalItems,
  totalPages,
  users,
}) {
  const { isSmartSchoolUser } = useContext(UserContext);
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const t = useFormatMessage();
  useTitle(t('manage.title'), t('global.USER.plural'));

  const displayRoleFn = (role) => t(`global.${role}.singular`);

  const actionsForUser = (user) => {
    const possibleActions = getInitialActionsForUser(
      searchParams,
      setUserData,
      t,
      user,
    );

    if (!isSmartSchoolUser) {
      if (!user.roles.includes('FTRPRF_SCHOOLADMIN')) {
        addActionsIfNotFtrprfSchoolAdmin(
          openDisableUserModal,
          openResetModal,
          possibleActions,
          selectedPeriodIsActivePeriod,
          setUserData,
          t,
          user,
          enableUser,
        );
      }

      if (!user.enabled && selectedPeriodIsActivePeriod) {
        possibleActions.push({
          disabled: user.isSmartschoolUser,
          icon: <UserPlusIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('global.activate'),
          onClick: () => {
            enableUser(user);
          },
          testName: dataTestIds.page.manage.users.actions.enable,
        });
      }
    }

    return possibleActions;
  };

  const paginationInfo = {
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    setPage,
    totalItems,
    totalPages,
  };

  return (
    <>
      <div className="w-full flex justify-between mb-4">
        <ManageUsersFilters
          defaultRoles={defaultRoles}
          displayRoleFn={displayRoleFn}
          onClearFilters={onClearFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        {!isSmartSchoolUser && selectedPeriodIsActivePeriod && (
          <ManageUsersGlobalActions
            openCreateUserModal={openCreateUserModal}
            setIsLinkTeachersModalOpen={setIsLinkTeachersModalOpen}
          />
        )}
      </div>
      <ManageUsersTable
        actionsForUser={actionsForUser}
        displayRoleFn={displayRoleFn}
        isLoadingUsers={isLoadingUsers}
        paginationInfo={paginationInfo}
        searchParams={searchParams}
        users={users}
      />
    </>
  );
}
