import {
  Badge,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import { sortObjectArrayAlphabetically } from 'utils/sortObjectArrayAlphabetically';
import { prettyOrderBadgeColours } from 'utils/constants/colors';
import { ChapterItemReportTableColumn } from './ChapterItemReportTableColumn';

export function ChapterItemReportTableRow({
  activeScoreMode,
  activeViewMode,
  allClassGroups,
  chapterReport,
  setDialogContent,
  setIsDialogOpen,
}) {
  const { chapterItems, classGroups, fullName, id } = chapterReport;

  return (
    <ResponsiveTableRow>
      <ResponsiveTableCol className="min-w-64 max-w-64 pl-2" turnHeaders>
        <div>
          <span className="ml-2 truncate">{fullName}</span>
          {classGroups?.length > 0 && (
            <ul className="inline-flex gap-1 overflow-x-auto max-w-full ml-2">
              {sortObjectArrayAlphabetically(classGroups, 'name')
                // Filter out the classgroups that this teacher doesn't have access to. Idealy we just don't get these back from the backend.
                .filter((classgroup) =>
                  allClassGroups.find((group) => group.id === classgroup.id),
                )
                .map((classGroup) => (
                  <li key={`title${classGroup.id}`}>
                    <Badge
                      className="min-w-max"
                      color={
                        prettyOrderBadgeColours[
                          allClassGroups.findIndex(
                            (group) => group.id === classGroup.id,
                          )
                        ]
                      }
                    >
                      {classGroup.name}
                    </Badge>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </ResponsiveTableCol>
      {chapterItems.map((chapterItem, index) => (
        <ChapterItemReportTableColumn
          key={`resultaatkolom_${chapterItem.id}_${index + 1}`}
          activeScoreMode={activeScoreMode}
          activeViewMode={activeViewMode}
          chapterItem={chapterItem}
          index={index}
          setDialogContent={setDialogContent}
          setIsDialogOpen={setIsDialogOpen}
          userId={id}
        />
      ))}
    </ResponsiveTableRow>
  );
}
