import { gql } from 'graphql-request';

export const TEACHER_RESET_STUDENT_PASSWORD = gql`
  mutation teacherResetStudentPassword(
    $studentId: String!
    $newPassword: String!
  ) {
    teacherResetStudentPassword(
      studentId: $studentId
      newPassword: $newPassword
    ) {
      statusCode
      id
      message
    }
  }
`;
