import { useEffect, useState } from 'react';
import { func, string } from 'prop-types';

import {
  DialogActions,
  DialogContent,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useTeacherCreateActivity from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateActivity';
import useFormatMessage from 'hooks/useFormatMessage';

import { SteamsIframeCenteredLoader } from './SteamsIframeCenteredLoader';
import { TeacherGroupCompositionByActivity } from './TeacherGroupCompositionByActivity';

export function GroupSteamsMemberDivider({
  calendarItemId,
  onDismiss,
  submit,
}) {
  const t = useFormatMessage();
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activityId, setActivityId] = useState();
  const { mutateAsync: createActivity } = useTeacherCreateActivity();

  useEffect(() => {
    const createActivityForItem = async (calendarItemId) => {
      const data = await createActivity(calendarItemId);
      if (data?.teacherCreateActivity?.message) {
        setActivityId(data.teacherCreateActivity.message.split('"')[1]);
      }
    };
    createActivityForItem(calendarItemId);
  }, [calendarItemId, createActivity]);

  // triggers when the content of the iframe is loaded entirely
  const onFrameLoaded = () => {
    setLoaded(true);
  };
  const onError = () => {
    // eslint-disable-next-line no-console
    console.error(error);
    setError(true);
  };

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data && e.data === 'success') {
        setSubmitted(true);
        submit();
      }
    });
  }, [setSubmitted, submit]);

  return (
    <>
      <DialogContent className="m-4">
        {!loaded ? <SteamsIframeCenteredLoader /> : null}

        {activityId && !submitted ? (
          <iframe
            height={loaded ? '805' : '5'}
            name="steamsgroupcompositor"
            onError={onError}
            onLoad={onFrameLoaded}
            seamless
            src={`https://steams.cs.kuleuven.be/dashboard/groupcompositor/${activityId}/v1`}
            title={t('groups.compose.divide')}
            width={loaded ? '900' : '5'}
          >
            <p>{t('groups.compose.steams.noiframe')}</p>
          </iframe>
        ) : null}

        {submitted ? (
          <TeacherGroupCompositionByActivity
            activity={activityId}
            setGroups={submit}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          className={submitted ? '' : 'cursor-wait'}
          disabled={!submitted}
          onClick={submit}
          send
          type="submit"
        >
          {t('groups.compose.divider.create')}
        </ActionStyledAsButton>
      </DialogActions>
    </>
  );
}

GroupSteamsMemberDivider.propTypes = {
  calendarItemId: string.isRequired,
  onDismiss: func.isRequired,
  submit: func.isRequired,
};
