import { externalNl } from './external';
import { dutch } from './internal.nl';
import { dutchAvatar } from './avatar.nl';

export default {
  // Internal
  ...dutch,
  // External
  ...externalNl,
  // Avatar from bigheads
  ...dutchAvatar,
};
