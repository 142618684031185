import { FaTimes } from 'react-icons/fa';
import { Transition } from '@headlessui/react';

import c from '../../../utils/c';

import { Button } from '../../buttons/Button';
import SpeechBubbleDot from '../images/speech-bubble-dot.png';
import { dataTestIds } from '../../../utils/constants/dataTestIds';

export default function SpeechBubble({
  children,
  hasDot = true,
  isFacingRight,
  isInline,
  isOpen,
  onClose,
  text,
}) {
  return (
    <Transition
      appear
      enter="transform transition ease-in-out duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={isOpen}
    >
      <div className="relative">
        <div
          className="px-3 py-5 rounded-md bg-white border-black border-2 opacity-100 bottom-0 max-w-md"
          data-test={dataTestIds.component.assistant.speechBubble}
        >
          {text && <p>{text}</p>}
          {children}
        </div>
        {hasDot && (
          <img
            alt="coach-codi-speech-bubble"
            className={c(
              'w-5 h-5 absolute',
              isInline
                ? 'rotate-90 transform -scale-x-100 -mt-7 -ml-4'
                : '-mt-1',
              isFacingRight && !isInline && 'left-3 transform -scale-x-100',
              !isFacingRight && !isInline && 'right-3',
            )}
            src={SpeechBubbleDot}
          />
        )}
        <Button
          className="h-2 w-2 absolute top-2 right-2 opacity-50"
          onClick={onClose}
        >
          <FaTimes />
        </Button>
      </div>
    </Transition>
  );
}
