import { useContext } from 'react';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/c';

import { SlideViewerContext } from '../../slideviewer/SlideViewer';

export default function SideBarSlidesOverview() {
  const t = useFormatMessage();

  const { currentSlide, setSlide, slides } = useContext(SlideViewerContext);

  return (
    <div className="overflow-hidden flex flex-col flex-grow px-4">
      <h3 className="mt-4 mb-1 text-2xl font-bold">
        {t('styled-slide-viewer.table_of_contents')}
      </h3>
      <div className="overflow-y-auto flex-grow flex flex-col">
        {slides.map((slide, index) => (
          <button
            key={slide.id}
            className={c(
              'mb-2 py-1 px-2 text-left transition-all duration-150 ease-in-out rounded',
              currentSlide.id === slide.id
                ? 'bg-accent-100'
                : 'hover:bg-gray-100',
            )}
            onClick={() => setSlide(index)}
            type="button"
          >
            {slide.title?.trim() || (
              <div className="italic text-gray-700">
                {t('styled-slide-viewer.empty_title')}
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
