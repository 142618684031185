import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_RESET_STUDENT_PASSWORD } from 'api/plannerService/teacher/mutations/teacherResetStudentPassword';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherResetStudentPassword() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    ({ newPassword, studentId }) =>
      plannerServiceQueryClient.request(TEACHER_RESET_STUDENT_PASSWORD, {
        studentId,
        newPassword,
      }),
    {
      mutationKey: 'teacherResetStudentPassword',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.password'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.password'),
        });
      },
    },
  );

  return mutation;
}
