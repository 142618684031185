import { gql } from 'graphql-request';

export const ADMIN_CREATE_PERIOD = gql`
  mutation adminCreatePeriod($organizationId: Int, $input: PeriodInput) {
    adminCreatePeriod(organizationId: $organizationId, input: $input) {
      id
      organizationId
      name
      description
      startDate
      endDate
      stats {
        classes
        programs
      }
    }
  }
`;
