import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_UPSERT_USERS_AND_CLASSES_VIA_EXCEL_FILE_UPLOAD } from 'api/plannerService/schooladmin/mutations/schooladminUpsertUsersAndClassesViaExcelFileUpload';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminUpsertUsersAndClassesViaExcelFileUpload() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    (file) =>
      plannerServiceQueryClient.request(
        SCHOOLADMIN_UPSERT_USERS_AND_CLASSES_VIA_EXCEL_FILE_UPLOAD,
        {
          file,
        },
      ),
    {
      mutationKey: 'schooladminUpsertUsersAndClassesViaExcelFileUpload',
      onSuccess: (e) => {
        // if there are no errors
        if (e.schooladminUpsertUsersAndClassesViaExcelFileUpload.length < 1) {
          // send a success toast
          toast(TOASTTYPES.POSTSUCCESS, {
            str: t('global.FILE.singular'),
          });
        }
        // otherwise, send an error toast
        else {
          toast(TOASTTYPES.POSTERROR, {
            str: t('global.FILE.singular'),
          });
        }
      },
    },
  );
  return mutation;
}
