export default function Divider({ text }) {
  return (
    <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <h2 className="bg-white pr-2 text-sm text-gray-500">{text}</h2>
      </div>
    </div>
  );
}
