import { gql } from 'graphql-request';

export const TEACHER_EXPORT_RESULTS = gql`
  mutation teacherExportResults($calendarItemId: Int!, $viewMode: ViewMode!) {
    teacherExportResults(calendarItemId: $calendarItemId, viewMode: $viewMode) {
      statusCode
      id
      message
    }
  }
`;
