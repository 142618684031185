import { useEffect, useMemo, useState } from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import { useCalendarItemById } from 'hooks/api/plannerService/shared/useCalendarItemById';
import { useTeacherStudentsByCalendarItemId } from 'hooks/api/plannerService/teacher/queries/useTeacherStudentsByCalendarItemId';
import useCurrentTab from 'hooks/useCurrentTab';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import { sortOperation } from 'utils/constants/sort';
import URLS, { studentResultTab, userProfileTabs } from 'utils/constants/urls';
import VIEWMODES from 'utils/constants/viewModes';
import { generateStudentResultPageTabPath } from 'utils/generateUrlPaths';
import queryString from 'query-string';
import { defaultCalenderItemsResultsOverviewSearchParams } from 'pages/Learn/CalendarItemResults/partials/defaultSearchParams';
import { useQueryParams } from 'use-query-params';
import { filterOperation } from 'utils/constants/filter';
import { dataTestIds } from 'utils/dataTestIds';
import { FetchLessonReport } from './partials/FetchLessonReport';
import { StudentPager } from './partials/StudentPager';
import { getFirstMatchingClassgroup, getStudentIndex } from './helpers';

export function ResultsForTeacher() {
  const t = useFormatMessage();
  const {
    calendarItemId,
    programId,
    sessionId: lessonSessionId,
    userId,
  } = useParams();
  const currentTab = useCurrentTab();

  const [studentId, setStudentId] = useState(userId);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const [searchParams, setSearchParams] = useQueryParams(
    defaultCalenderItemsResultsOverviewSearchParams,
  );
  const [activeViewMode, setActiveViewMode] = useState(searchParams.viewmode);

  const availableViewModes = [VIEWMODES.CLASSICAL, VIEWMODES.SELFSTUDY];
  const [max, setMax] = useState(0);
  const [denominator, setDenominator] = useState(0);
  const { data: calendarItem } = useCalendarItemById(calendarItemId);
  const [filterClassGroupIds, setFilterClassGroupIds] = useState(
    searchParams.classgroups || [],
  );
  const [currentStudentIndex, setCurrentStudentIndex] = useState();
  const [currentStudent, setCurrentStudent] = useState({});

  const chapterItem = calendarItem?.chapterItem;
  const classGroups = calendarItem?.classGroups;
  const filteredClassGroups = classGroups?.filter(
    (classGroup) => filterClassGroupIds.indexOf(classGroup.id) !== -1,
  );
  const pageTitle = chapterItem
    ? chapterItem?.name
    : calendarItem?.lessonContentTitle;

  const filter = Boolean(filterClassGroupIds?.length)
    ? {
        key: 'classGroupId',
        operation: filterOperation.EQUAL,
        value: filterClassGroupIds,
      }
    : [];

  const { data: studentsData } = useTeacherStudentsByCalendarItemId({
    calendarItemId,
    filter,
    page: 0,
    size: 999,
    sort: { fullname: sortOperation.ASC },
  });

  const students = studentsData?.content;

  useEffect(() => {
    setSearchParams({
      classgroups: filterClassGroupIds,
      viewmode: activeViewMode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterClassGroupIds, activeViewMode]);

  useEffect(() => {
    if (students) setCurrentStudentIndex(getStudentIndex(students, userId));
  }, [students, userId]);

  useEffect(() => {
    if (currentStudentIndex && students)
      setCurrentStudent(students[currentStudentIndex]);
  }, [currentStudentIndex, students]);

  useTitle(
    t('content.results'),
    `${currentStudent?.firstName} ${currentStudent?.lastName}`,
  );

  let firstBreadcrumbLink = useMemo(
    () => ({
      href: generatePath(URLS.CALENDAR_ITEM_RESULTS, {
        calendarItemId,
        programId,
      }),
      name: `${pageTitle}`,
      search: queryString.stringify(searchParams),
    }),
    [calendarItemId, pageTitle, programId, searchParams],
  );

  if (programId === nonNumericProgramIds.CLASS) {
    const classGroup =
      calendarItem?.classGroups?.length === 1 || !currentStudent
        ? calendarItem.classGroups[0]
        : getFirstMatchingClassgroup(
            calendarItem?.classGroups,
            currentStudent.classGroups,
          );

    if (classGroup) {
      firstBreadcrumbLink = {
        href: generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
          classGroupId:
            calendarItem?.classGroups?.length === 1 || !currentStudent
              ? calendarItem.classGroups[0].id
              : classGroup.id,
          studentId,
          tab: userProfileTabs.RESULTS,
        }),
        name: t('results-overview.breadcrumbs.results.for.class', {
          name: classGroup.name,
        }),
      };
    }
  }

  const breadcrumbsLinks = useMemo(
    () =>
      [
        firstBreadcrumbLink,
        currentStudent
          ? {
              name: `${
                currentStudent?.firstName ||
                students?.[currentStudentIndex]?.firstName
              } ${
                currentStudent?.lastName ||
                students?.[currentStudentIndex]?.lastName
              }`,
            }
          : {
              name: t('global.STUDENT.singular'),
            },
      ].filter(Boolean),
    [currentStudent, currentStudentIndex, firstBreadcrumbLink, students, t],
  );

  useEffect(() => {
    setStudentId(userId);
  }, [userId]);

  useEffect(() => {
    if (!activeViewMode) {
      setActiveViewMode(calendarItem?.viewMode);
    }
  }, [activeViewMode, calendarItem]);

  return (
    <GeneralLayout>
      <FetchLessonReport
        activeViewMode={activeViewMode}
        lessonSessionId={lessonSessionId}
        setData={setData}
        setDenominator={setDenominator}
        setError={setError}
        setMax={setMax}
        studentId={studentId}
      />
      <PageHeaderWrapper
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        button={
          currentTab === studentResultTab.RESPONSES && max ? (
            <strong className="text-white" data-test="studentScore">
              <span className="sr-only">
                {t('results-overview.column.score')}:
              </span>
              {` ${denominator.toLocaleString()} / ${max}`}
            </strong>
          ) : null
        }
        buttonOnTitleRow
        marginBottom={false}
        title={pageTitle}
      >
        {Boolean(
          students?.length && calendarItemId && currentStudentIndex >= 0,
        ) && (
          <>
            <StudentPager
              calendarItemId={calendarItemId}
              currentStudentIndex={currentStudentIndex}
              sessionId={lessonSessionId}
              setStudentId={setStudentId}
              students={students}
              tab={currentTab}
            />
            {Boolean(filteredClassGroups?.length) && (
              <div className="flex justify-center items-center">
                <span className="inline-flex items-center gap-x-0.5 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                  {t('results-overview.filter.classes')}
                  <strong>
                    {filteredClassGroups?.map((cg) => cg.name).join(', ')}
                  </strong>
                  <button
                    className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20"
                    onClick={() => {
                      setFilterClassGroupIds([]);
                    }}
                    type="button"
                  >
                    <span className="sr-only">{t('global.remove')}</span>
                    <svg
                      className="h-3.5 w-3.5 stroke-blue-600/50 group-hover:stroke-blue-600/75"
                      viewBox="0 0 14 14"
                    >
                      <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span className="absolute -inset-1" />
                  </button>
                </span>
              </div>
            )}
          </>
        )}

        <TabList>
          <Tab
            label={t('global.RESPONSE.plural')}
            test={dataTestIds.page.results.tabs.exercise}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.RESPONSES,
              userId,
            )}
          />

          <Tab
            label={t('global.SCRATCH_EXERCISE.plural')}
            test={dataTestIds.page.results.tabs.scratch}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.SCRATCH_EXERCISES,
              userId,
            )}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet
          context={[
            {
              activeViewMode,
              availableViewModes,
              calendarItem,
              data,
              denominator,
              error,
              lessonSessionId,
              max,
              setActiveViewMode,
              setData,
              setDenominator,
              setError,
              setMax,
              setStudentId,
              studentId,
            },
          ]}
        />
      </div>
    </GeneralLayout>
  );
}
