import {
  ActionStyledAsButton,
  Filter,
  Input,
  Label,
  Toggle,
} from '@ftrprf/tailwind-components';
import { dataTestIds } from 'utils/dataTestIds';
import useFormatMessage from 'hooks/useFormatMessage';

export function ManageUsersFilters({
  defaultRoles,
  displayRoleFn,
  onClearFilters,
  searchParams,
  setSearchParams,
}) {
  const t = useFormatMessage();

  const handleRoles = (value) => {
    const rolesValues = value.length === 0 ? defaultRoles : value;
    setSearchParams({ roles: rolesValues });
  };

  const handleUsersEnabled = (enabled) => {
    setSearchParams({ enabled: !enabled });
  };

  return (
    <div className="flex items-center">
      <div className="w-5/6 mr-5">
        <Label className="sr-only" htmlFor="input-search-by-fullname">
          {t('global.search.by.name')}
        </Label>
        <Input
          autoComplete="off"
          data-lpignore="true"
          data-test={dataTestIds.page.manage.input.name}
          id="input-search-by-fullname"
          onChange={(e) => {
            setSearchParams({ fullname: e.target.value });
          }}
          placeholder={t('global.search.by.name')}
          value={searchParams.fullname || ''}
        />
      </div>
      <div className="mr-5">
        <Filter
          activeFilters={searchParams.roles}
          allowNoneSelected
          displayOptionFn={displayRoleFn}
          name={t('global.role')}
          options={defaultRoles}
          setFilter={handleRoles}
          test={dataTestIds.page.manage.input.role}
        />
      </div>
      <div className="mr-5">
        <Toggle
          label={t('global.enabled.false')}
          name={t('student-overview.column.status')}
          onChange={handleUsersEnabled}
          test={dataTestIds.page.manage.toggle.showArchived}
          value={!searchParams.enabled}
          visibleLabel={t('global.enabled.false')}
        />
      </div>
      <div>
        <ActionStyledAsButton
          className="whitespace-nowrap"
          onClick={onClearFilters}
          variant="text"
        >
          {t('filter.clear')}
        </ActionStyledAsButton>
      </div>
    </div>
  );
}
