import { useState } from 'react';
import {
  LockOpenIcon,
  PencilIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import { useOutletContext } from 'react-router-dom';

import CreateUserDialog from 'pages/ManageSchoolAdmin/ManageUsers/partials/CreateUserDialog';
import { ResetPasswordDialog } from 'components/ResetPasswordDialog';

import useAdminCreateUser from 'hooks/api/plannerService/admin/mutations/useAdminCreateUser';
import useAdminDeleteUser from 'hooks/api/plannerService/admin/mutations/useAdminDeleteUser';
import { useAdminResetPassword } from 'hooks/api/plannerService/admin/mutations/useAdminResetPassword';
import useAdminUpdateUser from 'hooks/api/plannerService/admin/mutations/useAdminUpdateUser';
import useAdminUsersByOrganizationId from 'hooks/api/plannerService/admin/queries/useAdminUsersByOrganizationId';
import useImpersonateUser from 'hooks/api/plannerService/admin/mutations/useImpersonateUser';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import { dataTestIds } from 'utils/dataTestIds';
import { cloneDeep } from 'lodash-es';
import URLS from 'utils/constants/urls';
import useAdminOrganizationById from 'hooks/api/plannerService/admin/queries/useAdminOrganizationById';
import { SCHOOLADMIN } from 'utils/determineUserRoles';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import ftrprfUserRoles, {
  userRolesForAdminDropdown,
} from '../../../utils/constants/ftrprfUserRoles';
import DeleteUserDialog from './user/DeleteUserDialog';
import EditUserDialog from './user/EditUserDialog';
import { FiltersAndGlobalActions } from './user/FiltersAndGlobalActions';
import { UserTable } from './user/UserTable';
import { useAdminDisableUser } from '../../../hooks/api/plannerService/admin/mutations/useAdminDisableUser';
import { useAdminEnableUser } from '../../../hooks/api/plannerService/admin/mutations/useAdminEnableUser';

export default function ManageOrganizationUsers() {
  // constants
  const t = useFormatMessage();
  const { organizationId } = useParams();
  const defaultValues = {
    roles: withDefault(ArrayParam, userRolesForAdminDropdown),
  };
  // state
  const [fullnameFilterState, setFullnameFilterState] = useState('');
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useQueryParams(defaultValues);
  const [selectedRolesState, setSelectedRolesState] = useState(
    queryParams.roles || userRolesForAdminDropdown,
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDisabled, setShowDisabled] = useState(false);
  const [userData, setUserData] = useState();
  const { isLoading: isResetting, mutateAsync: resetPassword } =
    useAdminResetPassword();
  const { refetchOrganisationInfo } = useOutletContext();

  // debounce
  const debouncedFullnameFilter = useDebounce(fullnameFilterState, 300);

  // fetch hooks
  const { data: organizationData } = useAdminOrganizationById(organizationId);
  const {
    data: usersData,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useAdminUsersByOrganizationId({
    organizationId,
    filter: [
      {
        key: 'enabled',
        value: !showDisabled,
        operation: filterOperation.EQUAL,
      },
      {
        key: 'fullname',
        value: debouncedFullnameFilter,
        operation: filterOperation.LIKE,
      },
      {
        key: 'roles',
        value: queryParams.roles,
        operation: filterOperation.EQUAL,
      },
    ].filter((i) => Boolean(i.value)),
    page: page - 1,
    size: itemsPerPage,
    sort: { firstname: sortOperation.ASC },
  });

  // mutation hooks
  const { isLoading: isLoadingSaveUser, mutateAsync: addUserHook } =
    useAdminCreateUser();
  const { isLoading: isLoadingDisableUser, mutateAsync: disableUserHook } =
    useAdminDisableUser();
  const { isLoading: isLoadingEnableUser, mutateAsync: enableUserHook } =
    useAdminEnableUser();
  const { isLoading: isLoadingDeleteUser, mutateAsync: removeUserHook } =
    useAdminDeleteUser();
  const { isLoading: isLoadingUpdateUser, mutateAsync: updateUserHook } =
    useAdminUpdateUser();

  const setFullnameFilter = (value) => {
    setPage(1);
    setFullnameFilterState(value);
  };
  const setSelectedRoles = (value) => {
    setPage(1);
    setSelectedRolesState(value);
    setQueryParams({ roles: value });
  };

  // mutation hook wrappers
  const refetchAndDismiss = (dismiss) => {
    refetchOrganisationInfo();
    refetchUsers();
    if (dismiss) dismiss();
  };
  const addUser = async (data, dismiss) => {
    await addUserHook(data).then(() => {
      refetchAndDismiss(dismiss);
    });
  };
  const deleteUser = async (dismiss) => {
    await removeUserHook(selectedUser?.id).then(() => {
      refetchAndDismiss(dismiss);
    });
  };
  const disableUser = async (id) => {
    await disableUserHook(id || selectedUser?.id).then(() => {
      refetchAndDismiss();
    });
  };
  const enableUser = async (id) => {
    await enableUserHook(id || selectedUser?.id).then(() => {
      refetchAndDismiss();
    });
  };
  const updateUser = async (data, dissmis) => {
    const input = data;
    input.displayName = `${data?.firstName} ${data?.lastName}`;
    input.language = organizationData.language.toUpperCase();

    await updateUserHook({ id: selectedUser.id, input }).then(() => {
      refetchUsers();
      dissmis();
    });
  };
  const { isLoading: isLoadingImpersonateUser, mutateAsync: impersonateUser } =
    useImpersonateUser();
  const handleImpersonateUserClick = (user) => {
    impersonateUser(user.id).then(() => {
      localStorage.clear();
      window.location = `${process.env.REACT_APP_AD_REDIRECT_LINK}${URLS.IMPERSONATE}`;
    });
  };

  const displayRoleFn = (role) => t(`global.${role}.singular`);

  const {
    content: users,
    pages: totalPages,
    total: totalItems,
  } = usersData || { content: [], pages: 0, total: 0 };

  const actionsForOrganisation = (user) => {
    const actionsForUser = [
      {
        icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.edit'),
        testName: dataTestIds.page.organisationProfile.table.button.edit,
        onClick: () => {
          setSelectedUser(user);
          setOpenModalEditUser(true);
        },
      },
      {
        icon: <LockOpenIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.reset-password'),
        testName:
          dataTestIds.page.organisationProfile.table.button.resetPassword,
        onClick: () => {
          setUserData(user);
          setIsResetPasswordDialogOpen(true);
        },
      },
      {
        disabled: isLoadingImpersonateUser,
        icon: <UsersIcon className="h-4 w-4 inline-block mr-2" />,
        testName: dataTestIds.page.organisationProfile.table.button.impersonate,
        label: t('global.impersonate'),
        onClick: () => handleImpersonateUserClick(user),
      },
    ];

    if (user.enabled) {
      actionsForUser.push({
        disabled: isLoadingDisableUser,
        icon: <UserMinusIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('users-overview.disable'),
        testName: dataTestIds.page.organisationProfile.table.button.disable,
        onClick: () => disableUser(user.id),
      });
    } else {
      actionsForUser.push({
        disabled: isLoadingEnableUser,
        icon: <UserPlusIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('users-overview.enable'),
        testName: dataTestIds.page.organisationProfile.table.button.enable,
        onClick: () => enableUser(user.id),
      });
    }

    actionsForUser.push({
      icon: <XCircleIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.delete'),
      testName: dataTestIds.page.organisationProfile.table.button.delete,
      onClick: () => {
        setSelectedUser(user);
        setOpenModalDeleteUser(true);
      },
    });

    return actionsForUser;
  };
  const paginationVariables = {
    itemsPerPage,
    page,
    setItemsPerPage,
    setPage,
    totalItems,
    totalPages,
  };

  return (
    <>
      {!isLoadingUsers && (
        <>
          {Boolean(organizationData) && (
            <CreateUserDialog
              isLoading={isLoadingSaveUser}
              isOpen={isCreateUserModalOpen}
              onConfirm={(data, onDismiss) => {
                const clone = cloneDeep(data);
                clone.idp = 'FTRPRF';
                clone.organizationId = `${organizationData.id}`;
                clone.roles = [ftrprfUserRoles.SCHOOLADMIN];

                // eslint-disable-next-line no-param-reassign
                data = clone;

                addUser(data, onDismiss);
              }}
              onDismiss={() => {
                setIsCreateUserModalOpen(false);
              }}
              organization={organizationData}
              role={SCHOOLADMIN}
            />
          )}

          <DeleteUserDialog
            deleteUser={deleteUser}
            disableUser={disableUser}
            isLoadingDeleteUser={isLoadingDeleteUser || isLoadingDisableUser}
            isOpen={openModalDeleteUser}
            selectedUser={selectedUser}
            setOpenModalDeleteUser={setOpenModalDeleteUser}
            setSelectedUser={setSelectedUser}
          />

          <EditUserDialog
            isLoadingUpdateUser={isLoadingUpdateUser}
            isOpen={openModalEditUser}
            selectedUser={selectedUser}
            setOpenModalEditUser={setOpenModalEditUser}
            setSelectedUser={setSelectedUser}
            updateUser={updateUser}
          />

          {isResetPasswordDialogOpen && (
            <ResetPasswordDialog
              isOpen={isResetPasswordDialogOpen}
              isResetting={isResetting}
              onDismiss={() => setIsResetPasswordDialogOpen(false)}
              resetPassword={(newPassword) => {
                void resetPassword({
                  id: userData.id,
                  newPassword,
                });
              }}
            />
          )}
        </>
      )}

      <FiltersAndGlobalActions
        displayRoleFn={displayRoleFn}
        isLoadingUsers={isLoadingUsers}
        selectedRolesState={selectedRolesState}
        setFullnameFilter={setFullnameFilter}
        setIsCreateUserModalOpen={setIsCreateUserModalOpen}
        setSelectedRoles={setSelectedRoles}
        setShowDisabled={setShowDisabled}
        showDisabled={showDisabled}
      />

      <UserTable
        actionsForOrganisation={actionsForOrganisation}
        displayRoleFn={displayRoleFn}
        isLoading={isLoadingUsers || isFetchingUsers}
        paginationVariables={paginationVariables}
        users={users}
      />
    </>
  );
}
