import { dataTestIds } from 'utils/dataTestIds';

import SkeletonConstructor, { SKELETON_OPTIONS } from './SkeletonConstructor';

export default function ListSkeleton({
  amount = 10,
  children,
  loading,
  size = SKELETON_OPTIONS.LINE_H_MD,
}) {
  return loading ? (
    <div data-test={dataTestIds.component.loader}>
      <SkeletonConstructor
        className="mt-4"
        layout={`1${SKELETON_OPTIONS.LINE_H_SM} ${amount}${size}`}
      />
    </div>
  ) : (
    children
  );
}
