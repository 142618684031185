import { NoResults } from '../NoResults';

import { QuestionItem } from './QuestionItem';
import { QuestionItemForStudent } from './QuestionItemForStudent';

export function Questions({ earned, isStudent, questions, setDenominator }) {
  return questions?.length ? (
    <ul className="w-full" data-test="resultsForStudentOnTest">
      {questions?.map((question, index) =>
        isStudent ? (
          <QuestionItemForStudent
            key={question.questionId}
            index={index}
            question={question}
          />
        ) : (
          <QuestionItem
            key={question.questionId}
            index={index}
            question={question}
            total={earned}
            updateTotal={setDenominator}
          />
        ),
      )}
    </ul>
  ) : (
    <NoResults textKey="results_overview.calendar_item.no_results" />
  );
}
