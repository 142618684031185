import { gql } from 'graphql-request';

export const SCHOOLADMIN_CLASS_GROUPS = gql`
  query schooladminClassGroups(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    schooladminClassGroups(
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        name
        archived
      }
    }
  }
`;
