import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_CREATE_CLASS_GROUP } from 'api/plannerService/schooladmin/mutations/schooladminCreateClassGroup';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminCreateClassGroup() {
  const toast = useToast();
  const t = useFormatMessage();

  const mutation = useMutation(
    (name) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_CREATE_CLASS_GROUP, {
        name,
      }),
    {
      mutationKey: 'schooladminCreateClassGroup',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.CLASS.singular'),
        });
      },
    },
  );

  return mutation;
}
