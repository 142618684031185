import { addDays } from 'date-fns';

import useUpdateNotificationStatus from 'hooks/api/plannerService/common/mutations/useUpdateNotificationStatus';
import NotificationLanguage from 'hooks/NotificationLanguage';
import useFormatMessage from 'hooks/useFormatMessage';

const DAYS_NOTIFICATIONS_ARE_SNOOZED = 3;

export default function NotificationDisplay({ notification, onDismiss }) {
  const t = useFormatMessage();
  const { mutateAsync: updateNotificationStatus } =
    useUpdateNotificationStatus();
  const translatedNotification = NotificationLanguage(notification);

  return (
    <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200">
      <div className="flex flex-col items-center p-4">
        <h2 className="text-sm font-medium text-gray-900 self-start">
          {translatedNotification.title}
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          {translatedNotification.body}
        </p>
      </div>
      <div className="flex flex-col divide-y divide-gray-200">
        <button
          className="flex-1 flex w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 text-black hover:underline hover:underline-offset-4 items-center text-sm font-medium  focus:outline-none focus:ring-inset focus:z-10 focus:ring-2 focus:ring-black"
          onClick={() => {
            void updateNotificationStatus({
              id: translatedNotification.id,
              snoozeUntil: addDays(new Date(), DAYS_NOTIFICATIONS_ARE_SNOOZED),
            });
            onDismiss();
          }}
          type="button"
        >
          {t('global.snooze')}
        </button>
        <div className="w-full rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent-500">
          <a
            className="text-accent-600 hover:text-accent-500 hover:underline hover:underline-offset-4 "
            href={translatedNotification.actionUrl}
            onClick={() => {
              void updateNotificationStatus({
                id: translatedNotification.id,
                canceled: true,
              });
              onDismiss();
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('global.view')}
          </a>
        </div>
      </div>
    </div>
  );
}
