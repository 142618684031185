import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_STUDENTS_BY_CALENDAR_ITEM_ID } from 'api/plannerService/teacher/queries/teacherStudentsByCalendaritem';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export const useTeacherStudentsByCalendarItemId = ({
  calendarItemId,
  filter,
  page,
  size,
  sort,
}) => {
  const toast = useToast();
  const t = useFormatMessage();

  const info = useQuery(
    ['teacherStudents', calendarItemId, filter, page, size, sort],
    () =>
      plannerServiceQueryClient.request(TEACHER_STUDENTS_BY_CALENDAR_ITEM_ID, {
        calendarItemId,
        filter,
        page,
        size,
        sort,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.STUDENT.plural'),
        });
      },
    },
  );

  return {
    ...info,
    data: info?.data?.teacherStudentsByCalendarItemId,
  };
};
