import { generatePath, Outlet } from 'react-router-dom';
import { BookOpenIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline';
import { Stats } from '@ftrprf/tailwind-components';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { Tab, TabList } from 'components/Tab';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useAdminOrganizations from 'hooks/api/plannerService/admin/queries/useAdminOrganizations';
import useAdminPrograms from 'hooks/api/plannerService/admin/queries/useAdminPrograms';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS, { adminTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';

const MAX_ITEMS = 10;

export default function Manage() {
  const t = useFormatMessage();

  const { data: organizationsData } = useAdminOrganizations({
    filter: [],
    page: 0,
    size: MAX_ITEMS,
  });

  const { data: programsData } = useAdminPrograms({
    filter: [],
    page: 0,
    size: MAX_ITEMS,
  });

  return (
    <GeneralLayout>
      <PageHeaderWrapper
        borderBottom={false}
        marginBottom={false}
        title={t('manage.title')}
      >
        <div className="mt-5 flex gap-2 p-1">
          <Stats
            href={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.ORGANIZATIONS,
            })}
            logo={<BuildingLibraryIcon className="h-5 w-5 text-white" />}
            name={t('global.ORGANIZATION.plural')}
            test={dataTestIds.page.manage.button.stat.organisations}
            total={organizationsData?.total || '0'}
          />

          <Stats
            href={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.PROGRAMS,
            })}
            logo={<BookOpenIcon className="h-5 w-5 text-white" />}
            name={t('global.PROGRAM.plural')}
            test={dataTestIds.page.manage.button.stat.programs}
            total={programsData?.total || '0'}
          />
        </div>

        <TabList>
          <Tab
            label={t('global.ORGANIZATION.plural')}
            test={dataTestIds.page.manage.button.tab.organisations}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.ORGANIZATIONS,
            })}
          />
          <Tab
            label={t('global.PROGRAM.plural')}
            test={dataTestIds.page.manage.button.tab.programs}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.PROGRAMS,
            })}
          />
          <Tab
            label={t('global.REPORT.plural')}
            test={dataTestIds.page.manage.button.tab.reports}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.REPORTS,
            })}
          />
          <Tab
            label={t('global.TAG.plural')}
            test={dataTestIds.page.manage.button.tab.tags}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: adminTabs.TAGS,
            })}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet />
      </div>
    </GeneralLayout>
  );
}
