import { AvatarSection } from './AvatarSection';
import { AvatarDropdownProperty } from './AvatarDropdownProperty';
import { shouldReturnNullForBodyProperties } from './shouldReturnNullForBodyProperties';
import useFormatMessage from '../../../../../hooks/useFormatMessage';

export function AvatarBodySettings({ props, section, updateProp }) {
  const t = useFormatMessage();

  return (
    <AvatarSection name={t('avatar.section.body')}>
      {Object.entries(section).map(([key, map]) => {
        if (shouldReturnNullForBodyProperties(key, props)) return null;

        return (
          <AvatarDropdownProperty
            key={key}
            id={key}
            map={map}
            props={props}
            updateProp={updateProp}
          />
        );
      })}
    </AvatarSection>
  );
}
