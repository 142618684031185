import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { SettingsBeingRetrieved } from './partials/SettingsBeingRetrieved';
import TeamsCalendarSettings from './partials/TeamsCalendarSettings';

export function TeamsPreferences() {
  const { settings } = useContext(UserContext);

  if (!settings) {
    return <SettingsBeingRetrieved />;
  }

  return (
    <div className="h-full w-full p-4 border border-gray-300 rounded flex gap-14 flex-col">
      <TeamsCalendarSettings />
    </div>
  );
}
