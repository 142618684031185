export function shouldReturnNullForFacialProperties(key, props) {
  if (key === 'lipColor' && props.mouth !== 'lips') return true;

  if (key === 'hatColor' && props.hat.startsWith('none')) return true;

  if (
    key === 'hairColor' &&
    props.hair === 'none' &&
    (props.facialHair !== 'mediumBeard' || props.faceMask)
  ) {
    return true;
  }

  // noinspection RedundantIfStatementJS
  if (key === 'faceMaskColor' && !props.faceMask) return true;

  return false;
}
