import useFormatMessage from '../../hooks/useFormatMessage';

import de from './translations/de';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import nl from './translations/nl';
import { InfoBar } from '../InfoBar/InfoBar';

const messages = [
  {
    regex: /^\/schoolteacherprofile\/exams$/,
    tranlationKey: 'demo-info-bar.exams',
  },
  {
    regex: /^\/schoolteacherprofile\/exercises$/,
    tranlationKey: 'demo-info-bar.exercises',
  },
  {
    regex: /\//,
    tranlationKey: 'demo-info-bar.general',
  },
];

export function DemoInfoBar({ container, path }) {
  const t = useFormatMessage();
  const message = messages.find(({ regex }) => regex.test(path));

  if (!message) {
    return null;
  }

  return (
    <InfoBar
      className="max-w-screen-sm"
      container={container}
      text={t(message.tranlationKey)}
    />
  );
}

export const DemoInfoBarTranslations = {
  de,
  en,
  es,
  fr,
  nl,
};
