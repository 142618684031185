import c from '../utils/c';

export function Content({
  centerHorizontally = true,
  children = null,
  className = '',
  hasPaddingBottom = true,
}) {
  return (
    <div
      className={c(
        'w-full max-w-5/6 my-4 flex flex-1',
        centerHorizontally && 'mx-auto',
        hasPaddingBottom && 'pb-8',
        className,
      )}
    >
      {children}
    </div>
  );
}
