import { setRolesForGroup } from './setRolesForGroup';

/**
 * Clear roles from the moved member provided it's being moved to a
 * different group
 *
 * Add roles from movedMember to the originating group if
 * - it's being moved to a different group
 * - there are still members in the group it came from
 */
export const adjustRolesAfterMemberWasMoved = function (
  movedMember,
  groups,
  sourceGroupIndex,
  destinationGroupIndex,
) {
  if (!(destinationGroupIndex === sourceGroupIndex)) {
    const roles = [...movedMember.roles];
    // eslint-disable-next-line no-param-reassign
    movedMember.roles = [];

    if (groups[sourceGroupIndex].length)
      setRolesForGroup(groups[sourceGroupIndex], roles);
  }
};
