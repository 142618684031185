import { useContext, useEffect } from 'react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Datepicker, formatDate, locale } from '@mobiscroll/react';
import { addDays, subDays } from 'date-fns';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowWidth } from '@ftrprf/hooks';
import { Button, ActionStyledAsButton } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { ClassGroupFilterButton } from './ClassGroupFilterButton';

export function CustomNavMobile({
  calendarData,
  changeView,
  classButtonClick,
  getWeekRange,
  handleOpenModalCalendarItem,
  navigatePagePerDay,
  navigatePagePerWeek,
  selectedDate,
  selectedPeriodIsActivePeriod,
  setSelectedDate,
  showWeekend,
  showsExtendedTimes,
  toggleExtendedTimes,
  toggleWeekDisplay,
}) {
  const windowWidth = useWindowWidth();
  const { isTeacher, language } = useContext(UserContext);
  const t = useFormatMessage();
  const today = new Date();
  const { classGroups, setRange, setSelectedTeacher, teachers } = calendarData;

  useEffect(() => {
    const firstDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1,
    );

    const lastDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0,
    );
    setRange(subDays(firstDay, 7), addDays(lastDay, 7));
  }, [selectedDate, setRange]);

  return (
    <div className="w-full">
      <div className="flex justify-between w-full">
        <div className="font-bold mb-4 ml-4 self-center w-24">
          <Datepicker
            controls={['calendar']}
            cssClass={{ border: 'none' }}
            inputComponent="input"
            inputProps={
              windowWidth > 800
                ? { placeholder: getWeekRange(selectedDate) }
                : {
                    placeholder: formatDate('DD/MM/YYYY', today),
                  }
            }
            locale={locale[language]}
            marked={
              isTeacher
                ? calendarData.filteredEvents.map((event) => ({
                    date: event.start,
                    color: event.color,
                  }))
                : calendarData.events.map((event) => ({
                    date: event.start,
                    color: event.color,
                  }))
            }
            onChange={(e) => setSelectedDate(e.value)}
            onPageChange={changeView}
            onPageLoading={changeView}
            theme="ios"
            themeVariant="light"
            touchUi
            value={selectedDate}
          />
        </div>
        {isTeacher && (
          <div className="mb-4 flex justify-center gap-1">
            {selectedPeriodIsActivePeriod && (
              <ActionStyledAsButton onClick={handleOpenModalCalendarItem}>
                <PlusIcon className="h-6 w-6" />
                <span className="sr-only">{t('calendar.modal.title')}</span>
              </ActionStyledAsButton>
            )}
            <ActionStyledAsButton onClick={toggleExtendedTimes}>
              <ClockIcon className="h-6 w-6" />
              <span className="sr-only">
                {t(
                  showsExtendedTimes
                    ? 'calendar.modal.extended'
                    : 'calendar.modal.normal',
                )}
              </span>
            </ActionStyledAsButton>
            <ActionStyledAsButton onClick={toggleWeekDisplay}>
              <CalendarIcon className="h-6 w-6" />
              <span className="sr-only">
                {t(
                  showWeekend
                    ? 'calendar.modal.workweek'
                    : 'calendar.modal.week',
                )}
              </span>
            </ActionStyledAsButton>
          </div>
        )}
        <div className="flex mb-4 justify-end self-center">
          <Button
            className="p-1 rounded-xl"
            iconBefore={ChevronLeftIcon}
            onClick={() =>
              windowWidth > 800
                ? navigatePagePerWeek(true)
                : navigatePagePerDay(true)
            }
          />
          <ActionStyledAsButton
            onClick={() => {
              setSelectedDate(new Date());
            }}
          >
            {t('global.today')}
          </ActionStyledAsButton>
          <Button
            className="p-1 ml-1"
            iconBefore={ChevronRightIcon}
            onClick={() =>
              windowWidth > 800
                ? navigatePagePerWeek(false)
                : navigatePagePerDay(false)
            }
          />
        </div>
      </div>
      {windowWidth <= 800 && (
        <>
          {isTeacher && (
            <ul className="flex gap-2 ml-4 pb-2 mb-2 overflow-x-auto">
              {classGroups.map((classGroup) => (
                <ClassGroupFilterButton
                  key={classGroup.name}
                  isSelected={calendarData.selectedClasses.includes(classGroup)}
                  onClick={() => classButtonClick(classGroup)}
                >
                  {classGroup.name}
                </ClassGroupFilterButton>
              ))}
            </ul>
          )}
          <ul className="flex gap-2 ml-4 pb-2 mb-2 overflow-x-auto">
            {teachers.map((teacher) => (
              <ClassGroupFilterButton
                key={teacher.name}
                isSelected={calendarData.selectedTeachers.includes(teacher)}
                onClick={() => setSelectedTeacher(teacher)}
              >
                {teacher.name}
              </ClassGroupFilterButton>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
