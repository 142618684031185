/* eslint-disable react/jsx-props-no-spreading */
// the above disable is due to the props spreading from the droppable plugin
import { useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { generatePath } from 'react-router-dom';
import {
  PencilIcon,
  PlusIcon,
  TagIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import {
  ActionStyledAsButton,
  ConfirmationDialog,
  Content,
  MultiCollapsable,
  PageHeader,
} from '@ftrprf/tailwind-components';

import { EmptyState } from 'components/EmptyState';

import { UserContext } from 'providers/UserProvider';

import useAdminCreateChapter from 'hooks/api/plannerService/admin/mutations/useAdminCreateChapter';
import useAdminDeleteChapter from 'hooks/api/plannerService/admin/mutations/useAdminDeleteChapter';
import useAdminUpdateChapter from 'hooks/api/plannerService/admin/mutations/useAdminUpdateChapter';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';

import useAdminCreateChapterItem from 'hooks/api/plannerService/admin/mutations/useAdminCreateChapterItem';
import useAdminUpdateChapterItem from 'hooks/api/plannerService/admin/mutations/useAdminUpdateChapterItem';
import useTitle from 'hooks/useTitle';
import {
  droppedOutsideTheDroppable,
  hasADifferentPosition,
  setItemWeight,
} from '../helpers';

import UpdateChapterDialog from '../partials/UpdateChapterDialog';
import AddChapterDialog from '../partials/AddChapterDialog';
import AddChapterItemDialog from '../partials/AddChapterItemDialog';
import ProgramLoadingSkeleton from './partials/ChapterSkeleton';
import { ProgramPager } from './partials/ProgramPager';
import ManageTagOnItemDialog from '../partials/ManageTagOnItemDialog';
import { ChapterItem } from './partials/ChapterItem';

export default function Chapter({
  currentProgram,
  isLoading,
  programs,
  refetch,
}) {
  const { programId } = useParams();

  const t = useFormatMessage();
  const [chapterId, setChapterId] = useState(null);
  const [chapterItemId, setChapterItemId] = useState(null);
  const chapters = useMemo(
    () => currentProgram?.chapters ?? [],
    [currentProgram?.chapters],
  );
  const chapter = useMemo(
    () => chapters.find((item) => item.id === chapterId) ?? null,
    [chapterId, chapters],
  );
  const chapterItem = useMemo(() => {
    if (chapterItemId) {
      for (const chapter of chapters) {
        const chapterItem = chapter?.items.find(
          (item) => item.id === chapterItemId,
        );
        if (chapterItem) {
          return chapterItem;
        }
      }
    }

    return null;
  }, [chapterItemId, chapters]);

  const [openedChapter, setOpenedChapter] = useState(null);
  const [isAddChapterModalOpen, setIsAddChapterModalOpen] = useState(false);

  const [isDeleteChapterModalOpen, setIsDeleteChapterModalOpen] =
    useState(false);
  const [isUpdateChapterModalOpen, setIsUpdateChapterModalOpen] =
    useState(false);
  const [isAddChapterItemModalOpen, setIsAddChapterItemModalOpen] =
    useState(false);

  const [isManageTagOnItemModalOpen, setIsManageTagOnItemModalOpen] =
    useState(false);
  const { color, name } = currentProgram ?? {};

  chapters?.sort(sortByWeightThenByName);
  const { isAdmin } = useContext(UserContext);

  const { mutateAsync: adminCreateChapter } = useAdminCreateChapter();
  const { mutateAsync: adminUpdateChapter } = useAdminUpdateChapter();
  const { mutateAsync: adminDeleteChapter } = useAdminDeleteChapter();

  const { mutateAsync: adminCreateChapterItem } = useAdminCreateChapterItem();

  const addChapter = async (data, file) => {
    await adminCreateChapter({ programId, input: data, file });
    refetch();
  };
  const editChapter = async (data, file) => {
    await adminUpdateChapter({ id: chapterId, input: data, file });
    refetch();
  };
  const removeChapter = async () => {
    await adminDeleteChapter({ id: chapterId });
    refetch();
  };

  const adminAddChapterItem = async (data, file) => {
    await adminCreateChapterItem({ chapterId, input: data, file });
    refetch();
  };

  const actionsForChapter = (chapter) => {
    const { id } = chapter;

    const actions = [];
    if (isAdmin) {
      actions.push(
        {
          icon: <PlusIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('manage-program-details.add-chapter-item'),
          testName: dataTestIds.page.manage.table.button.addChild,
          onClick: () => {
            setChapterId(id);
            setIsAddChapterItemModalOpen(true);
          },
        },
        {
          icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('manage-program-details.edit-chapter'),
          testName: dataTestIds.page.manage.table.button.edit,
          onClick: () => {
            setChapterId(id);
            setIsUpdateChapterModalOpen(true);
          },
        },
        {
          icon: <TagIcon className="h-4 w-4 inline-block mr-2" />,
          label: `${t('global.manage.what', {
            what: t('global.TAG.plural'),
          })}`,
          testName: dataTestIds.page.manage.table.button.manageTag,
          onClick: () => {
            setChapterId(id);
            setIsManageTagOnItemModalOpen(true);
          },
        },
        {
          icon: <TrashIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('manage-program-details.delete-chapter'),
          testName: dataTestIds.page.manage.table.button.delete,
          onClick: () => {
            setChapterId(id);
            setIsDeleteChapterModalOpen(true);
          },
        },
      );
    }

    return actions;
  };

  const breadcrumbsLinks = [
    {
      href: generatePath(URLS.MANAGE_TAB, { tab: 'programs' }),
      name: t('global.PROGRAM.plural'),
    },
    {
      href: generatePath(URLS.MANAGE_PROGRAM_DETAILS, { programId }),
      name,
      current: true,
    },
  ];

  useTitle(name);

  const { mutateAsync: adminUpdateChapterItem } = useAdminUpdateChapterItem();
  const editChapterItem = async (data, file) => {
    await adminUpdateChapterItem({
      id: chapterItemId,
      input: data,
      file,
    });
    refetch();
  };

  const [openedChapterItems, setOpenedChapterItems] = useState();

  const onDragStartChapterItem = ({ draggableId }) => {
    const chapter = chapters.filter(
      (chapter) => chapter.id === openedChapter,
    )[0];
    setOpenedChapterItems(chapter.items);
    const chapterItem = chapter.items.filter(
      (chapterItem) => chapterItem.id === Number(draggableId),
    )[0];
    setChapterItemId(chapterItem.id);
  };

  const onDragEndChapterItem = ({ destination, source }) => {
    if (droppedOutsideTheDroppable(destination)) {
      return;
    }

    if (hasADifferentPosition(destination, source)) {
      setItemWeight(openedChapterItems, destination, source);

      void editChapterItem({
        name: chapterItem.name,
        weight: chapterItem.weight,
        studioId: chapterItem.studioId,
      });
    }
  };

  const onDragStartChapter = ({ draggableId }) => {
    const chapter = chapters.filter(
      (chapter) => chapter.id === Number(draggableId),
    )[0];

    const { id } = chapter;
    setChapterId(id);
  };

  const onDragEndChapter = ({ destination, source }) => {
    if (droppedOutsideTheDroppable(destination)) {
      return;
    }

    if (hasADifferentPosition(destination, source)) {
      setItemWeight(chapters, destination, source);

      void editChapter({
        name: chapter.name,
        weight: chapter.weight,
      });

      chapters.sort(sortByWeightThenByName);
    }
  };

  useEffect(() => {
    chapters?.sort(sortByWeightThenByName);
  }, [chapters]);

  return (
    <>
      {isAdmin && (
        <>
          <AddChapterDialog
            color={color}
            isOpen={isAddChapterModalOpen}
            onConfirm={addChapter}
            onDismiss={() => {
              setIsAddChapterModalOpen(false);
              setChapterId(null);
            }}
          />
          <ConfirmationDialog
            content={t('global.delete.what.confirm', {
              what: t('global.CHAPTER.singular'),
            })}
            isOpen={isDeleteChapterModalOpen}
            onConfirm={removeChapter}
            onDismiss={() => {
              setIsDeleteChapterModalOpen(false);
              setChapterId(null);
            }}
          />
          <UpdateChapterDialog
            chapter={chapter}
            color={color}
            isOpen={isUpdateChapterModalOpen}
            onConfirm={editChapter}
            onDismiss={() => {
              setIsUpdateChapterModalOpen(false);
              setChapterId(null);
            }}
          />

          <AddChapterItemDialog
            color={color}
            isOpen={isAddChapterItemModalOpen}
            onConfirm={adminAddChapterItem}
            onDismiss={() => {
              setChapterId(null);
              setIsAddChapterItemModalOpen(false);
            }}
          />

          <ManageTagOnItemDialog
            chapter={chapter}
            chapterItem={chapterItem}
            isOpen={isManageTagOnItemModalOpen}
            onDismiss={() => {
              setIsManageTagOnItemModalOpen(false);
              setChapterId(null);
              setChapterItemId(null);
            }}
            refetchTags={refetch}
          />
        </>
      )}
      <ProgramLoadingSkeleton loading={isLoading}>
        <PageHeader breadcrumbs={breadcrumbsLinks} title={name}>
          {programs && currentProgram && (
            <ProgramPager currentProgram={currentProgram} programs={programs} />
          )}
        </PageHeader>
        <Content className="flex-col">
          <div className="w-full flex md:justify-between md:items-center flex-col md:flex-row">
            {isAdmin && (
              <ActionStyledAsButton
                className="ml-auto mb-4 flex"
                onClick={() => {
                  setIsAddChapterModalOpen(true);
                }}
                test={dataTestIds.page.manageChapter.button.add.chapter}
              >
                <PlusIcon className="h-4 w-4 inline-block mr-2" />
                {t('manage-program-details.add-chapter')}
              </ActionStyledAsButton>
            )}
          </div>
          {chapters?.length ? (
            <div className="w-full">
              <DragDropContext
                onDragEnd={onDragEndChapter}
                onDragStart={onDragStartChapter}
              >
                <Droppable droppableId="full-list">
                  {(provided1) => (
                    <div
                      className="w-full"
                      {...provided1.droppableProps}
                      ref={provided1.innerRef}
                    >
                      {chapters?.map((chapter, index) => {
                        const subText =
                          chapter.items?.length === 1
                            ? t('manage-program-details.chapter-item')
                            : t('manage-program-details.chapter-items');

                        return (
                          <Draggable
                            key={chapter.id}
                            draggableId={`${chapter.id}`}
                            index={index}
                            isDragDisabled={openedChapter !== null || !isAdmin}
                          >
                            {(provided2) => (
                              <div
                                ref={provided2.innerRef}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                              >
                                <MultiCollapsable
                                  key={chapter.id}
                                  actions={actionsForChapter(chapter)}
                                  hasDropDown={chapter.items?.length > 0}
                                  id={chapter.id}
                                  image={chapter.blobUri}
                                  isHidden={chapter.hidden}
                                  isOpen={false}
                                  mainText={chapter.name}
                                  setClicked={setOpenedChapter}
                                  subText={[
                                    `${chapter.items?.length ?? 0} ${subText}`,
                                    isAdmin
                                      ? `${t('global.weight')} ${
                                          chapter.weight
                                        }`
                                      : undefined,
                                  ].filter((i) => i).join` | `}
                                  tags={chapter?.tags}
                                  test={
                                    dataTestIds.page.manage.toggle
                                      .multiCollapsable
                                  }
                                >
                                  <DragDropContext
                                    onDragEnd={onDragEndChapterItem}
                                    onDragStart={onDragStartChapterItem}
                                  >
                                    {chapter?.items && (
                                      <Droppable droppableId="full-list">
                                        {(provided3) => (
                                          <div
                                            className="w-full"
                                            {...provided3.droppableProps}
                                            ref={provided3.innerRef}
                                            // eslint-disable-next-line react/no-unknown-property
                                            isDragDisabled={!isAdmin}
                                          >
                                            {chapter.items
                                              .sort(sortByWeightThenByName)
                                              .map(
                                                (
                                                  chapterItem,
                                                  indexChampterItem,
                                                ) => (
                                                  <Draggable
                                                    key={chapterItem.id}
                                                    draggableId={`${chapterItem.id}`}
                                                    index={indexChampterItem}
                                                    // eslint-disable-next-line react/no-unknown-property
                                                    isDragDisabled={!isAdmin}
                                                  >
                                                    {(provided4) => (
                                                      <div
                                                        ref={provided4.innerRef}
                                                        {...provided4.draggableProps}
                                                        {...provided4.dragHandleProps}
                                                      >
                                                        <ChapterItem
                                                          chapterItem={
                                                            chapterItem
                                                          }
                                                          color={color}
                                                          refetch={refetch}
                                                          setChapterItemId={
                                                            setChapterItemId
                                                          }
                                                          setIsManageTagOnItemModalOpen={
                                                            setIsManageTagOnItemModalOpen
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                ),
                                              )}
                                          </div>
                                        )}
                                      </Droppable>
                                    )}
                                  </DragDropContext>
                                </MultiCollapsable>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <EmptyState
              textKey={
                isAdmin
                  ? 'admin.program.detail.empty'
                  : 'schooladmin.program.detail.empty'
              }
            />
          )}
        </Content>
      </ProgramLoadingSkeleton>
    </>
  );
}
