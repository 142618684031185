import { useContext, useEffect, useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

import { ActionStyledAsButton } from '../../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../../hooks/useFormatMessage';

import { SlideViewerContext } from '../../../slideviewer/SlideViewerContextProvider';

// eslint-disable-next-line import/no-cycle
import SlideViewerMultipleChoiceAnswerCard from './SlideViewerMultipleChoiceAnswerCard';
import { dataTestIds } from '../../../../utils/constants/dataTestIds';
import { MultipleChoiceQuestionWrapper } from './MultipleChoiceQuestionWrapper';
import {
  getAnswerCopy,
  getAnswersForQuestion,
  getCorrectAnswersForQuestion,
  getUserAnswers,
  removeIdFromArray,
  showSuccessAnimationIfAllIsCorrect,
} from './helpers';

export default function SlideViewerMultipleChoiceQuestion({
  canSubmit,
  question,
}) {
  const t = useFormatMessage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeOutId, setTimeOutId] = useState();

  const {
    canOverrideAnswers,
    isCodefever,
    isSubmittingAnswers,
    onSubmitQuestionAnswers,
    refetchAnswers,
    selectedPeriodIsNotActivePeriod,
    setShowConfirmationDialog,
    setSubmitAnswer,
    submittedQuestionAnswers,
  } = useContext(SlideViewerContext);

  const answersForThisQuestion = getAnswersForQuestion(
    question,
    submittedQuestionAnswers,
  );
  const correctAnswerIds = getCorrectAnswersForQuestion(answersForThisQuestion);
  const userAnswers = getUserAnswers(answersForThisQuestion);
  const [answerIds, setAnswerIds] = useState(userAnswers || []);

  const onSubmit = (answers) => {
    onSubmitQuestionAnswers(question, answers).then((questions) => {
      setSubmitAnswer(null);
      showSuccessAnimationIfAllIsCorrect(
        questions,
        answers,
        canOverrideAnswers,
      );
    });
  };

  // const { maxSelectableAnswers = 1 } = question;
  const hasNoAnswersSelected = answerIds?.length === 0;
  const hasAlreadySubmittedAndCanNotOverride =
    Boolean(correctAnswerIds?.length) && !canOverrideAnswers;
  // const hasChosenMoreAnswersThanAllowed = Boolean(answers) && answers.length > maxSelectableAnswers;
  /*
   * This has been broken for so long now, that fixing it might introduce a lot of broken questions into the lessons.
   * However, the above line is how it should work.
   * For now: we won't add tests as we don't do anything with this maxSelectable answers.
   * See: https://codefever.atlassian.net/browse/TR-2377
   */
  const hasChosenMoreAnswersThanAllowed = false;
  const disabled =
    !canSubmit ||
    hasChosenMoreAnswersThanAllowed ||
    hasAlreadySubmittedAndCanNotOverride ||
    hasNoAnswersSelected;

  let buttonText =
    canOverrideAnswers && canSubmit
      ? t('styled-slide-viewer.save')
      : t('styled-slide-viewer.saved');
  if (!canOverrideAnswers)
    buttonText = canSubmit
      ? t('styled-slide-viewer.submit')
      : t('styled-slide-viewer.submitted');

  // ensure the button goes back to normal once response is in
  useEffect(() => {
    if (
      (isSubmittingAnswers && !isSubmitting) ||
      (!isSubmittingAnswers && isSubmitting)
    )
      setIsSubmitting(isSubmittingAnswers);
  }, [isSubmitting, isSubmittingAnswers]);

  useEffect(() => {
    if (isSubmitting)
      setTimeOutId(
        setTimeout(() => {
          refetchAnswers();
        }, 5000),
      );

    return () => {
      if (!isSubmitting && timeOutId) clearTimeout(timeOutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, refetchAnswers]);

  return (
    <MultipleChoiceQuestionWrapper
      hasResults={hasAlreadySubmittedAndCanNotOverride}
      isCodefever={isCodefever}
    >
      <div className="mt-4 grid gap-4 grid-cols-1 lg:grid-cols-2 clear-both">
        {question.questionAnswersMultipleChoice.map((answer, index) => {
          const answerId = parseInt(answer.id, 10);
          const answerIsSelected = answerIds.includes(answerId);
          const answerCopy = getAnswerCopy(
            answer,
            answersForThisQuestion,
            answerId,
          );

          const hasResults =
            // when the user has submitted answers, and it can't be overridden
            (!canSubmit && !canOverrideAnswers) ||
            // or when the correct answer information is available
            (answerCopy.correct !== undefined && answerCopy.correct !== null) ||
            // or when it's a period in the past
            selectedPeriodIsNotActivePeriod;

          return (
            <SlideViewerMultipleChoiceAnswerCard
              key={answerId}
              answer={answerCopy}
              disabled={hasResults}
              hasResults={hasResults}
              index={index}
              isClicked={answerIsSelected}
              onClick={refetchAnswers}
              selectAnswer={() => {
                const newAnswerIds = !answerIds.includes(answerId) // is the answer selected
                  ? [...answerIds, answerId] // if not: add it
                  : removeIdFromArray(answerIds, answerId); // if yes: remove it

                setAnswerIds(newAnswerIds);
                /** because we are using a state hook to store the function we need to use a function that returns another function.  If we don't, and simply use the hook like this instead: setSubmitAnswer(onSubmit), then the onSubmit function will get called upon being set as the state ... which obviously we don't want. * */
                setSubmitAnswer(
                  () => () =>
                    onSubmit(newAnswerIds.length ? newAnswerIds : null),
                );
              }}
            />
          );
        })}
      </div>
      <div className="mt-4 w-full flex justify-center mb-1">
        <ActionStyledAsButton
          disabled={disabled || isSubmitting}
          iconBefore={PaperAirplaneIcon}
          loading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            onSubmit(answerIds);
            setShowConfirmationDialog(false);
            setSubmitAnswer(null);
            refetchAnswers();
          }}
          test={
            dataTestIds.component.slideviewer.slide.button
              .submitMultipleChoiceAnswer
          }
        >
          {buttonText}
        </ActionStyledAsButton>
      </div>
    </MultipleChoiceQuestionWrapper>
  );
}
