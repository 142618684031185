import { generatePath, useNavigate } from 'react-router-dom';

import { SubjectCard } from '@ftrprf/tailwind-components';

import URLS from 'utils/constants/urls';

export function HackRoomLessonItem({ lesson }) {
  const { imageUri, studioId } = lesson.lessons[0];
  const navigate = useNavigate();

  const linkToDetail = generatePath(URLS.SLIDEVIEWER_HACKROOM_LESSON, {
    studioId,
  });

  return (
    <li
      className="bg-white cursor-pointer"
      onClick={() => navigate(linkToDetail)}
    >
      <SubjectCard
        hasContent
        image={imageUri}
        link={linkToDetail}
        title={lesson.name}
      />
    </li>
  );
}
