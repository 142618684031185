// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';

import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  InputGroup,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import useAdminUpsertTag from 'hooks/api/plannerService/admin/mutations/useAdminUpsertTag';
import { TwitterPicker } from 'react-color';
import { badgeColors } from 'utils/constants/colors';
import { useEffect } from 'react';

export default function EditTagDialog({
  isOpen,
  onConfirm = () => {},
  onDismiss = () => {},
  tag,
}) {
  const t = useFormatMessage();
  const isEdit = Boolean(tag);
  const isCreate = !Boolean(tag);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      color: badgeColors[0].label,
    },
  });
  const { color, name } = watch();

  const { mutateAsync: upsert } = useAdminUpsertTag(isCreate);

  const onSubmit = (data) => {
    const upsertTagFields = { input: data };

    if (tag) {
      upsertTagFields.id = tag.id;
    }

    upsert(upsertTagFields)
      .then(() => {
        onConfirm();
        reset();
        onDismiss();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  useEffect(() => {
    reset({
      name: tag?.name ?? '',
      color: tag?.color ?? badgeColors[0].label,
    });
  }, [reset, tag, tag?.color, tag?.name]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader>
        {isEdit
          ? `${t('global.edit')} ${tag.name}`
          : t('global.create.what', { what: t('global.TAG.singular') })}
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <InputGroup>
            <Label className="font-bold" htmlFor="tagName_" required>
              {t('global.name')}
            </Label>
            <Input
              data-test={dataTestIds.modal.editTag.input.name}
              hasErrors={Boolean(errors.name)}
              id="tagName_"
              {...register('name', {
                required: true,
                minLength: 1,
              })}
            />
          </InputGroup>

          <InputGroup>
            <Label className="font-bold" htmlFor="tagColor_">
              {t('global.color')}
            </Label>
            <TwitterPicker
              className="rounded border border-gray-300"
              color={badgeColors[0].color}
              colors={badgeColors.map((item) => item.color)}
              data-test={dataTestIds.modal.editTag.input.color}
              id="tagColor_"
              onChange={(colorValues) =>
                setValue(
                  'color',
                  badgeColors.find((item) => item.color === colorValues.hex)
                    ?.label,
                )
              }
              styles={{
                default: {
                  card: {
                    boxShadow: '0',
                    border: '',
                  },
                  input: {
                    display: 'none',
                  },
                  hash: {
                    display: 'none',
                  },
                },
              }}
              triangle="hide"
              // this width prop resets the default width that's added by the twitterpicker
              width=""
            />
          </InputGroup>

          {Object.values(errors).map((error, index) => (
            <p key={`${error.type}${index + 1}`} className="text-red-500">
              {error.message}
            </p>
          ))}

          <div className="mt-4">
            <Label className="font-bold">{t('global.example')}</Label>
            <Badge
              {...{
                [color]: true,
              }}
            >
              {name || t('global.example')}
            </Badge>
          </div>
        </DialogContent>

        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.editTag.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.editTag.button.submit}
            type="submit"
          >
            {t(isEdit ? 'global.update.what' : 'global.create.what', {
              what: t('global.TAG.singular'),
            })}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
