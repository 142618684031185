import { gql } from 'graphql-request';

export const TEACHER_CHAPTER_ITEMS_BY_CHAPTER_ID = gql`
  query teacherChapterItemsByChapterId(
    $chapterId: Int!
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    teacherChapterItemsByChapterId(
      chapterId: $chapterId
      filter: $filter
      page: $page
      size: $size
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        name
        position
        chapterId
        blobUri
        lessons {
          id
          title
          studioId
          version
          status
          type
          blobUri
          imageUri
          organizationId
          tags
          copiedBy {
            firstName
            lastName
          }
        }
        studioId
        document
      }
    }
  }
`;
