import AsyncSelect from 'react-select';

import useFormatMessage from '../hooks/useFormatMessage';

export function AsyncDropdown({
  defaultOptions,
  loadOptions,
  onInputChange,
  ...props
}) {
  const t = useFormatMessage();

  return (
    <AsyncSelect
      cacheOptions
      className="react-select-container"
      classNamePrefix="react-select"
      classNames="w-full"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      noOptionsMessage={() => t('dropdown.no_results')}
      onInputChange={onInputChange}
      {...props}
    />
  );
}
