import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dropdown, Label, StandardDialog } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { listToOptionsValueElem } from 'utils/listToOptions';

export default function LinkProgramsDialog({
  isLoading,
  isOpen,
  onConfirm: linkPrograms,
  onDismiss: dismiss,
  programs,
}) {
  const t = useFormatMessage();
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: { programs },
  });

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(
    (data) => {
      linkPrograms(
        data.programIds.map((el) => el.value.id),
        onDismiss,
      );
    },
    [linkPrograms, onDismiss],
  );
  const selectedIds = watch('programIds')?.map((option) => option.value.id);

  return (
    <StandardDialog
      confirmText={t('link-programs-dialog.confirm')}
      isLoading={isLoading}
      isOpen={isOpen}
      onClick={handleSubmit(onSubmit)}
      onDismiss={onDismiss}
      title={t('link-programs-dialog.header')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <Label className="mt-3" htmlFor="linkProgramsDialog_programIds">
              {t('link-programs-dialog.label')}
            </Label>
            <Controller
              control={control}
              defaultValue={[]}
              name="programIds"
              render={({ field: { onBlur, onChange, value } }) => (
                <Dropdown
                  inputId="linkProgramsDialog_programIds"
                  isMulti
                  onBlur={onBlur}
                  onChange={onChange}
                  options={listToOptionsValueElem(programs).filter(
                    (option) => !selectedIds?.includes(option.value.id),
                  )}
                  returnMethod="object"
                  value={value}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'validation.required',
                },
              }}
              type="select"
            />
          </div>
        </div>
      </form>
    </StandardDialog>
  );
}
