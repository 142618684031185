import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import { TEACHER_USER_LESSON_REPORT_BY_USER_ID } from 'api/portfolioService/queries/teacherUserLessonReportByUserId';
import { USER_LESSON_REPORT } from 'api/portfolioService/queries/userLessonReport';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export const useLessonReport = ({ lessonSessionId, userId }) => {
  const { isStudent } = useContext(UserContext);

  const hookObject = {
    id: isStudent ? 'userLessonReport' : 'teacherUserLessonReportByUserId',
    query: isStudent
      ? USER_LESSON_REPORT
      : TEACHER_USER_LESSON_REPORT_BY_USER_ID,
    queryId: isStudent
      ? ['userLessonReport', lessonSessionId]
      : ['teacherUserLessonReportByUserId', lessonSessionId, userId],
    variables: isStudent
      ? {
          lessonSessionId,
        }
      : {
          lessonSessionId,
          userId,
        },
  };

  const info = useQuery(
    hookObject.queryId,
    () =>
      portfolioServiceQueryClient.request(
        hookObject.query,
        hookObject.variables,
      ),
    {},
  );

  return {
    ...info,
    data: info?.data?.[hookObject.id],
  };
};
