import { gql } from 'graphql-request';

export const ADMIN_TAGS = gql`
  query adminTags($filter: [JSON], $page: Int!, $size: Int!, $sort: JSON) {
    adminTags(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        id
        name
        color
        chapters {
          id
          name
          programId
          document
          weight
          blobUri
        }
        chapterItems {
          id
          name
          position
          chapterId
          document
          blobUri
          weight
          studioId
        }
      }
    }
  }
`;
