import { adjustRolesAfterMemberWasMoved } from './adjustRolesAfterMemberWasMoved';
import { getIndexesForDraggedItem } from './getIndexesForDraggedItem';

/**
 * Cut out moved member and insert it in the right spot at the new group.
 * Then adjust the roles.
 */
export const moveMemberToNewGroup = function (result, newGroups) {
  const {
    destinationGroupIndex,
    destinationMemberIndex,
    sourceGroupIndex,
    sourceMemberIndex,
  } = getIndexesForDraggedItem(result);

  const movedMember = newGroups[sourceGroupIndex].splice(
    sourceMemberIndex,
    1,
  )[0];
  newGroups[destinationGroupIndex].splice(
    destinationMemberIndex,
    0,
    movedMember,
  );

  adjustRolesAfterMemberWasMoved(
    movedMember,
    newGroups,
    sourceGroupIndex,
    destinationGroupIndex,
  );
};
