import useFormatMessage from '../../../hooks/useFormatMessage';

import { SlideViewerCloseButton } from './CloseButon';

export function SlideNotAvailable({ inEditor = false, onClose = () => {} }) {
  const t = useFormatMessage();

  return (
    <div className="w-full h-full flex items-center justify-center relative">
      {!inEditor && (
        <div className="absolute top-5 right-5">
          <SlideViewerCloseButton onClose={onClose} />
        </div>
      )}

      {inEditor
        ? t('styled-slide-viewer.not_available_editor')
        : t('styled-slide-viewer.not_available')}
    </div>
  );
}
