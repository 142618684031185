export const englishAvatar = {
  "avatar.pick.random": "Roll the dice",
  "avatar.prop.accessory": "Accessory",
  "avatar.prop.body": "Body",
  "avatar.prop.clothing": "Clothing",
  "avatar.prop.clothingColor": "Clothing colour",
  "avatar.prop.eyebrows": "Eyebrows",
  "avatar.prop.eyes": "Eyes",
  "avatar.prop.faceMask": "Face mask",
  "avatar.prop.faceMaskColor": "Face mask colour",
  "avatar.prop.facialHair": "Facial hair",
  "avatar.prop.graphic": "Graphic",
  "avatar.prop.hair": "Hair",
  "avatar.prop.hairColor": "Hair colour",
  "avatar.prop.hat": "Hat",
  "avatar.prop.hatColor": "Hat colour",
  "avatar.prop.lashes": "Lashes",
  "avatar.prop.lipColor": "Lip colour",
  "avatar.prop.mask": "Mask",
  "avatar.prop.mouth": "Mouth",
  "avatar.prop.skinTone": "Skin tone",
  "avatar.section.body": "Body settings",
  "avatar.section.facial": "Facial settings",
  "avatar.section.general": "General settings",
  "avatar.value.accessory.none": "None",
  "avatar.value.accessory.roundGlasses": "Round glasses",
  "avatar.value.accessory.shades": "Shades",
  "avatar.value.accessory.tinyGlasses": "Tiny glasses",
  "avatar.value.body.breasts": "Breasts",
  "avatar.value.body.chest": "Chest",
  "avatar.value.clothing.dress": "Dress",
  "avatar.value.clothing.dressShirt": "Dress shirt",
  "avatar.value.clothing.naked": "Naked",
  "avatar.value.clothing.shirt": "Shirt",
  "avatar.value.clothing.tankTop": "Tanktop",
  "avatar.value.clothing.vneck": "Vneck",
  "avatar.value.clothingColor.black": "Black",
  "avatar.value.clothingColor.blue": "Blue",
  "avatar.value.clothingColor.green": "Green",
  "avatar.value.clothingColor.red": "Red",
  "avatar.value.clothingColor.white": "White",
  "avatar.value.eyebrows.angry": "Angry",
  "avatar.value.eyebrows.concerned": "Concerned",
  "avatar.value.eyebrows.leftLowered": "Left lowered",
  "avatar.value.eyebrows.raised": "Raised",
  "avatar.value.eyebrows.serious": "Serious",
  "avatar.value.eyes.content": "Content",
  "avatar.value.eyes.dizzy": "Dizzy",
  "avatar.value.eyes.happy": "Happy",
  "avatar.value.eyes.heart": "Heart",
  "avatar.value.eyes.leftTwitch": "Left twitch",
  "avatar.value.eyes.normal": "Normal",
  "avatar.value.eyes.simple": "Simple",
  "avatar.value.eyes.squint": "Squint",
  "avatar.value.eyes.wink": "Wink",
  "avatar.value.faceMaskColor.black": "Black",
  "avatar.value.faceMaskColor.blue": "Blue",
  "avatar.value.faceMaskColor.green": "Green",
  "avatar.value.faceMaskColor.red": "Red",
  "avatar.value.faceMaskColor.white": "White",
  "avatar.value.facialHair.mediumBeard": "Medium beard",
  "avatar.value.facialHair.none": "None",
  "avatar.value.facialHair.none2": "None",
  "avatar.value.facialHair.none3": "None",
  "avatar.value.facialHair.stubble": "Stubble",
  "avatar.value.graphic.codeCosmos": "CodeCosmos",
  "avatar.value.graphic.ftrprf": "FTRPRF",
  "avatar.value.graphic.gatsby": "Gatsby",
  "avatar.value.graphic.graphQL": "GraphQL",
  "avatar.value.graphic.minecraft": "Minecraft",
  "avatar.value.graphic.minecraftPick": "Minecraft Pickaxe",
  "avatar.value.graphic.none": "None",
  "avatar.value.graphic.react": "React",
  "avatar.value.graphic.redwood": "Redwood",
  "avatar.value.graphic.scratch": "Scratch",
  "avatar.value.graphic.vue": "Vue",
  "avatar.value.hair.afro": "Afro",
  "avatar.value.hair.balding": "Balding",
  "avatar.value.hair.bob": "Bob",
  "avatar.value.hair.bun": "Bun",
  "avatar.value.hair.buzz": "Buzz",
  "avatar.value.hair.long": "Long",
  "avatar.value.hair.none": "None",
  "avatar.value.hair.pixie": "Pixie",
  "avatar.value.hair.short": "Short",
  "avatar.value.hairColor.black": "Black",
  "avatar.value.hairColor.blonde": "Blonde",
  "avatar.value.hairColor.blue": "Blue",
  "avatar.value.hairColor.brown": "Brown",
  "avatar.value.hairColor.orange": "Orange",
  "avatar.value.hairColor.pink": "Pink",
  "avatar.value.hairColor.white": "White",
  "avatar.value.hat.beanie": "Beanie",
  "avatar.value.hat.none": "None",
  "avatar.value.hat.none2": "None",
  "avatar.value.hat.none3": "None",
  "avatar.value.hat.none4": "None",
  "avatar.value.hat.none5": "None",
  "avatar.value.hat.turban": "Turban",
  "avatar.value.hatColor.black": "Black",
  "avatar.value.hatColor.blue": "Blue",
  "avatar.value.hatColor.green": "Green",
  "avatar.value.hatColor.red": "Red",
  "avatar.value.hatColor.white": "White",
  "avatar.value.lipColor.green": "Green",
  "avatar.value.lipColor.pink": "Pink",
  "avatar.value.lipColor.purple": "Purple",
  "avatar.value.lipColor.red": "Red",
  "avatar.value.lipColor.turqoise": "Turquoise",
  "avatar.value.mouth.grin": "Grin",
  "avatar.value.mouth.lips": "Lips",
  "avatar.value.mouth.open": "Open",
  "avatar.value.mouth.openSmile": "Open smile",
  "avatar.value.mouth.sad": "Sad",
  "avatar.value.mouth.serious": "Serious",
  "avatar.value.mouth.tongue": "Tongue",
  "avatar.value.skinTone.avatar": "Pandoran (Avatar)",
  "avatar.value.skinTone.brown": "Brown",
  "avatar.value.skinTone.black": "Black",
  "avatar.value.skinTone.dark": "Dark",
  "avatar.value.skinTone.light": "Light",
  "avatar.value.skinTone.orc": "Orc",
  "avatar.value.skinTone.pitchBlack": "PitchBlack",
  "avatar.value.skinTone.red": "Red",
  "avatar.value.skinTone.yellow": "Yellow",
};
