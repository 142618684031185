import { useState } from 'react';

import c from '../../utils/c';

import { SummaryChevron } from './summaries/SummaryChevron';
import { SummaryPlusMinus } from './summaries/SummaryPlusMinus';

export function DisclosureWidget({
  Summary,
  children,
  className,
  classNameContainer,
  isOpen = false,
  title,
}) {
  const [open, setOpen] = useState(isOpen);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const openSummary = (e) => {
    e.stopPropagation();
    toggleOpen();
  };

  return (
    <details className={c('details', classNameContainer)} open={isOpen}>
      {Summary ? (
        <Summary
          className={className}
          open={open}
          openSummary={openSummary}
          title={title}
        />
      ) : (
        <SummaryChevron
          className={className}
          open={open}
          openSummary={openSummary}
          title={title}
        />
      )}
      {children}
    </details>
  );
}

export function DisclosureChevron({ children, isOpen, title, ...props }) {
  return (
    <DisclosureWidget
      isOpen={isOpen}
      {...props}
      Summary={SummaryChevron}
      title={title}
    >
      {children}
    </DisclosureWidget>
  );
}

export function DisclosurePlusMinus({ children, isOpen, title, ...props }) {
  return (
    <DisclosureWidget
      isOpen={isOpen}
      {...props}
      Summary={SummaryPlusMinus}
      title={title}
    >
      {children}
    </DisclosureWidget>
  );
}
