import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_LINK_ALL_TEACHERS_TO_ALL_PROGRAMS } from 'api/plannerService/schooladmin/mutations/schooladminLinkAllTeachersToAllPrograms';

export default function useLinkAllTeachersToAllPrograms() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    () =>
      plannerServiceQueryClient.request(
        SCHOOLADMIN_LINK_ALL_TEACHERS_TO_ALL_PROGRAMS,
      ),
    {
      onSuccess: () => {
        toast(
          { ...TOASTTYPES.ADDSUCCESS, type: null },
          {
            str: t('link-teachers-programs.success'),
          },
        );
      },
      onError: () => {
        toast(
          { ...TOASTTYPES.ACTIVATEERROR, type: null },
          {
            str: t('global.error'),
          },
        );
      },
    },
  );
}
