const { useEffect, useRef } = require('react');

// Source: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export const usePrevious = (value, ignore) => {
  const ref = useRef();

  useEffect(() => {
    if (value !== ignore) {
      ref.current = value;
    }
  }, [value, ignore]);

  return ref.current;
};
