import useFormatMessage from 'hooks/useFormatMessage';

export function AnswerToOpenQuestionForStudent({ answer, exampleAnswer }) {
  const t = useFormatMessage();

  return (
    <div>
      {exampleAnswer ? (
        <>
          <h3 className="font-medium uppercase text-gray-500 text-sm mt-4">
            {t('student-answers.example_solution')}
          </h3>
          <p className="text-gray-500 font-normal text-sm whitespace-pre">
            {exampleAnswer}
          </p>
        </>
      ) : (
        <p className="bg-neutral-100 px-8 py-2 rounded mt-6 whitespace-pre">
          {answer}
        </p>
      )}
    </div>
  );
}
