import { sizesClassNames } from './classNameHelpers';

export default function IconBeforeWithLoader({
  iconBefore,
  iconMargin,
  loader,
  loading,
  showIcon,
  size,
}) {
  if (showIcon) {
    return (
      <span className={iconMargin}>{loading ? loader() : iconBefore()}</span>
    );
  }

  return <span className={sizesClassNames[size].icon} />;
}
