import * as sanitizeHtml from 'sanitize-html';

import { sanitizeHtmlAllowingInternalImages } from 'utils/sanitizeHtml';
import { MultipleChoiceQuestionAnswerPart } from './MultipleChoiceQuestionAnswerPart';

export function AnswerToMultipleChoiceQuestion({
  answers,
  questionIndex,
  userAnswers,
}) {
  return answers ? (
    <ol className="flex flex-col md:grid md:grid-cols-2 gap-x-16 gap-y-8 mt-6 justify-start">
      {answers.map((answer, index) => {
        const key = `${questionIndex}/${index}/${Date.now()}`;
        return (
          <MultipleChoiceQuestionAnswerPart
            key={key}
            answer={{
              ...answer,
              explanation: sanitizeHtml(answer.explanation),
              value: sanitizeHtmlAllowingInternalImages(answer.value),
            }}
            chosenByUser={userAnswers.includes(answer.id)}
          />
        );
      })}
    </ol>
  ) : null;
}
