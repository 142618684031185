export const MONTHNAMES_EN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
// https://wmich.edu/writing/rules/dates
export const MONTHNAMES_SHORT_EN = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];
// https://www.vlaanderen.be/team-taaladvies/taaladviezen/maanden-van-het-jaar-afkorting
export const MONTHNAMES_SHORT_NL = [
  'jan',
  'feb',
  'mrt',
  'apr',
  'mei',
  'jun',
  'jul',
  'aug',
  'sept',
  'okt',
  'nov',
  'dec',
];
// https://en.wikipedia.org/wiki/Date_and_time_notation_in_France
export const MONTHNAMES_SHORT_FR = [
  'jan.',
  'fév.',
  'mars',
  'avr',
  'mai',
  'juin',
  'juil.',
  'août',
  'sept.',
  'oct.',
  'nov.',
  'dec.',
];
