import { gql } from 'graphql-request';

export const ADMIN_CREATE_ORGANIZATION = gql`
  mutation adminCreateOrganization($input: CreateOrganizationInput) {
    adminCreateOrganization(input: $input) {
      id
      name
      prefix
      language
      issuer
      active
      beta
      demo
      demoEndDate
      steams
      showCalendar
    }
  }
`;
