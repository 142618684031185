import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { ADMIN_UNLINK_TAG } from 'api/plannerService/admin/mutations/adminUnlinkTag';

export default function useAdminUnlinkTag() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ chapterId, chapterItemId, id }) =>
      plannerServiceQueryClient.request(ADMIN_UNLINK_TAG, {
        id,
        chapterId,
        chapterItemId,
      }),
    {
      mutationKey: 'adminUnlinkTag',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.TAG.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.TAG.singular'),
        });
      },
    },
  );
}
