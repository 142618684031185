import { useQuery } from 'react-query';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';
import { TEACHER_EXERCISES_BY_USERID } from 'api/portfolioService/queries/teacherExercisesByUserId';

export function useTeacherExercisesByUserId(userId, size = 50) {
  const query = useQuery(['teacherExercisesByUserId', userId], () =>
    portfolioServiceQueryClient.request(TEACHER_EXERCISES_BY_USERID, {
      userId,
      size,
    }),
  );

  return { ...query, data: query.data?.teacherExercisesByUserId.content };
}
