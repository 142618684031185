export const frenchAvatar = {
  "avatar.pick.random": "Lancer les dés",
  "avatar.prop.accessory": "Accessoire",
  "avatar.prop.body": "Corps",
  "avatar.prop.clothing": "Vêtements",
  "avatar.prop.clothingColor": "Couleur des vêtements",
  "avatar.prop.eyebrows": "Les sourcils",
  "avatar.prop.eyes": "Yeux",
  "avatar.prop.faceMask": "Masque facial",
  "avatar.prop.facialHair": "Poils",
  "avatar.prop.graphic": "Graphique",
  "avatar.prop.hair": "Cheveux",
  "avatar.prop.hairColor": "Couleur des cheveux",
  "avatar.prop.hat": "Chapeau",
  "avatar.prop.hatColor": "Couleur du chapeau",
  "avatar.prop.lashes": "Cils",
  "avatar.prop.lipColor": "Couleur des lèvres",
  "avatar.prop.mask": "Masque",
  "avatar.prop.mouth": "Bouche",
  "avatar.prop.skinTone": "Teint",
  "avatar.section.body": "Paramètres du corps",
  "avatar.section.facial": "Paramètres du visage",
  "avatar.section.general": "Paramètres general",
  "avatar.value.accessory.none": "Aucun(e)",
  "avatar.value.accessory.roundGlasses": "Verres ronds",
  "avatar.value.accessory.shades": "Des lunettes de soleil",
  "avatar.value.accessory.tinyGlasses": "Petits verres",
  "avatar.value.body.breasts": "Seins",
  "avatar.value.body.chest": "Poitrine",
  "avatar.value.clothing.dress": "Robe",
  "avatar.value.clothing.dressShirt": "Chemise habillée",
  "avatar.value.clothing.naked": "Nu(e)",
  "avatar.value.clothing.shirt": "Chemise",
  "avatar.value.clothing.tankTop": "Débardeur",
  "avatar.value.clothing.vneck": "Col en V",
  "avatar.value.clothingColor.black": "Noir",
  "avatar.value.clothingColor.blue": "Bleu",
  "avatar.value.clothingColor.green": "Vert",
  "avatar.value.clothingColor.red": "Rouge",
  "avatar.value.clothingColor.white": "Blanc",
  "avatar.value.eyebrows.angry": "En colère",
  "avatar.value.eyebrows.concerned": "Concerné",
  "avatar.value.eyebrows.leftLowered": "Abaissé à gauche",
  "avatar.value.eyebrows.raised": "Soulevé",
  "avatar.value.eyebrows.serious": "Sérieux",
  "avatar.value.eyes.content": "Satisfait",
  "avatar.value.eyes.dizzy": "Vertigineux",
  "avatar.value.eyes.happy": "Heureux",
  "avatar.value.eyes.heart": "Cœur",
  "avatar.value.eyes.leftTwitch": "Contraction gauche",
  "avatar.value.eyes.normal": "Normale",
  "avatar.value.eyes.simple": "Simple",
  "avatar.value.eyes.squint": "Strabisme",
  "avatar.value.eyes.wink": "Clin d'œil",
  "avatar.value.faceMaskColor.black": "Noir",
  "avatar.value.faceMaskColor.blue": "Bleu",
  "avatar.value.faceMaskColor.green": "Vert",
  "avatar.value.faceMaskColor.red": "Rouge",
  "avatar.value.faceMaskColor.white": "Blanc",
  "avatar.value.facialHair.mediumBeard": "Barbe moyenne",
  "avatar.value.facialHair.none": "Aucun(e)",
  "avatar.value.facialHair.none2": "Aucun(e)",
  "avatar.value.facialHair.none3": "Aucun(e)",
  "avatar.value.facialHair.stubble": "Chaume",
  "avatar.value.graphic.codeCosmos": "CodeCosmos",
  "avatar.value.graphic.ftrprf": "FTRPRF",
  "avatar.value.graphic.gatsby": "Gatsby",
  "avatar.value.graphic.graphQL": "GraphQL",
  "avatar.value.graphic.minecraft": "Minecraft",
  "avatar.value.graphic.minecraftPick": "Pioche Minecraft",
  "avatar.value.graphic.none": "Aucun(e)",
  "avatar.value.graphic.react": "React",
  "avatar.value.graphic.redwood": "Rougewood",
  "avatar.value.graphic.scratch": "Scratch",
  "avatar.value.graphic.vue": "Vue",
  "avatar.value.hair.afro": "Afro",
  "avatar.value.hair.balding": "Calvitie",
  "avatar.value.hair.bob": "Bob",
  "avatar.value.hair.bun": "Chignon",
  "avatar.value.hair.buzz": "Buzz",
  "avatar.value.hair.long": "Long",
  "avatar.value.hair.none": "Aucun(e)",
  "avatar.value.hair.pixie": "Pixie",
  "avatar.value.hair.short": "Court",
  "avatar.value.hairColor.black": "Noir",
  "avatar.value.hairColor.blonde": "Blond",
  "avatar.value.hairColor.blue": "Bleu",
  "avatar.value.hairColor.brown": "Brun",
  "avatar.value.hairColor.orange": "Orange",
  "avatar.value.hairColor.pink": "Rose",
  "avatar.value.hairColor.white": "Blanc",
  "avatar.value.hat.beanie": "Bonnet",
  "avatar.value.hat.none": "Aucun(e)",
  "avatar.value.hat.none2": "Aucun(e)",
  "avatar.value.hat.none3": "Aucun(e)",
  "avatar.value.hat.none4": "Aucun(e)",
  "avatar.value.hat.none5": "Aucun(e)",
  "avatar.value.hat.turban": "Turban",
  "avatar.value.hatColor.black": "Noir",
  "avatar.value.hatColor.blue": "Bleu",
  "avatar.value.hatColor.green": "Vert",
  "avatar.value.hatColor.red": "Rouge",
  "avatar.value.hatColor.white": "Blanc",
  "avatar.value.lipColor.green": "Vert",
  "avatar.value.lipColor.pink": "Rose",
  "avatar.value.lipColor.purple": "Violet",
  "avatar.value.lipColor.red": "Rouge",
  "avatar.value.lipColor.turqoise": "Turquoise",
  "avatar.value.mouth.grin": "Sourire",
  "avatar.value.mouth.lips": "Lèvres",
  "avatar.value.mouth.open": "Ouvert",
  "avatar.value.mouth.openSmile": "Sourire ouvert",
  "avatar.value.mouth.sad": "Triste",
  "avatar.value.mouth.serious": "Sérieux",
  "avatar.value.mouth.tongue": "Langue",
  "avatar.value.skinTone.avatar": "Pandoranique (Avatar)",
  "avatar.value.skinTone.brown": "Brun",
  "avatar.value.skinTone.black": "Noir",
  "avatar.value.skinTone.dark": "Sombre",
  "avatar.value.skinTone.light": "Lumière",
  "avatar.value.skinTone.orc": "Ork",
  "avatar.value.skinTone.pitchBlack": "Noir foncé",
  "avatar.value.skinTone.red": "Rouge",
  "avatar.value.skinTone.yellow": "Jaune",
};
