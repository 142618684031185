import { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { UserContext } from 'providers/UserProvider';
import { getLongYearMonthDay } from 'utils/timeHelpers';

import { RoleHeader } from './RoleHeader';
import { UserFormFields } from './UserFormFields';
import { UserActions } from './UserActions';

export default function UserInformation({}) {
  const { isSchoolAdmin, isStudent } = useContext(UserContext);
  const [
    {
      canDelete,
      canEdit,
      canResetPassword,
      isSaving,
      openRemoveModal,
      openResetModal,
      updateUser,
      user,
    },
  ] = useOutletContext();
  const { displayName, email, firstName, lastName } = user;

  const showEndDate =
    isSchoolAdmin &&
    !user.isSchoolAdmin &&
    canEdit &&
    user.endDate &&
    user.endDate !== 'null';

  const defaultValues = {
    displayName: displayName || '',
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
  };

  if (showEndDate) {
    defaultValues.endDate = getLongYearMonthDay(new Date(user.endDate));
  }

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    (data) => {
      updateUser(data);
    },
    [updateUser],
  );

  return (
    <div className="grid md:grid-cols-2 w-full">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col space-y-4">
          {!isStudent ? <RoleHeader user={user} /> : ''}
          <UserFormFields
            canEdit={canEdit}
            control={control}
            errors={errors}
            showEndDate={showEndDate}
            user={user}
          />
          <UserActions
            canDelete={canDelete}
            canEdit={canEdit}
            canResetPassword={canResetPassword && user.isFtrprfUser}
            isDirty={isDirty}
            isSaving={isSaving}
            openRemoveModal={openRemoveModal}
            openResetModal={openResetModal}
          />
        </div>
      </form>
    </div>
  );
}
