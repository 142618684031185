import { useContext } from 'react';
import { generatePath, Outlet } from 'react-router-dom';

import { TabColumn, TabColumnList } from 'components/TabColumn';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { UserContext } from 'providers/UserProvider';
import { dataTestIds } from 'utils/dataTestIds';
import URLS, { profileTab } from 'utils/constants/urls';
import GeneralLayout from 'app/layouts/GeneralLayout';

export default function Profile() {
  const t = useFormatMessage();
  const user = useContext(UserContext);
  const {
    avatar: avatarTestId,
    notifications: notificationsTestId,
    preferences: preferencesTestId,
    userInfo: userInfoTestId,
  } = dataTestIds.page.profile;
  const {
    isAdmin,
    isStudent,
    isTeacher,
    organization: { isCodefever, isTeams },
  } = user;

  useTitle(t('profile.title'));

  const breadcrumbsLinks = [
    { current: true, href: URLS.PROFILE, name: t('header.navigation.profile') },
  ];

  return (
    <GeneralLayout>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        title={t('profile.title')}
      />
      <div className="w-full max-w-5/6 my-4 mx-auto">
        <div
          className="w-full mt-2"
          data-test={dataTestIds.page.profile.profile}
        >
          <div className="w-full flex flex-col md:flex-row gap-4 h-full">
            <TabColumnList
              navLabel={t('profile.navLabel')}
              test={dataTestIds.page.profile.nav}
            >
              {/* todo when eulaAccepted is available from backend: <ProfileInfo user={user} updateEulaAccepted={updateEulaAccepted} /> */}
              {!isCodefever && (
                <>
                  <TabColumn
                    label={t('global.account.info')}
                    test={userInfoTestId}
                    to={generatePath(URLS.PROFILE_TAB, {
                      tab: profileTab.ACCOUNT,
                    })}
                  />

                  {!isAdmin && (
                    <TabColumn
                      label={t('global.account.avatar')}
                      test={avatarTestId}
                      to={generatePath(URLS.PROFILE_TAB, {
                        tab: profileTab.AVATAR,
                      })}
                    />
                  )}
                </>
              )}

              <TabColumn
                label={t('profile.preferences')}
                test={preferencesTestId}
                to={generatePath(URLS.PROFILE_TAB, {
                  tab: profileTab.PREFERENCES,
                })}
              />

              {(isStudent || isTeacher) && !isCodefever && (
                <TabColumn
                  label={t('global.NOTIFICATION.plural')}
                  test={notificationsTestId}
                  to={generatePath(URLS.PROFILE_TAB, {
                    tab: profileTab.NOTIFICATIONS,
                  })}
                />
              )}

              {isTeams && isTeacher && (
                <TabColumn
                  label={t('global.teams')}
                  test={preferencesTestId}
                  to={generatePath(URLS.PROFILE_TAB, {
                    tab: profileTab.TEAMS,
                  })}
                />
              )}
            </TabColumnList>

            {/* {idp === IDPS.FTRPRF && (
            <FormGroup>
              <UpdatePassword user={user} />
            </FormGroup>
          )} */}

            <div className="flex w-full h-full">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
}
