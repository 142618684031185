import unified from 'unified';
import { createElement } from 'react';
import parsePlugin from 'rehype-parse';
import rehypeReact from 'rehype-react';

import Link from './SlideComponents/Link';
import ModalButton from './SlideComponents/ModalButton';

export const processor = unified()
  .use(parsePlugin, { fragment: true })
  .use(rehypeReact, {
    createElement,
    components: { a: Link, button: ModalButton },
  });
