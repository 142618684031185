export default {
  'global.required-field': 'Champ requis',
  'global.search': 'Recherche',
  'header.impersonated': "Imitation d'identité",
  'pagination.ellipsis': 'Ellipsis',
  'pagination.go-to-page': 'Aller à la page {number}',
  'pagination.items-per-page': 'éléments par page',
  'pagination.next': 'Suivant',
  'pagination.previous': 'Précédent',
  'pagination.results-info':
    'Résultat {startItem} à {endItem} des {totalItems} résultats',
};
