import { addDays, isAfter, isBefore, subDays } from 'date-fns';

export function getCurrentPeriod(periods) {
  if (!!periods) {
    const now = new Date();
    for (const period of periods) {
      // subtracting one day to ensure that if the period starts on the same day it's still valid (no method seems to exist to check beforeOrEqual)
      const startsBeforeToday = isBefore(
        subDays(new Date(period.startDate), 1),
        now,
      );
      // adding one day to ensure that if the period ends on the same day it's still valid (no method seems to exist to check afterOrEqual)
      const endsAfterToday = isAfter(addDays(new Date(period.endDate), 1), now);
      if (startsBeforeToday && endsAfterToday) {
        return period;
      }
    }
  }

  return null;
}
