import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { SCHOOLADMIN_PROGRAM_BY_ID } from 'api/plannerService/schooladmin/queries/schooladminProgramById';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useSchooladminProgramById(programId) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminProgramById', programId],
    () =>
      plannerServiceQueryClient.request(SCHOOLADMIN_PROGRAM_BY_ID, {
        programId,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.singular'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminProgramById,
  };
}
