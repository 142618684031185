import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import useUserClassgroupManagement from 'hooks/api/plannerService/CRUD/useUserClassgroupManagement';
import useSchooladminUpdateClassGroup from 'hooks/api/plannerService/schooladmin/mutations/useSchooladminUpdateClassGroup';
import useSchooladminClassgroupById from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassgroupById';
import useSchooladminClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminClassGroups';
import useParams from 'hooks/useParams';

import { filterOperation } from 'utils/constants/filter';

import ClassProfile from './ClassProfile';

const MAX_CLASSGROUPS = 100;
export default function ClassProfileContainer({
  canDelete = false,
  showArchived,
}) {
  const { classId, filter } = useParams();
  const classGroupFilter = [
    {
      key: 'name',
      value: filter || '',
      operation: filterOperation.LIKE,
    },
  ];

  if (!showArchived)
    classGroupFilter.push({
      key: 'archived',
      value: false,
      operation: filterOperation.EQUAL,
    });

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterUsers, setFilterUsers] = useState([]);
  const { data: classesData } = useSchooladminClassGroups({
    filter: classGroupFilter,
    page: 0,
    size: MAX_CLASSGROUPS,
    sort: {
      name: 'ASC',
    },
  });

  const {
    data: classGroup,
    isError,
    isLoading,
    refetch,
  } = useSchooladminClassgroupById(classId);

  const { read: useUsersOfClassgroup } = useUserClassgroupManagement();
  const debouncedFilterUsers = useDebounce(filterUsers, 200);

  const { data, refetch: refetchUserOfClassgroups } = useUsersOfClassgroup({
    classGroupId: classId,
    filter: debouncedFilterUsers,
    page: page - 1,
    size: itemsPerPage,
    sort: {},
  });

  const users = data?.content;
  const pages = data?.pages;
  const totalItems = data?.total;

  const { mutateAsync: updateClassGroup } = useSchooladminUpdateClassGroup();

  const changeClassGroup = async (name) => {
    await updateClassGroup({ id: classId, name });
    refetch();
  };

  useEffect(() => {
    refetchUserOfClassgroups();
  }, [debouncedFilterUsers, refetchUserOfClassgroups]);

  return (
    <ClassProfile
      canDelete={canDelete}
      changeClassGroup={changeClassGroup}
      classGroup={classGroup}
      classGroups={classesData?.content}
      currentPage={page}
      filter={filterUsers}
      isError={isError}
      isLoading={isLoading}
      itemsPerPage={itemsPerPage}
      pages={pages}
      refetchUserOfClassgroups={refetchUserOfClassgroups}
      setFilter={(value) => {
        setPage(1);
        setFilterUsers(value);
      }}
      setItemsPerPage={setItemsPerPage}
      setPage={setPage}
      totalItems={totalItems}
      users={users ?? []}
    />
  );
}
