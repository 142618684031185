import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Header } from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';

export function AdminHeader() {
  const t = useFormatMessage();

  const user = useContext(UserContext);

  return (
    <Header
      choseActivePeriod
      icons={[]}
      profileLinks={[
        {
          label: t('header.profile'),
          to: URLS.PROFILE,
          test: dataTestIds.header.userMenu.links.profile,
        },
        {
          label: t('header.logout'),
          to: URLS.LOGOUT,
          test: dataTestIds.header.userMenu.links.logout,
        },
      ]}
      user={user}
    >
      <NavLink data-test={dataTestIds.header.manage} to={URLS.MANAGE}>
        {t('header.navigation.manage')}
      </NavLink>
      <NavLink to={URLS.TOOLS}>{t('header.navigation.tools')}</NavLink>
    </Header>
  );
}
