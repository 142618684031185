import { useContext, useRef } from 'react';
import { SlideViewerContext } from '../../slideviewer/SlideViewerContextProvider';
import { FadeInOutTransition } from '../../Transition/FadeInOutTransition';
import c from '../../../utils/c';
import useFormatMessage from '../../../hooks/useFormatMessage';
import ChatDot from '../images/chat-dot.png';
import Hints from './Hints';
import { dataTestIds } from '../../../utils/constants/dataTestIds';
import { CoachImage } from './CoachImage';
import { determineCoachName } from './coachesHelper';
import { CloseButton } from '../../buttons/CloseButon';

// currently keeping the name slidesUntilNow because it allows us to easily go back to multiple slides if need be in the future.
export function Chat({ isOpen, onClick, slidesUntilNow }) {
  const t = useFormatMessage();
  const chatRef = useRef();

  const { lesson } = useContext(SlideViewerContext);

  return (
    <FadeInOutTransition isOpen={isOpen}>
      <aside
        className="relative max-w-[27ch]"
        data-test={dataTestIds.component.assistant.chatBox}
      >
        <div className="min-w-56 bg-white rounded-lg mr-2 shadow-xl z-10 rounded-br-none ml-2">
          <div
            className={`relative h-14 rounded-t-lg bg-[url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/blue-mesh.webp')] bg-center`}
            data-test={dataTestIds.component.assistant.chatHeader}
          >
            <div className="flex items-center px-2 backdrop-blur-xl h-full backdrop-brightness-75 rounded-t-lg">
              <CoachImage className="h-12 p-1" coach={lesson?.coach} />
              <div className="text-white text-[0.7rem]">
                <h2 className="font-bold uppercase">
                  {determineCoachName(t, lesson?.coach)}
                </h2>
                <p className="block" role="doc-subtitle">
                  {t('assistant.always-here-to-help')}
                </p>
              </div>
            </div>
            <CloseButton
              defaultClassNames="flex justify-end text-white"
              fullWidth
              iconClassNames="mr-2 mt-2 h-4 w-4"
              onClose={onClick}
            />
          </div>
          <div
            ref={chatRef}
            className="p-4 flex flex-col items-end gap-2 max-h-64 overflow-y-auto"
            data-test={dataTestIds.component.assistant.chatContent}
          >
            {slidesUntilNow.map(
              ({ hints, motivation }) =>
                (!!motivation?.length || !!hints?.length) && (
                  <div key={`wrapper${hints[0]?.id}`}>
                    <p className={c(!!hints.length && 'mb-2')}>
                      {motivation ?? t('assistant.chat.need-help')}
                    </p>

                    {!!hints?.length && <Hints hints={hints} />}
                  </div>
                ),
            )}
          </div>
        </div>
        <img alt="" className="h-5 absolute right-2" src={ChatDot} />
      </aside>
    </FadeInOutTransition>
  );
}
