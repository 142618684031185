import { shuffleArray } from 'utils/shuffleArray';

import { cloneDeep } from 'lodash-es';
import { setRolesForGroup } from './setRolesForGroup';

export function randomizeRolesForGroups(groups, roles) {
  if (!roles.length) return groups;

  return groups.map((group) => {
    const possibleRoles = shuffleArray(roles);

    // clear roles first
    const newGroup = group.map((member) => {
      const clone = cloneDeep(member);
      clone.roles = [];
      return clone;
    });

    return setRolesForGroup(newGroup, possibleRoles);
  });
}
