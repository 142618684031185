export function NotChosenInCorrect({ answer }) {
  return (
    <li>
      <div className="rounded shadow-md p-8 justify-between max-h-fit">
        <div
          className="font-medium block"
          dangerouslySetInnerHTML={{ __html: answer.value }}
        />
        <div
          className="text-sm text-gray-500 block"
          dangerouslySetInnerHTML={{ __html: answer.explanation }}
        />
      </div>
    </li>
  );
}
