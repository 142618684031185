import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { dataTestIds } from '../../utils/constants/dataTestIds';
import useFormatMessage from '../../hooks/useFormatMessage';
import c from '../../utils/c';
import { Dropdown } from '../Dropdown';
import { Label } from '../form/Label';
import { ActionStyledAsButton } from '../buttons/ActionStyledAsButton';
import { linkFocusStyles } from '../../utils/constants/linkFocusStyles';

export function Pagination({
  className = '',
  currentPage = 1,
  itemsPerPage = 10,
  itemsPerPageOptions = [10, 20, 50],
  setCurrentPage = () => {},
  setItemsPerPage = null,
  totalItems = 0,
  totalPages = 0,
}) {
  const t = useFormatMessage();

  const startAmountText = useMemo(
    () => `${(currentPage - 1) * itemsPerPage + 1}`,
    [currentPage, itemsPerPage],
  );
  const endAmountText = useMemo(
    () =>
      `${
        Boolean(!totalItems || currentPage * itemsPerPage < totalItems)
          ? currentPage * itemsPerPage
          : totalItems
      }`,
    [currentPage, itemsPerPage, totalItems],
  );
  const totalAmountText = `${totalItems || totalPages * itemsPerPage}`;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageButtons = () => {
    const visiblePageButtons = new Set();
    const maxVisibleOnSideOfCurrent = 3; // Number of page buttons to show before and after current page

    // Always add the first page button
    visiblePageButtons.add(1);

    // Add page buttons before the current page
    for (
      let i = Math.max(2, currentPage - maxVisibleOnSideOfCurrent);
      i < currentPage;
      i++
    ) {
      visiblePageButtons.add(i);
    }

    // Add the current page button
    visiblePageButtons.add(currentPage);

    // Add page buttons after the current page
    for (
      let i = currentPage + 1;
      i <= Math.min(totalPages - 1, currentPage + maxVisibleOnSideOfCurrent);
      i++
    ) {
      visiblePageButtons.add(i);
    }

    // Always add the last page button
    visiblePageButtons.add(totalPages);

    // Add -1 where there's a gap between numbers
    // -1 represent an ellipsis
    const finalPageButtons = [];
    let prevPage = null;
    for (const page of Array.from(visiblePageButtons).sort(
      (a, b) => Number(a) - Number(b),
    )) {
      const numberOfPagesApart = Number(page) - Number(prevPage);

      if (prevPage !== null && numberOfPagesApart > 1) {
        // 2 indicates that there is 1 page in between
        // e.g.: 1 and 3, they are 2 pages apart, so there is 1 page in between them
        if (numberOfPagesApart === 2) {
          finalPageButtons.push(Number(page) - 1);
        } else {
          finalPageButtons.push(-1);
        }
      }
      finalPageButtons.push(page);
      prevPage = page;
    }

    return finalPageButtons;
  };

  const leftRightButtonClasses =
    'inline-flex items-center px-1 text-sm font-medium text-gray-500 hover:text-gray-700 disabled:cursor-not-allowed disabled:opacity-50 rounded-md';

  return (
    <div className={c('flex flex-col', className)}>
      {totalPages > 1 && (
        <div className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
          <div className="-mt-px flex w-0 flex-1">
            <ActionStyledAsButton
              className={leftRightButtonClasses}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              test={dataTestIds.component.pagination.button.previous}
              type="button"
              variant="text"
            >
              <ArrowLongLeftIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-gray-400"
              />
              {t('pagination.previous')}
            </ActionStyledAsButton>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {getPageButtons().map((page, index) => {
              const isCurrentPage = currentPage === page;
              const isEllipsis = page === -1;

              const buttonClasses = c(
                'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium',
                !isEllipsis &&
                  'hover:border-gray-300 hover:text-gray-700 cursor-pointer',
                !isEllipsis && linkFocusStyles,
                isCurrentPage &&
                  'border-accent-500 text-accent-600 hover:text-accent-800 hover:border-accent-700',
                isEllipsis && 'text-gray-500 border-transparent cursor-default',
                !isCurrentPage && 'border-transparent text-gray-500',
              );

              return (
                <button
                  key={page === -1 ? `ellipsis-${index}` : `${page}`}
                  aria-disabled={isEllipsis}
                  aria-label={
                    isEllipsis
                      ? t('pagination.ellipsis')
                      : t('pagination.go-to-page', { number: page })
                  }
                  className={buttonClasses}
                  data-test={dataTestIds.component.pagination.button.number}
                  onClick={() => handlePageChange(Number(page))}
                  tabIndex={isEllipsis ? -1 : null}
                  type="button"
                >
                  {isEllipsis ? '...' : `${page}`}
                </button>
              );
            })}
          </div>
          <div className="-mt-px flex w-0 flex-1 justify-end">
            <ActionStyledAsButton
              className={leftRightButtonClasses}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              test={dataTestIds.component.pagination.button.next}
              type="button"
              variant="text"
            >
              {t('pagination.next')}
              <ArrowLongRightIcon
                aria-hidden="true"
                className="ml-3 h-5 w-5 text-gray-400"
              />
            </ActionStyledAsButton>
          </div>
        </div>
      )}

      {Boolean(itemsPerPage && totalPages) && (
        <div className="flex w-full relative justify-center items-center mb-2 pt-4">
          <div className="text-sm text-gray-700">
            {t('pagination.results-info', {
              endItem: (
                <span
                  className="font-bold"
                  data-test={
                    dataTestIds.component.pagination.text
                      .startItemCurrentSelection
                  }
                >
                  {endAmountText}
                </span>
              ),
              startItem: (
                <span
                  className="font-bold"
                  data-test={
                    dataTestIds.component.pagination.text
                      .endItemCurrentSelection
                  }
                >
                  {startAmountText}
                </span>
              ),
              totalItems: (
                <span
                  className="font-bold"
                  data-test={dataTestIds.component.pagination.text.totalItems}
                >
                  {totalAmountText}
                </span>
              ),
            })}
            {Boolean(
              setItemsPerPage &&
                itemsPerPage &&
                totalItems > Math.min(...itemsPerPageOptions),
            ) && (
              <>
                ,{' '}
                <div className="inline-block">
                  <Label className="sr-only inline" htmlFor="itemsPerPage">
                    {t('global.items.per.page')}
                  </Label>
                  <Dropdown
                    className="inline-flex"
                    defaultValue={itemsPerPage}
                    inputId="itemsPerPage"
                    isMulti={false}
                    menuPlacement="top"
                    onChange={(e) => {
                      if (setItemsPerPage) setItemsPerPage(e);
                      setCurrentPage(1);
                    }}
                    options={itemsPerPageOptions}
                    value={itemsPerPage}
                  />
                </div>{' '}
                {t('pagination.items-per-page')}
              </>
            )}
            .
          </div>
        </div>
      )}
    </div>
  );
}
