import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { PlusIcon } from '@heroicons/react/24/outline';

export function CreateExerciseButton() {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      className="mr-2"
      href={`${process.env.REACT_APP_STUDIO_URL}/exercises/new`}
      iconBefore={PlusIcon}
      rel="noopener noreferrer"
      target="_blank"
    >
      {t('exercises-overview.create')}
    </ActionStyledAsButton>
  );
}
