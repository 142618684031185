import { useMemo, useState } from 'react';

import { Content, PageHeader } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import URLS from 'utils/constants/urls';

import { ContentCardList } from './partials/ContentCardList';
import { CreateExerciseButton } from './partials/CreateExerciseButton';
import { EmptyExercises } from './partials/EmptyExercises';
import { ExerciseFilter } from './partials/ExerciseFilter';

export function ExercisesOverview({
  exercises = [],
  hasCreateExercise = true,
  isError,
  isLoading,
  title = '',
}) {
  const t = useFormatMessage();
  const pageTitle = title || t('exercises-overview.my-exercises.title');
  const breadcrumbsLinks = [{ href: URLS.EXERCISE, name: pageTitle }];

  const [filterTitle, setFilterTitle] = useState('');

  useTitle(pageTitle);

  const isSearching = filterTitle !== '';
  const filteredExercises = useMemo(
    () =>
      isSearching
        ? exercises.filter((exercise) =>
            exercise.exerciseTitle
              ?.toLowerCase()
              .includes(filterTitle.toLowerCase()),
          )
        : exercises,
    [isSearching, exercises, filterTitle],
  );

  if (!isLoading && !isError && exercises.length === 0) {
    return <EmptyExercises hasCreateExercise={hasCreateExercise} />;
  }

  return (
    <>
      <PageHeader
        breadcrumbs={breadcrumbsLinks}
        button={hasCreateExercise && <CreateExerciseButton />}
        buttonOnTitleRow
        title={pageTitle}
      />
      <Content>
        <div className="flex flex-col w-full">
          <ExerciseFilter
            filterTitle={filterTitle}
            isSearching={isSearching}
            setFilterTitle={setFilterTitle}
          />
          <ContentCardList
            filteredExercises={filteredExercises}
            isLoading={isLoading}
          />
        </div>
      </Content>
    </>
  );
}
