export const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_4a_3_2_1_RelyingParty_Username_Signin',
    editProfile: 'B2C_1A_4c_3_2_1_RelyingParty_Username_EditProfile',
  },
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AD_SIGN_IN_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_AD_AUTHORITY_DOMAIN,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_LINK,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_AD_LOGIN_REQUEST_SCOPE],
};
