import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { errorKeys } from 'utils/constants/errorKeys';
import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_TEAMS_CLASSGROUPS } from 'api/plannerService/schooladmin/queries/schooladminTeamsClassGroups';

export default function useSchooladminTeamsClassGroups() {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminTeamsClassGroups'],
    () => plannerServiceQueryClient.request(SCHOOLADMIN_TEAMS_CLASSGROUPS),
    {
      onError: (e) => {
        if (e.message.includes(errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND)) return;

        toast(TOASTTYPES.GETERROR, {
          str: t('global.CLASS.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminTeamsClassGroups,
  };
}
