import { gql } from 'graphql-request';

export const SAVE_SCRATCH_EXERCISE = gql`
  mutation saveScratchExercise(
    $input: UserScratchExerciseInput!
    $file: Upload
  ) {
    saveScratchExercise(input: $input, file: $file) {
      exerciseVersionId
      exerciseId
      exerciseTitle
      exerciseImage
      location
      lessonSessionId
      userId
      createdAt
      lastModified
    }
  }
`;
