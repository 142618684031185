/* eslint-disable react/jsx-props-no-spreading */
/* prop spreading needed for react-hook-form */

import {
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

export default function MoveCopyLessonForm({
  dropdownOptions,
  handleInputChange,
  isLoading,
  method,
  onSubmit,
}) {
  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();

  const submit = (data) => {
    onSubmit(data).then(() => {
      setValue('studioId', undefined);
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Label className="mt-4" htmlFor="studioId" required>
        Studio ID
      </Label>
      <Input
        id="studioId"
        type="number"
        {...register('studioId', { required: true })}
        required
      />
      {errors.studioId && (
        <p className="text-xs text-red-500">{t('global.required-field')}</p>
      )}

      <Label className="mt-3" htmlFor="organizationId" required>
        {t('global.ORGANIZATION.singular')}
      </Label>
      <Controller
        control={control}
        defaultValue={[]}
        name="organizationId"
        render={({ field: { onBlur, onChange } }) => (
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            classNames="w-full"
            id="organizationId"
            isLoading={isLoading}
            noOptionsMessage={() => t('dropdown.no_results')}
            onBlur={onBlur}
            onChange={onChange}
            onInputChange={handleInputChange}
            options={dropdownOptions}
          />
        )}
        rules={{
          required: {
            value: true,
            message: 'validation.required',
          },
        }}
        type="select"
      />

      {/* errors will return when field validation fails  */}
      {errors.organizationId && (
        <p className="text-xs text-red-500">{t('global.required-field')}</p>
      )}

      <p className="mt-4">
        <ActionStyledAsButton type="submit">
          {t(`move-lesson.method.${method}`)}
        </ActionStyledAsButton>
      </p>
    </form>
  );
}
