import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { TEACHER_EXPORT_RESULTS } from 'api/plannerService/teacher/mutations/teacherSendResultsToSkore';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';

export default function useTeacherExportResults() {
  const toast = useToast();
  const t = useFormatMessage();
  const { isTeamsUser } = useContext(UserContext);

  const what = isTeamsUser ? 'Teams' : 'Skore';

  return useMutation(
    ({ calendarItemId, viewMode }) =>
      plannerServiceQueryClient.request(TEACHER_EXPORT_RESULTS, {
        calendarItemId,
        viewMode,
      }),
    {
      mutationKey: 'teacherExportResults',
      onSuccess: () => {
        toast(TOASTTYPES.SUCCESS, {
          str: t('sendResults.success', { what }),
        });
      },
      onError: () => {
        toast(TOASTTYPES.ERROR, {
          str: t('sendResults.error', { what }),
        });
      },
    },
  );
}
