import { linkFocusStyles, linkHoverStyles } from '@ftrprf/tailwind-components';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import c from 'utils/c';

/**
 *
 * @param value - current value of the radiobutton
 * @param onChange - function that changes the value of the radiobutton
 * @param options - an array of items that look like { name: string, value: any }
 * @param label - string displayed as label of the input
 *
 * @returns A radiogroup component
 */
export function StyledRadioGroup({ label, onChange, options, value }) {
  return (
    <RadioGroup onChange={onChange} value={value}>
      <RadioGroup.Label className="font-medium text-gray-900 mb-1">
        {label}
      </RadioGroup.Label>

      <div className="grid grid-cols-1 gap-2 gap-y-6 sm:grid-cols-2 sm:gap-x-4 mt-1">
        {options.map((option) => {
          const { name, test, value } = option;

          return (
            <RadioGroup.Option
              key={name}
              className={({ checked }) =>
                c(
                  checked
                    ? 'border-transparant ring-2 ring-accent-500 hover:ring-accent-700'
                    : 'border-gray-300',
                  linkFocusStyles,
                  linkHoverStyles,
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none hover:no-underline',
                )
              }
              data-test={test}
              value={value}
            >
              {({ checked }) => (
                <>
                  <RadioGroup.Label
                    as="span"
                    className="flex flex-1 flex-col text-sm font-medium text-gray-900"
                  >
                    {name}
                  </RadioGroup.Label>
                  {checked && (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-accent-600"
                    />
                  )}
                </>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
}
