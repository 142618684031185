import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_PROGRAMS } from 'api/plannerService/teacher/queries/teacherPrograms';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useTeacherPrograms() {
  const toast = useToast();
  const t = useFormatMessage();
  const response = useQuery(
    [`teacherPrograms`],
    () => plannerServiceQueryClient.request(TEACHER_PROGRAMS, {}),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('global.PROGRAM.plural'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.teacherPrograms,
  };
}
