import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

import {
  Actions,
  ActionStyledAsButton,
  Badge,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { laptop } from 'utils/constants/mediaWidth';
import { sortByStartDateThenByEndDate } from 'utils/sortByStartDateThenByEndDate';
import { dataTestIds, managePeriodsDataTestIds } from 'utils/dataTestIds';
import { formatDateDDMonthYYYY, utcDatetoLocalDate } from 'utils/timeHelpers';

export function ManagePeriods({ organizationInfo, setDialog }) {
  const t = useFormatMessage();

  return (
    <>
      <ActionStyledAsButton
        className="mb-4 ml-auto"
        iconBefore={PlusIcon}
        onClick={() => setDialog({ type: 'edit' })}
        test={dataTestIds.page.organisationProfile.button.add.period}
      >
        {t('periods.new')}
      </ActionStyledAsButton>

      <ResponsiveTable
        headers={[
          {
            key: 'name',
            label: t('global.period'),
          },
          {
            key: 'startDate',
            label: t('periods.startDate'),
          },
          {
            key: 'endDate',
            label: t('periods.endDate'),
          },
          {
            key: 'actions',
            label: t('global.actions'),
            orderable: false,
            className: 'max-w-fit sr-only',
          },
        ]}
        mobileBreakpoint={laptop}
        mobileHeaderWidth={160}
        onRowClick={() => {}}
        test={dataTestIds.page.manage.table.self}
      >
        {organizationInfo?.periods
          ?.sort(sortByStartDateThenByEndDate)
          .map((period) => (
            <ResponsiveTableRow key={period.id}>
              <ResponsiveTableCol>
                <div
                  className="flex gap-2"
                  data-test={managePeriodsDataTestIds.periodName}
                >
                  {period.name}
                  {new Date(period.startDate) <= Date.now() &&
                    Date.now() <= new Date(period.endDate) && (
                      <Badge className="w-fit px-2 lowercase">
                        {t('periods.active')}
                      </Badge>
                    )}
                </div>
                <p
                  className="block text-slate-500 whitespace-normal"
                  data-test={managePeriodsDataTestIds.periodDescription}
                >
                  {period.description}
                </p>
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                {formatDateDDMonthYYYY(utcDatetoLocalDate(period.startDate))}
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                {formatDateDDMonthYYYY(utcDatetoLocalDate(period.endDate))}
              </ResponsiveTableCol>
              <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right justify-end">
                <Actions
                  items={[
                    {
                      icon: (
                        <PencilIcon className="h-4 w-4 inline-block mr-2" />
                      ),
                      label: t('global.edit'),
                      onClick: () => {
                        setDialog({ type: 'edit', period });
                      },
                      testName:
                        dataTestIds.page.organisationProfile.table.button.edit,
                    },
                    {
                      icon: (
                        <TrashIcon className="h-4 w-4 inline-block mr-2 text-red-500" />
                      ),
                      label: t('global.delete'),
                      onClick: () => {
                        setDialog({ type: 'delete', period });
                      },
                      testName:
                        dataTestIds.page.organisationProfile.table.button
                          .delete,
                    },
                  ]}
                />
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          ))}
      </ResponsiveTable>
    </>
  );
}
