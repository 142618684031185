import { GraphQLClient } from 'graphql-request';

import { getToken } from 'api';

import msalInstance from '../msalInstance';
import { getSelectedPeriodIdFromLocalStorage } from './selectedPeriod';

async function tokenMiddleware(request) {
  const token = await getToken(msalInstance);
  const periodId = getSelectedPeriodIdFromLocalStorage();

  const headers = {
    ...request.headers,
    authorization: `Bearer ${token}`,
  };

  if (request.url === process.env.REACT_APP_PLANNER_SERVICE_URL) {
    headers['Selected-Period-Id'] = periodId;
  }

  return {
    ...request,
    headers,
  };
}

// Export the clients for synchronous use troughout the app.
export const portfolioServiceQueryClient = new GraphQLClient(
  process.env.REACT_APP_PORTFOLIO_SERVICE_URL,
  {
    requestMiddleware: tokenMiddleware,
  },
);

export const plannerServiceQueryClient = new GraphQLClient(
  process.env.REACT_APP_PLANNER_SERVICE_URL,
  {
    requestMiddleware: tokenMiddleware,
  },
);

export const slideViewerServiceQueryClient = new GraphQLClient(
  process.env.REACT_APP_SLIDEVIEWER_SERVICE_URL,
  {
    requestMiddleware: tokenMiddleware,
  },
);
