import {
  CheckBox,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { ChangePassword } from './partials/ChangePassword';

export default function ProfileInfo() {
  const { eulaAccepted, firstname, lastname, updateEulaAccepted, username } =
    useContext(UserContext);

  const t = useFormatMessage();

  return (
    <FormGroup hasMarginBottom={false}>
      <InputGroup>
        <Label htmlFor="profileInfo-firstName">{t('profile.first_name')}</Label>
        <Input disabled id="profileInfo-firstName" value={firstname} />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="profileInfo-lastName">{t('profile.last_name')}</Label>
        <Input disabled id="profileInfo-lastName" value={lastname} />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="profileInfo-username">{t('profile.username')}</Label>
        <Input disabled id="profileInfo-username" value={username} />
      </InputGroup>

      <div>
        {/* TODO: create a separate section privacy for eula accepted and user agreement
          => when eulaAccepted is available from backend
        */}
        {process.env.NODE_ENV === 'development' && (
          <>
            <Label className="sr-only" htmlFor="profileInfo-eulaAccepted">
              {t('profile.eula_accept')}
            </Label>
            <CheckBox
              checked={eulaAccepted}
              id="profileInfo-eulaAccepted"
              label={t('profile.eula_accepted')}
              onChange={updateEulaAccepted}
            />
          </>
        )}
      </div>

      <ChangePassword />
    </FormGroup>
  );
}
