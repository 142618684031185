import useFormatMessage from 'hooks/useFormatMessage';

import { IDP_IMAGES } from 'utils/constants/idp';

export function Idp({ idp = 'ILEARN' }) {
  const t = useFormatMessage();

  return IDP_IMAGES[idp] ? (
    <img alt={idp.toLowerCase()} className="h-4 w-auto" src={IDP_IMAGES[idp]} />
  ) : (
    t('global.unknown')
  );
}
