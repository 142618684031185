import { gql } from 'graphql-request';

export const SCHOOLADMIN_UPSERT_USERS_AND_CLASSES_VIA_EXCEL_FILE_UPLOAD = gql`
  mutation schooladminUpsertUsersAndClassesViaExcelFileUpload($file: Upload) {
    schooladminUpsertUsersAndClassesViaExcelFileUpload(file: $file) {
      row
      sheetName
      column
      error
    }
  }
`;
