import { useContext, useEffect, useState } from 'react';

import {
  ComputerDesktopIcon,
  HomeIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { VIEW_MODES } from './constants';
import { SlideViewerContext } from './SlideViewerContextProvider';
import { ProjectionTooltip } from '../styled-slideviewer/partials/ProjectionTooltip';
import c from '../../utils/c';
import { urls } from '../../utils/constants/urls';
import { dataTestIds } from '../../utils/constants/dataTestIds';
import { MultiScreenModeIcon } from './MultiScreenMode/MultiScreenModeIcon';

// We want to full screen the entire body so modals still work.
const { body } = document;
const FULLSCREEN_CLASS = 'fullscreen';

const toggleFullscreen = () => {
  if (document.fullscreenElement) {
    void document.exitFullscreen();

    // Add class to the body when full screen, so we can hide the navigation and do some other tweaks
    body.classList.remove(FULLSCREEN_CLASS);
  } else if (body.requestFullscreen) {
    // requestFullscreen is not defined in all browsers
    body.classList.add(FULLSCREEN_CLASS);

    // On refresh, this will throw an error because `requestFullscreen`
    // can only be initiated by a user gesture.
    body.requestFullscreen().catch(() => {
      body.classList.remove(FULLSCREEN_CLASS);
    });
  }
};

export default function SlideViewerViewModes({ className }) {
  const [isHovering, setIsHovering] = useState(false);
  const { currentSlide, hasMultiScreenMode, setViewMode, viewMode } =
    useContext(SlideViewerContext);

  useEffect(() => {
    if (document.fullscreenElement && viewMode !== VIEW_MODES.PROJECTION) {
      void document.exitFullscreen();
      body.classList.remove(FULLSCREEN_CLASS);
    }
  }, [viewMode]);

  const projectionDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.PROJECTION,
  );

  const selfstudyDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.SELFSTUDY,
  );
  const classicalDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.CLASSICAL,
  );

  const classicalActive = viewMode === VIEW_MODES.CLASSICAL;
  const selfstudyActive = viewMode === VIEW_MODES.SELFSTUDY;
  const projectionActive = viewMode === VIEW_MODES.PROJECTION;
  const showViewModes = isHovering || viewMode !== VIEW_MODES.PROJECTION;

  return (
    <div
      className={className}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {!showViewModes && (
        <button type="button">
          <span
            className={c(
              'flex items-center justify-center rounded-full text-gray shadow ml-2 h-8 w-8',
            )}
          >
            <ChevronLeftIcon className="h-6 w-6 mr-2" />
          </span>
        </button>
      )}
      {showViewModes && (
        <>
          {hasMultiScreenMode && (
            <a
              data-test={
                dataTestIds.component.slideviewer.viewModes.button.multiscreen
              }
              href={urls.MULTISCREEN}
              rel="noreferrer"
              target="_blank"
              type="button"
            >
              <ProjectionTooltip label="multi-screen-mode.open">
                <MultiScreenModeIcon classNames="h-4 w-4" />
              </ProjectionTooltip>
            </a>
          )}
          {!classicalDisabled && (
            <button
              data-test={
                dataTestIds.component.slideviewer.viewModes.button.classical
              }
              onClick={() => setViewMode(VIEW_MODES.CLASSICAL)}
              type="button"
            >
              <ProjectionTooltip
                active={classicalActive}
                label="styled-slide-viewer.view-modes.classical"
              >
                <UserGroupIcon
                  className={c('h-4 w-4', classicalActive ? '' : 'text-white')}
                />
              </ProjectionTooltip>
            </button>
          )}
          {!selfstudyDisabled && (
            <button
              data-test={
                dataTestIds.component.slideviewer.viewModes.button.selfstudy
              }
              onClick={() => setViewMode(VIEW_MODES.SELFSTUDY)}
              type="button"
            >
              <ProjectionTooltip
                active={selfstudyActive}
                label="styled-slide-viewer.view-modes.selfstudy"
              >
                <HomeIcon
                  className={c('h-4 w-4', selfstudyActive ? '' : 'text-white')}
                />
              </ProjectionTooltip>
            </button>
          )}
          {!projectionDisabled && (
            <button
              data-test={
                dataTestIds.component.slideviewer.viewModes.button.projection
              }
              onClick={() => {
                toggleFullscreen();
                setViewMode(VIEW_MODES.PROJECTION);
              }}
              type="button"
            >
              <ProjectionTooltip
                active={projectionActive}
                label="styled-slide-viewer.view-modes.projection"
              >
                <ComputerDesktopIcon
                  className={c('h-4 w-4', projectionActive ? '' : 'text-white')}
                />
              </ProjectionTooltip>
            </button>
          )}
        </>
      )}
    </div>
  );
}
