import c from '../../utils/c';

import { Button } from './Button';

export function DefaultButton({ children, className = '', ...props }) {
  return (
    <Button
      className={c(
        className,
        'hover:bg-gray-200 rounded px-2 py-1 transition-colors duration-100',
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
