import { PageHeaderWrapper } from 'components/PageHeaderWrapper';

export default function UserProfilePageHeader({ breadcrumbs, children, user }) {
  const pageTitle = `${user?.firstName} ${user?.lastName}`;

  return (
    <PageHeaderWrapper
      avatar={user?.avatar}
      borderBottom={false}
      breadcrumbs={breadcrumbs}
      marginBottom={false}
      title={pageTitle}
    >
      {children}
    </PageHeaderWrapper>
  );
}
