import SkeletonConstructor, { SKELETON_OPTIONS } from '../SkeletonConstructor';

export default function SkeletonTable() {
  return (
    <>
      <SkeletonConstructor
        key="Skeleton_Table"
        layout={`1${SKELETON_OPTIONS.LINE_H_MD}`}
      />
      <SkeletonConstructor layout={`10${SKELETON_OPTIONS.LINE_H_LG}`} />
    </>
  );
}
