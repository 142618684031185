import { useEffect, useState } from 'react';

import { Dialog, DialogHeader } from '@ftrprf/tailwind-components';

import { useTeacherStudentsByCalendarItemId } from 'hooks/api/plannerService/teacher/queries/useTeacherStudentsByCalendarItemId';
import useUpsertLessonGroup from 'hooks/api/portfolioService/mutations/useUpsertLessonGroup';
import useFormatMessage from 'hooks/useFormatMessage';

import { transformGroupsToLessonGroup } from './Groups/helperFunctions/transformGroupsToLessonGroup';
import { transformSteamsGroupsToMemberTypeGroups } from './Groups/helperFunctions/transformSteamsGroupsToMemberTypeGroups';
import { transformToMemberArray } from './Groups/helperFunctions/transformToMemberArray';

import { GroupRandomMemberDivider } from './GroupRandomMemberDivider';
import { Groups } from './Groups';
import { GroupSteamsMemberDivider } from './GroupSteamsMemberDivider';
import { GroupTypePicker } from './GroupTypePicker';

export function GroupComposition({
  calendarItemId,
  isOpen,
  onDismiss,
  onSubmit: submit,
  options,
  possibleRoles,
  sessionId,
}) {
  const t = useFormatMessage();
  const [showGroupMemberDivider, setShowGroupMemberDivider] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [amountOfGroups, setAmountOfGroups] = useState(4);
  const [members, setMembers] = useState();
  const [activityId, setActivityId] = useState();
  const [groupType, setGroupType] = useState('random');
  const roles = [...possibleRoles];
  const PAGE_SIZE = 0;
  const MAX_AMOUNT_OF_STUDENTS = 100;
  const { data: students } = useTeacherStudentsByCalendarItemId({
    calendarItemId,
    page: PAGE_SIZE,
    size: MAX_AMOUNT_OF_STUDENTS,
    sort: undefined,
  });
  const goToShowGroupMemberDivider = (type) => {
    setGroupType(type);
    setShowGroupMemberDivider(true);
  };
  const goToShowGroups = (amount, activityId) => {
    setShowGroupMemberDivider(false);
    setAmountOfGroups(amount);
    setShowGroups(true);
    setActivityId(activityId);
  };
  const steamsGoToShowGroups = (groups) => {
    setGroups(transformSteamsGroupsToMemberTypeGroups(groups));
    goToShowGroups(groups.length);
  };

  const dismissModal = () => {
    setShowGroupMemberDivider(false);
    setShowGroups(false);
    onDismiss();
  };
  const { mutate: upsertLessonGroup } = useUpsertLessonGroup();
  const onSubmit = (groups) => {
    const lessonGroup = transformGroupsToLessonGroup(groups, sessionId);
    lessonGroup.activityId = activityId;
    upsertLessonGroup({ input: lessonGroup });
    submit();
    dismissModal();
  };

  useEffect(() => {
    if (students) {
      setMembers(transformToMemberArray(students.content));
    }
  }, [students]);

  return (
    <Dialog
      className="flex bg-white shadow-base flex-col rounded-lg max-w-full min-h-32"
      isOpen={isOpen}
      modal
    >
      <DialogHeader headerType="h3">{t('groups.compose.divide')}</DialogHeader>

      {!showGroupMemberDivider && !showGroups ? (
        <GroupTypePicker
          onDismiss={dismissModal}
          options={options}
          submit={goToShowGroupMemberDivider}
        />
      ) : null}

      {showGroupMemberDivider &&
      (groupType === 'random' || groupType === 'willekeurige') ? (
        <GroupRandomMemberDivider
          calendarItemId={calendarItemId}
          members={members}
          onDismiss={dismissModal}
          submit={goToShowGroups}
        />
      ) : null}

      {showGroupMemberDivider && groupType === 'steams' ? (
        <GroupSteamsMemberDivider
          calendarItemId={calendarItemId}
          onDismiss={dismissModal}
          submit={steamsGoToShowGroups}
        />
      ) : null}

      {showGroups ? (
        <Groups
          amount={amountOfGroups}
          groups={groups}
          groupType={groupType}
          members={members.filter((member) => member.include)}
          onDismiss={dismissModal}
          roles={roles}
          submit={onSubmit}
        />
      ) : null}
    </Dialog>
  );
}
