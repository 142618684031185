import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import titleCase from 'utils/titleCase';
import URLS, { schooladminTabs } from 'utils/constants/urls';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { generatePath } from 'react-router-dom';

export function SyncTeamsButton() {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      href={generatePath(URLS.MANAGE_TAB, {
        tab: schooladminTabs.TEAMS,
      })}
      iconBefore={ArrowPathIcon}
      test={dataTestIds.page.manage.button.sync.teams}
    >
      {titleCase(t('synchronize.teams'))}
    </ActionStyledAsButton>
  );
}
