import { gql } from 'graphql-request';

export const TEACHER_STUDENT_BY_ID = gql`
  query teacherStudentById($userId: String!) {
    teacherStudentById(userId: $userId) {
      id
      assessment {
        id
        userId
        status
      }
      avatar
      classGroups {
        id
        name
        archived
        amountOfStudents
      }
      displayName
      email
      enabled
      firstName
      fullName
      idp
      language
      lastName
      roles
      userName
    }
  }
`;
