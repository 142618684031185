import { useContext, useEffect, useMemo } from 'react';

import uniqBy from 'lodash-es/uniqBy';

import {
  Content,
  Pagination,
  ResponsiveTable,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { UserContext } from 'providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import { generateStudentResultPagePathWithLesson } from 'utils/generateUrlPaths';
import LessonResultRow from './partials/LessonResultRow';

export default function LessonResultsOverview({
  currentPage,
  isLoading,
  itemsPerPage,
  lessonsData,
  setFilter,
  setItemsPerPage,
  setPage,
  setSort,
  sort,
}) {
  const t = useFormatMessage();
  const navigate = useNavigate();
  useTitle(t('lessons-overview.title'));

  const { id: userId, isStudent, isTeacher } = useContext(UserContext);

  const lessons = uniqBy(
    lessonsData?.content,
    (lesson) => lesson.calendarItem.id,
  );
  const totalPages = lessonsData?.pages;
  const totalItems = lessonsData?.total;

  const headers = useMemo(
    () =>
      [
        isTeacher && {
          key: 'title',
          label: t('global.class'),
          orderable: false,
        },
        {
          key: 'startTime',
          label: t('lessons-overview.column.date'),
          orderable: true,
        },
        {
          key: 'moment',
          label: t('lessons-overview.column.moment'),
          orderable: false,
        },
        {
          key: 'teacher',
          label: t('global.FTRPRF_SCHOOLTEACHER.singular'),
          orderable: false,
        },
        {
          key: 'actions',
          label: t('global.actions'),
          orderable: false,
          className: 'max-w-fit sr-only',
        },
      ].filter((i) => i),
    [isTeacher, t],
  );

  useEffect(() => {
    /* route immediately to the result for a student when the student only has results for 1 calendarItem  */
    if (isStudent && lessons.length === 1) {
      const lesson = lessons[0];
      navigate(generateStudentResultPagePathWithLesson(lesson, userId), {
        replace: true,
      });
    }
  }, [isStudent, lessons, navigate, userId]);

  return (
    <Content hasPaddingBottom>
      <div className="w-full mx-auto">
        {isTeacher && (
          <div className="w-1/2 my-4 xl:w-1/3">
            <SearchBar
              onChange={(value) => setFilter(value)}
              placeholder={t('lesson-results-overview-search')}
            />
          </div>
        )}
        <TableSkeleton loading={isLoading}>
          <ResponsiveTable
            emptyText={t('lesson-results-class-not-found')}
            headers={headers}
            mobileBreakpoint={768}
            mobileHeaderWidth={160}
            onSortChange={(newSort) => {
              setSort({ startTime: newSort.startTime });
            }}
            sort={sort}
          >
            {lessons?.map((lesson) => (
              <LessonResultRow key={lesson.calendarItem.id} lesson={lesson} />
            ))}
          </ResponsiveTable>

          {Boolean(lessons && lessons.length) && (
            <Pagination
              className="mt-12"
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={(page) => setPage(page)}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
        </TableSkeleton>
      </div>
    </Content>
  );
}
