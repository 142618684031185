import { ActionStyledAsButton } from '../buttons/ActionStyledAsButton';
import useFormatMessage from '../../hooks/useFormatMessage';

export function SelectOrClearAllRow({
  allButtonText = '',
  clearButtonText = '',
  onClickAll,
  onClickClear,
  showClearButton,
}) {
  const t = useFormatMessage();

  return (
    <p className="mt-4">
      <ActionStyledAsButton onClick={onClickAll} primary variant="text">
        {allButtonText || t('global.checkAll')}
      </ActionStyledAsButton>
      {showClearButton && (
        <ActionStyledAsButton onClick={onClickClear} primary variant="text">
          {clearButtonText || t('global.clearAll')}
        </ActionStyledAsButton>
      )}
    </p>
  );
}
