import { gql } from 'graphql-request';

export const ADMIN_RESET_PASSWORD = gql`
  mutation adminResetPassword($id: String!, $newPassword: String!) {
    adminResetPassword(id: $id, newPassword: $newPassword) {
      statusCode
      id
      message
    }
  }
`;
