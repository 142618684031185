import { gql } from 'graphql-request';

export const TEACHER_USER_LESSON_REPORT_BY_USER_ID = gql`
  query teacherUserLessonReportByUserId(
    $lessonSessionId: String!
    $userId: String!
  ) {
    teacherUserLessonReportByUserId(
      lessonSessionId: $lessonSessionId
      userId: $userId
    ) {
      lessonSessionId
      userId
      firstName
      lastName
      questionScores {
        overallResults {
          max
          earned
          viewMode
        }
        questions {
          questionId
          questionTitle
          type
          max
          earned
          feedback
          viewModes
          ... on QuestionMultipleChoiceScore {
            userAnswers
            answers {
              id
              value
              correct
              explanation
              numbering
            }
          }
          ... on QuestionOpenScore {
            userAnswer
          }
        }
      }
      scratchExerciseScores {
        max
        earned
        exercises {
          exerciseTitle
          exerciseVersionId
          amount
          successful
          judgeResult
        }
      }
      lastOpenedAt
      openedCount
      closedAt
    }
  }
`;
