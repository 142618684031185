import { Dialog, DialogActions, DialogContent, DialogHeader } from './index';
import { ActionStyledAsButton } from '../buttons/ActionStyledAsButton';
import { Loader } from '../Loader';
import useFormatMessage from '../../hooks/useFormatMessage';

export function StandardDialog({
  children,
  confirmText,
  isLoading,
  isOpen,
  onClick,
  onDismiss,
  title = '',
}) {
  const t = useFormatMessage();

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      {isLoading ? (
        <div className="h-5 w-5">
          <Loader />
        </div>
      ) : (
        <>
          {title && <DialogHeader>{title}</DialogHeader>}
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <ActionStyledAsButton onClick={onDismiss} variant="text">
              {t('global.cancel')}
            </ActionStyledAsButton>
            <ActionStyledAsButton onClick={onClick}>
              {confirmText}
            </ActionStyledAsButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
