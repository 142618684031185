import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import Calendar from './partials/Calendar';
import Language from './partials/Language';
import SlideViewer from './partials/SlideViewer';
import { SettingsBeingRetrieved } from './partials/SettingsBeingRetrieved';
import CodeBlocks from './partials/CodeBlocks';

export function Preferences() {
  const { isStudent, isTeacher, organization, settings } =
    useContext(UserContext);
  const { isCodefever } = organization;
  const hasCalendarSettings = organization.showCalendar && !isCodefever;
  if (!settings) {
    return <SettingsBeingRetrieved />;
  }

  return (
    <div className="h-full w-full p-4 border border-gray-300 rounded flex gap-14 flex-col">
      {!isCodefever && <Language />}
      {hasCalendarSettings && (isStudent || isTeacher) && <Calendar />}
      {isTeacher && !isCodefever && <SlideViewer />}
      <CodeBlocks settings={settings} />
    </div>
  );
}
