import {
  getHoursWithLeadingZero,
  getMinutesWithLeadingZero,
  isDateInThePast,
} from 'utils/timeHelpers';

export function getEventTimeInfo(start, end) {
  return {
    dateEvent: start,
    endTimeHours: getHoursWithLeadingZero(end),
    endTimeMinutes: getMinutesWithLeadingZero(end),
    itemIsInThePast: isDateInThePast(start),
    startTimeHours: getHoursWithLeadingZero(start),
    startTimeMinutes: getMinutesWithLeadingZero(start),
  };
}
