export default (definedStyles, styles, defaultStyle) => {
  const foundStyles = Object.keys(styles).filter(
    (style) =>
      Object.keys(definedStyles).includes(style.toUpperCase()) && styles[style],
  );

  if (foundStyles.length > 1) {
    throw new Error('Button should only have one active type');
  }

  return foundStyles[0]?.toUpperCase() || defaultStyle;
};
