import { gql } from 'graphql-request';

export const SCHOOLADMIN_DELETE_TEACHER_FROM_PROGRAM = gql`
  mutation schooladminDeleteTeacherFromProgram($programId: Int!, $id: ID!) {
    schooladminDeleteTeacherFromProgram(programId: $programId, id: $id) {
      statusCode
      id
      message
    }
  }
`;
