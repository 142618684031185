import React from 'react';
import IcoMoon, { iconList } from 'react-icomoon';

import iconSet from './activityTypeLogos.json';

const Icon = React.memo(({ className, itemName, onClick, size }) => (
  <IcoMoon
    className={className}
    icon={itemName}
    iconSet={iconSet}
    onClick={onClick}
    size={size}
  />
));
Icon.displayName = 'Icon';
Icon.defaultProps = {
  onClick: () => {},
  size: 32,
  className: '',
};

const IconKeys = iconList(iconSet);
const IconSet = new Set(IconKeys);

const DefaultIconName = 'book_open';

const DefaultIcon = <Icon itemName={DefaultIconName} />;

function IconOrDefault(props) {
  const { itemName } = props;
  const iconName = IconSet.has(itemName) ? itemName : DefaultIconName;

  return <Icon {...props} itemName={iconName} />;
}
export { Icon, IconKeys, IconSet, DefaultIcon, IconOrDefault };
