import { gql } from 'graphql-request';

export const ADMIN_DELETE_PROGRAM = gql`
  mutation adminDeleteProgram($id: Int!) {
    adminDeleteProgram(id: $id) {
      statusCode
      id
      message
    }
  }
`;
