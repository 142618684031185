/**
 * Purpose of this file:
 * This file serves a crucial role in ensuring consistency for certain generatePaths that might be utilized across various components. By centralizing these paths, it significantly enhances maintenance efficiency. For instance, if the destination of a link needs to be updated, you can accomplish this in just one location. Consequently, all dependent components that rely on this path will automatically reflect the change. This approach streamlines maintenance efforts and minimizes the risk of overlooking interconnected components that may require updates.
 */

import { generatePath } from 'react-router-dom';
import URLS from './constants/urls';

export const generateStudentResultPagePathWithLesson = (lesson, userId) =>
  generatePath(URLS.STUDENT_RESULT_PAGE_FOR_LESSON_PROGRAM, {
    calendarItemId: lesson.calendarItem.id,
    programId: lesson.chapter.programId,
    sessionId: lesson.calendarItem.sessionId,
    userId,
  });

export const generateStudentResultPagePath = (
  calendarItemId,
  programId,
  sessionId,
  userId,
) =>
  generatePath(URLS.STUDENT_RESULT_PAGE_FOR_LESSON_TAB_PROGRAM, {
    calendarItemId,
    programId,
    sessionId,
    tab: 'responses',
    userId,
  });

export const generateStudentResultPageTabPath = (
  calendarItemId,
  programId,
  lessonSessionId,
  tab,
  userId,
) =>
  generatePath(URLS.STUDENT_RESULT_PAGE_FOR_LESSON_TAB_PROGRAM, {
    calendarItemId,
    programId,
    sessionId: lessonSessionId,
    tab,
    userId,
  });
