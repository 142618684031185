import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'providers/UserProvider';

const myHeaders = new Headers();
myHeaders.append('Authorization', 'test');
myHeaders.append('Content-Type', 'application/json');

const iframeId = '_vm';
const iframeOrigin = 'https://tools.vrtinnovatie.be';
const body = document.getElementsByTagName('body')[0];
const iframeSource = `${iframeOrigin}/steams/virtual-moderator/vm-frontend/`;

let initFrameOnGroup = false;

const steamsLessons = [
  '2334', // Dev Missie 1
  '2216', // PROD Edubox Teamwork
  '2544', // PROD Edebox Teamwork - Adult - Labtests
  '2490', // PROD Edebox Teamwork - Adult - Part 2 Version 1
  '2371', // PROD Edebox Teamwork Volwassenen
  '3347', // PROD FR-vertaling - EDUbox Teamwork - Adult
  '3411', // PROD EN-vertaling - EDUbox Teamwork - Adult
];

const adultLessons = [
  // '2334', // Dev Missie 1
  '2544', // PROD Edebox Teamwork - Adult - Labtests
  '2490', // PROD Edebox Teamwork - Adult - Part 2 Version 1
  '2371', // PROD Edebox Teamwork Volwassenen
  '3347', // PROD FR-vertaling - EDUbox Teamwork - Adult
  '3411', // PROD EN-vertaling - EDUbox Teamwork - Adult
];

const englishLessons = ['3411'];
const frenchLessons = ['3347'];

// 1. VIRTUAL MODERATOR
// 2. GET GROUP ID
// 3. PASS PAGE NUMBER TO STEAMS
export default function useSteams(studioId) {
  const user = useContext(UserContext);
  const [groupId, setGroupId] = useState();

  const isSteams = user.organization.steams;

  const isSteamsLesson = steamsLessons.indexOf(studioId) > -1;
  const isSteamsAdult = adultLessons.indexOf(studioId) > -1;

  // 1. VIRTUAL MODERATOR
  const vmEventhandler = (event) => {
    if (event.origin !== iframeOrigin) return;
    const { height, width } = JSON.parse(event.data);
    const iframe = document.getElementById(iframeId);
    iframe.width = window.innerWidth < width ? window.innerWidth : width;
    iframe.height = window.innerHeight < height ? window.innerHeight : height;
  };

  const initVm = (forcedGroupId) => {
    if (isSteams || isSteamsLesson) {
      if (forcedGroupId || groupId) {
        let iframeParams = `?groupId=${groupId || forcedGroupId}`;
        if (isSteamsAdult) {
          iframeParams += '&cps2';
        }
        if (englishLessons.includes(studioId)) {
          iframeParams += '&lang=en';
        }
        if (frenchLessons.includes(studioId)) {
          iframeParams += '&lang=fr';
        }
        const frame = document.createElement('iframe');
        frame.src = iframeSource + iframeParams;
        frame.id = iframeId;
        frame.width = 600;
        frame.height = 120;
        frame.style =
          'border: none; position:fixed; bottom: 0px; right: 0px; z-index: 9999';
        body.appendChild(frame);
        window.addEventListener('message', vmEventhandler, false);
      } else {
        initFrameOnGroup = true;
      }
    } else {
      initFrameOnGroup = true;
    }
  };

  const destroyVm = () => {
    if (isSteams) {
      const iframe = document.getElementById(iframeId);
      if (iframe) {
        body.removeChild(iframe);
      }

      window.removeEventListener('message', vmEventhandler, false);
    }
  };
  // END VIRTUAL MODERATOR

  // 2. GET GROUP ID
  // currently a fake version, just get all groups and take the first one.
  // eslint-disable-next-line
  const getGroupId = () => {
    if (isSteams) {
      const raw = JSON.stringify({
        students: {
          $elemMatch: {
            externalId: user.id,
          },
        },
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch('https://steams.cs.kuleuven.be/api/groups/find', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setGroupId(data[0]._id);

            if (initFrameOnGroup) {
              initVm(data[0]._id);
            }
          }
        })
        .catch((error) => console.log('error', error));
    }
  };
  // Get group id on hook init.
  useEffect(() => {
    if (isSteams && isSteamsLesson) {
      getGroupId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSteams, isSteamsLesson]);

  // END GROUP

  // 3. PASS PAGE NUMBER TO STEAMS
  const sendPageNumber = (sequence) => {
    if (isSteams) {
      if (groupId) {
        const raw = JSON.stringify({
          group: groupId,
          type: 'lesson',
          lesson: {
            type: 'progress',
            progress: {
              slide: {
                sequence,
              },
            },
          },
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch('https://steams.cs.kuleuven.be/api/logs', requestOptions)
          .then((response) => response.text())
          // eslint-disable-next-line
          .then((result) => {
            // Response from steams is just groupId
            // console.log(result);
          })
          .catch((error) => console.log('error', error));
      } else {
        setTimeout(() => sendPageNumber(sequence), 1000);
      }
    }
  };
  // END PASS PAGE NUMBER TO STEAMS

  return {
    initVm,
    destroyVm,
    sendPageNumber,
    groupId,
    isSteams,
    isSteamsAdult,
  };
}
