import { gql } from 'graphql-request';

export const TEACHER_CREATE_CALENDAR_ITEM = gql`
  mutation teacherCreateCalendarItem($input: CalendarItemInput) {
    teacherCreateCalendarItem(input: $input) {
      id
      sessionId
      startTime
      endTime
      chapterItem {
        id
        name
        chapterId
        lessons {
          id
        }
      }
      closedAfterEndTime
      classGroups {
        id
        name
        archived
      }
      teamsCalendarItem {
        addToCalendarAction
        assignmentDescription
        assignmentTitle
        resourceDisplayName
      }
      groupLesson
      hidden
      viewMode
    }
  }
`;
