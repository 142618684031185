import c from '../utils/c';
import getBadgeStyle from '../utils/getComponentStyle';

const COLOR_STYLES = {
  red: 'bg-red-50 text-red-700 ring-red-700/10',
  yellow: 'bg-yellow-50 text-yellow-700 ring-yellow-700/10',
  green: 'bg-green-50 text-green-700 ring-green-700/10',
  blue: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  gray: 'bg-gray-50 text-gray-700 ring-gray-700/10',
  indigo: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
  purple: 'bg-purple-50 text-purple-700 ring-purple-700/10',
  pink: 'bg-pink-50 text-pink-700 ring-pink-700/10',
};

const generateStyles = (colorStyles) => {
  const styles = {
    WARNING: COLOR_STYLES.yellow,
    SUCCESS: COLOR_STYLES.green,
    DANGER: COLOR_STYLES.red,
    INFO: COLOR_STYLES.blue,
    SUCCESSACCENT: 'bg-green-200 text-green-700',
  };

  // add colors individually
  Object.keys(colorStyles).forEach((key) => {
    styles[key.toUpperCase()] = colorStyles[key];
  });

  return styles;
};

export const badgeStyles = generateStyles(COLOR_STYLES);

export function Badge({
  blue = false,
  children = null,
  className = '',
  color = '',
  danger = false,
  gray = false,
  green = false,
  indigo = false,
  info = false,
  pink = false,
  purple = false,
  red = false,
  success = false,
  successAccent = false,
  warning = false,
  yellow = false,
}) {
  const badgeClassNames = color
    ? COLOR_STYLES[color]
    : badgeStyles[
        getBadgeStyle(
          badgeStyles,
          {
            success,
            warning,
            danger,
            info,
            successAccent,
            gray,
            red,
            green,
            blue,
            yellow,
            indigo,
            purple,
            pink,
          },
          'SUCCESS',
        )
      ];
  return (
    <span
      className={c(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        badgeClassNames,
        className,
      )}
    >
      {children}
    </span>
  );
}
