import { QueryClient } from 'react-query';

import { get } from 'api';

const defaultQueryFn = ({ queryKey }) => get(queryKey[0]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
