import { useEffect, useState } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';
import { SearchBar } from '@ftrprf/tailwind-components';
import useSchooladminSmartSchoolClassGroups from 'hooks/api/plannerService/schooladmin/queries/useSchooladminSmartSchoolClassGroups';
import { sortBy } from 'lodash-es';
import { SyncClassesTable } from './SyncClassesTable';

export function SynchronizeSmartschool() {
  const t = useFormatMessage();

  const {
    data: originalClassGroups,
    isLoading,
    refetch,
  } = useSchooladminSmartSchoolClassGroups();

  const [classGroups, setClassGroups] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredClassGroups, setFilteredClassGroups] = useState([]);

  useEffect(() => {
    if (Boolean(originalClassGroups)) {
      setClassGroups(
        sortBy(originalClassGroups, (classgroup) => classgroup.name),
      );
    }
  }, [originalClassGroups]);

  useEffect(() => {
    setFilteredClassGroups(
      classGroups.filter((classGroup) =>
        classGroup.name.toLowerCase().includes(filter.toLowerCase()),
      ),
    );
  }, [classGroups, filter]);

  return (
    <div>
      <div className="md:w-1/2 mb-4">
        <SearchBar
          id="filterClassgroupByName"
          onChange={setFilter}
          placeholder={t('global.search.by.name')}
          test={dataTestIds.page.manage.smartschool.inputs.filterClassgroup}
        />
      </div>
      <SyncClassesTable
        classGroups={filteredClassGroups}
        isLoading={isLoading}
        refetch={refetch}
        setClassGroups={setFilteredClassGroups}
      />
    </div>
  );
}
