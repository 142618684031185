import { useParams } from 'react-router-dom';

import useAdminProgramById from 'hooks/api/plannerService/admin/queries/useAdminProgramById';
import useAdminPrograms from 'hooks/api/plannerService/admin/queries/useAdminPrograms';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import Chapter from './Chapter';

export default function ChapterContainer() {
  const { filter, programId } = useParams();
  const MAX_PROGRAMS = 500;

  const {
    data: program,
    isLoading: isLoadingProgram,
    refetch,
  } = useAdminProgramById(programId);

  const { data: programsData } = useAdminPrograms({
    filter: [
      {
        key: 'name',
        value: filter || '',
        operation: filterOperation.LIKE,
      },
    ],
    page: 0,
    size: MAX_PROGRAMS,
    sort: {
      weight: sortOperation.DESC,
    },
  });

  return (
    <Chapter
      currentProgram={program}
      isLoading={isLoadingProgram}
      programs={programsData?.content}
      refetch={refetch}
    />
  );
}
