import Smartschool from 'assets/vectors/smartschool.svg';
import { Loader } from '@ftrprf/tailwind-components';

export function SmartschoolButton({ isLoading, onClick, text }) {
  return (
    <button
      className="border-2 border-red-500 flex items-center py-1 rounded-lg smartschoolButton text-white"
      onClick={onClick}
      type="submit"
    >
      <img alt="" className="block h-6 px-1 w-auto" src={Smartschool} />
      <span className="border-l-2 border-red-500 font-bold flex px-3">
        {isLoading && <Loader className="h-6 w-6 max-w-[1.5rem] mr-2" white />}
        {text}
      </span>
    </button>
  );
}
