export const getSortedCalendarItems = (calendarItems) =>
  calendarItems?.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

export function getEarliestCalendarItem(calendarItems) {
  const sorted = getSortedCalendarItems(calendarItems);

  return sorted?.[0];
}

export const getEarliestCalendarDate = (calendarItems) => {
  const earliestCalendarItem = getEarliestCalendarItem(calendarItems);

  if (earliestCalendarItem) {
    return earliestCalendarItem.startTime;
  }

  return undefined;
};
