import { useContext } from 'react';
import { generatePath, Outlet } from 'react-router-dom';
import {
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import { Stats } from '@ftrprf/tailwind-components';

import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import useFormatMessage from 'hooks/useFormatMessage';
import { UserContext } from 'providers/UserProvider';
import ftrprfUserRoles from 'utils/constants/ftrprfUserRoles';
import URLS, { schooladminTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { getCurrentPeriod } from 'utils/getCurrentPeriod';

export default function ManageSchooladmin() {
  const t = useFormatMessage();

  const { organization } = useContext(UserContext);
  const { isSmartschool, isTeams, periods, stats } = organization;

  return (
    <GeneralLayout>
      <PageHeaderWrapper
        borderBottom={false}
        marginBottom={false}
        title={organization.name}
      >
        <div className="mt-4 flex flex-col md:flex-row gap-4 md:justify-between md:items-center">
          <div className="w-full col-span-3 flex flex-col md:flex-row gap-2 p-1">
            <Stats
              href={generatePath(URLS.MANAGE_TAB, {
                tab: `${schooladminTabs.USERS}?roles=${ftrprfUserRoles.SCHOOLSTUDENT}`,
              })}
              logo={<AcademicCapIcon className="h-5 w-5 text-white" />}
              name={t('global.STUDENT.plural')}
              test={dataTestIds.page.manage.button.stat.students}
              total={stats?.students || 0}
            />

            <Stats
              href={generatePath(
                URLS.MANAGE_TAB,
                {
                  tab: `${schooladminTabs.USERS}?roles=${ftrprfUserRoles.SCHOOLTEACHER}`,
                },
                { replace: true },
              )}
              logo={<UserIcon className="h-5 w-5 text-white" />}
              name={t('global.TEACHER.plural')}
              test={dataTestIds.page.manage.button.stat.teachers}
              total={stats?.teachers || 0}
            />

            <Stats
              href={generatePath(URLS.MANAGE_TAB, {
                tab: schooladminTabs.CLASSES,
              })}
              logo={<UserGroupIcon className="h-5 w-5 text-white" />}
              name={t('global.CLASS.plural')}
              test={dataTestIds.page.manage.button.stat.classes}
              total={getCurrentPeriod(periods)?.stats?.classes || 0}
            />

            <Stats
              href={generatePath(URLS.MANAGE_TAB, {
                tab: schooladminTabs.PROGRAMS,
              })}
              logo={<BookOpenIcon className="h-5 w-5 text-white" />}
              name={t('global.PROGRAM.plural')}
              test={dataTestIds.page.manage.button.stat.programs}
              total={getCurrentPeriod(periods)?.stats?.programs || 0}
            />
          </div>
        </div>

        <TabList>
          <Tab
            label={t('global.USER.plural')}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: schooladminTabs.USERS,
            })}
          />
          <Tab
            label={t('global.CLASS.plural')}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: schooladminTabs.CLASSES,
            })}
          />
          <Tab
            label={t('global.PROGRAM.plural')}
            to={generatePath(URLS.MANAGE_TAB, {
              tab: schooladminTabs.PROGRAMS,
            })}
          />
          {isTeams && (
            <Tab
              label={t('global.teams')}
              to={generatePath(URLS.MANAGE_TAB, {
                tab: schooladminTabs.TEAMS,
              })}
            />
          )}
          {isSmartschool && (
            <Tab
              label={t('global.smartschool')}
              to={generatePath(URLS.MANAGE_TAB, {
                tab: schooladminTabs.SMARTSCHOOL,
              })}
            />
          )}
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet />
      </div>
    </GeneralLayout>
  );
}
