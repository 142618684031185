import { PlusIcon } from '@heroicons/react/24/solid';
import { dataTestIds } from 'utils/dataTestIds';
import titleCase from 'utils/titleCase';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function AddUserButton({ openCreateUserModal }) {
  const t = useFormatMessage();

  return (
    <ActionStyledAsButton
      iconBefore={PlusIcon}
      onClick={openCreateUserModal}
      test={dataTestIds.page.manage.button.add.user}
    >
      {titleCase(
        t('schooladmin-overview.new-user', {
          what: t('global.USER.singular'),
        }),
      )}
    </ActionStyledAsButton>
  );
}
