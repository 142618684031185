import { gql } from 'graphql-request';

export const UPDATE_OWN_USER_UI_SETTINGS = gql`
  mutation updateOwnUserUiSettings($uiSettings: String!) {
    updateOwnUserUiSettings(uiSettings: $uiSettings) {
      statusCode
      id
      message
    }
  }
`;
