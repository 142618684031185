import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';

import {
  Actions,
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useAdminPrograms from 'hooks/api/plannerService/admin/queries/useAdminPrograms';
import useFormatMessage from 'hooks/useFormatMessage';

import { filterOperation } from 'utils/constants/filter';
import { mobile } from 'utils/constants/mediaWidth';
import { dataTestIds } from 'utils/dataTestIds';
import { isAfter, subDays } from 'date-fns';
import { PeriodsDropdown } from './PeriodsDropdown';

export default function AddProgramToOrganizationDialog({
  currentPeriod,
  isOpen,
  periods,
  programsByOrganization,
  setIsAddProgramDialogOpen,
  setIsEnterSeatsDialogOpen,
  setPeriodId,
  setProgramId,
}) {
  const t = useFormatMessage();
  const [page, setPage] = useState(1);
  const [filter, setFilterState] = useState('');
  const [period, setPeriodState] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { data: programsData, isLoading } = useAdminPrograms({
    filter: [
      {
        key: 'name',
        value: filter,
        operation: filterOperation.LIKE,
      },
    ],
    page: page - 1,
    size: itemsPerPage,
    sort: { name: 'ASC' },
  });

  const totalPages = programsData?.pages;
  const totalItems = programsData?.total;
  const programs = programsData?.content || [];

  const setFilter = (value) => {
    setPage(1);
    setFilterState(value);
  };

  const setPeriod = (value) => {
    setPage(1);
    setPeriodState(value);
  };

  useEffect(() => {
    if (!!period && period.id) setPeriodId(period.id);

    if (!period && currentPeriod) {
      setPeriod(currentPeriod);
    }
  }, [currentPeriod, period, setPeriodId]);

  /**
   * Only allow programs to be added if the endDate of the selected period is
   * in the future.
   *
   * Subtract one day from today before comparing to ensure that periods which
   * end on the same day as today can still be added (even if it is pointless
   * to do so).
   * */
  const now = new Date();
  const allowProgramToBeAdded =
    !!period && isAfter(new Date(period.endDate), subDays(now, 1));

  // buttons
  const actions = (program) => {
    /**
     * ideally we should have a different call to fetch the non-added programs
     */
    const samePrograms = programsByOrganization?.filter(
      (p) => p.id === program.id,
    );
    const alreadyAddedProgram =
      !!period && samePrograms.every((p) => p.period.id !== period.id);

    return [
      alreadyAddedProgram &&
        allowProgramToBeAdded && {
          icon: <PlusIcon className="h-4 w-4 inline-block mr-2" />,
          label: t('global.PROGRAM.add'),
          testName: dataTestIds.modal.addProgramToOrganization.table.button.add,
          onClick: () => {
            setProgramId(program.id);
            setIsEnterSeatsDialogOpen(true);
          },
        },
    ];
  };

  const onDismiss = () => {
    setIsAddProgramDialogOpen(false);
  };

  return (
    <Dialog
      className="flex w-full md:max-w-2xl lg:max-w-fit bg-white shadow-base flex-col rounded-lg"
      isOpen={isOpen}
      modal
      onDismiss={onDismiss}
    >
      <DialogHeader>{t('add-program-dialog.add-to-organization')}</DialogHeader>
      <DialogContent>
        <div className="w-full m-4 mx-auto">
          {!!period && !!periods && (
            <PeriodsDropdown
              id="editProgramDialog-period"
              inModal
              period={period}
              periods={periods}
              setPeriod={setPeriod}
            />
          )}
          <div className="w-full flex justify-between">
            <div className="justify-between w-full">
              <SearchBar
                className="mb-4"
                onChange={(event) => {
                  setPage(1);
                  setFilter(event);
                }}
                placeholder={t('add-program-dialog.what_are_you_looking_for')}
              />
            </div>
          </div>
          <TableSkeleton loading={isLoading}>
            <ResponsiveTable
              headers={[
                {
                  key: 'name',
                  label: t('program-overview.column.name'),
                  orderable: false,
                },
                {
                  key: 'type',
                  label: t('program-overview.column.type'),
                  orderable: false,
                },
                {
                  key: 'actions',
                  label: t('global.actions'),
                  orderable: false,
                  className: 'max-w-fit sr-only',
                },
              ]}
              mobileBreakpoint={mobile}
              mobileHeaderWidth={160}
            >
              {programs?.map((program) => (
                <ResponsiveTableRow key={program.id} data={program}>
                  <ResponsiveTableCol>{program.name}</ResponsiveTableCol>
                  <ResponsiveTableCol>{program.type}</ResponsiveTableCol>
                  <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                    <Actions items={actions(program)} />
                  </ResponsiveTableCol>
                </ResponsiveTableRow>
              ))}
            </ResponsiveTable>
          </TableSkeleton>
        </div>

        {Boolean(programs && programs.length) && (
          <Pagination
            className="mt-12"
            currentPage={page}
            itemsPerPage={itemsPerPage}
            setCurrentPage={(page) => setPage(page)}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
            totalPages={totalPages}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton
          disabled={isLoading}
          onClick={onDismiss}
          test={dataTestIds.modal.addProgramToOrganization.button.close}
          variant="text"
        >
          {t('add-program-dialog.close')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
