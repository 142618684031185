const ftrprfUserRoles = {
  SCHOOLTEACHER: 'FTRPRF_SCHOOLTEACHER',
  ADMIN: 'FTRPRF_ADMIN',
  SCHOOLSTUDENT: 'FTRPRF_SCHOOLSTUDENT',
  SCHOOLADMIN: 'FTRPRF_SCHOOLADMIN',
};

export const userRolesForAdminDropdown = [
  ftrprfUserRoles.SCHOOLTEACHER,
  ftrprfUserRoles.SCHOOLSTUDENT,
  ftrprfUserRoles.SCHOOLADMIN,
];

ftrprfUserRoles.FTRPRF_SCHOOLTEACHER = ftrprfUserRoles.SCHOOLTEACHER;
ftrprfUserRoles.FTRPRF_ADMIN = ftrprfUserRoles.ADMIN;
ftrprfUserRoles.FTRPRF_SCHOOLSTUDENT = ftrprfUserRoles.SCHOOLSTUDENT;
ftrprfUserRoles.FTRPRF_SCHOOLADMIN = ftrprfUserRoles.SCHOOLADMIN;

export default ftrprfUserRoles;
