import { Navigate, generatePath } from 'react-router-dom';
import URLS, { adminTabs } from 'utils/constants/urls';

export default function ManageAdminIndex() {
  return (
    <Navigate
      replace
      to={generatePath(URLS.MANAGE_TAB, {
        tab: adminTabs.DEFAULT,
      })}
    />
  );
}
