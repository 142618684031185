import { generatePath, NavLink, useLocation } from 'react-router-dom';
import {
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

import {
  Content,
  DemoInfoBar,
  Header,
  InfoBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS, { studioUrls } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';

export function TeacherHeader({
  choseActivePeriod,
  currentPeriod,
  hasActivePeriod,
  onPeriodClick,
}) {
  const t = useFormatMessage();

  const user = useContext(UserContext);
  const { demo, id, isImpersonating, organization } = user;
  const { pathname } = useLocation();

  const container = (children) => (
    <Content hasPaddingBottom={false}>{children}</Content>
  );

  return (
    <>
      <Header
        choseActivePeriod={choseActivePeriod}
        currentPeriod={currentPeriod}
        icons={[
          {
            icon: <PresentationChartBarIcon />,
            label: t('header.navigation.studio'),
            showLabel: true,
            test: dataTestIds.header.studio,
            to: isImpersonating
              ? `${process.env.REACT_APP_STUDIO_URL}${generatePath(
                  studioUrls.IMPERSONATE,
                  {
                    id,
                  },
                )}`
              : process.env.REACT_APP_STUDIO_URL,
          },
          {
            label: t('header.help'),
            to:
              organization.language?.toLowerCase() === 'nl'
                ? process.env.REACT_APP_HELP_URL
                : process.env.REACT_APP_HELP_EN_URL,
            target: '_blank',
            icon: <QuestionMarkCircleIcon />,
            test: dataTestIds.header.help,
          },
        ]}
        onPeriodClick={onPeriodClick}
        organization={organization}
        profileLinks={[
          {
            label: t('header.profile'),
            to: URLS.PROFILE,
            test: dataTestIds.header.userMenu.links.profile,
          },
          {
            label: t('header.help_videos'),
            to:
              organization.language?.toLowerCase() === 'nl'
                ? process.env.REACT_APP_HELP_URL
                : process.env.REACT_APP_HELP_EN_URL,
            target: '_blank',
            test: dataTestIds.header.userMenu.links.help,
          },
          {
            label: isImpersonating
              ? t('global.unimpersonate')
              : t('header.logout'),
            to: URLS.LOGOUT,
            test: dataTestIds.header.userMenu.links.logout,
          },
        ]}
        user={user}
      >
        {organization.showCalendar && (
          <NavLink data-test={dataTestIds.header.home} to={URLS.HOME}>
            {t('calendar.title')}
          </NavLink>
        )}

        <NavLink data-test={dataTestIds.header.learn} to={URLS.LEARN}>
          {t('global.LEARN')}
        </NavLink>

        {/* <NavLink to={URLS.EXERCISE}>{t('global.EXERCISE.plural')}</NavLink> */}

        <NavLink
          data-test={dataTestIds.header.classgroups}
          to={URLS.CLASSGROUP}
        >
          {t('global.CLASS.plural')}
        </NavLink>

        {organization.language?.toLowerCase() === 'nl' && (
          <NavLink data-test={dataTestIds.header.hackroom} to={URLS.HACKROOM}>
            {t('global.hack_room')}
          </NavLink>
        )}
      </Header>

      {demo && <DemoInfoBar container={container} path={pathname} />}
      {!hasActivePeriod ? (
        <InfoBar
          className="max-w-screen-md"
          container={container}
          text={t('header.no.active.period.studentAndTeacher')}
        />
      ) : null}
    </>
  );
}
