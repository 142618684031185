export const dataTestIds = {
  component: {
    header: {
      userMenu: 'user-menu',
      logo: 'logo',
    },

    actions: {
      button: {
        showMore: 'table-button-show-more',
      },
    },

    pagination: {
      button: {
        next: 'pagination-button-next',
        previous: 'pagination-button-previous',
        number: 'pagination-button-number',
      },
      text: {
        startItemCurrentSelection:
          'pagination-text-start-item-current-selection',
        endItemCurrentSelection: 'pagination-text-end-item-current-selection',
        totalItems: 'pagination-text-total-items',
      },
    },

    multiCollapsable: {
      self: 'multi-collapsable-self',
    },

    slideviewer: {
      viewModes: {
        button: {
          multiscreen: 'slideviewer-view-modes-multiscreen',
          classical: 'slideviewer-view-modes-classical',
          selfstudy: 'slideviewer-view-modes-selfstufy',
          projection: 'slideviewer-view-modes-projection',
        },
      },

      slide: {
        button: {
          left: 'slideviewer-slide-button-left',
          right: 'slideviewer-slide-button-right',
          submitOpenQuestion: 'slideviewer-slide-button-submit-open-question',
          submitMultipleChoiceAnswer:
            'slideviewer-slide-button-submit-multiple-choice-question',
        },

        input: {
          openQuestion: 'slideviewer-slide-input-open-question',
        },
      },
    },

    stats: {
      name: 'state-name',
      amount: 'stats-amount',
    },

    searchBar: {
      button: {
        clear: 'clear-searchbar',
      },
    },

    table: {
      empty: 'table-empty',
    },

    subjectCard: {
      self: 'subject-card',
      text: {
        badges: 'subject-card-text-badges',
        title: 'subject-card-text-title',
      },
      button: {
        downloadDocument: 'subject-card-button-download-document',
      },
    },

    contentCard: {
      self: 'content-card',
    },

    assistant: {
      speechBubble: 'assistant-speech-bubble-text',
      chatBox: 'assistant-chat-box',
      chatContent: 'assistant-chat-content',
      chatHeader: 'assistant-chat-header',
      closedButton: 'assistant-closed-button',
    },
  },

  modal: {
    slideQuestionConfirmationModal: {
      self: 'slide-question-confirmation-modal',

      button: {
        submit: 'slide-question-confirmation-modal-button-submit',
        cancel: 'slide-question-confirmation-modal-button-cancel',
      },
    },

    stats: {
      name: 'state-name',
      amount: 'stats-amount',
    },
  },
};
