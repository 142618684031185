import { lazy, useCallback, useContext } from 'react';

import { LanguageContext } from 'providers/LanguageProvider';

import useSaveScratchExercise from 'hooks/api/portfolioService/mutations/useSaveScratchExercise';
import useParams from 'hooks/useParams';

import b64toBlob from 'utils/b64toBlob';
import { useTestExercise } from 'hooks/useTestExercise';

const Scratch = lazy(() => import('../partials/Scratch'));

export default function Exercise({ exercise, isRestarting, restart }) {
  const { lessonSessionId, studioId } = useParams();

  const {
    isLoading: isLoadingTests,
    results: testResults,
    test,
  } = useTestExercise({
    exerciseId: exercise.exerciseId,
    language: exercise?.exerciseLanguage,
    testPlanId: exercise.versionTestPlanId,
    versionId: exercise.versionId,
  });

  const { language } = useContext(LanguageContext);

  const { isLoading: isSaving, mutateAsync: saveExercise } =
    useSaveScratchExercise();

  const save = useCallback(
    ({ exerciseBlob }) =>
      saveExercise({
        exerciseId: exercise?.exerciseId,
        exerciseVersionId: exercise?.versionId,
        file: exerciseBlob,
        language: exercise?.exerciseLanguage,
        lessonSessionId,
        studioId,
        testPlanId: exercise?.versionTestPlanId,
      }),
    [
      exercise?.exerciseId,
      exercise?.exerciseLanguage,
      exercise?.versionId,
      exercise?.versionTestPlanId,
      lessonSessionId,
      saveExercise,
      studioId,
    ],
  );

  // useEffect(() => {}, [exercise?.content]);

  const saveCurrentExercise = () => {
    saveExercise({
      exerciseId: exercise?.exerciseId,
      exerciseVersionId: exercise?.versionId,
      file: b64toBlob(exercise?.content),
      language: exercise?.exerciseLanguage,
      lessonSessionId,
      studioId,
      testPlanId: exercise?.versionTestPlanId,
    });
  };

  return (
    <Scratch
      exercise={{
        blob: b64toBlob(exercise?.content),
        exerciseVersionId: exercise?.versionId,
        id: exercise?.exerciseId,
        language: exercise?.exerciseLanguage,
        testPlanId: exercise?.versionTestPlanId,
        testResults,
        title: exercise?.exerciseTitle,
        versionName: exercise?.versionName,
      }}
      isLoading={false}
      isLoadingTests={isLoadingTests}
      isOwnedExercise
      isRestarting={isRestarting}
      isSaving={isSaving}
      language={language}
      onExerciseLoaded={() => {
        saveCurrentExercise();
      }}
      restart={restart}
      save={save}
      test={test}
    />
  );
}
