import { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { STUDENT_LESSONS } from 'api/plannerService/student/queries/studentLessons';
import { TEACHER_LESSONS } from 'api/plannerService/teacher/queries/teacherLessons';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

/**
 *     Lessons can be filtered on each of the below properties (either combined or separate)
 *     @Id
 *     private Long calendarItemId;
 *     private String calendarItemSessionId;
 *     @Temporal(TemporalType.TIMESTAMP)
 *     private Date startTime;
 *     @Temporal(TemporalType.TIMESTAMP)
 *     private Date endTime;
 *     private Boolean calendarItemClosedAfterEndTime;
 *     private Boolean calendarItemGroupLesson;
 *     @Enumerated(EnumType.STRING)
 *     private ViewMode calendarItemViewMode;
 *     private Boolean calendarItemHidden;
 *     @Type(type = "uuid-char")
 *     private UUID userId;
 *     private String firstName;
 *     private String lastName;
 *     private Long chapterItemId;
 *     private String chapterItemName;
 *     private String chapterItemBlobUri;
 *     private Long chapterItemPosition;
 *     private Long chapterId;
 *     private String chapterName;
 *     private Long chapterProgramId;
 *     private String chapterBlobUri;
 *     private String color;
 *     private Long studioId;
 *     private Long lessonContentId;
 *     private String lessonContentType;
 *     private String lessonContentImageUri;
 *     private String lessonContentTitle;
 *     private String classGroupName;
 *     private Long classGroupId;
 *     private Long organizationId;
 */
export default function useLessons({
  filter,
  onBefore = () => {},
  page,
  size,
  sort,
}) {
  const toast = useToast();
  const t = useFormatMessage();
  const { isStudent } = useContext(UserContext);

  const gqlQuery = isStudent ? STUDENT_LESSONS : TEACHER_LESSONS;
  const functionName = isStudent ? 'studentLessons' : 'teacherLessons';

  const info = useQuery(
    [functionName, filter, size, page, sort],
    () => {
      onBefore();
      return plannerServiceQueryClient.request(gqlQuery, {
        filter,
        size,
        page,
        sort,
      });
    },
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: `${t('global.THE.plural')} ${t('global.LESSON.plural')}`,
        });
      },
    },
  );

  return { ...info, data: info?.data?.[functionName] };
}
