import { UNKNOWN_ERROR, errorKeys } from 'utils/constants/errorKeys';

export function getIsSingleErrorMessage(messages) {
  const isSingleGeneralError =
    messages.length === 1 &&
    (!messages[0].errorCategoryName ||
      messages[0].errorCategoryName.includes(UNKNOWN_ERROR) ||
      messages[0].errorCategoryName.includes(
        errorKeys.MICROSOFT_GRAPH_CALL_FAILED,
      ));

  const generalErrors = messages.filter(
    (message) =>
      !messages[0].errorCategoryName ||
      message.errorCategoryName?.includes(UNKNOWN_ERROR),
  );
  const hasOnlyGeneralErrors = messages.length === generalErrors.length;

  return !!(isSingleGeneralError || hasOnlyGeneralErrors);
}
