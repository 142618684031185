import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_CREATE_ORGANIZATION } from 'api/plannerService/admin/mutations/adminCreateOrganization';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useAdminCreateOrganization() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    (input) =>
      plannerServiceQueryClient.request(ADMIN_CREATE_ORGANIZATION, {
        input,
      }),
    {
      mutationKey: 'adminCreateOrganization',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('global.ORGANIZATION.singular'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('global.ORGANIZATION.singular'),
        });
      },
    },
  );
}
