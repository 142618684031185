import useFormatMessage from 'hooks/useFormatMessage';
import Confused from 'assets/vectors/error-pages/confused.svg';
import Empty from 'assets/vectors/error-pages/empty.svg';

export function EmptyState({ image, imageClass = 'w-20', textKey }) {
  const errorImages = [Confused, Empty];
  const randomIndex = Math.floor(Math.random() * errorImages.length);
  const randomImageSrc = errorImages[randomIndex];

  const t = useFormatMessage();

  return (
    <div className="flex justify-center flex-col items-center gap-8 mt-16 text-center">
      <img alt="" className={imageClass} src={image ?? randomImageSrc} />
      <p className="max-w-[70ch]">{t(textKey)}</p>
    </div>
  );
}
