export function DefaultStep({ onClick, step }) {
  return (
    <button
      className="group flex items-center h-fit"
      onClick={onClick}
      type="submit"
    >
      <span className="flex items-center px-6 py-2 text-sm font-medium">
        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
          <span className="text-gray-500 group-hover:text-gray-900">
            {step.id}
          </span>
        </span>
        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
          {step.name}
        </span>
      </span>
    </button>
  );
}
