import useFormatMessage from 'hooks/useFormatMessage';

import { ReactComponent as Loader } from 'assets/vectors/logo-animated-lines.svg';

export function SteamsIframeCenteredLoader() {
  const t = useFormatMessage();

  return (
    <div className="p-4 full-width">
      <p>{t('groups.compose.steams.loading')}</p>
      <div className="flex align-middle justify-center p-8">
        <Loader className="w-32" />
      </div>
    </div>
  );
}
