import { useEffect, useMemo, useState } from 'react';

const defaultOptions = {
  esc: true,
  clickOutside: true,
  onClose: null,
};

export default function useCloseState(ref, options) {
  const [isOpen, setIsOpen] = useState(false);

  const mergedOptions = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  const handleEvent = useMemo(
    () =>
      function handleEventFn(event) {
        const remove = () => {
          document.removeEventListener('mousedown', handleEventFn);
          document.removeEventListener('keydown', handleEventFn);
        };

        if (
          mergedOptions.clickOutside &&
          !ref?.current.contains(event.target)
        ) {
          remove();
          setIsOpen(false);

          if (mergedOptions.onClose) {
            mergedOptions.onClose();
          }
        } else if (mergedOptions.esc && event.keyCode === 27) {
          remove();
          setIsOpen(false);

          if (mergedOptions.onClose) {
            mergedOptions.onClose();
          }
        }
      },
    [mergedOptions, ref, setIsOpen],
  );

  useEffect(() => {
    if (isOpen) {
      if (mergedOptions.clickOutside) {
        document.addEventListener('mousedown', handleEvent);
      }

      if (mergedOptions.esc) {
        document.addEventListener('keydown', handleEvent);
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('keydown', handleEvent);
    };
  }, [isOpen, mergedOptions, handleEvent]);

  return [isOpen, setIsOpen];
}
