import useTeacherGroupCompositionByActivity from 'hooks/api/plannerService/teacher/queries/useTeacherGroupCompositionByActivity';

export function TeacherGroupCompositionByActivity(activity, setGroups) {
  const { data: groups } = useTeacherGroupCompositionByActivity({ activity });

  if (groups.length) {
    setGroups(groups);
  }

  return null;
}
