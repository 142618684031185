import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';

export function viewModeToLowerCase(viewMode) {
  return `${viewMode ?? viewModes.CLASSICAL}`.toLowerCase();
}
export function viewModeToUpperCase(viewMode) {
  return `${viewMode ?? viewModes.CLASSICAL}`.toUpperCase();
}

export const generateScratchExercisePath = ({
  sessionId,
  studioId,
  versionId,
}) => {
  if (!studioId || !versionId || !sessionId) {
    return '';
  }

  return generatePath(URLS.EXERCISE_START_EXERCISEVERSIONID_LESSONSESSIONID, {
    studioId,
    lessonSessionId: sessionId,
    exerciseVersionId: versionId,
  });
};

export const generateCurrentLessonPath = ({
  sessionId,
  slideId,
  studioId,
  viewMode,
}) => {
  if (!slideId || !sessionId) {
    return '';
  }

  return generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID_SLIDEID, {
    studioId,
    viewMode: viewModeToLowerCase(viewMode),
    sessionId,
    slideId,
  });
};

/**
 * Determines the target slide ID based on the current slide ID, URL slide ID, and available slides.
 * Use the URL slide ID whenever it differs from the current slide ID.
 * Otherwise use the current slide ID, unless the current slide ID is not present (undefined / null)
 *
 * @param {string} slideId - The current slide ID.
 * @param {string} urlSlideId - The slide ID from the URL.
 * @param {Array} slides - An array of available slides.
 * @returns {string} - The target slide ID to be used for navigation and state updates.
 */
export function determineTargetSlideId(urlSlideId, slideId, slides) {
  let targetSlideId;

  if (String(urlSlideId) !== String(slideId)) {
    targetSlideId = urlSlideId;
  } else {
    targetSlideId = slideId ?? slides[0]?.id;
  }

  return targetSlideId;
}
