import { useContext } from 'react';
import c from '../../utils/c';
import { SlideViewerContext } from './SlideViewerContextProvider';
import useFormatMessage from '../../hooks/useFormatMessage';
import { dataTestIds } from '../../utils/constants/dataTestIds';

/**
 * When the current slide is the last slide,
 * you can't go further Left and the Left actions should be disabled
 *
 * @param {Array} slides
 * @param {number} currentSlideIndex
 * @returns boolean
 */
export function isLeftActionDisabled(slides, currentSlideIndex) {
  const isDisabled = currentSlideIndex <= 0;

  return isDisabled;
}

export default function SlideViewerLeftAction({ children, className }) {
  const t = useFormatMessage();

  const {
    currentSlideIndex,
    setShowConfirmationDialog,
    setSlide,
    showConfirmationDialog,
    slides,
    submitAnswer,
  } = useContext(SlideViewerContext);

  const disabled = isLeftActionDisabled(slides, currentSlideIndex);

  return (
    <button
      aria-label={t('styled-slide-viewer.previous')}
      className={c(
        'slideviewerSlideLeft',
        className,
        !disabled && 'text-gray-400 hover:text-black',
        disabled &&
          !showConfirmationDialog &&
          'text-gray-100 cursor-not-allowed',
      )}
      data-test={dataTestIds.component.slideviewer.slide.button.left}
      disabled={disabled || showConfirmationDialog}
      onClick={() => {
        if (submitAnswer) {
          setShowConfirmationDialog(true);
        } else {
          setSlide(currentSlideIndex - 1);
        }
      }}
      type="button"
    >
      {children}
    </button>
  );
}
