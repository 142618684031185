import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import {
  ArrowDownOnSquareIcon,
  KeyIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import useFormatMessage from 'hooks/useFormatMessage';

export function UserActions({
  canDelete,
  canEdit,
  canResetPassword,
  isDirty,
  isSaving,
  openRemoveModal,
  openResetModal,
}) {
  const t = useFormatMessage();

  return (
    <div className="full-w grid grid-cols-2 gap-4 lg:inline-flex mb-6 lg:self-end">
      {canResetPassword && (
        <ActionStyledAsButton
          className="whitespace-nowrap sm:flex-grow-0"
          onClick={openResetModal}
          secondary
        >
          <KeyIcon className="h-3 w-3 mr-2" />
          {t('global.reset-password')}
        </ActionStyledAsButton>
      )}
      {canDelete && (
        <ActionStyledAsButton
          className="whitespace-nowrap sm:flex-grow-0"
          error
          onClick={openRemoveModal}
        >
          <TrashIcon className="h-3 w-3 mr-2" />
          {t('global.delete')}
        </ActionStyledAsButton>
      )}
      {canEdit && (
        <ActionStyledAsButton
          className="whitespace-nowrap sm:flex-grow-0"
          disabled={!isDirty || isSaving}
          type="submit"
        >
          <ArrowDownOnSquareIcon className="h-3 w-3 mr-2" />
          {t('global.save-changes')}
        </ActionStyledAsButton>
      )}
    </div>
  );
}
