import useFormatMessage from 'hooks/useFormatMessage';
import Abducted from 'assets/vectors/error-pages/abducted.svg';
import { isArray } from 'lodash-es';
import { ErrorBase } from './ErrorBase';

export function ErrorAbducted({ message, statusCode = '', titleKey }) {
  const t = useFormatMessage();
  const title = t(titleKey);
  const messages = isArray(message) ? message : [message];

  return (
    <ErrorBase
      image={Abducted}
      messages={messages}
      statusCode={statusCode}
      title={title}
    />
  );
}
