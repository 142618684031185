import { getItemIndex } from 'components/Pager/getItemIndex';
import { Pager } from 'components/Pager/Pager';
import { generateStudentResultPageTabPath } from 'utils/generateUrlPaths';
import { useParams } from 'react-router-dom';

export function StudentPager({
  calendarItemId,
  currentStudentIndex,
  sessionId,
  setStudentId,
  students,
  tab,
}) {
  const { programId } = useParams();

  return (
    <div className="w-full flex justify-center mt-2 items-center">
      <Pager
        key={`pager-${currentStudentIndex}`}
        currentItem={students[currentStudentIndex]}
        getItemIndex={getItemIndex}
        getNewLocation={(value) =>
          generateStudentResultPageTabPath(
            calendarItemId,
            programId,
            sessionId,
            tab,
            value.id,
          )
        }
        items={students}
        nameAttribute="fullName"
        setItemId={setStudentId}
      />
    </div>
  );
}
