import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

export default function SlideViewerSlideNumber() {
  const { currentSlideIndex, slides } = useContext(SlideViewerContext);

  return (
    <div>
      {currentSlideIndex + 1}/{slides.length}
    </div>
  );
}
