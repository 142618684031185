export const determineCorrectAndTotal = (test) => {
  let allCorrect = 0;
  let totalTests = 0;

  if (test.children) {
    test.children.forEach((child) => {
      const { allCorrect: testCorrect, totalTests: testTotal } =
        determineCorrectAndTotal(child);

      allCorrect += testCorrect;
      totalTests += testTotal;
    });
  } else {
    totalTests += 1;
    if (test.status === 'correct') {
      allCorrect += 1;
    }
  }

  return {
    allCorrect,
    totalTests,
  };
};
