import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { UPSERT_LESSON_GROUP } from 'api/portfolioService/mutations/upsertLessonGroup';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export default function useUpsertLessonGroup() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ input }) =>
      portfolioServiceQueryClient.request(UPSERT_LESSON_GROUP, {
        input,
      }),
    {
      mutationKey: 'upsertLessonGroup',
      onSuccess: () => {
        toast(TOASTTYPES.CREATESUCCESS, {
          str: t('groups.save.lesson.success'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.CREATEERROR, {
          str: t('groups.save.lesson.error'),
        });
      },
    },
  );
}
