import useFormatMessage from '../hooks/useFormatMessage';

export function Skiplink({ destination, focusClass, id }) {
  const t = useFormatMessage();

  return (
    <a
      aria-hidden
      className={`absolute p-2 top-0 bg-cyan-200 right-[200%] ${
        focusClass || 'focus:right-auto'
      }`}
      href={id}
    >
      {t('global.keyboard.skiplink', { destination })}
    </a>
  );
}
