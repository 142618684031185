import { Toggle, TwUiWarningNotification } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { useEffect, useState } from 'react';
import { TeamsAddToCalendarAction } from '../../../components/Form/TeamsAddToCalendarAction';
import { AssignmentTitle } from './TeamsInfo/AssignmentTitle';
import { AssignmentDescription } from './TeamsInfo/AssignmentDescription';
import { TeamsSettingsMessage } from './TeamsInfo/TeamsSettingsMessage';

export function TeamsInfo({ formOption, teamsInfo }) {
  const t = useFormatMessage();
  const { control, errors, setValue, watch } = formOption;
  const { isUpdate, itemIsInThePast } = teamsInfo;
  const assignmentAddToCalendarAction = watch('assignmentAddToCalendarAction');
  const assignmentDescription = watch('assignmentDescription');
  const assignmentTitle = watch('assignmentTitle');
  const chapterItem = watch('chapterItem');
  const sendToTeamsPreference = watch('sendToTeams');
  const [sendToTeams, setSendToTeams] = useState(sendToTeamsPreference ?? true);

  useEffect(() => {
    if (chapterItem?.label) {
      setValue('assignmentTitle', chapterItem.label);
      setValue('resourceDisplayName', chapterItem.label);
    }
  }, [chapterItem, setValue]);

  useEffect(() => {
    setValue('sendToTeams', sendToTeams);
    // only do this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-8 border rounded p-4 relative">
      <div className="bg-white absolute -top-3 px-2 left-2 text-gray-500">
        TEAMS
      </div>

      {itemIsInThePast && (
        <TwUiWarningNotification
          className="my-4"
          content={t('calendar.modal.teams.dateInThePast')}
        />
      )}

      {(!itemIsInThePast || isUpdate) && (
        <div>
          <Toggle
            description={<TeamsSettingsMessage />}
            descriptionClassName="mt-1 text-xs text-gray-500"
            label={t('profile.teams.sendTo')}
            labelClassName="mr-4"
            onChange={(e) => {
              setValue('sendToTeams', e);
              setSendToTeams(e);
            }}
            test="sendToTeams"
            value={sendToTeams}
            visibleLabel
          />

          {sendToTeams && (
            <div>
              <AssignmentTitle
                assignmentTitle={assignmentTitle}
                control={control}
                errors={errors}
                setAssignmentTitle={(e) => {
                  setValue('assignmentTitle', e.target.value);
                }}
              />
              <AssignmentDescription
                assignmentDescription={assignmentDescription}
                control={control}
                setAssignmentDescription={(e) =>
                  setValue('assignmentDescription', e.target.value)
                }
              />
              {!isUpdate && (
                <TeamsAddToCalendarAction
                  assignmentAddToCalendarAction={assignmentAddToCalendarAction}
                  control={control}
                  labelClassName="mr-4"
                  setAssignmentAddToCalendarAction={(e) =>
                    setValue('assignmentAddToCalendarAction', e)
                  }
                  showDescription
                  wrapperClassName="mt-4 flex flex-col"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
