// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export default function EnterSeatsCountDialog({
  addProgramToPeriod,
  isOpen,
  setFetch,
  setIsEnterSeatsDialogOpen,
  setProgramId,
}) {
  const t = useFormatMessage();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const onDismiss = useCallback(() => {
    setIsEnterSeatsDialogOpen(false);
    setProgramId(null);
    reset();
  }, [reset, setIsEnterSeatsDialogOpen, setProgramId]);

  const onSubmit = useCallback(
    (data) => {
      setIsEnterSeatsDialogOpen(false);
      addProgramToPeriod(data.seatCount);
      reset();
      setFetch(true);
    },
    [addProgramToPeriod, reset, setFetch, setIsEnterSeatsDialogOpen],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('add-program-dialog.enter.seat-count')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="enterSeatsCountDialog-seatsCount">
                {t('add-program-dialog.seat-count')}
              </Label>
              <Input
                data-test={dataTestIds.modal.editSeatsFromProgram.input.seats}
                hasErrors={Boolean(errors.seatCount)}
                id="enterSeatsCountDialog-seatsCount"
                type="text"
                {...register('seatCount', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: t('global.required-number'),
                  },
                })}
              />
              {errors.seatCount && (
                <span className="text-red-500 text-xs">
                  {errors.seatCount.message}
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.editSeatsFromProgram.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.editSeatsFromProgram.button.submit}
            type="submit"
          >
            {t('add-program-dialog.add-to-organization')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
