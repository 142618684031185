import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

export default function SlideViewerZoom({ children }) {
  const { fontSizeIndex, setFontSizeIndex } = useContext(SlideViewerContext);

  return children({
    fontSizeIndex,
    min: () => fontSizeIndex > 0 && setFontSizeIndex(fontSizeIndex - 1),
    plus: () => fontSizeIndex < 10 && setFontSizeIndex(fontSizeIndex + 1),
  });
}
