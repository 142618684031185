export const VIEW_MODES = {
  CLASSICAL: 'CLASSICAL',
  PROJECTION: 'PROJECTION',
  SELFSTUDY: 'SELFSTUDY',
};

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  OPEN: 'OPEN',
};
