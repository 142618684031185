import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'providers/UserProvider';

import { uniqBy } from 'lodash-es';
import { SearchBar } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { BadgeSelect } from 'components/BadgeSelect';
import { useLocation } from 'react-router-dom';
import CalendarItemDialog from '../../Calendar/CalendarItemDialog';

import ContentOverview from './partials/ContentOverview';
import { FetchTeacherClassGroups } from './partials/FetchTeacherClassGroups';
import { QuickPlanDialog } from './partials/QuickPlanDialog';
import {
  filterByTags,
  filteredByName,
  getUniqueSortedTags,
} from '../ChapterOverview/helpers';

export default function ChapterItemOverview({ chapterItems, pageTitle }) {
  const t = useFormatMessage();
  const { pathname } = useLocation();

  const [isOpenModalCalendarItem, setIsOpenModalCalendarItem] = useState(false);
  const [isOpenQuickPlanModal, setIsOpenQuickPlanModal] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [classGroups, setClassGroups] = useState();
  const [filteredChapterItems, setFilteredChapterItems] = useState([
    ...chapterItems,
  ]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [filter, setFilter] = useState('');
  const { isTeacher, organization } = useContext(UserContext);

  useEffect(() => {
    let filteredChapterItems = [...chapterItems];

    if (filter) {
      const filteredChapterItemsByName = filteredByName(
        filteredChapterItems,
        filter,
      );
      const filteredChapterItemsByLessonName = filteredChapterItems.filter(
        (chapterItem) => {
          const filteredLessons = chapterItem.lessons?.filter((lesson) =>
            lesson.title.toLowerCase().includes(filter.toLowerCase()),
          );
          return Boolean(filteredLessons.length);
        },
      );

      filteredChapterItems = uniqBy(
        [...filteredChapterItemsByName, ...filteredChapterItemsByLessonName],
        (chapter) => chapter.id,
      );
    }

    if (selectedTags.length > 0) {
      const tags = selectedTags.map((tag) => tag.value);

      filteredChapterItems = filterByTags(filteredChapterItems, tags);
    }

    setFilteredChapterItems(filteredChapterItems);
  }, [chapterItems, filter, selectedTags]);

  const tagOptions = getUniqueSortedTags(chapterItems).filter(
    (tag) => tag !== undefined,
  );

  return (
    <div>
      {isTeacher && (
        <>
          <FetchTeacherClassGroups setClassGroups={setClassGroups} />
          <CalendarItemDialog
            calendarModalOption={{
              isOpenModalCalendarItem,
              setIsOpenModalCalendarItem,
            }}
            classGroups={classGroups}
            getCalendarItems={() => true} // not needed in this context
            itemInfo={itemInfo}
            targetLink={pathname}
            updateItemOption={{ isUpdateMode, setIsUpdateMode }}
          />
          <QuickPlanDialog
            hasPlanButton={organization.showCalendar}
            isOpen={isOpenQuickPlanModal}
            itemInfo={itemInfo}
            onClose={() => {
              setIsOpenQuickPlanModal(false);
              setItemInfo({});
            }}
            openCalendarModal={() => setIsOpenModalCalendarItem(true)}
            setItemInfo={setItemInfo}
          />
        </>
      )}
      <div className="w-full mx-auto">
        <div className="flex gap-2 flex-col md:flex-row">
          <div className="md:w-1/2">
            <SearchBar
              id="filterChapterItemByName"
              onChange={setFilter}
              placeholder={t('chapterItem-overview.search.placeholder')}
              test="filterChapterItemByName"
            />
          </div>

          {Boolean(tagOptions?.length) && (
            <div className="md:w-1/2">
              <BadgeSelect
                id="filterChapterItemByTags"
                options={tagOptions.map((tag) => ({
                  value: tag?.name,
                  label: tag?.name,
                  color: tag?.color,
                }))}
                placeholder={t('chapterItem-overview.select.placeholder')}
                selected={selectedTags}
                setSelected={setSelectedTags}
              />
            </div>
          )}
        </div>

        <div className="mt-4 flex-grow text-center">
          <ContentOverview
            chapterItems={filteredChapterItems}
            itemInfo={itemInfo}
            openCalendarModal={setIsOpenModalCalendarItem}
            openQuickPlanModal={setIsOpenQuickPlanModal}
            ownContent={false}
            pageTitle={pageTitle}
            setItemInfo={setItemInfo}
          />
        </div>
      </div>
    </div>
  );
}
