import { gql } from 'graphql-request';

export const OPEN_LESSON_CONTENT = gql`
  query openLessonContent($studioId: Int!) {
    openLessonContent(studioId: $studioId) {
      studioId
      version
      title
      type
      coach
      editorVersion
    }
  }
`;
