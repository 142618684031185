import { FaChevronDown } from 'react-icons/fa';

import c from '../utils/c';

export default function DropdownIcon({ className, isOpen }) {
  return (
    <FaChevronDown
      className={c(
        className,
        isOpen
          ? 'transform -scale-y-1 transition duration-150'
          : 'transition duration-150',
      )}
    />
  );
}
