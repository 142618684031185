import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { ADMIN_RESET_PASSWORD } from 'api/plannerService/admin/mutations/adminResetPassword';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';

export const useAdminResetPassword = () => {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ id, newPassword }) =>
      plannerServiceQueryClient.request(ADMIN_RESET_PASSWORD, {
        id,
        newPassword,
      }),
    {
      mutationKey: 'adminResetPassword',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.password'),
        });
      },
      onError: () => {
        toast(TOASTTYPES.UPDATEERROR, {
          str: t('global.password'),
        });
      },
    },
  );
};
