import { gql } from 'graphql-request';

export const TEACHER_LESSONS = gql`
  query teacherLessons($filter: [JSON], $page: Int!, $size: Int!, $sort: JSON) {
    teacherLessons(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        calendarItem {
          classGroups {
            id
            name
          }
          closedAfterEndTime
          endTime
          id
          hidden
          sessionId
          startTime
          studioId
          teamsCalendarItem {
            addToCalendarAction
            assignmentDescription
            assignmentTitle
            resourceDisplayName
          }
          viewMode
        }
        chapter {
          id
          name
          programId
          blobUri
        }
        chapterItem {
          blobUri
          id
          name
          studioId
        }
        color
        firstName
        groupRoles {
          id
          role
        }
        lastName
        lessonContentImageUri
        lessonContentTitle
        lessonContentType
        userId
      }
    }
  }
`;
