import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Toggle({
  className = '',
  description = null,
  descriptionClassName = 'mt-2 text-sm text-gray-500',
  label,
  labelClassName = 'font-medium text-gray-900 mr-4',
  onChange,
  test = '',
  value = false,
  visibleLabel = false,
}) {
  return (
    <Switch.Group>
      <div className={classNames('flex items-center', className)}>
        {visibleLabel && (
          <Switch.Label className={labelClassName}>{label}</Switch.Label>
        )}
        <Switch
          checked={value}
          className={classNames(
            value ? 'bg-accent-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-accent-600 focus:ring-offset-2',
          )}
          data-test={test}
          onChange={onChange}
        >
          {!visibleLabel && <span className="sr-only">{label}</span>}
          <span
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                value
                  ? 'opacity-0 duration-100 ease-out'
                  : 'opacity-100 duration-200 ease-in',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
            >
              <svg
                className="h-3 w-3 text-gray-400"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                />
              </svg>
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                value
                  ? 'opacity-100 duration-200 ease-in'
                  : 'opacity-0 duration-100 ease-out',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
            >
              <svg
                className="h-3 w-3 text-accent-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>
      {description && (
        <Switch.Description as="p" className={descriptionClassName}>
          {description}
        </Switch.Description>
      )}
    </Switch.Group>
  );
}
