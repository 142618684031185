import { useEffect } from 'react';

import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';

export function FetchTeacherClassGroups({ setClassGroups }) {
  const { data: classGroupsData } = useTeacherClassGroups({
    page: 0,
    size: 500,
  });

  useEffect(() => {
    if (classGroupsData?.content) setClassGroups(classGroupsData.content);
  }, [classGroupsData, setClassGroups]);

  return null;
}
