import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

// component taken from https://tailwindui.com/components/application-ui/feedback/alerts#component-8a099d76389c1c4d6d4e92d69acbc8d2
export function TwUiWarningNotification({
  className = '',
  content,
  title = null,
}) {
  const t = useFormatMessage();

  return (
    <div className={c(className, 'w-full rounded-md bg-yellow-50 p-4')}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-5 w-5 text-yellow-400"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {title || t('notifications.warning.label')}
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
