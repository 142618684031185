// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  SubjectCard,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

export default function AddChapterItemDialog({
  color,
  isOpen,
  onConfirm: addChapterItem,
  onDismiss: dismiss,
}) {
  const [files, setFiles] = useState([]);
  const [name, setName] = useState('');

  const t = useFormatMessage();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const resetState = useCallback(() => {
    setFiles([]);
    setName('');
    reset();
  }, [reset, setFiles, setName]);

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    accept: 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ErrorCode.FileInvalidType) {
            setError(`Error: ${err.message}`);
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      return acceptedFiles;
    },
  });

  const onDismiss = useCallback(() => {
    dismiss();
    resetState();
  }, [dismiss, resetState]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await addChapterItem(data, files[0] ? acceptedFiles[0] : undefined);
        resetState();
        dismiss(false);
      } catch (e) {
        setError('studioId', {
          message: t('add-chapter-item-dialog.error'),
        });
      }
    },
    [addChapterItem, setError, resetState, dismiss, t, files, acceptedFiles],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>{t('add-chapter-item-dialog.header')}</DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4 ">
            <div className="flex flex-col">
              <Label htmlFor="addChapterItemDialog-name">
                {t('add-chapter-item-dialog.name')}
              </Label>
              <Input
                hasErrors={Boolean(errors.name)}
                id="addChapterItemDialog-name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                {...register('name', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.name && (
                <span className="text-red-500 text-xs">
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="addChapterItemDialog-weight">
                {t('global.weight')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('chapter-item-dialog.weight.description')}
              </p>
              <Input
                defaultValue="0"
                id="addChapterItemDialog-weight"
                min={0}
                type="number"
                {...register('weight', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <Label htmlFor="addChapterItemDialog-document">
                {t('chapter-item-dialog.document')}
              </Label>
              <p className="text-gray-500 text-sm my-1">
                {t('chapter-item-dialog.document.description')}
              </p>
              <Input
                hasErrors={Boolean(errors.document)}
                id="addChapterItemDialog-document"
                type="text"
                {...register('document', {
                  required: {
                    value: false,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.document && (
                <span className="text-red-500 text-xs">
                  {errors.document.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="addChapterItemDialog-studioId">
                {t('add-chapter-item-dialog.lesson-content-id')}
              </Label>
              <div className="">
                <Input
                  className="w-full"
                  hasErrors={Boolean(errors.studioId)}
                  id="addChapterItemDialog-studioId"
                  min="0"
                  type="number"
                  {...register('studioId', {
                    required: {
                      value: true,
                      message: t('global.required-field'),
                    },
                  })}
                />
              </div>
              {errors.studioId && (
                <span className="text-red-500 text-xs">
                  {errors.studioId.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('global.image.add')}</Label>
              {files[0] ? (
                <div
                  className="grid grid-cols-2 w-full p-2 justify-center items-center rounded cursor-pointer border border-gray-300"
                  onClick={() => {
                    setFiles([]);
                  }}
                >
                  <div className="p-2 flex flex-row justify-start items-center">
                    <img
                      alt=""
                      className="h-7 w-7 mr-2"
                      src={files[0]?.preview}
                    />
                    {files[0]?.name}
                  </div>
                  <div className="p-2 flex flex-row justify-end items-center">
                    <ActionStyledAsButton danger variant="text">
                      <XCircleIcon className="h-3 w-3 mr-2" />
                      {t('global.image.delete')}
                    </ActionStyledAsButton>
                  </div>
                </div>
              ) : (
                <div
                  {...getRootProps()}
                  className="flex flex-row gap-x-3 justify-center items-center p-7 rounded border border-gray-300"
                >
                  <Input
                    htmlFor="addChapterItemDialog-file"
                    type="file"
                    {...getInputProps()}
                  />
                  <div className="w-full flex flex-row justify-center items-center">
                    <ArrowUpTrayIcon
                      className="h-3 w-3 mr-2"
                      id="addChapterItemDialog-file"
                    />
                    {t('global.drag_and_drop_or_browse.image')}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('global.example')}</Label>
              <SubjectCard
                color={color}
                image={files[0]?.preview || undefined}
                title={name}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton onClick={onDismiss} variant="text">
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton type="submit">
            {t('global.confirm')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
