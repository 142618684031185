import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { PageHeader } from '@ftrprf/tailwind-components';

export function PageHeaderWrapper({
  avatar = null,
  borderBottom = true,
  breadcrumbs = [],
  button = null,
  buttonOnTitleRow = false,
  children = null,
  hasGradient = true,
  homeInfo = {},
  marginBottom = true,
  marginTop = true,
  subtitle = '',
  title = '',
}) {
  const user = useContext(UserContext);

  return (
    <PageHeader
      avatar={avatar}
      borderBottom={borderBottom}
      breadcrumbs={breadcrumbs}
      button={button}
      buttonOnTitleRow={buttonOnTitleRow}
      hasGradient={hasGradient}
      homeInfo={homeInfo}
      marginBottom={marginBottom}
      marginTop={marginTop}
      subtitle={subtitle}
      title={title}
      user={user}
    >
      {children}
    </PageHeader>
  );
}
