import { useContext } from 'react';
import Header from 'components/Header/Header';
import { UserContext } from 'providers/UserProvider';
import c from 'utils/c';
import 'prismjs/plugins/toolbar/prism-toolbar.min';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min';
import 'prismjs/plugins/diff-highlight/prism-diff-highlight.min';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';
import 'prismjs/plugins/line-highlight/prism-line-highlight.min';
import 'prismjs/plugins/match-braces/prism-match-braces.min';

export default function SlideviewerLayout({ children }) {
  const user = useContext(UserContext);
  const { settings } = user;

  return (
    <div
      className={c(
        'w-full h-screen',
        settings?.slideViewer?.showLineNumbers && 'line-numbers',
        settings?.slideViewer?.showMatchingBraces && 'match-braces',
        settings?.slideViewer?.showRainbowBrackets && 'rainbow-braces',
        settings?.slideViewer?.codeBlocksTheme,
      )}
    >
      <main className="h-full flex flex-col">
        <Header />
        {children}
      </main>
    </div>
  );
}
