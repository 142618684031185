export default {
  'styled-slide-viewer.open_the_sidebar': 'Open the sidebar',

  'styled-slide-viewer.sidebar.questions_not_saved':
    'Your submitted answers will not be saved',

  'styled-slide-viewer.previous': 'Previous',
  'styled-slide-viewer.next': 'Next',

  'styled-slide-viewer.teacher_manual': 'Teacher manual',
  'styled-slide-viewer.manual': 'Manual',
  'styled-slide-viewer.teacher_manual_empty':
    'No additional explanation is needed with this slide',

  'styled-slide-viewer.table_of_contents': 'Table of contents',

  'styled-slide-viewer.steams': 'Steams',
  'styled-slide-viewer.settings': 'Settings',
  'styled-slide-viewer.settings.feedback': 'Feedback',
  'styled-slide-viewer.settings.feedback_explanation':
    'Give feedback on this slide',

  'styled-slide-viewer.settings.zoom': 'Zoom',
  'styled-slide-viewer.settings.zoom_explanation':
    'Use + and - to zoom in and out the slides.',

  'styled-slide-viewer.userGroupInfo': 'Group info',

  'styled-slide-viewer.toggle.sidebar': 'Toggle sidebar',

  'styled-slide-viewer.view-modes.projection': 'Projection',
  'styled-slide-viewer.view-modes.selfstudy': 'Self study',
  'styled-slide-viewer.view-modes.classical': 'Classical',

  'styled-slide-viewer.submit': 'Submit',
  'styled-slide-viewer.submitted': 'Submitted',
  'styled-slide-viewer.save': 'Save',
  'styled-slide-viewer.saved': 'Saved',
  'styled-slide-viewer.close': 'Close',
  'styled-slide-viewer.no_slides': 'There are no slides in this lesson.',
  'styled-slide-viewer.not_available':
    "This slide is either not available for this projection mode, or it doesn't exist.",
  'styled-slide-viewer.not_available_editor':
    "This slide doesn't exist for this lesson.",
  'styled-slide-viewer.empty_title': 'no title',
  'styled-slide-viewer.submit_answers': 'Submit answers',
  'styled-slide-viewer.answer_placeholder': 'Enter your answer',
  'student-answers.correct_answer': 'The correct answer is:',
  'student-answers.no_answer': 'Answer not provided',

  'styled-slide-viewer.example_answer': 'Example answer',
  'styled-slide-viewer.hints': 'Hints',
  'styled-slide-viewer.hint': 'Hint',
  'styled-slide-viewer.hint-dialog.title': 'Hint',

  'styled-slide-viewer.questions.not_supported_type':
    'The question could not be displayed: the type of the question is not supported.',
  'styled-slide-viewer.multiplechoiceanswercard.explanation_less': 'Show less',
  'styled-slide-viewer.multiplechoiceanswercard.explanation_more': 'Show more',
  'styled-slide-viewer.feedback.title': "We'd love to hear your feedback",
  'styled-slide-viewer.feedback.target':
    'Which kind of content is your feedback about?',
  'styled-slide-viewer.feedback.target.error': 'Please choose a valid value',
  'styled-slide-viewer.feedback.target.placeholder': 'Please choose a type',
  'styled-slide-viewer.feedback.feedback': "What's your feedback?",
  'styled-slide-viewer.feedback.notify': 'Keep me posted about my feedback',
  'styled-slide-viewer.feedback.file.label':
    'Do you want to add a file to clarify your feedback?',
  'styled-slide-viewer.feedback.file.drag':
    'Drag a file, or look in your files',
  'styled-slide-viewer.feedback.type.SLIDE': 'Slide',
  'styled-slide-viewer.feedback.type.TEACHER_MANUAL': 'Teacher manual',
  'styled-slide-viewer.feedback.type.EXERCISE': 'Exercise',
  'styled-slide-viewer.feedback.type.WORKING_SHEET': 'Worksheet',
  'styled-slide-viewer.feedback.type.LESSON_DOCUMENT': 'Lesson document',
  'styled-slide-viewer.feedback.email': 'Email',
  'styled-slide-viewer.feedback.email.validate-error': 'Invalid email format',
  'styled-slide-viewer.feedback.email.required':
    'Email is required as you want to stay posted',
  'styled-slide-viewer.confirmation.title':
    "Don't forget to submit your answers!",
  'styled-slide-viewer.confirmation.content':
    "If you don't submit your answer, you can't get a grade for this question.",
  'styled-slide-viewer.confirmation.confirm': 'Submit and continue',
  'styled-slide-viewer.confirmation.cancel': 'Continue without submitting',

  // question disclosure
  'question.show.answers': 'Show answers',
  'question.hide.answers': 'Hide answers',
};
