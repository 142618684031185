/* eslint-disable react/jsx-no-useless-fragment */
/* added a fragment at the bottom, so pb-4 shows up */
import { flatten } from 'lodash-es';

import { DisclosureChevron } from '@ftrprf/tailwind-components';

import { HackRoomChapterItem } from './HackRoomChapterItem';

export function HackRoomProgramItem({ filter, program }) {
  return (
    <li>
      <DisclosureChevron
        className="flex items-center hover:cursor-pointer rounded-md hover:bg-gray-200 p-4"
        classNameContainer="bg-gray-100 rounded-md"
        isOpen
        title={
          <h2 className="text-2xl font-bold leading-none">{program.name}</h2>
        }
      >
        <div className="px-4 pt-1 pb-4">
          <ul className="w-full grid gap-6 grid-cols-1">
            {program.chapters
              ?.filter((chapter) =>
                [...flatten(chapter.items.map((lesson) => lesson.name))].some(
                  (item) => item.toLowerCase().includes(filter),
                ),
              )
              .map((chapter) => (
                <HackRoomChapterItem
                  key={chapter.id}
                  chapter={chapter}
                  filter={filter}
                />
              ))}
          </ul>
        </div>
        <></>
      </DisclosureChevron>
    </li>
  );
}
