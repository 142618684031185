import c from 'utils/c';

export function ClassGroupFilterButton({
  children,
  className,
  isSelected,
  onClick,
  ...props
}) {
  return (
    <li
      className={c(
        'rounded-xl p-1 px-2 whitespace-nowrap',
        className,
        isSelected ? 'bg-accent-100 text-accent-600' : 'bg-gray-200',
      )}
      {...props}
    >
      <button onClick={onClick} type="button">
        {children}
      </button>
    </li>
  );
}
