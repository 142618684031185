import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

import c from '../../../utils/c';

export function SummaryPlusMinus({ className, open, openSummary, title }) {
  return (
    <summary
      className={c(
        'rounded-md list-none hover:outline hover:outline-blue-500 hover:outline-2 focus-within:outline focus-within:outline-blue-500 focus-within:outline-2 focus-within:outline-offset-2',
        className,
      )}
      onClick={openSummary}
    >
      {/*
        note: this can't be a div because a summary only accepts phrasing content (and h-elements), not flow content
        note2: we don't use a h-element because most screen-readers won't announce it as such
      */}
      <span className="flex justify-between items-center w-full">
        {title}
        {open ? (
          <MinusIcon aria-hidden="true" className="h-6 w-6 mr-2 ml-auto" />
        ) : (
          <PlusIcon aria-hidden="true" className="h-6 w-6 mr-2 ml-auto" />
        )}
      </span>
    </summary>
  );
}
