import { generatePath, useNavigate } from 'react-router-dom';
import { EyeIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  Actions,
  ActionStyledAsButton,
  Content,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import URLS from 'utils/constants/urls';
import determineRoles from 'utils/determineUserRoles';
import titleCase from 'utils/titleCase';
import { cloneDeep } from 'lodash-es';
import { useContext } from 'react';
import UserProfile from './UserProfile';
import { PeriodsContext } from '../../providers/PeriodsProvider';
import { generateRouteToClassDetails } from './partials/helper';

export default function UserProfileSchooladmin({
  canDelete,
  canResetPassword,
  formattedSearchParams,
  hasLinkedClass,
  hasLinkedProgram,
  isSaving,
  openAddClassgroupsModal,
  openLinkProgramsModal,
  openRemoveModal,
  openResetModal,
  removeClassgroup,
  unlinkProgramsModal,
  updateUser,
  user,
  userClassGroups,
  userPrograms,
  users,
}) {
  const t = useFormatMessage();
  const navigate = useNavigate();
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);

  const routeToClassDetails = (classGroup) => {
    navigate(generateRouteToClassDetails(classGroup));
  };

  const routeToProgramDetails = ({ id: programId }) =>
    generatePath(URLS.MANAGE_PROGRAM_DETAILS, {
      programId,
    });

  const actionsClassGroup = (classGroup) => [
    {
      icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
      label: `${t('global.view')} ${t('global.class')}`,
      href: generateRouteToClassDetails(classGroup),
    },
    {
      icon: <TrashIcon className="h-3 inline-block w-3 mr-2" />,
      label: `${t('global.unlink.classgroup')}`,
      onClick: () => removeClassgroup(classGroup.id),
    },
  ];

  const actionsUserProgram = (userProgram) => [
    {
      icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
      label: `${t('global.view')} ${t('global.program')}`,
      href: routeToProgramDetails(userProgram),
    },
    {
      icon: <TrashIcon className="h-3 w-3 inline-block mr-2" />,
      label: t('global.unlink.program'),
      onClick: () => unlinkProgramsModal(userProgram.id),
    },
  ];

  const userClone = { ...determineRoles(user), ...user };
  const formattedSearchParamsClone = cloneDeep(formattedSearchParams);

  if (formattedSearchParams.enabled) formattedSearchParamsClone.enabled = 1;
  if (!formattedSearchParams.enabled) formattedSearchParamsClone.enabled = 0;

  return (
    <div>
      <UserProfile
        canDelete={canDelete}
        canEdit={!userClone.isSchoolAdmin}
        canResetPassword={canResetPassword}
        isSaving={isSaving}
        openRemoveModal={openRemoveModal}
        openResetModal={openResetModal}
        updateUser={updateUser}
        user={userClone}
        users={users}
      />
      <Content>
        <div className="w-full">
          {!userClone?.roles.some((role) => role.includes('SCHOOLADMIN')) && (
            <div className="my-6">
              <h2 className="text-3xl font-bold leading-none mb-4">
                {t('class-group.linked')}
              </h2>
              <div className="grid xl:grid-cols-2 gap-4">
                {!userClone?.roles.some((role) => role.includes('STUDENT')) && (
                  <div>
                    {selectedPeriodIsActivePeriod && (
                      <div className="text-right">
                        <ActionStyledAsButton
                          className="mb-4 ml-auto"
                          disabled={!hasLinkedProgram}
                          onClick={openLinkProgramsModal}
                          secondary={!hasLinkedProgram}
                        >
                          {hasLinkedProgram ? (
                            <>
                              <PlusIcon className="h-3 w-3 mr-2" />
                              {t('global.link.programs')}
                            </>
                          ) : (
                            t('link-program-dialog.all-programs-linked')
                          )}
                        </ActionStyledAsButton>
                      </div>
                    )}
                    <ResponsiveTable
                      emptyText={titleCase(
                        t('schooladmin.programs.empty', {
                          what: t(`global.${userClone.role}.singular`),
                        }),
                      )}
                      headers={[
                        {
                          key: 'Program',
                          orderable: false,
                          label: t('global.PROGRAM.plural'),
                        },
                        {
                          key: 'actions',
                          label: t('global.actions'),
                          orderable: false,
                          className: 'max-w-fit sr-only',
                        },
                      ]}
                      mobileBreakpoint={768}
                      mobileHeaderWidth={160}
                      onRowClick={routeToProgramDetails}
                    >
                      {userPrograms?.map((userProgram) => (
                        <ResponsiveTableRow
                          key={userProgram.id}
                          data={userProgram}
                        >
                          <ResponsiveTableCol className="clip px-4">
                            {userProgram.name}
                          </ResponsiveTableCol>
                          <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                            <Actions items={actionsUserProgram(userProgram)} />
                          </ResponsiveTableCol>
                        </ResponsiveTableRow>
                      ))}
                    </ResponsiveTable>
                  </div>
                )}

                <div>
                  {selectedPeriodIsActivePeriod && (
                    <div className="text-right">
                      <ActionStyledAsButton
                        className="mb-4 ml-auto"
                        disabled={!hasLinkedClass}
                        onClick={openAddClassgroupsModal}
                        secondary={!hasLinkedClass}
                      >
                        {hasLinkedClass ? (
                          <>
                            <PlusIcon className="h-3 w-3 mr-2" />
                            {t('global.link.classgroups')}
                          </>
                        ) : (
                          t('link-classgroup-dialog.all-classgroups-linked')
                        )}
                      </ActionStyledAsButton>
                    </div>
                  )}
                  <ResponsiveTable
                    emptyText={titleCase(
                      t('schooladmin.classes.empty', {
                        what: t(`global.${userClone.role}.singular`),
                      }),
                    )}
                    headers={[
                      {
                        key: 'Class',
                        orderable: false,
                        label: t('teacher-overview.classes'),
                      },
                      {
                        key: 'actions',
                        label: t('global.actions'),
                        orderable: false,
                        className: 'max-w-fit sr-only',
                      },
                    ]}
                    mobileBreakpoint={768}
                    mobileHeaderWidth={160}
                    onRowClick={routeToClassDetails}
                  >
                    {userClassGroups?.map((userClassGroup) => (
                      <ResponsiveTableRow
                        key={userClassGroup.id}
                        data={userClassGroup}
                      >
                        <ResponsiveTableCol>
                          {userClassGroup.name}
                        </ResponsiveTableCol>
                        <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                          <Actions items={actionsClassGroup(userClassGroup)} />
                        </ResponsiveTableCol>
                      </ResponsiveTableRow>
                    ))}
                  </ResponsiveTable>
                </div>
              </div>
            </div>
          )}
        </div>
      </Content>
    </div>
  );
}
