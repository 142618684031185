export const languageKeys = {
  DE: 'DE',
  EN: 'EN',
  ES: 'ES',
  FR: 'FR',
  NL: 'NL',
};

export const languages = {
  [languageKeys.DE]: 'Deutsch',
  [languageKeys.EN]: 'English',
  [languageKeys.ES]: 'Español',
  [languageKeys.FR]: 'Français',
  [languageKeys.NL]: 'Nederlands',
};

export const languagesForDropdown = Object.entries(languages).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

export const DEFAULT_LANGUAGE_KEY = languageKeys.NL;
