import { gql } from 'graphql-request';

export const SCHOOLADMIN_USER_BY_ID = gql`
  query schooladminUserById($id: String!) {
    schooladminUserById(id: $id) {
      id
      avatar
      displayName
      email
      enabled
      endDate
      firstName
      idp
      lastName
      roles
      userName
    }
  }
`;
