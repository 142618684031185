import { gql } from 'graphql-request';

export const ADMIN_DELETE_USER = gql`
  mutation adminDeleteUser($id: String!) {
    adminDeleteUser(id: $id) {
      statusCode
      id
      message
    }
  }
`;
