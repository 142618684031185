import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  getSelectedPeriodIdFromLocalStorage,
  setSelectedPeriodIdToLocalStorage,
} from 'utils/selectedPeriod';
import { UserContext } from './UserProvider';
import { getCurrentPeriod } from '../utils/getCurrentPeriod';

export const PeriodsContext = createContext({});

export function PeriodsProvider({ children }) {
  const { organization } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [selectedPeriod, setSelectedPeriodState] = useState();
  const [activePeriod, setActivePeriod] = useState();
  const [selectedPeriodIsActivePeriod, setSelectedPeriodIsActivePeriod] =
    useState(true);

  const setSelectedPeriod = useCallback(
    (period, refetch = true) => {
      setSelectedPeriodIdToLocalStorage(period?.id);
      setSelectedPeriodState(period);
      if (refetch) {
        void queryClient.invalidateQueries();
      }
    },
    [queryClient],
  );

  useEffect(() => {
    if (organization?.periods?.length && selectedPeriod === undefined) {
      const defaultPeriod = organization.periods.find(
        (period) => period.id === organization.selectedPeriodId,
      );
      const localPeriodId = getSelectedPeriodIdFromLocalStorage();
      const localPeriod = organization.periods.find(
        (period) => period.id === localPeriodId,
      );

      // if no local or default period was found, use the last one to avoid the app crashing
      setSelectedPeriod(
        localPeriod || defaultPeriod || organization.periods[0],
        false,
      );
    }
  }, [selectedPeriod, organization, setSelectedPeriod]);

  useEffect(() => {
    if (organization?.periods?.length) {
      setActivePeriod(getCurrentPeriod(organization?.periods));
    }
  }, [organization?.periods, setActivePeriod]);

  useEffect(() => {
    if (selectedPeriod && activePeriod) {
      setSelectedPeriodIsActivePeriod(selectedPeriod?.id === activePeriod?.id);
    }
  }, [activePeriod, activePeriod?.id, selectedPeriod, selectedPeriod?.id]);

  const hasActivePeriod = useMemo(() => {
    if (organization && organization.periods && organization.periods.length) {
      return Boolean(activePeriod);
    }

    /*
     organization can take a while to load
     => so put it on true for as long as the organization hasn't been initialized
     => in order to prevent unnecessary rerenders,
        and in the header it prevents an infobar from popping in and out of existence
     */
    return true;
  }, [activePeriod, organization]);

  const context = useMemo(
    () => ({
      activePeriod,
      hasActivePeriod,
      periods: organization?.periods,
      selectedPeriod,
      selectedPeriodIsActivePeriod,
      selectedPeriodIsNotActivePeriod: !selectedPeriodIsActivePeriod,
      setSelectedPeriod,
    }),
    [
      activePeriod,
      hasActivePeriod,
      organization?.periods,
      selectedPeriod,
      selectedPeriodIsActivePeriod,
      setSelectedPeriod,
    ],
  );

  return (
    <PeriodsContext.Provider value={context}>
      {children}
    </PeriodsContext.Provider>
  );
}
