import { gql } from 'graphql-request';

export const SCHOOLADMIN_ARCHIVE_CLASS_GROUP = gql`
  mutation schooladminArchiveClassGroup($id: Int!) {
    schooladminArchiveClassGroup(id: $id) {
      statusCode
      id
      message
    }
  }
`;
