import { useEffect, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import URLS from '../../utils/constants/urls';

export const HELPTYPES = {
  CATEGORY: 'category',
  VIDEO: 'video',
};

/**
 * Due to prismic changes breaking our build, without an easy fix, we moved
 * the help page to a separate project.
 */
export function HelpRedirect({ type }) {
  const params = useParams();
  const linkRef = useRef(null);
  let url = process.env.REACT_APP_HELP_URL;

  if (type === HELPTYPES.CATEGORY) {
    const { category } = params;
    url = `${process.env.REACT_APP_HELP_URL}/tag/${category}`;
  }

  if (type === HELPTYPES.VIDEO) {
    const { videoSlug } = params;
    url = `${process.env.REACT_APP_HELP_URL}/${videoSlug}`;
  }

  useEffect(() => {
    linkRef.current.click();
  }, [type, url]);

  return (
    <div>
      <a ref={linkRef} href={url} rel="noopener noreferrer" target="_blank">
        Help
      </a>
      <Navigate to={URLS.ROOT} />
    </div>
  );
}
