import { Suspense, useState } from 'react';

import useLessonExercise from 'hooks/api/slidesService/useLessonExercise';
import useParams from 'hooks/useParams';

import { ReactComponent as Loader } from 'assets/vectors/logo-animated-lines.svg';

import { CodiIntroDialog } from '../partials/CodiIntroDialog';
import ScratchError from '../partials/ScratchError';
import Exercise from './Exercise';

export function ExerciseContainer() {
  const { exerciseVersionId, studioId } = useParams();

  const [restart, setRestart] = useState(false);

  const {
    data: exercise,
    isError,
    isFetching,
    refetch,
  } = useLessonExercise({
    studioId,
    exerciseVersionId,
    restart,
  });

  if (isError) {
    return <ScratchError />;
  }
  if (isFetching) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Loader className="w-32" />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader className="w-32" />
        </div>
      }
    >
      <Exercise
        exercise={exercise}
        isRestarting={isFetching}
        restart={() => {
          setRestart(true);
          refetch();
        }}
      />
      <CodiIntroDialog hasCodi={Boolean(exercise?.versionTestPlanId)} />
    </Suspense>
  );
}
