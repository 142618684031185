import { containsMissingDetailErrors } from './containsMissingDetailErrors';

export function categorizeErrorsByDetail(messages) {
  const missingDetailsErrors = [];
  const otherErrors = [];

  messages.forEach((message) => {
    const { errorCategoryName } = message;

    if (containsMissingDetailErrors(errorCategoryName)) {
      missingDetailsErrors.push(message);
    } else {
      otherErrors.push(message);
    }
  });

  return { missingDetailsErrors, otherErrors };
}
