import { gql } from 'graphql-request';

export const SCHOOLADMIN_UPDATE_CLASS_GROUP = gql`
  mutation schooladminUpdateClassGroup($id: Int!, $name: String!) {
    schooladminUpdateClassGroup(id: $id, name: $name) {
      id
      name
      organizationId
      archived
    }
  }
`;
