import { gql } from 'graphql-request';

export const ADMIN_DEACTIVE_ORGANIZATION = gql`
  mutation adminDeactivateOrganization($id: Int!) {
    adminDeactivateOrganization(id: $id) {
      id
      name
      prefix
    }
  }
`;
