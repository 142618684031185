import { getInitials } from '../../../utils/getInitials';

export function MemberRole({ role }) {
  const fullName = `${role.firstName} ${role.lastName}`;

  return (
    <li>
      <div className="flex items-center my-4">
        <span className="flex-grow-0 bg-gray-400 rounded-full w-10 h-10 flex justify-center items-center text-white mr-2">
          {getInitials(fullName)}
        </span>
        <span className="flex-grow-0 min-w-1/4 mr-2 max-w-1/2">{fullName}</span>
        <span className="flex-grow-0 min-w-1/4 mr-2 max-w-1/2">
          {role?.roleName}
        </span>
      </div>
    </li>
  );
}
