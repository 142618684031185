import { Badge, ContentCard } from '@ftrprf/tailwind-components';

import { EmptyState } from 'components/EmptyState';
import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';

import { ExerciseActions } from './ExerciseActions';

export function ContentCardList({ filteredExercises, isLoading }) {
  const t = useFormatMessage();

  return (
    <ListSkeleton loading={isLoading}>
      <ul className="w-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-6">
        {filteredExercises.length > 0 &&
          filteredExercises.map((exercise) => {
            const { exerciseId, exerciseImage, exerciseTitle } = exercise;

            if (!exerciseTitle) return null; // only historic test data

            return (
              <li key={exerciseId} className="w-full col-span-1 text-center">
                <ContentCard
                  defaultImage={EmptyImage}
                  disabled={exercise.archived} // todo see to add this again
                  image={exerciseImage}
                  title={exerciseTitle}
                  version={
                    exercise.exerciseVersionType && ( // todo see to add this again?
                      <Badge className="px-1 text-xs w-auto inline-block" info>
                        {t(`scratch.version.${exercise.exerciseVersionType}`)}
                      </Badge>
                    )
                  }
                >
                  <ExerciseActions exercise={exercise} />
                </ContentCard>
              </li>
            );
          })}
      </ul>
      {filteredExercises.length === 0 && !isLoading && (
        <EmptyState textKey="global.no_results" />
      )}
    </ListSkeleton>
  );
}
