import { gql } from 'graphql-request';

export const SCHOOLADMIN_ENABLE_USER = gql`
  mutation schooladminEnableUser($id: ID!) {
    schooladminEnableUser(id: $id) {
      id
      enabled
    }
  }
`;
