import { Navigate, generatePath } from 'react-router-dom';
import URLS, { profileTab } from 'utils/constants/urls';

export default function ProfileIndex() {
  return (
    <Navigate
      replace
      to={generatePath(URLS.PROFILE_TAB, {
        tab: profileTab.DEFAULT,
      })}
    />
  );
}
