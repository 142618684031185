import { useContext, useState } from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import { useCalendarItemById } from 'hooks/api/plannerService/shared/useCalendarItemById';
import useCurrentTab from 'hooks/useCurrentTab';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { UserContext } from 'providers/UserProvider';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import URLS, { studentResultTab } from 'utils/constants/urls';
import { generateStudentResultPageTabPath } from 'utils/generateUrlPaths';
import { FetchLessonReport } from './partials/FetchLessonReport';

export function ResultsForStudent() {
  const t = useFormatMessage();
  const {
    calendarItemId,
    programId,
    sessionId: lessonSessionId,
    userId,
  } = useParams();

  const currentTab = useCurrentTab();

  const { displayname } = useContext(UserContext);

  const [data, setData] = useState();
  const [error, setError] = useState();
  const [max, setMax] = useState(0);
  const [denominator, setDenominator] = useState(0);
  const { data: calendarItem } = useCalendarItemById(calendarItemId);
  const chapterItem = calendarItem?.chapterItem;

  const pageTitle = chapterItem
    ? chapterItem?.name
    : calendarItem?.lessonContentTitle || '';

  useTitle(t('content.results'), displayname);

  let chapterItemLink = chapterItem && {
    href: generatePath(URLS.LESSON_RESULTS, {
      programId,
      chapterId: chapterItem?.chapterId,
      chapterItemId: chapterItem?.id,
      lessonId: chapterItem?.lessons[0].id,
    }),
    name: `${pageTitle}`,
  };

  if (!chapterItem && programId === nonNumericProgramIds.OWN) {
    chapterItemLink = {
      href: generatePath(URLS.OWN_LESSONS_OVERVIEW),
      name: t('curricula.lessons.school'),
    };
  }

  const breadcrumbsLinks = [
    chapterItemLink,
    {
      name: displayname,
    },
  ];

  return (
    <GeneralLayout>
      <FetchLessonReport
        activeViewMode={calendarItem?.viewMode}
        lessonSessionId={lessonSessionId}
        setData={setData}
        setDenominator={setDenominator}
        setError={setError}
        setMax={setMax}
        studentId={userId}
      />
      <PageHeaderWrapper
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        button={
          currentTab === studentResultTab.RESPONSES && max ? (
            <strong className="text-white" data-test="studentScore">
              <span className="sr-only">
                {t('results-overview.column.score')}:
              </span>{' '}
              {denominator} / {max}
            </strong>
          ) : null
        }
        buttonOnTitleRow
        marginBottom={false}
        title={pageTitle}
      >
        <TabList>
          <Tab
            label={t('global.RESPONSE.plural')}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.RESPONSES,
              userId,
            )}
          />

          <Tab
            label={t('global.SCRATCH_EXERCISE.plural')}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.SCRATCH_EXERCISES,
              userId,
            )}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet
          context={[
            {
              data,
              error,
              denominator,
              setDenominator,
              max,
              calendarItem,
              userId,
            },
          ]}
        />
      </div>
    </GeneralLayout>
  );
}
