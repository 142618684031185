import useFormatMessage from 'hooks/useFormatMessage';
import {
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import { MiniCopyToClipboardButton } from 'components/Buttons/MiniCopyToClipboardButton';
import { getMissingFieldValues } from '../helpers/getMissingFieldValues';

export function MissingFieldsErrorTable({ importTask, missingDetailsErrors }) {
  const t = useFormatMessage();

  const { classesProgress } = importTask.taskProgress;

  function findClassGroupById(idToCheck) {
    return classesProgress.find((classProgress) => {
      const { classGroupId, id } = classProgress;
      return (
        String(classGroupId) === String(idToCheck) ||
        String(id) === String(idToCheck)
      );
    });
  }

  return (
    <div className="mt-4">
      <h2 className="mt-4 mb-2 text-2xl font-bold">
        {t('errors.category.missing.fields.no.other.errors.title')}
      </h2>
      <p className="mb-4 max-w-[70ch]">
        {t('errors.category.missing.fields.no.other.errors.clarification')}
      </p>
      <ResponsiveTable
        headers={[
          {
            key: 'displayName',
            label: t('global.display_name'),
            orderable: true,
          },
          {
            key: 'email',
            label: t('global.email'),
            orderable: true,
          },

          {
            key: 'classGroupName',
            label: t('global.CLASSGROUP.name'),
            orderable: true,
          },
          {
            key: 'missingFields',
            label: t('global.invalid-values'),
            orderable: true,
          },
        ]}
        mobileBreakpoint={768}
        mobileHeaderWidth={160}
      >
        {missingDetailsErrors.map((detailError, index) => {
          const { missingFields } = getMissingFieldValues(detailError, t);

          const keyValueUserObject = detailError?.context.find(
            (item) => item.key === 'user',
          );

          const userObject = JSON.parse(keyValueUserObject?.value);
          const classGroupIds = userObject?.memberOfClasses;
          const { displayName, email, externalId, userName } = userObject;
          const hasNoEmail = email === 'null' || !email;
          const hasAnEmail = !hasNoEmail;
          const hasTeamsEmail = userName.includes('@');
          const hasNoTeamsEmail = !hasTeamsEmail;

          return (
            <ResponsiveTableRow
              key={`detailError-${detailError.userId}-${index + 1}`}
            >
              <ResponsiveTableCol>
                {displayName || t('global.unknown')}

                <span className="text-xs text-gray-600 lock flex items-center">
                  {externalId || t('global.unknown')}

                  <MiniCopyToClipboardButton text={externalId} />
                </span>
              </ResponsiveTableCol>
              <ResponsiveTableCol extendClassNames="align-top">
                {hasAnEmail && email}
                {hasNoEmail && hasTeamsEmail && userName}
                {hasNoEmail && hasNoTeamsEmail && t('global.unknown')}
              </ResponsiveTableCol>
              <ResponsiveTableCol extendClassNames="align-top">
                {classGroupIds?.map((classGroupId, index) => (
                  <span
                    key={`classGroupId-${classGroupId}-${index + 1}`}
                    className="block"
                  >
                    {findClassGroupById(classGroupId)?.name ||
                      t('global.unknown')}
                    <MiniCopyToClipboardButton text={classGroupId} />
                  </span>
                )) || t('global.unknown')}
              </ResponsiveTableCol>
              <ResponsiveTableCol extendClassNames="align-top">
                {missingFields}
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          );
        })}
      </ResponsiveTable>
    </div>
  );
}
