import { ChevronDownIcon } from '@heroicons/react/24/outline';

import c from '../../../utils/c';

export function SummaryChevron({ className, open, openSummary, title }) {
  return (
    <summary
      className={c(
        'rounded-md list-none hover:outline hover:outline-blue-500 hover:outline-2 focus-within:outline focus-within:outline-blue-500 focus-within:outline-2 focus-within:outline-offset-2',
        className,
      )}
      onClick={openSummary}
    >
      {/* note: this can't be a div because a summary only accepts phrasing content (and h-elements), not flow content */}
      <span className="flex justify-between items-center w-full">
        {title}
        <ChevronDownIcon
          aria-hidden="true"
          className={c(
            open ? '-rotate-180' : 'rotate-0',
            'h-6 w-6 transform mr-2 ml-auto',
          )}
        />
      </span>
    </summary>
  );
}
