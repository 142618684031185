import { gql } from 'graphql-request';

export const TEACHER_CREATE_ACTIVITY = gql`
  mutation teacherCreateActivity($calendarItemId: Int!) {
    teacherCreateActivity(calendarItemId: $calendarItemId) {
      statusCode
      id
      message
    }
  }
`;
