import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dropdown, Label } from '@ftrprf/tailwind-components';

import Divider from 'components/Divider';

import { UserContext } from 'providers/UserProvider';

import useUpdateOwnUserUiSettings from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserUiSettings';
import useFormatMessage from 'hooks/useFormatMessage';

import { viewModesWithTranslations } from 'utils/constants/viewModes';

export default function SlideViewer() {
  const { refetchSettings, settings } = useContext(UserContext);
  const t = useFormatMessage();
  const { mutateAsync: updateUiSettings } = useUpdateOwnUserUiSettings();
  const [viewMode, setViewMode] = useState(
    Object.values(viewModesWithTranslations).find(
      (viewMode) => viewMode.value === settings.slideViewer?.viewMode,
    ) || viewModesWithTranslations.PROJECTION,
  );
  const { control } = useForm();

  const findViewMode = (label) =>
    Object.values(viewModesWithTranslations).find(
      (viewMode) => t(viewMode.label) === label,
    );

  return (
    <div>
      <Divider text={t('profile.slideviewer')} />

      <div className="mt-4 flex flex-col">
        <Label className="sr-only" htmlFor="chooseViewMode">
          {t('profile.slideviewer.viewMode')}
        </Label>
        <Controller
          control={control}
          defaultValue={t(viewMode.label)}
          name="type"
          render={() => (
            <Dropdown
              className="w-full text-black"
              inputId="chooseViewMode"
              isMulti={false}
              onChange={(label) => {
                const viewMode = findViewMode(label);
                const { value } = viewMode;

                setViewMode(viewMode);
                updateUiSettings({
                  uiSettings: {
                    ...settings,
                    slideViewer: { viewMode: value },
                  },
                }).then(() => {
                  refetchSettings();
                });
              }}
              options={Object.values(viewModesWithTranslations).map((value) =>
                t(value.label),
              )}
              value={t(viewMode.label)}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('global.required-field'),
            },
          }}
          type="select"
        />
      </div>
    </div>
  );
}
