import {
  ActionStyledAsButton,
  Filter,
  SearchBar,
  Toggle,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { userRolesForAdminDropdown } from 'utils/constants/ftrprfUserRoles';
import { dataTestIds } from 'utils/dataTestIds';
import { PlusIcon } from '@heroicons/react/24/solid';

export function FiltersAndGlobalActions({
  displayRoleFn,
  isLoadingUsers,
  selectedRolesState,
  setFullnameFilter,
  setIsCreateUserModalOpen,
  setSelectedRoles,
  setShowDisabled,
  showDisabled,
}) {
  const t = useFormatMessage();

  return (
    <div className="w-full flex justify-between">
      <div className="flex flex-grow gap-2">
        <div className="justify-between w-1/2">
          <SearchBar
            className="mb-4"
            id="watzoekje"
            onChange={(value) => setFullnameFilter(value)}
            placeholder={t('hack-room.what_are_you_looking_for')}
            test={dataTestIds.page.organisationProfile.input.name}
          />
        </div>
        <Filter
          activeFilters={selectedRolesState}
          allowNoneSelected
          displayOptionFn={displayRoleFn}
          name={t('global.role')}
          options={userRolesForAdminDropdown}
          setFilter={(value) => {
            setSelectedRoles(value);
          }}
          test={dataTestIds.page.organisationProfile.dropdown.role}
        />
        <div className="md:px-8 flex items-center">
          <Toggle
            className="mb-4"
            label={t('global.enabled.false')}
            onChange={setShowDisabled}
            test={dataTestIds.page.manage.toggle.showDisabled}
            value={showDisabled}
            visibleLabel
          />
        </div>
      </div>

      {!isLoadingUsers && (
        <ActionStyledAsButton
          className="mb-4"
          iconBefore={PlusIcon}
          onClick={() => setIsCreateUserModalOpen(true)}
          test={dataTestIds.page.organisationProfile.button.add.schooladmin}
        >
          {t('schooladmin-overview.new-schooladmin')}
        </ActionStyledAsButton>
      )}
    </div>
  );
}
