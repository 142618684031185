import { gql } from 'graphql-request';

export const STUDENT_ORGANIZATION_CONTENT = gql`
  query studentOrganizationContent {
    studentOrganizationContent {
      lessonContentId
      lessonContentType
      lessonContentImageUri
      lessonContentTitle
      studioId
      organizationId
      calendarItemId
      calendarItemViewMode
      calendarItemSessionId
    }
  }
`;
