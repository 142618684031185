import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

export default function SlideViewerParentVisit({ children, className }) {
  const { setSlide, slides } = useContext(SlideViewerContext);

  const parentSlideIndex = slides.findIndex(
    (slide) => slide.module === 'PARENT_VISIT',
  );

  if (parentSlideIndex < 0) {
    return null;
  }

  return (
    <button
      className={className}
      onClick={() => setSlide(parentSlideIndex)}
      type="submit"
    >
      {children}
    </button>
  );
}
