import { useQuery } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { OPEN_LESSON_SLIDES } from 'api/slideViewerService/queries/openLessonSlides';

import { slideViewerServiceQueryClient } from 'utils/graphqlQueryClients';
import { OPEN_LESSON_SLIDES_WITH_CORRECT } from '../../../api/slideViewerService/queries/openLessonSlidesWithCorrect';

const toastId = 'openLessonSlides';

export default function useLessonSlides({
  lessonSessionId,
  selectedPeriodIsNotActivePeriod = false,
  studioId,
  viewMode,
}) {
  const toast = useToast();
  const t = useFormatMessage();
  const query = selectedPeriodIsNotActivePeriod
    ? OPEN_LESSON_SLIDES_WITH_CORRECT
    : OPEN_LESSON_SLIDES;

  const info = useQuery(
    ['openLessonSlides', studioId, viewMode, lessonSessionId],
    () =>
      slideViewerServiceQueryClient.request(query, {
        studioId,
        viewMode,
        lessonSessionId,
      }),
    {
      onError: async (data) => {
        // create semi-random number
        const id = Number.isNaN(data?.openLessonSlides?.id)
          ? Math.floor(Math.random() * 1000000)
          : data.openLessonSlides.id;
        toast(TOASTTYPES.GETERROR, {
          toastId: toastId + id,
          str: t('global.SLIDE.plural'),
        });
      },
    },
  );

  return { ...info, data: info?.data?.openLessonSlides };
}
