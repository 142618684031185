import { useQuery } from 'react-query';
import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';
import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_FIND_TASK_INFO } from 'api/plannerService/schooladmin/queries/schooladminFindTaskInfo';

export default function useSchooladminFindTaskInfo({
  pageSize,
  requestContinuation,
  taskType,
}) {
  const toast = useToast();
  const t = useFormatMessage();

  const response = useQuery(
    ['schooladminFindTaskInfo', taskType],
    () =>
      plannerServiceQueryClient.request(SCHOOLADMIN_FIND_TASK_INFO, {
        pageSize,
        requestContinuation,
        taskType,
      }),
    {
      onError: () => {
        toast(TOASTTYPES.GETERROR, {
          str: t('import.task'),
        });
      },
    },
  );

  return {
    ...response,
    data: response?.data?.schooladminFindTaskInfo,
  };
}
