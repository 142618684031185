import { Dropdown } from '@ftrprf/tailwind-components';
import { useOutletContext } from 'react-router-dom';
import useFormatMessage from 'hooks/useFormatMessage';
import { useMemo } from 'react';
import { NoResults } from './NoResults';
import { Questions } from './Questions/Questions';
import { Loader } from './Loader';

export default function ResponsesTeacher() {
  const t = useFormatMessage();

  const [
    {
      activeViewMode,
      availableViewModes,
      calendarItem,
      data,
      denominator,
      error,
      max,
      setActiveViewMode,
      setDenominator,
      studentId,
    },
  ] = useOutletContext();

  const viewmodeOptions = useMemo(
    () =>
      availableViewModes.map((viewMode) => ({
        key: viewMode.toLowerCase(),
        label: t(`view-modes.${viewMode.toLowerCase()}`),
        value: viewMode,
      })),
    [availableViewModes, t],
  );

  return (
    <>
      {error && error?.message.includes('NotFoundException') ? (
        <NoResults textKey="results_overview.calendar_item.no_results" />
      ) : null}
      {data && data.userId === studentId ? (
        <>
          {calendarItem && Boolean(availableViewModes.length > 1) && (
            <div>
              <Dropdown
                aria-label={t('global.viewMode')}
                className="float-right"
                inputId="viewModePicker"
                isMulti={false}
                onChange={(e) => {
                  setActiveViewMode(e);
                }}
                options={viewmodeOptions}
                value={activeViewMode}
              />
            </div>
          )}
          <Questions
            earned={denominator}
            max={max}
            questions={data?.questionScores?.questions.filter(
              (question) => question.viewModes.indexOf(activeViewMode) !== -1,
            )}
            setDenominator={setDenominator}
          />
        </>
      ) : (
        !error?.message && <Loader />
      )}
    </>
  );
}
