import useFormatMessage from 'hooks/useFormatMessage';
import { ViewModePicker } from 'components/Form/ViewModePicker';
import { Filter, SearchBar } from '@ftrprf/tailwind-components';
import { ScoreModePicker } from './ScoreModePicker';

export function ChapterItemReportFilters({
  activeScoreMode,
  activeViewMode,
  classGroups = [],
  filterClassGroup,
  filterStudents,
  setActiveScoreMode,
  setActiveViewMode,
  setFilterClassGroup,
  setFilterStudents,
}) {
  const t = useFormatMessage();

  // todo: maak filter op klasgroep met kleurkes

  return (
    <div className="flex items-center">
      <div className="my-4 mr-5">
        <SearchBar
          data-test="nameFilter"
          defaultValue={filterStudents}
          onChange={(value) => setFilterStudents(value, 'fullname')}
          placeholder={t('global.search.by.name')}
        />
      </div>
      <div className="my-4 mr-5">
        <Filter
          activeFilters={
            filterClassGroup?.map((id) => Number(id)) || classGroups
          }
          allowNoneSelected
          displayOptionFn={(classGroupId) =>
            classGroups.find((classGroup) => classGroup.id === classGroupId)
              .name
          }
          name={t('global.class')}
          options={classGroups?.map((classGroup) => classGroup.id)}
          placeholderForFilter={t('global.search.by.name')}
          searchFilterFunction={(searchString) =>
            classGroups
              ?.filter((classGroup) => classGroup.name.includes(searchString))
              ?.map((classGroup) => classGroup.id)
          }
          setFilter={setFilterClassGroup}
          test="classFilter"
          withSearch
        />
      </div>
      <div className="mt-4 mb-2 mr-5 min-w-fit">
        <ScoreModePicker
          activeScoreMode={activeScoreMode}
          setActiveScoreMode={setActiveScoreMode}
        />
      </div>
      <div className="mt-4 mb-2 mr-5">
        <ViewModePicker
          activeViewMode={activeViewMode}
          setActiveViewMode={setActiveViewMode}
        />
      </div>
    </div>
  );
}
