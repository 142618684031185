export function shouldReturnNullForBodyProperties(key, props) {
  const clothingValuesWithoutGraphic = ['dress', 'naked'];

  // noinspection RedundantIfStatementJS
  if (
    key === 'graphic' &&
    clothingValuesWithoutGraphic.includes(props.clothing)
  ) {
    return true;
  }

  return false;
}
