import useAdminOrganizations from 'hooks/api/plannerService/admin/queries/useAdminOrganizations';
import useFormatMessage from 'hooks/useFormatMessage';
import { useRef, useState } from 'react';
import { filterOperation } from 'utils/constants/filter';
import debounce from 'lodash-es/debounce';
import useAdminMoveLesson from 'hooks/api/plannerService/admin/mutations/useAdminMoveLesson';
import { TOOLS_TYPES } from 'utils/constants/toolsTypes';
import MoveLessonForm from './MoveCopyLessonForm';

export default function MoveCopyLesson({ method }) {
  const t = useFormatMessage();

  const [searchText, setSearchText] = useState('');
  const { mutateAsync: moveLesson } = useAdminMoveLesson();

  const onSubmit = (data) => {
    if (method === TOOLS_TYPES.MOVE) {
      return moveLesson({
        studioId: Number(data.studioId),
        toOrganizationId: data.organizationId.value,
      });
    }
    if (method === TOOLS_TYPES.COPY) {
      // To be implemented when Timo finally finishes his ticket
    }

    return null;
  };

  const handleSearchDebounced = useRef(
    debounce((searchText) => setSearchText(searchText), 300),
  ).current;

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      handleSearchDebounced(inputText);
    }
  };

  const { data: organizationsData, isLoading } = useAdminOrganizations({
    filter: [
      {
        key: 'name',
        value: searchText,
        operation: filterOperation.LIKE,
      },
    ],
    page: 0,
    size: 10,
  });

  const dropdownOptions = organizationsData
    ? organizationsData.content.map((organisation) => ({
        value: organisation.id,
        label: organisation.name,
      }))
    : [];

  return (
    <div className="lg:max-w-1/2">
      <h2 className="font-display text-xl tracking-tight text-slate-900">
        {t(`global.LESSON.singular`)}{' '}
        {t(`move-lesson.method.${method}`).toLowerCase()}
      </h2>
      <p className="mt-4 mb-4 tracking-tight text-slate-600">
        {t(`tools-${method}-lesson.description`)}
      </p>
      <MoveLessonForm
        dropdownOptions={dropdownOptions}
        handleInputChange={handleInputChange}
        isLoading={isLoading}
        method={method}
        onSubmit={onSubmit}
      />
    </div>
  );
}
