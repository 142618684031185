import { gql } from 'graphql-request';

export const SCHOOLADMIN_FIND_TASK_INFO = gql`
  query schooladminFindTaskInfo(
    $taskType: TaskType!
    $pageSize: Int!
    $requestContinuation: String
  ) {
    schooladminFindTaskInfo(
      taskType: $taskType
      pageSize: $pageSize
      requestContinuation: $requestContinuation
    ) {
      total
      pages
      requestContinuation
      content {
        taskId
        status
        messages {
          errorCategoryName
          context {
            key
            value
          }
        }
        taskProgress {
          __typename
          ... on ExcelTaskProgress {
            totalUsers
            processedUsers
          }
          ... on ExternalSyncTaskProgress {
            totalClasses
            processedClasses
            classesProgress {
              id
              name
              classCode
              classGroupId
              processedUsers
              totalUsers
            }
          }
        }
        organizationId
        startedAt
      }
    }
  }
`;
