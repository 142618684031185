import { useParams, generatePath } from 'react-router-dom';
import URLS, { schooladminTabs } from 'utils/constants/urls';
import useFormatMessage from 'hooks/useFormatMessage';
// the below is suppressed 'cause it is in the project dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string';
import { UserPager } from '../UserPager';
import { generateRouteToClassDetails } from '../helper';
import UserProfilePageHeader from './UserProfilePageHeader';

export default function SchooladminUserProfilePageHeader({
  formattedSearchParamsClone,
  user,
  users,
}) {
  const { classId, className } = useParams();
  const t = useFormatMessage();

  const pageTitle = `${user?.firstName} ${user?.lastName}`;
  const breadcrumbsLinks = classId
    ? [
        {
          href: generatePath(URLS.MANAGE_TAB, {
            tab: schooladminTabs.CLASSES,
          }),
          name: t('global.CLASS.plural'),
          search: queryString.stringify(formattedSearchParamsClone),
        },
        {
          href: generateRouteToClassDetails({ id: classId }),
          name: `${t('global.CLASS.singular')}${className && ` ${className}`}`,
        },
        {
          href: generatePath(URLS.USER_PROFILE, { userId: user?.id }),
          name: pageTitle,
          current: true,
        },
      ]
    : [
        {
          href: generatePath(URLS.MANAGE_TAB, {
            tab: schooladminTabs.USERS,
          }),
          name: t('global.USER.plural'),
          search: queryString.stringify(formattedSearchParamsClone),
        },
        {
          href: generatePath(URLS.USER_PROFILE, { userId: user?.id }),
          name: pageTitle,
          current: true,
        },
      ];

  return (
    <UserProfilePageHeader
      avatar={user?.avatar}
      borderBottom={false}
      breadcrumbs={breadcrumbsLinks}
      marginBottom={false}
      user={user}
    >
      {Boolean(users?.length > 1) && (
        <UserPager
          currentUser={user}
          searchString={queryString.stringify(formattedSearchParamsClone)}
          users={users}
        />
      )}
    </UserProfilePageHeader>
  );
}
