import { gql } from 'graphql-request';

export const TEACHER_EXERCISES_BY_USERID = gql`
  query teacherExercisesByUserId(
    $userId: String!
    $requestContinuation: String
    $size: Int
    $startDate: String
    $endDate: String
  ) {
    teacherExercisesByUserId(
      userId: $userId
      requestContinuation: $requestContinuation
      size: $size
      startDate: $startDate
      endDate: $endDate
    ) {
      content {
        content
        createdAt
        exerciseId
        exerciseImage
        exerciseTitle
        exerciseVersionId
        lastModified
        lessonSessionId
        location
        userId
      }
    }
  }
`;
