import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dropdown, Label } from '@ftrprf/tailwind-components';

import Divider from 'components/Divider';

import {
  LanguageContext,
  supportedLanguages,
} from 'providers/LanguageProvider';

import useUpdateOwnUserInfo from 'hooks/api/plannerService/common/mutations/useUpdateOwnUserInfo';
import useFormatMessage from 'hooks/useFormatMessage';

export default function Language() {
  const { language: currentLanguageKey } = useContext(LanguageContext);
  const currentLanguage = supportedLanguages.find(
    (language) => language.key === currentLanguageKey,
  )?.label;
  const [language, setLanguage] = useState(currentLanguage);
  const t = useFormatMessage();

  const { mutateAsync: updateLanguage } = useUpdateOwnUserInfo();

  const { control } = useForm();

  const getLanguageKey = (label) => {
    const language = supportedLanguages.find(
      (language) => language.label === label,
    );

    setLanguage(language.label);
    return language.key;
  };
  const changeLanguage = (languageKey) => {
    updateLanguage({ language: languageKey });
  };

  return (
    <div>
      <Divider text={t('profile.language')} />

      <div className="mt-4 flex flex-col lg:flex-row">
        <Label className="sr-only" htmlFor="chooseLanguage">
          {t('profile.language.choose')}
        </Label>
        <Controller
          control={control}
          defaultValue={language}
          name="type"
          render={() => (
            <Dropdown
              className="w-full text-black"
              inputId="chooseLanguage"
              isMulti={false}
              onChange={(e) => {
                changeLanguage(getLanguageKey(e));
              }}
              options={supportedLanguages.map((language) => language.label)}
              value={language}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('global.required-field'),
            },
          }}
          type="select"
        />
      </div>
    </div>
  );
}
