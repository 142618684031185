import { laptop } from '../utils/constants/mediaWidth';
import useWindowSize from '../hooks/useWindowSize';
import FTRPRFImg from '../assets/FTRPRFPageHeader.jpg';
import c from '../utils/c';
import { Breadcrumbs } from './Breadcrumbs';
import { PageSubtitle } from './PageSubtitle';
import { PageTitle } from './PageTitle';
import { Avatar } from './Avatar';

export const IDP_PAGE_HEADER_IMAGES = {
  FTRPRF: FTRPRFImg,
  // TEAMS: TEAMS_img,
  // ILEARN: ILEARN_img,
  // SMARTSCHOOL: SMARTSCHOOL_img,
  // CODECOSMOS: CODECOSMOS_img,
};

export function PageHeader({
  avatar = null,
  borderBottom = true,
  breadcrumbs = [],
  button = null,
  buttonOnTitleRow = false,
  children = null,
  hasGradient = true,
  homeInfo = {},
  marginBottom = true,
  marginTop = true,
  subtitle = '',
  title = '',
  user = null,
}) {
  const { width } = useWindowSize();

  return (
    <div
      className={c(
        'w-full',
        borderBottom && 'border-b',
        'bg-gradient-to-r from-accent-700 via-accent-600 to-accent-400',
      )}
      style={
        Boolean(hasGradient && width >= laptop)
          ? {
              backgroundImage: `url(${IDP_PAGE_HEADER_IMAGES.FTRPRF})`,
              backgroundSize: '160%',
              backgroundPosition: 'center left',
              backgroundRepeat: 'no-repeat',
            }
          : {}
      }
    >
      <div className={c('w-full flex flex-col', marginTop && 'pt-8')}>
        <div
          className={c(
            marginBottom && 'mb-4',
            'rounded w-full max-w-5/6 self-center',
          )}
        >
          {subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
          {!buttonOnTitleRow && button}
          {Boolean(title) && (
            <div className="flex flex-col gap-2 md:gap-8 md:flex-row">
              {Boolean(avatar) && (
                <Avatar
                  avatar={avatar}
                  className="flex justify-center items-center w-16 h-16 md:w-32 md:h-32"
                />
              )}
              <div className="w-full flex flex-col gap-2 justify-center">
                <div className="w-full flex justify-between">
                  <PageTitle>{title}</PageTitle>
                  {buttonOnTitleRow && button}
                </div>

                {breadcrumbs && (
                  <Breadcrumbs
                    className="text-gray-200"
                    homeLink={homeInfo?.homeLink}
                    homeText={homeInfo?.homeText}
                    links={breadcrumbs}
                    user={user}
                  />
                )}
              </div>
            </div>
          )}

          {children}
        </div>
      </div>
    </div>
  );
}
