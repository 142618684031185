import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { plannerServiceQueryClient } from 'utils/graphqlQueryClients';
import { SCHOOLADMIN_SYNC_TEAMS_CLASS_GROUPS } from 'api/plannerService/schooladmin/mutations/schooladminSyncTeamsClassGroups';

export function useSchooladminSyncTeamsClassGroups() {
  const toast = useToast();
  const t = useFormatMessage();

  return useMutation(
    ({ teamsClassGroups }) =>
      plannerServiceQueryClient.request(SCHOOLADMIN_SYNC_TEAMS_CLASS_GROUPS, {
        teamsClassGroups,
      }),
    {
      mutationKey: 'schooladminSyncTeamsClassGroups',
      onSuccess: () => {
        toast(TOASTTYPES.UPDATESUCCESS, {
          str: t('global.CLASS.plural'),
        });
      },
    },
  );
}
