import { formatDateDDMonthYYYY } from 'utils/timeHelpers';

export function createOptionForPeriod(period) {
  if (!period) return null;

  const { description, endDate, id, name, startDate } = period;

  return {
    description,
    endDate,
    id,
    label: `${period.name} (${formatDateDDMonthYYYY(
      new Date(startDate),
    )} - ${formatDateDDMonthYYYY(new Date(endDate))})`,
    name,
    startDate,
    value: id,
  };
}

/**
 *
 * @param periods an array of periods with the following layout:
 *       period {
 *         id
 *         description
 *         name
 *         startDate
 *         endDate
 *         stats {
 *           classes
 *           programs
 *         }
 *       }
 */
export function createOptionsForPeriods(periods) {
  if (!periods) return [];

  return periods.map((period) => createOptionForPeriod(period));
}
