import { Content as ContentContainer } from '@ftrprf/tailwind-components';

import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';
import useStudentOrganizationContent from 'hooks/api/plannerService/student/queries/useStudentOrganizationContent';
import { UserContext } from 'providers/UserProvider';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';

import { useContext } from 'react';
import './learn.css';
import { ProgramEmptyState } from './ChapterItemOverview/partials/ProgramEmptyState';
import { OverviewButton } from './ChapterItemOverview/partials/OverviewButton';
import ChapterOverview from './ChapterOverview/ChapterOverview';
import ProgramOverview from './ProgramOverview/ProgramOverview';

export default function Learn({
  breadcrumbsLinks,
  currentChapter,
  currentProgram,
  isLoading,
  pageTitle,
  sortedPrograms,
}) {
  const user = useContext(UserContext);
  const { isStudent, isTeacher } = user;

  const { data: studentOrganizationContent } = useStudentOrganizationContent({
    enabled: isStudent,
  });
  const chapters = currentProgram?.chapters?.sort(sortByWeightThenByName);

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        button={isStudent ? null : <OverviewButton />}
        buttonOnTitleRow
        title={pageTitle}
        user={user}
      />
      <ContentContainer hasPaddingBottom>
        <div className="w-full mx-auto">
          <ListSkeleton loading={isLoading}>
            <div className="flex flex-col w-full">
              {sortedPrograms?.length > 0 && (
                <div>
                  {/* Chapter Overview */}
                  {!currentChapter && currentProgram && (
                    <ChapterOverview
                      chapters={chapters}
                      currentProgramId={currentProgram?.id}
                    />
                  )}

                  {/* Program Overview */}
                  {!currentChapter && !currentProgram && (
                    <ProgramOverview
                      programs={sortedPrograms}
                      showOwnContent={
                        isTeacher || Boolean(studentOrganizationContent?.length)
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </ListSkeleton>
          {sortedPrograms?.length === 0 && <ProgramEmptyState />}
        </div>
      </ContentContainer>
    </>
  );
}
