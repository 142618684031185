import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export default function EditSeatsForOrganizationProgram({
  isLoadingUpdateProgram,
  isOpen,
  selectedProgram,
  setIsEditSeatsDialogOpen,
  setSelectedProgram,
  update,
}) {
  const t = useFormatMessage();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onDissmis = useCallback(() => {
    setIsEditSeatsDialogOpen(false);
    setSelectedProgram(null);
    reset();
  }, [reset, setIsEditSeatsDialogOpen, setSelectedProgram]);

  const onSubmit = useCallback(
    (data) => update(data, onDissmis),
    [onDissmis, update],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDissmis}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          {t('edit-seats-for-program.change')} {selectedProgram?.name}
        </DialogHeader>
        <DialogContent className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <Label htmlFor="editProgramDialog-maxSeats">
              {t('program-overview.column.seatCount')}
            </Label>
            <Controller
              control={control}
              defaultValue={selectedProgram?.maxSeats}
              name="maxSeats"
              render={({ field: { onBlur, onChange, value } }) => (
                <Input
                  data-test={dataTestIds.modal.editSeatsFromProgram.input.seats}
                  hasErrors={Boolean(errors.maxSeats)}
                  id="editProgramDialog-maxSeats"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t('global.required-field'),
                },
                validate: {
                  isFinite,
                },
              }}
              type="number"
            />

            {errors.maxSeats?.type === 'isFinite' && (
              <span className="text-red-500 text-xs">
                {t('email.validate-error')}
              </span>
            )}
            {errors.maxSeats && (
              <span className="text-red-500 text-xs">
                {errors.email.message}
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            disabled={isLoadingUpdateProgram}
            onClick={onDissmis}
            test={dataTestIds.modal.editSeatsFromProgram.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={Object.keys(errors).length > 0 || isLoadingUpdateProgram}
            loading={isLoadingUpdateProgram}
            test={dataTestIds.modal.editSeatsFromProgram.button.submit}
            type="submit"
          >
            {t('edit-seats-for-program.submit')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
