import { useCallback, useContext } from 'react';
import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ClipboardDocumentIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';

import { Actions } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { OWN_CONTENT } from 'utils/constants/contentTypes';
import { lessonStatus } from 'utils/constants/lessonStatus';
import { dataTestIds } from 'utils/dataTestIds';
import { PeriodsContext } from '../../../../providers/PeriodsProvider';

// todo refactor chapter item Id and document
export function ContentActions({
  chapterItemId,
  copiedLesson,
  document,
  hasPlanButtons,
  isExam,
  itemInfo,
  openCalendarModal,
  openQuickPlanModal,
  ownContent = false,
  quickplanInfo,
  setItemInfo,
  status,
  studioId,
}) {
  const t = useFormatMessage();
  const actions = [];
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);

  const updateItemInfo = useCallback(() => {
    setItemInfo({
      ...itemInfo,
      chapter: quickplanInfo,
      chapterItem: ownContent ? OWN_CONTENT : chapterItemId,
      studioId,
      isExam,
    });
  }, [
    chapterItemId,
    isExam,
    itemInfo,
    ownContent,
    quickplanInfo,
    setItemInfo,
    studioId,
  ]);

  if (hasPlanButtons && selectedPeriodIsActivePeriod) {
    actions.push({
      icon: <PlusIcon className="inline mr-2 w-4 h-4" />,
      label: t('curricula.overview.plan'),
      onClick: () => {
        updateItemInfo();
        openCalendarModal(true);
      },
      testName: dataTestIds.page.learn.ownLessons.button.planOwnContent,
    });
  }

  if (selectedPeriodIsActivePeriod)
    actions.push({
      icon: <PlusIcon className="inline mr-2 w-4 h-4" />,
      label: t('curricula.overview.plan.quick'),
      onClick: () => {
        updateItemInfo();
        openQuickPlanModal(true);
      },
      testName: dataTestIds.page.learn.ownLessons.button.quickPlanOwnContent,
    });

  // this should be second in the array/dropdown
  if (copiedLesson && selectedPeriodIsActivePeriod) {
    actions.push({
      icon: <PencilIcon className="inline mr-2 w-4 h-4" />,
      label: t('content.edit'),
      href: `${process.env.REACT_APP_STUDIO_URL}/lesson/${studioId}/edit?url=${window.location.href}`,
    });
  }

  if (selectedPeriodIsActivePeriod)
    actions.push({
      icon: <ClipboardDocumentIcon className="inline mr-2 w-4 h-4" />,
      label: t('content.copyAndEdit'),
      href: `${process.env.REACT_APP_STUDIO_URL}/copy/${studioId}?url=${window.location.href}`,
    });

  // this should be after copy&edit in the array/dropdown
  if (
    copiedLesson &&
    status !== lessonStatus.ARCHIVED &&
    selectedPeriodIsActivePeriod
  ) {
    actions.push({
      icon: <ArchiveBoxIcon className="inline mr-2 w-4 h-4" />,
      label: t('content.archive'),
      href: `${process.env.REACT_APP_STUDIO_URL}/archive/${studioId}`,
    });
  }

  // this should be after copy&edit in the array/dropdown
  if (
    copiedLesson &&
    status === lessonStatus.ARCHIVED &&
    selectedPeriodIsActivePeriod
  ) {
    actions.push({
      icon: <ArchiveBoxIcon className="inline mr-2 w-4 h-4" />,
      label: t('content.unarchive'),
      href: `${process.env.REACT_APP_STUDIO_URL}/unarchive/${studioId}`,
    });
  }

  if (document)
    actions.push({
      icon: <ArrowDownTrayIcon className="mr-2 w-4 h-4 inline" />,
      label: t('content-details.download'),
      href: document,
    });

  return (
    <Actions
      buttonClassName="truncate"
      className="flex flex-grow justify-center"
      items={actions}
      noShadow
      small
      width="max-w-[10rem]"
    />
  );
}
