import { createContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/browser';

import useOrganizationInfo from 'hooks/api/plannerService/common/queries/useOrganizationInfo';
import useUserInfo from 'hooks/api/plannerService/common/queries/useUserInfo';

import { getToken } from 'api';

import { LANGUAGE } from 'utils/constants/localStorage';
import viewModes from 'utils/constants/viewModes';
import determineUserRoles from 'utils/determineUserRoles';
import { determineOrganisationRoles } from 'utils/determineOrganisationRoles';
import ftrprfUserRoles from '../utils/constants/ftrprfUserRoles';

export const UserContext = createContext({});

export default function UserProvider({ children }) {
  const [initialToken, setInitialToken] = useState();
  const { instance } = useMsal();

  const preferredLanguage =
    localStorage.getItem(LANGUAGE) &&
    localStorage.getItem(LANGUAGE) !== 'undefined'
      ? localStorage.getItem(LANGUAGE)
      : '';
  const account = instance?.getActiveAccount();

  const { data: organizationInfo, refetch: refetchOrganisationInfo } =
    useOrganizationInfo(
      account,
      account?.idTokenClaims.roles?.includes(ftrprfUserRoles.ADMIN) ||
        !initialToken,
    );

  const {
    data: userInfo,
    isLoading: isRefetchingUserInfo,
    refetch: refetchUserInfo,
  } = useUserInfo(initialToken);

  useEffect(() => {
    if (account) {
      getToken().then((token) => {
        setInitialToken(token);
      });
    }
  }, [account]);

  // useEffect(() => {
  //   if (organizationInfo) {
  //     Sentry.captureMessage('Successfully fetched organizationInfo', {
  //       extra: { organizationInfo },
  //     });
  //   }
  // }, [organizationInfo]);

  const context = useMemo(() => {
    const user = account?.idTokenClaims;
    if (user) {
      // IF token language is overwritten either by userInfo or Localstorage item
      if (userInfo?.language || preferredLanguage) {
        user.language = userInfo?.language || preferredLanguage;
      }

      // Just a backup
      if (!user.language) {
        user.language = 'nl';
      }

      Sentry.setUser({
        id: user.sub,
        organization: organizationInfo?.name,
        segment: user.organizationid,
      });

      ReactGA.set({ userId: user.sub });
    }

    const roleConditions = determineUserRoles(user);

    let parsedUiSettings = false;
    if (userInfo?.uiSettings) {
      parsedUiSettings = JSON.parse(
        String(
          userInfo.uiSettings
            // TODO: the replaces are temporary must be removed when all users have updated their uiSettings
            .replace('calendar=', '"calendar":')
            .replace('extendedHours=', '"extendedHours":')
            .replace('fullWeek=', '"fullWeek":')
            .replace('viewMode=', '"viewMode":'),
        ),
      );
    }

    const settings = parsedUiSettings || {
      calendar: {
        extendedHours: false,
        fullWeek: false,
      },
      slideViewer: {
        viewMode: viewModes.PROJECTION,
      },
    };

    const organization = {
      ...determineOrganisationRoles(organizationInfo),
    };

    return {
      initialToken,
      ...user,
      ...roleConditions,
      avatar: userInfo?.avatar,
      email: user?.strongAuthenticationEmailAddress,
      eulaAccepted: true,
      id: user?.sub,
      isRefetchingSettings: isRefetchingUserInfo,
      language: user?.language.toLowerCase(),
      organization,
      refetchOrganisationInfo,
      refetchSettings: refetchUserInfo,
      settings,
    };
  }, [
    account?.idTokenClaims,
    userInfo,
    organizationInfo,
    initialToken,
    refetchUserInfo,
    isRefetchingUserInfo,
    refetchOrganisationInfo,
    preferredLanguage,
  ]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
