import { lazy, Suspense, useContext } from 'react';

import { LanguageContext } from 'providers/LanguageProvider';
import { UserContext } from 'providers/UserProvider';

import useTeacherStudentById from 'hooks/api/plannerService/teacher/queries/useTeacherStudentById';
import { useTeacherStudentExercise } from 'hooks/api/portfolioService/queries/useTeacherStudentExercise';
import useParams from 'hooks/useParams';

import b64toBlob from 'utils/b64toBlob';

import { ReactComponent as Loader } from 'assets/vectors/logo-animated-lines.svg';

import { useTestExercise } from 'hooks/useTestExercise';
import ScratchError from '../partials/ScratchError';
import { CodiIntroDialog } from '../partials/CodiIntroDialog';

const Scratch = lazy(() => import('../partials/Scratch'));

export default function Exercise() {
  const { exerciseVersionId, lessonSessionId, userId } = useParams();

  const { language } = useContext(LanguageContext);
  const user = useContext(UserContext);
  const { id } = user;

  const {
    data: exercise,
    isError,
    isLoading,
    refetch,
  } = useTeacherStudentExercise({
    lessonSessionId,
    userId,
    exerciseVersionId,
  });

  const {
    isLoading: isLoadingTests,
    results: testResults,
    test,
  } = useTestExercise({
    exerciseId: exercise?.exerciseId,
    versionId: exercise?.exerciseVersionId,
    testPlanId: exercise?.testPlanId,
    language: exercise?.exerciseLanguage,
  });

  const { data: owner } = useTeacherStudentById(userId);

  if (isError && exercise.content) {
    return <ScratchError />;
  }
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Loader className="w-32" />
      </div>
    );
  }

  const isOwnedExercise = exercise?.userId === id;
  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader className="w-32" />
        </div>
      }
    >
      <Scratch
        exercise={{
          ...exercise,
          testResults,
          id: exercise?.exerciseId,
          blob: b64toBlob(exercise?.content),
        }}
        isLoading={isLoading}
        isLoadingTests={isLoadingTests}
        isOwnedExercise={isOwnedExercise}
        isRestarting={isLoading}
        language={language}
        ownerName={`${owner?.firstName} ${owner?.lastName}`}
        restart={refetch}
        test={test}
      />
      <CodiIntroDialog hasCodi={Boolean(exercise?.versionTestPlanId)} />
    </Suspense>
  );
}
