import { FiGlobe } from 'react-icons/fi';

import { DropdownButton } from './buttons/DropdownButton';
import { MenuButton } from './buttons/MenuButton';

import { DropdownMenu } from './DropdownMenu';

export function LanguagePicker({ languages, onLanguageChange }) {
  const dropdownButton = ({ isOpen }) => (
    <DropdownButton isOpen={isOpen}>
      <FiGlobe className="w-5 h-5" />
    </DropdownButton>
  );

  return (
    <DropdownMenu trigger={dropdownButton}>
      {({ hide }) => (
        <div>
          {languages.map((language) => (
            <li>
              <MenuButton
                key={language.key}
                onClick={() => {
                  hide();
                  onLanguageChange(language.key);
                }}
              >
                {language.label}
              </MenuButton>
            </li>
          ))}
        </div>
      )}
    </DropdownMenu>
  );
}
