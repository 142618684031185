import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';

import Home from './Home';

export default function TeacherContainerHome() {
  const { data: classGroupsData } = useTeacherClassGroups({
    page: 0,
    size: 500,
  });

  const classGroups = classGroupsData?.content ?? [];

  return <Home classGroups={classGroups} />;
}
